<template>
  <div
    id="explore"
    class="explore-container"
  >
    <div
      class="flex"
      :class="[{ 'flex-col-reverse': MobileCreateMode }, { 'flex-col': MobileEditMode }]"
    >
      <!-- SCENARIO WIZARD STUDY AREA FOR MOBILE -->
      <slide-y-up-transition>
        <div
          v-if="MobileCreateMode && ACTIVE_SCENARIO_WIZARD_TAB === 'tab_region'"
          class="map-mobile-create mobile-study-area md-elevation-14"
        >
          <scenario-study-area />
        </div>
      </slide-y-up-transition>
      <!-- END SCENARIO WIZARD STUDY AREA FOR MOBILE -->
      <!-- SCENARIO WIZARD CHANGES FOR MOBILE -->
      <slide-y-up-transition>
        <div
          v-if="MobileCreateMode && ACTIVE_SCENARIO_WIZARD_TAB === 'tab_changes'"
          class="map-mobile-create mobile-study-area md-elevation-14"
        >
          <scenario-changes :simulation-state="simulationState" />
        </div>
      </slide-y-up-transition>
      <!-- SCENARIO WIZARD CHANGES FOR MOBILE -->
      <cover-map
        v-show="!(MobileCreateMode && ['tab_goals', 'tab_mgmt', 'tab_climate'].includes(ACTIVE_SCENARIO_WIZARD_TAB))"
        ref="covermap"
        :scenario="ACTIVE_SCENARIO"
        :year="year"
        :intensity-map-data="intensityMapData"
        :bitmaps-ready="bitmapsReady"
        :climate-inds-ready="climateIndsReady"
        :class="[{ 'map-mobile-create': MobileCreateMode }, { 'map-mobile-edit': MobileEditMode }]"
        @newPlanningJickle="loadPlanningJickle"
        @newPlanningRegionJson="loadPlanningRegionData"
        @onMapMoveend="mapMoveend"
      />
      <right-sidebar
        v-if="showSideBarContainer"
        :scenario="ACTIVE_SCENARIO"
        :climate-ind-data="climateIndData"
        :climate-inds-ready="climateIndsReady"
        :year="year"
        :min-year="minYear"
        :max-year="maxYear"
        :simulation-progress="simulationProgress"
        :simulation-state="simulationState"
        :region-indicators="regionIndicators"
        :region-indicators-ready="regionIndicatorsReady"
        :bitmaps-ready="bitmapsReady"
        class="md-elevation-4"
        :class="[{ 'scenario-mobile-create': MobileCreateMode }, { 'sidebar-mobile-wizard-tabs-only': (rightSidebarFullScreen && EXPLORE_MODE === 'create' && ['tab_region', 'tab_changes'].includes(ACTIVE_SCENARIO_WIZARD_TAB)) }]"
        @onDevRateChange="onDevRateChange"
        @onViewClimatePanel="onViewClimatePanel"
      />
    </div>
    <!-- climate indicator legend -->
    <map-indicator-legend
      v-if="showClimateLegend"
      :indicator="activeClimateInd"
      class="md-elevation-2 climate-legend"
    />
    <!-- timeline slider -->
    <div
      v-if="bitmapsReady && EXPLORE_MODE !== 'create'"
      id="ExploreTimeSliderContainer"
      class="slider-card md-elevation-8"
      :class="[{'slider-card-medium': rightSidebarMedium}, {'slider-card-fullscreen': rightSidebarFullScreen}]"
    >
      <div class="md-layout">
        <div
          v-show="!MobileEditMode"
          class="md-layout-item md-size-100 md-layout"
        >
          <div class="md-layout-item md-size-55">
            <h2 class="text-white slider-year">
              <b>{{ year }}</b>
            </h2>
          </div>
          <div class="md-layout-item md-size-15 center-vertical">
            <i
              class="fas fa-play fa-1x cursor-pointer text-white slider-button"
              @click="runAnimation"
            />
          </div>
          <div class="md-layout-item md-size-15 center-vertical">
            <i
              class="fas fa-pause fa-1x cursor-pointer text-white slider-button"
              @click="pauseAnimation"
            />
          </div>
          <div class="md-layout-item md-size-15 center-vertical">
            <i
              class="fas fa-backward fa-1x cursor-pointer text-white slider-button"
              @click="resetAnimation"
            />
          </div>
        </div>
        <div
          v-show="!MobileEditMode"
          class="md-layout-item md-size-90 slider-bar"
        >
          <slider v-model="simulationProgressComputed" />
          <md-tooltip md-direction="top">
            Slide through time
          </md-tooltip>
          <br>
        </div>
        <div
          v-if="responsive && rightSidebarFullScreen"
          class="md-layout-item md-size-80 slider-scen-title"
        >
          <h4>{{ ACTIVE_SCENARIO.name | truncate(titleTruncateAmt) }}</h4>
        </div>
        <div
          v-if="responsive && rightSidebarFullScreen"
          class="md-layout-item md-size-20 slider-scen-title"
        >
          <scenario-actions-btn class="slider-scen-title" />
        </div>
      </div>
    </div>
    <virtual-tour-dialog v-if="$Region.virtualToursOn" />
    <scenario-computing-dialog />
    <intro-tour
      v-if="showScenarioOutputTours"
      name="RightSidebarCoverIndicators"
      :steps="rightSidebarCoverIndicatorsSteps"
    />
    <intro-tour
      v-if="showScenarioOutputTours && ACTIVE_SCENARIO.type === 'Land Use'"
      name="RightSidebarCoverIndicatorGoal"
      :steps="rightSidebarCoverIndicatorGoalSteps"
    />
    <intro-tour
      v-if="showScenarioOutputTours"
      name="RightSidebarLandscapeSummary"
      :steps="rightSidebarLandscapeSummarySteps"
    />
    <intro-tour
      v-if="showScenarioOutputTours && ACTIVE_SCENARIO.type === 'Land Use'"
      name="RightSidebarLandscapeSummaryEdit"
      :steps="rightSidebarLandscapeSummaryEditSteps"
    />
    <intro-tour
      v-if="showScenarioOutputTours"
      name="RightSidebarClimatePanel"
      :steps="rightSidebarClimatePanelSteps"
    />
    <intro-tour
      v-if="bitmapsReady && EXPLORE_MODE !== 'create'"
      name="ExploreTimeSlider"
      :steps="exploreTimeSliderSteps"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { types } from "@/store/types";
import { Slider } from "@/components";
import ScenarioActionsBtn from "@/pages/Dashboard/Components/ScenarioActionsBtn";
import RightSidebar from "../Layout/RightSidebar.vue";
import { SlideYUpTransition } from 'vue2-transitions'
import ScenarioStudyArea from "@/pages/Dashboard/Components/ScenarioStudyArea";
import ScenarioChanges from "@/pages/Dashboard/Components/ScenarioChanges";
import ScenarioComputingDialog from '@/pages/Dashboard/Components/ScenarioComputingDialog';
import VirtualTourDialog from '@/pages/Dashboard/Components/VirtualTourDialog';
import CoverMap from "../Components/CoverMap";
import MapIndicatorLegend from "../Components/MapIndicatorLegend";
import IntroTour from "@/pages/Dashboard/Components/IntroTour";
import { ScenarioDataHandler } from "@/pages/Dashboard/Components/mixins/ScenarioDataHandler";
import { Sharing } from "@/pages/Dashboard/Components/mixins/Sharing";
import { Indicators } from "@/pages/Dashboard/Components/mixins/Indicators";
import { Gamification } from "@/pages/Dashboard/Components/mixins/Gamification";
import { TourSteps } from "@/pages/Dashboard/Components/mixins/TourSteps";
import { ResponsiveLayoutHelpers } from "@/pages/Dashboard/Components/mixins/ResponsiveLayoutHelpers";
import { TeacherFunctions } from "@/pages/Dashboard/Components/mixins/TeacherFunctions";

export default {
  name: "Explore",

  components: {
    CoverMap,
    RightSidebar,
    SlideYUpTransition,
    ScenarioStudyArea,
    ScenarioChanges,
    ScenarioComputingDialog,
    VirtualTourDialog,
    Slider,
    ScenarioActionsBtn,
    MapIndicatorLegend,
    IntroTour
  },
  mixins: [ScenarioDataHandler, Sharing, Indicators, Gamification, TourSteps, ResponsiveLayoutHelpers, TeacherFunctions], // for badge/hint/challenge conditions & events

  props: {},

  data() {
    return {
      simulationProgress: 0,
      yearRange: 0,
      animation: null,
      animationProgress: 0,
      // provide region helpers and global helpers to equation functions
      regionIndicators: [], // list of indicators specific to the study area
      // chartist-plugin-fill-donut inside CoverIndicator.vue component doesn't re-render the icons properly when they change
      // therefore we need to track when region promise is done loading region geojson and all indicators are defined before rendering gauges.
      regionIndicatorsReady: false, // tracking; don't render any indicators until this is true.
      landuseSummaryReady: false, // tracking; don't render any indicators until this is true.
      bitmapsReady: false,
      climateIndsReady: false,
      numClimateIndsReady: 0,
      devRateChanged: false, // for gamification - track if user has changed devrate to compare dials within the same scenario run.  Resets each time scenario is loaded with data from server
      hasViewedClimatePanel: false, // for climate quest - track if user has viewed the details on the climate info panel.  Resets each time Explore component is launched
    };
  },

  computed: {
    ...mapGetters([
      types.getters.ACTIVE_SCENARIO,
      types.getters.USER_SCENARIOS,
      types.getters.EXPLORE_MODE,
      types.getters.EXPLORE_MOBILE_FS_CONTENT,
      types.getters.ACTIVE_SCENARIO_WIZARD_TAB,
      types.getters.SIDEBAR_OPEN,
      types.getters.SIDEBAR_CONTENT_OPEN,
      types.getters.SIDEBAR_CONTENT_TYPE,
      types.getters.REFRESH_SCENARIO,
      types.getters.SELECTED_OBSERVATION,
      types.getters.MAP_DATALAYER,
      types.getters.USER_DATA,
      types.getters.INDICATOR_VALS_READY,
      types.getters.SELECTED_REGION_TYPE,
    ]),

    animationDuration() {
      // Number of seconds to animate scenario during playback
      return Math.min(Math.floor((this.maxYear - this.minYear) / 4), 20); // should be 7s for future, 20s for historic
    },
    minYear() {
      if (this.ACTIVE_SCENARIO !== null && this.EXPLORE_MODE === 'create') {
        return this.$Region.base_raster_time; // new scenarios use this setting
      } else if (this.ACTIVE_SCENARIO !== null) {
        return this.ACTIVE_SCENARIO.startTime;
      }
      return 0;
    },
    maxYear() {
      if (this.ACTIVE_SCENARIO !== null && this.EXPLORE_MODE === 'create') {
        return this.$Region.base_raster_time; // new scenarios use this setting
      } else if (this.ACTIVE_SCENARIO !== null) {
        return this.ACTIVE_SCENARIO.endTime;
      }
      return 0;
    },
    noChangeLandUseTypes() {
      return this.$Region.noChangeLandUseTypes;
    },
    landUseTypes() {
      return this.$Region.landUseTypes;
    },
    year() {
      return (
        this.minYear +
        parseInt(
          Math.round(
            (this.maxYear - this.minYear) * (this.simulationProgress / 100)
          )
        )
      );
    },
    simulationProgressComputed: {
      get: function () {
        return parseFloat(this.simulationProgress);
      },
      set: function (newVal) {
        this.simulationProgress = parseFloat(newVal);
      }
    },
    showSideBarContainer() {
      // whether or not the right-side area should be visible
      if ((this.SIDEBAR_OPEN && (this.bitmapsReady || this.SELECTED_OBSERVATION !== null)) || this.EXPLORE_MODE === 'create') {
        if (this.rightSidebarFullScreen && this.EXPLORE_MOBILE_FS_CONTENT !== 'stats' && this.EXPLORE_MODE !== 'create') {
          // fullscreen mode not set to sidebar
          return false;
        }
        return true;
      }
      if (this.rightSidebarFullScreen && (this.EXPLORE_MOBILE_FS_CONTENT === 'stats' || this.EXPLORE_MODE === 'create')) {
        return true;
      }
      return false;
    },
    MobileCreateMode() {
      // whether the sidebar is in mobile create mode for small screens
      return (this.rightSidebarFullScreen && this.EXPLORE_MODE === 'create');
    },
    MobileEditMode() {
      // whether the sidebar is in mobile edit mode for small screens editing of scenario allocations
      return (this.rightSidebarFullScreen && this.EXPLORE_MODE === 'scenario' && this.SIDEBAR_CONTENT_OPEN && this.SIDEBAR_CONTENT_TYPE === 'changes');
    },
    showScenarioOutputTours() {
      // whether or not to make help tours available for right sidebar scenario output stuff
      return this.showSideBarContainer && this.bitmapsReady && this.EXPLORE_MODE !== 'create';
    },
    scenarioLoaded() {
      // whether or not a scenario object is set
      return (this.ACTIVE_SCENARIO !== null);
    },
    showClimateLegend() {
      // whether or not to show the climate indicator legend on the map
      const inds = this.$Region.climateIndicators.map(ind => ind.name);
      return (inds.includes(this.MAP_DATALAYER) && this.climateIndsReady);
    },
    activeClimateInd() {
      // the indicator that is currently active on the map
      if (this.showClimateLegend) {
        return this.$Region.climateIndicators.find(ind => ind.name === this.MAP_DATALAYER);
      }
      return {};
    },
    titleTruncateAmt() {
      // how much to truncate a scenario title on mobile
      if (window.innerWidth < 360) {
        return 20;
      } else if (window.innerWidth < 385) {
        return 25;
      }
      return 30;
    }
  },

  watch: {
    year() {
      this.activateYear();
    },
    scenarioLoaded(newVal) {
      if (newVal === false) {
        // empty all scenario data if the scenario was closed
        this.emptyAllScenarioData();
      } else if (newVal === true) {
        this.simulationState = this.emptySimulationState; //setup simulationState object
        this.loadScenario();
        this.SHOW_LANDUSE_OVERLAY_ON();
        this.TOGGLE_REFRESH_LANDUSE_LAYER();
        this.CLOSE_SCENARIO_COMPUTING_DIALOG();
      }
    },
    REFRESH_SCENARIO() {
      this.scenarioDataReInit();
      this.loadScenario();
      this.SHOW_LANDUSE_OVERLAY_ON();
      this.TOGGLE_REFRESH_LANDUSE_LAYER();
      this.CLOSE_SCENARIO_COMPUTING_DIALOG();
    },
    EXPLORE_MODE(newVal) {
      if (['explore', 'create'].includes(newVal)) {
        // clear out data if explore mode changed to explore
        this.emptyAllScenarioData();
        this.SET_EXPLORE_MOBILE_FS_CONTENT('map');
      }
    },
    MAP_DATALAYER(newVal) {
      if (newVal === 'landuse') {
        this.intensityMapData = this.landuseMapData;
      } else if (newVal !== null) {
        this.intensityMapData = this.climateMapData[newVal];
      }
      this.TRIGGER_MAP_DATALAYER_RELOAD();
    },
    EXPLORE_MOBILE_FS_CONTENT(newVal) {
      if (newVal === 'stats') {
        this.OPEN_SIDEBAR();
      } else {
        setTimeout(() => this.$refs.covermap.map.resize(), 200);
      }
    },
    ACTIVE_SCENARIO_WIZARD_TAB(newVal) {
      // make sure map resizes properly during fullscreen scenario wizard
      if (this.rightSidebarFullScreen && ['tab_region', 'tab_changes'].includes(newVal)) {
        setTimeout(() => this.$refs.covermap.map.resize(), 200);
      }
    },
    INDICATOR_VALS_READY(newVal) {
      // if indicator vals were cleared due to devRate changing or mgmt practices changing, go ahead and re-calculate vals
      if (newVal === false && this.scenarioLoaded && this.EXPLORE_MODE === 'scenario') {
        this.pauseAnimation();
        this.calculateIndicatorVals();
      }
    }
  },

  beforeMount() {
    // load shared resource if it's in the url query
    if ('scen' in this.$route.query && this.$route.query.scen !== null && this.$route.query.scen !== '') {
      this.getShareObj('scen', this.$route.query.scen, this.$Helpers.remapScenData, this.SET_ACTIVE_SCENARIO, 'scenario');
    }
    // setup scenario stuff
    if (this.ACTIVE_SCENARIO !== null) {
      this.simulationState = this.emptySimulationState; //setup simulationState object
      if (this.EXPLORE_MODE === 'scenario') {
        this.loadScenario();
      }
    }
    // get teacher specific data
    if (this.USER_DATA.usertype === 'teacher') {
      this.studentsLoad();
    }
    // default study area type for scenarios
    if (this.SELECTED_REGION_TYPE === null) {
      this.SET_SELECTED_REGION_TYPE(this.$Region.region_type);
    }
  },

  methods: {
    ...mapActions([
      types.actions.SET_ACTIVE_SCENARIO,
      types.actions.UPDATE_ACTIVE_SCENARIO,
      types.actions.OPEN_SIDEBAR,
      types.actions.SET_EXPLORE_MOBILE_FS_CONTENT,
      types.actions.CLOSE_SCENARIO_COMPUTING_DIALOG,
      types.actions.TOGGLE_REFRESH_LANDUSE_LAYER,
      types.actions.SHOW_LANDUSE_OVERLAY_ON,
      types.actions.SET_ALLOCATION_AREA_MAX,
      types.actions.SET_MAP_DATALAYER,
      types.actions.TRIGGER_MAP_DATALAYER_RELOAD,
      types.actions.SET_INDICATOR_VALS_READY,
      types.actions.SET_SELECTED_REGION_TYPE,
    ]),

    emptyAllScenarioData() {
      // to clean out all scenario and scenario create/edit related variables
      this.bitmapsReady = false;
      this.climateIndsReady = false;
      this.numClimateIndsReady = 0;
      this.simulationProgress = 0;
      this.simulationState = this.emptySimulationState;
      this.climateIndData = {}
      this.landuseMapData = {};
      this.climateMapData = {};
      this.intensityMapData = {};
      this.SET_MAP_DATALAYER('landuse');
      this.regionIndicators = []; // list of indicators specific to the study area
      this.regionIndicatorsReady = false; // tracking; don't render any indicators until this is true.
      this.landuseSummaryReady = false;
    },

    scenarioDataReInit() {
      // to clean out all scenario and scenario create/edit related variables when a scenario is re-computed
      this.bitmapsReady = false;
      this.climateIndsReady = false;
      this.numClimateIndsReady = 0;
      this.simulationProgress = 0;
      this.simulationState = this.emptySimulationState;
      this.landuseMapData = {};
      this.climateMapData = {};
      this.intensityMapData = {};
      this.climateIndData = {};
    },

    loadScenario() {
      // load an existing scenario
      if (this.ACTIVE_SCENARIO !== null && this.EXPLORE_MODE !== 'create') {
        this.loadScenarioData();
      }
      // reset devrate tracking
      this.devRateChanged = false;
      // TODO deal with run errors
      if (this.ACTIVE_SCENARIO.error) {
        this.$notify({
          message: "There was an error - please try running that scenario again",
          icon: "error_outline",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger"
        });
        // TODO deal with run errors
      }
    },

    loadScenarioData() {
      // load bitmaps and related scenario data
      this.$Auth.currentSession().then(authData => {
        const myInit = {
          headers: { Authorization: authData.idToken.jwtToken },
          body: {
            bucket: this.$Bucket
          }
        };
        // get study area settings from region geojson
        this.loadStudyAreaData(myInit);
        // get the jickle bitmap data
        this.loadSimulationBitmaps(myInit, this.ACTIVE_SCENARIO);
        // get climate indicators
        this.getClimateInds(myInit, this.ACTIVE_SCENARIO);
      });
    },

    loadPlanningRegionData(regionData) {
      // get region specific data from selected region in new scenario planning mode
      this.simulationState = this.emptySimulationState; // reset the simulation state and set it up for new region data
      this.loadGlobalRegionScenarioData();
      this.loadRegionDataKeys(regionData);
      this.regionIndicatorsReady = true;
    },

    loadPlanningJickle(regionJicklePath) {
      // get refernce jickle bitmaps for planning mode
      if (regionJicklePath !== null) {
        // load new jickle
        this.$Auth.currentSession().then(authData => {
          const myInit = {
            headers: { Authorization: authData.idToken.jwtToken }
          };
          this.loadNewSimulationBitmaps(myInit, regionJicklePath);
        });
      } else {
        // clear jickle
        this.landuseMapData = {};
        if (this.MAP_DATALAYER === 'landuse') {
          this.intensityMapData = {};
        }
        this.bitmapsReady = false;
        this.climateIndsReady = false;
        this.numClimateIndsReady = 0;
      }
    },

    loadNewSimulationBitmaps(myInit, jicklePath) {
      // load jickle from s3 for planning mode and parse into object
      const _this = this;

      const jickleInit = Object.assign({}, myInit);
      jickleInit.body = { bucket: this.$Bucket, region: "us-west-2" };
      // get the tempLink
      this.$API
        .post("api", "/public/" + jicklePath, jickleInit)
        .then(response => {
          // TODO figure out how to make this work without making the jickle files public read access (although it's probably not a security problem)
          // PROBLEMS: if simply loading via amplify using this.$API.get, the response comes back gzipped and the browser doesn't unzip it properly
          // AND if using this.$API.post, it throws forbidden error unless the querystring is removed
          // AND passing responseType = "arraybuffer" to the $API also causes errors.... therefore, this is working as long as the files have read access
          //
          // replace the space characters with + signs
          // remove the querystring -- causes forbidden error
          const jickleEndpoint = response.body.tempLink
            .replace(/%2520/g, "+")
            .split("?")[0];
          // parse the jickle and load settings into intensityLayer
          _this.getJickle(jickleEndpoint, _this.parseAndForceLoadLanduseJickle);
        });
    },

    async loadSimulationBitmaps(myInit, scenario) {
      // load jickle from s3 and parse into object
      const dsPath = this.getJicklePath(scenario);

      const response = await this.$API.post("api", dsPath, myInit);

      // don't know why jickles coming from /public/ folder get different formatted tempLink's than those from /username/ folder
      // might be because of the filenames with special characters and spaces
      let jickleEndpoint = response.body.tempLink;
      if (dsPath.startsWith("/public/")) {
        // replace the space characters with + signs
        // remove the querystring -- causes forbidden error
        jickleEndpoint = jickleEndpoint.replace(/%2520/g, "+").split("?")[0];
      }

      // parse the jickle and load settings into intensityLayer
      this.getJickle(jickleEndpoint, this.parseAndLoadLanduseJickle);
    },

    parseAndForceLoadLanduseJickle(data) {
      this.parseAndLoadLanduseJickle(data, true);
    },

    initSimAreas() {
      // set the simulationState.simYear to track where the sim state is
      this.simulationState.simYear = this.minYear;
    },
    getLTArea_ha(num_cells, resolution) {
      // compute the area of an LT in ha based on number of cells
      // backwards compatible - jickles that don't have resolution will be assigned 2000 arbitrarily... should only affect beta versions
      if (resolution === null) {
        resolution = this.$Region.cell_size;
      }
      // get resolution from ACTIVE_SCENARIO if possible, else resolution (resolution used for reference data in create mode)
      const cs = (this.ACTIVE_SCENARIO !== null && 'runTimeResolution' in this.ACTIVE_SCENARIO) ? this.ACTIVE_SCENARIO.runtimeResolution : resolution;
      return (num_cells * cs * cs) / 10000;
    },

    activateYear() {
      this.simulationState.simYear = this.year;
    },
    calculateIndicatorVals() {
      // calculate indicator outputs for scenario
      // skip this function if in create mode with anything but a Land Use scenario
      if (this.EXPLORE_MODE === 'create' && this.ACTIVE_SCENARIO.type !== 'Land Use') {
        return;
      }
      // debug/testing
      // const t0 = performance.now();
      const allInds = this.filteredIndicators(this.regionIndicators);
      const allYears = Array(this.maxYear - this.minYear + 1).fill().map((x, i) => i + this.minYear);
      const vm = this;
      allInds.forEach(ind => {
        allYears.forEach(yr => {
          vm.simulationState.simYear = yr;
          vm.indicatorValue(ind.name, vm.ACTIVE_SCENARIO, vm.simulationState, yr);
        });
      });
      // reset simulation state to current year
      this.activateYear();
      this.SET_INDICATOR_VALS_READY(true);
      // debug/testing
      // const t1 = performance.now();
      // console.log(`calculated all indicator vals... time: ${t1 - t0} ms ..`);
    },
    runAnimation() {
      // run the playback animation - make sure indicator vals are ready
      const vm = this;
      const waitingDoRun = function () {
        // only run animation if the indicator vals have been calculated and are ready
        if (!vm.INDICATOR_VALS_READY) {
          setTimeout(waitingDoRun(), 200);
        } else {
          vm.doRunAnimation();
        }
      };
      waitingDoRun();
    },
    doRunAnimation() {
      // actual animation run
      if (this.animationProgress === 0) {
        // First run
        this.animationProgress = performance.now();
      } else if (this.animationProgress === -1) {
        // Resume from pause
        this.animationProgress =
          performance.now() -
          (this.simulationProgress / 100) * this.animationDuration * 1000;
      } else if (this.animationProgress === -2) {
        // Play after being run out
        this.simulationProgress = 0;
        this.animationProgress = performance.now();
      }
      if (this.year === this.maxYear) {
        this.simulationProgress = 100;
        this.animationProgress = -2;
        cancelAnimationFrame(this.animation);
        // GAMIFICATION EVENT: scenario-view
        this.getGameActivity('scenario-view');

      } else {
        const x = (performance.now() - this.animationProgress) / 1000;
        if (x > 0) {
          this.simulationProgress = Math.min((x / this.animationDuration) * 100, 100);
        } else {
          this.simulationProgress = 0;
        }
        this.animation = requestAnimationFrame(this.runAnimation);
      }
    },
    pauseAnimation() {
      this.animationProgress = -1;
      cancelAnimationFrame(this.animation);
    },
    resetAnimation() {
      cancelAnimationFrame(this.animation);
      this.simulationProgress = 0;
      this.animationProgress = 0;
    },
    mapMoveend() {
      // note map event and pass along to Gamification - quests
      // event only triggers quest activities
      if (this.ACTIVE_QUEST !== null) {
        this.checkQuestActivities('map-move');
      }
    },
    onDevRateChange() {
      // user has changed the dev rate within the same scenario run
      this.devRateChanged = true;
    },
    onViewClimatePanel() {
      // user has viewed the climate panel
      this.hasViewedClimatePanel = true;
      this.checkQuestActivities('panel-view');
    },
  }

};
</script>

<style lang="scss" scoped>
#explore,
.explore-container {
  width: 100%;
  position: relative;
}
.explore-container {
  height: calc(100vh - 70px);
}
.flex {
  display: flex;
  height: 100%;
  width: 100%;
}
.flex-col {
  flex-direction: column;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}
.map-mobile-create {
  flex-basis: 80%;
}
.map-mobile-edit {
  flex-basis: 50%;
}
.scenario-mobile-create {
  flex-basis: 100%;
}
/deep/ .scenario-mobile-create .md-card {
  margin-bottom: 0;
  border-radius: 0;
}
/deep/ .scenario-mobile-create .md-card-wizard {
  margin-top: 0;
}
/deep/ .sidebar-mobile-wizard-tabs-only {
  flex-basis: 98px;
  max-height: 98px;
  width: 100%;
  max-width: 100%;
  overflow-y: hidden;
  margin-top: 0;
  align-content: top;
}
.mobile-study-area {
  background: $gray-lighter;
  z-index: 10;
  overflow: scroll;
}
.climate-legend {
  position: absolute;
  bottom: 150px;
  left: 20px;
  z-index: 2;
  background: rgba(0, 0, 0, 0.4);
  max-width: 150px;
}
.slider-card {
  position: absolute;
  width: calc(100% - 640px);
  bottom: 10px;
  left: 20px;
  z-index: 2;
  background: rgba(0, 0, 0, 0.4);

  & > * {
    color: white;
  }
}
.slider-card-medium {
  width: calc(100% - 440px) !important;
}
.slider-card-fullscreen {
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 5;
}
.slider-year {
  margin-block-start: 0px;
  margin-block-end: 0px;
}
.slider-button {
  margin: 20px 0 0 0;
}
.slider-button:hover {
  cursor: pointer;
}
.slider-bar {
  margin-left: 20px;
  height: 40px;
}
.slider-scen-title {
  background: $gray-darker;
  height: 48px;
}
.slider-scen-title h4 {
  font-weight: bold;
  margin-top: 0;
  padding: 10px 0 0 0;
}
/deep/ .slider-scen-title button {
  background: transparent !important;
}
</style>
