<template>
  <div>
    <h5 class="info-text">
      {{ userInformation }}
    </h5>
    <div class="md-layout">
      <div class="md-layout-item md-size-95 ml-auto mt-4 md-small-size-100">
        <md-field
          :class="[
            {'md-valid': !errors.has('firstName') && touched.firstName},
            {'md-form-group': true},
            {'md-error': errors.has('firstName')}]"
        >
          <md-icon>star_half</md-icon>
          <label>First Name</label>
          <md-input
            v-model="firstName"
            v-validate="modelValidations.firstName"
            :disabled="authSuccess"
            data-vv-name="firstName"
            type="text"
            name="firstName"
            required
          />
          <slide-y-down-transition>
            <md-icon
              v-show="errors.has('firstName')"
              class="error"
            >
              close
            </md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              v-show="!errors.has('firstName') && touched.firstName"
              class="success"
            >
              done
            </md-icon>
          </slide-y-down-transition>
        </md-field>

        <md-field
          :class="[
            {'md-valid': !errors.has('lastName') && touched.lastName},
            {'md-form-group': true},
            {'md-error': errors.has('lastName')}]"
        >
          <md-icon>star</md-icon>
          <label>Last Name</label>
          <md-input
            v-model="lastName"
            v-validate="modelValidations.lastName"
            :disabled="authSuccess"
            data-vv-name="lastName"
            type="text"
            name="lastName"
            required
          />
          <slide-y-down-transition>
            <md-icon
              v-show="errors.has('lastName')"
              class="error"
            >
              close
            </md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              v-show="!errors.has('lastName') && touched.lastName"
              class="success"
            >
              done
            </md-icon>
          </slide-y-down-transition>
        </md-field>
        <md-field
          :class="[
            {'md-valid': !errors.has('email') && touched.email},
            {'md-form-group': true},
            {'md-error': errors.has('email')}]"
        >
          <md-icon>email</md-icon>
          <label>Email</label>
          <md-input
            ref="email"
            v-model="email"
            v-validate="modelValidations.email"
            :disabled="authSuccess"
            data-vv-name="email"
            type="text"
            name="email"
            required
          />
          <slide-y-down-transition>
            <md-icon
              v-show="errors.has('email')"
              class="error"
            >
              close
            </md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              v-show="!errors.has('email') && touched.email"
              class="success"
            >
              done
            </md-icon>
          </slide-y-down-transition>
        </md-field>
        <md-field
          :class="[
            {'md-valid': !errors.has('confirmEmail') && touched.confirmEmail},
            {'md-form-group': true},
            {'md-error': errors.has('confirmEmail')}]"
        >
          <md-icon>email</md-icon>
          <label>Confirm Email</label>
          <md-input
            v-model="confirmEmail"
            v-validate="modelValidations.confirmEmail"
            :disabled="authSuccess"
            data-vv-name="confirmEmail"
            data-vv-as="email"
            type="text"
            required
          />
          <slide-y-down-transition>
            <md-icon
              v-show="errors.has('confirmEmail') && touched.confirmEmail"
              class="error"
            >
              close
            </md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              v-show="!errors.has('confirmEmail') && touched.confirmEmail"
              class="success"
            >
              done
            </md-icon>
          </slide-y-down-transition>
        </md-field>
        <!-- username -->
        <md-field
          :class="[
            {'md-valid': !errors.has('username') && touched.username},
            {'md-form-group': true},
            {'md-error': errors.has('username')}]"
        >
          <md-icon>person</md-icon>
          <label>Username</label>
          <md-input
            v-model="username"
            v-validate="modelValidations.username"
            :disabled="authSuccess"
            data-vv-name="username"
            type="text"
            name="username"
            required
          />
          <slide-y-down-transition>
            <md-icon
              v-show="errors.has('username')"
              class="error"
            >
              close
            </md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              v-show="!errors.has('username') && touched.username"
              class="success"
            >
              done
            </md-icon>
          </slide-y-down-transition>
        </md-field>
        <div
          v-if="touched.username && errors.has('username')"
          class="md-layout-item"
        >
          Usernames must contain a minumum of 6 characters and may only contain letters, numbers, dashes "-" underscores "_" and periods "."
        </div>
        <!--Password-->
        <md-field
          :class="[
            {'md-valid': !errors.has('password') && touched.password},
            {'md-error': errors.has('password')}]"
        >
          <md-icon>lock</md-icon>
          <label>Password</label>
          <md-input
            ref="password"
            v-model="password"
            v-validate="modelValidations.password"
            :disabled="authSuccess"
            data-vv-name="password"
            type="password"
            required
          />
          <slide-y-down-transition>
            <md-icon
              v-show="errors.has('password') && touched.password"
              class="error"
            >
              close
            </md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              v-show="!errors.has('password') && touched.password"
              class="success"
            >
              done
            </md-icon>
          </slide-y-down-transition>
        </md-field>
        <md-field
          :class="[
            {'md-valid': !errors.has('confirmPassword') && touched.confirmPass},
            {'md-error': errors.has('confirmPassword')}]"
        >
          <md-icon>lock</md-icon>
          <label>Confirm Password</label>
          <md-input
            v-model="confirmPassword"
            v-validate="modelValidations.confirmPassword"
            :disabled="authSuccess"
            data-vv-name="confirmPassword"
            data-vv-as="password"
            type="password"
            required
          />
          <slide-y-down-transition>
            <md-icon
              v-show="errors.has('confirmPassword') && touched.confirmPassword"
              class="error"
            >
              close
            </md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              v-show="!errors.has('confirmPassword') && touched.confirmPassword"
              class="success"
            >
              done
            </md-icon>
          </slide-y-down-transition>
        </md-field>
        <div class="md-layout-item">
          Passwords must contain a minimum of 8 characters, one lowercase letter, one uppercase letter, and one number.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import swal from 'sweetalert2'

export default {
  name: "AboutStep",

  components: {
    SlideYDownTransition
  },
  props: {
    wizardModel: {
      type: Object,
      required: true
    },
    wizardBody: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // image: '',
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      confirmEmail: '',
      locale: '',
      password: '',
      confirmPassword: '',
      authSuccess: false,
      userInformation: 'User Information',
      touched: {
        firstName: false,
        lastName: false,
        password: false,
        username: false,
        email: false,
        confirmEmail: false,
        confirmPass: false
      },
      modelValidations: {
        firstName: {
          required: true,
          min: 2
        },
        lastName: {
          required: true,
          min: 2
        },
        username: {
          required: true,
          min: 6,
          regex: "^[A-Za-z0-9_@.-]*$"
        },
        email: {
          required: true,
          email: true
        },
        confirmEmail: {
          required: true,
          confirmed: 'email'
        },
        password: {
          required: true,
          min: 8,
          regex: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
        },
        confirmPassword: {
          required: true,
          confirmed: 'password'
        }
      }
    }
  },

  watch: {
    firstName() {
      this.touched.firstName = true
    },
    lastName() {
      this.touched.lastName = true
    },
    username() {
      this.touched.username = true
    },
    email() {
      this.touched.email = true
    },
    confirmEmail() {
      this.touched.confirmEmail = true
    },
    password() {
      this.touched.password = true
    },
    confirmPassword() {
      this.touched.confirmPass = true
    }
  },
  methods: {

    getError(fieldName) {
      return this.errors.first(fieldName)
    },

    validate() {
      return this.$validator.validateAll()
        .then(res => {
          // Nest the sign up authentication result as a promise that evaluates to a boolean [same as validateAll()]
          if (this.authSuccess) {
            return true
          }
          else if (res) {
            return this.signUp()
              .then((data) => { // eslint-disable-line
                this.onSuccess(res);
                return true
              })
              .catch((err) => {
                if (err.code === 'UsernameExistsException') {
                  // username already exists - show field as invalid
                  err.message = 'Sorry, that username already exists!'
                  const field = this.$validator.fields.find({ name: 'username', scope: this.$options.scope });
                  if (!field) return false;
                  this.$validator.errors.add({
                    id: field.id,
                    field: 'username',
                    msg: err.message,
                    scope: this.$options.scope,
                  });

                  field.setFlags({
                    invalid: true,
                    valid: false,
                    validated: true,
                  });
                } else if (err.code === 'UserLambdaValidationException') {
                  // username already exists - show field as invalid
                  err.message = 'Sorry, a user with that email address already exists!  Have you registered already? Go to the Login page!'
                  const field = this.$validator.fields.find({ name: 'email', scope: this.$options.scope });
                  if (!field) return false;
                  this.$validator.errors.add({
                    id: field.id,
                    field: 'email',
                    msg: err.message,
                    scope: this.$options.scope,
                  });

                  field.setFlags({
                    invalid: true,
                    valid: false,
                    validated: true,
                  });
                }
                this.signUpAlert(err.message);
                return false;
              })
          }
          return res
        })
    },

    onSuccess(res) {
      // some tasks once the sign-up api call is completed successfully
      // set username and datetime to localstorage for retrieval if user comes back later via the confirmation code link
      localStorage.username = this.username;
      const codeDate = Date.now();
      localStorage.verificationCodeDate = codeDate;

      // Increment the wizard
      this.$emit('on-validated', res, this.$data);
      this.authSuccess = true;
      this.userInformation = 'User Information Has Been Saved. If you messed up here please refresh the page.';
      this.modelValidations.firstName = null;
    },

    signUpAlert(message) {
      swal.fire({
        title: `<p style="color:red;">Not so fast...</p>`,
        text: message,
        timer: 4000,
        showConfirmButton: false
      })
    },

    signUp() {
      const attributes = {
        email: this.email.toLowerCase(),
        given_name: this.firstName,
        family_name: this.lastName,
        locale: this.locale
      };

      return this.$Auth.signUp({
        username: this.username,
        password: this.password,
        attributes: attributes })
    }
  }
}
</script>
<style>
</style>
