<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-size-100"
      @click="warning"
    >
      <div class="md-title">
        <span
          class="table-title"
          :class="{'text-white': ('complete' in ACTIVE_SCENARIO)}"
        >
          Industrial Activity: {{ devRate | formatNumber }}%
          <md-button
            v-if="showInfoBtn"
            id="MgmtPracticesInfoBtn"
            class="md-icon-button md-fab info-button"
            @click="showPracticesDetails()"
          >
            <md-icon>
              info
            </md-icon>
          </md-button>

          <p v-if="!showInfoBtn">
            {{ mgmtInfo.sliderDescription }}
          </p>
        </span>
      </div>
      <slider
        v-model="devRate"
        :disabled="!allowEdits"
        class="devrate-slider"
      />
    </div>
    <div class="md-layout-item md-size-100">
      <div class="md-title">
        <span
          class="table-title"
          :class="{'text-white': ('complete' in ACTIVE_SCENARIO)}"
        >
          {{ generalTitle }}
        </span>
      </div>
      <div
        class="mgmt-practices-table"
        :class="{'scroll-practices-table': (!rightSidebarMedium && !rightSidebarFullScreen && scrollPractices)}"
      >
        <div
          v-for="practice in mgmtPractices"
          :key="practice.name"
        >
          <mgmt-practice-switch
            :practice="practice"
            :practice-value="getScenarioMgmtPractice(practice.variable)"
            :disabled="!allowEdits || mgmtPracticesDisabled(practice.variable)"
            :scenario-type="scenario.type"
            :show-details-btn="!showInfoBtn"
            :active-practice-description="activePracticeDescription"
            @on-show-description="updateActivePracticeDescription"
          />
        </div>
      </div>
      <technical-doc />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import { debounce } from "debounce";
import { LandTypes } from "./mixins/LandTypes";
import { Scenario } from "./mixins/Scenario";
import { ResponsiveLayoutHelpers } from "@/pages/Dashboard/Components/mixins/ResponsiveLayoutHelpers";
import TechnicalDoc from './TechnicalDoc.vue';
import { Slider } from '@/components';
import MgmtPracticeSwitch from "./MgmtPracticeSwitch";

export default {
  name: "LandPracticesTable",

  components: {
    Slider,
    MgmtPracticeSwitch,
    TechnicalDoc
  },
  mixins: [LandTypes, Scenario, ResponsiveLayoutHelpers], // for saving updated scenario to db

  props: {
    scenario: {
      type: Object,
      required: true,
    },
    regionDevRate: {
      type: Number,
      default: 0
    },
    showInfoBtn: {
      type: Boolean,
      default: false
    },
    scrollPractices: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      mgmtInfo: this.$Region.mgmtInfo,
      mgmtPractices: this.$Region.mgmtPractices,
      devRate: null,
      activePracticeDescription: null // the currently active practice button description
    };
  },

  computed: {
    ...mapGetters([
      types.getters.EXPLORE_MODE
    ]),
    generalTitle() {
      return ('title' in this.$Region.mgmtInfo && this.$Region.mgmtInfo.title !== '') ? this.$Region.mgmtInfo.title : 'Management Practices'
    },
    devRateDisabled() {
      // dev rate can only be controlled on land use scenarios
      if (this.scenario.type !== 'Land Use') {
        return true;
      }
      return false;
    }
  },

  watch: {
    regionDevRate() {
      if (this.scenario.type !== 'Land Use') {
        this.devRate = (this.regionDevRate * 100).toFixed(2);
      }
    },
    devRate(newVal, oldVal) {
      if (oldVal !== null) {
        // only update things after the first init
        if (this.scenario.type === 'Land Use') {
          this.debounceDevRate();
        }
      }
    },
    scenario: {
      handler: function (newVal) {
        if (newVal.devRate === null || (newVal.devRate * 100).toFixed(2) !== this.devRate) {
          this.initDevRate();
        }
      },
      deep: true
    }
  },

  beforeMount() {
    // initialize the dev rate
    this.initDevRate();
  },

  methods: {
    ...mapActions([
      types.actions.UPDATE_ACTIVE_SCENARIO,
      types.actions.CLEAR_INDICATOR_VALS,
      types.actions.TOGGLE_SIDEBAR_CONTENT,
      types.actions.SET_SIDEBAR_CONTENT_TYPE
    ]),

    initDevRate() {
      if (this.scenario.type === 'Land Use') {
        // initialize for new scenarios in create mode
        if (this.scenario.devRate !== null) {
          // load from scenario
          this.devRate = (this.scenario.devRate * 100).toFixed(2); // previously loaded devRate
        }
      } else {
        // get devRate from regionDevRate prop - will be interpolated upstream if possible for BAU and Hist scenarios
        this.devRate = (this.regionDevRate * 100).toFixed(2);
      }
    },
    mgmtPracticesDisabled(mgmtVariable) {
      // management practices buttons are only enabled for scenarios with type = 'land use'
      if (this.scenario.type !== 'Land Use') {
        return true;
      } else if ((!('mgmtPracticesAdvancedActive' in this.$Region)
        || ('mgmtPracticesAdvancedActive' in this.$Region && this.$Region.mgmtPracticesAdvancedActive !== true))
        && mgmtVariable !== 'bestPractices') {
        // only the general best practices button is available
        return true;
      }
      return false;
    },
    getScenarioMgmtPractice(practiceVariable) {
      // check if a management practice is on or off
      if (("management" in this.scenario) && this.scenario.management.includes(practiceVariable)) {
        return true;
      }
      return false;
    },
    debounceDevRate: debounce(function () {
      // scenario updates when devrate slider changes
      if (this.ACTIVE_SCENARIO !== null) {
        this.UPDATE_ACTIVE_SCENARIO({ devRate: (this.devRate / 100) });
      }
      if (this.EXPLORE_MODE !== 'create') {
        // clear indicator data
        this.CLEAR_INDICATOR_VALS();
        // database updates
        this.debounceSaveScenarioUpdatesToDB();
        this.$emit('onDevRateChange'); // notify change to parent
      }
    }, 100),
    debounceSaveScenarioUpdatesToDB: debounce(function () {
      // update existing scenario in db if in view/edit mode
      this.saveScenarioUpdatesToDB(this.ACTIVE_SCENARIO, ["devRate"], false);
    }, 2000),
    warning() {
      if (this.devRateDisabled === true) {
        this.$notify({
          message: `Sorry, industrial activity rate can not be changed for ${this.scenario.type} scenarios.`,
          icon: "error_outline",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "info"
        });
      }
    },
    showPracticesDetails() {
      this.SET_SIDEBAR_CONTENT_TYPE('mgmt');
      this.TOGGLE_SIDEBAR_CONTENT();
    },
    updateActivePracticeDescription(practiceVariable) {
      if (practiceVariable !== null) {
        this.activePracticeDescription = practiceVariable;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .md-switch-label {
  color: $white-color;
  font-weight: 300;
}
/deep/ .md-switch-container {
  background-color: $gray-light !important;
}
.table-title {
  display: block;
  margin-top: 25px;
  color: $gray;
}
.text-white {
  color: $white-color;
}
/deep/ .devrate-slider {
  margin-bottom: 25px;
}
.mgmt-practices-table {
  background-color: $grey-700;
  padding: 10px 10px 0 10px;
  border-radius: 8px;
}
.scroll-practices-table {
  max-height: 300px;
  overflow-y: scroll;
}
.info-button {
  margin-left: 30px;
  margin-top: -16px;

  .md-icon {
    transform: scale(1.2, 1.2);
  }
}
</style>