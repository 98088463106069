/* jshint esversion: 6 */
// indicator equations
// all equations are passed equationArgs that contain the following:
// equationArgs = {
//   scenario: this.scenario, // should be read only... do not update via functions
//   simulationState: this.simulationState, // mutable.... functions can update the data inside the simulationState object
//   helpers: this.$Region.equationHelpers // pass the RegionEquations.helpers functions back so they can be accessed within indicator equations
// };
// all equations should return an array with 2 values:
// [index (values from 0 to 100), actualValue (in units of the indicator)]

const RegionEquations = {
  // HELPER FUNCTIONS
  devRateSliderHelper: function (
    // special function to interpolate devRate for display on UI slider
    scenario,
    simulationState,
    Interpolate
  ) {
    // return the dev rate for display on the UI slider
    return this.equationHelpers.getSimDevRate(
      scenario,
      simulationState,
      Interpolate,
      this.equationHelpers.getCurrentLookupGroupKey,
      this.equationHelpers.getFutureLookupGroupKey
    );
  },
  helpers: {
    // helper functions used by other functions.  The entire helpers object is passed to other functions when needed.
    preCheckAreas: function (simulationState, ltTypes) {
      // validate that the provided set of ltTypes has data available for the simYear
      if (
        "simAreas" in simulationState &&
        ltTypes.every(
          (key) =>
            Object.keys(simulationState.simAreas).includes(key) &&
            simulationState.simYear in simulationState.simAreas[key]
        )
      ) {
        return true;
      }
      return false;
    },
    getNaturalLandCover: function (simulationState) {
      // return the total area of natural land cover types for a single year based on simulationState
      const val =
        simulationState.simAreas.Forest[simulationState.simYear] +
        simulationState.simAreas.Water[simulationState.simYear] +
        simulationState.simAreas.Wetland[simulationState.simYear] +
        simulationState.simAreas["Alpine and Exposed"][
        simulationState.simYear
        ] +
        simulationState.simAreas.Grassland[simulationState.simYear];
      return val;
    },
    getAnthroLandCover: function (simulationState) {
      // return the total area of manmade land cover types for a single year based on simulationState
      const val =
        simulationState.simAreas.Agriculture[simulationState.simYear] +
        simulationState.simAreas.Urban[simulationState.simYear] +
        simulationState.simAreas["Industry and Transportation"][
        simulationState.simYear
        ];
      return val;
    },
    getLTAreaInit: function (simulationState, type) {
      // return the area of a given landscape/cover type
      // expects regionData rnvAreas to contain LT values as a percentage of total area
      return (
        simulationState.regionData.rnvAreas[type] * simulationState.totalArea
      );
    },
    getCurrentLookupGroupKey: function (year, scenarioType) {
      // return the object group to use for current lookup values based on the current simYear
      if (scenarioType === "Business as Usual") {
        year += 1; // bump BAU lookups into the next time period
      }
      if (year < 1980) {
        return "NONE";
      } else if (year >= 1980 && year <= 2020) {
        return "historicValues";
      }
      return "currentValues";
    },
    getFutureLookupGroupKey: function (year, scenarioType) {
      // return the object group to use for future lookup values based on the current simYear
      if (scenarioType === "Business as Usual") {
        year += 1; // bump BAU lookups into the next time period
      }
      if (year < 1980) {
        return "historicValues";
      } else if (year >= 1980 && year <= 2020) {
        return "currentValues";
      }
      return "futureValues";
    },
    getSimDevRate: function (
      scenario,
      simulationState,
      Interpolate,
      getCurrentLookupGroupKey,
      getFutureLookupGroupKey
    ) {
      // return development rate for current simulation year
      let val = 0;
      if (
        "regionData" in simulationState &&
        "currentValues" in simulationState.regionData &&
        "devRate" in simulationState.regionData.currentValues
      ) {
        const interpolator = new Interpolate();
        // default values for historic scenarios
        let from_val = 0,
          to_val = 0,
          from_time = scenario.startTime,
          to_time = scenario.endTime;
        if (["Business as Usual", "Historic"].includes(scenario.type)) {
          // system scenario
          const timePeriod = getCurrentLookupGroupKey(
            simulationState.simYear,
            scenario.type
          );
          if (timePeriod === "NONE") {
            from_val = 0;
            to_val = simulationState.regionData.historicValues.devRate;
            to_time = 1980;
          } else {
            from_val =
              simulationState.regionData[
                getCurrentLookupGroupKey(simulationState.simYear, scenario.type)
              ].devRate;
            from_time = timePeriod === "historicValues" ? 1980 : 2020;
            to_time = timePeriod === "historicValues" ? 2020 : scenario.endTime;
          }
          to_val =
            simulationState.regionData[
              getFutureLookupGroupKey(simulationState.simYear, scenario.type)
            ].devRate;
        } else if (scenario.type == "Land Use") {
          // user land use plan
          from_val = simulationState.regionData.currentValues.devRate;
          to_val = scenario.devRate;
        }
        // when creating a new scenario, startTime and endTime are the same in create wizard... skip interpolating
        if (scenario.startTime === scenario.endTime) {
          val = simulationState.regionData.currentValues.devRate;
        } else {
          val = interpolator
            .from(from_time, to_time)
            .to(from_val, to_val)
            .linear(simulationState.simYear); // even if growth model is exponential, this is an actively changing rate.. linear interpolation makes more sense
        }
      }
      return val;
    },
    getSimPopulation: function (
      scenario,
      simulationState,
      Interpolate,
      getCurrentLookupGroupKey
    ) {
      // return population calculation for current simulation year
      let val = 0;
      if (["Business as Usual", "Historic"].includes(scenario.type)) {
        // interpolate human pop for historic and BAU scenario types
        if (
          getCurrentLookupGroupKey(simulationState.simYear, scenario.type) ==
          "NONE"
        ) {
          // interpolate population based on 0 to historicValues.humanPop
          const interpolator = new Interpolate(),
            from_val = 0,
            to_val = simulationState.regionData.historicValues.humanPop;
          val = interpolator
            .from(scenario.startTime, 1980)
            .to(from_val, to_val)
            .linear(simulationState.simYear);
        } else if (
          getCurrentLookupGroupKey(simulationState.simYear, scenario.type) ==
          "historicValues"
        ) {
          // interpolate based on historicValues.humanPop to currentValues.humanPop
          const interpolator = new Interpolate(),
            from_val = simulationState.regionData.historicValues.humanPop,
            to_val = simulationState.regionData.currentValues.humanPop;
          val = interpolator
            .from(1980, scenario.endTime)
            .to(from_val, to_val)
            .linear(simulationState.simYear);
        } else {
          // interpolate based on currentValues.humanPop to futureValues.humanPop
          const interpolator = new Interpolate(),
            from_val = simulationState.regionData.currentValues.humanPop,
            to_val = simulationState.regionData.futureValues.humanPop;
          val = interpolator
            .from(scenario.startTime, scenario.endTime)
            .to(from_val, to_val)
            .linear(simulationState.simYear);
        }
      } else {
        // calculate based on the difference in urban area from current to future
        if ("futureAreas" in simulationState.regionData) {
          // NEW equation - population based on change compared to BAU expected change.
          // this new equation is intended to deal with differences in population densities for different regions
          // by indexing the population to the BAU futureArea and BAU future pop, we should be able to make indicator values remain consistent
          const popCurrent = simulationState.regionData.currentValues.humanPop,
            popBAUFuture = simulationState.regionData.futureValues.humanPop,
            urbanAreaCurrent =
              simulationState.simAreas.Urban[scenario.startTime],
            urbanAreaBAUFuture = simulationState.regionData.futureAreas.Urban,
            urbanArea = simulationState.simAreas.Urban[simulationState.simYear],
            urbanDensity = scenario.management.includes("urbanDensity");
          let urbanDiff = urbanAreaBAUFuture - urbanAreaCurrent;
          if (urbanDiff == 0) {
            urbanDiff = 0.01; // prevent divide by 0
          }
          val =
            popCurrent +
            ((popBAUFuture - popCurrent) / urbanDiff) *
            (urbanArea - urbanAreaCurrent) *
            (1 + urbanDensity * 0.35);
        } else {
          // OLD equation
          let popCurrent = simulationState.regionData.currentValues.humanPop,
            urbanAreaCurrent =
              simulationState.simAreas.Urban[scenario.startTime];
          const urbanArea =
            simulationState.simAreas.Urban[simulationState.simYear],
            urbanDensity = scenario.management.includes("urbanDensity");
          if (urbanArea !== 0) {
            if (popCurrent === 0) {
              popCurrent = 1; //seed with 1 person
            }
            if (urbanAreaCurrent == 0) {
              urbanAreaCurrent = 0.1; // seed area to prevent divide by 0
            }
            val =
              popCurrent *
              (urbanArea / urbanAreaCurrent) *
              (1 + urbanDensity * 0.35);
          }
        }
      }
      return val;
    },
    getSimEnergy: function (
      scenario,
      simulationState,
      devRate,
      getNaturalLandCover,
      Interpolate,
      getFutureLookupGroupKey
    ) {
      // return energy calculation for current simulation year
      let val = 0;
      if (
        getFutureLookupGroupKey(simulationState.simYear, scenario.type) ==
        "historicValues"
      ) {
        // interpolate value based on 0 to historicValues.energyProd
        const interpolator = new Interpolate(),
          from_val = 0,
          to_val = simulationState.regionData.historicValues.energyProd;
        val = interpolator
          .from(scenario.startTime, 1980)
          .to(from_val, to_val)
          .linear(simulationState.simYear);
      } else if (
        getFutureLookupGroupKey(simulationState.simYear, scenario.type) ==
        "currentValues"
      ) {
        // interpolate value based on historicValues.energyProd to currentValues.energyProd
        const interpolator = new Interpolate(),
          from_val = simulationState.regionData.historicValues.energyProd,
          to_val = simulationState.regionData.currentValues.energyProd;
        val = interpolator
          .from(1980, 2020)
          .to(from_val, to_val)
          .linear(simulationState.simYear);
      } else {
        if (scenario.type === "Business as Usual") {
          // interpolate value based on currentValues.energyProd to futureValues.energyProd
          const interpolator = new Interpolate(),
            from_val = simulationState.regionData.currentValues.energyProd,
            to_val = simulationState.regionData.futureValues.energyProd;
          val = interpolator
            .from(scenario.startTime, scenario.endTime)
            .to(from_val, to_val)
            .linear(simulationState.simYear);
        } else if (scenario.type === "Land Use") {
          // energy production is relative to the BAU future energy prod based on the difference in dev rates and land areas
          let devRateFuture = simulationState.regionData.futureValues.devRate;
          const energyProdFuture =
            simulationState.regionData.futureValues.energyProd,
            agArea =
              simulationState.simAreas.Agriculture[simulationState.simYear],
            natArea = getNaturalLandCover(simulationState),
            agAreaFuture =
              simulationState.simAreas.Agriculture[scenario.endTime],
            forestAreaFuture =
              simulationState.simAreas.Forest[scenario.endTime],
            waterAreaFuture = simulationState.simAreas.Water[scenario.endTime],
            wetlandAreaFuture =
              simulationState.simAreas.Wetland[scenario.endTime],
            shortVegAreaFuture =
              simulationState.simAreas.Grassland[scenario.endTime];
          if (devRateFuture === 0) {
            devRateFuture = 0.01; // prevent divide by 0
          }
          const energyRateReference =
            energyProdFuture /
            (devRateFuture *
              (agAreaFuture +
                forestAreaFuture +
                waterAreaFuture +
                wetlandAreaFuture +
                shortVegAreaFuture));
          const interpolator = new Interpolate(),
            from_val = simulationState.regionData.currentValues.energyProd,
            to_val = energyRateReference * devRate * (agArea + natArea);
          val = interpolator
            .from(scenario.startTime, scenario.endTime)
            .to(from_val, to_val)
            .linear(simulationState.simYear);
        }
      }
      return val;
    },
    getSimTimberProd: function (
      scenario,
      simulationState,
      devRate,
      Interpolate,
      getCurrentLookupGroupKey
    ) {
      // return Timber Production calculation for current simulation year
      let val;
      if (
        getCurrentLookupGroupKey(simulationState.simYear, scenario.type) ==
        "NONE"
      ) {
        // interpolate value based on 0 to historicValues.timberProd
        const interpolator = new Interpolate(),
          from_val = 0,
          to_val = simulationState.regionData.historicValues.timberProd;
        val = interpolator
          .from(scenario.startTime, 1980)
          .to(from_val, to_val)
          .linear(simulationState.simYear);
      } else if (
        getCurrentLookupGroupKey(simulationState.simYear, scenario.type) ==
        "historicValues"
      ) {
        // interpolate value based on historicValues.timberProd to currentValues.timberProd
        const interpolator = new Interpolate(),
          from_val = simulationState.regionData.historicValues.timberProd,
          to_val = simulationState.regionData.currentValues.timberProd;
        val = interpolator
          .from(1980, 2020)
          .to(from_val, to_val)
          .linear(simulationState.simYear);
      } else {
        // calculate based on the relative dev rate and forest area compared to the current dev rate, forest prod and forest area
        const devRateCurrent = simulationState.regionData.currentValues.devRate,
          forestArea = simulationState.simAreas.Forest[simulationState.simYear],
          forestAreaCurrent =
            simulationState.simAreas.Forest[scenario.startTime],
          timberProdCurrent =
            simulationState.regionData.currentValues.timberProd;

        // calculate a forestry rate coefficient based on the specific regiondata
        const forestRate =
          timberProdCurrent / (devRateCurrent * forestAreaCurrent);
        val = forestRate * devRate * forestArea;
      }
      return val;
    },
    getSimFishCommunityHealth: function (
      scenario,
      simulationState,
      getSimDevRate,
      getNaturalLandCover,
      getAnthroLandCover,
      Interpolate,
      getCurrentLookupGroupKey,
      getFutureLookupGroupKey,
    ) {
      // return Fish community health indicator value
      let fishCommunityHealth = 100;
      const devRate = getSimDevRate(
        scenario,
        simulationState,
        Interpolate,
        getCurrentLookupGroupKey,
        getFutureLookupGroupKey
      ),
        decreaseIndFootprint = scenario.management.includes(
          "decreaseIndFootprint"
        ),
        natArea = getNaturalLandCover(simulationState),
        anthroArea = getAnthroLandCover(simulationState);
      // climate change disturbance due to temperature
      let climateDist = 1,
        climateDistFuture = 1;
      if (
        scenario.climate !== "none" &&
        scenario.type !== "Historic" &&
        scenario.startTime !== scenario.endTime
      ) {
        const climateDistKey = `fishDist_${scenario.climate.toLowerCase()}`;
        if (climateDistKey in simulationState.regionData.futureValues) {
          climateDistFuture =
            simulationState.regionData.futureValues[climateDistKey];
          const interpolator = new Interpolate();
          climateDist = interpolator
            .from(scenario.startTime, scenario.endTime)
            .to(1, climateDistFuture)
            .linear(simulationState.simYear);
        }
      }
      fishCommunityHealth =
        climateDist *
        100 *
        ((0.2 * anthroArea +
          natArea *
          (devRate * (0.2 +
            decreaseIndFootprint * 0.5) +
            (1 - devRate) * 0.9)) /
          0.9 /
          simulationState.totalArea);
      return fishCommunityHealth;
    },
    // INDIGENOUS INDICATOR HELPER FUNCTIONS
    getSimTLUAccess: function (
      scenario,
      simulationState,
      getNaturalLandCover,
      Interpolate,
      getCurrentLookupGroupKey
    ) {
      // return TLU access calculation for current simulation year
      // TLU accessibility = if(A + S>0) or (PA>0), 0, 1), ---- NOTE this is cell level, not region level equation
      // where A is the agricultural land type in Alberta Tomorrow, S is the settlement land type in Alberta Tomorrow, and PA is area within national parrks, provincial parks, provincial recreation areas, ecological reserves, and wilderness areas.
      // the rough equivalent to the above equation is natural areas - protected areas
      // this assumes that the (A + S>0) condition should basically be looking for natural areas (so no ag, settlement or industry), and it should further reduce that amount by the protected areas
      // the additional assumption is that all protected areas are within natural areas
      let val = 0; // val is determining protectedAreas value
      const key = getCurrentLookupGroupKey(simulationState.simYear, scenario.type)
      if (key == "NONE") {
        // interpolate value based on 0 to historicValues.protectedAreas
        const interpolator = new Interpolate(),
          from_val = 0,
          to_val = simulationState.regionData.historicValues.protectedAreas;
        val = interpolator
          .from(scenario.startTime, 1980)
          .to(from_val, to_val)
          .linear(simulationState.simYear);
      } else if (key == "historicValues") {
        // interpolate value based on historicValues.tluAccess to currentValues.tluAccess
        const interpolator = new Interpolate(),
          from_val = simulationState.regionData.historicValues.protectedAreas,
          to_val = simulationState.regionData.currentValues.protectedAreas;
        val = interpolator
          .from(1980, 2020)
          .to(from_val, to_val)
          .linear(simulationState.simYear);
      } else {
        // interpolate value based on currentValues.protectedAreas to futureValues.protectedAreas
        const interpolator = new Interpolate(),
          from_val = simulationState.regionData.currentValues.protectedAreas,
          to_val = simulationState.regionData.futureValues.protectedAreas;
        val = interpolator
          .from(scenario.startTime, scenario.endTime)
          .to(from_val, to_val)
          .linear(simulationState.simYear);
      }
      const natArea = getNaturalLandCover(simulationState);
      const TLUAccess = (100 * natArea / simulationState.totalArea) - val;
      return TLUAccess / 100; // return as a decimal percentage
    },
    getSimMooseHabitat: function (
      scenario,
      simulationState,
      getSimDevRate,
      Interpolate,
      getCurrentLookupGroupKey,
      getFutureLookupGroupKey
    ) {
      // return calculation for current simulation year
      let val = 0; // val is determining mooseHabAvail value
      const key = getCurrentLookupGroupKey(simulationState.simYear, scenario.type);
      if (["Business as Usual", "Historic"].includes(scenario.type)) {
        // system scenario
        if (key == "NONE") {
          // interpolate value based on hist1910Values.mooseHabAvail to historicValues.mooseHabAvail
          const interpolator = new Interpolate(),
            from_val = simulationState.regionData.hist1910Values.mooseHabAvail,
            to_val = simulationState.regionData.historicValues.mooseHabAvail;
          val = interpolator
            .from(scenario.startTime, 1980)
            .to(from_val, to_val)
            .linear(simulationState.simYear);
        } else if (key == "historicValues") {
          // interpolate value based on historicValues.tluAccess to currentValues.tluAccess
          const interpolator = new Interpolate(),
            from_val = simulationState.regionData.historicValues.mooseHabAvail,
            // for current and future values, calculate habitat availability = Moose habitat availability AB = Moose habitat value AB * (1 – Moose use reduction AB)
            to_val = simulationState.regionData.currentValues.mooseHabValue * (1 - simulationState.regionData.currentValues.mooseUseReduc);
          val = interpolator
            .from(1980, 2020)
            .to(from_val, to_val)
            .linear(simulationState.simYear);
        } else {
          // interpolate value based on currentValues.mooseHabAvail to futureValues.mooseHabAvail
          const interpolator = new Interpolate(),
            // for current and future values, calculate habitat availability = Moose habitat availability AB = Moose habitat value AB * (1 – Moose use reduction AB)
            from_val = simulationState.regionData.currentValues.mooseHabValue * (1 - simulationState.regionData.currentValues.mooseUseReduc),
            to_val = simulationState.regionData.futureValues.mooseHabValue * (1 - simulationState.regionData.futureValues.mooseUseReduc);
          val = interpolator
            .from(scenario.startTime, scenario.endTime)
            .to(from_val, to_val)
            .linear(simulationState.simYear);
        }
      } else if (scenario.type == "Land Use") {
        // user land use plan
        if (scenario.startTime === scenario.endTime) {
          // scenario create/update goals
          val = simulationState.regionData.currentValues.mooseHabValue * (1 - simulationState.regionData.currentValues.mooseUseReduc);
        } else {
          // calculate scenario values
          // calculate habitat value: Moose habitat value AB UD =Moose habitat value AB BAU *(F UD *0.7 + W UD *0.4)/(F BAU *0.7 + W BAU *0.4),
          const interpolator = new Interpolate();
          // habitatValBau
          let from_val = simulationState.regionData.currentValues.mooseHabValue,
            to_val = simulationState.regionData.futureValues.mooseHabValue;
          const habitatValBau = interpolator
            .from(scenario.startTime, scenario.endTime)
            .to(from_val, to_val)
            .linear(simulationState.simYear);
          const Forest = simulationState.simAreas.Forest[simulationState.simYear];
          // ForestBAU
          from_val = simulationState.simAreas.Forest[scenario.startTime];
          to_val = simulationState.regionData.futureAreas.Forest;
          const ForestBAU = interpolator
            .from(scenario.startTime, scenario.endTime)
            .to(from_val, to_val)
            .linear(simulationState.simYear);
          const Wetland = simulationState.simAreas.Wetland[simulationState.simYear];
          // WetlandBAU
          from_val = simulationState.simAreas.Wetland[scenario.startTime];
          to_val = simulationState.regionData.futureAreas.Wetland;
          const WetlandBAU = interpolator
            .from(scenario.startTime, scenario.endTime)
            .to(from_val, to_val)
            .linear(simulationState.simYear);
          const habitatVal = habitatValBau * (Forest * 0.7 + Wetland * 0.4) / (ForestBAU * 0.7 + WetlandBAU * 0.4);
          // devRateBAU
          from_val = simulationState.regionData.currentValues.devRate;
          to_val = simulationState.regionData.futureValues.devRate;
          const devRateBAU = interpolator
            .from(scenario.startTime, scenario.endTime)
            .to(from_val, to_val)
            .linear(simulationState.simYear);
          const devRateUD = getSimDevRate(
            scenario,
            simulationState,
            Interpolate,
            getCurrentLookupGroupKey,
            getFutureLookupGroupKey
          );
          // calculate moose use reduction: Moose use reduction AB UD =Moose use reduction BAU *(D UD *(1-0.2*B))/D BAU ,
          // where B is best practices decreased ind footprint
          const decreaseIndFootprint = scenario.management.includes("decreaseIndFootprint");
          // MooseUseReductionBAU
          from_val = simulationState.regionData.currentValues.mooseUseReduc;
          to_val = simulationState.regionData.futureValues.mooseUseReduc;
          const MooseUseReductionBAU = interpolator
            .from(scenario.startTime, scenario.endTime)
            .to(from_val, to_val)
            .linear(simulationState.simYear);
          // console.log(`devRateUD: ${devRateUD}, devRateBAU: ${devRateBAU}`);
          const MooseUseReduction = MooseUseReductionBAU * devRateUD * (1 - 0.2 * decreaseIndFootprint) / devRateBAU;
          // console.log(`mooseUseReducUD: ${MooseUseReduction}, mooseUseReducBAU: ${MooseUseReductionBAU}`);
          // Moose habitat availability AB UD = Moose habitat value AB UD * (1 - Moose use reduction AB UD ).
          val = habitatVal * (1 - MooseUseReduction)
          // normalize habitat availability against ALCES equation... difference in calculation due to not having cell-level calculations
          // console.log(`user-defined land use scenario moose habitat before normalization: ${val}`)
        }
      }
      // console.log(`mooseHabAvail: ${val}`);
      // normalize so that 1910 value is the reference for 100% (represented as a decimal)
      val = val / simulationState.regionData.hist1910Values.mooseHabAvail
      // console.log(`normalized mooseHabAvail: ${val}`);
      return val;
    },
  },
  // END HELPER FUNCTIONS
  NaturalLandscapes: function (equationArgs) {
    // return Natural Landscapes indicator value
    // parse out the passed args
    const scenario = equationArgs.scenario,
      simulationState = equationArgs.simulationState,
      helpers = equationArgs.helpers;
    let naturalLandscapes = 100;
    // make sure the equation variables have been loaded by ajax from region geojson
    const req_lts = [
      "Forest",
      "Water",
      "Wetland",
      "Grassland",
      "Alpine and Exposed",
    ];
    if (
      simulationState !== undefined &&
      "simAreas" in simulationState &&
      helpers.preCheckAreas(simulationState, req_lts)
    ) {
      const devRate = helpers.getSimDevRate(
        scenario,
        simulationState,
        helpers.Interpolate,
        helpers.getCurrentLookupGroupKey,
        helpers.getFutureLookupGroupKey
      ),
        decreaseIndFootprint = scenario.management.includes(
          "decreaseIndFootprint"
        ),
        natArea = helpers.getNaturalLandCover(simulationState);
      naturalLandscapes =
        100 *
        (((1 - devRate * (1 - decreaseIndFootprint * 0.2)) * natArea) /
          simulationState.totalArea);
    }
    return [naturalLandscapes, naturalLandscapes];
  },
  CaribouHabitat: function (equationArgs) {
    // return Caribou Habitat indicator value
    // parse out the passed args
    const scenario = equationArgs.scenario,
      simulationState = equationArgs.simulationState,
      helpers = equationArgs.helpers;
    let caribouHabitat = 100;
    // make sure the equation variables have been loaded by ajax from region geojson
    const req_lts = [
      "Forest",
      "Water",
      "Wetland",
      "Grassland",
      "Alpine and Exposed",
      "Agriculture",
      "Urban",
      "Industry and Transportation",
    ];
    if (
      simulationState !== undefined &&
      helpers.preCheckAreas(simulationState, req_lts)
    ) {
      const devRate = helpers.getSimDevRate(
        scenario,
        simulationState,
        helpers.Interpolate,
        helpers.getCurrentLookupGroupKey,
        helpers.getFutureLookupGroupKey
      ),
        decreaseIndFootprint = scenario.management.includes(
          "decreaseIndFootprint"
        ),
        natArea = helpers.getNaturalLandCover(simulationState),
        anthroArea = helpers.getAnthroLandCover(simulationState),
        rd_arr = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100], // range disturbance array
        pp_arr = [100, 90, 78, 66, 50, 35, 25, 15, 8, 4, 0], // probability of persistence array
        growthModel = "linear";
      // climate change related natural disturbance
      // get natural disturbance coefficient if available
      let naturalDist = 0;
      const naturalDistCurrent =
        "caribouDist_none" in simulationState.regionData.currentValues
          ? simulationState.regionData.currentValues.caribouDist_none
          : 0;
      let naturalDistNow = 0;
      if (scenario.climate !== "none" && scenario.type !== "Historic") {
        const naturalDistKey = `caribouDist_${scenario.climate.toLowerCase()}`;
        if (naturalDistKey in simulationState.regionData.futureValues) {
          naturalDist = simulationState.regionData.futureValues[naturalDistKey];
        }
      } else if (
        "caribouDist_none" in simulationState.regionData.currentValues
      ) {
        if (
          scenario.type === "Historic" ||
          (scenario.type !== "Historic" && scenario.climate === "none")
        ) {
          naturalDist =
            simulationState.regionData.currentValues.caribouDist_none;
        }
      }
      // when creating a new scenario, startTime and endTime are the same in create wizard... skip interpolating
      if (scenario.startTime === scenario.endTime) {
        naturalDistNow = naturalDistCurrent;
      } else {
        const interpolator = new helpers.Interpolate();
        naturalDistNow = interpolator
          .from(scenario.startTime, scenario.endTime)
          .to(naturalDistCurrent, naturalDist)
          .linear(simulationState.simYear);
      }
      // equation step 1
      const rangeDisturbance = Math.min(
        100 *
        ((anthroArea + devRate * (1 - decreaseIndFootprint * 0.2) * natArea) /
          simulationState.totalArea) +
        naturalDistNow,
        100
      );
      // equation step 2
      const rd_to_pp = new helpers.Interpolate();
      for (let i = 1, len = rd_arr.length; i < len; i++) {
        const item = rd_arr[i];
        if (rangeDisturbance <= item) {
          // found range and index for other range
          rd_to_pp.to(pp_arr[i - 1], pp_arr[i]);
          // rd_to_pp.to(pp_arr[i], pp_arr[i - 1]);
          // inputs are min, max, value, to linear interpolate
          rd_to_pp.from(rd_arr[i - 1], item);
          // rd_to_pp.from(item, rd_arr[i - 1]);

          caribouHabitat = rd_to_pp[growthModel](rangeDisturbance);
          break;
        }
      }
    }
    return [caribouHabitat, caribouHabitat];
  },
  GrizzlyHabitat: function (equationArgs) {
    // return grizzly habitat indicator value
    // parse out the passed args
    const scenario = equationArgs.scenario,
      simulationState = equationArgs.simulationState,
      helpers = equationArgs.helpers;
    let grizzlyHabitat = 100;
    // make sure the equation variables have been loaded by ajax from region geojson
    const req_lts = [
      "Forest",
      "Water",
      "Wetland",
      "Grassland",
      "Alpine and Exposed",
      "Agriculture",
      "Urban",
      "Industry and Transportation",
    ];
    if (
      simulationState !== undefined &&
      helpers.preCheckAreas(simulationState, req_lts)
    ) {
      const devRate = helpers.getSimDevRate(
        scenario,
        simulationState,
        helpers.Interpolate,
        helpers.getCurrentLookupGroupKey,
        helpers.getFutureLookupGroupKey
      ),
        decreaseIndFootprint = scenario.management.includes(
          "decreaseIndFootprint"
        ),
        natArea = helpers.getNaturalLandCover(simulationState),
        agArea = simulationState.simAreas.Agriculture[simulationState.simYear],
        settlementArea =
          simulationState.simAreas.Urban[simulationState.simYear] +
          simulationState.simAreas["Industry and Transportation"][
          simulationState.simYear
          ];
      // equation step 1
      const exposureIndex =
        1.0598 +
        ((100 * agArea) / simulationState.totalArea) *
        (0.0057 - decreaseIndFootprint * 0.002) +
        (((100 * devRate * natArea) / simulationState.totalArea) * 0.0055 -
          decreaseIndFootprint * 0.0026) +
        ((100 * settlementArea) / simulationState.totalArea) *
        (0.0688 - decreaseIndFootprint * 0.0322);
      // equation step 2
      grizzlyHabitat = 100 * (Math.max(0, 1.5 - exposureIndex) / 0.4402);
    }
    return [grizzlyHabitat, grizzlyHabitat];
  },
  FishCommunityHealth: function (equationArgs) {
    // return Fish community health indicator value
    // parse out the passed args
    const scenario = equationArgs.scenario,
      simulationState = equationArgs.simulationState,
      helpers = equationArgs.helpers;
    let fishCommunityHealth = 100;
    // make sure the equation variables have been loaded by ajax from region geojson
    const req_lts = [
      "Forest",
      "Water",
      "Wetland",
      "Grassland",
      "Alpine and Exposed",
      "Agriculture",
      "Urban",
      "Industry and Transportation",
    ];
    if (
      simulationState !== undefined &&
      helpers.preCheckAreas(simulationState, req_lts)
    ) {
      fishCommunityHealth = helpers.getSimFishCommunityHealth(
        scenario,
        simulationState,
        helpers.getSimDevRate,
        helpers.getNaturalLandCover,
        helpers.getAnthroLandCover,
        helpers.Interpolate,
        helpers.getCurrentLookupGroupKey,
        helpers.getFutureLookupGroupKey
      )
    }
    return [fishCommunityHealth, fishCommunityHealth]; // getSimFishCommunityHealth is returned as a percentage between 0-100
  },
  WaterQuality: function (equationArgs) {
    // return Water quality indicator value
    // parse out the passed args
    const scenario = equationArgs.scenario,
      simulationState = equationArgs.simulationState,
      helpers = equationArgs.helpers;
    let waterQuality = 100;
    // make sure the equation variables have been loaded by ajax from region geojson
    const req_lts = [
      "Forest",
      "Grassland",
      "Agriculture",
      "Urban",
      "Industry and Transportation",
    ];
    if (
      simulationState !== undefined &&
      helpers.preCheckAreas(simulationState, req_lts)
    ) {
      const agFertilizer = scenario.management.includes("agFertilizer"),
        forestAreaInit = helpers.getLTAreaInit(simulationState, "Forest"),
        shortVegAreaInit = helpers.getLTAreaInit(simulationState, "Grassland"),
        forestArea = simulationState.simAreas.Forest[simulationState.simYear],
        shortVegArea =
          simulationState.simAreas.Grassland[simulationState.simYear],
        agArea = simulationState.simAreas.Agriculture[simulationState.simYear],
        urbanArea = simulationState.simAreas.Urban[simulationState.simYear],
        industryArea =
          simulationState.simAreas["Industry and Transportation"][
          simulationState.simYear
          ];
      waterQuality =
        100 *
        ((0.228 * forestAreaInit + 0.05 * shortVegAreaInit) /
          (0.228 * forestArea +
            0.05 * shortVegArea +
            (0.603 * agArea) / (1 + agFertilizer) +
            0.831 * (urbanArea + industryArea)));
      waterQuality = Math.min(waterQuality, 100);
    }
    return [waterQuality, waterQuality];
  },
  BioticCarbon: function (equationArgs) {
    // return Biotic carbon indicator value
    // parse out the passed args
    const scenario = equationArgs.scenario,
      simulationState = equationArgs.simulationState,
      helpers = equationArgs.helpers;
    let bioticCarbon = 100;
    // make sure the equation variables have been loaded by ajax from region geojson
    const req_lts = ["Water", "Wetland", "Forest", "Grassland", "Agriculture"];
    if (
      simulationState !== undefined &&
      helpers.preCheckAreas(simulationState, req_lts)
    ) {
      const devRate = helpers.getSimDevRate(
        scenario,
        simulationState,
        helpers.Interpolate,
        helpers.getCurrentLookupGroupKey,
        helpers.getFutureLookupGroupKey
      ),
        forestAreaInit = helpers.getLTAreaInit(simulationState, "Forest"),
        shortVegAreaInit = helpers.getLTAreaInit(simulationState, "Grassland"),
        wetlandAreaInit = helpers.getLTAreaInit(simulationState, "Wetland"),
        wetlandArea = simulationState.simAreas.Wetland[simulationState.simYear],
        forestArea = simulationState.simAreas.Forest[simulationState.simYear],
        shortVegArea =
          simulationState.simAreas.Grassland[simulationState.simYear],
        agArea = simulationState.simAreas.Agriculture[simulationState.simYear];
      bioticCarbon =
        100 *
        ((1332 * wetlandArea +
          137 * shortVegArea +
          171 * (1 - devRate * 0.09) * forestArea +
          97 * agArea) /
          (1332 * wetlandAreaInit +
            137 * shortVegAreaInit +
            171 * forestAreaInit));
      bioticCarbon = Math.min(bioticCarbon, 100);
    }
    return [bioticCarbon, bioticCarbon];
  },
  GreenhouseGasses: function (equationArgs) {
    // return GHG indicator value
    // parse out the passed args
    const scenario = equationArgs.scenario,
      simulationState = equationArgs.simulationState,
      helpers = equationArgs.helpers;
    let GHGsIndex = 0,
      GHGs = 0;
    // make sure the equation variables have been loaded by ajax from region geojson
    const req_lts = [
      "Agriculture",
      "Forest",
      "Water",
      "Wetland",
      "Grassland",
      "Alpine and Exposed", // getSimEnergy requires these LT's
      "Urban", // getSimPopulation requires these LT's
    ];
    if (
      simulationState !== undefined &&
      helpers.preCheckAreas(simulationState, req_lts)
    ) {
      const devRate = helpers.getSimDevRate(
        scenario,
        simulationState,
        helpers.Interpolate,
        helpers.getCurrentLookupGroupKey,
        helpers.getFutureLookupGroupKey
      ),
        agArea = simulationState.simAreas.Agriculture[simulationState.simYear],
        humanPop = helpers.getSimPopulation(
          scenario,
          simulationState,
          helpers.Interpolate,
          helpers.getCurrentLookupGroupKey
        ),
        energyProd = helpers.getSimEnergy(
          scenario,
          simulationState,
          devRate,
          helpers.getNaturalLandCover,
          helpers.Interpolate,
          helpers.getFutureLookupGroupKey
        ),
        timberProd = helpers.getSimTimberProd(
          scenario,
          simulationState,
          devRate,
          helpers.Interpolate,
          helpers.getCurrentLookupGroupKey
        ),
        BP1 = scenario.management.includes("increasedRenewables"),
        BP2 = scenario.management.includes("coalPhaseOut"),
        BP3 = scenario.management.includes("electricConservation"),
        BP4 = scenario.management.includes("urbanDensity"),
        BP5 = scenario.management.includes("useEVs"),
        BP6 = scenario.management.includes("carbonCapture"),
        agAreaCurrent =
          simulationState.simAreas.Agriculture[scenario.startTime],
        humanPopCurrent = simulationState.regionData.currentValues.humanPop,
        timberProdCurrent = simulationState.regionData.currentValues.timberProd,
        energyProdCurrent = simulationState.regionData.currentValues.energyProd;
      let GHGsCurrent =
        1.66 * agAreaCurrent +
        22.91 * humanPopCurrent +
        0.019 * energyProdCurrent +
        0.062 * timberProdCurrent;
      if (GHGsCurrent === 0) {
        GHGsCurrent = 0.01; // prevent divide by 0
      }
      GHGs =
        1.66 * agArea +
        0.062 * timberProd +
        humanPop *
        ((10.58 - BP2 * 3.25) * (1 - BP1 * 0.203 - BP3 * 0.086) +
          4.55 * (1 - 0.086 * BP3) +
          7.34 * (1 - 0.05 * BP4 - 0.12 * BP5) +
          0.45) +
        0.019 * energyProd * (1 - BP6 * 0.36);
      GHGsIndex = Math.min(100, 100 * (GHGs / (2 * GHGsCurrent)));
    }
    return [GHGsIndex, GHGs];
  },
  HumanPopulation: function (equationArgs) {
    // return human population indicator value
    // parse out the passed args
    const scenario = equationArgs.scenario,
      simulationState = equationArgs.simulationState,
      helpers = equationArgs.helpers;
    let humanPopIndex = 0,
      humanPop = 0;
    const req_lts = [
      "Urban", // getSimPopulation requires these LT's
    ];
    if (
      simulationState !== undefined &&
      helpers.preCheckAreas(simulationState, req_lts)
    ) {
      let popCurrent = simulationState.regionData.currentValues.humanPop;
      if (popCurrent === 0) {
        popCurrent = 1; // seed initial population
      }
      humanPop = helpers.getSimPopulation(
        scenario,
        simulationState,
        helpers.Interpolate,
        helpers.getCurrentLookupGroupKey
      );
      humanPopIndex = Math.min(100, 100 * (humanPop / (2 * popCurrent)));
    }
    return [humanPopIndex, humanPop];
  },
  GrossDomesticProduct: function (equationArgs) {
    // return GDP indicator value
    // parse out the passed args
    const scenario = equationArgs.scenario,
      simulationState = equationArgs.simulationState,
      helpers = equationArgs.helpers;
    let GDPindex = 0,
      GDP = 0;
    // make sure the equation variables have been loaded by ajax from region geojson
    const req_lts = [
      "Agriculture",
      "Forest",
      "Water",
      "Wetland",
      "Grassland",
      "Alpine and Exposed", // getSimEnergy requires these LT's
    ];
    if (
      simulationState !== undefined &&
      helpers.preCheckAreas(simulationState, req_lts)
    ) {
      const devRate = helpers.getSimDevRate(
        scenario,
        simulationState,
        helpers.Interpolate,
        helpers.getCurrentLookupGroupKey,
        helpers.getFutureLookupGroupKey
      ),
        agArea = simulationState.simAreas.Agriculture[simulationState.simYear],
        timberProd = helpers.getSimTimberProd(
          scenario,
          simulationState,
          devRate,
          helpers.Interpolate,
          helpers.getCurrentLookupGroupKey
        ),
        energyProd = helpers.getSimEnergy(
          scenario,
          simulationState,
          devRate,
          helpers.getNaturalLandCover,
          helpers.Interpolate,
          helpers.getFutureLookupGroupKey
        ),
        timberProdCurrent = simulationState.regionData.currentValues.timberProd,
        energyProdCurrent = simulationState.regionData.currentValues.energyProd;
      let agAreaCurrent =
        simulationState.simAreas.Agriculture[scenario.startTime];
      if (scenario.type === "Historic") {
        agAreaCurrent = simulationState.simAreas.Agriculture[scenario.endTime];
      }
      const GDPCurrent =
        292.82 * agAreaCurrent +
        27.5 * timberProdCurrent +
        11.29 * energyProdCurrent;
      GDP = 292.82 * agArea + 27.5 * timberProd + 11.29 * energyProd;
      GDPindex = Math.min(100 * (GDP / (2 * GDPCurrent)), 100);
    }
    return [GDPindex, GDP];
  },
  HydrocarbonProduction: function (equationArgs) {
    // return hydrocarbon production indicator value
    // parse out the passed args
    const scenario = equationArgs.scenario,
      simulationState = equationArgs.simulationState,
      helpers = equationArgs.helpers;
    let hydroCarbonProdIndex = 0,
      energyProd = 0;
    const req_lts = [
      "Agriculture",
      "Forest",
      "Water",
      "Wetland",
      "Grassland",
      "Alpine and Exposed", // getSimEnergy requires these LT's
    ];
    if (
      simulationState !== undefined &&
      helpers.preCheckAreas(simulationState, req_lts)
    ) {
      const devRate = helpers.getSimDevRate(
        scenario,
        simulationState,
        helpers.Interpolate,
        helpers.getCurrentLookupGroupKey,
        helpers.getFutureLookupGroupKey
      );
      let energyProdCurrent =
        simulationState.regionData.currentValues.energyProd;
      if (energyProdCurrent === 0) {
        energyProdCurrent = 0.001; // prevent divide by 0
      }
      energyProd = helpers.getSimEnergy(
        scenario,
        simulationState,
        devRate,
        helpers.getNaturalLandCover,
        helpers.Interpolate,
        helpers.getFutureLookupGroupKey
      );
      hydroCarbonProdIndex = Math.min(
        100,
        100 * (energyProd / (2 * energyProdCurrent))
      );
      return [+hydroCarbonProdIndex.toFixed(2), +energyProd.toFixed(2)];
    }
    return [hydroCarbonProdIndex, energyProd];
  },
  TimberProduction: function (equationArgs) {
    // return timber production indicator value
    // parse out the passed args
    const scenario = equationArgs.scenario,
      simulationState = equationArgs.simulationState,
      helpers = equationArgs.helpers;
    let timberProdIndex = 0,
      timberProd = 0;
    // make sure the equation variables have been loaded by ajax from region geojson
    const req_lts = ["Forest"];
    if (
      simulationState !== undefined &&
      helpers.preCheckAreas(simulationState, req_lts)
    ) {
      const devRate = helpers.getSimDevRate(
        scenario,
        simulationState,
        helpers.Interpolate,
        helpers.getCurrentLookupGroupKey,
        helpers.getFutureLookupGroupKey
      );
      let timberProdCurrent =
        simulationState.regionData.currentValues.timberProd;
      if (timberProdCurrent === 0) {
        timberProdCurrent = 0.001; //prevent divide by 0
      }
      timberProd = helpers.getSimTimberProd(
        scenario,
        simulationState,
        devRate,
        helpers.Interpolate,
        helpers.getCurrentLookupGroupKey
      );
      timberProdIndex = Math.min(
        100,
        (100 * timberProd) / (2 * timberProdCurrent)
      );
    }
    return [timberProdIndex, timberProd];
  },
  AgriculturalProduction: function (equationArgs) {
    // return agricultural production indicator value
    // parse out the passed args
    const scenario = equationArgs.scenario,
      simulationState = equationArgs.simulationState,
      helpers = equationArgs.helpers;
    let agProdIndex = 0,
      agProd = 0;
    // make sure the equation variables have been loaded by ajax from region geojson
    const req_lts = ["Agriculture"];
    if (
      simulationState !== undefined &&
      helpers.preCheckAreas(simulationState, req_lts)
    ) {
      const agArea =
        simulationState.simAreas.Agriculture[simulationState.simYear];
      let agAreaCurrent =
        simulationState.simAreas.Agriculture[scenario.startTime];
      if (scenario.type === "Historic") {
        agAreaCurrent = simulationState.simAreas.Agriculture[scenario.endTime];
      }
      if (agAreaCurrent === 0) {
        agAreaCurrent = 0.001; //prevent divide by 0
      }
      agProd = 4250842 * agArea; //kcal per ha * ha
      agProdIndex = Math.min(100, 100 * (agArea / (2 * agAreaCurrent)));
    }
    return [agProdIndex, agProd];
  },
  WaterConsumption: function (equationArgs) {
    // return water consumption indicator value
    // parse out the passed args
    const scenario = equationArgs.scenario,
      simulationState = equationArgs.simulationState,
      helpers = equationArgs.helpers;
    let waterConsumptionIndex = 0,
      waterConsumption = 0;
    const req_lts = [
      "Agriculture",
      "Forest",
      "Water",
      "Wetland",
      "Grassland",
      "Alpine and Exposed", // getSimEnergy requires these LT's
      "Urban", // getSimPopulation requires
    ];
    if (
      simulationState !== undefined &&
      helpers.preCheckAreas(simulationState, req_lts)
    ) {
      const agArea =
        simulationState.simAreas.Agriculture[simulationState.simYear],
        waterConsAg = scenario.management.includes("waterConservationAg"),
        waterConsMuni = scenario.management.includes("waterConservationMuni"),
        waterConsEnergy = scenario.management.includes(
          "waterConservationEnergy"
        ),
        devRate = helpers.getSimDevRate(
          scenario,
          simulationState,
          helpers.Interpolate,
          helpers.getCurrentLookupGroupKey,
          helpers.getFutureLookupGroupKey
        ),
        humanPop = helpers.getSimPopulation(
          scenario,
          simulationState,
          helpers.Interpolate,
          helpers.getCurrentLookupGroupKey
        ),
        energyProd = helpers.getSimEnergy(
          scenario,
          simulationState,
          devRate,
          helpers.getNaturalLandCover,
          helpers.Interpolate,
          helpers.getFutureLookupGroupKey
        ),
        popCurrent = simulationState.regionData.currentValues.humanPop,
        energyProdCurrent = simulationState.regionData.currentValues.energyProd;
      let agAreaCurrent =
        simulationState.simAreas.Agriculture[scenario.startTime];
      if (scenario.type === "Historic") {
        agAreaCurrent = simulationState.simAreas.Agriculture[scenario.endTime];
      }
      let waterConsumptionCurrent =
        175.78 * agAreaCurrent +
        31.23 * popCurrent +
        0.03544 * energyProdCurrent;
      if (waterConsumptionCurrent === 0) {
        waterConsumptionCurrent = 0.001; // prevent divide by 0
      }
      // todo interpolate bestPractices
      // climate change impacts on ag water use
      let climChangeAg = 1;
      if (
        scenario.climate !== "none" &&
        scenario.type !== "Historic" &&
        scenario.startTime !== scenario.endTime
      ) {
        const interpolator = new helpers.Interpolate();
        climChangeAg = interpolator
          .from(scenario.startTime, scenario.endTime)
          .to(1, 1.1)
          .linear(simulationState.simYear);
      }
      waterConsumption =
        175.78 * agArea * (1 - waterConsAg * 0.3) * climChangeAg +
        31.23 * humanPop * (1 - waterConsMuni * 0.26) +
        0.03544 * energyProd * (1 - waterConsEnergy * 0.35);
      waterConsumptionIndex = Math.min(
        100,
        100 * (waterConsumption / (2 * waterConsumptionCurrent))
      );
    }
    return [waterConsumptionIndex, waterConsumption];
  },
  // INDIGENOUS VOICES INDICATOR EQUATIONS
  TLUAccess: function (equationArgs) {
    // return TLU Access indicator value
    // parse out the passed args
    const scenario = equationArgs.scenario,
      simulationState = equationArgs.simulationState,
      helpers = equationArgs.helpers;
    let TLUAccess = 0;
    // make sure the equation variables have been loaded by ajax from region geojson
    const req_lts = [
      "Forest",
      "Water",
      "Wetland",
      "Grassland",
      "Alpine and Exposed",
    ];
    if (
      simulationState !== undefined &&
      helpers.preCheckAreas(simulationState, req_lts)
    ) {
      TLUAccess = helpers.getSimTLUAccess(scenario, simulationState, helpers.getNaturalLandCover, helpers.Interpolate, helpers.getCurrentLookupGroupKey) * 100;
    }
    return [TLUAccess, TLUAccess];
  },
  MooseHabitat: function (equationArgs) {
    // return moose habitat availability indicator value
    // parse out the passed args
    const scenario = equationArgs.scenario,
      simulationState = equationArgs.simulationState,
      helpers = equationArgs.helpers;
    let MooseHabitat = 0;
    // make sure the equation variables have been loaded by ajax from region geojson
    const req_lts = [
      "Forest",
      "Water",
      "Wetland",
      "Grassland",
      "Alpine and Exposed",
    ];
    if (
      simulationState !== undefined &&
      helpers.preCheckAreas(simulationState, req_lts)
    ) {
      MooseHabitat = helpers.getSimMooseHabitat(scenario, simulationState, helpers.getSimDevRate, helpers.Interpolate, helpers.getCurrentLookupGroupKey, helpers.getFutureLookupGroupKey) * 100;
    }
    return [MooseHabitat, MooseHabitat];
  },
  HuntingOpportunity: function (equationArgs) {
    // return hunting opportunity indicator value
    // value = TLUAcess * MooseHabitat
    // parse out the passed args
    const scenario = equationArgs.scenario,
      simulationState = equationArgs.simulationState,
      helpers = equationArgs.helpers;
    let HuntingOpportunity = 0;
    // make sure the equation variables have been loaded by ajax from region geojson
    const req_lts = [
      "Forest",
      "Water",
      "Wetland",
      "Grassland",
      "Alpine and Exposed",
    ];
    if (
      simulationState !== undefined &&
      helpers.preCheckAreas(simulationState, req_lts)
    ) {
      const TLUAccess = helpers.getSimTLUAccess(scenario, simulationState, helpers.getNaturalLandCover, helpers.Interpolate, helpers.getCurrentLookupGroupKey);
      const MooseHabitat = helpers.getSimMooseHabitat(scenario, simulationState, helpers.getSimDevRate, helpers.Interpolate, helpers.getCurrentLookupGroupKey, helpers.getFutureLookupGroupKey);
      HuntingOpportunity = TLUAccess * MooseHabitat * 100;
    }
    return [HuntingOpportunity, HuntingOpportunity];
  },
  FishingOpportunity: function (equationArgs) {
    // return fishing opportunity indicator value
    // value = TLUAcess * FishCommunityHealth
    // parse out the passed args
    const scenario = equationArgs.scenario,
      simulationState = equationArgs.simulationState,
      helpers = equationArgs.helpers;
    let FishingOpportunity = 0;
    // make sure the equation variables have been loaded by ajax from region geojson
    const req_lts = [
      "Forest",
      "Water",
      "Wetland",
      "Grassland",
      "Alpine and Exposed",
      "Agriculture",
      "Urban",
      "Industry and Transportation",
    ];
    if (
      simulationState !== undefined &&
      helpers.preCheckAreas(simulationState, req_lts)
    ) {
      const TLUAccess = helpers.getSimTLUAccess(scenario, simulationState, helpers.getNaturalLandCover, helpers.Interpolate, helpers.getCurrentLookupGroupKey);
      const FishCommunityHealth = helpers.getSimFishCommunityHealth(
        scenario,
        simulationState,
        helpers.getSimDevRate,
        helpers.getNaturalLandCover,
        helpers.getAnthroLandCover,
        helpers.Interpolate,
        helpers.getCurrentLookupGroupKey,
        helpers.getFutureLookupGroupKey
      );
      // TLUAccess is returned as a decimal percentage
      // getSimFishCommunityHealth is returned as a percentage between 0-100 already (not a decimal percentage)
      FishingOpportunity = TLUAccess * FishCommunityHealth;
    }
    return [FishingOpportunity, FishingOpportunity];
  },
};

export default RegionEquations;
