<template>
  <footer class="footer">
    <div class="container">
      <p>
        <a
          :href="$Region.homeUrl"
          :title="`Go to the ${$Region.app_name} Home Page`"
        >{{ $Region.app_name }} Home</a>
      </p>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style>
</style>
