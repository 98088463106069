<template>
  <div>
    <video
      ref="videoPlayer"
      class="video-js"
      crossorigin="anonymous"
    />
  </div>
</template>

<script>
import videojs from "video.js";
import "videojs-contrib-quality-levels";
import "videojs-hls-quality-selector";
import "video.js/dist/video-js.min.css";
import "videojs-hls-quality-selector/dist/videojs-hls-quality-selector.css";
import "videojs-youtube/dist/Youtube.min.js";
import "videojs-vr";
import { User } from "@/pages/Dashboard/Components/mixins/User";
import { Gamification } from "@/pages/Dashboard/Components/mixins/Gamification";

export default {
  name: "VideoPlayer",
  mixins: [User, Gamification], // user profile methods for updating points, view history
  props: {
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    is360: {
      type: Boolean,
      default: false,
    },
    locations: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      player: null,
      defaultOptions: {
        controlBar: {
          pictureInPictureToggle: false,
        },
        plugins: {
          hlsQualitySelector: true,
        },
      },
      // tracking variables to determine when videos have been completely watched
      timeStarted: -1,
      timePlayed: 0,
      duration: 0,
      currentLocation: null,
    };
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, {
      ...this.options,
      ...this.defaultOptions,
    });
    if (this.is360) {
      this.player.vr({ projection: "360" });
    }
    this.player.qualityLevels(); // show options for video quality

    // setup time event listener for moving map locations on set timestamps
    if (this.locations.length) {
      this.currentLocation = this.locations[0].coordinates; // set the initial coords
      this.player.on("timeupdate", this.onTimeUpdate);
    }

    // setup tracking for awarding points, tracking view history etc
    this.player.on("play", this.videoStartedPlaying);
    this.player.on("playing", this.videoStartedPlaying);
    this.player.on("pause", this.videoStoppedPlaying);
    this.player.on("ended", this.videoStoppedPlaying);
    if (this.player.readyState() > 0) {
      this.getDuration();
    } else {
      this.player.on("loadedmetadata", this.getDuration);
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    videoStartedPlaying() {
      // remember time user started the video
      this.timeStarted = new Date().getTime() / 1000;

      // get duration if it wasn't determined at mount - mobile devices not loading duration at mount
      if (this.duration === 0) {
        this.getDuration();
      }

      // analytics tracking
      this.$Helpers.gtagEvent(
        this,
        `view_video`,
        `videos`,
        `${this.title}, ${this.options.sources[0].src}`
      );
    },
    videoStoppedPlaying(event) {
      // track when a video stops playing
      // if user has viewed entire video, award points and update profile history

      // get duration if it wasn't determined at mount - mobile devices not loading duration at mount
      if (this.duration === 0) {
        this.getDuration();
      }

      // Start time less then zero means stop event was fired vidout start event
      if (this.timeStarted > 0) {
        const playedFor = new Date().getTime() / 1000 - this.timeStarted;
        this.timeStarted = -1;
        // add the new ammount of seconds played
        this.timePlayed += playedFor;
      }
      // Count as complete only if end of video was reached and they watched most (total minus 5sec)
      if (this.timePlayed >= this.duration - 5 && event.type == "ended") {
        // award some points!
        this.addUserPoints(this.$Region.awardPoints.videoView);
        // add video to user's videosViewed
        const historyItem = `v_${this.title}`;
        // call the API and update the user profile
        this.updateUserHistory(historyItem); // updates DB and vuex store
        // GAMIFICATION EVENT: video-view
        this.getGameActivity("video-view");
        // analytics tracking
        this.$Helpers.gtagEvent(
          this,
          `view_video_complete`,
          `videos`,
          `${this.title}, ${this.options.sources[0].src}`
        );
        // } else {
        //   // mobile debugging
        //   this.$Helpers.gtagEvent(this, `view_video_DEBUG`, `videos`, `played: ${this.timePlayed}, duration: ${this.duration}, event: ${event.type}, ${this.title}, ${this.options.sources[0].src}`);
      }
    },
    getDuration() {
      // get the video duration so we can decide if a user has watched all of the video before awarding points
      this.duration =
        isNaN(this.player.duration()) || this.player.duration() === null
          ? 0
          : this.player.duration();
    },
    pausePlayback() {
      // pause video playback
      this.player.pause();
    },
    onTimeUpdate() {
      // handles time updated events for triggering map location changes
      for (let index = this.locations.length - 1; index >= 0; index--) {
        const item = this.locations[index];
        if (this.player.currentTime() >= item.time) {
          if (this.currentLocation !== item.coordinates) {
            this.currentLocation = item.coordinates; // update the current location
            this.$emit('on-location-update', this.currentLocation);
          }
          break; // break as soon as any location in reverse order exceeds the current time
        }
      }
    },
  },
};
</script>