// state store for scenario related information
import { types } from "../types";
import { v4 as uuidv4 } from "uuid";

// initial state
const state = {
  userScenarios: [], // list of all user's scenarios loaded from api
  activeScenario: null, // scenario object loaded from dynamo includes all scenario details
  activeScenarioShared: false, // if the current activeScenario is shared and owned by a different user
  activeRunID: null, // active scenario runID of scenario sent/being processed by backend
  activeRunIDNewScenarioID: null, // whether the active run id represents a brand new scenario - use to differentiate workflows
  scenarioConnectionActive: false, // whether a connection has been made to an IoT topic for scenario run status
  scenarioMode: "create", // one of ["create", "view", "edit"] whether a scenario is being created, viewed or edited
  refreshScenario: false, // trigger scenario map reload - any time this toggles it should trigger (either true or false)
  selectedRegionType: null, // type of region to model (ie watershed vs indigenous)
  selectedRegions: [], // list of regions selected when creating a new scenario (contains nested watersheds from large to small)
  selectedRegionName: null, // name of last region selected
  removeAllocationID: 0, // allocation to be removed - used to trigger marker removals on map
  allocationAreaMax: 1, // the max allowable area tha a single allocation can be - computed based on 5% of the study area size
  allocationsUpdated: [], // list of scenarios with current updates not saved. track whether or not allocations have been updated in an a given scenario - allow simulation run if updated
  scenarioUndos: [], // list of scenario objects containing the scenario state when it was loaded.  Use this to undo changes that would require a scenario re-run. Note that this will not keep current with changes that do not require a rerun.
  mgmtPracticeUpdated: null, // the most recent mgmt practice switch to be updated - use to trigger updates
  planningMakersDraggable: false, // whether or not markers can be draggable depends on what the user is busy doing/what panels are open
  showPlanningLayer: false, // whether or not map is in planning/changes mode - show reference bitmaps and changes layer
  refreshRegionsLayer: false, // trigger map refresh of regions layer - any time this toggles it should trigger (either true or false)
  refreshLanduseLayer: false, // trigger map refresh of Landuse layer - any time this toggles it should trigger (either true or false)
  refreshPlanningMarkers: false, // trigger map refresh of planning markers - any time this toggles it should trigger (either true or false)
  removeRegionsLayer: false, // trigger removal of map regions layer - any time this toggles it should trigger (either true or false)
  removePlanningMarkers: false, // trigger clearing all allocation markers - any time this toggles it should trigger (either true or false)
  lastScenarioPatch: {}, // track the last scenario udpate payload that was patched to the db - used to prevent patching the same item multiple times
  indicatorVals: {}, // object to track calculated indicator values by indicator and year
  indicatorValsReady: false, // whether vals have been calculated or not... use to prevent playback until vals are ready
};

// getters
const getters = {
  [types.getters.USER_SCENARIOS]: (state) => state.userScenarios,
  [types.getters.ACTIVE_SCENARIO]: (state) => state.activeScenario,
  [types.getters.ACTIVE_SCENARIO_SHARED]: (state) => state.activeScenarioShared,
  [types.getters.ACTIVE_RUNID]: (state) => state.activeRunID,
  [types.getters.ACTIVE_RUNID_NEW_SCENARIO_ID]: (state) =>
    state.activeRunIDNewScenarioID,
  [types.getters.SCENARIO_CONNECTION_ACTIVE]: (state) =>
    state.scenarioConnectionActive,
  [types.getters.REFRESH_SCENARIO]: (state) => state.refreshScenario,
  [types.getters.SCENARIO_UNDOS]: (state) => {
    if (state.activeScenario !== null && "id" in state.activeScenario) {
      return state.scenarioUndos.find(
        (scen) => scen.id === state.activeScenario.id
      );
    }
    return null;
  },
  [types.getters.SELECTED_REGIONS]: (state) => state.selectedRegions,
  [types.getters.SELECTED_REGION_TYPE]: (state) => state.selectedRegionType,
  [types.getters.SELECTED_REGION_NAME]: (state) => state.selectedRegionName,
  [types.getters.REMOVE_ALLOCATION_ID]: (state) => state.removeAllocationID,
  [types.getters.ALLOCATION_AREA_MAX]: (state) => state.allocationAreaMax,
  [types.getters.ALLOCATIONS_UPDATED]: (state) => state.allocationsUpdated,
  [types.getters.MGMT_PRACTICE_UPDATED]: (state) => state.mgmtPracticeUpdated,
  [types.getters.PLANNING_MAKERS_DRAGGABLE]: (state) =>
    state.planningMakersDraggable,
  [types.getters.SHOW_PLANNING_LAYER]: (state) => state.showPlanningLayer,
  [types.getters.REFRESH_LANDUSE_LAYER]: (state) => state.refreshLanduseLayer,
  [types.getters.REFRESH_PLANNING_MARKERS]: (state) =>
    state.refreshPlanningMarkers,
  [types.getters.REFRESH_REGIONS_LAYER]: (state) => state.refreshRegionsLayer,
  [types.getters.REMOVE_REGIONS_LAYER]: (state) => state.removeRegionsLayer,
  [types.getters.REMOVE_PLANNING_MARKERS]: (state) =>
    state.removePlanningMarkers,
  [types.getters.LAST_SCENARIO_PATCH]: (state) => state.lastScenarioPatch,
  [types.getters.INDICATOR_VALS]: (state) => state.indicatorVals,
  [types.getters.INDICATOR_VALS_READY]: (state) => state.indicatorValsReady,
};

// mutations
const mutations = {
  [types.mutations.LOAD_USER_SCENARIOS](state, payload) {
    // populate array with user scenarios loaded from api
    state.userScenarios = [...payload];
  },
  [types.mutations.USER_SCENARIOS_PUSH](state, _scenario) {
    // add a scenario to the userScenarios array
    state.userScenarios.push(_scenario);
  },
  [types.mutations.USER_SCENARIOS_UPDATE](state, _scenario) {
    // update a single scenario in the userScenarios array
    const scenIndex = state.userScenarios.findIndex(
      (scen) => scen.id === _scenario.id
    );
    state.userScenarios[scenIndex] = JSON.parse(JSON.stringify(_scenario));
  },
  [types.mutations.USER_SCENARIOS_REMOVE](state, _scenario) {
    // remove a single scenario from the userScenarios array
    const scenIndex = state.userScenarios.findIndex(
      (scen) => scen.id === _scenario.id
    );
    state.userScenarios.splice(scenIndex, 1);
  },
  [types.mutations.SET_ACTIVE_SCENARIO](state, _scenario) {
    // set the current scenario to the store
    state.activeScenario = _scenario;
  },
  [types.mutations.UPDATE_ACTIVE_SCENARIO](state, payload) {
    // update keys in activeScenario - update all keys provided in payload
    Object.keys(payload).forEach((key) => {
      state.activeScenario[key] = JSON.parse(JSON.stringify(payload[key]));
    });
  },
  [types.mutations.SET_ACTIVE_SCENARIO_SHARED](state, status) {
    // set the sharing/ownership status of the current active scenario
    state.activeScenarioShared = status;
  },
  [types.mutations.SET_ACTIVE_RUNID](state, runid) {
    // set the current active run id to the store
    state.activeRunID = runid;
  },
  [types.mutations.SET_ACTIVE_RUNID_NEW_SCENARIO_ID](state, id) {
    state.activeRunIDNewScenarioID = id;
  },
  [types.mutations.SET_SCENARIO_CONNECTION_ACTIVE](state, status) {
    state.scenarioConnectionActive = status;
  },
  [types.mutations.TOGGLE_REFRESH_SCENARIO](state) {
    state.refreshScenario = !state.refreshScenario;
  },
  [types.mutations.SCENARIO_UNDOS_PUSH](state, _scenario) {
    // add a scenario to the list of undos
    state.scenarioUndos.push(JSON.parse(JSON.stringify(_scenario)));
  },
  [types.mutations.SCENARIO_UNDOS_REMOVE](state, id) {
    // remove from list of scenario undos
    const scenIndex = state.scenarioUndos.findIndex((scen) => scen.id === id);
    state.scenarioUndos.splice(scenIndex, 1);
  },
  [types.mutations.SET_SELECTED_REGION_TYPE](state, type) {
    state.selectedRegionType = type;
  },
  [types.mutations.SELECTED_REGIONS_PUSH](state, region) {
    // add a region to the selectedRegions array
    state.selectedRegions.push(region);
  },
  [types.mutations.SELECTED_REGIONS_CLEAR](state) {
    // clear the selectedRegions array
    state.selectedRegions = [];
    // also clear the selectedRegionName
    state.selectedRegionName = null;
  },
  [types.mutations.SET_SELECTED_REGION_NAME](state, name) {
    state.selectedRegionName = name;
  },
  [types.mutations.SET_REMOVE_ALLOCATION_ID](state, id) {
    state.removeAllocationID = id;
  },
  [types.mutations.SET_ALLOCATION_AREA_MAX](state, areaMax) {
    state.allocationAreaMax = areaMax;
  },
  [types.mutations.ALLOCATIONS_UPDATED_PUSH](state, id) {
    // add a scenario to the list of scenarios with current un-saved updates
    const index = state.allocationsUpdated.findIndex((scen) => scen === id);
    if (index === -1) {
      state.allocationsUpdated.push(id);
    }
  },
  [types.mutations.ALLOCATIONS_UPDATED_REMOVE](state, id) {
    // remove from list of scenarios with current un-saved updates
    const updatedList = state.allocationsUpdated.filter((scen) => scen !== id);
    state.allocationsUpdated = updatedList;
  },
  [types.mutations.SET_MGMT_PRACTICE_UPDATED](state, practice) {
    state.mgmtPracticeUpdated = practice;
  },
  [types.mutations.PLANNING_MARKERS_DRAGGABLE_ON](state) {
    state.planningMakersDraggable = true;
  },
  [types.mutations.PLANNING_MARKERS_DRAGGABLE_OFF](state) {
    state.planningMakersDraggable = false;
  },
  [types.mutations.SET_SHOW_PLANNING_LAYER](state, status) {
    state.showPlanningLayer = status;
  },
  [types.mutations.TOGGLE_REFRESH_REGIONS_LAYER](state) {
    state.refreshRegionsLayer = !state.refreshRegionsLayer;
  },
  [types.mutations.TOGGLE_REFRESH_LANDUSE_LAYER](state) {
    state.refreshLanduseLayer = !state.refreshLanduseLayer;
  },
  [types.mutations.TOGGLE_REFRESH_PLANNING_MARKERS](state) {
    state.refreshPlanningMarkers = !state.refreshPlanningMarkers;
  },
  [types.mutations.TOGGLE_REMOVE_REGIONS_LAYER](state) {
    state.removeRegionsLayer = !state.removeRegionsLayer;
  },
  [types.mutations.TOGGLE_CLEAR_PLANNING_MARKERS](state) {
    state.removePlanningMarkers = !state.removePlanningMarkers;
    // and goals and allocations must be reset
    state.allocationID = 0;
    state.editAllocationID = 0;
  },
  [types.mutations.SET_LAST_SCENARIO_PATCH](state, payload) {
    state.lastScenarioPatch = payload;
  },
  [types.mutations.SET_INDICATOR_VAL](state, payload) {
    // set a new value
    if (!(payload.indicator in state.indicatorVals)) {
      // make sure obj is setup
      state.indicatorVals[payload.indicator] = {};
    }
    state.indicatorVals[payload.indicator][payload.simYear] = payload.val;
  },
  [types.mutations.CLEAR_INDICATOR_VALS](state) {
    // set a clean slate
    state.indicatorVals = {};
    state.indicatorValsReady = false;
  },
  [types.mutations.SET_INDICATOR_VALS_READY](state, status) {
    state.indicatorValsReady = status;
  },
};

// actions
const actions = {
  [types.actions.LOAD_USER_SCENARIOS]({ commit }, payload) {
    // load the array of user scenarios
    // note, this adds to the already existing array.  If a scenario is being deleted, the array should be cleared first before loading
    // payload expects a vue model as a {vm: this} ... this should be called from a model like so: this.LOAD_USER_SCENARIOS({vm: this})
    // relies on loading global options

    const scenarios = [];
    const ids = [];
    const authLib = payload.vm.$Auth;
    const apiLib = payload.vm.$API;

    // API Call
    authLib
      .currentSession()
      .then((authData) => {
        const myInit = {
          headers: { Authorization: authData.idToken.jwtToken },
        };
        const username = authData.accessToken.payload.username;

        apiLib
          .get("api", "/simulation/" + username, myInit)
          .then((body) => {
            // Parse from DynamoDB
            body.Items.map((item) => {
              const scenData = payload.vm.$Helpers.remapScenData(item, uuidv4);
              scenarios.push(scenData);
              ids.push(scenData.id);
            });
            // Reverse the scenarios based on ID
            ids.sort();
            ids.reverse().forEach((id) => {
              scenarios.forEach((scen) => {
                if (id === scen.id) {
                  // find the scen by id
                  const _scen = state.userScenarios.find(
                    (_scen) => _scen.id === scen.id
                  );
                  if (!_scen) {
                    // add it if it's not in USER_SCENARIOS
                    commit(types.mutations.USER_SCENARIOS_PUSH, scen);
                  } else {
                    // else update with the latest info for existing scen
                    commit(types.mutations.USER_SCENARIOS_UPDATE, scen);
                  }
                }
              });
            });
            // trigger potential mission achievement updates
            commit(types.mutations.INCREMENT_MISSION_UPDATE_TRIGGER);
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error.response);
          });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  },
  [types.actions.CLEAR_USER_SCENARIOS]({ commit }) {
    // reset the array of user scenarios
    commit(types.mutations.LOAD_USER_SCENARIOS, []);
  },
  [types.actions.USER_SCENARIOS_PUSH]({ commit }, _scenario) {
    commit(types.mutations.USER_SCENARIOS_PUSH, _scenario);
  },
  [types.actions.USER_SCENARIOS_UPDATE]({ commit }, _scenario) {
    commit(types.mutations.USER_SCENARIOS_UPDATE, _scenario);
  },
  [types.actions.USER_SCENARIOS_REMOVE]({ commit }, _scenario) {
    commit(types.mutations.USER_SCENARIOS_REMOVE, _scenario);
  },
  [types.actions.SET_ACTIVE_SCENARIO]({ commit, state }, payload) {
    const vm = payload.vm;
    const _scenario = payload.scenario;
    // set a new active scenario
    commit(types.mutations.CLEAR_INDICATOR_VALS);
    commit(types.mutations.SHOW_PLANNING_MARKERS_OFF);
    commit(types.mutations.SET_EXPLORE_MODE, "scenario");
    commit(types.mutations.SET_ACTIVE_SCENARIO, _scenario);
    // add to the undos array
    if (!state.allocationsUpdated.includes(_scenario.id)) {
      commit(types.mutations.SCENARIO_UNDOS_PUSH, _scenario);
    }
    // also clear any previous sidebar content
    commit(types.mutations.SET_SIDEBAR_CONTENT_TYPE, null);
    commit(types.mutations.CLOSE_SIDEBAR_CONTENT);
    // and turn on the landuse layer
    commit(types.mutations.SET_MAP_DATALAYER, "landuse");
    commit(types.mutations.SHOW_LANDUSE_OVERLAY_ON);
    // and turn on markers
    if (_scenario.type === "Land Use") {
      commit(types.mutations.SHOW_PLANNING_MARKERS_ON);
    }
    // check ownership/share status
    const isShared =
      "UserName" in _scenario && _scenario.UserName !== vm.$Auth.user.username;
    commit(types.mutations.SET_ACTIVE_SCENARIO_SHARED, isShared);
    // analytics tracking
    const label = `${_scenario.type}, ${_scenario.studyArea}, ${_scenario.climate}`;
    vm.$Helpers.gtagEvent(vm, `view_scenario`, `scenarios`, label);
  },
  [types.actions.UPDATE_ACTIVE_SCENARIO]({ commit }, payload) {
    // note this only updates the vuex object.  It does not update the database.
    commit(types.mutations.UPDATE_ACTIVE_SCENARIO, payload);
  },
  [types.actions.CLOSE_SCENARIO]({ commit }) {
    commit(types.mutations.SET_ACTIVE_HINT, null);
    commit(types.mutations.SET_ACTIVE_SCENARIO, null);
    commit(types.mutations.SET_ACTIVE_SCENARIO_SHARED, false);
    commit(types.mutations.SET_EXPLORE_MODE, "explore");
    commit(types.mutations.SET_EXPLORE_MOBILE_FS_CONTENT, "map");
    commit(types.mutations.SET_INDICATOR_VALS_READY, false);
  },
  [types.actions.CLEAR_SCENARIO_AND_REGIONS]({ commit }) {
    // clear the edit/create scenario in progress and related data
    commit(types.mutations.SET_ACTIVE_SCENARIO, null);
    commit(types.mutations.SELECTED_REGIONS_CLEAR);
    commit(types.mutations.SET_INDICATOR_VALS_READY, false);
  },
  [types.actions.INIT_NEW_SCENARIO]({ commit }, payload) {
    // initialize a new edit/create scenario object with default settings
    const vm = payload.vm;
    const climScen =
      "climateDefaultIndex" in vm.$Region
        ? vm.$Region.climateScenarios[vm.$Region.climateDefaultIndex].variable
        : "none";
    const _scenario = {
      // initialize these keys
      allocations: [],
      goals: {},
      devRate: null,
      management: [],
      climate: climScen,
    };
    commit(types.mutations.SET_ACTIVE_SCENARIO, _scenario);
    commit(types.mutations.SELECTED_REGIONS_CLEAR);
    commit(types.mutations.SET_ACTIVE_SCENARIO_SHARED, false);
  },
  [types.actions.NEW_ACTIVE_RUNID]({ commit }) {
    const runid = uuidv4();
    commit(types.mutations.SET_ACTIVE_RUNID, runid);
  },
  [types.actions.CLEAR_ACTIVE_RUNID]({ commit }) {
    commit(types.mutations.SET_ACTIVE_RUNID, null);
  },
  [types.actions.SET_ACTIVE_RUNID_NEW_SCENARIO_ID]({ commit }, id) {
    commit(types.mutations.SET_ACTIVE_RUNID_NEW_SCENARIO_ID, id);
  },
  [types.actions.SET_SCENARIO_CONNECTION_ACTIVE]({ commit }, status) {
    commit(types.mutations.SET_SCENARIO_CONNECTION_ACTIVE, status);
  },
  [types.actions.SCENARIO_UNDOS_PUSH]({ commit }, _scenario) {
    // push a scenario to the undos array
    // clear any old one out if there was one already
    commit(types.mutations.SCENARIO_UNDOS_REMOVE, _scenario.id);
    // commit(types.mutations.SCENARIO_UNDOS_PUSH, _scenario);
    commit(types.mutations.SCENARIO_UNDOS_PUSH, _scenario);
  },
  [types.actions.SCENARIO_UNDOS_REMOVE]({ commit }, id) {
    commit(types.mutations.SCENARIO_UNDOS_REMOVE, id);
  },
  [types.actions.SELECTED_REGIONS_PUSH]({ commit }, region) {
    commit(types.mutations.SELECTED_REGIONS_PUSH, region);
    // reset the devRate
    commit(types.mutations.UPDATE_ACTIVE_SCENARIO, { devRate: null });
  },
  [types.actions.TOGGLE_REFRESH_SCENARIO]({ commit }) {
    commit(types.mutations.TOGGLE_REFRESH_SCENARIO);
  },
  [types.actions.SET_SELECTED_REGION_TYPE]({ commit, state }, type) {
    if (state.selectedRegionType !== type) {
      commit(types.mutations.SET_SELECTED_REGION_TYPE, type);
      commit(types.mutations.TOGGLE_REFRESH_REGIONS_LAYER);
    }
  },
  [types.actions.SELECTED_REGIONS_CLEAR]({ commit }) {
    // wipe selected regions
    commit(types.mutations.SELECTED_REGIONS_CLEAR);
    // reset the devRate
    commit(types.mutations.UPDATE_ACTIVE_SCENARIO, { devRate: null });
  },
  [types.actions.SET_SELECTED_REGION_NAME]({ commit }, name) {
    commit(types.mutations.SET_SELECTED_REGION_NAME, name);
  },
  [types.actions.SET_REMOVE_ALLOCATION_ID]({ commit }, id) {
    commit(types.mutations.SET_REMOVE_ALLOCATION_ID, id);
  },
  [types.actions.ALLOCATIONS_CLEAR]({ commit }) {
    // wipe the allocations from the edit scenario
    commit(types.mutations.UPDATE_ACTIVE_SCENARIO, { allocations: [] });
  },
  [types.actions.SET_ALLOCATION_AREA_MAX]({ commit }, areaMax) {
    commit(types.mutations.SET_ALLOCATION_AREA_MAX, areaMax);
  },
  [types.actions.ALLOCATIONS_PUSH]({ commit, state }, allocation) {
    // add an allocation to the active scenario
    const _allocations = state.activeScenario.allocations;
    _allocations.push(allocation);
    commit(types.mutations.UPDATE_ACTIVE_SCENARIO, {
      allocations: _allocations,
    });
  },
  [types.actions.ALLOCATIONS_UPDATED_PUSH]({ commit }, id) {
    // add a scenario id to the list of scenarios that have pending updates - they have updated allocations but have not been saved/re-run
    commit(types.mutations.ALLOCATIONS_UPDATED_PUSH, id);
  },
  [types.actions.ALLOCATIONS_UPDATED_REMOVE]({ commit }, id) {
    commit(types.mutations.ALLOCATIONS_UPDATED_REMOVE, id);
  },
  [types.actions.SET_MGMT_PRACTICE_UPDATED]({ commit }, practice) {
    commit(types.mutations.SET_MGMT_PRACTICE_UPDATED, practice);
  },
  [types.actions.GOALS_CLEAR]({ commit }) {
    // wipe the allocations from the edit scenario
    commit(types.mutations.UPDATE_ACTIVE_SCENARIO, { goals: {} });
  },
  [types.actions.PLANNING_MARKERS_DRAGGABLE_ON]({ commit }) {
    commit(types.mutations.PLANNING_MARKERS_DRAGGABLE_ON);
  },
  [types.actions.PLANNING_MARKERS_DRAGGABLE_OFF]({ commit }) {
    commit(types.mutations.PLANNING_MARKERS_DRAGGABLE_OFF);
  },
  [types.actions.SET_SHOW_PLANNING_LAYER]({ commit }, status) {
    commit(types.mutations.SET_SHOW_PLANNING_LAYER, status);
  },
  [types.actions.TOGGLE_REFRESH_REGIONS_LAYER]({ commit }) {
    commit(types.mutations.TOGGLE_REFRESH_REGIONS_LAYER);
  },
  [types.actions.TOGGLE_REMOVE_REGIONS_LAYER]({ commit }) {
    commit(types.mutations.TOGGLE_REMOVE_REGIONS_LAYER);
  },
  [types.actions.TOGGLE_REFRESH_LANDUSE_LAYER]({ commit }) {
    commit(types.mutations.TOGGLE_REFRESH_LANDUSE_LAYER);
  },
  [types.actions.TOGGLE_REFRESH_LANDUSE_LAYER]({ commit }) {
    commit(types.mutations.TOGGLE_REFRESH_LANDUSE_LAYER);
  },
  [types.actions.TOGGLE_REFRESH_PLANNING_MARKERS]({ commit }) {
    commit(types.mutations.TOGGLE_REFRESH_PLANNING_MARKERS);
  },
  [types.actions.TOGGLE_CLEAR_PLANNING_MARKERS]({ commit }) {
    // trigger map to remove all plannign markers
    commit(types.mutations.TOGGLE_CLEAR_PLANNING_MARKERS);
    // also reset goals and allocations
    commit(types.mutations.UPDATE_ACTIVE_SCENARIO, { allocations: [] });
    commit(types.mutations.UPDATE_ACTIVE_SCENARIO, { goals: {} });
  },
  [types.actions.SET_LAST_SCENARIO_PATCH]({ commit }, payload) {
    commit(types.mutations.SET_LAST_SCENARIO_PATCH, payload);
  },
  [types.actions.SET_INDICATOR_VAL]({ commit }, payload) {
    commit(types.mutations.SET_INDICATOR_VAL, payload);
  },
  [types.actions.CLEAR_INDICATOR_VALS]({ commit }) {
    commit(types.mutations.CLEAR_INDICATOR_VALS);
  },
  [types.actions.SET_INDICATOR_VALS_READY]({ commit }, status) {
    commit(types.mutations.SET_INDICATOR_VALS_READY, status);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
