// land types setup

const RegionLandTypes = {
  lt_types: {
    Forest: 1,
    Grassland: 2,
    Agriculture: 3,
    Water: 4,
    Wetland: 5,
    Urban: 6,
    "Industry and Transportation": 7,
    "Alpine and Exposed": 8,
  },
  simulation_weights: [
    [1, 0.9, 0.9, 0.05, 0.5, 0.05, 0.9, 0],
    [0.9, 1, 0.9, 0.05, 0.5, 0.05, 0.9, 0],
    [0.9, 0.9, 1, 0.05, 0.5, 0.05, 0.05, 0],
    [0.1, 0.1, 0.1, 1, 0.5, 0.05, 0.1, 0],
    [0.05, 0.05, 0.05, 0.1, 1, 0.05, 0.1, 0],
    [0.9, 0.9, 0.9, 0.05, 0.5, 1, 0.5, 0],
    [0.9, 0.9, 0.9, 0.05, 0.5, 0.5, 1, 0.1],
  ],
  colors: {
    Forest: "#006600",
    Grassland: "#ffbb00",
    Agriculture: "#f44336",
    Water: "#2196f3",
    Wetland: "#4caf50",
    Urban: "#9c27b0",
    "Industry and Transportation": "#999999",
    "Alpine and Exposed": "#f7f7f7",
  },

  // For styling
  landUseTypes: [
    // TODO align these better with the lt_types and colors at the top of this file.... not sure why they are duplicated
    {
      id: 0,
      name: "Agriculture",
      description: `Agriculture refers to land that has been cleared for food or hay production.  Examples would be a wheat field, canola field or  brome grass field. To learn more watch this Agriculture video.`,
      color: "md-danger",
      hex: "#ef3631",
      series: "ct-series-b",
      currentArea: 15109200,
      media: {
        title: "Agricultural Production",
        type: "video",
        url:
          "https://d2qcvmovr4fv.cloudfront.net/out/v1/ae54d05645274ba78eb07c8c480d8455/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/760a93f5-2cff-49e0-843c-3dd759b7232d/thumbnails/Agriculture Production v2_H264_tumb.0000025.jpg",
      },
    },
    {
      id: 1,
      name: "Grassland",
      description: `Grassland refers to native prairie.  Native prarie is the community of plants and animals that would exist in southern Alberta prior to the start of agriculture.  Alberta has different types of grasslands, including the short grass grassland, mixed-grass grassland, and fescue grassland. Grasslands perform many mportant ecosystem functions, such as cleansing our air, storing and filtering our water, capturing carbon and supplying sustainable grazing. They contain over 75% of Alberta's species at risk, largely because much of the grasslands ecoregion has been cleared for Agriculture. For more information watch this Natural Landscapes Video.`,
      color: "grassland-btn",
      hex: "#ffbb00",
      series: "ct-series-c",
      currentArea: 7030300,
      media: {
        title: "Natural Landscapes",
        type: "video",
        url:
          "https://d2qcvmovr4fv.cloudfront.net/out/v1/a87c6ba3b82849e48619d4027ed41e81/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/7d120260-62f4-48f9-8d72-63fb519fd9a7/thumbnails/Natural Landscapes v3_H264_tumb.0000064.jpg",
      },
    },
    {
      id: 2,
      name: "Water",
      description: `In Alberta Tomorrow the blue refers to water.  This would inlcude open water lakes, ponds and sloughs, rivers and streams. Water areas perform many important ecosystem functions such as freshwater storage, water quality improvement, floodwater storage, fish and wildlife habitat.`,
      color: "md-info",
      hex: "#23aec3",
      series: "ct-series-a",
      currentArea: 2120100,
    },
    {
      id: 3,
      name: "Wetland",
      description: `Wetlands inlcudes "wet" areas such as marshes, bogs or black spruce forests where water is either at the surface or very near the surface all year or for certain times during the year. Wetlands perform many important ecosystem functions such as water quality improvement, floodwater storage, fish and wildlife habitat and carbon absorption. There was a time in Alberta's past when wetlands were seen as unproductive land and farmers were encouraged to drain and fill them in. We now recognize the value of wetlands and have a  Wetland Policy that aims to minimize the loss and degradation of wetlands while allowing for continued growth and economic development in the province. For more information watch this Ecosystem Services video.`,
      color: "md-success",
      hex: "#4ca453",
      series: "ct-series-e",
      currentArea: 16045600,
      media: {
        title: "Ecological Goods and Services",
        type: "video",
        url:
          "https://d2qcvmovr4fv.cloudfront.net/out/v1/d3af3f5027fc4578bb8a5e661fd15662/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/7f76a14f-20e9-430d-bef6-5c08247cad75/thumbnails/Ecological Goods v2_H264_tumb.0000033.jpg",
      },
    },
    {
      id: 4,
      name: "Forest",
      description: `Forested areas are those predominantly covered by trees.  In Alberta Tomorrow, forested area included the Boreal Forest, parts of the Aspen Parkland, Foothills and Alpine regions, as well as forested areas along rivers and in coulees.  For more information watch this Natural Landscapes Video.`,
      color: "md-forest",
      hex: "#2e7d32",
      series: "ct-series-g",
      currentArea: 23650300,
      media: {
        title: "Natural Landscapes",
        type: "video",
        url:
          "https://d2qcvmovr4fv.cloudfront.net/out/v1/a87c6ba3b82849e48619d4027ed41e81/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/7d120260-62f4-48f9-8d72-63fb519fd9a7/thumbnails/Natural Landscapes v3_H264_tumb.0000064.jpg",
      },
    },
    {
      id: 5,
      name: "Urban",
      description: `In Alberta Tomorrow Urban and Settlement areas are primaraly those occupied by cities and towns.  Other areas that have significant anthropogenic changes such as the oil sands may also appear as settlement. For more infomration on how our population in Alberta has grown, watch this video on Human Population Growth.`,
      color: "md-primary",
      hex: "#8f229f",
      series: "ct-series-d",
      currentArea: 556900,
      media: {
        title: "Human Population",
        type: "video",
        url:
          "https://d2qcvmovr4fv.cloudfront.net/out/v1/88c0e1da186a403b9d8f6504062316a9/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/020d0ab8-d1d3-4cc4-b064-ce5c86f1b702/thumbnails/Human Population v2_H264_tumb.0000010.jpg",
      },
    },
    {
      id: 6,
      name: "Industry and Transportation",
      description: `Industry and Transportation areas include large open pit mines, the oil sands and airports.`,
      color: "md-default",
      hex: "#919191",
      series: "ct-series-f",
      currentArea: 86600,
    },
  ],

  noChangeLandUseTypes: [
    {
      id: 7,
      name: "Alpine and Exposed",
      description: `In Alberta Tomorrow, Alpine referrs to areas that are covered by rock or ice.  Since there is very little soil, there are few plants and these areas are often not suitable for any other landuse.`,
      color: "md-white",
      hex: "#ffffff",
      series: "ct-series-o",
      currentArea: 1644300,
    },
  ],

  icons: {
    Agriculture: "tractor",
    Grassland: "abc_grassland_icon.svg",
    Water: "water",
    Forest: "trees",
    Wetland: "abc_wetland_icon.svg",
    Urban: "city",
    "Industry and Transportation": "industry-alt",
    "Alpine and Exposed": "mountain",
  },
};

export default RegionLandTypes;
