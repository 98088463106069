<template>
  <div
    ref="studentListContainer"
    @paste="divPasteHandler"
    @contextmenu.prevent="contextMenu"
  >
    <!-- <div
  IGNORE THIS... todo - customized context menu to allow copy/paste with right click
    ref="studentListContainer"
    @paste="divPasteHandler"
    @contextmenu.prevent="contextMenu"
  > -->
    <!-- context menu -->
    <!-- <md-menu
      ref="ctxm"
      md-size="medium"
      :md-active="contextMenuActive"
      :md-offset-x="contextOffsetX"
      :md-offset-y="contextOffsetY"
      md-close-on-click
      @md-closed="contextMenuActive=false"
    >
      <md-menu-content>
        <md-menu-item>Copy</md-menu-item>
        <md-menu-item @click="divPasteHandler">
          Paste
        </md-menu-item>
      </md-menu-content>
    </md-menu> -->
    <div>
      <h5 class="info-text">
        Provide details for all new students you would like to register
      </h5>
      <p>
        <ul>
          <li>*First Name, Last Name and Username are required.</li>
          <li>Email field is optional. If not provided, those users will be unable to retrieve forgotten passwords. Instead, as their teacher, you will be responsible for helping them reset their password if they forget it.</li>
          <li>
            <b>Warning:</b> Do not input emails for students in grades 6 or younger.
            <ul> *Some school divisions block emails from outside domains. If you are unsure if they do, DO NOT provide an email address, or contact your school division IT department to allow emails from @albertatomorrow.ca  (If you provide their email address, to complete the registration process students must have access to their email to verify the account). </ul>
          </li>
          <li>Password field is optional. If not provided, password will be automatically generated.</li>
        </ul>
      </p>
      <!-- template and copy/paste info -->
      <div class="md-elevation-2 optional-info">
        <h5><i>Optional:</i> You can use a template to prepare student info</h5>
        <p>
          Use <a
            style="cursor: pointer"
            @click="downloadTemplate"
          >this template</a> to compile the information for the new students, and then copy and paste them in the table below.
          <ol>
            <li>
              Download the template:<br>
              <md-button
                id="TeacherNewStudentsBtn"
                slot="content"
                class="md-raised md-info"
                @click="downloadTemplate"
              >
                <md-icon>download</md-icon> Download CSV Template
              </md-button>
            </li>
            <li>Prepare information for new student accounts</li>
            <li>Copy list from your editing program by pressing Ctrl+C (Windows) or Cmd+C (Mac)</li>
            <li>Paste your list in the table below by pressing Ctrl+V (Windows) or Cmd+V (Mac)</li>
          </ol>
        </p>
      </div>
      <span>
        <grid
          ref="grid"
          :data="gridProps.data"
          :columns="gridProps.columns"
          :options="gridProps"
          @beforeChange="beforeGridChange"
        />
        <md-tooltip
          md-delay="1000"
          md-direction="top"
        >Double-click cells to edit.<br>Tab to jump to next cell.</md-tooltip>
      </span>
      <p>
        <md-button
          id="gridAddRowsBtn"
          slot="content"
          class="md-raised md-success"
          @click="addGridRows(10)"
        >
          <md-icon>add</md-icon> Add Rows
        </md-button>
        <md-button
          id="gridResetBtn"
          slot="content"
          class="md-raised md-warning grid-reset-btn"
          @click="resetGrid"
        >
          Reset
        </md-button>
      </p>
    </div>
  </div>
</template>

<script>
import "tui-grid/dist/tui-grid.css";
import { Grid } from "@toast-ui/vue-grid";
// uses TUI Grid
// https://github.com/nhn/tui.grid/tree/master/packages/toast-ui.vue-grid
// http://nhn.github.io/tui.grid/latest/Grid#restore
import swal from "sweetalert2";

export default {
  name: "BulkWizardStudentList",

  components: {
    grid: Grid,
  },

  props: {
    toggleReset: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      validated: false,
      gridProps: {},
      gridData: [],
      pasteHandled: false,
      importData: [],
      errorMessages: [],
      // contextMenuActive: false,
      // contextOffsetX: 0,
      // contextOffsetY: 0,
    };
  },

  computed: {
    gridHeadingsVariations() {
      // list of headings used in grid and possible variations to sanitize from paste events
      const headings = [
        "First Name",
        "Last Name",
        "Username",
        "Email",
        "Password",
      ];
      // return any iteration of headings, headings lowercase, headings without spaces, headings with asterisks
      return [
        ...headings,
        ...headings.map((head) => head.replace(/\s/g, "")),
        ...headings.map((head) => `${head}*`),
        ...headings
          .map((head) => head.replace(/\s/g, ""))
          .map((head) => `${head}*`),
      ];
    },
  },

  watch: {
    toggleReset() {
      this.resetGrid();
      this.validated = false;
    },
  },

  created() {
    this.gridProps = {
      data: this.gridData,
      columns: [
        { name: "firstname", header: "First Name*", editor: "text" },
        { name: "lastname", header: "Last Name*", editor: "text" },
        { name: "UserName", header: "Username*", editor: "text" },
        { name: "email", header: "Email", editor: "text" },
        { name: "password", header: "Password", editor: "text" },
      ],
      usageStatistics: false,
      bodyHeight: 400,
      heightResizable: true,
      // contextMenu: null, // todo for customized context menu
    };
  },

  beforeMount() {},

  mounted() {
    this.addGridRows(25);
    this.focus();
    this.$nextTick(() => this.focus());
  },

  methods: {
    toggleListValidated() {
      this.validated = false;
    },
    validate() {
      // some required field validations
      // check for at leats 1 row of data to import
      // check for required fields on each row
      // check for name, username, email, password field formats

      // finish any cell editing and blur
      const grid = this.$refs.grid;
      const activeCell = grid.invoke("getFocusedCell");
      if (activeCell.rowKey != null) {
        // if (any cell is active);
        grid.invoke("blur");
      }

      // reset errors
      this.errorMessages = [];

      // do validation steps
      if (!this.validated) {
        this.sanitizeGrid();
        // check that there is at least 1 row to import
        if (!this.importData.length) {
          this.addErrorMsg(`You must provide info for at least one new user!`);
          this.resetGrid();
        } else {
          // check for required fields
          const requiredFields = [
            ["firstname", "First Name"],
            ["lastname", "Last Name"],
            ["UserName", "Username"],
          ];
          requiredFields.forEach((field) => {
            const fieldname = field[0];
            const title = field[1];
            this.importData.forEach((row) => {
              if ([null, "", undefined].includes(row[fieldname])) {
                this.markCellInvalid(row.rowKey, fieldname);
                this.addErrorMsg(`${title} is required for each new user.`);
              }
            });
          });
          // check that firstname,lastname are valid format
          ["firstname", "lastname"].forEach((nameField) => {
            this.importData.forEach((row) => {
              if (
                ![null, "", undefined].includes(row[nameField]) &&
                !this.validateName(row[nameField])
              ) {
                this.markCellInvalid(row.rowKey, nameField);
                this.addErrorMsg(
                  `Name must be at least 2 characters long and use alphabetical characters and hyphens "-" only.`
                );
              }
            });
          });
          // check that username is valid format
          this.importData.forEach((row) => {
            if (
              ![null, "", undefined].includes(row.UserName) &&
              !this.validateUsername(row.UserName)
            ) {
              this.markCellInvalid(row.rowKey, "UserName");
              this.addErrorMsg(
                `Usernames must contain a minumum of 6 characters and may only contain letters, numbers, dashes "-" underscores "_" and periods ".". Usernames can not be in the form of an email address (do not use an @ symbol).`
              );
            }
          });
          // check that email is valid format
          this.importData.forEach((row) => {
            if (
              ![null, "", undefined].includes(row.email) &&
              !this.validateEmail(row.email)
            ) {
              this.markCellInvalid(row.rowKey, "email");
              this.addErrorMsg(`Email address must be in valid email format.`);
            }
          });
          // check that password is valid format
          this.importData.forEach((row) => {
            if (
              ![null, "", undefined].includes(row.password) &&
              !this.validatePassword(row.password)
            ) {
              this.markCellInvalid(row.rowKey, "password");
              this.addErrorMsg(
                `Passwords must contain a minimum of 8 characters, one lowercase letter, one uppercase letter, and one number.`
              );
            }
          });
        }
        if (!this.errorMessages.length) {
          this.validated = true;
          return new Promise((resolve) => {
            resolve(true);
          }).then((res) => {
            this.onSuccess(res);
            return res;
          });
        }
        this.errorAlert(this.errorMessages.join(" "));
        return false;
      }
    },
    addErrorMsg(msg) {
      if (!this.errorMessages.includes(msg)) {
        this.errorMessages.push(msg);
      }
    },
    validateName(name) {
      // simply checks if a string is in a valid first name or last name format
      if (name.match(/^([a-zA-Z-]){2,}$/)) {
        return true;
      }
      return false;
    },
    validateUsername(username) {
      // simply checks if a string is in a valid username format. Must be at least 6 chars, only letters, numbers, dashes, underscores, periods
      // must not be in email format (no @ symbol)
      if (username.match(/^([A-Za-z0-9_.-]){6,}$/)) {
        return true;
      }
      return false;
    },
    validateEmail(email) {
      // simply checks if a string is in a valid email address format
      if (
        email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        return true;
      }
      return false;
    },
    validatePassword(password) {
      // checks if a string is in a valid password format
      // requires min 8 characters, at least 1 lowercase, 1 uppercase, 1 number. special characters are allowed
      if (
        password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/)
      ) {
        return true;
      }
      return false;
    },
    errorAlert(message) {
      swal.fire({
        title: `<p style="color:red;">Uh oh, please check that all required data is entered...</p>`,
        text: message,
        timer: 4000,
        showConfirmButton: false,
      });
    },
    onSuccess(res) {
      // update data in parent
      this.$emit("on-validated", res, this.importData);
    },
    onDataUpdated(model) {
      // update the data triggered by API response in review component
      this.$refs.grid.invoke("resetData", model);
      // get the cell classes
      model.forEach((row) => {
        Object.keys(row._attributes.className.column).forEach((col) => {
          if (row._attributes.className.column[col] == "grid-invalid") {
            this.markCellInvalid(row.rowKey, col);
          }
        });
      });
    },
    markCellInvalid(rowKey, fieldname) {
      this.$refs.grid.invoke(
        "addCellClassName",
        rowKey,
        fieldname,
        "grid-error"
      );
    },
    sanitizeGrid() {
      // do some clean up before performing validation
      // - remove empty rows
      const grid = this.$refs.grid;
      // get latest data from grid component
      this.importData = grid.invoke("getData");
      // check for empty rows and remove
      this.importData.forEach((row) => {
        if (
          [null, "", undefined].includes(row.firstname) &&
          [null, "", undefined].includes(row.lastname) &&
          [null, "", undefined].includes(row.UserName)
        ) {
          grid.invoke("removeRow", row.rowKey);
        }
      });
      // refresh importData with updated grid component
      this.importData = grid.invoke("getData");
    },
    downloadTemplate() {
      const url = this.$Region.bulkUploadTemplateURL;
      window.location.href = url;
    },
    addGridRows(numRows) {
      // fill empty rows into the tui grid
      for (let i = 0; i < numRows; i++) {
        this.$refs.grid.invoke("appendRow", {});
      }
    },
    resetGrid() {
      this.$refs.grid.invoke("restore");
      this.importData = [];
      this.addGridRows(25);
      this.validated = false;
    },
    beforeGridChange(ev) {
      // function fires whenever a change is made to a grid cell
      // reset validation
      this.validated = false;
      // reset any error class
      ev.changes.forEach((change) => {
        this.$refs.grid.invoke(
          "removeCellClassName",
          change.rowKey,
          change.columnName,
          "grid-error"
        );
      });
      // handle paste events
      this.gridPasteHandler(ev);
    },
    divPasteHandler() {
      // handles paste event to copy bulk set of rows from template
      if (!this.pasteHandled) {
        const clipboardData =
          window.clipboardData ||
          event.clipboardData ||
          (event.originalEvent && event.originalEvent.clipboardData);

        let pastedText =
          clipboardData.getData("Text") || clipboardData.getData("text/plain");

        if (!pastedText && pastedText.length) {
          return;
        }

        // check if pastedText is multiline
        if (pastedText.indexOf("\n") == -1) {
          // not a multi-line paste. ignore this paste and let grid deal with it
          return;
        }

        // check if first line contains headings
        const re = /(FirstName)+\**[\t\s,]+(LastName)+\**[\t\s,]+(Username)/i;
        if (re.test(pastedText)) {
          // remove first line
          pastedText = pastedText.substring(pastedText.indexOf("\n") + 1);
        }

        // Parse the pasted text from Excel into rows.
        // modified from https://gist.github.com/torjusb/7d6baf4b68370b4ef42f
        // Pasted text is usually separated by a new line for each row,
        // but a single cell can contain multiple lines, which is what
        // we pars out in the first `replace`.
        //
        // We find all text within double-quotes ('"') which has new
        // lines, and put the text within the quotes into capture
        // groups. For each match, we replace its contents again, by
        // removing the new lines with spaces.
        //
        // Then lastly, once we've joined all the multi line cells, we
        // split the entire pasted content on new lines, which gives
        // us an array of each row.
        //
        // Since Windows usually uses weird line-endings, we need to
        // ensure we check for each of the different possible
        // line-endings in every regexp.
        //
        // It also handles cells which contains quotes. There appears
        // to be two ways this is handled. In Google Docs, quotes within
        // cells are always doubled up when pasting, so " becomes "".
        // In Libre Office, the quotes are not normal quotes, some
        // other character is used, so we don't need to handle it any
        // differently.
        const rows = pastedText
          .replace(
            /"((?:[^"]*(?:\r\n|\n\r|\n|\r))+[^"]+)"/gm,
            function (match, p1) {
              // This function runs for each cell with multi lined text.
              return (
                p1
                  // Replace any double double-quotes with a single
                  // double-quote
                  .replace(/""/g, '"')
                  // Replace all new lines with spaces.
                  .replace(/\r\n|\n\r|\n|\r/g, " ")
              );
            }
          )
          // Split each line into rows
          .split(/\r\n|\n\r|\n|\r/g)
          // split rows by tabs
          .map((row) => row.split("\t"))
          // assign to fields
          .map((row) => {
            const newRow = {
              firstname: row[0],
              lastname: row[1],
              UserName: row[2],
              email: row[3],
              password: row[4],
            };
            return newRow;
          });

        // sanitize - remove headings if they are in the paste
        const lowerCaseHeadings = this.gridHeadingsVariations.map((head) =>
          head.toLowerCase()
        );
        const rowsFiltered = rows.filter(
          (row) =>
            !lowerCaseHeadings.includes(row.firstname) &&
            !lowerCaseHeadings.includes(row.lastname) &&
            !lowerCaseHeadings.includes(row.UserName)
        );

        // add in first available empty row, subsequent rows for subsequent inserts
        let appendIndex = 0;
        rowsFiltered.forEach((row) => {
          const emptyRows = this.$refs.grid.invoke("findRows", {
            firstname: null,
            lastname: null,
            UserName: null,
          });
          // if there are empty rows
          if (emptyRows.length > 0) {
            this.$refs.grid.invoke("appendRow", row, {
              at: emptyRows[0].rowKey + appendIndex,
            });
            appendIndex += 1;
            // remove an empty row from at the same time
            this.$refs.grid.invoke(
              "removeRow",
              emptyRows[emptyRows.length - 1].rowKey
            );
          } else {
            this.$refs.grid.invoke("appendRow", row);
          }
        });

        // this.this.validated = false;
        // this.validate();
      } else {
        // toggle paste tracking
        this.pasteHandled = false;
      }
    },
    gridPasteHandler(ev) {
      const grid = this.$refs.grid;
      if (ev.origin === "paste") {
        // sanitize - remove headings if they are in the paste
        ev.changes.forEach((change) => {
          if (
            this.gridHeadingsVariations
              .map((head) => head.toLowerCase())
              .includes(change.nextValue.toLowerCase())
          ) {
            change.nextValue = change.value;
            grid.invoke("removeRow", change.rowKey);
            grid.invoke("appendRow", {});
          }
        });
        this.pasteHandled = true;
      }
    },
    // contextMenu(event) {
      // todo this menu is not working because the paste events are too tricky. Ignoring feature for now.
    //   // handle displaying context menu
    //   // put the menu in the right place
    //   this.contextMenuActive = false;
    //   const offsetX =
    //     event.clientX -
    //     this.$refs.studentListContainer.getBoundingClientRect().left;
    //   const offsetY =
    //     event.clientY -
    //     this.$refs.studentListContainer.getBoundingClientRect().top;
    //   this.contextOffsetX = offsetX + 30;
    //   this.contextOffsetY = offsetY;
    //   // md-offset-x doesn't seem to work... override by setting style info instead
    //   this.$refs.ctxm.$el.style.position=`absolute`;
    //   this.$refs.ctxm.$el.style.left=`${offsetX}px`;
    //   this.contextMenuActive = true;
    // },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .grid-reset-btn {
  margin-left: 10px !important;
}
/deep/ .grid-error {
  background-color: lighten($brand-danger, 35%) !important;
}
.optional-info {
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: lighten($brand-info, 50);
  padding: 10px 20px 10px 20px;
}
</style>