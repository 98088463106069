// indigenous data layers

const RegionIndigenous = [
  {
    id: 'territories',
    name: `Traditional Territories`,
    description: `Traditional Territories`,
    source: `Native Land Digital`,
    sourceUrl: `https://native-land.ca/`,
    mapdata: "ab_ind_territories.geojson",
  },
  {
    id: 'treaties',
    name: `Treaties`,
    description: `Treaties`,
    source: `Native Land Digital`,
    sourceUrl: `https://native-land.ca/`,
    mapdata: "ab_ind_treaties.geojson",
  },
  {
    id: 'languages',
    name: `Languages`,
    description: `Languages`,
    source: `Native Land Digital`,
    sourceUrl: `https://native-land.ca/`,
    mapdata: "ab_ind_languages.geojson",
  },
  {
    id: 'settlements',
    name: `Reserves and Settlements`,
    description: `First Nations Reserves and Metis Settlements`,
    source: `Government of Canada`,
    sourceUrl: `https://open.canada.ca/data/en/dataset/522b07b9-78e2-4819-b736-ad9208eb1067`,
    mapdata: "ab_ind_reserves_settlements.geojson",
  },
  {
    id: 'metisregions',
    name: `Métis Regions`,
    description: `Métis Nation of Alberta Regional Boundaries`,
    source: `Métis Nation of Alberta`,
    sourceUrl: `https://albertametis.com/`,
    mapdata: "ab_ind_metis.geojson",
  },
];

export default RegionIndigenous;
