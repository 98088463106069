<template>
  <div
    id="map"
    class="md-layout md-layout-item map"
  >
    <!-- LAYERS MENU -->
    <md-menu
      v-if="!(rightSidebarFullScreen && EXPLORE_MOBILE_FS_CONTENT === 'stats')"
      id="CoverMapStepLayersMenu"
      :md-offset-x="15"
      :md-active.sync="layersMenuActive"
      class="mapLayersMenu"
    >
      <md-button
        md-menu-trigger
        class="md-icon-button md-fab md-primary"
        @click="geoViewLayers()"
      >
        <md-icon> layers </md-icon>
      </md-button>
      <md-menu-content>
        <div class="md-layout md-layout-gutter">
          <md-list class="md-layout-item">
            <!-- BASEMAP SELECTION -->
            <md-subheader> Select Basemap </md-subheader>
            <md-list-item>
              <md-radio
                v-model="basemapStyle"
                class="md-layout-item"
                value="streets"
              >
                Streets
              </md-radio>
            </md-list-item>
            <md-list-item>
              <md-radio
                v-model="basemapStyle"
                class="md-layout-item"
                value="satellite"
              >
                Satellite
              </md-radio>
            </md-list-item>
            <!-- OBSERVATIONS -->
            <md-divider> - </md-divider>
            <md-list-item
              v-for="item in observationLayersNonInd"
              :key="item"
              class="obs-layer-switch-item"
            >
              <template v-if="item !== 'observations'">
                <i
                  :class="`fas ${$Region.observation_types[item].icon}`"
                  :style="`max-width: 14px; padding-right: 0px; margin-left: 0px; color: ${$Region.observation_types[item].color}`"
                />
              </template>
              <template v-else>
                <i
                  :class="`fas ${$Region.observation_types.water.icon}`"
                  :style="`padding-right: 0px; margin-left: -14px; color: ${$Region.observation_types.water.color}`"
                />
                <i
                  :class="`fas ${$Region.observation_types.general.icon}`"
                  :style="`padding-right: 0px; margin-left: 2px; color: ${$Region.observation_types.general.color}`"
                />
              </template>
              <md-switch
                v-model="SHOW_OBSERVATIONS[item]"
                :disabled="EXPLORE_MODE === 'create'"
                class="md-layout-item obs-layer-switch"
              >
                {{ (item === 'observations') ? 'User Observations' : $Region.observation_types[item].title }}
              </md-switch>
            </md-list-item>
            <!-- virtual tours -->
            <md-list-item>
              <i
                :class="`fas fa-camera`"
                :style="`max-width: 14px; padding-right: 0px; margin-left: 0px;`"
              />
              <md-switch
                v-model="showVirtours"
                :disabled="EXPLORE_MODE === 'create'"
                class="md-layout-item"
              >
                Virtual Tours
              </md-switch>
            </md-list-item>
            <!-- LANDUSE LAYER STUFF -->
            <md-divider v-if="bitmapsReady">
              -
            </md-divider>
            <md-list-item
              v-if="
                bitmapsReady &&
                  EXPLORE_MODE === 'scenario' &&
                  scenario.type === 'Land Use' &&
                  SHOW_LANDUSE_OVERLAY
              "
            >
              <md-switch
                v-model="showPlanningMarkersSwitch"
                :disabled="SHOW_PLANNING_MARKERS_LOCKED"
                class="md-layout-item"
              >
                Show Planning Markers
              </md-switch>
            </md-list-item>
            <md-list-item v-if="bitmapsReady">
              <md-switch
                v-model="showLanduseOverlaySwitch"
                class="md-layout-item"
              >
                Show Data Layer
              </md-switch>
            </md-list-item>
            <md-list-item v-if="bitmapsReady && $Region.climateModuleActive">
              <md-radio
                v-model="mapDataLayer"
                class="md-layout-item"
                value="landuse"
              >
                Land Use
              </md-radio>
            </md-list-item>
            <div v-if="climateIndsReady">
              <md-list-item
                v-for="ind in climateIndicators"
                :key="ind.name"
              >
                <md-radio
                  v-model="mapDataLayer"
                  class="md-layout-item"
                  :value="ind.name"
                >
                  {{ ind.name }}
                </md-radio>
              </md-list-item>
            </div>
            <!-- INDIGENOUS LAYER STUFF -->
            <div v-if="EXPLORE_MODE !== 'create' && indFeaturesReady">
              <md-divider> - </md-divider>
              <md-subheader>
                <i
                  class="fas fa-feather"
                  style="padding-right: 10px"
                />
                Indigenous Map Layers
              </md-subheader>
              <div
                v-for="(layer, index) in $Region.indigenousLayers"
                :key="layer.id"
                :style="cssVars(index)"
              >
                <md-list-item class="ind-layer-checkbox ind-layer">
                  <md-switch
                    v-model="showIndLayerSwitches[index]"
                    class="md-layout-item"
                    :class="[
                      { 'ind-layer-checked': showIndLayerSwitches[index] },
                    ]"
                    @change="toggleIndLayerSwitch(index)"
                  >
                    {{ layer.name }}
                  </md-switch>
                </md-list-item>
                <collapse-transition>
                  <div v-if="SHOW_IND_LAYERS[index]">
                    <!-- toggle all switch -->
                    <md-list-item class="ind-feature-checkbox">
                      <md-checkbox
                        v-model="indFeaturesAll[index]"
                        class="md-layout-item md-caption"
                        @change="toggleAllIndFeatures(index)"
                      >
                        Toggle All
                      </md-checkbox>
                    </md-list-item>
                    <md-list-item
                      v-for="item in indLayers[layer.id].mapJson.features"
                      :key="item.properties.id"
                      class="ind-feature-checkbox"
                    >
                      <button
                        @click.capture="clicked"
                        @click.stop="indRegionFlyTo(item)"
                      >
                        <md-icon> my_location </md-icon>
                      </button>
                      <md-checkbox
                        v-model="showIndFeaturesSwitches[item.properties.id]"
                        class="md-layout-item md-caption"
                        @change="
                          toggleIndFeatureSwitch(layer.id, item.properties.id)
                        "
                      >
                        {{ item.properties.Name }}
                      </md-checkbox>
                    </md-list-item>
                  </div>
                </collapse-transition>
              </div>
              <md-list-item
                v-for="item in observationLayersInd"
                :key="item"
                class="obs-layer-switch-item"
              >
                <i
                  :class="`fas ${$Region.observation_types[item].icon}`"
                  :style="`max-width: 14px; padding-right: 0px; margin-left: 0px; color: ${$Region.observation_types[item].color}`"
                />
                <md-switch
                  v-model="SHOW_OBSERVATIONS[item]"
                  :disabled="EXPLORE_MODE === 'create'"
                  class="md-layout-item obs-layer-switch"
                >
                  {{ $Region.observation_types[item].title }}
                </md-switch>
              </md-list-item>
            </div>
          </md-list>
        </div>
      </md-menu-content>
    </md-menu>
    <md-speed-dial
      v-if="!(rightSidebarFullScreen && EXPLORE_MOBILE_FS_CONTENT === 'stats')"
      id="CoverMapStepSpeedDial"
      class="mapSpeedDial md-primary"
      md-event="hover"
      md-direction="bottom"
    >
      <md-speed-dial-target class="md-icon-button md-primary">
        <md-icon class="md-morph-initial">
          navigation
        </md-icon>
        <md-icon class="md-morph-final">
          near_me
        </md-icon>
      </md-speed-dial-target>
      <md-speed-dial-content>
        <md-button
          class="md-icon-button md-fab"
          @click="geoLocate()"
        >
          <md-icon>my_location</md-icon>
        </md-button>
        <md-button
          v-if="bitmapsReady"
          class="md-icon-button md-fab"
          @click="flyTo()"
        >
          <md-icon>zoom_out_map</md-icon>
        </md-button>
        <md-button
          v-if="EXPLORE_MODE !== 'create'"
          class="md-icon-button md-fab"
          @click="newObservationListener()"
        >
          <md-icon>place</md-icon>
        </md-button>
      </md-speed-dial-content>
    </md-speed-dial>
    <div
      v-if="collectingMapData"
      class="progress-bar"
    >
      <h4 class="card-title">
        Collecting map data...
      </h4>
      <md-progress-bar
        class="md-primary"
        md-mode="buffer"
        :md-value="progressAmount"
        :md-buffer="progressAmount"
      />
    </div>
    <right-sidebar-toggle
      v-if="showRightSidebar"
      id="CoverMapRightSidebar"
      class="slider-card md-elevation-4"
    />
    <intro-tour
      v-if="showRightSidebar"
      name="CoverMapSidebarToggle"
      :steps="coverMapRightSidebarTourSteps"
    />
    <intro-tour :steps="coverMapTourSteps" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import * as mapboxgl from "mapbox-gl";
import proj4 from "proj4";
import { bbox, point, polygon, booleanPointInPolygon } from "@turf/turf";
import { v4 as uuidv4 } from "uuid";
import { debounce } from "debounce";
import { CollapseTransition } from "vue2-transitions";
import { MapLayers } from "./mixins/MapLayers";
import swal from "sweetalert2";
import "@/mapboxgl-texture-overlay"; // TODO build production version (should be smaller/minified?)
import "@/jickle"; // inherently made available as Window.JICKLE (aka JICKLE)
import RightSidebarToggle from "../Layout/RightSidebarToggle";
import { ResponsiveLayoutHelpers } from "@/pages/Dashboard/Components/mixins/ResponsiveLayoutHelpers";
import IntroTour from "@/pages/Dashboard/Components/IntroTour";
import { TourSteps } from "@/pages/Dashboard/Components/mixins/TourSteps";
import { User } from "./mixins/User";

// collect observation types from the region config
const Region = require(`@/${process.env.VUE_APP_CONFIG_ROOT}region-config.js`).default;
// replace 'water' and 'general' with 'observations' in the observation types
const observationTypes = Region.observation_types_alt;

export default {
  name: "CoverMap",

  components: {
    CollapseTransition,
    RightSidebarToggle,
    IntroTour,
  },
  mixins: [ResponsiveLayoutHelpers, MapLayers, TourSteps, User], // bunch of stuff for controlling mapbox layers and style, including landuse overlay bitmaps

  props: {
    scenario: {
      type: Object,
      default: () => null,
    },
    year: {
      type: Number,
      default: () => null,
    },
    intensityMapData: {
      type: Object,
      default: () => null,
    },
    bitmapsReady: {
      type: Boolean,
      default: false,
    },
    climateIndsReady: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      customGeoLocateControl: null, // to manage the mapboxgl geolocate control so we can control it programmatically using a custom button instead of the default built-in control
      layersMenuActive: false,
      basemapStyle: null,
      mapDataLayer: null,
      showLanduseOverlaySwitch: null,
      showPlanningMarkersSwitch: null,
      showIndLayerSwitches: null,
      showIndFeaturesSwitches: null,
      showVirtours: null,
      hoveredRegionID: null,
      hoveredRegionSource: null,
      regionPopup: new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      }),
      indLayerPopup: new mapboxgl.Popup({
        closeButton: true,
        closeOnClick: true,
      }),
      regionJsonPath: null, // path to geojson file
      regionJicklePath: null, // path to jickle file
      markerPrevLngLat: null, // fallback when validating marker is within study area
      newObsListenerActive: false, // whether or not the map is listening for click for a new observation marker
      indLayers: {}, // collection of indigenous map layers
      indLayersReady: 0, // make sure we don't try to render UI elements ore  map layers before the layer data is loaded
      indFeaturesReady: false,
      indFeaturesAll: null,
      // there must be as many or more colors defined here as there are indigenous region layers
      indLayerColors: ["#E98E33", "#753D04", "#5E8438", "#E833B7", "#027CFF"],
      indLayerFillColors: [
        "hsla(30, 78%, 50%, 0.2)",
        "hsla(30, 94%, 50%, 0.2)",
        "hsla(90, 57%, 50%, 0.2)",
        "hsla(317, 78%, 50%, 0.2)",
        "hsla(211, 99%, 50%, 0.2)",
      ],
    };
  },

  computed: {
    ...mapGetters([
      types.getters.SIDEBAR_OPEN,
      types.getters.EXPLORE_MOBILE_FS_CONTENT,
      types.getters.BASEMAP_STYLE,
      types.getters.MAP_FLYTO,
      types.getters.MAP_ACTION_TRIGGER,
      types.getters.SHOW_LANDUSE_OVERLAY,
      types.getters.SHOW_PLANNING_MARKERS,
      types.getters.SHOW_PLANNING_MARKERS_LOCKED,
      types.getters.PLANNING_MAKERS_DRAGGABLE,
      types.getters.REFRESH_PLANNING_MARKERS,
      types.getters.REFRESH_LANDUSE_LAYER,
      types.getters.REFRESH_REGIONS_LAYER,
      types.getters.REMOVE_REGIONS_LAYER,
      types.getters.SHOW_PLANNING_LAYER,
      types.getters.ACTIVE_SCENARIO,
      types.getters.ACTIVE_LT,
      types.getters.ALLOCATION_AREA_MAX,
      types.getters.REMOVE_ALLOCATION_ID,
      types.getters.REMOVE_PLANNING_MARKERS,
      types.getters.SHOW_OBSERVATIONS,
      types.getters.PREV_SHOW_OBSERVATIONS,
      types.getters.SHOW_VIRTOURS,
      types.getters.SIDEBAR_CONTENT_OPEN,
      types.getters.OBSERVATIONS_LAST_LOADED,
      types.getters.OBSERVATIONS_ALL_JSON,
      types.getters.HIDDEN_OBSERVATION,
      types.getters.SELECTED_OBSERVATION,
      types.getters.SELECTED_OBSERVATION_TYPE,
      types.getters.REFRESH_OBSERVATION_DATA,
      types.getters.OBSERVATION_FEATURE_TO_LOAD,
      types.getters.MAP_DATALAYER_RELOAD,
      types.getters.SHOW_IND_LAYERS,
      types.getters.SHOW_IND_FEATURES,
      types.getters.SELECTED_REGION_TYPE,
    ]),

    showRightSidebar() {
      // when to display the right sidebar
      return (
        this.bitmapsReady &&
        this.EXPLORE_MODE === "scenario" &&
        !this.isNewObservation() &&
        !this.rightSidebarFullScreen
      );
    },
    sidebarOpen() {
      // any of these have potential to require map resize
      return [this.SIDEBAR_OPEN, this.EXPLORE_MODE, this.SIDEBAR_CONTENT_OPEN];
    },
    mapOptions() {
      return {
        container: "map",
        style: this.mapboxStyles[this.BASEMAP_STYLE], // from MapLayers mixin
        center: [this.$Region.map_coords.lng, this.$Region.map_coords.lat],
        zoom: 5,
        maxZoom: 22,
        antialias: true,
        bearingSnap: 0,
      };
    },
    featureRegionName() {
      // get the name field to use for a given feature geom depending on watershed scale
      if (this.SELECTED_REGION_TYPE !== this.$Region.region_type) {
        // not using watersheds - other region types should use 'Name'
        return 'Name';
      }
      let ws_scale = this.SELECTED_REGIONS.length;
      if (ws_scale === 3) {
        ws_scale = 2; // the sub-watersheds are the finest scale - same field is used for SELECTED_REGIONS.length=2 and SELECTED_REGIONS.length=3
      }
      return this.$Region.base_region_field[ws_scale];
    },
    landUseTypes() {
      return this.$Region.landUseTypes;
    },
    climateIndicators() {
      return this.$Region.climateIndicators;
    },
    sharedObsInQuery() {
      // is there a shared obs in the query to load
      return (
        "obs" in this.$route.query &&
        this.$route.query.obs !== null &&
        this.$route.query.obs !== ""
      );
    },
    observationLayersInd() {
      // get the observation layers from the region config filtered to indigenous
      const allObsLayers = Object.keys(this.$Region.observation_types);
      return allObsLayers.filter((layer) => ("isIndigenous" in this.$Region.observation_types[layer] && this.$Region.observation_types[layer].isIndigenous));
    },
    observationLayersNonInd() {
      // get the observation layers from the region config filtered to non-indigenous
      const allObsLayers = this.$Region.observation_types_alt;
      return allObsLayers.filter((layer) => !this.observationLayersInd.includes(layer));
    },
  },

  watch: {
    // -----------------------------------------------------------------
    // TOC WATCH - UI LAYOUT GENERAL WATCHERS
    // -----------------------------------------------------------------
    EXPLORE_MODE(newVal, oldVal) {
      if (newVal === "create") {
        // track the show observations state and re-init it after done create mode
        this.SET_PREV_SHOW_OBSERVATIONS(this.SHOW_OBSERVATIONS);
        this.SHOW_OBSERVATIONS_OFF();
      }
      if (oldVal === "create") {
        this.clearMarkers();
        if (this.ACTIVE_SCENARIO_WIZARD_TAB === "tab_region") {
          // remove regions layers when leaving EXPLORE_MODE === 'create'
          this.removeRegionsLayers();
        }
        this.PREV_SHOW_OBSERVATIONS.forEach((obsType) => {
          this.SHOW_OBSERVATIONS_ON(obsType);
        });
      }
    },
    sidebarOpen() {
      // make sure the map re-renders whenever it might get resized
      this.map.resize();
      setTimeout(() => this.map.resize(), 200);
      setTimeout(() => this.map.resize(), 400);
      setTimeout(() => this.map.resize(), 600);
    },

    // -----------------------------------------------------------------
    // TOC WATCH - TIMELINE WATCHERS
    // -----------------------------------------------------------------
    year() {
      this.activateYear();
    },

    // -----------------------------------------------------------------
    // TOC WATCH - GENERAL MAP WATCHERS
    // -----------------------------------------------------------------
    basemapStyle(newVal, oldVal) {
      if (oldVal !== null) {
        this.layersMenuActive = false;
        this.setStyle(newVal);
      }
    },
    MAP_FLYTO(newVal) {
      // fly to new position on map if MAP_FLYTO is changed in the store
      if (newVal !== null) {
        const currentZoom = this.map.getZoom();
        const zoom = currentZoom > 9 ? currentZoom : 9;
        // check if flyto contains a 3rd value to indicate moving current marker required
        const coords = this.MAP_FLYTO.split(",").map((x) => parseFloat(x));
        const moveMarker = coords.length > 2 ? true : false;
        if (moveMarker) {
          coords.pop(); // remove the extra item
          this.$options.currentObsMarker.setLngLat(coords);
        }
        const flyOptions = {
          center: coords,
          zoom: zoom,
          speed: 0.8,
          pitch: 20,
          bearing: 5,
        };
        this.map.flyTo(flyOptions);
        // reset the flyto point
        this.SET_MAP_FLYTO(null);
      }
    },
    MAP_ACTION_TRIGGER(newVal) {
      // watch for map action requests
      if (newVal !== null) {
        this.doMapAction(newVal);
      }
    },

    // -----------------------------------------------------------------
    // TOC WATCH - LANDUSE OVERLAY & BITMAP WATCHERS
    // -----------------------------------------------------------------
    bitmapsReady(newVal, oldVal) {
      if (newVal === true) {
        this.closeProgressBar();
        this.map.resize(); // in case right sidebar is toggled
        this.loadIntensityMapData();
        if (this.EXPLORE_MODE === "scenario") {
          // load region data and planning markers from scenario
          if (this.SHOW_PLANNING_MARKERS) {
            this.loadScenarioAllocations();
          }
          this.loadScenarioStudyAreaJson();
          // fly to newly loaded scenario region.  In create mode map should already be zoomed to the right position
          if (
            "leftCoord" in this.scenario &&
            "bottomCoord" in this.scenario &&
            "rightCoord" in this.scenario &&
            "topCoord" in this.scenario &&
            !this.OBSERVATION_FEATURE_TO_LOAD
          ) {
            this.flyTo();
          }
          // a resize to fix some style issues
          setTimeout(() => this.map.resize(), 600);
        }
      } else if (newVal === false && oldVal === true) {
        // scenario was closed
        this.map.resize();
        this.removePlanningLayers();
      }
    },
    showLanduseOverlaySwitch(newVal, oldVal) {
      if (oldVal !== null) {
        this.TOGGLE_LANDUSE_OVERLAY();
      }
    },
    SHOW_LANDUSE_OVERLAY(newVal) {
      if (newVal === true) {
        this.addLanduseLayer();
        if (this.SHOW_PLANNING_MARKERS) {
          this.loadMarkers();
        }
      } else {
        this.removeLanduseLayer();
        if (this.SHOW_PLANNING_MARKERS) {
          this.clearMarkers();
        }
      }
    },
    REFRESH_LANDUSE_LAYER() {
      // any change should trigger map landuse layers to redraw from beginning
      this.launchProgressBar();
      this.removeLanduseLayer();
      this.addLanduseLayer();
    },
    mapDataLayer(newVal, oldVal) {
      // update the map datalayer selection
      if (newVal !== oldVal) {
        this.SET_MAP_DATALAYER(newVal);
        if (newVal !== "landuse") {
          this.SET_CLIMATE_TAB(`tab_${newVal}`);
        }
      }
    },
    MAP_DATALAYER(newVal) {
      // watch for map datalayer being changed via vuex store
      this.mapDataLayer = newVal;
    },
    MAP_DATALAYER_RELOAD() {
      // reload intensitymapdata, repaint etc
      this.removeLanduseLayer();
      this.addLanduseLayer(this.MAP_DATALAYER);
      this.setTextureColors(this.MAP_DATALAYER);
    },

    // -----------------------------------------------------------------
    // TOC WATCH - PLANNING REGIONS WATCHERS
    // -----------------------------------------------------------------
    REFRESH_REGIONS_LAYER() {
      // any change should trigger map regions layers to redraw from beginning
      this.removeRegionsLayers();
      this.initRegionsLayers();
    },
    REMOVE_REGIONS_LAYER() {
      this.removeRegionsLayers();
    },

    // -----------------------------------------------------------------
    // TOC WATCH - PLANNING LAYERS AND MARKERS WATCHERS
    // -----------------------------------------------------------------
    showPlanningMarkersSwitch(newVal, oldVal) {
      if (oldVal !== null) {        
        if (newVal === true) {
          this.SHOW_PLANNING_MARKERS_ON();
        } else {
          this.SHOW_PLANNING_MARKERS_OFF();
        }
      }
    },
    SHOW_PLANNING_MARKERS(newVal) {
      if (newVal === true) {
        this.loadMarkers();
        this.showPlanningMarkersSwitch = true;
      } else {
        this.clearMarkers();
        this.showPlanningMarkersSwitch = false;
      }
    },
    REFRESH_PLANNING_MARKERS() {
      // force the planning markers to redraw
      this.loadMarkers();
    },
    PLANNING_MAKERS_DRAGGABLE(newVal) {
      if (newVal === true) {
        this.$options.planningMarkers.forEach((marker) =>
          marker.setDraggable(true)
        );
      } else {
        this.$options.planningMarkers.forEach((marker) =>
          marker.setDraggable(false)
        );
      }
    },
    SHOW_PLANNING_LAYER(newVal) {
      if (newVal === true) {
        this.launchProgressBar();
        this.removeRegionsLayers();
        this.addPlanningLayers();
      } else {
        this.removePlanningLayers();
      }
    },
    REMOVE_ALLOCATION_ID(newVal) {
      if (newVal !== 0) {
        // remove marker from map
        this.$options.planningMarkers[newVal - 1].remove();
        this.$options.planningMarkers.splice(newVal - 1, 1);
        // update marker numbering for markers with higher id's than the one removed
        this.$options.planningMarkers.forEach((marker) => {
          if (marker.getElement().innerHTML > newVal) {
            marker.getElement().innerHTML -= 1;
          }
        });
        this.SET_REMOVE_ALLOCATION_ID(0); // need to reset in case user repeatedly removes allocation with the same index
      }
    },
    REMOVE_PLANNING_MARKERS() {
      // remove all planning markers from map
      this.clearMarkers();
    },
    OBSERVATION_FEATURE_TO_LOAD(newVal) {
      // activate and fly to observation if it OBSERVATION_FEATURE_TO_LOAD is changed in the store
      if (newVal !== null) {
        this.activateObservation(newVal)
      }
    },

    // -----------------------------------------------------------------
    // TOC WATCH - OBSERVATIONS WATCHERS
    // -----------------------------------------------------------------
    // setup watchers for each observation type
    ...observationTypes.reduce((acc, type) => ({
      ...acc,
      [`SHOW_OBSERVATIONS.${type}`]: {
        handler(newVal) {
          if (newVal === true) {
            // switch turned on, add the observation layer to the map
            if (type === 'observations') {
              // user observations
              this.addObservationsLayer();
            } else {
              // system observations layer
              this.addSystemObservationsLayer(type);
            }
          } else {
            // switch turned off, remove the observation layer from the map
            if (type === 'observations') {
              // user observations
              this.removeObservationsLayer();
            } else {
              // system observations layer
              this.removeSystemObservationsLayer(type);
            }
          }
        },
      },
    }), {}),
    showVirtours(newVal) {
      if (this.SHOW_VIRTOURS !== newVal) {
        this.TOGGLE_SHOW_VIRTOURS();
      }
    },
    SHOW_VIRTOURS: {
      handler(newVal) {
        if (newVal === true) {
          this.showVirtours = true;
          this.addVirtualToursLayer();
        } else {
          this.showVirtours = false;
          this.removeVirtualToursLayer();
        }
      },
      immediate: true,
    },
    SELECTED_OBSERVATION(newVal, oldVal) {
      // clear marker if selected observation is nullified
      if (newVal === null) {
        this.clearSelectedObservation(oldVal.layer.id);
      }
    },
    SELECTED_OBSERVATION_TYPE(newVal) {
      // change marker icon to match new type
      if (this.SELECTED_OBSERVATION !== null) {
        this.updateObsMarkerIcon(newVal);
      }
    },
    REFRESH_OBSERVATION_DATA() {
      // force the observations map source to reload new data
      this.map.getSource("observations").setData(this.OBSERVATIONS_ALL_JSON["observations"]);
    },
  },

  beforeMount() {
    if (this.sharedObsInQuery) {
      this.SHOW_OBSERVATIONS_ON('observations');
    }
    this.showLanduseOverlaySwitch = this.SHOW_LANDUSE_OVERLAY;
    this.showPlanningMarkersSwitch = this.SHOW_PLANNING_MARKERS;
    this.basemapStyle = this.BASEMAP_STYLE;
    this.mapDataLayer = this.MAP_DATALAYER;
    this.launchProgressBar(); // progress bar for map data loading
    this.initIndLayersSwitches();
  },

  mounted() {
    this.initMap();
    if (this.EXPLORE_MODE === "create") {
      this.SET_PREV_SHOW_OBSERVATIONS(this.SHOW_OBSERVATIONS);
      this.SHOW_OBSERVATIONS_OFF();
      if ("studyArea" in this.ACTIVE_SCENARIO) {
        this.regionJsonPath = this.ACTIVE_SCENARIO.studyArea;
        this.regionJicklePath =
          this.$Region.base_raster_path +
          "_" +
          this.regionJsonPath.replace(/.geojson/, "_bitmaps.jickle");
      }
      if (this.ACTIVE_SCENARIO_WIZARD_TAB === "tab_region") {
        this.initRegionsLayers();
      } else {
        // other tabs need planning layers
        this.getPlanningRegionJson();
      }
    }
  },

  beforeDestroy() {
    // Clean up the map
    this.clearMarkers();
    this.map.remove();
  },

  methods: {
    ...mapActions([
      types.actions.SELECTED_REGIONS_PUSH,
      types.actions.SET_SELECTED_REGION_NAME,
      types.actions.UPDATE_ACTIVE_SCENARIO,
      types.actions.ALLOCATIONS_UPDATED_PUSH,
      types.actions.ALLOCATIONS_PUSH,
      types.actions.SET_ALLOCATION_POINT_SELECTED,
      types.actions.SET_REMOVE_ALLOCATION_ID,
      types.actions.SHOW_PLANNING_MARKERS_ON,
      types.actions.SHOW_PLANNING_MARKERS_OFF,
      types.actions.SHOW_OBSERVATIONS_ON,
      types.actions.SHOW_OBSERVATIONS_OFF,
      types.actions.SET_PREV_SHOW_OBSERVATIONS,
      types.actions.TOGGLE_SHOW_VIRTOURS,
      types.actions.OBSERVATIONS_LOAD_ALL,
      types.actions.OBSERVATIONS_LOAD_ALL_SYSTEM,
      types.actions.OBSERVATIONS_ALL_FEATURES_SPLICE,
      types.actions.SET_HIDDEN_OBSERVATION,
      types.actions.CLEAR_HIDDEN_OBSERVATION,
      types.actions.RESTORE_HIDDEN_OBSERVATION,
      types.actions.CLEAR_OBSERVATION_FEATURE_TO_LOAD,
      types.actions.SET_SELECTED_OBSERVATION,
      types.actions.SET_SELECTED_OBSERVATION_TYPE,
      types.actions.SET_SELECTED_VIRTUAL_TOUR,
      types.actions.SET_MAP_FLYTO,
      types.actions.SET_SIDEBAR_CONTENT_TYPE,
      types.actions.SET_EXPLORE_MOBILE_FS_CONTENT,
      types.actions.TOGGLE_SIDEBAR_CONTENT,
      types.actions.TOGGLE_SIDEBAR,
      types.actions.SET_CLIMATE_TAB,
      types.actions.SET_MAP_ACTION_TRIGGER,
      types.actions.SET_MAP_DATALAYER,
      types.actions.IND_LAYERS_INIT,
      types.actions.TOGGLE_IND_LAYER,
      types.actions.IND_FEATURES_INIT,
      types.actions.TOGGLE_IND_FEATURE,
      types.actions.TOGGLE_REFRESH_OBSERVATION_DATA
    ]),

    // -----------------------------------------------------------------
    // TOC METHODS - TIMELINE METHODS
    // -----------------------------------------------------------------
    activateYear() {
      if (this.bitmapsReady) {
        this.setBitmapTime(this.year);
      }
    },

    // -----------------------------------------------------------------
    // TOC METHODS - GENERAL MAP METHODS
    // -----------------------------------------------------------------
    initMap() {
      // Initiate the map instance
      mapboxgl.accessToken = this.$Region.mapbox_token;
      this.map = new mapboxgl.Map(this.mapOptions);

      // Initiate layers on load
      const _map = this.map;
      this.map.on("load", () => {
        // add the icons used for observations
        this.loadObservationIcons();

        // add the geolocate control
        this.customGeoLocateControl = new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: false,
        });
        this.map.addControl(this.customGeoLocateControl, "top-left");
      });

      this.map.on("style.load", () => {
        const waiting = () => {
          if (!_map.isStyleLoaded() || !this.indFeaturesReady) {
            setTimeout(waiting, 200);
          } else {
            // add some layers
            this.addHillshadeLayer();
            this.addLanduseLayer();
            if (this.EXPLORE_MODE !== "scenario") {
              this.closeProgressBar();
            } else if (this.bitmapsReady) {
              this.closeProgressBar();
              if (!this.OBSERVATION_FEATURE_TO_LOAD) {
                this.flyTo();
              }
            } // else progress bar will be closed once bitmaps load

            // add observations layers
            this.$Region.observation_types_alt.forEach((type) => {
              if (this.SHOW_OBSERVATIONS[type]) {
                if (type === 'observations') {
                  // user observations
                  this.addObservationsLayer();
                } else {
                  // system observations layer
                  this.addSystemObservationsLayer(type);
                }
              }
            });

            // add virtual tours
            if (this.SHOW_VIRTOURS) {
              this.addVirtualToursLayer();
            }

            // add indigenous territories layer
            this.SHOW_IND_LAYERS.forEach((layer, index) => {
              if (layer === true) {
                this.addIndLayer(this.$Region.indigenousLayers[index], index);
                // set filter
                this.filterIndLayer(this.$Region.indigenousLayers[index].id);
              }
            });

            // do any requested actions
            if (this.MAP_ACTION_TRIGGER !== null) {
              this.doMapAction(this.MAP_ACTION_TRIGGER);
            }

            // add map zoom and pan event handlers so we can detect it for gamification conditions
            _map.on("moveend", this.emitMapMoveend);
          }
        };
        waiting();
      });
    },
    geoLocate() {
      // trigger mapbox geolocate action
      this.customGeoLocateControl.trigger();
      this.updateUserHistory('m_LocateMap');      
    },
    geoViewLayers() {
      this.updateUserHistory('m_ViewMapLayers');      
    },    
    getBaseMapCursor() {
      // what type of cursor to revert to on a mouseleave event
      return this.newObsListenerActive && this.PLANNING_MAKERS_DRAGGABLE
        ? "crosshair"
        : "";
    },
    getProjectedCoords(lat, lng) {
      // convert lat lng coords to region dest projection
      const sourceproj = new proj4.Proj("EPSG:4326"),
        destproj = new proj4.Proj(this.$Region.base_projection);
      return proj4.transform(sourceproj, destproj, [lng, lat]);
    },
    getMarkerLatLng(marker) {
      // get the latlng from a marker
      const lat = marker.getLngLat()["lat"],
        lng = marker.getLngLat()["lng"];
      return [lat, lng];
    },
    doMapAction(mapaction) {
      // perform an action based on the mapaction input
      if (mapaction === "newObservation") {
        this.newObservationListener("swal");
      }
      this.SET_MAP_ACTION_TRIGGER(null);
    },
    emitMapMoveend() {
      // emit a map.moveend event
      this.$emit("onMapMoveend");
    },
    findTraditionalTerritory() {
      // user toggled on Traditional Territories in Map Layers
      this.updateUserHistory('m_FindTraditionalTerritory');   
    },  
    findTreatyArea() {
      // user toggled on Treaties in Map Layers
      this.updateUserHistory('m_FindTreatyArea');      
    },  
    findTraditionalLanguages() {
      // user toggled on Languages in Map Layers
      this.updateUserHistory('m_FindTraditionalLanguages');      
    },  
    findReservesSettlements() {
      // user toggled on Reserves and Settlements in Map Layers
      this.updateUserHistory('m_FindReservesSettlements');      
    },  
    findMetisRegions() {
      // user toggled on Metis Regions in Map Layers
      this.updateUserHistory('m_FindMetisRegions');      
    },                  
    // -----------------------------------------------------------------
    // TOC METHODS - PLANNING REGIONS METHODS
    // -----------------------------------------------------------------
    regionFillOnMouseMove(e) {
      // hover effect for regions
      // needs to be a separate function (not inline) so it can be removed during layer updates
      if (e.features.length > 0) {
        if (this.hoveredRegionID) {
          this.map.setFeatureState(
            { source: "regions", id: this.hoveredRegionID },
            { hover: false }
          );
        }
        this.hoveredRegionID = e.features[0].id;
        this.map.setFeatureState(
          { source: "regions", id: this.hoveredRegionID },
          { hover: true }
        );
        if (this.SELECTED_REGIONS.length < 3) {
          this.map.getCanvas().style.cursor = "pointer";
        }
      }
      const coordinates = e.lngLat;
      const description = e.features[0].properties[this.featureRegionName];
      this.regionPopup
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(this.map);
    },
    regionFillOnMouseLeave() {
      // turn off hover effect when leaving region
      // needs to be a separate function so it can be removed during layer updates
      if (this.hoveredRegionID) {
        this.map.setFeatureState(
          { source: "regions", id: this.hoveredRegionID },
          { hover: false }
        );
      }
      this.hoveredRegionID = null;
      this.map.getCanvas().style.cursor = this.getBaseMapCursor();
      this.regionPopup.remove();
    },
    regionFillOnClick(e) {
      // click handler for region selection
      // needs to be a separate function so it can be removed during layer updates
      this.pushSelectedRegion(e.features[0].properties[this.featureRegionName]);
    },
    addRegionsLayers() {
      // add the regions data source and region-fills, region-outlines layers
      this.map.addSource("regions", {
        type: "geojson",
        data: this.regionJson,
      });
      this.map.addLayer({
        id: "region-fills",
        type: "fill",
        source: "regions",
        paint: {
          "fill-color": "#627BC1",
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            0.5,
            0.25,
          ],
        },
      });
      this.map.addLayer({
        id: "region-outlines",
        type: "line",
        source: "regions",
        paint: {
          "line-color": "#627BC1",
          "line-width": 3,
        },
      });
      // When the user moves their mouse over the region-fill layer do hover effect
      this.map.on("mousemove", "region-fills", this.regionFillOnMouseMove);

      // When the mouse leaves the region-fill layer remove hover effect
      this.map.on("mouseleave", "region-fills", this.regionFillOnMouseLeave);

      // set region when user clicks
      // only until 3 selected regions for watersheds or 1 selected regions for others
      if (this.SELECTED_REGIONS.length < 1 || (this.SELECTED_REGIONS.length < 3 && this.SELECTED_REGION_TYPE === this.$Region.region_type)) {
        this.map.on("click", "region-fills", this.regionFillOnClick);
      }
    },
    removeRegionsLayers() {
      // remove region popup, click handler, hover actions and region layers
      this.regionPopup.remove();
      this.map.off("click", "region-fills", this.regionFillOnClick);
      this.map.off("mousemove", "region-fills", this.regionFillOnMouseMove);
      this.map.off("mouseleave", "region-fills", this.regionFillOnMouseLeave);
      this.removeLayer("region-fills");
      this.removeLayer("region-outlines");
      this.removeSource("regions");
    },
    getPlanningRegionJson() {
      // load the region json from server - used when explore page is launched directly to any tab other than tab_region
      this.launchProgressBar();
      this.$Auth.currentSession().then((authData) => {
        const myInit = {
          headers: { Authorization: authData.idToken.jwtToken },
        };
        this.$API
          .get("api", "/public/" + this.regionJsonPath, myInit)
          .then((body) => {
            this.regionJson = body;
            this.addPlanningLayers();
            this.map.fitBounds(bbox(body), { padding: 20 }); // zoom to region
            this.closeProgressBar();
          })
          // eslint-disable-next-line
          .catch((err) => console.log(err));
      });
    },
    pushSelectedRegion(region) {
      this.SELECTED_REGIONS_PUSH(region);
      this.SET_SELECTED_REGION_NAME(region);
      // force bitmaps to clear in case user is going back and forth and drilling down to deeper watershed without a reset
      this.$emit("newPlanningJickle", null);
      this.loadNextRegion(false);
    },
    loadNextRegion(dsOverride) {
      // load next region during scenario create/edit
      this.launchProgressBar();
      // remove regions layers and event handlers
      this.removeRegionsLayers();
      // Load the next level
      this.$Auth.currentSession().then((authData) => {
        const myInit = {
          headers: { Authorization: authData.idToken.jwtToken },
        };
        let dsPath = "";
        if (dsOverride) {
          dsPath = this.$Region.base_region.replace(".geojson", "");
        } else if (this.SELECTED_REGIONS.length > 1) {
          dsPath = this.SELECTED_REGIONS[0];
          let i;
          for (i = 1; i < this.SELECTED_REGIONS.length; i++) {
            dsPath = dsPath + "__" + this.SELECTED_REGIONS[i];
          }
        } else {
          dsPath = this.SELECTED_REGIONS[0];
        }
        this.regionJicklePath =
          this.$Region.base_raster_path + "_" + dsPath + "_bitmaps.jickle";
        this.regionJsonPath = dsPath + ".geojson";
        this.UPDATE_ACTIVE_SCENARIO({ studyArea: this.regionJsonPath, studyAreaType: this.SELECTED_REGION_TYPE });
        const mainPath = "/public/" + dsPath + ".geojson"; // get the proper region json with metadata and no interior borders for later use
        this.$API
          .get("api", mainPath, myInit)
          .then((body) => {
            this.mainRegionJson = body;
          })
          // eslint-disable-next-line
          .catch((err) => console.log(err));
        const altPath = "/public/" + dsPath + "_ws.geojson"; // drilldown-region selector uses different geojson file with interior sub-watershed boundaries included
        this.$API
          .get("api", altPath, myInit)
          .then((body) => {
            this.regionJson = body;
            this.altRegionJson = body;
            this.addRegionsLayers();
            this.map.fitBounds(bbox(body), { padding: 20 }); // zoom to region
            this.closeProgressBar();
          })
          // eslint-disable-next-line
          .catch((err) => console.log(err));
      });
    },

    // -----------------------------------------------------------------
    // TOC METHODS - PLANNING LAYERS AND MARKERS METHODS
    // -----------------------------------------------------------------
    planningFillOnMouseMove(e) {
      // hover effect for planning - mouse cursor
      // needs to be a separate function (not inline) so it can be removed during layer updates
      if (e.features.length > 0) {
        this.map.getCanvas().style.cursor =
          this.PLANNING_MAKERS_DRAGGABLE || this.newObsListenerActive
            ? "crosshair"
            : "";
      }
    },
    planningFillOnMouseLeave() {
      // turn off hover effect when leaving region
      // needs to be a separate function so it can be removed during layer updates
      this.map.getCanvas().style.cursor = this.getBaseMapCursor();
    },
    planningOnClick(e) {
      // click handler for planning mode
      if (!this.newObsListenerActive) {
        // observation listener takes priority for clicks
        if (this.ACTIVE_LT === null && this.PLANNING_MAKERS_DRAGGABLE) {
          // no LT selected
          this.$notify({
            message:
              "Choose a land cover type to plan a change to the landscape.",
            icon: "add_alert",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "warning",
          });
        } else if (
          this.ACTIVE_LT !== null &&
          this.PLANNING_MAKERS_DRAGGABLE &&
          (this.EXPLORE_MODE === "scenario" ||
            (this.EXPLORE_MODE === "create" &&
              this.ACTIVE_SCENARIO_WIZARD_TAB === "tab_changes"))
        ) {
          const marker = new mapboxgl.Marker({
            element: this.markerIcon(
              this.activeLTObject(this.ACTIVE_LT).hex,
              (this.ACTIVE_SCENARIO.allocations.length + 1).toString()
            ),
            draggable: true,
          })
            .setLngLat(e.lngLat)
            .addTo(this.map);
          marker.on("dragstart", this.markerOnDragStart);
          marker.on("dragend", this.markerOnDragEnd);
          this.$options.planningMarkers.push(marker);
          const latlng = this.getMarkerLatLng(
              this.$options.planningMarkers[
                this.$options.planningMarkers.length - 1
              ]
            ),
            xy_coords = this.getProjectedCoords(latlng[0], latlng[1]),
            _allocation = {
              area: (parseInt(this.ALLOCATION_AREA_MAX / 10, 10) + 1) * 10 * 50, // round to nearest 10
              coords: {
                lat: latlng[0],
                lng: latlng[1],
              },
              // store in dest projection for scenario modelling
              coordsProj: {
                x: xy_coords.x,
                y: xy_coords.y,
              },
              id: this.ACTIVE_SCENARIO.allocations.length + 1,
              name: this.ACTIVE_LT,
              uuid: uuidv4(), // for maintaining unique key in the ui since the id get's updated
            };
          this.ALLOCATIONS_PUSH(_allocation);
          if (this.EXPLORE_MODE === "scenario") {
            // allocation updated in an existing scenario - take note
            this.ALLOCATIONS_UPDATED_PUSH(this.ACTIVE_SCENARIO.id);
          }
        }
      }
    },
    markerIcon(hex, label) {
      // make a simple div with a circle and a text label
      const el = document.createElement("div");
      el.className = "allocationMarker";
      el.style.width = "20px";
      el.style.height = "20px";
      el.style.borderRadius = "10px";
      el.style.border = "1px solid #fff";
      el.style.fontSize = "10px";
      el.style.fontWeight = "bold";
      el.style.color = "#fff";
      el.style.lineHeight = "18px";
      el.style.textAlign = "center";
      el.style.background = hex;
      el.innerText = label;
      return el;
    },
    markerOnDragStart(e) {
      // capture marker coordinates at start of drag - used to validate position at drag end and reset if necessary
      const marker = e.target;
      this.markerPrevLngLat = marker.getLngLat();
    },
    markerOnDragEnd(e) {
      // capture marker coordinates on drag end to update marker coordinates in planning allocations when dragged
      const marker = e.target;
      const latlng = this.getMarkerLatLng(marker),
        _point = point([latlng[1], latlng[0]]),
        _poly = polygon(this.regionJson.features[0].geometry.coordinates);
      // make sure the moved marker is inside the study area
      if (!booleanPointInPolygon(_point, _poly)) {
        // if not, move it back and notify user
        marker.setLngLat(this.markerPrevLngLat);
        this.$notify({
          message:
            "Your proposed changes must be within the selected study area.",
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      } else {
        // proceed to update the allocation coordinates
        const markerID = parseInt(marker.getElement().innerText),
          xy_coords = this.getProjectedCoords(latlng[0], latlng[1]),
          _allocations = JSON.parse(
            JSON.stringify(this.ACTIVE_SCENARIO.allocations)
          ),
          _allocIndex = _allocations.findIndex(
            (alloc) => alloc.id === markerID
          );
        if (_allocIndex !== -1) {
          _allocations[_allocIndex].coords = {
            lat: latlng[0],
            lng: latlng[1],
          };
          _allocations[_allocIndex].coordsProj = {
            x: xy_coords.x,
            y: xy_coords.y,
          };
          this.UPDATE_ACTIVE_SCENARIO({ allocations: _allocations });
          if (this.EXPLORE_MODE === "scenario") {
            // allocation updated in an existing scenario - take note
            this.ALLOCATIONS_UPDATED_PUSH(this.ACTIVE_SCENARIO.id);
          }
        }
      }
    },
    loadScenarioAllocations() {
      // load the allocations into marker objects for an existing scenario
      // first clear any previous markers
      this.clearMarkers();
      if (this.scenario.type === "Land Use") {
        this.scenario.allocations.forEach((alloc) => {
          const marker = new mapboxgl.Marker({
            element: this.markerIcon(
              this.activeLTObject(alloc.name).hex,
              alloc.id.toString()
            ),
            draggable: this.PLANNING_MAKERS_DRAGGABLE,
          })
            .setLngLat([alloc.coords.lng, alloc.coords.lat])
            .addTo(this.map);
          marker.on("dragstart", this.markerOnDragStart);
          marker.on("dragend", this.markerOnDragEnd);
          this.$options.planningMarkers.push(marker);
        });
      }
    },
    clearMarkers() {
      // remove all planning markers from map
      this.$options.planningMarkers.forEach((marker) => {
        marker.remove();
      });
      this.$options.planningMarkers = [];
    },
    loadMarkers() {
      // load planning markers onto map
      this.loadScenarioAllocations();
      this.$options.planningMarkers.forEach((marker) => {
        marker.addTo(this.map);
      });
    },
    activeLTObject(ltName) {
      let activeLTObj = null;
      this.landUseTypes.forEach((lt) => {
        if (lt.name === ltName) activeLTObj = lt;
      });
      return activeLTObj;
    },

    // -----------------------------------------------------------------
    // TOC METHODS - OBSERVATIONS METHODS
    // -----------------------------------------------------------------
    loadObservationIcons() {
      // add the icons used for observations
      const _map = this.map;
      // single observation icons
      Object.keys(this.$Region.observation_types).forEach((obsType) => {
        const imgFile = this.$Region.observation_types[obsType].image; // the actual img filename
        const img = imgFile.replace(/.png/gi, ""); // image ID to give to the map
        if (!_map.hasImage(img)) {
          _map.loadImage(`/img/${imgFile}`, function (error, image) {
            if (error) throw error;
            // Add the loaded image to the style's sprite with the img ID
            _map.addImage(img, image);
          });
        }
      });
      // cluster icon
      _map.loadImage(`/img/obscluster.png`, function (error, image) {
        if (error) throw error;
        _map.addImage("obscluster", image);
      });
    },
    addObservationsLayer() {
      // add the observations data source and observations layer for user observations
      const loadCurrentMarker = true; // load the current marker if it's hidden... this used to be passed as an argument to the function but seems to be deprecated... can't find anywhere actually using that parameter

      const _this = this;
      const debounceObservationsLoadAll = debounce(function () {
        // loade all observations from api... debounced to prevent simultaneous loads
        _this.OBSERVATIONS_LOAD_ALL({ vm: _this });
        _this.CLEAR_HIDDEN_OBSERVATION();
      }, 1000);

      // make sure the observations json has been loaded from the api and is not stale - ie haven't been loaded for 5min (300000ms)
      if (
        this.OBSERVATIONS_LAST_LOADED === null ||
        Date.now() > this.OBSERVATIONS_LAST_LOADED + 300000
      ) {
        debounceObservationsLoadAll();
      }

      // add the source
      const waiting = () => {
        // make sure the observations have finished loading from the api
        if (Date.now() > this.OBSERVATIONS_LAST_LOADED + 300000) {
          setTimeout(waiting, 100);
        } else {
          debounceObservationsLoadAll.clear(); // stop any further loads

          // add the observations source
          if (typeof this.map.getSource("observations") === "undefined") {
            this.map.addSource("observations", {
              type: "geojson",
              // data: "/observations.geojson",
              data: this.OBSERVATIONS_ALL_JSON["observations"],
              cluster: true,
              clusterMaxZoom: 14, // Max zoom to cluster points on
              clusterRadius: 50,
            });
          }

          // if there's a hidden observation, add that back in
          else if (loadCurrentMarker) {
            this.loadMissingObsMarker();
          }

          // add the user observations clusters layers
          if (typeof this.map.getLayer("obsclusters") === "undefined") {
            this.map.addLayer({
              id: "obsclusters",
              type: "symbol",
              source: "observations",
              filter: ["has", "point_count"],
              layout: {
                "icon-image": "obscluster",
                "icon-size": 1,
                "icon-allow-overlap": true,
                "icon-offset": [0, -1],
              },
            });
          }
          if (typeof this.map.getLayer("obscluster-count") === "undefined") {
            this.map.addLayer({
              id: "obscluster-count",
              type: "symbol",
              source: "observations",
              filter: ["has", "point_count"],
              layout: {
                "text-field": "{point_count_abbreviated}",
                "text-size": 8,
                "text-anchor": "bottom",
              },
              paint: {
                "text-color": "#666666",
              },
            });
          }

          // add the user observations unclustered layer
          if (typeof this.map.getLayer("observations") === "undefined") {
            this.map.addLayer({
              id: "observations",
              type: "symbol",
              source: "observations",
              layout: {
                "icon-image": "{icon}",
                "icon-size": 1,
                "icon-allow-overlap": true,
              },
            });
          }

          // add event handlers for user observations
          this.map.on(
            "mouseenter",
            "observations",
            this.observationOnMouseEnter
          );
          this.map.on(
            "mouseleave",
            "observations",
            this.observationOnMouseLeave
          );
          this.map.on("click", "observations", this.observationOnClick);
          this.map.on("click", "obsclusters", this.observationClusterOnClick);
          this.map.on(
            "mouseenter",
            "obsclusters",
            this.observationOnMouseEnter
          );
          this.map.on(
            "mouseleave",
            "obsclusters",
            this.observationOnMouseLeave
          );

          // launch shared obs if included in route query
          if (this.sharedObsInQuery) {
            this.loadSharedObs();
          }
          // launch requested observation details if they were set
          if (this.OBSERVATION_FEATURE_TO_LOAD && ['water', 'general'].includes(this.OBSERVATION_FEATURE_TO_LOAD.properties.type)) {
            this.activateObservation(this.OBSERVATION_FEATURE_TO_LOAD);
            // pause before clearing the feature to prevent map from flying to study area if a scenario is loaded
            setTimeout(this.CLEAR_OBSERVATION_FEATURE_TO_LOAD, 5000);
          }
        }
      };
      waiting();
    },
    addVirtualToursLayer() {
      // add the virtual tours data source and virtual tours layer
      // add the source
      if (this.$Region.virtualToursOn) {
        // add the source
        if (typeof this.map.getSource("virtualTours") === "undefined") {
          this.map.addSource("virtualTours", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: this.$Region.virtualTours,
            },
          });
        }

        // add the layer
        if (typeof this.map.getLayer("virtualTours") === "undefined") {
          this.map.addLayer({
            id: "virtualTours",
            type: "symbol",
            source: "virtualTours",
            layout: {
              "icon-image": "attraction-15",
              "icon-size": 1,
              "icon-allow-overlap": true,
            },
          });
        }

        // add event handlers for virtual tours
        this.map.on(
          "mouseenter",
          "virtualTours",
          this.observationOnMouseEnter
        );
        this.map.on(
          "mouseleave",
          "virtualTours",
          this.observationOnMouseLeave
        );
        this.map.on("click", "virtualTours", this.virtualTourOnClick);
      }

      //check virtual tour in query
      if ("vt" in this.$route.query) {
        const vt_code = this.$route.query.vt;
        const tours = this.$Region.virtualTours;
        let tour = null;
        //find tour
        tours.some(function (item) {
          if (item.properties.code === vt_code) {
            tour = item;
            return true;
          }
        });
        if (tour) {
          //fly to tour and open tour dialog
          this.SET_MAP_FLYTO(tour.geometry.coordinates.toString());
          const vm = this;
          setTimeout(function () {
            vm.SET_SELECTED_VIRTUAL_TOUR(JSON.parse(JSON.stringify(tour)));
          }, 2800);
          // clear the query in the url
          this.$router.replace({ query: null });
        }
      }
    },
    addSystemObservationsLayer(obsType) {
      // add the observations data source and observations layer for a specific system observation type
      const _this = this;
      const debounceObservationsLoadAll = debounce(function () {
        // loade all observations from api... debounced to prevent simultaneous loads
        _this.OBSERVATIONS_LOAD_ALL_SYSTEM({ vm: _this });
        _this.CLEAR_HIDDEN_OBSERVATION();
      }, 1000);

      // make sure the observations json has been loaded from the api and is not stale - ie haven't been loaded for 5min (300000ms)
      if (
        !(obsType in this.OBSERVATIONS_ALL_JSON) || !('features' in this.OBSERVATIONS_ALL_JSON[obsType])
      ) {
        debounceObservationsLoadAll();
      }

      // add the source
      const waiting = () => {
        // make sure the observations have finished loading to the vuex store
        if (this.map === 'undefined' || !(obsType in this.OBSERVATIONS_ALL_JSON) || !('features' in this.OBSERVATIONS_ALL_JSON[obsType])) {
          setTimeout(waiting, 100);
        } else {
          debounceObservationsLoadAll.clear(); // stop any further loads

          // add the source
          if (typeof this.map.getSource(obsType) === "undefined") {
            this.map.addSource(obsType, {
              type: "geojson",
              data: this.OBSERVATIONS_ALL_JSON[obsType],
            });
          }

          // add layer for observation type
          if (typeof this.map.getLayer(obsType) === "undefined") {
            this.map.addLayer({
              id: obsType,
              type: "symbol",
              source: obsType,
              layout: {
                "icon-image": "{icon}",
                "icon-size": 1,
                "icon-allow-overlap": true,
              },
            });
          }

          // add event handlers
          this.map.on(
            "mouseenter",
            obsType,
            this.observationOnMouseEnter
          );
          this.map.on(
            "mouseleave",
            obsType,
            this.observationOnMouseLeave
          );
          this.map.on("click", obsType, this.observationOnClick);

          // launch shared obs if included in route query
          if (this.sharedObsInQuery) {
            this.loadSharedObs();
          }
          // launch requested observation details if they were set
          if (this.OBSERVATION_FEATURE_TO_LOAD && !['water', 'general'].includes(this.OBSERVATION_FEATURE_TO_LOAD.properties.type)) {
            this.activateObservation(this.OBSERVATION_FEATURE_TO_LOAD);
            // pause before clearing the feature to prevent map from flying to study area if a scenario is loaded
            setTimeout(this.CLEAR_OBSERVATION_FEATURE_TO_LOAD, 5000);
          }
        }
      }
      waiting();
    },
    removeObservationsLayer() {
      // remove observations data source and observations layer
      this.map.off("click", "observations", this.observationOnClick);
      this.map.off("mouseenter", "observations", this.observationOnMouseEnter);
      this.map.off("mouseleave", "observations", this.observationOnMouseLeave);
      this.map.off("click", "obsclusters", this.observationClusterOnClick);
      this.map.off("mouseenter", "obsclusters", this.observationOnMouseEnter);
      this.map.off("mouseleave", "obsclusters", this.observationOnMouseLeave);

      this.removeLayer("observations");
      this.removeLayer("obsclusters");
      this.removeLayer("obscluster-count");
      this.removeSource("observations");
    },
    removeVirtualToursLayer() {
      // remove virtual tours data source and layer
      this.map.off("click", "virtualTours", this.virtualTourOnClick);
      this.map.off("mouseenter", "virtualTours", this.observationOnMouseEnter);
      this.map.off("mouseleave", "virtualTours", this.observationOnMouseLeave);
      this.removeLayer("virtualTours");
      this.removeSource("virtualTours");
    },
    removeSystemObservationsLayer(obsType) {
      // remove system observations data source and layer
      this.map.off("click", obsType, this.observationOnClick);
      this.map.off("mouseenter", obsType, this.observationOnMouseEnter);
      this.map.off("mouseleave", obsType, this.observationOnMouseLeave);
      this.removeLayer(obsType);
      this.removeSource(obsType);
    },
    observationOnMouseEnter() {
      // change cursor during observation mouseover
      this.map.getCanvas().style.cursor = "pointer";
    },
    observationOnMouseLeave() {
      // revert cursor after observation mouseover
      this.map.getCanvas().style.cursor = this.getBaseMapCursor();
    },
    observationOnClick(e) {
      // click handler for observation selection - launches sidebar with observation details
      this.activateObservation(e.features[0]);
    },
    virtualTourOnClick(e) {
      this.SET_SELECTED_VIRTUAL_TOUR(JSON.parse(JSON.stringify(e.features[0])));
    },
    observationClusterOnClick(e) {
      // click handler for observation cluster selection - zoom in on cluster
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ["obsclusters"],
      });
      const clusterId = features[0].properties.cluster_id;
      const _this = this;
      this.map
        .getSource("observations")
        .getClusterExpansionZoom(clusterId, function (err, zoom) {
          if (err) return;
          _this.map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
        });
    },
    activateObservation(feature) {
      // activate an observation from a feature - launches sidebar with observation details
      // get current selected observation type
      const oldObsType = this.SELECTED_OBSERVATION !== null && 'layer' in this.SELECTED_OBSERVATION && 'id' in this.SELECTED_OBSERVATION.layer ? this.SELECTED_OBSERVATION.layer.id : null;
      this.SET_SELECTED_OBSERVATION(JSON.parse(JSON.stringify(feature)));

      // if there's an active observation marker, clear it
      if (this.$options.currentObsMarker !== null) {
        this.clearSelectedObservation(oldObsType);
      }
      // create a new marker
      const marker = this.newObsMarker(
        this.SELECTED_OBSERVATION_TYPE,
        feature.geometry.coordinates,
        false
      );

      // hide the image by removing the point from the data
      const hiddenObservation = { ...feature };
      // remap some of the mapbox keys to original geojson keys
      if ("_geometry" in hiddenObservation) {
        hiddenObservation.geometry = { ...hiddenObservation._geometry };
        const removeKeys = [
          "layer",
          "source",
          "state",
          "_geometry",
          "_vectorTileFeature",
        ];
        removeKeys.forEach((key) => {
          delete hiddenObservation[key];
        });
      }
      // store hiddenObservation
      this.SET_HIDDEN_OBSERVATION({ ...hiddenObservation });
      // remove the feature from the observations json
      const obsType = ['water', 'general'].includes(feature.properties.type) ? 'observations' : feature.properties.type;
      this.OBSERVATIONS_ALL_FEATURES_SPLICE({
        type: obsType,
        index: feature.id,
      });

      // wait to make sure map and source have been loaded
      const waiting = () => {
        if (typeof this.map.getSource(obsType) === "undefined") {
          setTimeout(waiting, 100);
        } else {
          this.map.getSource(obsType).setData(this.OBSERVATIONS_ALL_JSON[obsType]);
          // animate and display the marker
          this.animateObsMarker(marker);

          // open the sidebar
          this.openObservationSidebar();
        }
      };
      waiting();
    },
    animateObsMarker(marker) {
      // animate an observation marker
      const doAnimate = function (vm, marker) {
        // do the actual marker animation
        // make sure the marker is visible and inside padded map edge
        vm.padMarkerMapEdge(marker);
        const waiting = () => {
          if (vm.map.isMoving()) {
            // if the map is currently moving, wait before animating
            setTimeout(waiting, 100);
          } else {
            vm.$el.style.setProperty(
              "--obsmarker-x",
              `${vm.map.project(marker.getLngLat()).x}px`
            );
            vm.$el.style.setProperty(
              "--obsmarker-y",
              `${vm.map.project(marker.getLngLat()).y}px`
            );
            marker.getElement().classList.add("bounceInMarker");
          }
        };
        waiting();
      };

      const vm = this;
      // if sidebar is not open, set a timeout to give time for the panel to open
      if (!this.SIDEBAR_OPEN) {
        // animate the marker
        setTimeout(function () {
          doAnimate(vm, marker);
        }, 500);
      } else {
        // no timeout needed
        doAnimate(vm, marker);
      }
    },
    padMarkerMapEdge(marker) {
      // make sure a marker is inside the map's canvas by at least 50px
      const width = this.map.getCanvas().width,
        height = this.map.getCanvas().height,
        minPad = 50,
        marker_xy = this.map.project(marker.getLngLat());

      let pan_x = 0,
        pan_y = 0;

      // check marker x and y position against padded map bounds
      if (marker_xy.x < minPad) {
        pan_x = marker_xy.x - minPad;
      } else if (marker_xy.x > width - minPad) {
        pan_x = -(width - minPad - marker_xy.x);
      }
      if (marker_xy.y < minPad) {
        pan_y = marker_xy.y - minPad - 30; // move extra 30px to allow for icon to show at top
      } else if (marker_xy.y > height - minPad) {
        pan_y = -(height - minPad - marker_xy.y);
      }
      // if the map is outside the padded bounds, move it
      if (pan_x !== 0 || pan_y !== 0) {
        this.map.panBy([pan_x, pan_y]);
      }
    },
    openObservationSidebar() {
      // launch the observation details sidebar
      this.SET_SIDEBAR_CONTENT_TYPE("observation");
      if (!this.SIDEBAR_OPEN) {
        this.TOGGLE_SIDEBAR();
      }
      if (!this.SIDEBAR_CONTENT_OPEN) {
        this.TOGGLE_SIDEBAR_CONTENT();
      }
      // for mobile fullscreen
      const vm = this;
      setTimeout(function () {
        vm.SET_EXPLORE_MOBILE_FS_CONTENT("stats");
      }, 1000);
    },
    initNewObservation() {
      // setup a new observation object
      const obs = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [],
        },
        properties: {},
        status: "new",
      };
      return obs;
    },
    newObservationOnClick(e) {
      // add new observation marker and set location for observation
      // if there's an active observation marker, clear it
      if (this.$options.currentObsMarker !== null) {
        const oldObsType = 'layer' in this.SELECTED_OBSERVATION && 'id' in this.SELECTED_OBSERVATION.layer ? this.SELECTED_OBSERVATION.layer.id : null;
        this.clearSelectedObservation(oldObsType);
      }
      // create a new marker
      const marker = this.newObsMarker(
        this.SELECTED_OBSERVATION_TYPE,
        e.lngLat,
        true
      );
      marker.on("dragend", this.obsMarkerOnDragEnd);

      // deactivate the click handler and cursor
      this.map.off("click", this.newObservationOnClick);
      this.newObsListenerActive = false;
      this.map.getCanvas().style.cursor = this.getBaseMapCursor();
      // set up new observation and launch right sidebar
      const newObs = this.initNewObservation();
      newObs.geometry.coordinates = [e.lngLat.lng, e.lngLat.lat];
      this.SET_SELECTED_OBSERVATION(JSON.parse(JSON.stringify(newObs)));

      // animate and display the marker
      this.animateObsMarker(marker);
  
      // alert the user to the options they have after making observation - and then check the zoom level to see if they are zoomed out too much
      swal.fire({
        title: "Observation Created",
        text: "Is your observation marker in the correct location? You can still pan the map, adjust zoom, or drag the marker to position your observation before saving it.", 
        icon: "info",
        iconHtml: `<i class="fas fa-info"></i>`,
        customClass: {
          confirmButton: "md-button md-success",
        },
        buttonsStyling: false,
      }).then(()=>{
        // perform check on the zoom level to display warning if not zoomed in enough
        this.checkZoomLevelListener()
      })

      // launch right sidebar
      this.openObservationSidebar();
    },
    // todo for mobile devices
    loadedObservationOnClick() {
      // relaunch the right sidebar when clicking on existing loaded marker
      this.openObservationSidebar();
    },
    obsMarkerOnDragEnd(e) {
      // capture marker coordinates on drag end and update marker coordinates of observation when dragged
      const marker = e.target;
      const _obs = JSON.parse(JSON.stringify(this.SELECTED_OBSERVATION));
      _obs.geometry.coordinates = [
        marker.getLngLat().lng,
        marker.getLngLat().lat,
      ];
      this.SET_SELECTED_OBSERVATION(JSON.parse(JSON.stringify(_obs)));
    
      // perform check on the zoom level to display warning if not zoomed in enough 
      this.checkZoomLevelListener()

    },
    checkZoomLevelListener() {
      // get the zoom level
      const currentZoom = this.map.getZoom();
      const zoomThreshold = 14; // threshold for warning - can change if too low or high 0: Fully zoomed out, entire world, 1: Continent scale, 5: Region / large area scale, 10: City scale, 15: Streets scale, 20: Buildings scale

      // get the marker position/coordinates
      const markerPosition = this.SELECTED_OBSERVATION.geometry.coordinates;

      // check if the zoom level is below the threshold level 
      if (currentZoom < zoomThreshold) {
        // Display warning to the user because they are too zoomed out - give them option to zoom in automatically
        swal.fire({
          text: "We recommend zooming in further to make sure your observation is in the right spot! When zoomed out, your observation location is not as accurate.",
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "md-button md-danger",
            cancelButton: "md-button md-success",
          },
          confirmButtonText: `Zoom In`,
          cancelButtonText: `Stay Zoomed Out`,
          buttonsStyling: false
        }).then(result => {
          // User agreed to zoom in 
          if (result.value === true) {
            // Zoom into the provided marker position or default to map center
            const positionToZoom = markerPosition || this.map.getCenter();
            this.map.flyTo({ // use the zoom function 
              center: positionToZoom,
              zoom: zoomThreshold
            });
          }
        });
      }
    },
    newObservationListener() { //n use to have otificationType = "notify"
      // activate map click handler and prepare to receive a new observation marker
      // if user observations layer is not on, turn it on
      if (!this.SHOW_OBSERVATIONS['observations']) {
        this.SHOW_OBSERVATIONS_ON('observations');
      }
      this.map.on("click", this.newObservationOnClick);
      this.map.getCanvas().style.cursor = "crosshair";
      this.newObsListenerActive = true;

      // notification display prompting user to click on map - explanation of creating an observation 
      // happen when starting on the explore page or coming from observations page
      swal.fire({
        title: "New Observation",
        text: "Use the controls on your mouse or mobile device to zoom and pan to your location, then click the map to create your new observation.",
        icon: "info",
        iconHtml: `<i class="fas fa-info"></i>`,
        customClass: {
          confirmButton: "md-button md-success",
        },
        buttonsStyling: false,
      });

    },
    loadMissingObsMarker() {
      // load a missing observation to a marker (ie, if route changes and map reloads, might need to reload the active marker)
      let draggable = false,
        markerFound = false,
        coordinates;
      if (this.HIDDEN_OBSERVATION && this.SELECTED_OBSERVATION) {
        // look for a hidden observation
        markerFound = true;
        coordinates = this.HIDDEN_OBSERVATION.geometry.coordinates;
      } else if (
        this.SELECTED_OBSERVATION &&
        "status" in this.SELECTED_OBSERVATION &&
        this.SELECTED_OBSERVATION.status === "new"
      ) {
        // look for a new observation
        markerFound = true;
        coordinates = this.SELECTED_OBSERVATION.geometry.coordinates;
        draggable = true;
      }
      if (markerFound) {
        const marker = this.newObsMarker(
          this.SELECTED_OBSERVATION_TYPE,
          coordinates,
          draggable
        );
        // animate and display the marker
        this.animateObsMarker(marker);
      }
    },
    restoreHiddenObservation(obsLayer) {
      // if there is a hiddenObservation, restore it to the json features
      if (this.HIDDEN_OBSERVATION) {
        this.RESTORE_HIDDEN_OBSERVATION();
        // update the map source
        const _obsLayer = obsLayer === null ? 'observations' : obsLayer;
        // wait to make sure map and source have been loaded
        const waiting = () => {
          if (typeof this.map.getSource(_obsLayer) === "undefined") {
            setTimeout(waiting, 100);
          } else {
            this.map.getSource(_obsLayer).setData(this.OBSERVATIONS_ALL_JSON[_obsLayer]);
          }
        };
        waiting();
      }
    },
    clearSelectedObservation(obsLayer) {
      // clear any selected observation marker
      // if there is a hiddenObservation, restore it
      this.restoreHiddenObservation(obsLayer);
      this.$options.currentObsMarker.remove();
      this.$options.currentObsMarker = null;
    },
    updateObsMarkerIcon(obsType) {
      // update the marker style for an observation marker
      this.$options.currentObsMarker
        .getElement()
        .classList.remove("bounceInMarker");
      const vm = this;
      setTimeout(function () {
        vm.$options.currentObsMarker.getElement().innerHTML =
          vm.obsMarkerIconInnerHTML(obsType).outerHTML;
        vm.animateObsMarker(vm.$options.currentObsMarker);
      }, 100);
    },
    isNewObservation() {
      // whether a current selected observation is a new one or not
      if (
        this.SELECTED_OBSERVATION !== null &&
        "status" in this.SELECTED_OBSERVATION &&
        this.SELECTED_OBSERVATION.status === "new"
      ) {
        return true;
      }
      return false;
    },
    loadSharedObs() {
      // load a shared obs that was provided in route query
      // example urls:
      // http://localhost:8080/#/dashboard/explore?obs=obs_f747daa9-e463-4f6b-9bca-3c34f3729c66
      // http://localhost:8080/#/dashboard/explore?obs=system_40
      const isSystemObs = !this.$route.query.obs.startsWith("obs_");
      const obsId = isSystemObs
        ? parseInt(this.$route.query.obs.split("_")[1])
        : this.$route.query.obs;
      const obsKey = isSystemObs ? "id" : "UserTransaction";
      const obsType = isSystemObs ? this.$route.query.obs.split("_")[0] : "observations";

      // find the observation
      // wait until observations are loaded
      const waiting = () => {
        let ready = false;
        if (isSystemObs) {
          ready = (this.map !== 'undefined'
            && (obsType in this.OBSERVATIONS_ALL_JSON)
            && ('features' in this.OBSERVATIONS_ALL_JSON[obsType])
            && this.OBSERVATIONS_ALL_JSON[obsType].features.length > 0
          );
        } else {
          ready = (Date.now() <= this.OBSERVATIONS_LAST_LOADED + 300000);
        }
        if (!ready) {
          setTimeout(waiting, 100);
        } else {
          const obs = JSON.parse(
            JSON.stringify(
              this.OBSERVATIONS_ALL_JSON[obsType].features.find(
                (feat) => feat.properties[obsKey] === obsId
              )
            )
          );
          // clear the query in the url
          this.$router.replace({ query: null });
          // launch the obs
          this.activateObservation(obs);
        }
      };
      waiting();
    },

    // -----------------------------------------------------------------
    // TOC METHODS - INDIGENOUS LAYERS METHODS
    // -----------------------------------------------------------------
    initIndLayersSwitches() {
      // initialize the list of indigenous layers
      if (
        "indigenousModuleActive" in this.$Region &&
        this.$Region.indigenousModuleActive === true
      ) {
        this.loadIndLayers();
        if (
          this.$Region.indigenousLayers.length != this.SHOW_IND_LAYERS.length
        ) {
          // initialize the store
          this.IND_LAYERS_INIT(this.$Region.indigenousLayers.length);
        }
        this.showIndLayerSwitches = this.SHOW_IND_LAYERS;
      }
      // init toggle-all switches
      this.indFeaturesAll = new Array(
        this.$Region.indigenousLayers.length
      ).fill(true);
    },
    toggleIndLayerSwitch(index) {
      // update store and add/remove layers from map when switches are toggled
      this.showIndLayerSwitches[index] = !this.showIndLayerSwitches[index];
      this.TOGGLE_IND_LAYER(index);
      const layer = this.$Region.indigenousLayers[index];
      if (this.showIndLayerSwitches[index]) {
        // switch is on
        this.addIndLayer(layer, index);
        // When toggle on each of these, capture for Indigenous Mission
        if (layer.id == "territories") {
          this.findTraditionalTerritory();
        }
        if (layer.id == "treaties") {
          this.findTreatyArea();
        }
        if (layer.id == "languages") {
          this.findTraditionalLanguages();
        }
        if (layer.id == "settlements") {
          this.findReservesSettlements();
        }
        if (layer.id == "metisregions") {
          this.findMetisRegions();
        }  
      } else {
        this.removeIndLayer(layer);
      }
    },
    initIndFeaturesSwitches() {
      // initialize the list of indigenous layers
      if (
        "indigenousModuleActive" in this.$Region &&
        this.$Region.indigenousModuleActive === true
      ) {
        this.loadIndLayers();
        if (
          this.$Region.indigenousLayers.length != this.SHOW_IND_LAYERS.length
        ) {
          // initialize the store
          this.IND_LAYERS_INIT(this.$Region.indigenousLayers.length);
        }
        this.showIndLayerSwitches = this.SHOW_IND_LAYERS;
      }
    },
    toggleIndFeatureSwitch(layer, index) {
      // update store and add/remove features from map when switches are toggled
      this.showIndFeaturesSwitches[index] =
        !this.showIndFeaturesSwitches[index];
      this.TOGGLE_IND_FEATURE(index);
      // set filter
      this.filterIndLayer(layer);
    },
    toggleAllIndFeatures(layerIndex) {
      // toggle all ind features for a layer
      const layer = this.$Region.indigenousLayers[layerIndex].id;
      this.indLayers[layer].mapJson.features.forEach((feature) => {
        const index = feature.properties.id;
        this.showIndFeaturesSwitches[index] = this.indFeaturesAll[layerIndex];
        if (this.SHOW_IND_FEATURES[index] !== this.indFeaturesAll[layerIndex]) {
          this.TOGGLE_IND_FEATURE(index);
        }
      });
      this.filterIndLayer(layer);
    },
    filterIndLayer(layer) {
      // filter features for an indigenous layer
      let filter = null;
      if (this.showIndFeaturesSwitches.includes(false)) {
        // not all features are on... get list of features that are on
        const visFeatures = this.showIndFeaturesSwitches.reduce(
          (out, bool, _index) => (bool ? out.concat(_index) : out),
          []
        );
        filter = ["in", "id", ...visFeatures];
      }
      this.map.setFilter(`${layer}Fill`, filter);
      this.map.setFilter(`${layer}Outline`, filter);
    },
    loadIndLayers() {
      // load the indigenous map data layers from the API
      this.$Region.indigenousLayers.forEach((layer) => {
        // get the json for an indigenous map layer
        this.$Auth.currentSession().then((authData) => {
          const myInit = {
            headers: { Authorization: authData.idToken.jwtToken },
          };
          const dsPath = `/public/${layer.mapdata}`;
          this.$API
            .get("api", dsPath, myInit)
            .then((body) => {
              // reorder layer features alphabetically
              body.features = body.features.sort((a, b) =>
                // eslint-disable-next-line no-nested-ternary
                a.properties.Name > b.properties.Name
                  ? 1
                  : b.properties.Name > a.properties.Name
                  ? -1
                  : 0
              );
              this.indLayers[layer.id] = {
                mapJson: body,
              };
              this.indLayersReady += 1;
            })
            // eslint-disable-next-line
            .catch((err) => console.log(err));
        });
      });
      // assign IDs and init the list of features visibility
      const waiting = () => {
        if (this.indLayersReady < this.$Region.indigenousLayers.length) {
          setTimeout(waiting, 200);
        } else {
          let allFeaturesCount = 0;
          this.$Region.indigenousLayers.forEach((layer) => {
            // auto-assign id's
            this.indLayers[layer.id].mapJson.features.forEach(
              (feature, index) => {
                feature.properties.id = index + allFeaturesCount;
              }
            );
            allFeaturesCount +=
              this.indLayers[layer.id].mapJson.features.length;
          });
          // populate SHOW_IND_FEATURES visibility
          // init store if it's not set up
          if (this.SHOW_IND_FEATURES.length < allFeaturesCount) {
            // auto set visibility to true for all
            this.IND_FEATURES_INIT(allFeaturesCount);
          }
          this.showIndFeaturesSwitches = this.SHOW_IND_FEATURES;
          this.indFeaturesReady = true;
        }
      };
      waiting();
    },
    addIndLayer(layer, index) {
      // add an indigenous layer to map
      if (
        this.map.getSource(layer.id) === undefined &&
        this.indLayers[layer.id].mapJson !== null
      ) {
        this.launchProgressBar(); // this will get cloased later by another function
        const layerJson = JSON.parse(
          JSON.stringify(this.indLayers[layer.id].mapJson)
        );

        this.map.addSource(layer.id, {
          type: "geojson",
          data: layerJson,
          generateId: true, // automatically assign id's to geoms
        });

        let firstSymbolId;
        const layers = this.map.getStyle().layers;
        for (let i = 0; i < layers.length; i++) {
          if (layers[i].type === "symbol") {
            firstSymbolId = layers[i].id;
            break;
          }
        }

        if (typeof this.map.getLayer(`${layer.id}Outline`) === "undefined") {
          this.map.addLayer(
            {
              id: `${layer.id}Outline`,
              type: "line",
              source: layer.id,
              layout: {},
              paint: {
                "line-color": this.indLayerColors[index],
                "line-width": 3,
              },
            },
            firstSymbolId
          );
        }

        if (typeof this.map.getLayer(`${layer.id}Fill`) === "undefined") {
          this.map.addLayer(
            {
              id: `${layer.id}Fill`,
              type: "fill",
              source: layer.id,
              layout: {},
              paint: {
                "fill-color": this.indLayerColors[index],
                "fill-opacity": [
                  "case",
                  ["boolean", ["feature-state", "hover"], false],
                  0.5,
                  0.25,
                ],
              },
            },
            `${layer.id}Outline`
          );
        }

        // add mouseover event listeners
        this.map.on("click", `${layer.id}Fill`, this.indLayerOnClick);
        this.map.on("mousemove", `${layer.id}Fill`, this.indLayerOnMouseMove);
        this.map.on("mouseleave", `${layer.id}Fill`, this.indLayerOnMouseLeave);
        // add click event listener
        // this.map.on("click", `${layer.id}Fill`, this.indLayerOnClick);

        // filter features
        this.filterIndLayer(layer.id);

        this.closeProgressBar();
      }
    },
    removeIndLayer(layer) {
      // remove an indigenous layer from map
      this.map.off("click", `${layer.id}Fill`, this.indLayerOnClick);
      this.map.off("mousemove", `${layer.id}Fill`, this.indLayerOnMouseMove);
      this.map.off("mouseleave", `${layer.id}Fill`, this.indLayerOnMouseLeave);
      this.removeLayer(`${layer.id}Fill`);
      this.removeLayer(`${layer.id}Outline`);
      this.removeSource(layer.id);
    },
    indLayerOnClick(e) {
      // click popup for indigenous layer
      // show list of all active indigenous layer features under the mouse
      const coordinates = e.lngLat;
      const allLayers = this.$Region.indigenousLayers
        .filter((layer, index) => this.SHOW_IND_LAYERS[index])
        .map((layer) => layer.id);
      const activeLayers = allLayers.map((layer) => `${layer}Fill`);
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: activeLayers,
      });
      const eventLayers = Array.from(
        new Set(
          features.map((feat) =>
            this.$Region.indigenousLayers.find(
              (layer) => layer.id === feat.source
            )
          )
        )
      );
      let description = "";
      eventLayers.forEach((layer, index) => {
        description = description + `${layer.description}<ul>`;
        const _features = features.filter((feat) => feat.source === layer.id);
        _features.forEach((feat) => {
          description =
            description + `<li><strong>${feat.properties.Name}</strong></li>`;
        });
        description =
          description +
          `</ul>Source: <a href="${layer.sourceUrl}" title="${layer.source}" target="_blank">${layer.source}</a>`;
        if (index < eventLayers.length - 1) {
          description = description + `<br><hr>`;
        }
      });
      this.indLayerPopup
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(this.map);
    },
    indLayerOnMouseMove(e) {
      // hover effect for indigenous layer
      if (e.features.length > 0) {
        if (this.hoveredRegionID !== null) {
          this.map.setFeatureState(
            { source: this.hoveredRegionSource, id: this.hoveredRegionID },
            { hover: false }
          );
        }
        this.hoveredRegionID = e.features[0].id;
        this.hoveredRegionSource = e.features[0].source;
        this.map.setFeatureState(
          { source: this.hoveredRegionSource, id: this.hoveredRegionID },
          { hover: true }
        );
        this.map.getCanvas().style.cursor = "pointer";
      }
    },
    indLayerOnMouseLeave() {
      // turn off hover effect for indigenous layer
      if (this.hoveredRegionID) {
        this.map.setFeatureState(
          { source: this.hoveredRegionSource, id: this.hoveredRegionID },
          { hover: false }
        );
      }
      this.hoveredRegionID = null;
      this.hoveredRegionSource = null;
      this.map.getCanvas().style.cursor = this.getBaseMapCursor();
    },
    indRegionFlyTo(featureJson) {
      // fly to a specific region
      this.map.fitBounds(bbox(featureJson), { padding: 20 }); // zoom to region
    },
    clicked: function (e) {
      e.preventDefault();
    },
    cssVars(index) {
      // to pass the layer color
      return {
        "--bg-color": this.indLayerColors[index],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  padding: 0;
}
.mapLayersMenu {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 5;
}
.mapSpeedDial {
  position: absolute;
  top: 70px;
  left: 20px;
  z-index: 5;
}
/deep/ .ind-layer-checkbox .md-list-item-content {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0px;
}
/deep/ .ind-layer .ind-layer-checked .md-switch-container {
  // switch colors
  background-color: var(--bg-color) !important;
}
/deep/
  .ind-feature-checkbox
  .md-checkbox.md-theme-default.md-checked
  .md-checkbox-container,
/deep/
  .ind-feature-checkbox
  .md-checkbox.md-checked
  .md-checkbox-container:after {
  border-color: var(--bg-color) !important;
}
/deep/ .ind-layer-checkbox .md-ripple {
  color: var(--bg-color) !important;
}
/deep/ .ind-feature-checkbox .md-list-item-content {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 40px;
  min-height: 20px;
}
.ind-feature-checkbox button {
  background: none;
  border: 0px none;
  cursor: pointer;
  visibility: hidden;
}
.ind-feature-checkbox:hover button {
  visibility: visible;
}
/deep/ .obs-layer-switch .md-switch-label {
  word-wrap: break-word;
  width: 160px !important;
  white-space: normal;
  margin-right: 0px;
  padding-right: 0px;
}
.obs-layer-switch-item .md-list-item-content{
  padding-left: 0px;
  padding-right: 0px;
}
.progress-bar {
  width: calc(80% - 120px);
  position: absolute;
  top: 20px;
  left: 90px;
  padding: 20px;
  z-index: 5;
  background: rgba(0, 0, 0, 0.2);

  & > * {
    color: white;
  }
}
/deep/ .hideMarker {
  visibility: hidden;
}
/deep/ .bounceInMarker {
  visibility: visible;
  animation: bounce-in 0.5s;
}
@keyframes bounce-in {
  0% {
    transform: translate(-50%, -100%)
      translate(var(--obsmarker-x), var(--obsmarker-y)) scale(0);
  }
  50% {
    transform: translate(-50%, -100%)
      translate(var(--obsmarker-x), var(--obsmarker-y)) scale(1.4);
  }
  70% {
    transform: translate(-50%, -100%)
      translate(var(--obsmarker-x), var(--obsmarker-y)) scale(0.7);
  }
  90% {
    transform: translate(-50%, -100%)
      translate(var(--obsmarker-x), var(--obsmarker-y)) scale(1.2);
  }
  100% {
    transform: translate(-50%, -100%)
      translate(var(--obsmarker-x), var(--obsmarker-y)) scale(1);
  }
}
</style>
<style>
/* hide the built-in mapbox geolocate control */
.mapboxgl-ctrl-group .mapboxgl-ctrl-geolocate,
.mapboxgl-ctrl-group .mapboxgl-ctrl-geolocate-background {
  display: none;
}
</style>