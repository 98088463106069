<template>
  <md-card class="side-panel-card layout-padding-none">
    <md-toolbar class="layout-gray-dark layout-padding-small">
      <h3 class="md-title">
        <md-icon
          :md-src="`/img/${indicator.icon}`"
          class="chart-icon"
        />
        <p>{{ indicator.name }}</p>
      </h3>
      <md-button
        class="md-icon-button"
        @click="TOGGLE_SIDEBAR_CONTENT"
      >
        <md-icon>close</md-icon>
      </md-button>
    </md-toolbar>
    <md-card-content>
      <div
        class="md-layout md-alignment-center-space-between"
        style="font-size: 50px;"
        :class="{'flex-center': rightSidebarFullScreen || !('complete' in scenario && scenario.complete)}"
      >
        <cover-indicator
          :scenario="scenario"
          :indicator="indicator"
          :activity="indicatorActivity"
          :indicator-val="indicatorValue(indicator.name, scenario, simulationState)"
          :year="simulationState.simYear"
          :show-all-units="true"
          class="md-layout-item md-size-33"
        />
        <div
          v-if="'complete' in scenario && scenario.complete"
          class="md-layout-item md-size-55 md-xsmall-size-100 md-title"
        >
          <span class="text-white">
            {{ indicator.name }} over time ({{ indicator.units }})
          </span>
          <chartist
            :data="indicatorChartData()"
            :options="indicatorChartOptions()"
            ratio=".ct-perfect-fourth"
            type="Line"
          />
        </div>
      </div>
      <p class="text-white">
        {{ indicator.description }}
      </p>
      <p class="text-white units-description">
        {{ indicator.name }} units: {{ unitsDescription }}
      </p>
      <!-- media area -->
      <div v-if="hasMedia">
        <div v-if="indicator.media.type==='image'">
          <img
            v-if="'url' in indicator.media && indicator.media.url != ''"
            :src="indicator.media.url"
          >
        </div>
        <div v-else-if="indicator.media.type==='video'">
          <video-player
            :options="getVideoOptions(indicator)"
            :title="indicator.name"
            class="md-layout-item md-size-100"
          />
        </div>
      </div>
      <technical-doc />
    </md-card-content>
  </md-card>
</template>

<script>
import { mapActions } from "vuex";
import { types } from "@/store/types";
import { Indicators } from "./mixins/Indicators";
import { VideoOptions } from "@/pages/Dashboard/Components/mixins/VideoOptions";
import { ResponsiveLayoutHelpers } from "@/pages/Dashboard/Components/mixins/ResponsiveLayoutHelpers";
import VideoPlayer from "@/components/VideoPlayer";
import TechnicalDoc from './TechnicalDoc.vue';
import CoverIndicator from "./CoverIndicator";

export default {
  name: "IndicatorDetails",

  components: {
    VideoPlayer,
    CoverIndicator,
    TechnicalDoc,
  },
  mixins: [Indicators, VideoOptions, ResponsiveLayoutHelpers], // common indicator related stuff, video loading functions

  props: {
    indicator: {
      type: Object,
      required: true
    },
    scenario: {
      type: Object,
      required: true,
    },
    simulationProgress: {
      type: Number,
      default: 0,
    },
    simulationState: {
      type: Object,
      required: true,
    }
  },

  data: function () {
    return {
    };
  },

  computed: {
    indicatorActivity() {
      // determine whether to be in view or update mode
      // if showGoals is true, then this must be a scenario with goals so we should be allowed to update them
      return (this.showGoals) ? 'update' : 'view';
    },
    hasMedia() {
      // is there media attached to this indicator
      if ("media" in this.indicator && ['video', 'image'].includes(this.indicator.media.type)) {
        return true;
      }
      return false;
    },
    unitsDescription() {
      // provide a units conversion description in simple terms
      // calculate the conversion
      const conversion = this.$Helpers.abbreviateNumber(1/this.indicator.conversion);
      // de-pluralize the simple units
      const simple_units = this.indicator.simple_units.split(" ").map(w => {
        return w.replace(/s$/,"").replace(/People/,"Person");
      }).join(" ");
      return this.indicator.units === this.indicator.simple_units ? this.indicator.units : `1 ${simple_units} = ${conversion} ${this.indicator.units}`;
    }
  },

  mounted() {
    // analytics tracking
    this.$Helpers.gtagEvent(this, `view_indicator`, `indicators`, this.indicator.name);
  },

  methods: {
    ...mapActions([types.actions.TOGGLE_SIDEBAR_CONTENT]),

    fillMissingYearData() {
      // fill any gaps in the INDICATOR_VALS in case user jumped slider before playing through
      const allYears = Array(this.simulationState.simYear - this.scenario.startTime).fill().map((x,i)=> i + this.scenario.startTime);
      const missingYears = allYears.filter(val => (val <= this.scenario.endTime && val <= this.simulationState.simYear && !Object.keys(this.INDICATOR_VALS[this.indicator.name]).includes(val)));
      missingYears.forEach(yr => this.indicatorValue(this.indicator.name, this.scenario, this.simulationState, yr));
    },
    indicatorChartData() {
      this.fillMissingYearData();
      const yearIndex = this.simulationState.simYear - this.scenario.startTime;
      const labels = Object.keys(this.INDICATOR_VALS[this.indicator.name]).filter(val => (val <= this.scenario.endTime && val <= this.simulationState.simYear));
      const series = Object.values(this.INDICATOR_VALS[this.indicator.name]).map(val => val[1]).slice(0, yearIndex + 1);
      series.forEach((val, index) => series[index] = { meta: `${labels[index]}:`, value: `${parseFloat(val).toFixed(2)}` });
      return {
        labels: labels,
        series: [series]
      };
    },
    indicatorChartOptions() {
      const units = this.indicator.units;
      const vm = this;
      const unitsAddOn = function (val) {
        return `${vm.$Helpers.abbreviateNumber(val)} ${units}`;
      }
      const numYears = this.scenario.endTime - this.scenario.startTime;
      const skipNum = (numYears <= 40) ? 10 : 30;
      const tooltipClass = this.rightSidebarFullScreen ? 'mobile-tooltip' : '';
      return {
        showLabel: false,
        height: '200px',
        axisX: {
          // skip labels on x axis... only show a subset
          labelInterpolationFnc: function skipLabels(value, _index) {
            return _index % skipNum === 0 ? value : null;
          }
        },
        axisY: {
          labelInterpolationFnc: function (value) {
            // abbreviate labels
            return vm.$Helpers.abbreviateNumber(value);
          }
        },
        plugins: [
          this.$chartist.plugins.tooltip({
            anchorToPoint: false,
            appendToBody: false,
            class: tooltipClass,
            transformTooltipTextFnc: unitsAddOn
          })
        ]
      };
    },
  }

};
</script>
<style lang="scss" scoped>
.side-panel-card {
  height: 100%;
}
.text-white {
  color: $white-color;
}
.units-description {
  font-style: italic;
}
.flex {
  display: flex;
  padding: 0.5rem;
}

.flex > * {
  margin: 0 0.5rem !important;
}

.flex > p {
  line-height: 1.6;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// md-elevation 0 is not working :(
.md-toolbar {
  box-shadow: none !important;
  .md-title {
    flex: 1;

    :first-child {
      margin-right: 1em;
    }

    .md-icon {
      color: white;
    }
  }
  .md-button {
    box-shadow: none !important;
    background: none !important;
  }
}

/deep/ .chartist-tooltip {
  position: absolute;
  display: none;
  min-width: 5em;
  padding: 8px 10px;
  margin-top: 50px;
  margin-left: 45%;
  background: #383838;
  color: #fff;
  text-align: center;
  pointer-events: none;
  z-index: 100;
  transition: opacity 0.2s linear;
}
/deep/ .chartist-tooltip:before {
  position: absolute;
  bottom: -14px;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: rgba(251, 249, 228, 0);
  border-top-color: #383838;
  border-width: 7px;
  margin-left: 0;
}
/deep/ .chartist-tooltip.tooltip-show {
  display: inline-block !important;
}
/deep/ .mobile-tooltip {
  margin-top: 280px;
  margin-left: 15px;
}
.ind-chart {
  height: 300px;
}
/deep/ .ct-grids line {
  stroke: $grey-500;
}
/deep/ .ct-labels span {
  color: $white-color;
}
/deep/ .ct-labels .ct-horizontal {
  margin: 0 0 0 -20px;
}
</style>