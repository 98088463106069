/*jshint esversion: 6 */
const awsmobile = {
  aws_project_region: "us-west-2",
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: "us-west-2_JwvMxqK8I",
  aws_user_pools_web_client_id: "6lge84qjhukbuqkv988h2pkk14",
  aws_cognito_identity_pool_id:
    "us-west-2:3299ef20-be63-4b88-948e-66f6f7ab1e5f",
  aws_cloud_logic_custom: [
    {
      name: "api",
      endpoint:
        "https://ri57e4qful.execute-api.us-west-2.amazonaws.com/abctomorrow-abctomorrowdev20191028",
      region: "us-west-2"
    }
  ],
  userfiles: "abctomorrow-abctomorrowdev20191028-userfiles",
  imageuploads: "abctomorrow-abctomorrowdev20191028-images",
  videouploads: "abctomorrow-abctomorrowdev20191028-vod-source",
  scenario_tracker: "a9f6amvw80syo-ats.iot.us-west-2.amazonaws.com"
};

export default awsmobile;
