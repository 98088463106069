<template>
  <div class="content">
    <div class="md-layout">
      <div
        v-if="USER_SCENARIOS.length === 0 && collectingData"
        class="md-layout"
      >
        <div class="md-layout-item md-size-100">
          <h4 class="card-title">
            Collecting your scenarios...
          </h4>
          <md-progress-bar
            class="md-info"
            md-mode="buffer"
            :md-value="amount"
            :md-buffer="amount"
          />
        </div>
      </div>
      <div
        v-else-if="USER_SCENARIOS.length === 0"
        class="md-layout-item md-size-100"
      >
        <md-card>
          <div class="md-card-content">
            <h3>You don't have any scenarios yet</h3>
            <md-button
              id="UserScenariosNewScenarioBtn"
              class="md-raised md-success"
              @click="newScenario"
            >
              <md-icon>add</md-icon> Create your first scenario
            </md-button>
          </div>
        </md-card>
      </div>

      <div
        v-else
        class="md-layout-item md-size-100"
      >
        <md-card>
          <div class="md-card-content">
            <div class="md-layout">
              <div class="md-layout-item md-xsmall-size-100 md-size-50">
                <h3>You have {{ scenarios.length }} {{ 'scenario' | pluralize(scenarios.length) }}</h3>
                <span class="badge badge-warning">{{ encouragingMessage }}</span>
              </div>
              <div class="md-layout-item md-xsmall-size-80 md-size-40">
                <md-button
                  id="UserScenariosNewScenarioBtn"
                  class="md-raised md-success title-button"
                  @click="newScenario"
                >
                  <md-icon>add</md-icon> {{ newScenarioText }}
                </md-button>
              </div>
              <!-- SCENARIO LIST ACTIONS  -->
              <div class="md-layout-item md-xsmall-size-20 md-size-10">
                <md-menu md-size="medium">
                  <md-button
                    id="UserScenariosFilterBtn"
                    md-menu-trigger
                    class="md-icon-button md-fab title-button menu-button"
                  >
                    <md-icon>more_vert</md-icon>
                  </md-button>
                  <md-menu-content>
                    <md-menu-item @click="orderBy('name')">
                      Order by Name
                    </md-menu-item>
                    <md-menu-item @click="orderBy('dateCreated')">
                      Order by Date
                    </md-menu-item>
                    <md-menu-item @click="orderBy('studyArea')">
                      Order by {{ $Region.region_type }}
                    </md-menu-item>
                    <md-menu-item @click="orderBy('type')">
                      Order by Scenario Type
                    </md-menu-item>
                  </md-menu-content>
                </md-menu>
              </div>
            </div>
          </div>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div
        v-for="(item, index) in scenarios"
        :key="item.id"
        class="md-layout-item md-size-25 md-medium-size-33 md-small-size-100"
      >
        <user-scenario-card
          :scenario-data="item"
          :region-path="item.studyArea"
          :show-map="index < maxPreviewMaps"
          @on-report-add-scenario="showReportBuilderOn"
          @on-report-scenarios-max="warnReportMax"
        />
      </div>
    </div>
    <md-snackbar
      md-position="center"
      :md-duration="Infinity"
      :md-active.sync="showReportBuilder"
      class="report-builder-snackbar"
    >
      <p>
        {{ ACTIVE_REPORT.scenarios.length }} {{ 'scenario' | pluralize(ACTIVE_REPORT.scenarios.length) }} added to Report Builder:
        <ul>
          <li
            v-for="scen in ACTIVE_REPORT.scenarios"
            :key="scen.id"
          >
            {{ scen.name }}
          </li>
        </ul>
        <md-button
          id="reportsBtn"
          class="md-raised md-default"
          @click="clearReport"
        >
          Reset
        </md-button>
        <md-button
          id="reportsBtn"
          class="md-raised md-success"
          @click="goToReport"
        >
          <md-icon>insert_chart_outlined</md-icon> Launch Report
        </md-button>
      </p>
    </md-snackbar>
    <intro-tour :steps="userScenariosTourSteps" />
    <intro-tour
      v-if="USER_SCENARIOS.length > 0"
      name="UserScenariosFilter"
      :steps="userScenariosFilterTourSteps"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import swal from "sweetalert2";
import UserScenarioCard from '../Components/UserScenarioCard'
import IntroTour from "@/pages/Dashboard/Components/IntroTour";
import { TourSteps } from "@/pages/Dashboard/Components/mixins/TourSteps";
import { ResponsiveLayoutHelpers } from "@/pages/Dashboard/Components/mixins/ResponsiveLayoutHelpers";

export default {
  name: "UserScenarios",
  components: {
    UserScenarioCard,
    IntroTour
  },
  mixins: [TourSteps, ResponsiveLayoutHelpers],
  props: {
  },
  data() {
    return {
      collectingData: true,
      amount: 0,
      timeOutAmount: 0,
      showReportBuilder: false
    };
  },
  computed: {
    ...mapGetters([
      types.getters.USER_SCENARIOS,
      types.getters.ACTIVE_REPORT,
      types.getters.ACTIVE_REPORT_SCENARIO_COUNT,
      types.getters.NEW_REPORT_MSG,
      types.getters.REPORT_MODE
    ]),
    scenarios() {
      return this.USER_SCENARIOS;
    },
    encouragingMessage() {
      if (this.scenarios.length > 5) {
        return "You are unstoppable!";
      }
      return "Keep going!";
    },
    newScenarioText() {
      // shortened message is displayed for small mobile size
      if (window.innerWidth < 375) {
        return `New Scenario`;
      }
      return `Create a New Scenario`;
    }
  },

  watch: {
    ACTIVE_REPORT_SCENARIO_COUNT(newVal) {
      if (newVal === 0) {
        this.showReportBuilder = false;
      }
    }
  },

  beforeMount() {
    this.incrementBar(); // prevent the "you have no scenarios" msg from flashing on the screen if it's because vuex hasn't yet caught up
    if (this.ACTIVE_REPORT_SCENARIO_COUNT > 0 && this.REPORT_MODE === 'create') {
      this.showReportBuilder = true;
    }
  },

  mounted() {
    if (this.NEW_REPORT_MSG) {
      swal.fire({
        title: "Report Builder",
        html: `<p>Select scenarios to add to your report by clicking on the add to report <i data-v-46708695="" class="md-icon md-icon-font md-theme-default">addchart</i> buttons with each scenario.</p>`,
        icon: "info",
        iconHtml: `<i class="fas fa-info"></i>`,
        customClass: {
          confirmButton: "md-button md-success",
        },
        buttonsStyling: false
      });
      this.SET_NEW_REPORT_MSG(false);
    }
  },

  methods: {
    ...mapActions([
      types.actions.TOGGLE_EDIT_SCENARIO_DIALOG,
      types.actions.ACTIVE_REPORT_CLEAR,
      types.actions.SET_NEW_REPORT_MSG
    ]),

    incrementBar: function () {
      if (this.amount < 98) {
        this.amount++;
        setTimeout(this.incrementBar, Math.random() * 10);
        if (this.amount === 98) {
          this.initiateTimeOut();
        }
      }
    },
    initiateTimeOut() {
      if (this.collectingData) {
        this.timeOutAmount++;
        if (this.timeOutAmount === 20) {
          this.collectingData = false;
        } else {
          setTimeout(this.initiateTimeOut, 100);
        }
      }
    },
    newScenario() {
      this.TOGGLE_EDIT_SCENARIO_DIALOG();
    },
    orderBy(key) {
      // re-order scenarios list by key
      if (key === 'dateCreated') {
        // numeric sort
        this.USER_SCENARIOS.sort((a, b) => b[key] - a[key]);
      } else {
        // text sort
        this.USER_SCENARIOS.sort(function (a, b) {
          let _a = a[key].toUpperCase();
          let _b = b[key].toUpperCase();
          if (key === 'studyArea') {
            // parse out the watershed that is actually displayed
            const getName = function (i) {
              const name = i.replace(".geojson", "").split("__");
              return name[name.length - 1];
            }
            _a = getName(_a);
            _b = getName(_b);
          }
          if (_a < _b) {
            return -1;
          }
          if (_b < _a) {
            return 1;
          }
          // vals must be equal
          return 0;
        })
      }
    },
    showReportBuilderOn() {
      // show report builder snackbar with scenario being added
      this.showReportBuilder = true;
    },
    warnReportMax() {
      // notify the user the report builder is already at max scenarios
      this.showReportBuilder = true;
      this.$notify({
        message: `Sorry, you can only include a maximum of 4 scenarios in a report.`,
        icon: "error_outline",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "warning"
      });
    },
    goToReport() {
      this.$router.push({
        name: "Reports"
      });
    },
    clearReport() {
      this.ACTIVE_REPORT_CLEAR();
      this.showReportBuilder = false;
    }
  }
}
</script>

<style scoped>
.menu-button {
  margin-top: -0.3em;
}
.title-button {
  top: 1em;
}
.report-builder-snackbar {
  min-height: 240px;
}
.content {
  margin-bottom: 200px;
}
</style>
