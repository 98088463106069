// Blackfoot Voices videos

const blackfootVoicesVideos = [
  {
    id: 3000,
    title: "A Blackfoot Perspective on Water",
    description: ``,
    coordinates: [-112.45130318711476, 50.77737948576379],
    subtype: 'blackfootvoices',    
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://www.youtube.com/watch?v=r-NzlnMgDj4',
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/thumb-a-blackfoot-perspective.jpg",
      shareUrl: '',
      is360: true,
    },
    observedTime: 1607104608043,
    hashtags: ''
  },  
];

export default blackfootVoicesVideos;
