<template>
  <md-card md-with-hover>
    <md-card-header class="md-card-header-primary">
      <div class="md-layout">
        <div class="md-layout-item md-size-20">
          <md-icon class="md-size-2x card-title-icon">
            assignment
          </md-icon>
        </div>
        <div class="md-layout-item md-size-80 card-title">
          <a @click="viewDetails()">
            <span class="md-title">
              {{ resource.title }}
            </span>
          </a>
          <div class="md-subhead md-layout">
            <md-icon class="time-icon">
              access_time
            </md-icon>
            {{ resource.approximateTime }}
          </div>
        </div>
      </div>
    </md-card-header>
    <md-card-content class="md-layout">
      <div class="md-layout-item md-size-100 layout-no-padding">
        {{ resource.description }}
      </div>
      <div class="md-layout-item md-size-100 layout-badges">
        <span
          v-if="numFiles > 0"
          class="badge badge-warning"
        >
          <md-icon class="badge-icon">folder</md-icon>
          {{ numFiles }} {{ 'file' | pluralize(numFiles) }}
        </span>
        <span
          v-if="numVideos > 0"
          class="badge badge-info"
        >
          <md-icon class="badge-icon">videocam</md-icon>
          {{ numVideos }} {{ 'video' | pluralize(numVideos) }}
        </span>
      </div>
      <div
        v-if="'theme' in resource"
        class="md-layout-item md-size-100 layout-no-padding tag-section"
      >
        <h6>
          Theme:
        </h6>
        <md-chip
          md-clickable
          :md-deletable="false"
          class="md-primary"
          @click="selectTag(resource.theme)"
        >
          {{ resource.theme }}
        </md-chip>
      </div>
      <div class="md-layout-item md-size-100 layout-no-padding tag-section">
        <h6>
          Grades:
        </h6>
        <md-chip
          v-for="chip in grades"
          :key="chip"
          md-clickable
          :md-deletable="false"
          class="md-info"
          @click="selectTag(chip)"
        >
          {{ chip }}
        </md-chip>
      </div>
      <div class="md-layout-item md-size-100 layout-no-padding tag-section">
        <h6>
          Subjects:
        </h6>
        <md-chip
          v-for="chip in resource.subjects"
          :key="chip"
          md-clickable
          :md-deletable="false"
          class="md-success"
          @click="selectTag(chip)"
        >
          {{ chip }}
        </md-chip>
      </div>
      <div class="md-layout-item md-size-100 layout-no-padding tag-section">
        <h6>
          Curriculum Tags:
        </h6>
        <collapse-transition>
          <div>
            <md-chip
              v-for="chip in tagsFiltered"
              :key="chip"
              md-clickable
              :md-deletable="false"
              class="md-default"
              @click="selectTag(chip)"
            >
              {{ chip }}
            </md-chip>
            <span
              v-if="!tagsAll"
              class="badge badge-default"
              @click="expandTags"
            >
              more &darr;
            </span>
            <span
              v-if="tagsAll && numTags > 2"
              class="badge badge-default"
              @click="shrinkTags"
            >
              &uarr; less
            </span>
          </div>
        </collapse-transition>
      </div>
    </md-card-content>
    <md-card-actions>
      <md-button
        class="md-raised md-success center-vertical"
        @click="viewDetails()"
      >
        View Details
      </md-button>
    </md-card-actions>
  </md-card>
</template>

<script>
import { CollapseTransition } from "vue2-transitions";
import { TeacherResources } from "@/pages/Dashboard/Components/mixins/TeacherResources";
export default {
  name: "TeacherResourceCard",

  components: {
    CollapseTransition
  },

  mixins: [TeacherResources],

  // props: {
  // },

  data() {
    return {
      tagsAll: false,
      tagsFiltered: []
    };
  },

  computed: {
  },

  beforeMount() {
    this.initTags();
  },

  methods: {
    initTags() {
      this.expandTags();
      this.shrinkTags();
    },
    shrinkTags() {
      if (this.numTags > 2) {
        this.tagsFiltered.splice(2);
        this.tagsAll = false;
      } else {
        this.tagsAll = true;
      }
    },
    expandTags() {
      this.tagsFiltered = [...this.resource.tags];
      this.tagsAll = true;
    },
    viewDetails() {
      // analytics tracking
      const vm = this;
      const label = `${this.resource.key}, ${this.resource.title}`;
      this.$Helpers.gtagEvent(vm, `view_resource`, `resources`, label);
      // launch the lesson details
      this.$emit('on-load-resource', this.resource.key)
    },
    selectTag(tag) {
      // emit a filter event
      this.$emit('on-set-tag', tag);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-title-icon {
  position: relative;
  margin: 10px 0 0 -15px;
  padding: 0px;
  color: $white-color !important;
}
.card-title {
  color: $white-color !important;
}
.md-title {
  font-size: 16px;
  line-height: 20px;
}
.time-icon {
  margin: -2px 5px 0 0;
  color: $white-color !important;
}
.md-subhead {
  margin: 5px 0 0 0;
}
/deep/ .md-chip .md-ripple {
  // prevent chips from resizing on hover
  padding: 0px 12px !important;
}
</style>
