<template>
  <form>
    <md-card>
      <md-card-header
        class="md-card-header-icon"
        :class="getClass(headerColor)"
      >
        <div class="card-icon">
          <md-icon>perm_identity</md-icon>
        </div>
        <h4 class="title">
          Profile
        </h4>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div
            v-for="item in Object.keys(userDisplayData)"
            :key="item"
            class="md-layout-item md-small-size-100 md-size-33"
          >
            <h4 class="card-title">
              {{ item }}
            </h4>
            <p class="card-description">
              {{ userDisplayData[item] }}
            </p>
          </div>
        </div>

        <md-button
          class="md-round md-success"
          @click="changeProfile"
        >
          Edit Profile
        </md-button>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
export default {

  name: 'EditProfileForm',
  props: {
    headerColor: {
      type: String,
      default: ''
    },
    userData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      namesMap: {
        "postal_code": "Postal Code",
        "grade": "Grade",
        "curriculum": "Curriculum",
        "email": "email",
        "teacher": "Teacher",
        "rationale": "My Objective"
      }
    }
  },
  computed: {
    userDisplayData: function () {
      let display_data = {};

      Object.keys(this.userData).map(item => {
        if (this.userData[item]) {
          if (this.namesMap[item]) {
            display_data[this.namesMap[item]] = this.userData[item]
          }
        }
      });
      if (Object.keys(display_data).length > 0) {
        display_data['Additional Info'] = this.userData.firstname + ' is awesome!'
      }
      else { display_data = { 'Please wait...': '' } }

      return display_data
    }
  },

  beforeMount() {
  },
  methods: {
    errorMessage(message) {
      this.$notify(
        {
          message: message,
          icon: 'error_outline',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'danger'
        })
    },
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
    changeProfile() {
      this.$router.push({
        name: 'Register',
        params: {
          updateProfile: true,
          userData: this.userData
        }
      })
    }
  }
}

</script>
<style>
</style>
