<template>
  <div class="fill-it-up">
    <landing-content-desktop v-if="!isMdXSmall" />
    <landing-content-mobile v-else />
  </div>
</template>
<script>
import LandingContentDesktop from "./LandingContentDesktop";
import LandingContentMobile from "./LandingContentMobile";
import { ResponsiveLayoutHelpers } from "@/pages/Dashboard/Components/mixins/ResponsiveLayoutHelpers";

export default {
  name: "LandingContent",

  components: {
    LandingContentDesktop,
    LandingContentMobile
  },
  mixins: [ResponsiveLayoutHelpers], //some common lt stuff, responsive layout stuff
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.text-white {
  color: white;
}
.text-right {
  text-align: right;
  vertical-align: middle;
}
.text-center {
  text-align: center;
}
.fill-it-up {
  width: 100%;
  height: 100%;
  position: relative;
}
.button-middle {
  margin: auto;
  width: 50%;
}
.card-medium-opacity {
  background-color: rgba(255, 255, 255, 0.4) !important;
}
.register-btn {
  border: solid 1px $white-color;
  margin-bottom: 30px;
}
</style>
