<template>
  <div class="content">
    <div class="md-layout">
      <div
        v-if="OBSERVATIONS_ALL_USER.length === 0 && collectingData"
        class="md-layout"
      >
        <div class="md-layout-item md-size-100">
          <h4 class="card-title">
            Collecting observations...
          </h4>
          <md-progress-bar
            class="md-info"
            md-mode="buffer"
            :md-value="amount"
            :md-buffer="amount"
          />
        </div>
      </div>
      <div
        v-else-if="OBSERVATIONS_ALL_USER.length === 0"
        class="md-layout-item md-size-100"
      >
        <md-card>
          <div class="md-card-content">
            <h3>You don't have any observations yet</h3>
            <md-button
              id="ObservationsNewBtn"
              class="md-raised md-success"
              @click="createNewObservation"
            >
              <md-icon>add</md-icon> Record your first observation
            </md-button>
          </div>
        </md-card>
      </div>

      <div
        v-else
        class="md-layout-item md-size-100"
      >
        <md-card>
          <div class="md-card-content">
            <div class="md-layout">
              <div class="md-layout-item md-xsmall-size-100 md-size-50">
                <h3>You have {{ OBSERVATIONS_ALL_USER.length }} {{ 'observation' | pluralize(OBSERVATIONS_ALL_USER.length) }}</h3>
                <span class="badge badge-warning">{{ encouragingMessage }}</span>
              </div>
              <div class="md-layout-item md-xsmall-size-80 md-size-40">
                <md-button
                  id="ObservationsNewBtn"
                  class="md-raised md-success title-button"
                  @click="createNewObservation"
                >
                  <md-icon>add</md-icon> {{ newObservationText }}
                </md-button>
              </div>
              <!-- LIST ACTIONS  -->
              <div class="md-layout-item md-xsmall-size-20 md-size-10">
                <md-menu md-size="medium">
                  <md-button
                    id="ObservationsFilterBtn"
                    md-menu-trigger
                    class="md-icon-button md-fab title-button menu-button"
                  >
                    <md-icon>more_vert</md-icon>
                  </md-button>
                  <md-menu-content>
                    <md-menu-item @click="orderBy('title')">
                      Order by Title
                    </md-menu-item>
                    <md-menu-item @click="orderBy('observedTime')">
                      Order by Date
                    </md-menu-item>
                    <md-menu-item @click="orderBy('type')">
                      Order by Type
                    </md-menu-item>
                  </md-menu-content>
                </md-menu>
              </div>
            </div>
          </div>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div
        v-for="(item, index) in OBSERVATIONS_ALL_USER"
        :key="`obs${index}`"
        class="md-layout-item md-size-25 md-medium-size-33 md-small-size-100"
      >
        <user-observation-card
          :observation-data="item"
          :show-map="index < maxPreviewMaps"
          @on-explore="explore"
          @on-delete="confirmDeleteObservation"
        />
      </div>
    </div>
    <intro-tour :steps="observationsTourSteps" />
    <intro-tour
      v-if="OBSERVATIONS_ALL_USER.length > 0"
      name="UserObservationsFilter"
      :steps="observationsFilterTourSteps"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import swal from "sweetalert2";
import { Observation } from "@/pages/Dashboard/Components/mixins/Observation";
import UserObservationCard from '../Components/UserObservationCard';
import IntroTour from "@/pages/Dashboard/Components/IntroTour";
import { TourSteps } from "@/pages/Dashboard/Components/mixins/TourSteps";
import { ResponsiveLayoutHelpers } from "@/pages/Dashboard/Components/mixins/ResponsiveLayoutHelpers";

export default {
  name: "Observations",

  components: {
    UserObservationCard,
    IntroTour
  },
  mixins: [Observation, TourSteps, ResponsiveLayoutHelpers],

  props: {},

  data() {
    return {
      collectingData: true,
      amount: 0,
      timeOutAmount: 0
    };
  },

  computed: {
    ...mapGetters([
      types.getters.OBSERVATIONS_ALL_USER,
      types.getters.OBSERVATIONS_ALL_JSON,
      types.getters.SELECTED_OBSERVATION,
      types.getters.SELECTED_OBSERVATION_TYPE
    ]),
    encouragingMessage() {
      if (this.OBSERVATIONS_ALL_USER.length > 5) {
        return "You are unstoppable!";
      }
      return "Keep going!";
    },
    newObservationText() {
      // shortened message is displayed for mobile size
      if (this.responsive) {
        return `New Observation`;
      }
      return `Record a New Observation`;
    }
  },

  async beforeMount() {
    await this.OBSERVATIONS_LOAD_ALL_USER({ vm: this });
    this.incrementBar(); // prevent the "you have no observations" msg from flashing on the screen if it's because vuex hasn't yet caught up
  },

  methods: {
    ...mapActions([
      types.actions.OBSERVATIONS_ALL_FEATURES_SPLICE,
      types.actions.USER_OBSERVATIONS_REMOVE,
      types.actions.CLEAR_HIDDEN_OBSERVATION,
      types.actions.OBSERVATIONS_LOAD_ALL_USER,
      types.actions.SET_SELECTED_OBSERVATION_TYPE,
      types.actions.CLEAR_SELECTED_OBSERVATION,
      types.actions.SET_MAP_ACTION_TRIGGER
    ]),

    incrementBar: function () {
      if (this.amount < 98) {
        this.amount++;
        setTimeout(this.incrementBar, Math.random() * 10);
        if (this.amount === 98) {
          this.initiateTimeOut();
        }
      }
    },
    initiateTimeOut() {
      if (this.collectingData) {
        this.timeOutAmount++;
        if (this.timeOutAmount === 20) {
          this.collectingData = false;
        } else {
          setTimeout(this.initiateTimeOut, 100);
        }
      }
    },
    orderBy(key) {
      // re-order observations list by key
      if (key === 'observedTime') {
        // numeric sort
        this.OBSERVATIONS_ALL_USER.sort((a, b) => b[key] - a[key]);
      } else {
        // text sort
        this.OBSERVATIONS_ALL_USER.sort(function (a, b) {
          const _a = a[key].toUpperCase();
          const _b = b[key].toUpperCase();
          if (_a < _b) {
            return -1;
          }
          if (_b < _a) {
            return 1;
          }
          // vals must be equal
          return 0;
        })
      }
    },
    createNewObservation() {
      // launch explore screen ready to create a new observation
      this.SET_MAP_ACTION_TRIGGER('newObservation');
      // if current observation type is system obs, set it to general
      if (!['water', 'general'].includes(this.SELECTED_OBSERVATION_TYPE)) {
        this.SET_SELECTED_OBSERVATION_TYPE('general');
      }
      // go to explore view
      this.$router.push({
        name: "Explore"
      });
    },
    confirmDeleteObservation(id) {
      // actually delete an observation when the user has confirmed it in modal
      const vm = this;
      swal.fire({
        title: "Are you sure?",
        text: `Your observation and any attached videos or images will be deleted forever.`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "md-button md-success",
          cancelButton: "md-button md-danger",
        },
        confirmButtonText: "Yes, delete it",
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          // Move on to actually delete it...
          // get the usertransaction in order to find the observation in the global list
          const userTransaction = this.OBSERVATIONS_ALL_USER.find(obs => obs.id === id).UserTransaction;

          // remove from the OBSERVATIONS_ALL_USER array
          this.USER_OBSERVATIONS_REMOVE(id); // remove from the OBSERVATIONS_ALL_USER array

          // clear from all obs json and selected observation - it should be in one or the other
          if (this.SELECTED_OBSERVATION !== null && this.SELECTED_OBSERVATION.properties.UserTransaction === userTransaction) {
            // if it's the observation that's currently open, clear it
            this.CLEAR_SELECTED_OBSERVATION();
            this.CLEAR_HIDDEN_OBSERVATION();
          } else {
            // remove from the OBSERVATIONS_ALL_JSON
            const feature = this.OBSERVATIONS_ALL_JSON.features.find(feat => feat.properties.UserTransaction === userTransaction);
            this.OBSERVATIONS_ALL_FEATURES_SPLICE({
              type: feature.properties.type,
              index: feature.id
            });
          }

          // api call to delete it
          const payload = {
            UserTransaction: userTransaction,
            imageBucket: this.$ImageBucket,
            videoBucket: this.$VideoBucket
          };

          this.$Auth
            .currentSession()
            .then(authData => {
              const username = authData.accessToken.payload.username;
              payload.UserName = username;
              const path = "/observation/" + username;
              const myInit = {
                body: payload,
                headers: { Authorization: authData.idToken.jwtToken }
              };

              this.$API
                .del("api", path, myInit)
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error);
                });
            })
            // eslint-disable-next-line
            .then(resp => {
              swal.fire({
                title: "It's gone",
                text: "Your observation has been deleted",
                icon: "success",
                customClass: {
                  confirmButton: "md-button md-success",
                },
                buttonsStyling: false
              });
              // reload the all observations json
              vm.debounceObservationsLoadAll();
            });
        }
      });
    },
  }
};
</script>

<style scoped>
.menu-button {
  margin-top: -0.3em;
}
.title-button {
  top: 1em;
}
</style>
