<template>
  <md-card md-with-hover>
    <md-card-media md-ratio="4:3">
      <img
        :src="tour.properties.image"
        @click="explore(tour)"
      >
    </md-card-media>
    <md-card-header class="md-card-header-blue">
      <div class="md-layout">
        <div class="md-layout-item md-size-100 card-title">
          <md-icon class="card-title-icon">
            place
          </md-icon>
          <a @click="explore(tour)">
            <span class="md-title">
              {{ tour.properties.title }}
            </span></a>
        </div>
      </div>
    </md-card-header>
    <md-card-content class="md-layout">
      <div class="md-layout-item md-size-100">
        {{ tour.properties.description }}
      </div>
      <div class="md-layout-item md-size-100 card-actions">
        <!-- SHARE -->
        <share-dialog
          v-if="$Region.socialSharingOn"
          ref="shareDialog"
          obj-type="vt"
          :obj="tour.properties"
          gtag-action="share_virtualtour_close"
          gtag-category="virtualtours"
        >
          <md-button class="md-simple md-just-icon">
            <span @click="toggleShareDialog">
              <md-icon>share</md-icon>
              <md-tooltip md-direction="bottom">Share</md-tooltip>
            </span>
          </md-button>
        </share-dialog>
        <!-- EXPLORE -->
        <md-button
          class="md-raised md-dense md-success explore-btn"
          @click="explore(tour)"
        >
          <md-icon>explore</md-icon>Explore
          <md-tooltip md-direction="bottom">
            Launch tour
          </md-tooltip>
        </md-button>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import ShareDialog from "@/pages/Dashboard/Components/ShareDialog";

export default {
  name: "VirtualTourCard",
  components: { ShareDialog },
  props: {
    tour: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
    };
  },
  methods: {
    explore() {
      this.$router.push({ name: "Explore" , query: {vt: this.tour.properties.code}});
    },
    toggleShareDialog() {
      this.$refs.shareDialog.toggleShowDialog();
    },
  }

};
</script>

<style lang="scss" scoped>
.card-title-icon {
  position: relative;
  padding: 0;
  color: $white-color !important;
}
.md-title {
  font-size: 20px;
  line-height: 20px;
}
.card-actions {
  text-align: right;
}
.explore-btn {
  left: 5px;
  margin-top: 10px;
}
/deep/ .explore-btn .md-ripple {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
</style>