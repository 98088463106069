<template>
  <md-card md-with-hover>
    <md-card-header class="md-card-header-blue">
      <div class="md-layout">
        <div class="md-layout-item md-size-100 card-title">
          <span class="md-title">
            {{ quest.title }}
          </span>
          <br>
          <md-chip class="tasks-chip">
            {{ quest.activities.length }} {{ 'task' | pluralize(quest.activities.length) }}
          </md-chip>
        </div>
      </div>
    </md-card-header>
    <md-card-content class="md-layout">
      <div class="md-layout-item md-size-100 layout-no-padding">
        <h2 class="quest-icon">
          <span class="fa-stack fa-2x">
            <i class="fas fa-rocket fa-stack-2x" />
            <i
              v-if="'icon' in quest && quest.icon !== ''"
              :class="questIcon"
            />
          </span>
        </h2>
      </div>
      <div class="md-layout-item md-size-100 layout-no-padding">
        <p>{{ quest.description }}</p>
        <p class="md-subheading">
          {{ quest.prompt }}
        </p>
      </div>
      <div class="md-layout-item md-size-100 card-actions">
        <!-- LAUNCH QUEST -->
        <md-button
          v-if="completed"
          class="md-simple md-just-icon md-success"
        >
          <md-icon>check</md-icon>
        </md-button>
        <md-button
          class="md-raised md-dense md-success launch-btn"
          @click="launchQuest()"
        >
          View Details
        </md-button>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  name: "UserQuestCard",

  components: {
  },

  props: {
    quest: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    completed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
    };
  },

  computed: {
    questIcon() {
      // get an icon to display for the quest
      return `fas ${this.quest.icon} fa-inverse fa-stack-1x quest-stack-icon`;
    },
    // shortDescription() {
    // not currently in use
    //   // get the shortened description
    //   const maxLength = 80;
    //   const regex = new RegExp(`^(.{${maxLength}}[^\s]*).*`);
    //   return this.quest.description.replace(regex, "$1");
    // }
  },

  methods: {
    launchQuest() {
      // emit to parent for launching
      this.$emit('on-launch-quest', this.index);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-title-icon {
  position: relative;
  margin: 0 0 0 -15px;
  padding: 0px;
  color: $white-color !important;
}
.card-title {
  color: $white-color !important;
}
.md-title {
  font-size: 16px;
  line-height: 20px;
}
.md-subhead {
  margin: 5px 0 0 0;
}
.card-actions {
  text-align: right;
}
.launch-btn {
  left: 5px;
  margin-top: 10px;
}
.tasks-chip {
  background-color: darken($brand-info, 10%) !important;
}
.quest-icon {
  color: $brand-primary;
  text-align: center;
}
.quest-stack-icon {
  font-size: 0.5em;
}
</style>
