// BRBC 360 videos

const brbcVideos = [
  {
    id: 2007,
    title: 'Invasive Species and Control',
    description: `Learn about invasive species in native grasslands, control systems, and the importance of native species from Erin Anderson and Megan Evans as you explore various prairie landscapes.`,
    coordinates: [-114.190296, 50.201977],
    subtype: 'brbc',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/3360de4ce6ba4b1ead9e342a2cdec406/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/brbc_1_invasive_species_and_control.png',
      shareUrl: '',
      is360: true,
      locations: [
        {
          time: 0,
          coordinates: '-114.190296, 50.201977',
        },
        {
          time: 74,
          coordinates: '-112.841994, 50.994639',
        },
        {
          time: 122,
          coordinates: '-114.076243, 50.705873',
        },
        {
          time: 175,
          coordinates: '-114.587656, 50.390193',
        },
        {
          time: 229,
          coordinates: '-113.369413, 51.006424',
        },
      ],
    },
    observedTime: 1607104608043,
    hashtags: 'water,watershed, monitoring',
  },
  {
    id: 2000,
    title: 'Elbow River Watershed Partnership (ERWP) Aquifers',
    description: `Explore the Elbow River watershed and learn about its groundwater, and state of the aquifer with Joe and Flora. Learn the definitions for what an aquifer is, how groundwater is used in the sub-basin, and how it is affected by people.`,
    coordinates: [-114.7810132, 50.8678614],
    subtype: 'brbc',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/0acc6dd386ae4331bf1388181d2511dc/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/brbc_2_elbow_river_watershed_partnership_aquifers.png',
      shareUrl: '',
      is360: true,
      locations: [
        {
          time: 0,
          coordinates: '-114.7810132,50.8678614',
        },
        {
          time: 161,
          coordinates: '-114.6923929,50.901219',
        },
        {
          time: 238,
          coordinates: '-114.563245,50.9533249',
        },
      ],
    },
    observedTime: 1607104608043,
    hashtags: 'water,watershed, monitoring',
  },
  {
    id: 2010,
    title: 'Native Prairies and Plants',
    description: `As Megan Evans and Erin Anderson talk about the importance of prairies and their native plants, explore several grassland landscapes within the Bow River Basin.`,
    coordinates: [-114.116592, 50.927827],
    subtype: 'brbc',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/31117838b5b049f195d0a14790e95a64/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/brbc_3_native_prairies_and_plants.png',
      shareUrl: '',
      is360: true,
      locations: [
        {
          time: 0,
          coordinates: '-114.116592, 50.927827',
        },
        {
          time: 47,
          coordinates: '-114.233110, 50.876436',
        },
        {
          time: 123,
          coordinates: '-113.696312, 50.805607',
        },
        {
          time: 200,
          coordinates: '-114.587656, 50.390193',
        },
        {
          time: 242,
          coordinates: '-114.190296, 50.201977',
        },
      ],
    },
    observedTime: 1607104608043,
    hashtags: 'water,watershed, monitoring',
  },
  {
    id: 2001,
    title: 'Watersheds and Hydrology',
    description: `Go from the mountains through the river basin while learning what a watershed is, and how hydrology is apparent in the landscape. Tricia Stadnyk will explain how hydrology can affect everything around it as you explore.`,
    coordinates: [-115.12966, 50.932082],
    subtype: 'brbc',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/a2355c31a3d7451483e63750d2ae1f48/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/brbc_4_watersheds_and_hydrology.png',
      shareUrl: '',
      is360: true,
      locations: [
        {
          time: 0,
          coordinates: '-115.129660, 50.932082',
        },
        {
          time: 54,
          coordinates: '-114.587656,50.390193',
        },
        {
          time: 172,
          coordinates: '-113.696312,50.805607',
        },
      ],
    },
    observedTime: 1607104608043,
    hashtags: 'water,watershed, monitoring',
  },
  {
    id: 2006,
    title: 'Wetlands and Stormwater',
    description: `Take a walk around the constructed wetlands in North Glenmore park with Liliana Bozic as she explains the uses of wetlands for storage and stormwater. Learn how water quality is affected by wetlands, and how land development changes, and seasons affect them.`,
    coordinates: [-114.120316, 50.989518],
    subtype: 'brbc',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/eb92e3d8e984451f9386414c7dd75121/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/brbc_5_wetlands_and_stormwater.png',
      shareUrl: '',
      is360: true,
      locations: [
        {
          time: 0,
          coordinates: '-114.120316, 50.989518',
        },
        {
          time: 110,
          coordinates: '-114.028253, 51.303713',
        },
      ],
    },
    observedTime: 1607104608043,
    hashtags: 'water,watershed, monitoring',
  },
  {
    id: 2004,
    title: 'Water Services',
    description: `Explore some of the Glenmore dam and reservoir as Patrick Van den Eynden talks about its history, infrastructure, services, and the City’s water drinking system.`,
    coordinates: [-114.097184, 50.999912],
    subtype: 'brbc',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/71e5011337c04dda87b9327490254d70/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/brbc_6_water_services.png',
      shareUrl: '',
      is360: true,
      locations: [
        {
          time: 0,
          coordinates: '-114.097184, 50.999912',
        },
        {
          time: 225,
          coordinates: '-114.220395, 51.099285',
        },
        {
          time: 261,
          coordinates: '-114.097184, 50.999912',
        },
      ],
    },
    observedTime: 1607104608043,
    hashtags: 'water,watershed, monitoring',
  },
  {
    id: 2009,
    title: 'Water Quality',
    description: `Join Patrick Van den Eynden as he talks about the City of Calgary’s Glenmore Water Treatment Plant’s process from getting the water from the reservoir, all the way to disinfection and sending the water out to service sites across the city.`,
    coordinates: [-114.098440, 51.002496],
    subtype: 'brbc',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/0566b3f5515243b89026137be6fe050e/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/brbc_7_water_quality.png',
      shareUrl: '',
      is360: true,
    },
    observedTime: 1607104608043,
    hashtags: 'water,watershed, monitoring',
  },
  {
    id: 2002,
    title: 'Hydrology and Land Development',
    description: `Explore the watershed while Tricia Stadnyk explains how landscapes and water are affected by development and use changes. How does hydrology within the watershed change when the landscape changes because of humans?`,
    coordinates: [-114.716500, 51.219053],
    subtype: 'brbc',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/7a9a290fb3a84ce58f07e91f8e96049b/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/brbc_8_hydrology_and_land_development.png',
      shareUrl: '',
      is360: true,
      locations: [
        {
          time: 0,
          coordinates: '-114.716500, 51.219053',
        },
        {
          time: 54,
          coordinates: '-114.097184,50.999912',
        },
        {
          time: 116,
          coordinates: '-114.077158,51.055417',
        },
        {
          time: 155,
          coordinates: '-115.129660,50.932082',
        },
        {
          time: 212,
          coordinates: '-115.145593,50.917276',
        },
        {
          time: 238,
          coordinates: '-115.048676,51.033742',
        },
      ],
    },
    observedTime: 1607104608043,
    hashtags: 'water,watershed, monitoring',
  },
  {
    id: 2005,
    title: 'Watershed Management and Data',
    description: `Dr. Lee Jackson talks about the Sustainable Watershed Integrated Management Platform and the necessity of shared water data for the health of the Bow River Basin. Explore from the headwaters into the prairies and learn about citizen science projects, and different data parameters.`,
    coordinates: [-116.464694, 51.678051],
    subtype: 'brbc',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/fa86c43190f043ce98aed64b16304c33/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/brbc_9_watershed_management_and_data.png',
      shareUrl: '',
      is360: true,
      locations: [
        {
          time: 0,
          coordinates: '-116.464694, 51.678851',
        },
        {
          time: 47,
          coordinates: '-114.959892, 51.263301',
        },
        {
          time: 89,
          coordinates: '-114.077158, 51.055417',
        },
        {
          time: 139,
          coordinates: '-114.220627, 51.099321',
        },
        {
          time: 160,
          coordinates: '-114.097184, 50.999912',
        },
        {
          time: 191,
          coordinates: '-113.828234, 51.020180',
        },
        {
          time: 235,
          coordinates: '-113.696312, 50.805607',
        },
      ],
    },
    observedTime: 1607104608043,
    hashtags: 'water,watershed, monitoring',
  },
  {
    id: 2003,
    title: 'Hydrology and Climate Change',
    description: `Tricia Stadnyk talks about how a watershed can affect the climate it is in, and in turn, how the climate then affects the landscape. Go from the headwaters through to the prairies as she talks about precipitation and more.`,
    coordinates: [-116.464694, 51.678451],
    subtype: 'brbc',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/4364db1e1a7041538db38b0d58346cb5/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/brbc_10_hydrology_and_climate_change.png',
      shareUrl: '',
      is360: true,
      locations: [
        {
          time: 0,
          coordinates: '-116.464694, 51.678851',
        },
        {
          time: 126,
          coordinates: '-114.959892,51.263301',
        },
        {
          time: 208,
          coordinates: '-114.243607,50.689097',
        },
      ],
    },
    observedTime: 1607104608043,
    hashtags: 'water,watershed, monitoring',
  },
  {
    id: 2008,
    title: 'Watershed',
    description: `Visit each sub-basin in the Bow River basin and get a feel for how people use the water and the landscape. Look at the different types of buildings, pathways, and activities that can take place within the watershed as you travel from the headwaters, down to the end in the prairies.`,
    coordinates: [-116.464694, 51.678851],
    subtype: 'brbc',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/7f8e6a38b7534de6b27c6d87eade9d62/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/brbc_11_watershed.png',
      shareUrl: '',
      is360: true,
      locations: [
        {
          time: 0,
          coordinates: '-116.464694, 51.678851',
        },
        {
          time: 20,
          coordinates: '-115.129660, 50.932082',
        },
        {
          time: 35,
          coordinates: '-114.959892, 51.263301',
        },
        {
          time: 50,
          coordinates: '-114.771438, 51.037320',
        },
        {
          time: 65,
          coordinates: '-114.716500, 51.219053',
        },
        {
          time: 80,
          coordinates: '-114.190296, 50.201977',
        },
        {
          time: 95,
          coordinates: '-114.703432, 50.615995',
        },
        {
          time: 110,
          coordinates: '-114.116592, 50.927827',
        },
        {
          time: 125,
          coordinates: '-114.097184, 50.999912',
        },
        {
          time: 140,
          coordinates: '-114.049133, 51.422275',
        },
        {
          time: 155,
          coordinates: '-114.077158, 51.055417',
        },
        {
          time: 170,
          coordinates: '-113.696312, 50.805607',
        },
        {
          time: 185,
          coordinates: '-113.441861, 50.827096',
        },
        {
          time: 200,
          coordinates: '-112.918901, 50.561080',
        },
      ],
    },
    observedTime: 1607104608043,
    hashtags: 'water,watershed, monitoring',
  }
];

export default brbcVideos;
