// your mission
import RegionConditions from "./region-conditions";

/*
Mission can contain a number of items for users to complete.
Sample mission item:
    {
      title: "", // the item title
      description: "", // this text is shown for any item that is not yet completed by the user
      helpAction: null, // this function is triggered when user clicks on a mission item button
      successConditions: [], // conditions must be in place for this item to be marked complete
      successMsg: "", // text is shown once user has completed the item (all successConditions return true)
      successPrompt: "", // optional chip showing a prompt to the user for next things to think about
      icon: "", // for md-icons use icon name, leave iconClass empty
      iconClass: "" // for fontawesome use iconClass, leave icon empty
    }
*/

/*
Basic mission components for AB
- watch all indicator videos
- explore map
- create a scenario
- create a historic scenario
- create a bau scenario
- create a future land use plan
- compare climate scenarios
- submit an observation
*/

const RegionMission = {
  title: "Your Mission",
  description: `<p><strong>Your mission is to create a sustainable landuse plan for the future Alberta that balances the environmental, social, and economic values of Albertans.</strong>
                To do this you must first understand how our actions impact the environment, and economy.</p>
                <p>You will do this by watching the videos and viewing the historic and business as usual scenarios.
                Once you understand the impacts we have on both the environment and economy, you will create your own landuse plan for
                Alberta's future. Set your goals and decide how you will reach them.</p>
                <p>The Alberta Tomorrow simulator will do all the hard work and calculations to see if you can reach your goals with the landuse plan you created.</p>
                <p>Remember, you can earn achievments and points. Check out how you did by looking at the
                leader board!</p>
                <p>Take the challenge and create a sustainable and balanced landuse plan! It's not easy, but who said anything worthwhile in life is easy!
                How successful will your mission be?</p>`,
  media: {
    type: "none",
    url: "",
    thumbUrl: "",
  },
  missionItems: [
    {
      title: "Watch the videos",
      description:
        "Learn about Alberta's environment, economy and resource production.  Watch the Environmental Indicators and Socio-Economic Indicators video series.",
      helpAction: RegionConditions.launchVideos,
      successConditions: [RegionConditions.userHasViewedAllIndicatorVideos],
      successMsg: "You've watched all of the videos. Way to go!",
      successPrompt:
        "Did you learn something new about land use, the environment and the economy?",
      icon: "videocam",
      iconClass: "",
    },
    {
      title: "Explore the map",
      description:
        "Look around Alberta.  Can you find your town or city?  Can you find your home?",
      helpAction: RegionConditions.launchExplore,
      successConditions: [RegionConditions.userHasUsedMap],
      successMsg: "Good job! You found the map.",
      successPrompt:
        "Try creating a historic scenario to see how the landscape has changed over time.",
      icon: "explore",
      iconClass: "",
    },
    {
      title: "Create a Scenario",
      description: "Create a scenario to look at land-use changes over time.",
      helpAction: RegionConditions.launchScenarios,
      successConditions: [RegionConditions.userHasScenarios],
      successMsg: "Nice work creating your first scenario!",
      successPrompt: "How do different scenarios compare?",
      icon: "map",
      iconClass: "",
    },
    {
      title: "Explore the Past",
      description:
        "Take a look at how the landscape has changed over time by creating a Historic scenario",
      helpAction: RegionConditions.launchScenarios,
      successConditions: [RegionConditions.userHasHistoricScenarios],
      successMsg: "Awesome - you created your first Historic Scenario!",
      successPrompt:
        "Did the landscape changes over time affect environmental or socio-economic indicators?",
      icon: "rotate_left",
      iconClass: "",
    },
    {
      title: "Glimpse the Future",
      description:
        "Where are we headed? Create a future Business as Usual scenario to see what the future might look like.",
      helpAction: RegionConditions.launchScenarios,
      successConditions: [RegionConditions.userHasBAUScenarios],
      successMsg: "Nice work! You created a future Business as Usual scenario",
      successPrompt:
        "Do all of the environmental and socio-economic indicators look healthy?",
      icon: "rotate_right",
      iconClass: "",
    },
    {
      title: "Design Your Own Future",
      description: "Create future scenario with your own land-use plan.",
      helpAction: RegionConditions.launchScenarios,
      successConditions: [RegionConditions.userHasLandUseScenarios],
      successMsg:
        "Things just got real - you're making your own future land use plans.  You're a pro!",
      successPrompt:
        "Keep going! Try running different scenarios to see how outcomes might differ.",
      icon: "edit",
      iconClass: "",
    },
    {
      title: "Learn about Climate Change",
      description:
        "Watch the Climate Change video and make multiple scenarios to compare the effects of climate change",
      helpAction: RegionConditions.launchClimateVideo,
      successConditions: [RegionConditions.userHasComparedClimateScenarios],
      successMsg:
        "Excellent! You used scenario modelling to look at how climate change could impact Alberta.",
      successPrompt:
        "What could we do to mitigate impacts of Climate Change in Alberta?",
      icon: "",
      iconClass: "fal fa-thermometer-half",
    },
    {
      title: "Observe",
      description:
        "Take a walk or a field trip and submit your own land use or water quality observations.",
      helpAction: RegionConditions.launchObservations,
      successConditions: [RegionConditions.userHasObservations], // conditions must be in place for this item to be marked complete
      successMsg: "Awesome!  Thanks for contributing to the observation data!",
      successPrompt: "Have you told a friend about your observations?",
      icon: "location_on",
      iconClass: "",
    },
    {
      title: "Report",
      description:
        "Create a comparison report to understand the differences between two or more scenarios.",
      helpAction: RegionConditions.launchReports,
      successConditions: [RegionConditions.userHasComparisonReports], // conditions must be in place for this item to be marked complete
      successMsg: "You're a pro!  Keep up the good work",
      successPrompt: "Have you told a friend about your report analysis?",
      icon: "insert_chart_outlined",
      iconClass: "",
    },
  ],
};

export default RegionMission;
