// indicator definitions
import RegionEquations from "./region-equations";

const RegionIndicators = {
  devRateSliderHelper: RegionEquations.devRateSliderHelper,
  equationHelpers: RegionEquations.helpers, // these functions are passed through back to individual indicator equations so they have access as-needed to additional helper utilities
  indicators: [
    {
      name: "Natural Landscapes",
      type: "Environmental",
      equation: RegionEquations.NaturalLandscapes,
      order: 0,
      units: "%",
      simple_units: "%",
      conversion: 1,
      icon: "natural_landscape.svg",
      baseline: 40,
      goal: 50,
      target: "high",
      className: "ct-series-d",
      description:
        "What does this mean? Prior to Alberta's European Settlement, 100% of the landscape was considered natural. That is, 100% of the landscape was covered by either forests, wetlands or native prairie, with 0% of the landscape devoted to development. Best practices can reduce the amount of footprint associated with industrial development. To learn more about the natural landscapes of Alberta, watch the Natural Landscapes video in the Mission section.",
      media: {
        type: "video",
        url:
          "https://d2qcvmovr4fv.cloudfront.net/out/v1/a87c6ba3b82849e48619d4027ed41e81/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/7d120260-62f4-48f9-8d72-63fb519fd9a7/thumbnails/Natural Landscapes v3_H264_tumb.0000064.jpg",
        shareUrl: "https://www.youtube.com/watch?v=Lx94jZ9ZSBY",
      },
      hashtags: "landuse,abnaturallandscapes,abecoregions",
    },
    {
      name: "Caribou Habitat",
      type: "Environmental",
      equation: RegionEquations.CaribouHabitat,
      order: 10,
      units: "%",
      simple_units: "%",
      conversion: 1,
      icon: "caribou_habitat.svg",
      baseline: 40,
      goal: 50,
      target: "high",
      className: "ct-series-h",
      description:
        "What does this mean? 100% represents a landscape that is 100% suitable for caribou habitat. Caribou habitat is determined by the amount of landscape that is natural, and the degree of development on the landscape. Caribou are impacted by habitat loss and by predation. The amount of predation correlates directly with the amount of human disturbances such as cutblocks, well sites, roads, and pipelines. These disturbances in the habitat make it easier for humans and wolves to hunt caribou. Best practices can reduce the human footprint by shortening the lifespan and lessening the size of industrial features.",
      media: {
        type: "video",
        url:
          "https://d2qcvmovr4fv.cloudfront.net/out/v1/92b720ba2d60441d97a416358c3b700e/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/6d77c369-dc90-454f-a833-ace354a38e40/thumbnails/Caribou Habitat v4_H264_tumb.0000035.jpg",
        shareUrl: "https://www.youtube.com/watch?v=xiBz_Vov6H0",
      },
      hashtags:
        "caribouhabitat,abcaribou,woodlandcaribou,threatenedspecies,landuse,abwildlife",
    },
    {
      name: "Grizzly Habitat",
      type: "Environmental",
      equation: RegionEquations.GrizzlyHabitat,
      order: 11,
      units: "%",
      simple_units: "%",
      conversion: 1,
      icon: "grizzly_habitat.svg",
      baseline: 40,
      goal: 50,
      target: "high",
      className: "ct-series-h",
      description:
        "What does this mean? If the grizzly habitat is at 0%, there is 0% suitable habitat for grizzlies. If the indicator was at 100%, 100% of the landscape would be suitable for grizzly habitat. Grizzly bears require large areas of forest that are secluded from human activity. Resource development such as oil and gas, mining and forestry change the age of the forest and increase the road density which leads to more human-bear encounters that often result in grizzly death.",
      media: {
        type: "video",
        url:
          "https://d2qcvmovr4fv.cloudfront.net/out/v1/7d5e0260a35c45af8755c0186b82e3d3/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/4aec75a3-5a3a-44fa-8350-8f8dfda26f0c/thumbnails/Grizzly Bear habitat v1_tumb.0000021.jpg",
        shareUrl: "https://www.youtube.com/watch?v=4wxOOhpNRhs",
      },
      hashtags:
        "grizzlybearhabitat,abwildlife,abgrizzlies,landuse,threatenedspecies",
    },
    {
      name: "Fish Habitat",
      type: "Environmental",
      equation: RegionEquations.FishCommunityHealth,
      order: 20,
      units: "%",
      simple_units: "%",
      conversion: 1,
      icon: "fish_comm_health.svg",
      baseline: 40,
      goal: 50,
      target: "high",
      className: "ct-series-i",
      description:
        "What does this mean? A value of 100% reflects a natural fish community where the full range of native species exist and large sport fish such as walleye and pike are abundant. A value of 0% reflects a fish community that has been highly disturbed by angling and habitat disturbance that is associated with land use. A highly disturbed fish community is dominated by minnows and suckers and contain few large sport fish. Best practices are assumed to increase fish community health by limiting angler access to lakes, rivers, and streams.",
      media: {
        type: "video",
        url:
          "https://d2qcvmovr4fv.cloudfront.net/out/v1/edc8301613324a6699d44e7e8ad91427/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/28da03d6-aee2-4c23-be8f-bee1d50d7a4a/thumbnails/Fish Habitat v2_H264_tumb.0000030.jpg",
        shareUrl: "https://www.youtube.com/watch?v=oti7o8JS62Q",
      },
      hashtags: "abfish,fishhabitat,abwater,abtrout,nativetrout",
    },
    {
      name: "Water Quality",
      type: "Environmental",
      equation: RegionEquations.WaterQuality,
      order: 30,
      units: "%",
      simple_units: "%",
      conversion: 1,
      icon: "water_quality.svg",
      baseline: 40,
      goal: 50,
      target: "high",
      className: "ct-series-j",
      description:
        "What does this mean? Phosphorus runoff is assessed to investigate water quality. Elevated levels of phosphorus runoff can degrade aquatic ecosystems through eutrophication. A value of 100% represents the phosphorus runoff associated with natural land cover. The indicator declines as the landscape is converted to agriculture and settlement that cause higher levels of phosphorous runoff. Best practices such as avoiding excessive application of fertilizers can reduce phosphorus runoff.",
      media: {
        type: "video",
        url:
          "https://d2qcvmovr4fv.cloudfront.net/out/v1/5cde617d12f6457c8c1021b18aaa4796/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/71ac9e8a-e577-4765-9783-71eaee4c08dd/thumbnails/Water Quality v2_H264_tumb.0000063.jpg",
        shareUrl: "https://www.youtube.com/watch?v=qsaeCqrJ64o",
      },
      hashtags: "abwater,waterquality,eutrophication,landuse",
    },
    {
      name: "Biotic Carbon",
      type: "Environmental",
      equation: RegionEquations.BioticCarbon,
      order: 40,
      units: "%",
      simple_units: "%",
      conversion: 1,
      icon: "biotic_carbon.svg",
      baseline: 40,
      goal: 50,
      target: "high",
      className: "ct-series-k",
      description:
        "What does this mean? A value of 100% represents the biotic carbon stored in a natural landscape. Biotic carbon is the carbon stored in vegetation such as trees, shrubs and grasses, as well as the carbon stored in soil and the ocean. The biotic carbon value declines as wetland, grassland, and forest is converted to farmland and settlements, or used for timber production.",
    },
    {
      name: "Greenhouse Gasses",
      type: "Environmental",
      equation: RegionEquations.GreenhouseGasses,
      order: 50,
      units: "tonnes CO₂",
      simple_units: "tonnes CO₂",
      conversion: 1,
      icon: "greenhouse_gasses.svg",
      baseline: 40,
      goal: 50,
      target: "low",
      className: "ct-series-l",
      description:
        "What does this mean? The greenhouse gas indicator increases as the region's human population and natural resource production increase. The maximum value is equivalent to the amount of greenhouse gases that would be emitted if the human population were to double over the next 30 years, which approximates the growth rate of the Calgary-Edmonton corridor. But what is a tonne of CO2?  1 tonne of CO2 would fill a cube 8.23 m by 8.23 m by 8.23 m.  It would be the equivalent of the emissions from driving your car almost 4000 km, or the amount of carbon taken in by 16.5 trees grown for 10 years. Best practices are assumed to reduce greenhouse gas emissions by 50%, based on the Alberta Government's goal of cutting emission intensity in half by 2050.",
      media: {
        type: "video",
        url:
          "https://d2qcvmovr4fv.cloudfront.net/out/v1/0ae65744bb3847bb9a6d8b8eb33c2ef0/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/be03a00b-b27d-4afe-be64-332ec61b92c5/thumbnails/Greenhouse Gases v2_H264_tumb.0000039.jpg",
        shareUrl: "https://www.youtube.com/watch?v=m7G3mSq2aD0",
      },
      hashtags:
        "abclimatechange,climatechange,bioticcarbonstorage,landuse,photosynthesis,carbonsinks,carbonsources,greenhousegases,greehouseeffect",
    },
    {
      name: "Human Population",
      type: "Socio-Economic",
      equation: RegionEquations.HumanPopulation,
      order: 60,
      units: "People",
      simple_units: "People",
      conversion: 1,
      icon: "human_population.svg",
      baseline: 40,
      goal: 50,
      target: "high",
      className: "ct-series-m",
      description:
        "What does this mean? Maximum population growth is assumed to be a doubling of the population, based on the growth rate in the lower mainland. An indicator value of 100% represents a doubling of the population. To learn more about human population, watch the Human Population video in the Mission section.",
      media: {
        type: "video",
        url:
          "https://d2qcvmovr4fv.cloudfront.net/out/v1/88c0e1da186a403b9d8f6504062316a9/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/020d0ab8-d1d3-4cc4-b064-ce5c86f1b702/thumbnails/Human Population v2_H264_tumb.0000010.jpg",
        shareUrl: "https://www.youtube.com/watch?v=BOBc-sv-qJw",
      },
      hashtags: "abpopulation,exponentialpopulationgrowth,abgrowth,landuse",
    },
    {
      name: "Gross Domestic Product",
      type: "Socio-Economic",
      equation: RegionEquations.GrossDomesticProduct,
      order: 70,
      units: "$",
      simple_units: "$",
      conversion: 1,
      icon: "gdp.svg",
      baseline: 40,
      goal: 50,
      target: "high",
      className: "ct-series-n",
      description:
        "What does this mean? GDP for an area is based on the amount of area designated to oil and gas production, forestry and agriculture. The maximum GDP value occurs if the entire region is used for natural resource production.",
      media: {
        type: "video",
        url:
          "https://d2qcvmovr4fv.cloudfront.net/out/v1/b8a59f71ae254c71ab89fcb1bdc48d07/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/e6bbc086-0392-43b3-9a2b-3aa3fef83eea/thumbnails/GDP v2_H264_tumb.0000011.jpg",
        shareUrl: "https://www.youtube.com/watch?v=u9_-Oz9W3Dg",
      },
      hashtags: "abgdp,abeconomy,landuse",
    },
    {
      name: "Hydrocarbon Production",
      type: "Socio-Economic",
      equation: RegionEquations.HydrocarbonProduction,
      order: 80,
      units: "GJ",
      simple_units: "Barrels Oil",
      conversion: 0.17060421187678282, //GJ per barrel
      icon: "hydrocarbon.svg",
      baseline: 40,
      goal: 50,
      target: "high",
      className: "ct-series-o",
      description:
        "Hyrocarbon production includes all fossil fuels such as natural gas, oil, butumen (oil sands) and coal. Production is converted to barrels of oil.  1 barrel of oil is equivalent to 160 liters (40 4 l milk jugs). The maximum growth in hydrocarbon production is twice current production.",
      media: {
        type: "video",
        url:
          "https://d2qcvmovr4fv.cloudfront.net/out/v1/d48b44f7b1294f1d811ac728af797c42/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/cd19ded8-4eea-4b3a-941b-a42e65800907/thumbnails/Oil Gas v2_tumb.0000033.jpg",
        shareUrl: "https://www.youtube.com/watch?v=Bfz4p6dQQcE",
      },
      hashtags: "aboilandgas,hydrocarbons,landuse,abeconomy,landuse",
    },
    {
      name: "Timber Production",
      type: "Socio-Economic",
      equation: RegionEquations.TimberProduction,
      order: 90,
      units: "m³",
      simple_units: "Truck Loads",
      conversion: 0.020588840848260244, // 48.57m3 per load
      icon: "timber_prod.svg",
      baseline: 40,
      goal: 50,
      target: "high",
      className: "ct-series-a",
      description:
        "What does this mean? The maximum level means that the entire area that is forested is being used for timber production",
      media: {
        type: "video",
        url:
          "https://d2qcvmovr4fv.cloudfront.net/out/v1/82edfc851a914347a416aafc0108d37b/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/741f8b05-8f79-4994-8234-01592f4688e2/thumbnails/Forestry v2_H264_tumb.0000025.jpg",
        shareUrl: "https://www.youtube.com/watch?v=157uaHUOKJw",
      },
      hashtags: "abforestry,landuse,forestry,abeconomy",
    },
    {
      name: "Agricultural Production",
      type: "Socio-Economic",
      equation: RegionEquations.AgriculturalProduction,
      order: 100,
      units: "kcal",
      simple_units: "People Fed",
      conversion: 7.7612635e-7, // 1/288450 to convert 4250842 kcal per ha (used in the equation) to people fed per ha based on doc,
      icon: "agri_prod.svg",
      baseline: 40,
      goal: 50,
      target: "high",
      className: "ct-series-b",
      description:
        "What does this mean? A value of 100% occurs when the entire landscape is used for agriculture.",
      media: {
        type: "video",
        url:
          "https://d2qcvmovr4fv.cloudfront.net/out/v1/ae54d05645274ba78eb07c8c480d8455/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/760a93f5-2cff-49e0-843c-3dd759b7232d/thumbnails/Agriculture Production v2_H264_tumb.0000025.jpg",
        shareUrl: "https://www.youtube.com/watch?v=hh84gk46swY",
      },
      hashtags: "abag,agriculture,landuse,forestry,abeconomy",
    },
    {
      name: "Water Consumption",
      type: "Socio-Economic",
      equation: RegionEquations.WaterConsumption,
      order: 110,
      units: "m³",
      simple_units: "Swimming Pools", // could use swimming pools? 2500m3/pool
      conversion: 0.0004, // 0.0004 to convert to swimming pools
      icon: "water_consumption.svg",
      baseline: 40,
      goal: 50,
      target: "low",
      className: "ct-series-c",
      description:
        "Water use in m³ is based on the area of agriculture, settelement and the amount of hydrocarbon prodution. To picture a m³, imagine a cube 1 m by 1m by 1m. That's the equivalent of 1000 L of water.  A standard Olympic sized swimming pool contains 2500m³ of water. Best practices reduce water use by 30% (based on the Alberta Environment provincial water strategy)",
      media: {
        type: "video",
        url:
          "https://d2qcvmovr4fv.cloudfront.net/out/v1/15cffe272bc44d51a21fda366d42a26d/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/a42e73bb-ede0-415a-b12b-a873782a69a1/thumbnails/Water Consumption v3_H264_tumb.0000039.jpg",
        shareUrl: "https://www.youtube.com/watch?v=YcibG9eybbc&t=2s",
      },
      hashtags: "abwater,abwateruse,wateruse,landuse",
    },
    // INDIGENOUS INDICATORS
    // these indicators have `collection: "Indigenous"` and are rendered only in scenarios that use the indigenous indicator collection
    {
      name: "TLU Access",
      collection: "Indigenous",
      type: "Indigenous",
      equation: RegionEquations.TLUAccess,
      order: 200,
      units: "%",
      simple_units: "%",
      conversion: 1,
      icon: "feather-svgrepo-com.svg",
      baseline: 40,
      goal: 50,
      target: "high",
      className: "ct-series-c",
      description:
        `Traditional Land Use Access (TLU Access) is a measure of how accessible the land is for Indigenous people to undertake traditional land use activities such as hunting, fishing, and gathering berries and medicines. Land is deemed to be accessible for TLU in natural areas such as grassland, wetland and forest, and inaccessible in urban, agricultural and industrial areas. TLU accessibility is further restricted by protected areas that prevent or make it difficult for TLU activities to occur. Protected areas in Alberta include national parks, provincial parks, provincial recreation areas, ecological reserves, and wilderness areas. As a result, TLU Access is a reflection of how much natural landscape is present that is not identified as being within a protected area.`,
      media: {
        type: 'video',
        status: 'Complete',
        url:
          'https://d2qcvmovr4fv.cloudfront.net/out/v1/31daf805c8d44748a6a14f7d38cc0084/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
        thumbUrl:
          'https://d2qcvmovr4fv.cloudfront.net/a7edc400-3c29-48bb-bcc6-296883adc89c/thumbnails/Using%20the%20Land_tumb.0000000.jpg',
        shareUrl: 'https://youtu.be/yz52Ysp_meQ?si=2uYyt4W4Qjq31z2N',
        is360: false
      },
      hashtags: "abwater,abwateruse,wateruse,landuse",
    },
    {
      name: "Moose Habitat",
      collection: "Indigenous",
      type: "Indigenous",
      equation: RegionEquations.MooseHabitat,
      order: 210,
      units: "%",
      simple_units: "%",
      conversion: 1,
      icon: "moose-shape-svgrepo-com.svg",
      baseline: 40,
      goal: 50,
      target: "high",
      className: "ct-series-c",
      description:
        `Moose Habitat is based on the habitat value of the land cover types that are present, adjusted to account for the reduced value of habitat in proximity to development footprints due to avoidance and mortality. As more land is converted from prime moose habitat such as natural wetlands and forest to developed or industrialized footprints like urban, agricultural or industrial, the habitat value decreases. In addition, an increased industrial development rate and the proximity of developed footprints to the preferred moose habitat footprints causes further reduction in habitat. The Moose Habitat equation is indexed to the natural, pre-industrial state of the landscape for each study area in Alberta Tomorrow with a value of 100% representing the natural moose habitat conditions for that study area. Any decline in moose habitat therefore represents how this indicator has declined relative to the natural state.`,
      media: {
        type: 'video',
        status: 'Complete',
        url:
          'https://d2qcvmovr4fv.cloudfront.net/out/v1/0b5e0c2176c843228629c01f7775b085/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
        thumbUrl:
          'https://d2qcvmovr4fv.cloudfront.net/18212f4c-ba95-443b-9a9c-8cea0186f22a/thumbnails/Moose%20Story_tumb.0000000.jpg',
        shareUrl: 'https://youtu.be/rb0WIzUHnYw?si=r438JuVzkL4PxsaR',
        is360: false
      },
      hashtags: "abwater,abwateruse,wateruse,landuse",
    },
    {
      name: "Hunting Opportunity",
      collection: "Indigenous",
      type: "Indigenous",
      equation: RegionEquations.HuntingOpportunity,
      order: 220,
      units: "%",
      simple_units: "%",
      conversion: 1,
      icon: "moose_tlu.svg",
      baseline: 40,
      goal: 50,
      target: "high",
      className: "ct-series-c",
      description:
        `What does this mean? The Hunting Opportunity indicator represents how much opportunity there is for Indigenous people to participate in successful hunting activity in this study area. Hunting Opportunity is calculated as a combination of TLU Access and Moose Habitat. The maximum value occurs when there is a maximum of TLU Access and a maximum of Moose Habitat. A reduction in either TLU Access or Moose Habitat results in reduced opportunities for hunting.`,
      media: {
        type: 'video',
        status: 'Complete',
        url:
          'https://d2qcvmovr4fv.cloudfront.net/out/v1/5eefc5c24b0d4099bd93ae333cf1ec23/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
        thumbUrl:
          'https://d2qcvmovr4fv.cloudfront.net/4682cb45-4133-437f-b341-98c1a7254a06/thumbnails/Land%20Use%20wildlife_tumb.0000000.jpg',
        shareUrl: 'https://youtu.be/d_wKqUZMaTk?si=3dnMMNcSOKg8pW5X',
        is360: false
      },
      hashtags: "abwater,abwateruse,wateruse,landuse",
    },
    {
      name: "Water Quality",
      collection: "Indigenous",
      type: "Indigenous",
      equation: RegionEquations.WaterQuality,
      order: 230,
      units: "%",
      simple_units: "%",
      conversion: 1,
      icon: "water_quality.svg",
      baseline: 40,
      goal: 50,
      target: "high",
      className: "ct-series-j",
      description:
        "What does this mean? Phosphorus runoff is assessed to investigate water quality. Elevated levels of phosphorus runoff can degrade aquatic ecosystems through eutrophication. A value of 100% represents the phosphorus runoff associated with natural land cover. The indicator declines as the landscape is converted to agriculture and settlement that cause higher levels of phosphorous runoff. Best practices such as avoiding excessive application of fertilizers can reduce phosphorus runoff.",
      media: {
        type: 'video',
        status: 'Complete',
        url:
          'https://d2qcvmovr4fv.cloudfront.net/out/v1/eee47828da824aeb93a087271924c641/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
        thumbUrl:
          'https://d2qcvmovr4fv.cloudfront.net/91762971-98d6-4e2a-91d0-8cce43dfb69f/thumbnails/Water%20-%20now%20and%20future_tumb.0000000.jpg',
        shareUrl: 'https://youtu.be/eUKsxcJtYNY?si=ocljo1c9IxdRESlq',
        is360: false
      },
      hashtags: "abwater,waterquality,eutrophication,landuse",
    },
    {
      name: "Fish Habitat",
      collection: "Indigenous",
      type: "Indigenous",
      equation: RegionEquations.FishCommunityHealth,
      order: 240,
      units: "%",
      simple_units: "%",
      conversion: 1,
      icon: "fish_comm_health.svg",
      baseline: 40,
      goal: 50,
      target: "high",
      className: "ct-series-i",
      description:
        "What does this mean? A value of 100% reflects a natural fish community where the full range of native species exist and large sport fish such as walleye and pike are abundant. A value of 0% reflects a fish community that has been highly disturbed by angling and habitat disturbance that is associated with land use. A highly disturbed fish community is dominated by minnows and suckers and contain few large sport fish. Best practices are assumed to increase fish community health by limiting angler access to lakes, rivers, and streams.",
      media: {
        type: "video",
        url:
          "https://d2qcvmovr4fv.cloudfront.net/out/v1/edc8301613324a6699d44e7e8ad91427/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/28da03d6-aee2-4c23-be8f-bee1d50d7a4a/thumbnails/Fish Habitat v2_H264_tumb.0000030.jpg",
        shareUrl: "https://www.youtube.com/watch?v=oti7o8JS62Q",
      },
      hashtags: "abfish,fishhabitat,abwater,abtrout,nativetrout",
    },
    {
      name: "Fishing Opportunity",
      collection: "Indigenous",
      type: "Indigenous",
      equation: RegionEquations.FishingOpportunity,
      order: 250,
      units: "%",
      simple_units: "%",
      conversion: 1,
      icon: "fish_tlu.svg",
      baseline: 40,
      goal: 50,
      target: "high",
      className: "ct-series-i",
      description:
        `What does this mean? The Fishing Opportunity indicator represents how much opportunity there is for Indigenous people to participate in successful fishing activity in this study area. Fishing Opportunity is calculated as a combination of TLU Access and Fish Habitat. The maximum value occurs when there is a maximum of TLU Access and a maximum of Fish Habitat. A reduction in either TLU Access or Fish Habitat results in reduced opportunities for fishing.`,
      media: {
        type: 'video',
        status: 'Complete',
        url:
          'https://d2qcvmovr4fv.cloudfront.net/out/v1/64b6a087dc63427fa227b08165994242/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
        thumbUrl:
          'https://d2qcvmovr4fv.cloudfront.net/e9bd9064-c34e-4769-a3c3-8d702623d91b/thumbnails/Fishing%20on%20the%20Bow_tumb.0000000.jpg',
        shareUrl: 'https://youtu.be/vN_X59jznLE?si=qs742rPJ9WvbOe1y',
        is360: false
      },
      hashtags: "abfish,fishhabitat,abwater,abtrout,nativetrout",
    },
  ],
  collectionIndicators: function (collection) {
    // gather all indicators that have the given collection (ie: indigenous)
    return this.indicators.filter((i) => 'collection' in i && i.collection === collection);
  },
  defaultIndicators: [
    // list of indicators to be used by all regions
    // individual regions can add to this by providing a list of regionIndicators in the region geojson
    // indicators are ordered according to indicator.order values when rendering
    "Natural Landscapes",
    "Fish Habitat",
    "Water Quality",
    "Biotic Carbon",
    "Greenhouse Gasses",
    "Human Population",
    "Gross Domestic Product",
    "Hydrocarbon Production",
    "Timber Production",
    "Agricultural Production",
    "Water Consumption",
  ],
};

export default RegionIndicators;
