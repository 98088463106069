<template>
  <div class="md-layout">
    <!-- reset password confirmation dialog -->
    <md-dialog-confirm
      :md-active.sync="showUpdatePasswordConfirmDialog"
      :md-click-outside-to-close="false"
      md-title="Reset User Password"
      :md-content="resetPwdText"
      md-confirm-text="Reset Password"
      @md-confirm="acceptResetPassword"
      @md-cancel="cancelResetPassword"
    />
    <!-- reset password completed dialog -->
    <md-dialog
      :md-active.sync="showUpdatePasswordCompleteDialog"
      :md-click-outside-to-close="false"
    >
      <md-dialog-title>Reset User Password</md-dialog-title>
      <div
        v-if="!passwordUpdated"
        class="md-layout-item md-size-100 md-elevation-1"
      >
        <p
          class="
            text-center
            md-layout md-layout-item md-alignment-center-center
            updating-text
          "
        >
          Updating password for {{ resetPwdUserName }}
        </p>
        <p
          class="
            text-center
            md-layout md-layout-item md-alignment-center-center
          "
        >
          <md-progress-spinner
            class="md-secondary"
            :md-stroke="10"
            :md-diameter="150"
            md-mode="indeterminate"
          />
        </p>
      </div>
      <div
        v-else
        class="md-layout-item md-size-100 md-elevation-1"
      >
        <p
          class="
            text-center
            md-layout md-layout-item md-alignment-center-center
            updating-text
          "
        >
          Done! Password has been reset for {{ resetPwdUserName }}.
        </p>
        <p>
          <strong>New password:</strong>
        </p>
        <div class="md-layout">
          <div class="md-layout-item md-size-40 md-small-size-30">
            <md-field>
              <md-input
                v-model="newPassword"
                readonly
              />
            </md-field>
          </div>
          <div class="md-layout-item md-size-20 md-small-size-20">
            <md-button
              class="md-success"
              @click="clipWrite"
            >
              <md-icon>content_copy</md-icon>Copy to Clipboard
            </md-button>
          </div>
        </div>
      </div>
      <md-dialog-actions>
        <md-button @click="closePasswordComplete">
          Close
        </md-button>
      </md-dialog-actions>
    </md-dialog>
    <!-- end reset password completed dialog -->
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
    >
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-warning">
          <div class="card-text">
            <h4 class="title">
              Teacher
            </h4>
          </div>
        </md-card-header>
      </md-card>
    </div>
    <zoom-y-transition>
      <div
        v-if="STUDENTS_DATA_LOADED"
        class="md-layout-item md-size-100"
      >
        <div class="md-layout">
          <div
            id="students"
            class="
              md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100
            "
          >
            <!--Students-->
            <dashboard-students
              :students="STUDENTS"
              :teacher="USER_DATA.UserName"
              @on-password-update="launchUpdatePasswordDialog"
            />
          </div>
        </div>
      </div>
    </zoom-y-transition>
    <zoom-y-transition>
      <div
        v-if="studentObservationDataLoaded"
        class="md-layout-item md-size-100"
      >
        <div class="md-layout">
          <div
            id="studentsobservations"
            class="
              md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100
            "
          >
            <!--Student Observations-->
            <dashboard-student-observations
              :observations="studentObservations"
            />
          </div>
        </div>
      </div>
    </zoom-y-transition>
    <div
      id="achievements"
      class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50"
    >
      <!--Achievements-->
      <dashboard-achievements
        :teacher="null"
        :usertype="USER_DATA.usertype"
      />
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50">
      <div class="md-layout">
        <div
          id="scenarios"
          class="
            md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100
          "
        >
          <!--Scenarios-->
          <dashboard-scenarios
            :scenarios="scenarios"
            @edit-scenario="editScenario"
            @explore-scenario="exploreScenario"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { write as clipboardyWrite } from "clipboardy";
import { mapGetters } from "vuex";
import { types } from "@/store/types";
import DashboardScenarios from "./Components/DashboardScenarios";
import DashboardStudents from "./Components/DashboardStudents";
import DashboardStudentObservations from "./Components/DashboardStudentObservations";
import DashboardAchievements from "./Components/DashboardAchievements";
import { ZoomYTransition } from "vue2-transitions";
import { DashboardScenariosMixin } from "@/pages/Dashboard/Components/mixins/DashboardScenariosMixin";
import { TeacherFunctions } from "@/pages/Dashboard/Components/mixins/TeacherFunctions";

export default {
  components: {
    DashboardScenarios,
    DashboardStudents,
    DashboardStudentObservations,
    DashboardAchievements,
    ZoomYTransition,
  },
  mixins: [DashboardScenariosMixin, TeacherFunctions],

  props: {},

  data() {
    return {
      studentObservations: [],
      studentObservationDataLoaded: false,
      showUpdatePasswordConfirmDialog: false,
      showUpdatePasswordCompleteDialog: false,
      resetPwdUserName: null,
      resetPwdStudentName: null,
      passwordUpdated: false,
      newPassword: null,
    };
  },

  computed: {
    ...mapGetters([types.getters.USER_DATA]),

    resetPwdText() {
      return `Are you sure you want to change password for the following user? <br>Name: <strong>${this.resetPwdStudentName}</strong><br>username: <strong>${this.resetPwdUserName}</strong>`;
    },
  },

  beforeMount() {
    this.studentsLoad();
    this.getStudentObservations();
  },

  methods: {
    remapStudentObsData(item) {
      // remap data from API call into data row
      // data from API endpoint lambda function is coming in a different format than the data that comes directly from dynamodb
      ["media", "details"].forEach((key) => {
        if (key in item) {
          item[key] = JSON.parse(item[key].replace(/'/g, '"'));
        }
      });
      return item;
    },
    getStudentObservations() {
      // get list of observations linked to the teacher from API
      this.$Auth.currentSession().then((authData) => {
        const path = `/obsadmin/teacher`;
        const myInit = {
          headers: { Authorization: authData.idToken.jwtToken },
          body: {
            UserName: this.USER_DATA.UserName,
          },
        };
        this.$API
          .post("api", path, myInit)
          .then((body) => {
            if ("Observations" in body.body) {
              body.body.Observations.forEach((item) => {
                this.studentObservations.push(this.remapStudentObsData(item));
              });
              this.studentObservationDataLoaded = true;
            }
          })
          .catch((error) => {
            console.log(error.response); // eslint-disable-line
          });
      });
    },
    launchUpdatePasswordDialog(UserName, studentName) {
      this.resetPwdUserName = UserName;
      this.resetPwdStudentName = studentName;
      this.showUpdatePasswordConfirmDialog = true;
    },
    acceptResetPassword() {
      this.passwordUpdated = false;
      this.newPassword = null;
      this.showUpdatePasswordCompleteDialog = true;
      // reset password via API
      this.$Auth.currentSession().then((authData) => {
        const _payload = {
          UserName: this.resetPwdUserName,
        };
        const payload = JSON.stringify(_payload).replace(/"/g, "`");
        const path = `/bulkadmin`;
        const myInit = {
          headers: { Authorization: authData.idToken.jwtToken },
          body: {
            UserName: this.USER_DATA.UserName,
            AdminAction: "ResetUserPassword",
            Payload: payload,
          },
        };
        this.$API
          .post("api", path, myInit)
          .then((body) => {
            if ("user" in body.body) {
              this.newPassword = body.body.user.password;
              this.passwordUpdated = true;
            }
          })
          .catch((error) => {
            console.log(error.response); // eslint-disable-line
          });
      });
    },
    cancelResetPassword() {
      this.resetPwdUserName = null;
      this.resetPwdStudentName = null;
    },
    closePasswordComplete() {
      this.resetPwdUserName = null;
      this.resetPwdStudentName = null;
      this.showUpdatePasswordCompleteDialog = false;
    },
    clipWrite() {
      // copy to clipboard
      clipboardyWrite(this.newPassword);
      this.$notify({
        message: "Password was copied to your clipboard.",
        icon: "content_copy",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "info",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.updating-text {
  padding-bottom: 32px;
}
</style>