<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <div class="md-card-content">
            <md-content v-html="$Region.voices.description" />
          </div>
        </md-card>
      </div>
      <div class="md-layout-item md-size-100">
        <indigenous-achievements
          :teacher="USER_DATA.teacher"
          :usertype="USER_DATA.usertype"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { types } from "@/store/types";
import IndigenousAchievements from "@/pages/Dashboard/Components/IndigenousAchievements";

export default {
  name: "IndigenousVoices",

  components: {
    IndigenousAchievements
  },
  props: {},
  data() {
    return {
    };
  },

  computed: {
    ...mapGetters([
      types.getters.USER_DATA
    ])
  },
};
</script>

<style scoped>
</style>
