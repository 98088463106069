<template>
  <div class="md-layout text-center">
    <div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
      <login-card header-color="green">
        <h4
          slot="title"
          class="title"
        >
          {{ loginText }}
        </h4>
        <md-button
          slot="buttons"
          to="/registerwizard"
          class="md-just-icon md-simple md-white"
          @mouseover.native="showRegister"
          @mouseout.native="showRegister"
        >
          <md-icon>person_add</md-icon>
        </md-button>
        <div
          v-if="showLoginWarning"
          slot="description"
          class="md-elevation-2 signup-warning"
        >
          <h4>
            Notice to Classic Version Users
          </h4>
          <p>
            Login accounts for the older version of {{ $Region.app_name }} are not connected to our new simulator. Please
            <router-link :to="{ name: 'Register' }">
              register a new FREE account
            </router-link>
            to begin using the new simulator.
          </p>
        </div>
        <p
          slot="description"
          class="description"
        >
          Log in if you've already signed up
        </p>

        <md-field
          slot="inputs"
          class="md-form-group"
        >
          <md-icon>person</md-icon>
          <label>Email or Username...</label>
          <md-input
            v-model="email"
            type="email"
            @keyup.13="login"
          />
        </md-field>

        <md-field
          slot="inputs"
          class="md-form-group"
        >
          <md-icon>lock_outline</md-icon>
          <label>Password...</label>
          <md-input
            v-model="password"
            type="password"
            @keyup.13="login"
          />
        </md-field>
        <p
          v-if="!triedOnce"
          slot="inputs"
          class="forgot-pass-small"
        >
          <a @click="forgotPassword">
            Forgot Password
          </a>
        </p>
        <md-button
          slot="footer"
          :disabled="apiUpdating"
          class="md-raised md-success md-lg"
          @click="login"
          @keyup.13="login"
        >
          <md-icon
            v-if="apiUpdating"
            class="updating-icon"
          >
            swap_horiz
          </md-icon>
          Lets Go!
        </md-button>
        <md-button
          v-if="triedOnce"
          slot="footer"
          class="md-default md-lg"
          @click="forgotPassword"
        >
          Forgot Password
        </md-button>
      </login-card>
    </div>
  </div>
</template>
<script>
import { LoginCard } from '@/components'
import swal from 'sweetalert2'

export default {

  components: {
    LoginCard
  },
  data() {
    return {
      authenticated: false,
      email: null,
      password: null,
      triedOnce: false,
      loginText: 'Log In',
      apiUpdating: false
    }
  },
  computed: {
    showLoginWarning() {
      // whether or not to show warning to return users when they have trouble signing in
      return (this.triedOnce && "showLoginWarning" in this.$Region && this.$Region.showLoginWarning);
    }
  },
  async beforeCreate() {
    try {
      const auth_status = await this.$Auth.currentAuthenticatedUser();
      this.authenticated = Boolean(auth_status.attributes.given_name);
    } catch (err) {
      this.authenticated = false
    }
  },
  methods: {
    showRegister() {
      if (this.loginText === 'Register') {
        this.loginText = 'Log In'
      }
      else { this.loginText = 'Register' }
    },
    errorAlert(message) {
      swal.fire({
        title: `<p style="color:red;">Sorry</p>`,
        text: message,
        timer: 2000,
        showConfirmButton: false
      })
    },

    async login() {
      if (this.email == null || this.password == null) {
        this.errorAlert('A user email and password must be provided')
      }
      else {
        try {
          this.apiUpdating = true;
          const auth_result = await this.$Auth.signIn(this.email, this.password); // eslint-disable-line
          this.authenticated = true;
          swal.fire({
            timer: 1000,
            icon: 'success',
            showConfirmButton: false
          });
          this.$router.push('/Dashboard')
          // analytics tracking
          this.$Helpers.gtagEvent(this, `login`, `engagement`, 'cognito');
        } catch (err) {
          // Check if the user needs to complete their profile
          if (err.message.toLowerCase().includes('not confirmed')) {
            this.$router.push({
              name: 'Register',
              params: {
                confirmOnly: true,
                email: this.email,
                password: this.password
              }
            });
          }
          else {
            this.apiUpdating = false;
            this.triedOnce = true;
            this.errorAlert(err.message)
            // analytics tracking
            this.$Helpers.gtagEvent(this, `login_fail`, `engagement`, 'cognito');
          }
        }
      }
    },
    forgotPassword() {
      this.$router.push('/forgotPassword')
    }
  }
}
</script>

<style lang="scss" scoped>
.forgot-pass-small {
  margin: 0 0 0 40px;
  cursor: pointer;
}
.signup-warning {
  border-radius: 10px;
  margin-bottom: 20px;
}
.signup-warning h4 {
  background-color: $brand-warning;
  color: $white-color;
  text-align: center;
  margin: 0;
  padding: 12px 10px 10px 10px;
  border-radius: 10px 10px 0 0;
}
.signup-warning p {
  margin: 0;
  padding: 10px;
  border: 2px solid $brand-warning;
  border-radius: 0 0 10px 10px;
  text-align: center;
}
/* updating icon animation */
.updating-icon {
  animation: flip 2s infinite;
}
@keyframes flip {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
