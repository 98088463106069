<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-33 profile-card">
        <user-card
          button-color="success"
          :user-data="userData"
        />
      </div>
      <div class="md-layout-item md-medium-size-100 md-size-66">
        <edit-profile-form
          header-color="green"
          :user-data="userData"
        />
      </div>
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-text md-card-header-green">
            <div class="card-text">
              <h4 class="title">
                Home Base
              </h4>
            </div>
          </md-card-header>
          <md-card-content>
            <div class="card-text">
              <user-profile-map
                class="map map-big"
                :lat="USER_DATA.lat"
                :lng="USER_DATA.lng"
              />
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { types } from "@/store/types";
import { EditProfileForm, UserCard } from '@/pages'
import UserProfileMap from '@/pages/Dashboard/Components/UserProfileMap';
export default {

  components: {
    EditProfileForm,
    UserCard,
    UserProfileMap },

  props: {
    scrollLocation: {
      type: String,
      default: '0'
    }
  },

  data() {
    return {
      mapOptions: {
        disableDefaultUI: true
      }
    }
  },

  computed: {
    ...mapGetters([
      types.getters.USER_DATA
    ]),

    coords: function () {
      const coords = {};

      if (this.userData.lat !== 0 && this.userData.lng !== 0) {
        coords.lat = this.userData.lat;
        coords.lng = this.userData.lng
      }
      else {
        coords.lat = this.$Region.map_coords.lat;
        coords.lng = this.$Region.map_coords.lng
      }

      return coords
    },
    zIndex: function () {
      if (this.userData.lat !== 0 && this.userData.lng !== 0) {
        return 9
      }

      return 5

    },
    userData: function () {
      return this.USER_DATA;
    }
  }
}
</script>
<style lang="scss">
.text-right {
  display: flex;
}
.profile-card {
  margin-top: 100px;
}
</style>
