<template>
  <simple-wizard
    :wizard-locked="wizardLocked"
    :finish-button-text="finishButtonText"
    :show-cancel="true"
    :on-cancel="cancelWizard"
  >
    <template slot="header">
      <h3 class="title">
        New Observation
      </h3>
      <h5 class="category">
        Please fill in some details to record your observation.
      </h5>
    </template>
    <wizard-tab :before-change="() => validateStep('generalInfo')">
      <template slot="label">
        General Info
      </template>
      <observation-new-info
        ref="generalInfo"
        :wizard-locked="wizardLocked"
        @on-validated="onStepValidated"
      />
    </wizard-tab>
    <wizard-tab :before-change="() => validateStep('obsDetails')">
      <template slot="label">
        Details
      </template>
      <observation-new-details
        ref="obsDetails"
        :observation-type="observation.type"
        :wizard-locked="wizardLocked"
        @on-validated="onStepValidated"
      />
    </wizard-tab>
    <wizard-tab :before-change="() => validateStep('obsMedia')">
      <template slot="label">
        Media
      </template>
      <observation-new-media
        ref="obsMedia"
        :observation-user-transaction="observation.UserTransaction"
        @on-validated="wizardComplete"
        @on-upload-complete="uploadComplete"
      />
    </wizard-tab>
  </simple-wizard>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import { Observation } from "./mixins/Observation";
import { User } from "@/pages/Dashboard/Components/mixins/User";
import { Gamification } from "@/pages/Dashboard/Components/mixins/Gamification";
import { SimpleWizard, WizardTab } from '@/components';
import ObservationNewInfo from './ObservationNewInfo';
import ObservationNewDetails from './ObservationNewDetails';
import ObservationNewMedia from './ObservationNewMedia';
import swal from "sweetalert2";
import Geohash from 'latlon-geohash'; // future-proofing... geohashing will allow to query a smaller subset of observations in future when volume is too high
import { v4 as uuidv4 } from "uuid";
export default {
  name: "ObservationNew",

  components: {
    SimpleWizard,
    WizardTab,
    ObservationNewInfo,
    ObservationNewDetails,
    ObservationNewMedia
  },
  mixins: [Observation, User, Gamification],

  props: {
  },

  data() {
    return {
      observation: {
        type: ''
      }, // to assemble the observation data -- this will be mutated by child components through props
      wizardLocked: false, // to lock wizard during upload of completed form
      finishButtonText: 'Save'
    };
  },

  computed: {
    ...mapGetters([
      types.getters.EXPLORE_MODE,
      types.getters.OBSERVATIONS_ALL_JSON
    ])
  },

  created() {
    // init the observation id
    this.observation.UserTransaction = this.getObservationID();
  },

  methods: {
    ...mapActions([
      types.actions.OBSERVATIONS_ALL_FEATURES_SPLICE,
      types.actions.TOGGLE_REFRESH_OBSERVATION_DATA
    ]),

    validateStep(ref) {
      // validate using the validate() function on the ref component
      return this.$refs[ref].validate()
    },
    onStepValidated(res, model) {
      // update the data from the child component
      this.observation = { ...this.observation, ...model };
    },
    getObservationID() {
      // get a unique ID for a new observation
      return `obs_${uuidv4()}`;
    },
    getGeoHash() {
      // return the geohash for a given point
      // get a geohash with a precision of 6 (cell width <= 1.22km; cell height <= 0.61km)... plenty of precision for filtering observations on general queries
      return Geohash.encode(this.SELECTED_OBSERVATION.geometry.coordinates[1], this.SELECTED_OBSERVATION.geometry.coordinates[0], 6);
    },
    uploadComplete() {
      // update the upload status
      this.closeWizard();
    },
    wizardComplete(res, model) {
      // update the data from the child component
      this.observation = { ...this.observation, ...model };

      // prepare to give some points
      let pointsToAdd = this.$Region.awardPoints.observationCreate;

      // lock the wizard against changes during saving
      this.wizardLocked = true;
      this.finishButtonText = "Saving your observation..."

      // notify that saving is started
      this.$notify({
        message: 'Saving your observation details.',
        icon: "save",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success"
      });

      try {
        // Send the observation information to the DB
        // add the username, usertransaction, other details to the observation
        this.observation.UserName = this.$Auth.user.username;
        this.observation.coordinates = this.SELECTED_OBSERVATION.geometry.coordinates;
        this.observation.geohash = this.getGeoHash(); // provide geo-hash for future-proofing to allow geo-indexing of observations
        this.observation.GeoEntityType = "obs"; // used for secondary index in dynamodb

        const payload = { ...this.observation };
        payload.details = JSON.stringify(this.observation.details).replace(/"/g, "'");
        payload.media = JSON.stringify(this.observation.media).replace(/"/g, "'");

        // sanitize the payload
        this.$Helpers.sanitizeDBPayload(payload);
        // push to the database
        this.$Auth.currentSession().then(authData => {
          const path = "/observation/";
          const myInit = {
            body: this.$Helpers.bodyEmptyStringToNull(payload),
            headers: { Authorization: authData.idToken.jwtToken }
          };

          this.$API
            .put("api", path, myInit)
            // .then(() => {
            // eslint-disable-next-line no-console
            // console.log(response);
            // })
            .catch(error => {
              // eslint-disable-next-line no-console
              console.error(error.response);
            });
        });

        // upload media if attached
        if (['video', 'image'].includes(this.observation.media.type)) {
          this.finishButtonText = "Uploading media..."
          this.$refs.obsMedia.uploadMedia();
          // event listener will automatically close the wizard when the upload has completed
          // give the user some extra points for including media!
          pointsToAdd += this.$Region.awardPoints.observationMedia
        } else {
          this.closeWizard();
        }
        // give the user some points!
        // delay a bit to make sure other popups are closed and don't obscure the point counter updating
        const vm = this;
        setTimeout(function () {
          vm.addUserPoints(pointsToAdd);
        }, 2000);

        // analytics tracking
        const label = ['video', 'image'].includes(this.observation.media.type) ? `${this.observation.type} obs with ${this.observation.media.type}` : `${this.observation.type} obs`;
        this.$Helpers.gtagEvent(this, `create_observation`, `observations`, label);

      } catch (err) { console.log(err) } // eslint-disable-line
    },
    closeWizard() {
      // close the wizard and notify user when all processes are completed

      // notify user saving
      swal.fire({
        title: `Observation Saved!`,
        text: `Thanks for your contribution!`,
        icon: 'success',
        customClass: {
          confirmButton: "md-button md-success",
        },
        buttonsStyling: false
      });

      // check for achievements or game activity
      // GAMIFICATION EVENT: obs-create
      this.getGameActivity('obs-create');

      // add the new observation to the map
      const _obs = { ...this.SELECTED_OBSERVATION };
      const idx = this.OBSERVATIONS_ALL_JSON['observations'].features.length;
      delete _obs.status; // don't want to include the status key
      // add basic fields
      _obs.id = idx;
      _obs.properties = {
        UserName: this.observation.UserName,
        UserTransaction: this.observation.UserTransaction,
        icon: `obs${this.observation.type}`,
        type: this.observation.type
      };
      this.OBSERVATIONS_ALL_FEATURES_SPLICE({
        type: this.observation.type,
        index: idx,
        feature: _obs
      });

      // clear the marker
      this.CLEAR_SELECTED_OBSERVATION();

      // refresh the map source
      this.TOGGLE_REFRESH_OBSERVATION_DATA('observations');

      // close the sidebar panel
      if (this.EXPLORE_MODE === "explore") {
        this.CLOSE_SIDEBAR();
      }
    },
    cancelWizard() {
      // cancel creating a new observation
      // clear the marker
      this.CLEAR_SELECTED_OBSERVATION();
      this.TOGGLE_SIDEBAR_CONTENT();

      // refresh the map source
      this.TOGGLE_REFRESH_OBSERVATION_DATA('observations');
      // close the sidebar panel
      if (this.EXPLORE_MODE === "explore") {
        this.CLOSE_SIDEBAR();
      }
    }
  }

};
</script>
<style lang="scss" scoped>
</style>