<template>
  <div class="full-page">
    <!-- standard menu -->
    <md-toolbar
      md-elevation="0"
      class="md-transparent md-toolbar-absolute"
    >
      <div class="md-toolbar-row md-offset">
        <div
          v-if="!$sidebar.showSidebar"
          class="md-toolbar-section-start"
        >
          <h3 class="md-title">
            {{ toolbarTitle }}
          </h3>
        </div>
        <div
          v-if="!$sidebar.showSidebar"
          class="md-toolbar-section-end"
        >
          <md-button
            class="md-just-icon md-simple md-round md-toolbar-toggle"
            :class="{toggled: $sidebar.showSidebar}"
            @click="toggleSidebar"
          >
            <span class="icon-bar" />
            <span class="icon-bar" />
            <span class="icon-bar" />
          </md-button>
          <auth-links
            v-if="!responsive"
            class="md-collapse"
            :class="{'off-canvas-sidebar': responsive}"
            :authenticated="authenticated"
          />
        </div>
      </div>
    </md-toolbar>
    <!-- mobile menu -->
    <md-drawer
      v-if="responsive"
      :md-active.sync="$sidebar.showSidebar"
      md-swipeable
    >
      <md-toolbar
        class="md-primary"
        md-elevation="0"
      >
        <span class="md-title">{{ $Region.app_name }}</span>
      </md-toolbar>
      <auth-links
        :authenticated="authenticated"
        @on-link-clicked="linkClick"
      />
    </md-drawer>
    <div
      class="wrapper wrapper-full-page"
      @click="toggleSidebarPage"
    >
      <div
        class="page-header header-filter"
        :class="setPageClass"
        filter-color="black"
        :style="setBgImage"
      >
        <div class="container md-offset">
          <zoom-center-transition
            :duration="pageTransitionDuration"
            mode="out-in"
          >
            <router-view />
          </zoom-center-transition>
        </div>
        <footer
          v-if="hasSponsors"
          class="footer md-elevation-16"
          :class="[{'footer-mobile': isMdXSmall}]"
        >
          <div key="flickity">
            <flickity
              ref="flickity"
              :options="flickityOptions"
            >
              <sponsor-logo
                v-for="sponsor in orderedSponsors"
                :key="sponsor.name"
                :sponsor="sponsor"
              />
            </flickity>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import { ZoomCenterTransition } from 'vue2-transitions';
import AuthLinks from "@/pages/Dashboard/Layout/Extra/AuthLinks";
import SponsorLogo from "@/pages/Dashboard/Components/SponsorLogo";
import { ResponsiveLayoutHelpers } from "@/pages/Dashboard/Components/mixins/ResponsiveLayoutHelpers";
import swal from "sweetalert2";
import Flickity from "vue-flickity";

export default {
  name: "AuthLayout",

  components: {
    ZoomCenterTransition,
    AuthLinks,
    SponsorLogo,
    Flickity
  },
  mixins: [ResponsiveLayoutHelpers],

  props: {
    backgroundColor: {
      type: String,
      default: 'black'
    }
  },

  data() {
    return {
      authenticated: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 300,
      year: new Date().getFullYear(),
      flickityOptions: {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        autoPlay: 4000,
        cellAlign: 'left',
        draggable: '>1',
        groupCells: 2
      },
    }
  },

  computed: {
    toolbarTitle() {
      return this.$route.name === 'Simulator' ? `${this.$Region.app_name} ${this.$route.name}` : this.$route.name;
    },
    setBgImage() {
      const images = {
        Type: './img/bg-pricing.jpg',
        Login: './img/login.jpg',
        Password: './img/bg-pricing.jpg',
        Register: './img/register.jpg',
        Lock: './img/lock.jpg',
        Simulator: './img/bg-pricing.jpg'
      };
      return {
        backgroundImage: `url(${images[this.$route.name]})`
      }
    },
    setPageClass() {
      return `${this.$route.name}-page`.toLowerCase();
    },
    hasSponsors() {
      // are there sponsors to display
      return ('sponsors' in this.$Region && this.$Region.sponsors.length);
    },
    orderedSponsors() {
      // return list of sponsors, ordered by region-sponsors.js data
      return this.$Region.sponsors;
    },
    // randomSponsors() {
    //   // return randomized list of sponsors
    //   const shuffleArray = function (array) {
    //     return array.sort(() => Math.random() - 0.5);
    //   }
    //   return shuffleArray(this.$Region.sponsors);
    // },
  },

  async beforeCreate() {
    if (this.$route.name === 'Lock') {
      this.authenticated = false
    } else {
      try {
        const auth_status = await this.$Auth.currentAuthenticatedUser();
        this.authenticated = Boolean(auth_status.attributes.given_name);
      } catch (err) {
        this.authenticated = false
      }
    }
  },

  mounted() {
    if (this.responsive) {
      swal.fire({
        // title: `Welcome to ${this.$Region.app_name}!`,
        title: `<i class="fas fa-mobile-alt" /><h2 class="swal2-title" id="swal2-title" style="display: flex; font-size: 32px;">Welcome to ${this.$Region.app_name}!</h2>`,
        html: `<p>We noticed you are browsing on a mobile device.  We are still working on mobile layout optimizations.</p>
               <p>Please bear with us while we improve the mobile experience over the coming days.</p>
               <p>In the meantime, we recommend the desktop version for the best experience.</p>`,
        iconHtml: ``,
        customClass: {
          confirmButton: "md-button md-success",
        },
        buttonsStyling: false,
        confirmButtonText: "Ok"
      })
    }
  },

  inject: {
    autoClose: {
      default: true
    }
  },

  methods: {
    toggleSidebarPage() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
    linkClick(logout = false) {
      if (this.autoClose && this.$sidebar && this.$sidebar.showSidebar === true) {
        this.$sidebar.displaySidebar(false)
      }
      if (logout) {
        this.authenticated = false;
      }
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
  }
}
</script>
<style lang="scss" scoped>
$scaleSize: 0.1;
$zoomOutStart: 0.7;
$zoomOutEnd: 0.46;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}
.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}
@keyframes zoomOut8 {
  from {
    opacity: 1;
    transform: scale3d($zoomOutStart, $zoomOutStart, $zoomOutStart);
  }
  to {
    opacity: 0;
    transform: scale3d($zoomOutEnd, $zoomOutEnd, $zoomOutEnd);
  }
}
.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}
.footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  height: 160px;
  background: $gray-lighter;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
.footer-mobile {
  height: 100px;
}
.footer h3 {
  color: $gray-darker;
  margin: 0 0 5px 0 !important;
  font-size: 16px;
  line-height: 20px;
}
.carousel-cell {
  width: 20%;
  height: 140px;
  margin-right: 10px;
  background: #fff;
  border-radius: 5px;
}
.footer-mobile .carousel-cell {
  width: 33%;
  height: 80px;
}
/deep/ .sponsor-logo {
  max-height: 100%;
  max-width: 100%;
  padding: 10px;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
/deep/ .sponsor-name {
  color: $gray-dark;
  font-weight: bold;
  text-align: center;
  max-height: 100%;
  max-width: 100%;
  padding: 20px;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
/deep/ .footer-mobile .sponsor-name {
  font-size: 10px;
  line-height: 12px;
}
</style>
