<template>
  <div class="md-layout">
    <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50">
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-warning">
          <div class="card-text">
            <h4 class="title">
              Public
            </h4>
          </div>
        </md-card-header>
      </md-card>
    </div>
    <div
      id="achievements"
      class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50"
    >
      <!--Achievements-->
      <dashboard-achievements
        :teacher="null"
        :usertype="USER_DATA.usertype"
      />
    </div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50">
      <div class="md-layout">
        <div
          id="scenarios"
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        >
          <!--Scenarios-->
          <dashboard-scenarios
            :scenarios="scenarios"
            @edit-scenario="editScenario"
            @explore-scenario="exploreScenario"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { types } from "@/store/types";
import DashboardScenarios from "./Components/DashboardScenarios";
import DashboardAchievements from "./Components/DashboardAchievements";
import { DashboardScenariosMixin } from "@/pages/Dashboard/Components/mixins/DashboardScenariosMixin";

export default {
  components: {
    DashboardScenarios,
    DashboardAchievements
  },
  mixins: [DashboardScenariosMixin],

  props: {},

  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      types.getters.USER_DATA
    ]),
  }
}
</script>
