// badges setup
import RegionConditions from "./region-conditions";

/* SAMPLE BADGE OBJECT
{
  code: "code",
  title: "title",
  description: "desc",
  successMsg: "hurray",
  comment: "",
  event: "quest-complete", // possible types: ['obs-create', 'obs-view', 'video-view', 'scenario-view', 'quest-complete', 'report-create', 'mission-complete']
  passConditions: [RegionConditions.questCompleteSomething],
  points: 100,
  icon: "icon"
}
*/

const RegionBadges = [
  // -------------- GENERIC BADGES - APPLICABLE TO ANY JURISDICTION/VERSION ------------------
  {
    code: "missionComplete",
    title: "Mission Accomplished",
    description: "Complete all of the mission tasks to earn this badge.",
    successMsg:
      "Congratulations - you have completed all of the tasks in Your Mission! Keep going - see how many quests you can complete too!",
    comment: "",
    event: "mission-complete",
    passConditions: [RegionConditions.trueConditionTest], // relies on logic in mission page - 'mission-complete' event is only fired if it all tasks are done
    points: 500,
    icon: "fa-briefcase",
  },
  {
    code: "firstPlan",
    title: "First Plan",
    description: "Create your first land use plan to earn this badge.",
    successMsg: "You created your first plan.  Way to go!",
    comment: "",
    event: "scenario-view",
    passConditions: [RegionConditions.userHasLandUseScenarios],
    points: 50,
    icon: "fa-map",
  },
  {
    code: "reporter",
    title: "Reporter",
    description: "Create a report to unlock this achievement",
    successMsg: "You created your first report.  Yay!  Keep going!",
    comment: "",
    event: "report-create",
    passConditions: [RegionConditions.userHasReports],
    points: 50,
    icon: "fa-newspaper",
  },
  {
    code: "create3Plans",
    title: "Make 3 Plans",
    description:
      "Create 3 different scenarios including Historic, Business as Usual and a Land Use Plan to unlock this achievement.",
    successMsg:
      "You created 3 different scenarios including a Historical, Business as Usual and Land Use Plan. Nice work!",
    comment: "",
    event: "scenario-view",
    passConditions: [
      RegionConditions.userHasHistoricScenarios,
      RegionConditions.userHasBAUScenarios,
      RegionConditions.userHasLandUseScenarios,
    ],
    points: 150,
    icon: "fa-map",
  },
  {
    code: "goalSetter",
    title: "Goal Setter",
    description:
      "Achieve all of your goals in a Land Use Plan to achieve this badge.",
    successMsg: "You achieved all of your goals in a Land Use Plan.  Good job!",
    comment: "",
    event: "scenario-view",
    passConditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.goalsPassAll,
    ],
    points: 150,
    icon: "fa-clipboard-check",
  },
  {
    code: "reportCompareRegionsBAU",
    title: "Regional Analyst",
    description:
      "Create a comparison report looking at BAU scenarios in 2 different watersheds to unlock this achievement.",
    successMsg:
      "You created a comparison report looking at BAU scenarios in 2 different watersheds.  Great work!",
    comment: "",
    event: "report-create",
    passConditions: [RegionConditions.userHasComparisonReportsBAUWatersheds],
    points: 250,
    icon: "fa-map",
  },
  {
    code: "reportCompareClimate",
    title: "Climate Researcher",
    description:
      "Create a comparison report looking at different climate scenarios in the same watershed.",
    successMsg:
      "You created a report comparing different climate models in a watershed.  Great work!",
    comment: "",
    event: "report-create",
    passConditions: [RegionConditions.userHasComparisonReportClimate],
    points: 250,
    icon: "fa-cloud-sun-rain",
  },
  {
    code: "watchAllVideos",
    title: "Researcher",
    description:
      "To earn this badge, watch all of the videos in the Environmental Indicators and Socio-Economic Indicators video series.",
    successMsg:
      "You watched all of videos in the Environmental Indicators and Socio-Economic Indicators video series.  Keep up the research and learning!",
    comment: "",
    event: "video-view",
    passConditions: [RegionConditions.userHasViewedAllIndicatorVideos],
    points: 200,
    icon: "fa-video",
  },
  {
    code: "createObsGeneral",
    title: "Scout",
    description: "To earn this badge, submit a land use observation.",
    successMsg:
      "You submitted a land use observation.  Thanks for contributing!",
    comment: "",
    event: "obs-create",
    passConditions: [RegionConditions.userHasLandUseObservations],
    points: 100,
    icon: "fa-binoculars",
  },
  {
    code: "createObsWater",
    title: "Hydrologist",
    description: "To earn this badge, submit a water quality observation.",
    successMsg:
      "You submitted a water quality observation.  Thanks for contributing!",
    comment: "",
    event: "obs-create",
    passConditions: [RegionConditions.userHasWaterObservations],
    points: 100,
    icon: "fa-tint",
  },
  {
    code: "createObsMedia",
    title: "Keen Observer",
    description:
      "To earn this badge, attach a photo or video when submitting an observation.",
    successMsg: "You attached a photo or video to a new observation.  Awesome!",
    comment: "",
    event: "obs-create",
    passConditions: [RegionConditions.userHasObservationWithMedia],
    points: 200,
    icon: "fa-camera-retro",
  },
  // -------------- QUEST-SPECIFIC BADGES ------------------
  {
    code: "glaciersQuestComplete",
    title: "Ice Guardian",
    description: "Complete the Glaciers & Freshwater Quest to earn this badge",
    successMsg: "You completed the Glaciers & Freshwater Quest. Great work!",
    comment: "",
    event: "quest-complete",
    passConditions: [RegionConditions.glaciersQuestComplete],
    points: 100,
    icon: "fa-snowflake",
  },
  {
    code: "waterAndFishQuestComplete",
    title: "Fisheries Scientist",
    description:
      "Complete the Water Quality and Fish Habitat Quest to earn this badge",
    successMsg:
      "You completed the Water Quality and Fish Habitat Quest. Great work!",
    comment: "",
    event: "quest-complete",
    passConditions: [RegionConditions.waterAndFishQuestComplete],
    points: 100,
    icon: "fa-fish",
  },
  {
    code: "elbowWatershedQuestComplete",
    title: "Elbow River Researcher",
    description: "Complete the Elbow River Watershed Quest to earn this badge",
    successMsg: "You completed the Elbow River Watershed Quest. Great work!",
    comment: "",
    event: "quest-complete",
    passConditions: [RegionConditions.elbowWatershedQuestComplete],
    points: 100,
    icon: "fa-water",
  },
  {
    code: "waterUseQuestComplete",
    title: "Water Use Planner",
    description: "Complete the Water Use in Alberta Quest to earn this badge",
    successMsg: "You completed the Water Use in Alberta Quest. Great work!",
    comment: "",
    event: "quest-complete",
    passConditions: [RegionConditions.waterUseQuestComplete],
    points: 100,
    icon: "fa-tint",
  },
  {
    code: "forestryQuestComplete",
    title: "Forestry Planner",
    description: "Complete the Forestry Quest to earn this badge",
    successMsg: "You completed the Forestry Quest. Great work!",
    comment: "",
    event: "quest-complete",
    passConditions: [RegionConditions.forestryQuestComplete],
    points: 100,
    icon: "fa-tree",
  },
  {
    code: "oilSandsQuestComplete",
    title: "Oil Sands Explorer",
    description: "Complete the Oil Sands Quest to earn this badge",
    successMsg: "You completed the Oil Sands Quest. Great work!",
    comment: "",
    event: "quest-complete",
    passConditions: [RegionConditions.oilSandsQuestComplete],
    points: 100,
    icon: "fa-burn",
  },
  {
    code: "climateChangeQuestComplete",
    title: "Climate Scientist",
    description: "Complete the Climate Change Quest to earn this badge",
    successMsg: "You completed the Climate Change Quest. Great work!",
    comment: "",
    event: "quest-complete",
    passConditions: [RegionConditions.climateChangeQuestComplete],
    points: 300,
    icon: "fa-cloud-sun-rain",
  },
];

export default RegionBadges;
