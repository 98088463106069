<template>
  <md-card class="side-panel-card layout-padding-none">
    <md-toolbar
      class="layout-gray-dark"
      md-elevation="0"
    >
      <h3 class="md-title">
        <md-icon
          :class="getIconClass(landType)"
          :md-src="getIconSrc(landType)"
        />
        <span>{{ landType.name }}</span>
      </h3>
      <md-button
        class="md-icon-button"
        @click="TOGGLE_SIDEBAR_CONTENT"
      >
        <md-icon>close</md-icon>
      </md-button>
    </md-toolbar>
    <md-card-content>
      <div
        class="md-layout-item md-size-100 md-title"
      >
        <span class="text-white">
          {{ landType.name }} area (ha)
        </span>
        <chartist
          :data="landTypeChartData()"
          :options="landTypeChartOptions()"
          ratio=".ct-perfect-fourth"
          type="Line"
        />
      </div>
      <p class="text-white">
        {{ landType.description }}
      </p>
      <!-- media area -->
      <div v-if="hasMedia">
        <div v-if="landType.media.type==='image'">
          <img
            v-if="'url' in landType.media && landType.media.url != ''"
            :src="landType.media.url"
          >
        </div>
        <div v-else-if="landType.media.type==='video'">
          <video-player
            :options="getVideoOptions(landType)"
            :title="landType.name"
            class="md-layout-item md-size-100"
          />
        </div>
      </div>
      <technical-doc />
    </md-card-content>
  </md-card>
</template>

<script>
import { mapActions } from "vuex";
import { types } from "@/store/types";
import { LandTypes } from "./mixins/LandTypes";
import { VideoOptions } from "@/pages/Dashboard/Components/mixins/VideoOptions";
import VideoPlayer from "@/components/VideoPlayer";
import TechnicalDoc from './TechnicalDoc.vue';

export default {
  name: "LandTypeDetails",

  components: {
    VideoPlayer,
    TechnicalDoc
  },
  mixins: [LandTypes, VideoOptions], //some common lt stuff

  props: {
    landType: {
      type: Object,
      required: true
    },
    simulationState: {
      type: Object,
      required: true,
    }
  },

  data: function () {
    return {
    };
  },

  computed: {
    hasMedia() {
      // is there media attached to this land type
      if ("media" in this.landType && ['video', 'image'].includes(this.landType.media.type)) {
        return true;
      }
      return false;
    },
    minYear() {
      // the earliest year in the simulation
      return Math.min(...(Object.keys(this.simulationState.simAreas[this.landType.name]).map(yr => parseInt(yr))));
    },
    maxYear() {
      // the earliest year in the simulation
      return Math.max(...(Object.keys(this.simulationState.simAreas[this.landType.name]).map(yr => parseInt(yr))));
    }
  },

  mounted() {
    // analytics tracking
    this.$Helpers.gtagEvent(this, `view_landtype`, `landtypes`, this.landType.name);
  },

  methods: {
    ...mapActions([types.actions.TOGGLE_SIDEBAR_CONTENT]),

    landTypeChartData() {
      const yearIndex = this.simulationState.simYear - this.minYear;
      const labels = Object.keys(this.simulationState.simAreas[this.landType.name]).filter(val => (val <= this.maxYear && val <= this.simulationState.simYear));
      const series = Object.values(this.simulationState.simAreas[this.landType.name]).slice(0, yearIndex + 1);
      series.forEach((val, index) => series[index] = { meta: `${labels[index]}:`, value: `${parseFloat(val).toFixed(2)}` });
      return {
        labels: labels,
        series: [series]
      };
    },
    landTypeChartOptions() {
      const unitsAddOn = function (val) {
        return `${val} ha`;
      }
      const numYears = this.maxYear - this.minYear;
      const skipNum = (numYears <= 40) ? 10 : 30;
      const vm = this;
      return {
        showLabel: false,
        height: '200px',
        axisX: {
          // skip labels on x axis... only show a subset
          labelInterpolationFnc: function skipLabels(value, _index) {
            return _index % skipNum === 0 ? value : null;
          }
        },
        axisY: {
          labelInterpolationFnc: function (value) {
            // abbreviate labels
            return vm.$Helpers.abbreviateNumber(value);
          }
        },
        plugins: [
          this.$chartist.plugins.tooltip({
            anchorToPoint: false,
            appendToBody: false,
            class: '',
            transformTooltipTextFnc: unitsAddOn
          })
        ]
      };
    },
  }

};
</script>
<style lang="scss" scoped>
.side-panel-card {
  height: 100%;
}

.text-white {
  color: $white-color;
}

// md-elevation 0 is not working :(
.md-toolbar {
  box-shadow: none !important;
  .md-title {
    flex: 1;

    :first-child {
      margin-right: 1em;
    }

    .md-icon {
      color: white;
    }
  }
  .md-button {
    box-shadow: none !important;
    background: none !important;
  }
}

/deep/ .chartist-tooltip {
  position: absolute;
  display: none;
  min-width: 5em;
  padding: 8px 10px;
  margin-top: 48px;
  margin-left: 15px;
  background: #383838;
  color: #fff;
  text-align: center;
  pointer-events: none;
  z-index: 100;
  transition: opacity 0.2s linear;
}
/deep/ .chartist-tooltip:before {
  position: absolute;
  bottom: -14px;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: rgba(251, 249, 228, 0);
  border-top-color: #383838;
  border-width: 7px;
  margin-left: 0;
}
/deep/ .chartist-tooltip.tooltip-show {
  display: inline-block !important;
}
.ind-chart {
  height: 300px;
}
/deep/ .ct-grids line {
  stroke: $grey-500;
}
/deep/ .ct-labels span {
  color: $white-color;
}
/deep/ .ct-labels .ct-horizontal {
  margin: 0 0 0 -20px;
}
</style>