// climate/climate change setup

const RegionClimate = {
  GeneralInfo: {
    title: "Climate Change",
    description:
      "How will Alberta be affected by Climate Change?  What's the difference between Climate Change and Global Warming?",
    media: {
      type: "video",
      url: "https://www.youtube.com/watch?v=Okh7vn60kxg",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/climate_change_video.jpg",
      shareUrl: "https://www.youtube.com/watch?v=Okh7vn60kxg",
    },
    hashtags:
      "climatechange,landuse,landuseplanning,renewableenergy,beneficialmanagment,futurealberta",
  },
  ClimateIndicators: [
    {
      name: "Temperature",
      chartTitle: "Average Annual Temperature",
      chartUnits: "°C",
      icon: "thermometer-half.svg",
      baseRasterPrefix: "ab_temperature",
      minval: -8,
      maxval: 12,
      mapColors: {
        0: "#ffffff",
        1: "#1a00a2",
        32: "#2f87f1",
        64: "#54eefe",
        96: "#37c555",
        128: "#a0e54d",
        160: "#fdf65d",
        192: "#fa971d",
        224: "#e50f2f",
        255: "#810913",
      },
    },
    {
      name: "Precipitation",
      chartTitle: "Average Annual Precipitation",
      chartUnits: "mm",
      icon: "tint-solid.svg",
      baseRasterPrefix: "ab_precip",
      minval: 0,
      maxval: 3300,
      mapColors: {
        0: "#ffffff",
        1: "#810913",
        32: "#e50f2f",
        64: "#fa971d",
        96: "#fdf65d",
        128: "#a0e54d",
        160: "#37c555",
        192: "#54eefe",
        224: "#2f87f1",
        255: "#1a00a2",
      },
    },
  ],
  ClimateScenarios: [
    {
      name: "Ignore",
      subTitle: "",
      variable: "none",
      icon: "thermometer-quarter",
      description: `No climate change will occur in your scenario.  Why might someone choose to ignore climate change when modelling a land use scenario? By ignoring climate change, you remove a variable from the simulator, therefore allowing you to examine the impacts of land use decisions by themselves.`,
      indicatorBaseRasterKey: "ccnone",
      indicatorRepeatAfter: 2020,
      transitions: [],
    },
    {
      name: "Mild",
      subTitle: "RCP 2.6",
      variable: "rcp26",
      icon: "thermometer-half",
      description:
        "Alberta’s mild climate scenario is a combination of (1) the Canadian Earth System Model (CanESM2) for emission scenario RCP 2.6, which assumes strong emission mitigation, and (2) the Alberta Biodiversity Monitoring Institute (ABMI) Cool Model of future Natural Subregion classification, which represents a minimum amount of ecological change. Changes in climate affects transitions in natural landcover types as well as rates of natural disturbance (i.e., fire), which in turn can affect the habitat of many wildlife species.",
      indicatorBaseRasterKey: "cc26",
      indicatorRepeatAfter: 0,
      transitions: [
        {
          fromLT: "Forest",
          toLT: "Grassland",
          baseRaster: "ab_cc26_forest_grass",
        },
        {
          fromLT: "Wetland",
          toLT: "Forest",
          baseRaster: "ab_cc26_water_forest",
        },
        {
          fromLT: "Wetland",
          toLT: "Grassland",
          baseRaster: "ab_ccall_water_grass",
        },
      ],
    },
    {
      name: "Medium",
      subTitle: "RCP 4.5",
      variable: "rcp45",
      icon: "thermometer-three-quarters",
      description:
        "Alberta’s median climate scenario is a combination of (1) the Canadian Earth System Model (CanESM2) for emission scenario RCP 4.5, which assumes moderate emission mitigation, and (2) the Alberta Biodiversity Monitoring Institute (ABMI) Median Model of future Natural Subregion classification, which represents a moderate amount of ecological change. Changes in climate affects transitions in natural landcover types as well as rates of natural disturbance (i.e., fire), which in turn can affect the habitat of many wildlife species.",
      indicatorBaseRasterKey: "cc45",
      indicatorRepeatAfter: 0,
      transitions: [
        {
          fromLT: "Forest",
          toLT: "Grassland",
          baseRaster: "ab_cc45_forest_grass",
        },
        {
          fromLT: "Wetland",
          toLT: "Forest",
          baseRaster: "ab_cc45_water_forest",
        },
        {
          fromLT: "Wetland",
          toLT: "Grassland",
          baseRaster: "ab_ccall_water_grass",
        },
      ],
    },
    {
      name: "Hot",
      subTitle: "RCP 8.5",
      variable: "rcp85",
      icon: "thermometer-full",
      description:
        "Alberta’s hot climate scenario is a combination of (1) the Canadian Earth System Model (CanESM2) for emission scenario RCP 8.5, which assumes no emission mitigation, and (2) the Alberta Biodiversity Monitoring Institute (ABMI) Hot Model of future Natural Subregion classification, which represents a maximum amount of ecological change. Changes in climate affects transitions in natural landcover types as well as rates of natural disturbance (i.e., fire), which in turn can affect the habitat of many wildlife species.",
      indicatorBaseRasterKey: "cc85",
      indicatorRepeatAfter: 0,
      transitions: [
        {
          fromLT: "Forest",
          toLT: "Grassland",
          baseRaster: "ab_cc85_forest_grass",
        },
        {
          fromLT: "Wetland",
          toLT: "Forest",
          baseRaster: "ab_cc85_water_forest",
        },
        {
          fromLT: "Wetland",
          toLT: "Grassland",
          baseRaster: "ab_ccall_water_grass",
        },
      ],
    },
  ],
};

export default RegionClimate;
