<template>
  <modal v-if="isVisible">
    <template slot="header">
      <h4 class="modal-title">
        Scenario Computing
      </h4>
    </template>
    <template slot="body">
      <div class="md-layout md-alignment-center-center">
        <div class="md-layout-item md-size-100">
          <md-progress-bar
            class="md-primary"
            :md-mode="progressType"
            :md-value="percentLoaded"
          />
        </div>
      </div>
      <slide-y-up-transition>
        <div
          v-if="currDidYouKnow"
          class="md-layout md-alignment-center-center"
        >
          <h3 class="title">
            {{ currDidYouKnow.title }}
          </h3>
          <div
            v-if="hasImg"
            class="md-layout-item md-size-100 title-image"
          >
            <img
              :src="`/img/${currDidYouKnow.image}`"
              class=""
            >
          </div>
          <div
            v-else-if="hasIcon"
            class="md-layout-item md-size-100 title-icon"
          >
            <i :class="iconClass" />
          </div>
          <p class="center-text">
            {{ currDidYouKnow.message }}
          </p>
        </div>
      </slide-y-up-transition>
    </template>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import { types } from "@/store/types";
import { SlideYUpTransition } from "vue2-transitions";
import { Modal } from "@/components";

export default {
  name: "ScenarioComputingDialog",
  components: {
    SlideYUpTransition,
    Modal
  },
  data: function () {
    return {
      currDidYouKnow: null,
      timer: null,
      isVisible: false,
      elapsed: 0,
      isDeterminate: false, //after 5s, we set it to be determinate. wink wink
      percentLoaded: 0,
      lastLoadedIndex: -1, // the last didYouKnow item that was loaded so we don't load the same one 2x in a row
    };
  },
  computed: {
    ...mapGetters([
      types.getters.SCENARIO_COMPUTING_DIALOG_OPEN,
      types.getters.SCENARIO_COMPUTING_RUNTIME,
    ]),
    progressType() {
      return this.isDeterminate ? 'determinate' : 'indeterminate';
    },
    hasImg() {
      // if currDidYouKnow has an img to load
      return ('image' in this.currDidYouKnow);
    },
    hasIcon() {
      // if currDidYouKnow has an icon to load
      return ('icon' in this.currDidYouKnow);
    },
    iconClass() {
      return `${this.currDidYouKnow.icon} fa-3x md-info`
    }
  },
  watch: {
    SCENARIO_COMPUTING_DIALOG_OPEN(newVal) {
      if (newVal === true) {
        //simulation run just started
        this.isDeterminate = false;
        this.isVisible = true;
        this.currDidYouKnow = null;
        this.elapsed = 0;
        this.percentLoaded = 0;
        this.timer = setInterval(this.checkElapsed, 1000);
      }
      else {
        // simulation has been loaded. But we want to keep dialog open for
        // 10 more seconds if its taken longer than 5s to load
        // so that the didYouKnow can be read...
        const vm = this;
        const completed = function () {
          vm.isVisible = false;
          clearInterval(vm.timer);
          vm.currDidYouKnow = null;
        }

        //ensure that we've given enough time for didYouKnow to be read
        if (this.elapsed >= 5 && this.elapsed < 15) {
          const secondsLeft = 15 - this.elapsed;
          setTimeout(function () {
            completed();
          }, secondsLeft * 1000);
        }
        else {
          completed();
        }
      }

    },
  },
  methods: {
    checkElapsed() {
      //triggered every second
      this.elapsed += 1;

      //show new didYouKnow after 5 seconds and every 20s
      if (this.elapsed === 5 || (this.elapsed > 0 && this.elapsed % 20 == 0)) {
        //display random didYouKnow
        const items = this.$Region.didYouKnow;
        const vm = this;
        // prevent from reloading the same index 2x in a  row
        let nextIndex = this.lastLoadedIndex;
        while (nextIndex == this.lastLoadedIndex) {
          nextIndex = Math.floor(Math.random() * items.length);
        }
        this.lastLoadedIndex = nextIndex;
        // reset and then reload after brief timeout to allow animations to complete
        this.currDidYouKnow = null;
        setTimeout(function () { vm.currDidYouKnow = items[nextIndex] }, 300);

        // entering determinate mode, faked with respect to estimated time
        if (this.elapsed === 5) this.isDeterminate = true;
      }

      if (this.elapsed >= 5) {
        const percentage = (this.elapsed / this.SCENARIO_COMPUTING_RUNTIME) * 100;
        // don't let loader exceed 95%
        this.percentLoaded = Math.min(percentage, 95);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-weight: 600;
}
.title-image {
  text-align: center;
  margin: 20px;
}
.title-icon {
  text-align: center;
  color: $brand-info;
  margin: 5px 0 20px 0;
}
.center-text {
  text-align: center;
}
</style>