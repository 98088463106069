// general info videos
// info videos for climate, mgmt practices, mission will automatically be included in the first general list of videos on the videos page.
// additional categories may be added as well - climate, mgmt etc always go into the first category
// in addition to the above, any videos listed here will be included
// the title provided here is used for the videos page general videos section heading

const RegionVideos = [
  {
    title: "Tutorials",
    videos: [
      {
        title: "User Tutorial: Registering for an Account",
        description:
          "Watch this tutorial to see how to register for a Public, Teacher or Student Account.",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=5Qi8v-Y3iXM",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/tutorialRegisterAccount.jpg",
          shareUrl: "https://youtu.be/5Qi8v-Y3iXM",
        },
        hashtags: "",
      },
      {
        title: "User Tutorial: An overview of the AT simulator and features",
        description:
          "Watch this tutorial to see the different features of the Alberta Tomorrow Simulator.",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=nnm0V6jmpbM",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/tutorialOverview.jpg",
          shareUrl: "https://youtu.be/nnm0V6jmpbM",
        },
        hashtags: "",
      },
      {
        title: "User Tutorial: Your Mission",
        description:
          "An overview of the mission you will accomplish using Alberta Tomorrow",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=yNljbKNBI1M",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/tutorialMission.jpg",
          shareUrl: "https://youtu.be/yNljbKNBI1M",
        },
        hashtags: "",
      },
      {
        title:
          "User Tutorial: Running a Historical Simulation in Alberta Tomorrow",
        description:
          "Watch this video to find out how your study area has changed since 1910!",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=qMXZYBHCD_E",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/tutorialHistoric.jpg",
          shareUrl: "https://youtu.be/qMXZYBHCD_E",
        },
        hashtags: "",
      },
      {
        title:
          "User Tutorial: How to Create a Business as Usual (BAU) Scenario",
        description:
          "A BAU scenario gives you a glimpse of a possible future, one that carries current trends in landuse into the future.",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=gWm7yWBkPHo",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/tutorialCreateBAU.jpg",
          shareUrl: "https://youtu.be/gWm7yWBkPHo",
        },
        hashtags: "",
      },
      {
        title: "User Tutorial: How to Create your own Land Use Scenarios",
        description:
          "This video will show you how to: select your watershed study area; andset goals; andmake changes on the map; andset the level of industrial activity; andchoose your beneficial management practices; and run the scenario",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=RQ2Pz6gLIQ0",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/tutorialLandusePlan.jpg",
          shareUrl: "https://youtu.be/RQ2Pz6gLIQ0",
        },
        hashtags: "",
      },
      {
        title: "User Tutorial: How to Create an Observation in the Simulator",
        description:
          "This tutorial walks you through how to create your own observation in the simulator that you and all registered users can see.",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=fp_WVPCdCnU",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/tutorialCreateObs.jpg",
          shareUrl: "https://youtu.be/fp_WVPCdCnU",
        },
        hashtags: "",
      },
      {
        title:
          "User Tutorial: Investigating Climate Change Scenarios in Alberta Tomorrow",
        description:
          "See how you can view changes that may occur due to climate change with Mild, Medium or Hot Climate Change Scenarios.",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=yF4SY7VoqDs",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/tutorialClimate.jpg",
          shareUrl: "https://youtu.be/yF4SY7VoqDs",
        },
        hashtags: "",
      },
      {
        title:
          "User Tutorial: Creating and Sharing a Report in Alberta Tomorrow",
        description:
          "This short tutorial video will show you how to create and share a report in the simulator.",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=poxco59ASU8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/tutorialReports.jpg",
          shareUrl: "https://youtu.be/poxco59ASU8",
        },
        hashtags: "",
      },
    ],
  },
  {
    title: "Understanding the Landscape",
    includeOtherVideos: true,
    videos: [
      {
        title: "Ecological Goods and Services",
        description: "An introduction to ecological goods and services",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/d3af3f5027fc4578bb8a5e661fd15662/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/7f76a14f-20e9-430d-bef6-5c08247cad75/thumbnails/Ecological Goods v2_H264_tumb.0000033.jpg",
          shareUrl: "https://youtu.be/WBdpkY9KSgs",
        },
        hashtags: "EGS,landuse,landuseplanning",
      },
      {
        title: "Water Quality, Climate Change and Alberta's Glaciers Webinar",
        description:
          "This webinar was held on May 27, 2020 and features 4 prominent scientists talking about climate change and how it will affect glaciers, glacier melt, water policy and our water quality.",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=Xgb3_I8nagY",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/water-climate-change-glaciers-webinar.jpg",
          shareUrl: "https://www.youtube.com/watch?v=Xgb3_I8nagY",
        },
        hashtags:
          "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,landuse",
      },
    ],
  },
];

export default RegionVideos;
