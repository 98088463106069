/* jshint esversion: 6 */
// application state storage
import Vue from "vue";
import Vuex from "vuex";

// import modules
import gamification from "./modules/gamification";
import observation from "./modules/observation";
import report from "./modules/report";
import scenario from "./modules/scenario";
import teacher from "./modules/teacher";
import tours from "./modules/tours";
import ui from "./modules/ui";
import user from "./modules/user";

Vue.use(Vuex);

// setup initial state so we can reset the app on logouts etc
const clone = require("rfdc")();
const defaultState = {
  gamification: clone(gamification.state),
  observation: clone(observation.state),
  report: clone(report.state),
  scenario: clone(scenario.state),
  teacher: clone(teacher.state),
  tours: clone(tours.state),
  ui: clone(ui.state),
  user: clone(user.state)
};

export default new Vuex.Store({
  modules: {
    gamification, // gamification - quests, challenges, hints, badges etc elements
    observation, // observation specific data elements
    report, // report specific data elements
    scenario, // scenario specific data elements
    teacher, // teacher specific data elements
    tours, // tours specific data elements
    ui, // ui layout elements
    user // user profile elements
  },

  state: {},

  getters: {},

  mutations: {
    reset(state) {
      // resets ALL vuex store data to its initial state
      const resetObjectsRecursively = (state, defaultState) => {
        for (const [key, value] of Object.entries(defaultState)) {
          if (Object.prototype.toString.call(state[key]) === "[object Object]")
            resetObjectsRecursively(state[key], defaultState[key]);
          else if (
            Object.prototype.toString.call(state[key]) === "[object Array]"
          ) {
            state[key].length = 0;
            for (const elem of defaultState[key]) {
              state[key].push(elem);
            }
          } else {
            state[key] = value;
          }
        }
      };
      resetObjectsRecursively(state, defaultState);
    }
  },

  actions: {}
});
