<template>
  <!-- customized vue-tour template based on docs here: https://github.com/pulsardev/vue-tour/wiki/Customizing-the-Template -->
  <!-- when a tour is done, this component will automatically attempt to launch a subsequent tour if one is available -->
  <!-- all tours end with launching the HelpButton tour if it hasn't yet been seen -->
  <v-tour
    :name="tourName"
    :steps="steps"
    :callbacks="tourCallbacks"
  >
    <template slot-scope="tour">
      <FadeTransition
        group
        :duration="200"
        mode="out-in"
      >
        <div
          v-for="(step, index) of tour.steps"
          :key="index"
        >
          <v-step
            v-if="tour.currentStep === index"
            :step="step"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tourLabels"
            :highlight="tour.highlight"
          >
            <!-- Help Button Tour -->
            <template v-if="type === 'help'">
              <div slot="actions">
                <button
                  class="v-step__button v-step__button-next"
                  @click="quitHelpBtn(tour.stop)"
                >
                  Ok
                </button>
              </div>
            </template>
            <!-- Last Tour Step -->
            <template v-else-if="type === 'group'">
              <div slot="actions">
                <button
                  v-if="nextTourAvailable"
                  class="v-step__button v-step__button-previous"
                  @click="quitTour(tour.stop)"
                >
                  Skip
                </button>
                <button
                  v-if="prevTourAvailable > -1 || tour.currentStep > 0"
                  class="v-step__button v-step__button-previous"
                  @click="prevStep(tour.isFirst, tour.previousStep, tour.stop)"
                >
                  Previous
                </button>
                <button
                  class="v-step__button v-step__button-next"
                  @click="nextStep(tour.isLast, tour.nextStep, tour.stop)"
                >
                  {{ nextLabel(tour.isLast) }}
                </button>
              </div>
            </template>
          </v-step>
        </div>
      </FadeTransition>
    </template>
  </v-tour>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import { User } from "@/pages/Dashboard/Components/mixins/User";
import { Tours } from "@/pages/Dashboard/Components/mixins/Tours";
import { FadeTransition } from 'vue2-transitions'
export default {
  name: "IntroTour",

  components: {
    FadeTransition
  },
  mixins: [User, Tours], // user profile methods and vuex

  props: {
    name: {
      type: String,
      default: null
    },
    version: {
      type: String,
      default: "1.0"
    },
    steps: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      default: "group" // should be one of ['help', 'group', 'single']
      // help = the help button.  A single step tour highlighting the help button
      // group = part of the tutorial/help... tours are tracked in user history and available tours for any given context are chained together
      // single = a separate tour that is not connected to the tutorial/help system
    }
  },

  data() {
    return {
      tourCallbacks: {
        onStart: this.tourStart
      },
      tourLabels: {
        buttonSkip: 'Skip',
        buttonPrevious: 'Previous',
        buttonNext: 'Next',
        buttonStop: 'Ok'
      }
    }
  },

  computed: {
    ...mapGetters([
      types.getters.USER_DATA,
      types.getters.HELP_BTN_TOUR_COMPLETE,
      types.getters.AVAILABLE_TOURS,
      types.getters.PRIORITY_AVAILABLE_TOURS
    ]),
    tourName() {
      // get a name for the tour
      // return a combination of name and version
      // if name prop is passed, use that
      // else use the parent component's name
      const name = this.name !== null ? this.name : this.$parent.$options.name;
      return `${name}_v${this.version}`;
    },
    helpButtonTourName() {
      // get the name including current version of the HelpButton tour so we can always show it last
      // todo it's possible that this won't work when the menu is improved and setup for mobile... need to handle case where there is no key found
      return Object.keys(this.$tours).find(key => key.startsWith('HelpButton'));
    },
    prevTourAvailable() {
      // is another tour available that precedes this one in the list
      // returns the prev available tour index
      if (this.PRIORITY_AVAILABLE_TOURS.length > 1) {
        // get next available tour and launch it
        const index = this.PRIORITY_AVAILABLE_TOURS.findIndex(tour => tour === this.tourName);
        if (index >= 1) {
          return index - 1;
        }
      }
      return -1;
    },
    nextTourAvailable() {
      // is another tour available that precedes this one in the list
      // returns the next available tour index
      if (this.PRIORITY_AVAILABLE_TOURS.length > 1) {
        // get next available tour and launch it
        const index = this.PRIORITY_AVAILABLE_TOURS.findIndex(tour => tour === this.tourName);
        if (index + 1 < this.PRIORITY_AVAILABLE_TOURS.length) {
          return index + 1;
        }
      }
      return -1;
    }
  },

  mounted() {
    // update USER_DATA with seenIntro
    if (!this.USER_DATA.seenIntro) {
      this.USER_DATA_UPDATE({ seenIntro: true });
    }
    // add to the available tours if it's part of the group/help collection of tours
    if (this.type !== 'single') {
      this.AVAILABLE_TOURS_PUSH(this.tourName);
    }
  },

  beforeDestroy() {
    // remove from the available tours
    this.AVAILABLE_TOURS_REMOVE(this.tourName);
  },

  methods: {
    ...mapActions([
      types.actions.SET_TOUR_ACTIVE,
      types.actions.SET_HELP_BTN_TOUR_COMPLETE,
      types.actions.AVAILABLE_TOURS_PUSH,
      types.actions.AVAILABLE_TOURS_REMOVE
    ]),

    nextLabel(isLast) {
      // return a label for the Next button
      if (this.nextTourAvailable > -1 || !isLast) {
        return 'Next';
      }
      return 'Ok';
    },
    tourStart() {
      // this is sent as a callback and is fired whenever the vue-tour starts
      // check the status of the help button tour
      if (this.HELP_BTN_TOUR_COMPLETE === false && 'history' in this.USER_DATA && this.USER_DATA.history.includes(`t_${this.helpButtonTourName}`)) {
        this.SET_HELP_BTN_TOUR_COMPLETE(true);
      }
      else if (this.tourName === this.helpButtonTourName) {
        // if the tour is for the HelpButton, set the status
        this.SET_HELP_BTN_TOUR_COMPLETE(true);
      }
      // add tour to user's history
      this.addToUserHistory();
    },
    addToUserHistory() {
      // add a tour to the user's history
      // update profile -- add to user's completed challenges
      const historyItem = `t_${this.tourName}`;
      // call the API and update the user profile
      this.updateUserHistory(historyItem); // updates DB and vuex store
    },
    nextStep(isLast, tourNextFn, tourStopFn) {
      // next step on a group tour
      if (!isLast) {
        // mid-tour... just proceed
        tourNextFn();
      } else {
        // when the final step of a tour is closed, this function will launch the next tour if there is one
        // use the callback to stop the current tour
        tourStopFn();
        if (this.nextTourAvailable > -1) {
          // get next available tour and launch it
          this.$tours[this.PRIORITY_AVAILABLE_TOURS[this.nextTourAvailable]].start();
        } else if (!this.HELP_BTN_TOUR_COMPLETE) {
          // if none are available launch helpbutton if it hasn't been seen before
          this.$tours[this.helpButtonTourName].start();
        } else {
          // all tours done
          this.SET_TOUR_ACTIVE(false);
        }
      }
    },
    prevStep(isFirst, prevStepFn, tourStopFn) {
      // when user clicks on previous button in a tour
      if (isFirst && this.prevTourAvailable > -1) {
        // first step of tour but previous tour is available
        tourStopFn();
        const prevIndex = this.$tours[this.PRIORITY_AVAILABLE_TOURS[this.prevTourAvailable]].steps.length;
        this.$tours[this.PRIORITY_AVAILABLE_TOURS[this.prevTourAvailable]].start(prevIndex - 1);
      } else {
        // mid tour
        prevStepFn();
      }
    },
    quitTour(tourStopFn) {
      // quit a tour when skip button is pressed
      tourStopFn();
      // launch helpbutton if it hasn't been seen before
      if (!this.HELP_BTN_TOUR_COMPLETE) {
        this.$tours[this.helpButtonTourName].start();
      } else {
        // all tours done
        this.SET_TOUR_ACTIVE(false);
      }
    },
    quitHelpBtn(tourStopFn) {
      // quit the HelpButton last step tour
      tourStopFn();
      // all tours done
      this.SET_TOUR_ACTIVE(false);
    }
  }
};
</script>

<style lang="scss" scoped>
.points-counter {
  margin-left: 10px;
}
</style>
