<template>
  <div class="md-layout text-center">
    <div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
      <login-card header-color="green">
        <h4
          slot="title"
          class="title"
        >
          Reset Password
        </h4>

        <p
          v-if="!codeSent"
          slot="description"
          class="description"
        >
          Please enter your email to reset your password
        </p>
        <p
          v-else
          slot="description"
          class="description"
        >
          Please enter the verification code emailed to you
        </p>
        <md-field
          v-if="!codeSent"
          slot="inputs"
          class="md-form-group"
        >
          <md-icon>email</md-icon>
          <label>Email or Username...</label>
          <md-input
            v-model="email"
            type="email"
            @keyup.13="forgotPassword"
          />
        </md-field>

        <!--verification code-->
        <md-field
          v-else
          slot="inputs"
          :class="[
            {'md-valid': !errors.has('code') && touched.code},
            {'md-error': errors.has('code')}]"
        >
          <md-icon>lock</md-icon>
          <label>6-Digit Verification Code</label>
          <md-input
            ref="code"
            v-model="code"
            v-validate="modelValidations.code"
            data-vv-name="code"
            type="code"
            required
          />
          <slide-y-down-transition>
            <md-icon
              v-show="errors.has('code') && touched.code"
              class="error"
            >
              close
            </md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              v-show="!errors.has('code') && touched.code"
              class="success"
            >
              done
            </md-icon>
          </slide-y-down-transition>
        </md-field>

        <md-button
          v-if="!codeSent"
          slot="footer"
          class="md-simple md-success md-lg"
          @click="forgotPassword"
          @keyup.13="forgotPassword"
        >
          Send
        </md-button>

        <p
          v-else
          slot="inputs"
          class="description"
        >
          Please choose a new password
        </p>

        <!--password fields-->
        <md-field
          v-if="codeSent"
          slot="inputs"
          :class="[
            {'md-valid': !errors.has('newPassword') && touched.newPassword},
            {'md-error': errors.has('newPassword')}]"
        >
          <md-icon>lock</md-icon>
          <label>New Password</label>
          <md-input
            ref="password"
            v-model="newPassword"
            v-validate="modelValidations.newPassword"
            data-vv-name="newPassword"
            type="password"
            required
          />
          <slide-y-down-transition>
            <md-icon
              v-show="errors.has('newPassword') && touched.newPassword"
              class="error"
            >
              close
            </md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              v-show="!errors.has('newPassword') && touched.newPassword"
              class="success"
            >
              done
            </md-icon>
          </slide-y-down-transition>
        </md-field>
        <md-field
          v-if="codeSent"
          slot="inputs"
          :class="[
            {'md-valid': !errors.has('newPasswordVerify') && touched.newPasswordVerify},
            {'md-error': errors.has('newPasswordVerify')}]"
        >
          <md-icon>lock</md-icon>
          <label>Confirm New Password</label>
          <md-input
            v-model="newPasswordVerify"
            v-validate="modelValidations.newPasswordVerify"
            data-vv-name="newPasswordVerify"
            data-vv-as="password"
            type="password"
            required
          />
          <slide-y-down-transition>
            <md-icon
              v-show="errors.has('newPasswordVerify') && touched.newPasswordVerify"
              class="error"
            >
              close
            </md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              v-show="!errors.has('newPasswordVerify') && touched.newPasswordVerify"
              class="success"
            >
              done
            </md-icon>
          </slide-y-down-transition>
        </md-field>

        <p
          v-if="codeSent"
          slot="inputs"
          class="description"
        >
          Passwords must contain a minimum of 8 characters, one lowercase letter, one uppercase letter, and one number
        </p>
        <md-button
          v-if="codeSent"
          slot="footer"
          class="md-simple md-success md-lg"
          @click="submitNewPassword"
          @keyup.13="submitNewPassword"
        >
          Update
        </md-button>
      </login-card>
    </div>
  </div>
</template>
<script>
import { LoginCard } from '@/components'
import swal from 'sweetalert2'
import { SlideYDownTransition } from 'vue2-transitions'

export default {
  components: {
    LoginCard,
    SlideYDownTransition
  },
  data() {
    return {
      email: null,
      codeSent: false,
      code: '',
      newPassword: '',
      newPasswordVerify: '',
      modelValidations: {
        code: {
          required: true,
          regex: "^[0-9]{6,6}"
        },
        newPassword: {
          required: true,
          regex: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
        },
        newPasswordVerify: {
          required: true,
          confirmed: 'password'
        }
      },
      touched: {
        code: false,
        newPassword: false,
        newPasswordVerify: false
      }
    }
  },
  watch: {
    code() {
      this.touched.code = true
    },
    newPassword() {
      this.touched.newPassword = true
    },
    newPasswordVerify() {
      this.touched.newPasswordVerify = true
    }
  },
  methods: {
    errorAlert(message) {
      swal.fire({
        title: `<p style="color:red;">Sorry</p>`,
        text: message,
        timer: 2000,
        showConfirmButton: false
      })
    },

    forgotPassword() {
      if (this.email == null) {
        this.errorAlert('Please provide a valid email address or username')
      }

      const username = this.email.toLowerCase();

      this.$Auth.forgotPassword(username)
        .then(data => {
          this.codeSent = true;
          // eslint-disable-next-line no-console
          console.log(data)
        })
        .catch(err => this.errorAlert(err.message))
    },

    submitNewPassword() {
      const username = this.email.toLowerCase();
      const code = this.code;
      const new_password = this.newPassword;

      if (!RegExp("^[0-9]{6,6}").test(code) || !RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})").test(new_password)) {
        this.errorAlert('Please provide a valid verification code and password')
      }
      else {
        this.$Auth.forgotPasswordSubmit(username, code, new_password)
          .then(data => {
            swal.fire({
              title: 'Success!',
              text: 'Your password has been reset',
              customClass: {
                confirmButton: "md-button md-success",
              },
              buttonsStyling: false,
              icon: 'success'
            });
            this.$router.push('/Login');
            // eslint-disable-next-line no-console
            console.log(data)
          })
          .catch(err => this.errorAlert(err.message))
      }
    }
  }
}
</script>

<style>
</style>
