<template>
  <div style="display:inline;">
    <slot>
      <md-button
        v-if="showButton"
        class="md-info"
        @click="toggleShowDialog"
      >
        <md-icon>share</md-icon>Share
      </md-button>
    </slot>
    <md-dialog
      :md-active.sync="showDialog"
      style="z-index:9999"
    >
      <md-dialog-title>Share</md-dialog-title>
      <div
        v-if="showDialog"
        class="md-layout share-dialog"
      >
        <div
          v-if="status"
          class="md-layout-item md-size-100 md-elevation-1 sharing-status sharing-on"
        >
          <p>
            Any users with the link can view this {{ shareTypeFullName }}.
          </p>
        </div>
        <div
          v-else
          class="md-layout-item md-size-100 md-elevation-1 sharing-status"
        >
          <p>
            Sharing is turned OFF for your {{ shareTypeFullName }}.
            <br>
            Turn link sharing ON?
          </p>
        </div>
        <div class="md-layout-item md-size-100">
          <md-switch
            v-if="!sharingLocked"
            v-model="status"
            class="share-switch"
            :disabled="sharingLocked"
          >
            Link Sharing
          </md-switch>
        </div>
        <div
          v-if="status"
          class="md-layout-item md-size-100"
          :class="{'padding-top': sharingLocked}"
        >
          <div class="md-layout">
            <div class="md-layout-item md-size-80 md-small-size-70">
              <md-field>
                <md-input
                  v-model="shareUrl"
                  readonly
                />
              </md-field>
            </div>
            <div class="md-layout-item md-size-20 md-small-size-30">
              <md-button
                class="md-success"
                @click="clipWrite"
              >
                <md-icon>content_copy</md-icon>
              </md-button>
            </div>
          </div>
        </div>
        <collapse-transition>
          <div
            v-if="status"
            class="padding-top text-center"
          >
            <ShareNetwork
              v-for="network in networks"
              :key="network.network"
              :network="network.network"
              :url="shareUrl"
              :title="shareTitle"
              :description="shareDesc"
              :hashtags="hashtags"
              :twitter-user="twitterUser"
              @open="openShare"
              @close="closeShare"
            >
              <md-button
                class="share-button"
                :style="getBtnStyle(network.color)"
              >
                <i :class="network.icon" />
                {{ network.name }}
              </md-button>
            </ShareNetwork>
          </div>
        </collapse-transition>
      </div>
      <md-dialog-actions>
        <md-button @click="toggleShowDialog">
          Close
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import { write as clipboardyWrite } from 'clipboardy';
import { v4 as uuidv4 } from "uuid";
import { ShareNetwork } from 'vue-social-sharing';
import { CollapseTransition } from 'vue2-transitions';
import { Gamification } from "@/pages/Dashboard/Components/mixins/Gamification";
export default {
  name: "ShareDialog",

  components: {
    ShareNetwork,
    CollapseTransition
  },
  mixins: [Gamification],

  props: {
    objType: {
      type: String,
      required: true
    },
    obj: {
      type: Object,
      required: true
    },
    showButton: {
      type: Boolean,
      default: true
    },
    gtagAction: {
      type: String,
      default: 'social_share'
    },
    gtagCategory: {
      type: String,
      default: 'general'
    },
    shareEvent: {
      type: String,
      default: 'none'
    }
  },

  data() {
    return {
      showDialog: false,
      networks: this.$Region.socialSettings.networks,
      status: null
    }
  },

  computed: {
    sharingLocked() {
      // user can not turn sharing off
      return ['obs', 'vid', 'vt'].includes(this.objType);
    },
    shareTypeFullName() {
      // get the proper name based on a objType
      const typeNames = {
        scen: "Scenario",
        rep: "Report",
        obs: "Observation",
        vid: "Video",
        vt: "Virtual Tour"
      }
      return typeNames[this.objType];
    },
    sharingIsOn() {
      // whether sharing is turned on or not for this obj
      return this.sharingLocked || "shareKey" in this.obj && this.obj.shareKey !== 'NONE';
    },
    shareKey() {
      if (this.objType === 'obs') {
        if ('UserTransaction' in this.obj) {
          // from UserObservationCard
          return this.obj.UserTransaction;
        } else if ('properties' in this.obj) {
          // from ObservationDetails
          // user transaction OR ID for system observation
          return 'UserTransaction' in this.obj.properties ? this.obj.properties.UserTransaction : `${this.obj.source}_${this.obj.properties.id}`;
        }
      } else if (this.objType === 'vt') {
        return this.obj.code;
      }
      if (!this.status) {
        return "NONE";
      }
      return "privateKey" in this.obj ? this.obj.privateKey : uuidv4();
    },
    shareUrl() {
      const _origin = window.location.origin;
      if (this.objType === 'vid') {
        return ('shareUrl' in this.obj.media && this.obj.media.shareUrl !== null && this.obj.media.shareUrl !== '' && this.obj.media.shareUrl !== undefined) ? this.obj.media.shareUrl : _origin;
      } else if (this.objType === 'rep') {
        return `${_origin}/#/dashboard/reports?${this.objType}=${this.shareKey}`;
      }
      return `${_origin}/#/dashboard/explore?${this.objType}=${this.shareKey}`;
    },
    studyAreaName() {
      if ('regionPath' in this.obj) {
        const name = this.obj.regionPath.replace(".geojson", "").split("__");
        return name[name.length - 1];
      }
      return null;
    },
    obsTitle() {
      // get an observation title to share
      let title = null;
      if (this.objType === 'obs') {
        if ('UserName' in this.obj && this.obj.UserName === this.$Auth.user.username) {
          title = `I recorded a ${this.$Region.observation_types[this.obj.type].title} observation: ${this.obj.title}`
        } else if ('properties' in this.obj && 'title' in this.obj.properties) {
          // system obs title
          title = `I explored the landscape and found information about ${this.obj.properties.title}`;
        } else if ('type' in this.obj) {
          // user obs title
          title = `I explored a ${this.$Region.observation_types[this.obj.type].title} observation: ${this.obj.title}`;
        }
      }
      return title;
    },
    shareTitle() {
      const titleRegex = /%TITLE%/g;
      const typeTitles = {
        scen: `in the ${this.studyAreaName} ${this.$Region.region_type.toLowerCase()}: ${this.obj.name}`,
        rep: this.obj.title,
        obs: this.obsTitle,
        vid: ('title' in this.obj) ? this.obj.title : this.obj.name,
        vt: ('title' in this.obj) ? this.obj.title : 'Virtual Tour'
      }
      return this.$Region.socialSettings.shareProperties[this.shareTypeFullName.toLowerCase().replace(/\s+/g, '')].title.replace(titleRegex, typeTitles[this.objType]);
    },
    shareDesc() {
      const descRegex = /%DESC%/g;
      const desc = this.obj.description;
      return this.$Region.socialSettings.shareProperties[this.shareTypeFullName.toLowerCase().replace(/\s+/g, '')].description.replace(descRegex, desc);
    },
    obsHashtags() {
      let tags = this.$Region.socialSettings.shareProperties.observation.hashtags;
      if ('properties' in this.obj && 'type' in this.obj.properties && this.obj.properties.type === 'general' && 'generalHashtags' in this.$Region.socialSettings.shareProperties.observation) {
        tags = `${tags},${this.$Region.socialSettings.shareProperties.observation.generalHashtags}`;
      } else if ('properties' in this.obj && 'type' in this.obj.properties && this.obj.properties.type === 'water' && 'waterHashtags' in this.$Region.socialSettings.shareProperties.observation) {
        tags = `${tags},${this.$Region.socialSettings.shareProperties.observation.waterHashtags}`
      }
      return tags;
    },
    vidHashtags() {
      let tags = this.$Region.socialSettings.shareProperties.video.hashtags;
      if ('hashtags' in this.obj) {
        tags = `${tags},${this.obj.hashtags}`;
      }
      return tags;
    },
    hashtags() {
      const typeHashtags = {
        scen: this.$Region.socialSettings.shareProperties[this.shareTypeFullName.toLowerCase().replace(/\s+/g, '')].hashtags,
        rep: this.$Region.socialSettings.shareProperties[this.shareTypeFullName.toLowerCase().replace(/\s+/g, '')].hashtags,
        obs: this.obsHashtags,
        vid: this.vidHashtags,
        vt: this.$Region.socialSettings.shareProperties[this.shareTypeFullName.toLowerCase().replace(/\s+/g, '')].hashtags,
      }
      return typeHashtags[this.objType];
    },
    twitterUser() {
      // get the twitterUser or else ''
      return ('twitterUser' in this.$Region.socialSettings && this.$Region.socialSettings.twitterUser !== '' && this.$Region.socialSettings.twitterUser !== null && this.$Region.socialSettings.twitterUser !== undefined) ? this.$Region.socialSettings.twitterUser : '';
    }
  },

  watch: {
    status(newValue, oldValue) {
      if (oldValue !== null) {
        this.$emit('on-status-change', this.objType, this.obj, newValue, this.shareKey);
      }
    }
  },

  created() {
    this.status = this.sharingIsOn;
  },

  methods: {
    toggleShowDialog() {
      this.showDialog = !this.showDialog;
      this.$emit('on-show-dialog');
    },
    openShare() {
      // sharing network was opened
      // notify of share event - this is clearly not bulletproof... it's close enough for basic gamification purposes.
      // The assumption is that if a user opens a share network, that they also actually follow through with submitting the shared item
      if (this.shareEvent !== 'none') {
        this.checkQuestActivities(this.shareEvent);
      }
    },
    closeShare(network, url) {
      // sharing network was closed
      // NOTE this does not mean item was successfully shared... it means the dialog was closed. There is no real way to know if the item was actually shared on a network
      // analytics tracking
      this.$Helpers.gtagEvent(this, this.gtagAction, this.gtagCategory, `${network}, ${url}`);
    },
    getBtnStyle(color) {
      return {
        '--bg-color': color,
      };
    },
    clipWrite() {
      // copy to clipboard
      clipboardyWrite(this.shareUrl);
    },
  }
};
</script>

<style lang="scss" scoped>
.share-dialog {
  padding: 0 20px 20px 20px;
}
.share-button {
  color: $white-color;
  background-color: var(--bg-color) !important;
  margin-right: 10px !important;
}
/deep/ .share-switch label {
  color: $gray;
}
.sharing-status {
  margin: 0;
  border-radius: 5px;
  text-align: center;
  background-color: $gray-lighter;
  border: 1px solid $gray-light;
}
.sharing-on {
  background-color: lighten($brand-success, 2%);
  border-color: $brand-success;
  color: $white-color;
}
.padding-top {
  padding-top: 20px;
}
.text-center {
  text-align: center;
}
</style>
