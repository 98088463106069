/* jshint ignore: start */
// mixin for showing dashboard scenarios
import { mapGetters } from "vuex";
import { types } from "@/store/types";
export const DashboardScenariosMixin = {
  data() {
    return {};
  },

  computed: {
    ...mapGetters([types.getters.USER_SCENARIOS]),
    scenarios() {
      return this.USER_SCENARIOS;
    }
  },

  methods: {
    editScenario(id) {
      this.$router.push({
        name: "New Scenario",
        params: { id: id, targetTab: "Scenario" }
      });
    },
    exploreScenario() {
      this.$router.push({ name: "Explore" });
    },
    getScenarioByID(id) {
      let returnScen;
      this.USER_SCENARIOS.forEach(scen => {
        if (scen.id === id) {
          returnScen = scen;
        }
      });
      if (!returnScen) {
        throw "Could not find scenario " + id.toString();
      }
      return returnScen;
    }
  }
};
