<template>
  <!-- a badge is awarded -->
  <div class="md-layout">
    <div class="md-layout-item md-size-100 md-layout md-alignment-center-center">
      <badge-icon
        :badge="badge"
        :achieved="true"
        :xlarge="true"
        class="badge-icon"
      />
      <span class="md-layout-item md-size-100 badge-title">
        <h3 class="md-layout-item md-size-100">
          {{ badge.title }}
        </h3>
      </span>
      <p class="md-layout-item md-size-100 badge-msg">
        {{ badge.successMsg }}
      </p>

      <div class="md-layout-item md-size-100 md-layout md-alignment-center-center">
        <span class="badge badge-primary points-counter">
          <points-counter />
        </span>
      </div>
      <div class="md-layout-item md-size-100 md-layout md-alignment-center-center close-section">
        <md-button
          class="md-raised md-success"
          @click="closeBadge"
        >
          Ok
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as confetti from 'canvas-confetti';
import { Gamification } from "@/pages/Dashboard/Components/mixins/Gamification";
import BadgeIcon from "@/pages/Dashboard/Components/BadgeIcon";
import PointsCounter from "@/pages/Dashboard/Components/PointsCounter";

export default {
  name: "Badge",

  components: {
    BadgeIcon,
    PointsCounter
  },
  mixins: [Gamification],

  props: {
    badge: {
      type: Object,
      required: true
    }
  },

  data() {
    return {}
  },

  computed: {
  },

  mounted() {
    setTimeout(this.doFire, 400);
  },

  methods: {
    fire(particleRatio, opts) {
      // fire some confetti
      const count = 200;
      const defaults = {
        origin: { y: 0.6 }
      }
      confetti.create(null, { resize: true })(Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio)
      }));
    },
    doFire() {
      // do actual confetti fire with realistic look based on example at: https://www.kirilv.com/canvas-confetti/
      this.fire(0.25, {
        spread: 26,
        startVelocity: 55,
      });
      this.fire(0.2, {
        spread: 60,
      });
      this.fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8
      });
      this.fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2
      });
      this.fire(0.1, {
        spread: 120,
        startVelocity: 45,
      });
    },
    closeBadge() {
      // close the dialog
      this.$emit('closeDialog');
    }

  }
};
</script>

<style lang="scss" scoped>
.badge-icon {
  margin-top: 120px;
}
.badge-title h3 {
  text-align: center;
  font-weight: 600;
  margin-top: 10px;
}
.badge-msg {
  text-align: center;
}
.close-section {
  margin-top: 30px;
  margin-bottom: 30px;
}
.points-counter {
  margin-top: 20px;
}
</style>
