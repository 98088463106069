<template>
  <div>
    <md-menu
      md-size="medium"
      :md-offset-x="15"
    >
      <!-- SCENARIO ACTIONS BUTTON -->
      <md-button
        md-menu-trigger
        class="md-icon-button md-fab"
      >
        <md-icon>more_vert</md-icon>
      </md-button>
      <!-- END SCENARIO ACTIONS BUTTON -->
      <md-menu-content v-if="ACTIVE_SCENARIO_SHARED">
        <md-menu-item @click="closeScenario()">
          <md-icon>close</md-icon>
          <span class="scenarioActionItem">Close Scenario</span>
        </md-menu-item>
      </md-menu-content>
      <md-menu-content v-else-if="EXPLORE_MODE === 'create'">
        <md-menu-item @click="closeScenario()">
          <md-icon>close</md-icon>
          <span class="scenarioActionItem">Reset Scenario</span>
        </md-menu-item>
      </md-menu-content>
      <md-menu-content v-else>
        <md-menu-item @click="editScenario()">
          <md-icon>edit</md-icon>
          <span class="scenarioActionItem">Edit</span>
        </md-menu-item>
        <md-menu-item @click="addToReport()">
          <md-icon>insert_chart_outlined</md-icon>
          <span class="scenarioActionItem">Add to Report</span>
        </md-menu-item>
        <md-menu-item
          v-if="$Region.socialSharingOn"
          @click="toggleShareDialog"
        >
          <md-icon>share</md-icon>
          <span class="scenarioActionItem">Share</span>
        </md-menu-item>
        <md-menu-item @click="closeScenario()">
          <md-icon>close</md-icon>
          <span class="scenarioActionItem">Close Scenario</span>
        </md-menu-item>
      </md-menu-content>
      <!-- END SCENARIO ACTIONS MENU -->
    </md-menu>
    <share-dialog
      v-if="$Region.socialSharingOn && !ACTIVE_SCENARIO_SHARED"
      ref="shareDialog"
      obj-type="scen"
      :obj="ACTIVE_SCENARIO"
      :show-button="false"
      gtag-action="share_scenario_close"
      gtag-category="scenarios"
      @on-status-change="updateShareObj"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import ShareDialog from "@/pages/Dashboard/Components/ShareDialog";
import { Sharing } from "@/pages/Dashboard/Components/mixins/Sharing";
export default {
  name: "ScenarioActionsBtn",

  components: {
    ShareDialog
  },
  mixins: [Sharing],

  props: {},

  data() {
    return {
    }
  },

  computed: {
    ...mapGetters([
      types.getters.EXPLORE_MODE,
      types.getters.REPORT_MODE,
      types.getters.ACTIVE_SCENARIO,
      types.getters.ACTIVE_SCENARIO_SHARED,
      types.getters.ACTIVE_REPORT_SCENARIO_COUNT
    ]),
    studyAreaName() {
      if (this.EXPLORE_MODE === 'scenario') {
        const name = this.ACTIVE_SCENARIO.studyArea.replace(".geojson", "").split("__");
        return name[name.length - 1];
      }
      return `...`;
    },
  },

  watch: {
  },

  methods: {
    ...mapActions([
      types.actions.CLOSE_SCENARIO,
      types.actions.CLEAR_SCENARIO_AND_REGIONS,
      types.actions.SET_EXPLORE_MODE,
      types.actions.CLOSE_SCENARIO_DIALOG,
      types.actions.TOGGLE_EDIT_SCENARIO_DIALOG,
      types.actions.SET_SCENARIO_EDIT_MODE,
      types.actions.ACTIVE_REPORT_ADD_SCENARIO,
      types.actions.ACTIVE_REPORT_CLEAR
    ]),

    closeScenario() { // closeScenario
      if (this.EXPLORE_MODE === 'create') {
        // close scenario in create mode
      this.CLEAR_SCENARIO_AND_REGIONS();
      this.SET_EXPLORE_MODE('explore');
      } else {
        // close scenario in view/edit mode
        this.CLOSE_SCENARIO();
      }
      // clear any potential sharing query in the url
      this.$router.replace({ query: null });
    },
    editScenario() {
      this.SET_SCENARIO_EDIT_MODE("edit");
      this.CLOSE_SCENARIO_DIALOG();
      this.TOGGLE_EDIT_SCENARIO_DIALOG();
    },
    addToReport() {
      // add scenario to report builder
      if (this.REPORT_MODE === 'create' && this.ACTIVE_REPORT_SCENARIO_COUNT >= 4) {
        this.$notify({
          message: `Sorry, the report builder already has the maximum 4 scenarios in it.  Either save that report and start a new one, or remove a scenario from your current report.`,
          icon: "error_outline",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "warning"
        });
        return;
      } else if (this.REPORT_MODE === 'view') {
        // report in view mode... switch it up
        this.ACTIVE_REPORT_CLEAR();
      }
      this.ACTIVE_REPORT_ADD_SCENARIO(this.ACTIVE_SCENARIO);
      this.$router.push({
        name: "Reports"
      });
    },
    toggleShareDialog() {
      this.$refs.shareDialog.toggleShowDialog();
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .scenarioActionItem {
  text-align: right !important;
}
</style>
