<template>
  <div class="wizard-container">
    <form @submit.prevent>
      <!--        You can switch " data-color="primary" "  with one of the next bright colors: "green", "orange", "red", "blue"       -->
      <md-card
        class="md-card-wizard active"
        data-color="green"
      >
        <md-card-header v-if="showHeader">
          <slot name="header">
            <h3 class="card-title">
              {{ title }}
            </h3>
            <h3 class="description">
              {{ subTitle }}
            </h3>
          </slot>
        </md-card-header>
        <div
          v-if="showNavigation"
          class="wizard-navigation"
        >
          <ul
            class="nav nav-pills"
            role="tablist"
          >
            <li
              v-for="(tab, index) in tabs"
              :id="`step-${tab.tabId}`"
              :key="tab.title"
              :ref="`tab-${index}`"
              role="tab"
              :tabindex="tab.checked ? 0 : ''"
              :aria-controls="tab.tabId"
              :aria-disabled="tab.active"
              :aria-selected="tab.active"
              class="nav-item wizard-tab-link"
              :style="linkWidth"
            >
              <a
                class="nav-link"
                :class="[{'disabled-wizard-link': !tab.checked}, {active: tab.active}, {checked: tab.checked}]"
                data-toggle="tab"
                @click="navigateToTab(index)"
              >
                <tab-item-content :tab="tab" />
              </a>
            </li>
          </ul>
          <div
            v-if="activeTab"
            class="moving-tab"
            :class="{'error-link': activeTab.hasError}"
            style="transition: transform 0.5s cubic-bezier(0.29, 1.42, 0.79, 1); width: 100%;"
            :style="movingTabStyles"
          >
            <tab-item-content
              :tab="activeTab"
              :moving-tab="true"
            />
          </div>
        </div>

        <md-card-content>
          <div
            v-show="showTabContent"
            class="tab-content"
          >
            <slot
              :activeIndex="activeTabIndex"
              :activeTab="activeTab"
            />
          </div>
        </md-card-content>

        <md-card-actions
          v-show="showActions"
          md-alignment="space-between"
        >
          <slot
            name="footer"
            :next-tab="nextTab"
            :prev-tab="prevTab"
          >
            <div>
              <md-button
                v-if="showCancel"
                class="btn-previous"
                :disabled="wizardLocked"
                @click.native="onCancel()"
              >
                {{ cancelButtonText }}
              </md-button>
            </div>
            <div>
              <md-button
                v-if="showPrevButton && activeTabIndex > 0"
                class="btn-previous"
                :disabled="wizardLocked || animateNextButtonIcon"
                @click.native="prevTab"
              >
                {{ prevButtonText }}
              </md-button>
            </div>

            <div>
              <md-button
                v-if="activeTabIndex < tabCount -1"
                class="btn-next md-success"
                :disabled="wizardLocked || animateNextButtonIcon"
                @click.native="nextTab"
              >
                <md-icon
                  v-if="animateNextButtonIcon"
                  class="updating-icon"
                >
                  swap_horiz
                </md-icon>
                {{ activeNextText }}
              </md-button>
              <md-button
                v-else
                class="md-success"
                :disabled="wizardLocked"
                @click.native="nextTab"
              >
                <md-icon
                  v-if="wizardUpdating"
                  class="updating-icon"
                >
                  swap_horiz
                </md-icon>
                {{ finishButtonText }}
              </md-button>
            </div>
          </slot>
        </md-card-actions>
      </md-card>
    </form>
  </div>
</template>
<script>
import { throttle } from './throttle';

export default {
  name: 'SimpleWizard',
  components: {
    TabItemContent: {
      props: ['tab', 'movingTab'],
      render(h) {
        return h('span', [this.tab.$slots.label || this.tab.label])
      }
    }
  },
  props: {
    startIndex: {
      type: Number,
      default: 0
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showNavigation: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: 'Title'
    },
    subTitle: {
      type: String,
      default: 'Subtitle'
    },
    showTabContent: {
      type: Boolean,
      default: true
    },
    showActions: {
      type: Boolean,
      default: true
    },
    showCancel: {
      type: Boolean,
      default: false
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel'
    },
    onCancel: {
      type: Function,
      default: () => { }
    },
    prevButtonText: {
      type: String,
      default: 'Previous'
    },
    showPrevButton: {
      type: Boolean,
      default: true,
    },
    nextButtonText: {
      type: String,
      default: 'Next'
    },
    nextButtonUpdatingText: {
      type: String,
      default: 'Next'
    },
    finishButtonText: {
      type: String,
      default: 'Finish'
    },
    vertical: {
      type: Boolean
    },
    wizardLocked: {
      type: Boolean,
      default: false
    },
    wizardUpdating: {
      type: Boolean,
      default: false
    },
    preventPrevNav: {
      type: Boolean,
      default: false
    },
  },
  provide() {
    return {
      addTab: this.addTab,
      removeTab: this.removeTab
    }
  },
  data() {
    return {
      tabs: [],
      activeTabIndex: 0,
      tabLinkWidth: 0,
      tabLinkHeight: 50,
      activeNextText: ''
    }
  },
  computed: {
    animateNextButtonIcon() {
      return (this.wizardUpdating || this.nextButtonText !== this.activeNextText);
    },
    tabCount() {
      return this.tabs.length;
    },
    linkWidth() {
      let width = 100;
      if (this.tabCount > 0) {
        width = 100 / this.tabCount
      }
      if (this.vertical) {
        width = 100;
      }
      return { width: `${width}%` };
    },
    activeTab() {
      return this.tabs[this.activeTabIndex]
    },
    movingTabStyles() {
      /** @type {number} */
      const widthByIndex = this.tabLinkWidth * this.activeTabIndex
      let translateXValue = widthByIndex

      if (this.activeTabIndex === 0) {
        translateXValue = widthByIndex - 8
      } else if (this.activeTabIndex === (this.tabCount - 1)) {
        translateXValue = widthByIndex + 8
      }

      let translateYValue = 0;
      if (this.vertical) {
        translateYValue = this.tabLinkHeight * this.activeTabIndex
        translateXValue = 0;
      }
      const styles = {
        transform: `translate3d(${translateXValue}px, ${translateYValue}px, 0px)`
      };
      if (this.tabLinkWidth !== 0) {
        styles.width = `${this.tabLinkWidth}px`;
      }
      return styles;
    }
  },
  watch: {
    activeTabIndex(newValue, oldValue) {
      if (newValue !== oldValue) {
        const oldTab = this.tabs[oldValue];
        const newTab = this.tabs[newValue];
        oldTab.active = false;
        newTab.active = true;

        if (!newTab.checked) {
          newTab.checked = true
        }
        this.$emit('tab-change', oldTab, newTab);
        this.$emit('update:startIndex', newValue);
      }
    }
  },
  beforeMount() {
    this.activeNextText = this.nextButtonText;
  },
  mounted() {
    this.activeTabIndex = this.startIndex;
    this.$nextTick(() => {
      this.tabs[this.activeTabIndex].active = true;
      this.tabs[this.activeTabIndex].checked = true;
      this.onResize();
    });
    window.addEventListener('resize', () => {
      throttle(this.onResize, 40)
    }, false);
  },
  methods: {
    addTab(tab) {
      const index = this.$slots.default.indexOf(tab.$vnode);
      const tabTitle = tab.title || '';
      tab.tabId = `${tabTitle.replace(/ /g, '')}${index}`
      if (!this.activeTab && index === 0) {
        tab.active = true;
        tab.checked = true
      }
      if (this.activeTab === tab.name) {
        tab.active = true;
        tab.checked = true;
      }
      this.tabs.splice(index, 0, tab)
    },
    removeTab(tab) {
      const tabs = this.tabs;
      const index = tabs.indexOf(tab);
      if (index > -1) {
        tabs.splice(index, 1)
      }
    },
    validate(tab) {
      const tabToValidate = tab || this.activeTab
      const beforeChange = tabToValidate.beforeChange
      if (beforeChange) {
        this.activeNextText = this.nextButtonUpdatingText;
        return Promise.resolve(beforeChange()).then(res => {
          this.activeTab.hasError = res ? false : true
          this.activeNextText = this.nextButtonText;
          return res;
        }).catch(() => {
          this.activeNextText = this.nextButtonText;
          this.activeTab.hasError = true
        })
      }
      return Promise.resolve(true);

    },
    async nextTab() {
      const isValid = await this.validate();
      if (isValid && this.activeTabIndex < this.tabCount - 1) {
        this.activeTabIndex++
      }
      this.$emit('on-tab-update', this.activeTab.id);
      return isValid
    },
    prevTab() {
      if (this.preventPrevNav) {
        return;
      }
      this.activeTabIndex--;
      this.$emit('on-tab-update', this.activeTab.id);
    },
    async navigateToTab(index) {
      if (this.preventPrevNav && index < this.activeTabIndex) {
        return;
      }
      if (this.tabs[index].checked) {
        // recursively validate each tab
        if (index > this.activeTabIndex) {
          const valid = await this.nextTab();
          if (valid) {
            this.navigateToTab(index)
          }
        } else {
          this.activeTabIndex = index
        }
        this.$emit('on-tab-update', this.activeTab.id);
      }
    },
    onResize() {
      const tabLinks = document.getElementsByClassName('wizard-tab-link');
      if (tabLinks.length > 0 && this.tabCount > 0) {
        const { clientWidth, clientHeight } = tabLinks[0];
        this.tabLinkWidth = clientWidth;
        this.tabLinkHeight = clientHeight;
      }
    }
  }
}
</script>
<style lang="scss">
/* Tab content animation */
.tab-content {
  display: flex; // to avoid horizontal scroll when animating
  .tab-pane {
    display: block;
    animation: fadeIn 0.5s;
    width: 100%;
  }
}

/**
    Extra niceties. Display error tabs and disable navigation unvisited tabs
   */
.wizard-navigation .nav-link {
  &.active,
  &.checked {
    cursor: pointer;
  }
}

.disabled-wizard-link {
  cursor: not-allowed;
}
/* updating icon animation */
.updating-icon {
  animation: flip 2s infinite;
}
@keyframes flip {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
