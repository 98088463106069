<template>
  <md-card md-with-hover>
    <md-card-header class="md-card-header-blue">
      <div class="md-layout">
        <div class="md-layout-item md-size-100 card-title">
          <md-icon class="card-title-icon">
            insert_chart_outlined
          </md-icon>
          <a @click="viewDetails()">
            <span class="md-title">
              {{ report.title }}
            </span>
          </a>
          <br>
          <md-chip class="scen-chip">
            {{ report.scenarios.length }} {{ 'scenario' | pluralize(report.scenarios.length) }}
          </md-chip>
        </div>
      </div>
    </md-card-header>
    <md-card-content class="md-layout">
      <div class="md-layout-item md-size-100 layout-no-padding">
        <p class="md-caption">
          {{ dateCreated }}
        </p>
        <p>{{ report.description }}</p>
      </div>
      <!-- DELETE -->
      <div class="md-layout-item md-size-100 card-actions">
        <md-button class="md-simple md-just-icon">
          <span @click="deleteReport">
            <md-icon>delete</md-icon>
            <md-tooltip md-direction="bottom">Delete</md-tooltip>
          </span>
        </md-button>
        <!-- SHARE -->
        <share-dialog
          v-if="$Region.socialSharingOn"
          ref="shareDialog"
          obj-type="rep"
          :obj="report"
          gtag-action="share_report_close"
          gtag-category="reports"
          share-event="report-share"
          @on-status-change="updateShareObj"
        >
          <md-button class="md-simple md-just-icon">
            <span @click="toggleShareDialog">
              <md-icon>share</md-icon>
              <md-tooltip md-direction="bottom">Share</md-tooltip>
            </span>
          </md-button>
        </share-dialog>
        <!-- VIEW REPORT -->
        <md-button
          class="md-raised md-dense md-success view-btn"
          @click="viewDetails()"
        >
          View
        </md-button>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import ShareDialog from "@/pages/Dashboard/Components/ShareDialog";
import { Sharing } from "@/pages/Dashboard/Components/mixins/Sharing";
export default {
  name: "UserReportCard",

  components: {
    ShareDialog
  },
  mixins: [Sharing],

  props: {
    report: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      dateCreated: ''
    };
  },

  computed: {},

  beforeMount() {
    // get date
    if (!('dateCreated' in this.report) || this.report.dateCreated === undefined || this.report.dateCreated === null) {
      this.dateCreated = new Date().toDateString()
    } else {
      this.dateCreated = new Date(parseInt(this.report.dateCreated)).toDateString();
    }
  },

  methods: {
    viewDetails() {
      // launch the lesson details
      this.$emit('on-load-report', this.index)
    },
    deleteReport() {
      // tell parent to delete this report
      this.$emit('on-delete-report', this.index)
    },
    toggleShareDialog() {
      this.$refs.shareDialog.toggleShowDialog();
    }
  }
};
</script>

<style lang="scss" scoped>
.card-title-icon {
  position: relative;
  margin: 0 0 0 -15px;
  padding: 0px;
  color: $white-color !important;
}
.card-title {
  color: $white-color !important;
}
.md-title {
  font-size: 16px;
  line-height: 20px;
}
.time-icon {
  margin: -2px 5px 0 0;
  color: $white-color !important;
}
.md-subhead {
  margin: 5px 0 0 0;
}
.card-actions {
  text-align: right;
}
.view-btn {
  left: 5px;
  margin-top: 10px;
}
.scen-chip {
  background-color: darken($brand-info, 10%) !important;
}
</style>
