<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <div class="md-card-content">
          <div class="md-layout">
            <div
              class="md-layout-item md-small-size-100 md-xsmall-size-100"
              :class="[{'md-size-55':showSocialSharing}, {'md-size-70':!showSocialSharing}]"
            >
              <div v-if="REPORT_MODE === 'create'">
                <h3>New Report</h3>
              </div>
              <div v-else>
                <h3>
                  {{ report.title }}
                </h3>
                <!-- SHARE INFO -->
                <md-chip
                  v-if="ACTIVE_REPORT_SHARED"
                  class="share-chip"
                >
                  Shared by: {{ ACTIVE_REPORT.UserName }}
                </md-chip>
                <p class="md-caption">
                  {{ dateComputed }}
                </p>
                <p>{{ report.description }}</p>
                <h4>Analysis</h4>
                <p>{{ report.analysis }}</p>
              </div>
            </div>
            <div
              v-if="REPORT_MODE === 'view'"
              class="md-layout-item md-small-size-100 md-xsmall-size-100 md-size-45"
            >
              <!-- SHARING DIALOG -->
              <share-dialog
                v-if="$Region.socialSharingOn && !ACTIVE_REPORT_SHARED"
                obj-type="rep"
                :obj="report"
                gtag-action="share_report_close"
                gtag-category="reports"
                share-event="report-share"
                @on-status-change="updateShareObj"
              />
              <md-button
                id="ReportsBackBtn"
                class="md-raised md-default center-vertical"
                @click="backToAll"
              >
                <md-icon>arrow_back</md-icon> Back to All Reports
              </md-button>
            </div>
            <div
              v-else-if="report.scenarios.length < 4 && report.scenarios.length > 0"
              class="md-layout-item md-small-size-100 md-xsmall-size-100 md-size-30"
            >
              <md-button
                id="scenariosBtn"
                class="md-raised md-success"
                @click="goToScenarios(false)"
              >
                <md-icon>add</md-icon> Add Scenarios
              </md-button>
            </div>
          </div>
        </div>
      </md-card>
      <md-card v-if="report.scenarios.length">
        <div class="md-card-content">
          <!-- controls -->
          <div
            v-if="REPORT_MODE === 'create'"
            class="md-layout"
          >
            <div :class="attrColumnClass">
              &nbsp;
            </div>
            <div
              v-for="(scen, index) in report.scenarios"
              :key="`clim${scen.id}`"
              :class="[scenarioColumnClass, {'attr-col-alternate': index % 2 !== 0}, {'active-col': (isMdSmall || isMdXSmall) && activeCol === index}, {'hidden-col': (isMdSmall || isMdXSmall) && activeCol !== index}]"
            >
              <div style="text-align: center;">
                <md-button
                  v-if="index !== 0 && !isMdSmall && !isMdXSmall"
                  class="md-simple md-just-icon"
                >
                  <span @click="moveScenarioIndex(scen.id, 'back')">
                    <md-icon>arrow_left</md-icon>
                    <md-tooltip md-direction="bottom">Change Scenario Order</md-tooltip>
                  </span>
                </md-button>
                <md-button class="md-simple md-just-icon">
                  <span @click="remove_scenario(scen.id)">
                    <md-icon>remove_circle</md-icon>
                    <md-tooltip md-direction="bottom">Remove from Report</md-tooltip>
                  </span>
                </md-button>
                <md-button
                  v-if="index !== (report.scenarios.length - 1) && !isMdSmall && !isMdXSmall"
                  class="md-simple md-just-icon"
                >
                  <span @click="moveScenarioIndex(scen.id, 'fwd')">
                    <md-icon>arrow_right</md-icon>
                    <md-tooltip md-direction="bottom">Change Scenario Order</md-tooltip>
                  </span>
                </md-button>
              </div>
            </div>
            <div class="md-layout-item md-size-100">
              <md-divider />
            </div>
          </div>
          <!-- general info -->
          <div class="md-layout">
            <div :class="attrColumnClass">
              <span>Scenario</span>
            </div>
            <div
              v-for="(scen, index) in report.scenarios"
              :key="`name${scen.id}`"
              :class="[scenarioColumnClass, {'attr-col-alternate': index % 2 !== 0}, {'active-col': (isMdSmall || isMdXSmall) && activeCol === index}, {'hidden-col': (isMdSmall || isMdXSmall) && activeCol !== index}]"
            >
              <span class="scenario-title">{{ scen.name }}</span>
              <md-button class="md-simple md-just-icon explore-button">
                <span @click="explore(scen)">
                  <md-icon>explore</md-icon>
                  <md-tooltip md-direction="bottom">Explore</md-tooltip>
                </span>
              </md-button>
              <br>
              <md-icon>place</md-icon>
              <span>{{ studyAreaName(scen) }}</span>
              <br>
              <span class="md-caption">{{ scen.type }}</span>
              <br>
              <span class="md-caption">{{ scen.startTime }} - {{ scen.endTime }}</span>
              <br>
              <span class="md-caption">{{ scen.description }}</span>
            </div>
          </div>
          <!-- climate change model -->
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <md-divider />
            </div>
            <div :class="attrColumnClass">
              <span>Climate Change</span>
            </div>
            <div
              v-for="(scen, index) in report.scenarios"
              :key="`clim${scen.id}`"
              :class="[scenarioColumnClass, {'attr-col-alternate': index % 2 !== 0}, {'active-col': (isMdSmall || isMdXSmall) && activeCol === index}, {'hidden-col': (isMdSmall || isMdXSmall) && activeCol !== index}]"
            >
              <span>{{ getClimateScenario(scen.climate).name }}</span>
              <br>
              <span class="md-caption">{{ getClimateScenario(scen.climate).subTitle }}</span>
            </div>
          </div>
          <!-- maps -->
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <md-divider />
            </div>
            <div :class="attrColumnClass">
              <span>Landscape</span>
            </div>
            <div
              v-for="(scen, index) in report.scenarios"
              :key="`map${scen.id}`"
              :class="[scenarioColumnClass, {'attr-col-alternate': index % 2 !== 0}, {'active-col': (isMdSmall || isMdXSmall) && activeCol === index}, {'hidden-col': (isMdSmall || isMdXSmall) && activeCol !== index}]"
            >
              <div v-if="bitmapsReady[scen.id]">
                {{ scen.startTime }} Landscape
                <study-area-map
                  :ref="`startmap_${scen.id}`"
                  :scenario="scen"
                  :year="scen.startTime"
                  :bitmaps-ready="bitmapsReady[scen.id]"
                  :intensity-map-data="intensityMapData[scen.id]"
                  class="scenario-map"
                  @on-launch-scenario="explore"
                />
                {{ scen.endTime }} Landscape
                <study-area-map
                  :ref="`endmap_${scen.id}`"
                  :scenario="scen"
                  :year="scen.endTime"
                  :bitmaps-ready="bitmapsReady[scen.id]"
                  :intensity-map-data="intensityMapData[scen.id]"
                  class="scenario-map"
                  @on-launch-scenario="explore"
                />
              </div>
              <md-progress-spinner
                v-else
                :md-diameter="80"
                :md-stroke="8"
                md-mode="indeterminate"
                class="scenario-progress-spinner"
              />
            </div>
          </div>
          <!-- landuse types -->
          <div
            v-for="item in landUseTypes"
            :key="item.id"
            class="md-layout hover-row"
          >
            <div :class="attrColumnClass">
              <md-button :class="getButtonClass(item)">
                <md-icon
                  :class="getIconClass(item)"
                  :md-src="getIconSrc(item)"
                />
              </md-button>
              <md-tooltip md-direction="top">
                {{ item.name }}
              </md-tooltip>
              <span class="attribute-title-2">{{ item.name }}</span>
            </div>
            <div
              v-for="(scen, index) in report.scenarios"
              :key="`${item.name}-${scen.id}`"
              :class="[scenarioColumnClass, {'attr-col-alternate': index % 2 !== 0}, {'active-col': (isMdSmall || isMdXSmall) && activeCol === index}, {'hidden-col': (isMdSmall || isMdXSmall) && activeCol !== index}]"
            >
              <spark-data-item
                v-if="scen.id in simulationData && 'simAreas' in simulationData[scen.id] && simulationData[scen.id].simAreas[item.name]"
                :start-time="scen.startTime"
                :end-time="scen.endTime"
                :dataset="simulationData[scen.id].simAreas[item.name]"
                units="ha"
              />
            </div>
          </div>
          <!-- indicators grouped by type -->
          <!-- indicator type headings -->
          <div
            v-for="type in indicatorTypes"
            :key="type"
          >
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <md-divider />
              </div>
              <div :class="attrColumnClass">
                <span>{{ type }} Indicators </span>
              </div>
              <div
                v-for="(scen, index) in report.scenarios"
                :key="`${scen.id}-${type}-inds`"
                :class="[scenarioColumnClass, {'attr-col-alternate': index % 2 !== 0}, {'active-col': (isMdSmall || isMdXSmall) && activeCol === index}, {'hidden-col': (isMdSmall || isMdXSmall) && activeCol !== index}]"
              >
                &nbsp;
              </div>
            </div>
            <!-- indicators -->
            <div
              v-for="ind in indicatorsByType(type)"
              :key="ind.name"
              class="md-layout hover-row"
            >
              <div :class="attrColumnClass">
                <md-icon
                  :md-src="`/img/${ind.icon}`"
                  class="chart-icon"
                />
                <span class="attribute-title-2">{{ ind.name }}</span>
              </div>
              <div
                v-for="(scen, index) in report.scenarios"
                :key="`${scen.id}-${ind.name}`"
                :class="[scenarioColumnClass, {'attr-col-alternate': index % 2 !== 0}, {'active-col': (isMdSmall || isMdXSmall) && activeCol === index}, {'hidden-col': (isMdSmall || isMdXSmall) && activeCol !== index}]"
              >
                <div v-if="showIndVals(scen, ind)">
                  <spark-data-item
                    :start-time="scen.startTime"
                    :end-time="scen.endTime"
                    :dataset="indicatorVals[scen.id][ind.name]"
                    :units="ind.units"
                  />
                  <div v-if="scen.type === 'Land Use'">
                    <br>
                    <span class="ind-goal">
                      <status-indicator
                        :id="`CoverIndicatorGoalStatus${ind.name.replace(/\s/g, '')}`"
                        :status="indicatorGoalStatus(scen, ind)"
                        :pulse="false"
                        class="statusIndicator"
                      />
                      Goal: {{ indicatorGoalUnits(scen, ind) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- industrial activity -->
          <div class="md-layout hover-row">
            <div class="md-layout-item md-size-100">
              <md-divider />
            </div>
            <div :class="attrColumnClass">
              <span>Industrial Activity</span>
            </div>
            <div
              v-for="(scen, index) in report.scenarios"
              :key="`ind-activity-${scen.id}`"
              :class="[scenarioColumnClass, {'attr-col-alternate': index % 2 !== 0}, {'active-col': (isMdSmall || isMdXSmall) && activeCol === index}, {'hidden-col': (isMdSmall || isMdXSmall) && activeCol !== index}]"
              style="text-align: center;"
            >
              <span>{{ (scen.devRate * 100).toFixed(2) | formatNumber }}%</span>
            </div>
          </div>
          <!-- management practices -->
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <md-divider />
            </div>
            <div :class="attrColumnClass">
              <span>Beneficial Management Practices</span>
            </div>
            <div
              v-for="(scen, index) in report.scenarios"
              :key="`${scen.id}--mgmt`"
              :class="[scenarioColumnClass, {'attr-col-alternate': index % 2 !== 0}, {'active-col': (isMdSmall || isMdXSmall) && activeCol === index}, {'hidden-col': (isMdSmall || isMdXSmall) && activeCol !== index}]"
            >
              &nbsp;
            </div>
          </div>
          <div
            v-for="item in $Region.mgmtPractices"
            :key="item.variable"
            class="md-layout hover-row"
          >
            <div :class="attrColumnClass">
              <span class="attribute-title-2">{{ item.name }}</span>
            </div>
            <div
              v-for="(scen, index) in report.scenarios"
              :key="`${item.variable}-${scen.id}`"
              :class="[scenarioColumnClass, {'attr-col-alternate': index % 2 !== 0}, {'active-col': (isMdSmall || isMdXSmall) && activeCol === index}, {'hidden-col': (isMdSmall || isMdXSmall) && activeCol !== index}]"
            >
              <mgmt-practice-switch
                :practice="item"
                :practice-value="getMgmtPracticeStatus(scen, item.variable)"
                :disabled="true"
                :scenario-type="scen.type"
                :show-name="false"
                :show-warning="false"
                class="attr-val-center"
              />
            </div>
          </div>
          <!-- climate indicators -->
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <md-divider />
            </div>
            <div :class="attrColumnClass">
              <span>Climate Data</span>
            </div>
            <div
              v-for="(scen, index) in report.scenarios"
              :key="`${scen.id}-clim`"
              :class="[scenarioColumnClass, {'attr-col-alternate': index % 2 !== 0}, {'active-col': (isMdSmall || isMdXSmall) && activeCol === index}, {'hidden-col': (isMdSmall || isMdXSmall) && activeCol !== index}]"
            >
              &nbsp;
            </div>
          </div>
          <div
            v-for="item in $Region.climateIndicators"
            :key="item.name"
            class="md-layout hover-row"
          >
            <div :class="attrColumnClass">
              <span class="attribute-title-2">{{ item.chartTitle }}</span>
            </div>
            <div
              v-for="(scen, index) in report.scenarios"
              :key="`${item.name}-${scen.id}`"
              :class="[scenarioColumnClass, {'attr-col-alternate': index % 2 !== 0}, {'active-col': (isMdSmall || isMdXSmall) && activeCol === index}, {'hidden-col': (isMdSmall || isMdXSmall) && activeCol !== index}]"
            >
              <spark-data-item
                v-if="item.name in climDataReady && climDataReady[item.name][scen.id]"
                :start-time="scen.startTime"
                :end-time="scen.endTime"
                :dataset="climateIndData[scen.id][item.name]"
                :units="item.chartUnits"
              />
            </div>
          </div>
        </div>
        <!-- MOBILE COLUMN CONTROLS -->
        <div
          v-if="isMdSmall || isMdXSmall"
          class="mobile-col-controls md-layout md-alignment-bottom-space-between md-elevation-1"
        >
          <md-button class="md-layout-item md-simple md-just-icon">
            <span @click="changeActiveColumn('back')">
              <md-icon>arrow_left</md-icon>
            </span>
          </md-button>
          <div class="md-layout-item mobile-dots">
            <span
              v-for="(scen, index) in report.scenarios"
              :key="scen.id"
              class="dot"
              :class="[{'dot-active': index === activeCol}]"
              @click="changeActiveColumn(index)"
            />
          </div>
          <md-button class="md-layout-item md-simple md-just-icon">
            <span @click="changeActiveColumn('fwd')">
              <md-icon>arrow_right</md-icon>
            </span>
          </md-button>
        </div>
      </md-card>
      <md-card v-else>
        <!-- no scenarios in report -->
        <div class="md-card-content">
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <p>
                Your report has no scenarios! Please select 1 to 4 scenarios to report from <a :href="goToScenarios(true)">
                  your scenarios
                </a>.
              </p>
            </div>
            <div class="md-layout-item md-size-30">
              <md-button
                id="scenariosBtn"
                class="md-raised md-success"
                @click="goToScenarios(true)"
              >
                Select Scenarios
              </md-button>
            </div>
          </div>
        </div>
      </md-card>
    </div>
    <report-scenario-renderless
      v-for="scen in report.scenarios"
      :key="scen.id"
      :scenario="scen"
      @on-jickle-loaded="renderScenarioData"
      @on-climate-data-ready="renderClimateData"
    >
      <div slot-scope="{}" />
    </report-scenario-renderless>
    <!-- NEW REPORT FORM -->
    <div
      v-if="REPORT_MODE === 'create' && report.scenarios.length"
      class="md-layout"
    >
      <div class="md-layout-item md-size-100">
        <md-card>
          <new-report :scenarios="reportScenarioIDs" />
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import "@/jickle"; // inherently made available as Window.JICKLE (aka JICKLE)
import StudyAreaMap from "./StudyAreaMap";
import ReportScenarioRenderless from "./ReportScenarioRenderless";
import SparkDataItem from "./SparkDataItem";
import { StatusIndicator } from 'vue-status-indicator';
import MgmtPracticeSwitch from "./MgmtPracticeSwitch";
import NewReport from "@/pages/Dashboard/Forms/NewReport";
import ShareDialog from "@/pages/Dashboard/Components/ShareDialog";
import { Sharing } from "@/pages/Dashboard/Components/mixins/Sharing";
import { LandTypes } from "./mixins/LandTypes";
import { Indicators } from "./mixins/Indicators";
import { ResponsiveLayoutHelpers } from "@/pages/Dashboard/Components/mixins/ResponsiveLayoutHelpers";
export default {
  name: "ReportDetails",

  components: {
    StudyAreaMap,
    ReportScenarioRenderless,
    SparkDataItem,
    StatusIndicator,
    MgmtPracticeSwitch,
    NewReport,
    ShareDialog
  },

  mixins: [Sharing, LandTypes, Indicators, ResponsiveLayoutHelpers],

  props: {
    report: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      activeCol: 0,
      bitmapsReady: {},
      simulationData: {},
      regionIndicators: {},
      indicatorVals: {},
      indValsReady: {},
      intensityMapData: {},
      climateIndData: {},
      climDataReady: {},
      relevantInds: []
    }
  },

  computed: {
    ...mapGetters([
      types.getters.REPORT_MODE,
      types.getters.ACTIVE_REPORT,
      types.getters.ACTIVE_REPORT_SHARED
    ]),

    attrColumnWidth() {
      let width = 20;
      if (this.isMdSmall) {
        width = 40;
      } else if (this.isMdXSmall) {
        width = 30;
      }
      return width;
    },
    dateComputed() {
      // the report date as a string
      if (!('dateCreated' in this.report) || this.report.dateCreated === undefined || this.report.dateCreated === null) {
        return new Date().toDateString();
      }
      return new Date(parseInt(this.report.dateCreated)).toDateString();
    },
    attrColumnClass() {
      // return the css class to use for the attribute titles
      return `md-layout-item md-size-${this.attrColumnWidth} attribute-title`;
    },
    scenarioColumnClass() {
      // return the css class to use for a scenario column
      // based on the number of scenarios in the report minus the width for the attribute titles column, rounded to nearest 5%
      const numScens = this.report.scenarios.length;
      let classWidth = 100 - this.attrColumnWidth;
      if (!(this.isMdSmall || this.isMdXSmall)) {
        classWidth = Math.round((100 - this.attrColumnWidth) / numScens / 5) * 5;
      }
      return `md-layout-item md-size-${classWidth}`;
    },
    indicatorTypes() {
      // return a list of indicator types based on only indicators with videos
      const indTypes = [...new Set(this.$Region.indicators.map(ind => ind.type))]
      return indTypes;
    },
    reportScenarioIDs() {
      // return a list of scenario id's in this report
      // used to save list of id's to db instead of saving full objects to db
      return this.report.scenarios.map(scen => scen.id);
    },
    showSocialSharing() {
      return (this.REPORT_MODE === 'view' && this.$Region.socialSharingOn)
    },
  },

  created() {
    // initialize some data tracking
    this.$Region.climateIndicators.forEach(ind => {
      this.$set(this.climDataReady, ind.name, {});
    })
    this.report.scenarios.forEach(scen => {
      // need to use $set method since these keys aren't initialized yet in the reactivity system
      this.$set(this.bitmapsReady, scen.id, false); // instead of this.bitmapsReady[scen.id] = false
      this.$set(this.simulationData, scen.id, {});
      this.$set(this.regionIndicators, scen.id, []);
      this.$set(this.indicatorVals, scen.id, {});
      this.$set(this.indValsReady, scen.id, false);
      this.$set(this.intensityMapData, scen.id, null);
      this.$set(this.climateIndData, scen.id, {});
      this.$Region.climateIndicators.forEach(ind => {
        this.$set(this.climDataReady[ind.name], scen.id, false);
        this.$set(this.climateIndData[scen.id], ind.name, null);
      })
    });
    // init the list of indicators
    this.initRelevantInds();
  },

  methods: {
    ...mapActions([
      types.actions.SET_ACTIVE_SCENARIO,
      types.actions.ACTIVE_REPORT_REMOVE_SCENARIO,
      types.actions.SET_NEW_REPORT_MSG
    ]),

    backToAll() {
      // emit back to all reports
      this.$emit('on-back');
    },
    studyAreaName(scenario) {
      const name = scenario.studyArea.replace(".geojson", "").split("__");
      return name[name.length - 1];
    },
    getClimateScenario(code) {
      return this.$Region.climateScenarios.filter(scen => scen.variable === code)[0];
    },
    calculateIndicatorVals(id) {
      // calculate indicator outputs for a scenario (using scenario's id for reference)
      const scenario = this.report.scenarios.find(scen => scen.id === id);
      const allInds = this.filteredIndicators(this.regionIndicators[id]);
      const allYears = Array(scenario.endTime - scenario.startTime + 1).fill().map((x, i) => i + scenario.startTime);
      const _this = this;
      allInds.forEach(ind => {
        allYears.forEach(yr => {
          _this.simulationData[id].simYear = yr;
          this.indicatorValue(ind.name, scenario, _this.simulationData[id], yr, this.indicatorVals[id]);
        });
      });
      return true;
    },
    renderScenarioData(id, regionIndicators, simulationState, intensityMapData) {
      // get the computed scenario data from the child component and set make it availalbe to the comparison table fields
      this.regionIndicators[id] = regionIndicators;
      this.simulationData[id] = simulationState;
      this.intensityMapData[id] = intensityMapData;
      this.bitmapsReady[id] = true;
      this.getScenarioRelevantInds(id);
      this.indValsReady[id] = this.calculateIndicatorVals(id);
    },
    renderClimateData(id, climInd, climateIndData) {
      // get the computed climate data from the child component and make it available
      this.climateIndData[id][climInd] = climateIndData;
      this.climDataReady[climInd][id] = true;
    },
    initRelevantInds() {
      // init relavantInds with ordered list of default indicators
      this.relevantInds = this.$Region.indicators // indicator definitions
        .filter(ind =>
          this.$Region.defaultIndicators // list of default indicators
            .includes(ind.name)
        )
        .sort((a, b) => a.order - b.order);
    },
    getScenarioRelevantInds(id) {
      // compute list of indicators relevant to study area of scenario based on scenario id
      this.relevantInds = this.relevantInds.concat(this.filteredIndicators(this.regionIndicators[id])
        .filter((item) => this.relevantInds.indexOf(item) < 0))
        .sort((a, b) => a.order - b.order);
    },
    indicatorsByType(type) {
      // return list of indicators by type
      return this.relevantInds.filter(ind => ind.type === type);
    },
    showIndVals(scen, ind) {
      return scen.id in this.indValsReady && this.indValsReady[scen.id] === true && scen.id in this.indicatorVals && ind.name in this.indicatorVals[scen.id] && scen.endTime in this.indicatorVals[scen.id][ind.name];
    },
    indicatorGoal(scenario, indicator) {
      // get the goal setting for a scenario indicator
      return indicator.name in scenario.goals ? parseFloat(scenario.goals[indicator.name]) : parseFloat(this.$Region.indicators.find(ind => ind.name === indicator.name).goal);
    },
    indicatorGoalUnits(scenario, indicator) {
      // return a goal value represented as actual indicator units instead of %
      if (indicator.simple_units === '%') {
        return `${this.indicatorGoal(scenario, indicator)}%`;
      }
      // compute the goal as actual and return
      // todo a better way to handle indicators that have value of 0
      // prevent divide by 0, set min values for indicator actual and percent to 1
      const indicatorValConverted = this.indicatorVals[scenario.id][indicator.name][scenario.endTime][1] * indicator.conversion;
      const indicatorValPercent = this.indicatorVals[scenario.id][indicator.name][scenario.endTime][0];
      return this.$Helpers.abbreviateNumber(Math.max(indicatorValConverted, 1) / Math.max(indicatorValPercent, 1) * this.indicatorGoal(scenario, indicator));
    },
    indicatorGoalStatus(scenario, indicator) {
      // get the current status of a goal
      const indValPercent = this.indicatorVals[scenario.id][indicator.name][scenario.endTime][0];
      const indGoal = this.indicatorGoal(scenario, indicator);
      let val1 = indValPercent,
        val2 = indGoal;
      if (indicator.target === "low") {
        // low values are better - invert calculation
        val1 = val2;
        val2 = indValPercent;
      }
      if (val1 >= val2) {
        return "positive";
      } else if (val1 >= (val2 - 5)) {
        return "intermediary";
      } else if (val1 >= (val2 - 10)) {
        return "intermediary";
      }
      return "negative";
    },
    getMgmtPracticeStatus(scenario, practice) {
      return (scenario.management.includes(practice));
    },
    explore(scenario) {
      this.SET_ACTIVE_SCENARIO({ vm: this, scenario: scenario });
      this.$router.push({
        name: "Explore"
      });
    },
    goToScenarios(showMsg = false) {
      this.SET_NEW_REPORT_MSG(showMsg);
      this.$router.push({ name: "Scenarios" });
    },
    remove_scenario(id) {
      // remove a scenario from the report
      this.ACTIVE_REPORT_REMOVE_SCENARIO(id)
      // remove data from report
      delete this.bitmapsReady[id];
      delete this.simulationData[id];
      delete this.regionIndicators[id];
      delete this.indicatorVals[id];
      delete this.indValsReady[id];
      delete this.intensityMapData[id];
      delete this.climateIndData[id];
      this.$Region.climateIndicators.forEach(ind => {
        delete this.climDataReady[ind.name][id];
      });
      // force all maps to resize
      setTimeout(() => {
        this.report.scenarios.forEach(scen => {
          this.$refs[`startmap_${scen.id}`][0].map.resize();
          this.$refs[`endmap_${scen.id}`][0].map.resize();
        });
      }, 200);
    },
    moveScenarioIndex(id, direction) {
      // move a scenario forward or backward in the report scenarios list
      const oldIndex = this.report.scenarios.findIndex(scen => scen.id === id);
      const newIndex = direction === 'fwd' ? oldIndex + 1 : oldIndex - 1;
      this.report.scenarios.splice(newIndex, 0, this.report.scenarios.splice(oldIndex, 1)[0]);
    },
    changeActiveColumn(direction) {
      if (Number.isInteger(direction)) {
        this.activeCol = direction;
      } else if (direction === 'fwd') {
        this.activeCol = Math.min(this.activeCol + 1, this.report.scenarios.length - 1);
      } else {
        this.activeCol = Math.max(this.activeCol - 1, 0);
      }
      // force map to resize and center
      setTimeout(() => {
        this.$refs[`startmap_${this.report.scenarios[this.activeCol].id}`][0].map.resize();
        this.$refs[`endmap_${this.report.scenarios[this.activeCol].id}`][0].map.resize();
        this.$refs[`startmap_${this.report.scenarios[this.activeCol].id}`][0].flyTo();
        this.$refs[`endmap_${this.report.scenarios[this.activeCol].id}`][0].flyTo();
      }, 200);
    }
  }
};
</script>

<style lang="scss" scoped>
.attribute-title,
.attribute-title-2 {
  background-color: $gray-lighter;
}
.attribute-title > span {
  font-weight: bold;
}
.attribute-title > button {
  height: 27px !important;
  width: 27px !important;
  line-height: 27px !important;
  margin-top: -5px;
  margin-right: 5px;
  margin-bottom: 10px;
}
.attribute-title > i {
  float: left;
  color: $black-color;
  margin-right: 5px;
}
.attribute-title-2 {
  font-weight: normal !important;
}
.attr-col-alternate {
  background-color: lighten($gray-base, 97.5%);
}
/deep/ .attr-value,
.ind-goal {
  float: left;
  width: 14ch;
  text-align: right;
  margin-left: 8ex;
}
.attr-val-center {
  transform: translate(40%, 0);
}
/deep/ .attr-delta {
  float: right;
  width: 9ch;
  text-align: right;
}
.scenario-title {
  font-weight: bold;
}
.explore-button {
  margin-top: -0.5em;
}
.scenario-progress-spinner {
  padding: 15px;
}
.scenario-map {
  margin-bottom: 10px;
}
.hover-row:hover {
  background-color: lighten($gray-base, 93.5%);
}
.hover-row:hover .attr-col-alternate,
.hover-row:hover .attribute-title,
.hover-row:hover .attribute-title-2 {
  background-color: lighten($gray-base, 90%);
}

// show/hide columns on mobile
@keyframes show {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
@keyframes hide {
  0% {
    display: block;
    opacity: 1;
  }
  99% {
    display: block;
  }
  100% {
    display: none;
    opacity: 0;
  }
}
.hidden-col {
  animation: hide 500ms linear;
  animation-fill-mode: forwards;
  display: none;
}
.active-col {
  animation: show 500ms linear;
  animation-fill-mode: forwards;
  display: block;
}
.mobile-col-controls {
  position: sticky;
  bottom: 0;
  background-color: $white-color;
  border-radius: 0 0 5px 5px;
  z-index: 10;
}
.mobile-col-controls i {
  font-size: 30px !important;
}
.mobile-dots {
  text-align: center;
  display: inline-block;
  transform: translate(0, -5px);
}
.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px 5px 5px;
  border-radius: 50%;
  background-color: $gray-light;
  display: inline-block;
}
.dot-active {
  background-color: $brand-info;
}
.share-chip {
  background-color: $brand-info !important;
}
</style>
