<template>
  <div class="md-layout">
    <!--landscape icons-->
    <div class="md-layout-item md-size-100 md-layout">
      <h4
        v-if="!rightSidebarFullScreen"
        :class="{'text-white': 'complete' in ACTIVE_SCENARIO && ACTIVE_SCENARIO.complete}"
      >
        What changes do you want to make?
      </h4>
      <!-- run/undo buttons to show when editing existing scenario -->
      <div
        v-if="'complete' in ACTIVE_SCENARIO && ACTIVE_SCENARIO.complete"
        class="md-layout-item md-size-100 run-buttons"
      >
        <transition name="bounce">
          <md-button
            v-if="readyToRun || rightSidebarFullScreen"
            :disabled="(!readyToRun)"
            class="md-default md-sm"
            @click="undoChanges"
          >
            <i class="fas fa-undo" />
            Undo Changes
          </md-button>
        </transition>
        <transition name="bounce">
          <md-button
            v-if="readyToRun || rightSidebarFullScreen"
            :disabled="(!readyToRun)"
            class="md-success md-sm"
            @click="emitSubmitScenario"
          >
            <i class="fas fa-play" />
            Run Scenario!
          </md-button>
        </transition>
      </div>
    </div>
    <div
      class="md-layout-item md-size-100 md-layout"
      :class="{'lt-buttons-fullscreen': rightSidebarFullScreen}"
    >
      <div class="md-layout-item md-size-100">
        <span
          v-if="ACTIVE_SCENARIO.allocations.length === 0"
          class="badge badge-success badge-mobile-fullscreen"
        >
          <i class="fas fa-caret-down" />
          {{ chooseCoverTypeMsg }}
        </span>
      </div>
      <div
        v-for="item in landUseTypes"
        :key="item.id"
        class="md-layout-item md-size-15"
      >
        <md-button
          class="md-button-fill md-icon-button md-fab"
          :class="[{[item.color]: true, 'md-button-active-border': isActiveLT(item.name), 'md-raised': isActiveLT(item.name)}]"
          @click="activateLT(item.name)"
        >
          <md-icon
            :class="getIconClass(item)"
            :md-src="getIconSrc(item)"
          />
        </md-button>
        <md-tooltip md-direction="right">
          {{ item.name }}
        </md-tooltip>
      </div>
      <div
        v-for="item in noChangeLandUseTypes"
        :key="item.id"
        class="md-layout-item md-size-15"
      >
        <md-button
          class="md-button-fill md-icon-button md-fab"
          :class="[{[item.color]: true}]"
          @click="warnNoChangeLT(item.name)"
        >
          <md-icon
            :class="getIconClass(item)"
            :md-src="getIconSrc(item)"
          />
        </md-button>
        <md-tooltip md-direction="right">
          {{ item.name }}
        </md-tooltip>
      </div>
    </div>
    <!-- ALLOCATIONS LIST -->
    <div
      class="md-layout-item"
      :class="[{'md-size-50': !sidebarWidePanels}, {'md-size-100': sidebarWidePanels}]"
    >
      <md-card v-show="!rightSidebarFullScreen || ACTIVE_SCENARIO.allocations.length">
        <md-card-header class="md-card-header-text md-card-header-blue">
          <div class="card-text">
            <h4 class="title">
              Your Changes
            </h4>
          </div>
        </md-card-header>
        <md-card-content>
          <p>
            <whats-a-ha />
          </p>
          <transition-group name="bounceGroup">
            <scenario-allocation-card
              v-for="item in allocations.slice().reverse()"
              :key="item.uuid"
              :allocation="item"
            />
          </transition-group>
        </md-card-content>
      </md-card>
    </div>
    <!-- END ALLOCATIONS LIST -->
    <!-- COMPOSITION CHARTS -->
    <div
      v-if="!rightSidebarFullScreen || 'complete' in ACTIVE_SCENARIO && ACTIVE_SCENARIO.complete"
      class="md-layout-item"
      :class="[{'md-size-50': !sidebarWidePanels}, {'md-size-100': sidebarWidePanels}]"
    >
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-blue">
          <div class="card-text">
            <h4 class="title">
              Landscape Composition (%)
            </h4>
          </div>
        </md-card-header>
        <md-card-content>
          <div class="chart-holder">
            <chartist
              :data="landscapeCompositionChartData('start')"
              :options="landscapeCompositionChartOptions"
              type="Pie"
            />
            <span class="md-caption">{{ this.$Region.landuse_simulation_start_time }}</span>
          </div>
          <div class="chart-holder">
            <chartist
              :data="landscapeCompositionChartData('end')"
              :options="landscapeCompositionChartOptions"
              type="Pie"
            />
            <span class="md-caption">Your plan for {{ this.$Region.landuse_simulation_end_time }}</span>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <!-- END COMPOSITION CHARTS -->
    <!-- NEW SCENARIO / SCENARIO WIZARD ACTION BUTTONS only show for new scenarios -->
    <div
      v-if="!('complete' in ACTIVE_SCENARIO)"
      class="md-layout-item md-size-100 tab-actions"
    >
      <div>
        <md-button @click.native="closeCreateMode">
          Cancel
        </md-button>
      </div>
      <div>
        <md-button @click.native="prevTab">
          Back
        </md-button>
      </div>
      <div>
        <md-button
          class="md-success"
          @click.native="nextTab"
        >
          Next
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import { ScenarioChanges } from "@/pages/Dashboard/Components/mixins/ScenarioChanges";
import { LandTypes } from "./mixins/LandTypes";
import ScenarioAllocationCard from "./ScenarioAllocationCard";
import { ScenarioWizardHelpers } from "@/pages/Dashboard/Components/mixins/ScenarioWizardHelpers";
import { ResponsiveLayoutHelpers } from "@/pages/Dashboard/Components/mixins/ResponsiveLayoutHelpers";
import WhatsAHa from "./WhatsAHa";

export default {
  name: "ScenarioChanges",

  components: {
    ScenarioAllocationCard,
    WhatsAHa
  },
  mixins: [ScenarioChanges, LandTypes, ScenarioWizardHelpers, ResponsiveLayoutHelpers], //some common lt stuff

  props: {
    simulationState: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      readyToRun: false
    };
  },

  computed: {
    ...mapGetters([
      types.getters.SCENARIO_UNDOS,
      types.getters.ALLOCATION_AREA_MAX,
      types.getters.ALLOCATIONS_UPDATED,
      types.getters.ACTIVE_LT
    ]),

    chooseCoverTypeMsg() {
      return !this.rightSidebarFullScreen ? `Choose a land cover type & click on the map to plan changes` : `Choose a land cover type & click on map to plan`;
    },
    allocations() {
      return this.ACTIVE_SCENARIO.allocations;
    },
    icons() {
      return this.$Region.icons;
    },
    landUseTypes() {
      return this.$Region.landUseTypes;
    },
    noChangeLandUseTypes() {
      return this.$Region.noChangeLandUseTypes;
    },
    activeLTObject() {
      let activeLTObj = null;
      this.landUseTypes.forEach(lt => {
        if (lt.name === this.ACTIVE_LT) activeLTObj = lt;
      });
      return activeLTObj;
    },
    allocationAreas() {
      const areas = {};
      this.allocations.forEach(alloc => {
        areas[alloc.name] = { area: 0, series: "" };
      });
      this.allocations.forEach(alloc => {
        areas[alloc.name].area += alloc.area;
        areas[alloc.name].series = this.allocDataFromName(alloc.name).series;
      });
      const labels = Object.keys(areas);
      const series = [];
      labels.forEach(label =>
        series.push({
          value: areas[label].area,
          className: areas[label].series
        })
      );
      return {
        labels: labels,
        series: series
      };
    },
    chartClass() {
      return this.activeLTObject.series;
    },
  },

  watch: {
    ALLOCATIONS_UPDATED: {
      handler: function () {
        if (this.ALLOCATIONS_UPDATED.includes(this.ACTIVE_SCENARIO.id)) {
          this.readyToRun = true;
        } else {
          this.readyToRun = false;
        }
      },
      deep: true,
      immediate: true // make sure readyToRun is detected when edit panel closes and re-opens if changes have been made
    }

  },

  beforeMount() {
  },

  mounted() {
    this.SET_ACTIVE_LT(null);
    this.SHOW_PLANNING_MARKERS_ON();
    this.SHOW_PLANNING_MARKERS_LOCK_ON();
    this.PLANNING_MARKERS_DRAGGABLE_ON();
  },

  beforeDestroy() {
    this.SHOW_PLANNING_MARKERS_LOCK_OFF();
    this.PLANNING_MARKERS_DRAGGABLE_OFF();
  },

  methods: {
    ...mapActions([
      types.actions.SET_ACTIVE_LT,
      types.actions.ALLOCATIONS_UPDATED_REMOVE,
      types.actions.UPDATE_ACTIVE_SCENARIO,
      types.actions.SCENARIO_UNDOS_REMOVE,
      types.actions.SHOW_PLANNING_MARKERS_ON,
      types.actions.SHOW_PLANNING_MARKERS_LOCK_ON,
      types.actions.SHOW_PLANNING_MARKERS_LOCK_OFF,
      types.actions.PLANNING_MARKERS_DRAGGABLE_ON,
      types.actions.PLANNING_MARKERS_DRAGGABLE_OFF,
      types.actions.TOGGLE_REFRESH_PLANNING_MARKERS
    ]),

    landscapeCompositionChartData(timeFrame) {
      const labels = [],
        series = [];
      const _this = this;
      let totalAllocations = 0;
      if (timeFrame === 'end') {
        this.ACTIVE_SCENARIO.allocations.forEach(alloc => {
          totalAllocations += alloc.area;
        });
      }
      this.$Region.landUseTypes
        .concat(this.$Region.noChangeLandUseTypes)
        .forEach(ltType => {
          let _val = _this.simulationState.simAreas[ltType.name][this.ACTIVE_SCENARIO.startTime];
          if (timeFrame === 'start') {
            // values are proportionate to totalArea
            _val = _val / _this.simulationState.totalArea;
          } else {
            // values are proportionate to totalArea + total requested allocations
            this.ACTIVE_SCENARIO.allocations.forEach(alloc => {
              if (ltType.name === alloc.name) {
                _val += alloc.area;
              }
            });
            _val = _val / (_this.simulationState.totalArea + totalAllocations);
          }
          _val = (_val * 100).toFixed(2);
          labels.push(ltType.name);
          series.push({
            meta: ltType.name,
            value: _val,
            className: ltType.series
          });
        });
      return {
        labels: labels,
        series: series
      };
    },
    areaToString(area) {
      return this.$Helpers.abbreviateNumber(area) + " ha";
    },
    isActiveLT(name) {
      return (name === this.ACTIVE_LT);
    },
    activateLT(name) {
      // only activate the LT if there's not a point currently being created/edited
      this.landUseTypes.forEach(lt => {
        if (lt.name === name) {
          this.SET_ACTIVE_LT(lt.name);
        }
      });
    },
    warnNoChangeLT(name) {
      // warn user that they can't use this LT
      this.$notify({
        message: `Sorry, allocating ${name} is not allowed.`,
        icon: "error_outline",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger"
      });
    },
    allocDataFromName(name) {
      let landUseType;
      this.landUseTypes.forEach(lt => {
        if (name === lt.name) {
          landUseType = lt;
        }
      });
      return landUseType;
    },
    undoChanges() {
      // update the scenario allocations back to the loaded undos state
      this.UPDATE_ACTIVE_SCENARIO({ allocations: this.SCENARIO_UNDOS.allocations });
      this.ALLOCATIONS_UPDATED_REMOVE(this.ACTIVE_SCENARIO.id);
      this.TOGGLE_REFRESH_PLANNING_MARKERS();
    },
    emitSubmitScenario() {
      this.$emit('on-submit');
    }
  }
};
</script>

<style lang="scss" scoped>
.text-white {
  color: $white-color;
}
.ha-info {
  color: $brand-info !important;
  cursor: pointer;
}
.run-buttons {
  display: flex;
  justify-content: space-between;
}
.tab-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 10px;
}
.badge-mobile-fullscreen {
  margin-bottom: 10px;
  width: 100%;
}
.lt-buttons-fullscreen {
  margin-top: 15px;
}
.md-button-active-border {
  border-style: solid;
  border-width: 1px;
  border-color: $white-color;
}
.md-button-fill {
  @extend .md-button;
  min-width: 100%;
}
.md-card-no-elevation {
  background: transparent;
  box-shadow: none;
}
.land-comp-chart {
  margin: 20px 35px 10px 35px;
}
.md-caption {
  // color: $white-color !important;
  line-height: 16px;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.chart-holder {
  position: relative;
  min-height: 50px;
  margin: 2em 0 2em 0;
}
.chart-holder > * {
  position: absolute;
  // top: 0;
}
/deep/ .chartist-tooltip {
  position: absolute;
  display: none;
  min-width: 5em;
  padding: 8px 10px;
  margin-top: 10px;
  background: #383838;
  color: #fff;
  text-align: center;
  pointer-events: none;
  z-index: 100;
  transition: opacity 0.2s linear;
}
/deep/ .chartist-tooltip:before {
  position: absolute;
  bottom: -14px;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: rgba(251, 249, 228, 0);
  border-top-color: #383838;
  border-width: 7px;
  margin-left: -8px;
}
/deep/ .chartist-tooltip.tooltip-show {
  display: inline-block !important;
}
/deep/ .chartist-tooltip-value:after {
  content: "%";
}
/* allocation cards group transition */
.bounceGroup-enter-active {
  animation: bounce-in 0.3s;
}
.bounceGroup-leave-active {
  animation: bounce-out 0.3s;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bounce-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0);
  }
}
</style>
