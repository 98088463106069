// BRBC 360 videos

const guardiansOfTheIceObservations = [
  {
    id: 1,
    title: "The Athabasca Glacier",
    // move and put slo-mo video
    description: "A panoramic view of the Athabasca Glacier.",
    subtype: "iceguardian",
    coordinates: [-117.25197315216064, 52.19348864884182],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/a45f46d3514947359f0745c3b5dff31a/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/c6678f98-a6f3-4ca1-ab0b-4082cc0fc877/thumbnails/This is the Athabasca glacier._tumb.0000007.jpg",
      shareUrl: "https://www.youtube.com/watch?v=KF1JBsGdyZY",
    },
    observedTime: 1579725402764,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,landuse,glaciermovement,glaciermelt",
  },
  {
    id: 2,
    title: "Peyto Calving",
    description: "Peyto Glacier on the Wapta Icefield Calving.",
    subtype: "iceguardian",
    coordinates: [-116.54910716705899, 51.6764763438762],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/489f9170035b4f488cf5c59b59a2e752/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/6f55c12a-d9e7-42a8-b28b-ad61a1891fef/thumbnails/obs_27fdd0c1-2160-401e-8909-12e64084a8cf_tumb.0000008.jpg",
    },
    observedTime: 1582912970958,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,landuse",
  },
  {
    id: 3,
    title: "How much of the Athabasca Glacier are we losing?",
    description: "Peter Lemieux shows us how we know we are losing ice.",
    subtype: "iceguardian",
    coordinates: [-117.23987102508586, 52.203530200543156],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/b7542b2f05c24d9896d0503fc0694fe6/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/a93656c2-dbe9-4cca-8b30-1348050ba4f1/thumbnails/obs_07c1c554-f590-4de2-9609-52263faafbda_tumb.0000024.jpg",
      shareUrl: "https://www.youtube.com/watch?v=Htf4sCgOyvc",
    },
    observedTime: 1583439300883,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,landuse",
  },
  {
    id: 4,
    title: "Bow Glacier",
    description:
      "Part of the Wapta Icefield,  the 3.5 square km Bow Glacier drains into Bow Lake.",
    subtype: "iceguardian",
      coordinates: [-116.50997175776537, 51.645463378279544],
    media: {
      type: "image",
      status: "Unoptimized",
      url: "obs_34cc66c2-1332-4f71-b2b0-32121f6d9b09.jpg",
    },
    observedTime: 1583439300883,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,landuse",
  },
  {
    id: 5,
    title: "Guardians of the Ice Trailer",
    description: "Learn more about the Guardians of the Ice Project",
    subtype: "iceguardian",
    coordinates: [-117.29674010464652, 52.12486659320277],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/d1393505a2214691b11a3b70ac1edecc/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/e1c3e998-3758-4f2f-9a2a-b27e7be79dc4/thumbnails/obs_46b4e3af-414b-4928-b219-9c9372d2eb27_tumb.0000013.jpg",
      shareUrl: "https://www.youtube.com/watch?v=asftg5KZnoY&t=4s",
    },
    observedTime: 1394823600000,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,landuse",
  },
  {
    id: 6,
    title: "Approaching the Athabasca Glacier",
    description: "Experience walking up to the glacier!",
    subtype: "iceguardian",
    coordinates: [-117.23904371970335, 52.21373405595895],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/9ebdc6ccb13a4d8b91c513b7cc62b762/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/8b8ba51d-9a90-4daa-9cbd-22a13dc1660b/thumbnails/obs_885b7ca9-4e91-4226-a03b-8ad488210cb5_tumb.0000016.jpg",
      shareUrl: "https://www.youtube.com/watch?v=IvYUaGfUesM",
    },
    observedTime: 1583430803341,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,landuse",
  },
  {
    id: 7,
    title: "Saskatchewan Glacier",
    description:
      "The Saskatchewan Glacier is over 400 m thick and 13 km long.  It covers and area of 30 square km.",
    subtype: "iceguardian",
      coordinates: [-117.18213854538482, 52.14492598578525],
    media: {
      type: "image",
      status: "Unoptimized",
      url: "obs_7cb139a9-71f7-4ce3-a0ce-701e1671b615.jpg",
    },
    observedTime: 1583771591244,
  },
  {
    id: 8,
    title: "Why are some lakes blue?",
    description:
      "Peter Lemieux of IceWalks talks about what makes some lakes blue.",
    subtype: "iceguardian",
      coordinates: [-116.37980467358389, 51.59450853912091],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/39f0c26ff0e544e8884d5a1778887b84/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/293b6029-d5c7-4849-a220-4a42283a2f07/thumbnails/obs_709aae70-14a4-4c3b-bc4d-05aebd62b4f7_tumb.0000013.jpg",
      shareUrl: "https://www.youtube.com/watch?v=KXSVsc1t8OQ",
    },
    observedTime: 1583435951778,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,landuse",
  },
  {
    id: 9,
    title: "Rae Glacier",
    description: "Headwaters of the Elbow River in Kananaskis.",
    subtype: "iceguardian",
    coordinates: [-114.97514063471081, 50.62323823266564],
    media: {
      type: "image",
      status: "Unoptimized",
      url: "obs_cc6abee8-ece0-4054-a529-540b049e564e.jpg",
    },
    observedTime: 1583771828027,
  },
  {
    id: 10,
    title: "Dr. Ali Criscitiello on the importance of glaciers",
    description:
      "Why are glaciers important?  Dr. Ali from the Ice Core Lab, University of Alberta",
    subtype: "iceguardian",
      coordinates: [-113.52474624314297, 53.525506187851704],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/cc1f053af987409ba8cee10614273e38/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/09f0fac4-de18-4c6a-a045-783ba063c4f0/thumbnails/obs_cff895b3-1701-407d-b743-b3d6326443ba_tumb.0000006.jpg",
      shareUrl: "https://www.youtube.com/watch?v=HOhflZn8cqo",
    },
    observedTime: 1582915332165,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,landuse",
  },
  {
    id: 11,
    title: "How chemicals trapped in glacier ice get into our water",
    description:
      "Dr. Criscitiello discusses why we are seeing banned chemicals in our drinking water.",
    subtype: "iceguardian",
      coordinates: [-117.26164538142712, 52.18516873238073],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/66663357340b4fedb28e49c6ea05a360/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/82b1ec7b-42c4-4ce4-89f4-e72ac43eb94c/thumbnails/obs_15795177-357c-4a5f-895c-c96c2e84b2e4_tumb.0000011.jpg",
      shareUrl: "https://www.youtube.com/watch?v=2ULp_NlGUHE",
    },
    observedTime: 1582915332165,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,persistentchemicals,ddt,pfc,ozonelayer",
  },
  {
    id: 12,
    title: "Forest Fires and Glaciers",
    description:
      "Dr. Criscitiello discusses how forest fires affect Alberta Glaciers.",
    subtype: "iceguardian",
      coordinates: [-117.06003175787251, 52.28233105288666],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/9f6012c81b594265a1f9c91091b77a3f/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/46fdc7ef-1ad0-43ec-8790-0c7045c5fbc3/thumbnails/obs_84d7a2a8-f86c-4e00-8d81-91ef031412c3_tumb.0000018.jpg",
      shareUrl: "https://www.youtube.com/watch?v=f2wUf95774k",
    },
    observedTime: 1582915332165,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,landuse,abforestfires,forestfires,glaciermelt",
  },
  {
    id: 13,
    title: "Dr. Ali Criscitiello and her research",
    description:
      "Dr. Criscitielllo talks about her research at the Canadian Ice Core Lab, University of Alberta",
    subtype: "iceguardian",
      coordinates: [-113.52477878518526, 53.525654500022085],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/5abdb83044f944428927fc1a38e8db9c/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/75a19241-4149-455f-b43b-91a41920ac28/thumbnails/obs_88cdd5c9-8aa9-4c48-870b-3791889c946f_tumb.0000042.jpg",
      shareUrl: "https://www.youtube.com/watch?v=VIt0NySXoGM",
    },
    observedTime: 1582915332165,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,landuse",
  },
  {
    id: 14,
    title: "Where does water from Mount Snowdome go?",
    description:
      "Dr. Peter Lemieux discusses the reach of the water from the Columbia Icefield.",
    subtype: "iceguardian",
      coordinates: [-117.3168398896562, 52.187569165046824],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/9f910ab7196f4aefb7b881c20d1d2fae/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/7c3e031d-624b-4383-bca0-1566a980c5a4/thumbnails/obs_b10c9053-9433-43ac-8290-8e643250149d_tumb.0000020.jpg",
      shareUrl: "https://www.youtube.com/watch?v=ZS8TG4_q24A&t=3s",
    },
    observedTime: 1582915332165,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,landuse",
  },
  {
    id: 15,
    title:
      "Persistent Chemicals - what are they and how do they get into our water?",
    description:
      "Dr. Criscitiello discusses what persistent chemicals are and how they get into our drinking water.",
    subtype: "iceguardian",
      coordinates: [-113.89298081809721, 53.35688189668551],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/02e7e6aeb5f14e5b99b98a9804fc80e9/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/eacfe64c-ddd5-4a28-b1b3-c8b6bca8234f/thumbnails/obs_f22d390a-3485-415f-8740-d15791524344_tumb.0000014.jpg",
      shareUrl: "https://www.youtube.com/watch?v=dlIX3EisMpo",
    },
    observedTime: 1582915332165,
    hashtags:
      "abglaciers,waterquality,abwater,persistentchemicals,ddt,pfc,ozonelayer",
  },
  {
    id: 16,
    title: "Technology used to Study Ice Cores",
    description:
      "Dr. Criscitiello talks about measuring Oxygen isotopes using the Picarro machine and what we can learn from their concentrations.",
    subtype: "iceguardian",
      coordinates: [-113.52479686410112, 53.525355725694595],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/f68beb3d8ec74cfc8cad61b64277978a/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/54643b94-82a5-4a5f-a25a-1dada676b28b/thumbnails/obs_9d106e72-1121-4e26-b06d-cd0c60e95ae7_tumb.0000032.jpg",
      shareUrl: "https://www.youtube.com/watch?v=pHCFmnNBeL0",
    },
    observedTime: 1582915332165,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,piccaro,isotopes,glaciermelt",
  },
  {
    id: 26,
    title: "How Glaciers Move",
    description: "Peter Lemieux of Icewalks explains how glaciers move",
    subtype: "iceguardian",
    coordinates: [-117.25353892829338, 52.1953692347285],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/ea5b6edc33f84f61afc20af4ab255cf8/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/a18a5cea-79e7-48df-bfc7-db46c8e20978/thumbnails/obs_baff24ea-edad-4cce-b8b2-5e1667464453_tumb.0000006.jpg",
      shareUrl: "https://www.youtube.com/watch?v=Iy6sdst_Nnc",
    },
    observedTime: 1579725402764,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,landuse,glaciermovement,glaciermelt",
  },
  {
    id: 27,
    title: "Where was the Glacier in 1982 and what is lateral moraine?",
    description:
      "Peter Lemieux explains how far the glacier has retreated since 1982 and how he knows where it was.  Also, what is lateral moraine?",
    subtype: "iceguardian",
      coordinates: [-117.2340701260674, 52.210606844786696],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/923b56df41114454a3512bf75db1f3a2/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/a360ecfa-fbe4-4fa7-9ee0-6d29c3daf898/thumbnails/obs_16fa3377-4dfb-48ec-8410-c0f2459cd9c1_tumb.0000009.jpg",
      shareUrl: "https://www.youtube.com/watch?v=xYQocIUXHQU",
    },
    observedTime: 1579725402764,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,landuse,glaciermovement,glaciermelt",
  },
  {
    id: 28,
    title: "Where was the Glacier in 2006?",
    description:
      "Peter Lemieux explains how much the glacier has receded since 2006 and how he knows!",
    subtype: "iceguardian",
      coordinates: [-117.23322956237571, 52.20997134574753],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/05d8d1851e0c4622920cc37132e2044c/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/93ceec42-bbe2-445b-a8fe-cd7b937dea97/thumbnails/obs_30f32002-8027-458d-bfbf-49cd86ebb463_tumb.0000016.jpg",
      shareUrl: "https://www.youtube.com/watch?v=NCKgmaIkIm0",
    },
    observedTime: 1579725402764,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,landuse,glaciermovement,glaciermelt",
  },
  {
    id: 29,
    title: "Where will the Athabasca glacier be in 30 years?",
    description:
      "Peter Lemieux estimates where the glacier will be in 30 years.",
    subtype: "iceguardian",
      coordinates: [-117.23969789051822, 52.202521468247],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/27aa40169ae441138a6fcf43652cbe3e/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/e9f439e3-768d-49e8-ac24-ad2e3a044c3c/thumbnails/obs_0982b57c-d260-4570-9478-6a38a365ffa9_tumb.0000011.jpg",
      shareUrl: "https://www.youtube.com/watch?v=HXQLMjomCes",
    },
    observedTime: 1579725402764,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,landuse,glaciermovement,glaciermelt",
  },
  {
    id: 30,
    title: "Evidence of Glaciers - Striations",
    description: "Peter Lemieux of Icewalks explains what striations are.",
    subtype: "iceguardian",
    coordinates: [-117.22597381643935, 52.21608581756843],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/b249cfb689c64eb3b9221e5037ec5a74/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/8bd09403-0b61-40a3-a595-d4f5723bd38b/thumbnails/obs_0c843e48-cfb2-49b6-a517-5e4882a3cc99_tumb.0000020.jpg",
      shareUrl: "https://www.youtube.com/watch?v=sKF4M0v1GdM",
    },
    observedTime: 1579725402764,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,landuse,glaciermovement,glaciermelt",
  },
  {
    id: 31,
    title: "Seasonal Change on the Glacier",
    description:
      "Peter Lemieux describes what how the look of the glacier changes with the seasons.",
    subtype: "iceguardian",
      coordinates: [-117.23842566995793, 52.204088983189166],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/2720aec2f8ab4ece83a94110e2d883ba/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/cc549af5-f1c9-4dcb-b901-479bd9e1d34e/thumbnails/obs_2112a06d-1b75-483b-a9b8-748ac90b1a32_tumb.0000010.jpg",
      shareUrl: "https://www.youtube.com/watch?v=KCKew0PiqOc",
    },
    observedTime: 1579725402764,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,landuse,glaciermovement,glaciermelt",
  },
  {
    id: 32,
    title: "How crevasses form",
    description: "Peter Lemieux explains how crevasses form",
    subtype: "iceguardian",
    coordinates: [-117.24376105703284, 52.198326568159246],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/e20276786a8c4f468d5ef70dac7de076/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/abc02ad4-803a-46a3-a40a-30f67c5deaab/thumbnails/obs_4e61c978-3fd4-41c1-8d01-637b2a629580_tumb.0000011.jpg",
      shareUrl: "https://www.youtube.com/watch?v=QbotXyLZXiQ",
    },
    observedTime: 1579725402764,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,landuse,glaciermovement,glaciermelt",
  },
  {
    id: 33,
    title: "River in the Ice",
    description: "River in the Ice.... slow motion",
    subtype: "iceguardian",
    coordinates: [-117.25619898242563, 52.189994500594196],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/0d946d176661421b997d5c9bdf5a293b/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/a11b3057-9ac1-4a47-84ec-38d4ee089d6e/thumbnails/obs_e0dbb54c-0556-4dfa-a7a4-1126fbc1d3b7_tumb.0000004.jpg",
      shareUrl: "",
    },
    observedTime: 1579725402764,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,landuse,glaciermovement,glaciermelt",
  },
  {
    id: 34,
    title: "Cutting Ice Cores for Analysis",
    description:
      "Dr. Alison Criscitiello demonstrated how she cuts ice cores for analysis at the Canadian Ice Core Lab at the University of Alberta.",
    subtype: "iceguardian",
      coordinates: [-113.52477935317614, 53.525743768498245],
    media: {
      type: "video",
      status: "Complete",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/46756ab0b2724c3cbbfbadeeb01f55e3/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/acb56cc0-edb4-4cb1-ae5e-d8504ee154d0/thumbnails/obs_9877d8ac-26fc-435d-8e9d-d1acad385aae_tumb.0000004.jpg",
      shareUrl: "",
    },
    observedTime: 1579725402764,
    hashtags:
      "abglaciers,climatechange,waterquality,abwater,columbiaicefield,athabascaglacier,landuse,glaciermovement,glaciermelt",
  },
  ];

  export default guardiansOfTheIceObservations;