<template>
  <md-card>
    <md-card-header class="md-card-header-text md-card-header-primary">
      <div class="card-text">
        <h4 class="title">
          My Students
        </h4>
        <p
          v-if="students.length === 0"
          class="category"
        >
          No students have linked their profiles to you yet.
        </p>
        <p
          v-else
          class="category"
        >
          The following students have identified you as their teacher
          <br>
          <md-chip class="student-chip">
            <i class="fas fa-user" />
            {{ students.length }} {{ "student" | pluralize(students.length) }}
          </md-chip>
        </p>
      </div>
    </md-card-header>
    <md-card-content>
      <div class="md-layout">
        <div
          v-if="students.length !== 0"
          class="md-layout-item md-size-100"
        >
          <v-client-table
            :columns="columns"
            :data="students"
            :options="options"
          >
            <div
              slot="email"
              slot-scope="props"
            >
              <a
                v-if="!props.row.noemail"
                target="_blank"
                :href="`mailto:${props.row.email}`"
              >{{ props.row.email }}</a>
            </div>
            <div
              v-if="props.row.profileManager == teacher"
              slot="profileManager"
              slot-scope="props"
            >
              <md-button
                class="md-dense md-info"
                @click="updatePassword(props.row.UserName, `${props.row.firstname} ${props.row.lastname}`)"
              >
                <md-icon>key</md-icon> Reset Password
              </md-button>
            </div>
            <div
              v-if="isMdXSmall"
              slot="child_row"
              slot-scope="props"
            >
              Username: {{ props.row.UserName }}
              <br>
              <div v-if="!props.row.noemail">
                Email:
                <a
                  target="_blank"
                  :href="`mailto:${props.row.email}`"
                >{{
                  props.row.email
                }}</a>
              </div>
              <br>
              Points: {{ props.row.points }}
              <div
                v-if="props.row.profileManager == teacher"
              >
                <br>
                <md-button
                  class="md-dense md-info"
                  @click="updatePassword(props.row.UserName, `${props.row.firstname} ${props.row.lastname}`)"
                >
                  <md-icon>key</md-icon> Reset Password
                </md-button>
              </div>
            </div>
          </v-client-table>
        </div>
        <div class="md-layout-item md-size-100">
          <md-button
            id="TeacherNewStudentsBtn"
            class="md-raised md-success"
            @click="launchStudentWizard"
          >
            <md-icon>add</md-icon> Add Students
          </md-button>
          <p>
            Your students can link to you by selecting you as their teacher in
            their profile.
          </p>
          <p>
            You and your students will have to register for accounts on Alberta Tomorrow.  There are 3 ways to do this, 2 requiring email address confirmation by students.
          </p>
          <ul>
            <li>Students sign up using their own username and password.</li>
            <li>Bulk import by teacher using student email addresses</li>
            <li>Bulk import by teacher with no email addresses (if you use this option only the teacher can reset passwords)</li>
          </ul>
          <p>
            *Some school divisions block emails from outside domains. If you are unsure if they do, do not provide an email, or contact your school division IT department to allow emails from @albertatomorrow.ca.
          </p>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import { ResponsiveLayoutHelpers } from "@/pages/Dashboard/Components/mixins/ResponsiveLayoutHelpers";
export default {
  name: "DashboardStudents",

  mixins: [ResponsiveLayoutHelpers],

  props: {
    students: {
      type: Array,
      required: true,
    },
    teacher: {
      type: String,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    columns() {
      // table columns, list of columns to show - show fewer columns on smaller screens
      const columns = ["lastname", "firstname"];
      if (!this.isMdXSmall) {
        columns.push("UserName", "email", "points", "profileManager");
      }
      return columns;
    },
    options() {
      // table options
      const options = {
        headings: {
          lastname: "Last Name",
          firstname: "First Name",
          profileManager: "",
        },
        columnsClasses: {
          points: "align-right",
        },
        sortable: ["lastname", "firstname"],
        filterable: ["lastname", "firstname"],
        orderBy: {
          column: "lastname",
        },
        multiSorting: {
          lastname: [
            {
              column: "firstname",
              matchDir: true,
            },
          ],
          firstname: [
            {
              column: "lastname",
              matchDir: true,
            },
          ],
        },
        showChildRowToggler: true,
        uniqueKey: "UserName",
      };
      if (!this.isMdXSmall) {
        // phone screens have fewer headings, bigger screens have all
        options.headings.UserName = "Username";
        options.headings.email = "Email";
        options.headings.points = "Points";
        options.sortable.push("UserName", "email", "points");
        options.filterable.push("UserName");
        options.showChildRowToggler = false;
      }
      return options;
    },
  },

  beforeMount() {
    // initialize students
    this.initStudents();
  },

  methods: {
    allowProfileUpdate(student) {
      return 'profileManager' in student && student.profileManager == this.teacher ? true : false;
    },
    initStudents() {
      // organize a few things about students
      // make sure all users have points (set to 0 if not in the object)
      this.students.forEach((student) => {
        if (!("points" in student)) {
          student.points = 0;
        }
      });
    },
    launchStudentWizard() {
      // launch bulk student sign-up wizard
      this.$router.push({ name: "Add Students" });
    },
    updatePassword(UserName, studentName) {
      this.$emit('on-password-update', UserName, studentName);
    }
  },
};
</script>

<style lang="scss" scoped>
.md-card-no-elevation {
  background: transparent;
  box-shadow: none;
}
.student-chip {
  background-color: darken($brand-primary, 10%) !important;
}
.student-chip i {
  height: 16px !important;
  line-height: 16px !important;
  width: 16px !important;
}
/deep/ .VueTables__search__input {
  margin-left: 10px;
}
/deep/ .VueTables__limit-field label {
  padding-right: 10px;
}
/deep/ .VueTables__table {
  width: 100%;
  margin-top: 12px;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}
/deep/ .VueTables__heading {
  font-size: 16px;
  font-weight: 300;
}
/deep/ .VueTables__sort-icon {
  padding-left: 8px;
}
/deep/ .fa-sort {
  color: $gray-light;
}
/deep/ .align-right {
  text-align: right;
}
/deep/ .VueTables__child-row-toggler {
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}

/deep/ .VueTables__child-row-toggler--closed::before {
  content: "+";
}

/deep/ .VueTables__child-row-toggler--open::before {
  content: "-";
}
</style>
