<template>
  <div>
    <!-- general observations fields -->
    <div v-if="observationType === 'general'">
      <h5 class="info-text">
        Can you describe your observation with some more details?
      </h5>
      <!-- all the general observations are multi-line text areas... loop through them -->
      <div
        v-for="(item, key) in generalFields"
        :key="key"
        class="md-layout-item md-size-100"
      >
        <md-field
          :class="[
            { 'md-valid': !errors.has('key') && touched[key] },
            { 'md-error': errors.has('key') },
          ]"
        >
          <label>{{ generalFields[key].label }}</label>
          <md-textarea
            v-model="generalDetails[key]"
            v-validate="modelValidations[key]"
            name="key"
            :disabled="wizardLocked"
            required
          />
          <slide-y-down-transition>
            <md-icon
              v-show="errors.has('key')"
              class="error"
            >
              close
            </md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              v-show="!errors.has('key') && touched[key]"
              class="success"
            >
              done
            </md-icon>
          </slide-y-down-transition>
          <span class="md-helper-text md-helper-text-textarea">{{
            item.helperText
          }}</span>
        </md-field>
      </div>
    </div>
    <!-- water observations fields -->
    <div v-else>
      <!-- bodyOfWater -->
      <div class="md-layout-item md-size-100">
        <md-field
          :class="[
            { 'md-valid': !errors.has('bodyOfWater') && touched['bodyOfWater'] },
            { 'md-error': errors.has('bodyOfWater') },
          ]"
        >
          <label>{{ waterFields.bodyOfWater.label }}</label>
          <md-input
            v-model="waterDetails.bodyOfWater"
            v-validate="modelValidations.bodyOfWater"
            name="bodyOfWater"
            :disabled="wizardLocked"
          />
          <slide-y-down-transition>
            <md-icon
              v-show="errors.has('bodyOfWater')"
              class="error"
            >
              close
            </md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              v-show="!errors.has('bodyOfWater') && touched['bodyOfWater']"
              class="success"
            >
              done
            </md-icon>
          </slide-y-down-transition>
          <span class="md-helper-text">{{ waterFields.bodyOfWater.helperText }}</span>
        </md-field>
      </div>
      <!-- bodyOfWaterType -->
      <div
        class="md-layout-item md-size-100"
      >
        <md-field>
          <label>{{ waterFields.bodyOfWaterType.label }}</label>
          <md-select
            v-model="waterDetails.bodyOfWaterType"
            name="bodyOfWaterType"
          >
            <md-option
              v-for="item in waterBodyTypes"
              :key="item[0]"
              :value="item[0]"
            >
              {{ item[1] }}
            </md-option>
          </md-select>
          <slide-y-down-transition>
            <md-icon
              v-show="errors.has('bodyOfWaterType')"
              class="error"
            >
              close
            </md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              v-show="!errors.has('bodyOfWaterType') && touched['bodyOfWaterType']"
              class="success"
            >
              done
            </md-icon>
          </slide-y-down-transition>
          <span class="md-helper-text">{{ waterFields.bodyOfWaterType.helperText }}</span>
        </md-field>
      </div>
      <!-- locationName -->
      <div class="md-layout-item md-size-100">
        <md-field
          :class="[
            { 'md-valid': !errors.has('locationName') && touched['locationName'] },
            { 'md-error': errors.has('locationName') },
          ]"
        >
          <label>{{ waterFields.locationName.label }}</label>
          <md-input
            v-model="waterDetails.locationName"
            v-validate="modelValidations.locationName"
            name="locationName"
            :disabled="wizardLocked"
          />
          <slide-y-down-transition>
            <md-icon
              v-show="errors.has('locationName')"
              class="error"
            >
              close
            </md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              v-show="!errors.has('locationName') && touched['locationName']"
              class="success"
            >
              done
            </md-icon>
          </slide-y-down-transition>
          <span class="md-helper-text">{{ waterFields.locationName.helperText }}</span>
        </md-field>
      </div>
      <!-- locationDescription -->
      <div class="md-layout-item md-size-100">
        <md-field
          :class="[
            { 'md-valid': !errors.has('locationDescription') && touched['locationDescription'] },
            { 'md-error': errors.has('locationDescription') },
          ]"
        >
          <label>{{ waterFields.locationDescription.label }}</label>
          <md-textarea
            v-model="waterDetails.locationDescription"
            v-validate="modelValidations.locationDescription"
            name="locationDescription"
            :disabled="wizardLocked"
          />
          <slide-y-down-transition>
            <md-icon
              v-show="errors.has('locationDescription')"
              class="error"
            >
              close
            </md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              v-show="!errors.has('locationDescription') && touched['locationDescription']"
              class="success"
            >
              done
            </md-icon>
          </slide-y-down-transition>
          <span class="md-helper-text">{{ waterFields.locationDescription.helperText }}</span>
        </md-field>
      </div>
      <!-- weather -->
      <h5 class="info-text">
        Weather Conditions
      </h5>
      <div
        v-for="item in ['weatherNow', 'weatherPast24h']"
        :key="item"
        class="md-layout-item md-size-100"
      >
        <md-field>
          <label>{{ waterFields[item].label }}</label>
          <md-select
            :id="item"
            v-model="waterDetails[item]"
            :name="item"
            multiple
          >
            <md-option
              v-for="_item in weatherConditionsOptions"
              :key="_item"
              :value="_item"
            >
              {{ _item }}
            </md-option>
          </md-select>
          <slide-y-down-transition>
            <md-icon
              v-show="errors.has(item)"
              class="error"
            >
              close
            </md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              v-show="!errors.has(item) && touched[item]"
              class="success"
            >
              done
            </md-icon>
          </slide-y-down-transition>
          <span class="md-helper-text">{{ waterFields[item].helperText }}</span>
        </md-field>
      </div>
      <!-- water measurements -->
      <h5 class="info-text">
        What measurements did you record for your water quality observation?
      </h5>
      <!-- all other water observations are numeric inputs... loop through them -->
      <div
        v-for="(item, key) in waterFieldsNumeric"
        :key="key"
        class="md-layout-item md-size-100"
      >
        <md-field
          :class="[
            { 'md-valid': !errors.has(key) && touched[key] },
            { 'md-error': errors.has(key) },
          ]"
        >
          <label>{{ item.label }}</label>
          <md-input
            v-model="waterDetails[key]"
            v-validate="modelValidations[key]"
            :name="key"
            :disabled="wizardLocked"
          />
          <slide-y-down-transition>
            <md-icon
              v-show="errors.has(key)"
              class="error"
            >
              close
            </md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              v-show="!errors.has(key) && touched[key]"
              class="success"
            >
              done
            </md-icon>
          </slide-y-down-transition>
          <span class="md-helper-text">{{ item.helperText }}</span>
        </md-field>
      </div>
    </div>
  </div>
</template>

<script>
import { Observation } from "./mixins/Observation";
import { SlideYDownTransition } from "vue2-transitions";
export default {
  name: "ObservationNewDetails",

  components: {
    SlideYDownTransition,
  },
  mixins: [Observation],

  props: {
    observationType: {
      type: String,
      default: "general",
    },
    wizardLocked: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // generalFields: {}, // comes from Observations mixin
      // waterFields: {}, // comes from Observations mixin
      generalDetails: {
        // general fields
        descriptionOfEcosystem: "",
        humanActivity: "",
        weatherConditions: "",
      },
      waterDetails: {
        // water fields
        totalPhosphates: "",
        nitrates: "",
        waterTemp: "",
        dissolvedOxygen: "",
        fecalColiform: "",
        bod: "",
        ph: "",
        turbidity: "",
        bodyOfWater: "",
        bodyOfWaterType: "",
        weatherNow: [],
        weatherPast24h: [],
        airTemp: "",
        alkalinity: "",
        hardness: "",
        conductivity: "",
        chlorine: "",
        waterflow: "",
        locationName: "",
        locationDescription: "",
      },
      waterBodyTypes: [
        ["unknown", "Unknown"],
        ["river", "River"],
        ["lake", "Lake"],
        ["pond", "Pond"],
        ["creek", "Creek"],
        ["stream", "Stream"],
        ["ocean", "Ocean"],
        ["sea", "Sea"],
        ["estuary", "Estuary"],
        ["channel", "Channel"],
        ["bay", "Bay"],
        ["canal", "Canal"],
        ["wetland", "Wetland"],
        ["river_delta", "River Delta"],
        ["lagoon", "Lagoon"],
        ["inlet_or_fjord", "Inlet or Fjord"],
      ],
      weatherConditionsOptions: [
        "Sunny",
        "Cloudy",
        "Rainy",
        "Windy",
      ],
      touched: {
        descriptionOfEcosystem: false,
        humanActivity: false,
        weatherConditions: false,
        totalPhosphates: false,
        nitrates: false,
        waterTemp: false,
        dissolvedOxygen: false,
        fecalColiform: false,
        bod: false,
        ph: false,
        turbidity: false,
        locationName: false,
        locationDescription: false,
        bodyOfWater: false,
        bodyOfWaterType: false,
        weatherNow: false,
        weatherPast24h: false,
        airTemp: false,
        alkalinity: false,
        hardness: false,
        conductivity: false,
        chlorine: false,
        waterflow: false,
      },
      modelValidations: {
        locationName: {
          required: true,
        },
        locationDescription: {
          required: true,
        },
        bodyOfWater: {
          required: true,
        },
        bodyOfWaterType: {
          required: true,
        },
        weatherNow: {
          required: true,
        },
        weatherPast24h: {
          required: true,
        },
        totalPhosphates: {
          decimal: 4,
          min_value: 0,
        },
        nitrates: {
          decimal: 4,
          min_value: 0,
        },
        chlorine: {
          decimal: 4,
          min_value: 0,
        },
        waterflow: {
          decimal: 4,
          min_value: 0,
        },
        waterTemp: {
          decimal: 4,
          min_value: -10,
          max_value: 100,
        },
        dissolvedOxygen: {
          decimal: 4,
          min_value: 0,
        },
        fecalColiform: {
          decimal: 4,
          min_value: 0,
        },
        bod: {
          decimal: 4,
          min_value: 0,
        },
        ph: {
          decimal: 2,
          min_value: 0,
          max_value: 14,
        },
        turbidity: {
          decimal: 2,
          min_value: 0,
        },
        airTemp: {
          decimal: 4,
          min_value: -30,
          max_value: 50,
        },
        alkalinity: {
          decimal: 4,
          min_value: 0,
        },
        hardness: {
          decimal: 4,
          min_value: 0,
        },
        conductivity: {
          decimal: 4,
          min_value: 0,
        },
      },
    };
  },

  computed: {
    waterFieldsNumeric() {
      return Object.fromEntries(Object.entries(this.waterFields).filter(([key]) => !this.waterFieldsNonNumeric.includes(key)));
    },
  },

  watch: {
    descriptionOfEcosystem() {
      this.touched.descriptionOfEcosystem = true;
    },
    humanActivity() {
      this.touched.humanActivity = true;
    },
    weatherConditions() {
      this.touched.weatherConditions = true;
    },
    totalPhosphates() {
      this.touched.totalPhosphates = true;
    },
    nitrates() {
      this.touched.nitrates = true;
    },
    waterTemp() {
      this.touched.waterTemp = true;
    },
    dissolvedOxygen() {
      this.touched.dissolvedOxygen = true;
    },
    fecalColiform() {
      this.touched.fecalColiform = true;
    },
    bod() {
      this.touched.bod = true;
    },
    ph() {
      this.touched.ph = true;
    },
    turbidity() {
      this.touched.turbidity = true;
    },
    bodyOfWater() {
      this.touched.bodyOfWater = true;
    },
    bodyOfWaterType() {
      this.touched.bodyOfWaterType = true;
    },
    weatherNow() {
      this.touched.weatherNow = true;
    },
    weatherPast24h() {
      this.touched.weatherPast24h = true;
    },
    airTemp() {
      this.touched.airTemp = true;
    },
    alkalinity() {
      this.touched.alkalinity = true;
    },
    hardness() {
      this.touched.hardness = true;
    },
    conductivity() {
      this.touched.conductivity = true;
    },
    chlorine() {
      this.touched.chlorine = true;
    },
    waterflow() {
      this.touched.waterflow = true;
    },
  },

  methods: {
    validate() {
      // some required field validations
      // no validations are required for 'general'
      let hasErrors = false;
      if (this.observationType === "water") {
        // water observations
        // all fields should be numbers
        Object.keys(this.waterDetails).forEach((_field) => {
          if (this.errors.has(_field)) {
            hasErrors = true;
            return this.numberError(this.waterFields[_field].label);
          }
        });
      }

      if (!hasErrors) {
        return new Promise((resolve) => {
          resolve(true);
        }).then((res) => {
          this.onSuccess(res);
          return res;
        });
      }
    },
    numberError(fieldName) {
      // alert an error for a number input field
      return new Promise((resolve) => {
        this.errorAlert(
          `Please enter only numbers and decimals for ${fieldName} and be sure the value you are entering is in an acceptable range.`
        );
        resolve(false);
      });
    },
    onSuccess(res) {
      // update data in parent and Increment the wizard;
      const _data = {};
      if (this.observationType === "general") {
        _data.details = { ...this.generalDetails };
      } else {
        _data.details = { ...this.waterDetails };
      }
      this.$emit("on-validated", res, _data);
    },
  },
};
</script>
<style lang="scss" scoped>
.md-helper-text-textarea {
  // bump the helper text down a little further
  transform: translate(0, 0.45em);
}
</style>