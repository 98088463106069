// teacher resources, lesson plans etc
/*
sample resource
  {
    title: "",
    key: "", // make sure this key is unique
    theme: "", // theme determines how resources are organized and filtered on the teacher resources page. Does not need to be unique.
    description: "",
    approximateTime: "",
    provider: ["name", "link"], // provider name and link
    files: [], // list of files to include - suggested types are pdf, zip.  Each file should be an array in the form ["title", "filename_or_url"].  If filename, file is expected to be located at BUCKET/resources/ where bucket is the AWS s3 bucket defined in aws-exports.js as userfiles
    grades: [""], // list of grades as strings - should match grades from RegionSchools.gradeOptions
    subjects: [""], // list of subjects as strings - should match curriculum options from RegionSchools.curriculumOptions
    tags: [""],
    learningOutcomes: [""],
    videos: [ // optional list of videos
      {
        title: "",
        description: "",
        media: {
          type: "video",
          url:
            "",
          thumbUrl:
            ""
        }
      }
    ],
    relatedUrls: [] // optional list of external urls. Each link should be an array in the form ["title", "url"]
  }
*/

import brbcVideos from "./brbc_360_videos";

const RegionResources = [
  {
    title: "Introduction to Glaciers",
    key: "IntroToGlaciers",
    theme: "Glaciers/Watersheds",
    description:
      "In this lesson students will be introduced to the glaciers of Alberta, glacier terminology, meet a glaciologist from the University of Alberta and identify the effects of human activity on glaciers.",
    approximateTime: "40 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Glaciers in Alberta (Google Slides)",
        "https://docs.google.com/presentation/d/1rBX6NS-xWfHD3HxAV341q1ZC4wZV5FvNaUdsLi94ptg/view#slide=id.p",
      ],
    ],
    grades: ["5", "7", "8", "9", "10", "11"],
    subjects: ["Science", "Biology"],
    tags: [
      "Wetland Ecosystems",
      "Interactions and Ecosystems",
      "Freshwater and Satlwater Systems",
      "Environmental Chemistry",
      "Energy Flow in Global System",
      "Energy and Matter in the Biosphere",
      "Investigating Matter and Energy",
      "Biology 20",
      "Science 14",
      "water",
      "wetland",
      "glaciers",
      "human activity",
      "terminology",
    ],
    learningOutcomes: [
      "develop an understanding of what glaciologist do",
      "identify glacier location in Alberta",
      "be introduced to glacier vocabulary",
      "learn what Dr. Ali Criscitiello does at the Ice Core Lab",
      "identify the effects of human activity on glaciers",
    ],
    videos: [
      {
        title: "This is the Athabasca glacier",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=KF1JBsGdyZY",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/thisistheathabascaglacier.jpg",
        },
      },
      {
        title: "Where do glaciers form",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=Y8IaKMp4dAk",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/wheredoglaciersform.jpg",
        },
      },
      {
        title: "How Glaciers Move",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=Iy6sdst_Nnc",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/howglaciersmove.jpg",
        },
      },
      {
        title: "Colour of the Ice",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=7Ld_jQCuJzE",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/coloroftheice.jpg",
        },
      },
      {
        title: "Seasonal Change on the Glacier",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=KCKew0PiqOc",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/seasonalchangeontheglacier.jpg",
        },
      },
      {
        title: "What is a Moraine?",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=nFzRuDBiB28",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/whatisamoraine.jpg",
        },
      },
      {
        title: "How big is the Athabasca Glacier?",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=qq7vwd_ahzU",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/howbigistheathabascaglacier.jpg",
        },
      },
      {
        title: "How crevasses form",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=QbotXyLZXiQ",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/howcrevassesform.jpg",
        },
      },
      {
        title: "Striations on the Rocks",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=sKF4M0v1GdM",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/striationsontherocks.jpg",
        },
      },
      {
        title: "Are people affecting the glaciers?",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=Ns7BiGb3cMQ",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/arepeopleaffectingtheglaciers.jpg",
        },
      },
      {
        title: "Growth and Attrition",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=uATZEvCjoT4",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/growthandattrition.jpg",
        },
      },
      {
        title: "Why Should We Protect Glaciers?",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=LKtGm_ZxZyU",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/whyshouldweprotectglaciers.jpg",
        },
      },
    ],
    relatedUrls: [
      [
        "U of A Ice Core Lab",
        "https://www.ualberta.ca/science/research-and-teaching/research/ice-core-archive/index.html",
      ],
    ],
  },
  {
    title: "Love it or Lose It: Alberta Glacier Edition",
    key: "LoveItOrLoseItGlaciers",
    theme: "Glaciers/Watersheds",
    description:
      "Students investigate the location of glaciers in Alberta and observe how they have changed over time. Students learn about how climate change is affecting our glaciers and come up with an action plan to help preven the further loss of our glaciers.",
    approximateTime: "30 min plus project time",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Love it or Lose it: Alberta Glacier Edition",
        "Love_it_or_Lose_it__Alberta_Glacier_Edition.pdf",
      ],
    ],
    grades: ["4", "5", "7", "8", "10", "11"],
    subjects: ["Social Studies", "Science", "Biology"],
    tags: [
      "A Sense of the Land",
      "Physical Geography of Canada",
      "Wetland Ecosystems",
      "Interactions and Ecosystems",
      "Freshwater and Satlwater Systems",
      "Energy Flow in Global System",
      "Energy and Matter Exchange",
      "Grade 10 Science",
      "Biology 20",
      "glaciers",
      "climate change",
      "action plan",
      "personal actions",
      "glacier loss",
    ],
    learningOutcomes: [
      "identify glacier location in Alberta",
      "observe change in the glaciers over time",
      "Investigate how climate change is affecting the Alberta’s Glaciers",
      "be challenged to develop an action plan of how they can help prevent further loss of the glaciers",
    ],
    videos: [
      {
        title: "Every year its farther to get to work",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=efoZxoc0_RE",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/everyyearitsfurthertowork.jpg",
        },
      },
      {
        title: "Where will the glacier be in 30 years?",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=HXQLMjomCes",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/wherewilltheglacierbein30yrs.jpg",
        },
      },
      {
        title: "Growth and Attrition",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=uATZEvCjoT4",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/growthandattrition.jpg",
        },
      },
      {
        title: "Where was the glacier in 1982 and what is a lateral moraine?",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=xYQocIUXHQU",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/wherewastheglacierin1982.jpg",
        },
      },
      {
        title: "Where was the Athabasca glacier in 2006?",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=NCKgmaIkIm0",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/wherewastheglacierin2006.jpg",
        },
      },
      {
        title: "Why Should We Protect Glaciers?",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=LKtGm_ZxZyU",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/whyshouldweprotectglaciers.jpg",
        },
      },
      {
        title: "Are people affecting the glaciers?",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=Ns7BiGb3cMQ",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/arepeopleaffectingtheglaciers.jpg",
        },
      },
      {
        title: "What can we do to protect Alberta glaciers?",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=iHKg1WAQ3h8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/whatcanwedotoprotectglaciers.jpg",
        },
      },
    ],
    relatedUrls: [
      ["Mountain Legacy Project", "http://mountainlegacy.ca/"],
      [
        "Guardians of the Ice",
        "https://guardiansoftheice.com/the-columbia-icefield/",
      ],
    ],
  },
  {
    title: "Ice Core Basics",
    key: "IceCoreBasics",
    theme: "Glaciers/Watersheds",
    description:
      "The students will watch videos of a glaciologist talking about how she analyzes the ice cores she collects from glaciers.",
    approximateTime: "30 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [["Ice Core Basics", "IceCoreBasics.pdf"]], // list of files to include - suggested types are pdf, zip.  Each file should be an array in the form ["title", "filename_or_url"]
    grades: ["5", "7", "8", "9", "10", "11"],
    subjects: ["Science", "Biology"],
    tags: [
      "Wetlands",
      "Interactions and Ecosystems",
      "Freshwater and Saltwater Systems",
      "Environmental Chemistry",
      "Energy Flow in Global Systems",
      "Energy and Matter in Chemical Change",
      "Energy and Matter in the Biosphere",
      "Chemical Changes",
      "Matter and Energy",
      "Biology 20",
      "Science 14",
      "oxygen isotopes",
      "cliamte change",
      "glaciers",
      "water quality",
      "persistent chemicals",
      "cfc's",
    ],
    learningOutcomes: ["understand what we can learn from ice core analysis"],
    videos: [
      // optional list of videos
      {
        title: "Oxygen Isotopes",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=YfRDNyB1XOY",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/oxygenisotopes.jpg",
        },
      },
      {
        title: "Water Consumption",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=YcibG9eybbc",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/a42e73bb-ede0-415a-b12b-a873782a69a1/thumbnails/Water Consumption v3_H264_tumb.0000039.jpg",
        },
      },
      {
        title: "Water Quality",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=qsaeCqrJ64o",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/71ac9e8a-e577-4765-9783-71eaee4c08dd/thumbnails/Water Quality v2_H264_tumb.0000063.jpg",
        },
      },
      {
        title: "What is the Picarro machine and what does it do?",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=Ps7dhpC1Gec",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/whatisthepicarromachine.jpg",
        },
      },
      {
        title: "Measuring Isotopes",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=pHCFmnNBeL0",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/measuringisotopes.jpg",
        },
      },
      {
        title: "Ion Chromatograph",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=L7F4VIAwcA0",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/ionchromatograph.jpg",
        },
      },
      {
        title:
          "How do chemicals that were trapped in Alberta glaciers get into our water?",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=2ULp_NlGUHE",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/82b1ec7b-42c4-4ce4-89f4-e72ac43eb94c/thumbnails/obs_15795177-357c-4a5f-895c-c96c2e84b2e4_tumb.0000011.jpg",
        },
      },
      {
        title: "Endocrine Disruptors",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=fjJZesxnfBo",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/endocrinedisruptors.jpg",
        },
      },
    ],
    relatedUrls: [], // optional list of external urls. Each link should be an array in the form ["title", "url"]
  },
  {
    title: "Going Going Going Gone: Climate Change and the Columbia Icefield",
    key: "GoingGoingGoneColumbiaIcefield",
    theme: "Climate Change",
    description:
      "Students will explore how climate change has affected Alberta's glaciers and do some calculations to determine the loss of Ice on the Athabasca Glacier.",
    approximateTime: "60 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [["Going Going Going Gone", "GoingGoingGoingGone.pdf"]], // list of files to include - suggested types are pdf, zip.  Each file should be an array in the form ["title", "filename_or_url"]
    grades: ["4", "5", "7", "8", "10", "11"], // list of grades as strings - should match grades from RegionSchools.gradeOptions
    subjects: ["Social Studies", "Science", "Biology", "CTS"], // list of subjects as strings - should match curriculum options from RegionSchools.curriculumOptions
    tags: [
      "Science 14",
      "Biology 20",
      "A Sense of the Land",
      "Physical Geography of Canada",
      "Wetlands",
      "Interactions and Ecosystems",
      "Freshwater and Saltwater Systems",
      "Energy Flow in Global Systems",
      "Change in Living Sysgetms",
      "Energy and Matter Exchange in the Biosphere",
      "The Changing Earth",
      "Matter and Energy in the Environment",
      "Environmental Stewarship, CTS",
      "Primary Resources, CTS",
      "glaciers",
      "climate change",
      "math",
      "ice loss",
      "calculations",
      "alberta",
      "water",
    ],
    learningOutcomes: [
      "understand seasonal variation in glacier mass",
      "understand the effect of climate change on glacier mass",
      "calculate loss of glacier ice on the Athabasca Glacier over the next 10 years.",
      "determine the loss of ice volume in Rocky Mountain Glaciers",
    ],
    videos: [
      // optional list of videos
      {
        title: "Greenhouse Gases and Biotic Carbon Storage",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=m7G3mSq2aD0",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/be03a00b-b27d-4afe-be64-332ec61b92c5/thumbnails/Greenhouse Gases v2_H264_tumb.0000039.jpg",
        },
      },
      {
        title: "Climate Change",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=Okh7vn60kxg",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/climate_change_video.jpg",
        },
      },
      {
        title: "Why are Alberta glaciers important?",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=HOhflZn8cqo",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/whyareglaciersimportant.jpg",
        },
      },
      {
        title: "Forest Fires and Glaciers",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=f2wUf95774k",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/forestfiresandglaciers.jpg",
        },
      },
    ],
    relatedUrls: [
      ["Oxygen Isotopes", "https://www.youtube.com/watch?v=YfRDNyB1XOY"],
      [
        "Athabasca Glacier Info",
        "http://cgip.wikifoundry.com/page/Athabasca+Glacier",
      ],
    ], // optional list of external urls. Each link should be an array in the form ["title", "url"]
  },
  {
    title: "Follow the Flow: Water Quality and the Columbia Icefield",
    key: "FollowTheFlow",
    theme: "Glaciers/Watersheds",
    description:
      "Students will learn what a watershed is and identify what watershed they live within. Through watching some short video interviews students will understand how climate change is resulting in persistent chemicals, some banned in Canada, are being released into the rivers from which we get our drinking water from.",
    approximateTime: "60 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [["Follow the Flow", "FollowtheFlow.pdf"]], // list of files to include - suggested types are pdf, zip.  Each file should be an array in the form ["title", "filename_or_url"]
    grades: ["5", "7", "8", "10", "11", "12"],
    subjects: ["Social Studies", "Science", "Biology", "CTS"], // list of subjects as strings - should match curriculum options from RegionSchools.curriculumOptions
    tags: [
      "Science 14",
      "Biology 20",
      "Physical Geography of Canada",
      "Wetlands Ecosystems",
      "Interactions and Ecosystems",
      "Freshwater and Saltwater Systems",
      "Environmental Chemistry",
      "Energy FLow in Global Systems",
      "Change in Living Systems",
      "Energy and Matter Exchange ",
      "Investigating Matter and Enschange in the Biosphere",
      "Investigating Matter and Energy in the Environment",
      "Agriculture 1010",
      "Environmental Stewarship 1110",
      "Environmental Stewarship 1115",
      "Environmental Stewarship 3040",
      "water",
      "watershed",
      "water quality",
      "flow",
      "glaciers",
      "water source",
      "persistent chemicals",
      "cfcs",
    ],
    learningOutcomes: [
      "identify their watershed and drainage basin",
      "find the Columbia Icefield on the map",
      "define persistent chemicals",
      "investigate factors that contribute to water quality",
      "develop an understanding of how climate change will affect the quality and of our drinking water",
    ],
    videos: [
      // optional list of videos
      {
        title:
          "Watershed Great Divide - Where does water from the Columbia Icefield go?",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=OGN943BCxAk",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/watershedgreatdivide.jpg",
        },
      },
      {
        title: "Where does water from the Snowdome go?",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=ZS8TG4_q24A",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/7c3e031d-624b-4383-bca0-1566a980c5a4/thumbnails/obs_b10c9053-9433-43ac-8290-8e643250149d_tumb.0000020.jpg",
        },
      },
      {
        title:
          "How do chemicals that were trapped in Alberta glaciers get into our water?",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=2ULp_NlGUHE",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/82b1ec7b-42c4-4ce4-89f4-e72ac43eb94c/thumbnails/obs_15795177-357c-4a5f-895c-c96c2e84b2e4_tumb.0000011.jpg",
        },
      },
      {
        title: "Persistent Chemicals in Alberta Glaciers",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=dlIX3EisMpo",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/persistentchemicals.jpg",
        },
      },
      {
        title: "Forest Fires and Glaciers",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=f2wUf95774k",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/forestfiresandglaciers.jpg",
        },
      },
    ],
    relatedUrls: [], // optional list of external urls. Each link should be an array in the form ["title", "url"]
  },
  {
    title:
      "Yesterday, Today, and Tomorrow:  Using the Alberta Tomorrow Simulator",
    key: "YesterdayTodayTomorrow",
    theme: "Land Use",
    description:
      "Students will see how the landscape has changed since 1900 as a result of human activity. They will then investigate a business as usual future that shows a potential future based on rates of development from our past.",
    approximateTime: "90 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Yesterday, Today and Tomorrow - Using the Alberta Tomorrow Simuator",
        "YesterdayTodayandTomorrow-UsingtheAlbertaTomorrowSimuator.pdf",
      ],
    ], // list of files to include - suggested types are pdf, zip.  Each file should be an array in the form ["title", "filename_or_url"]
    grades: ["4", "5", "6", "7", "8", "9", "10", "11"], // list of grades as strings - should match grades from RegionSchools.gradeOptions
    subjects: ["Social Studies", "Science", "Biology"], // list of subjects as strings - should match curriculum options from RegionSchools.curriculumOptions
    tags: [
      "Science 14",
      "Biology 20",
      "A Sense of the Land",
      "Wetland Ecosystems",
      "Trees and Forests",
      "Interactions and Ecosystems",
      "Freshwater and Saltwater Ecosystems",
      "Government Decision Making",
      "Environmental Chemistry",
      "Globalization",
      "Energy and Matter in Chemical Change",
      "The Changing Earth",
      "Energy and Matter Exchange in the Biosphere",
      "land use",
      "wildlife",
      "water qualtiy",
      "water consumption",
      "alberta",
      "economy",
      "ecosystem services",
      "beneneficial magment practices",
      "business as usual",
      "ghgs",
      "climate change",
      "biotic carbon storage",
      "forestry",
      "oil and gas production",
      "agriculture",
      "GDP",
      "human population",
      "fish health",
      "future",
      "land use planning",
      "historical change",
      "indutstrial activity",
    ],
    learningOutcomes: [
      "explore the province of Alberta on satellite imagery",
      "identify which watershed they live in",
      "see how the landscape has changed in the past and the result on environmental and social economic indicators",
      "see a projected 'business as usual' future",
    ],
    videos: [],
    relatedUrls: [], // optional list of external urls. Each link should be an array in the form ["title", "url"]
  },
  {
    title: "My Future Alberta: Creating a Landuse Plan for 2050",
    key: "MyFutureAlberta",
    theme: "Land Use",
    description:
      "Students will use the Alberta Tomorrow simulator to create a landuse plan for Alberta’s Future",
    approximateTime: "90 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "My Future Alberta: Creating a Landuse Plan for 2050",
        "MyFutureAlbertaCreatingaLandusePlanfor2050.pdf",
      ],
    ], // list of files to include - suggested types are pdf, zip.  Each file should be an array in the form ["title", "filename_or_url"]
    grades: ["4", "5", "6", "7", "8", "9", "10", "11"], // list of grades as strings - should match grades from RegionSchools.gradeOptions
    subjects: ["Social Studies", "Science", "Biology"], // list of subjects as strings - should match curriculum options from RegionSchools.curriculumOptions
    tags: [
      "Science 14",
      "Biology 20",
      "A Sense of the Land",
      "Wetland Ecosystems",
      "Trees and Forests",
      "Interactions and Ecosystems",
      "Freshwater and Saltwater Ecosystems",
      "Government Decision Making",
      "Environmenal Chemistry",
      "Globalization",
      "Energy and Matter in Chemical Change",
      "The Changing Earth",
      "Energy and Matter Exchange in the Biosphere",
      "land use",
      "wildlife",
      "water qualtiy",
      "water consumption",
      "alberta",
      "economy",
      "ecosystem services",
      "beneneficial magment practices",
      "business as usual",
      "ghgs",
      "climate change",
      "biotic carbon storage",
      "forestry",
      "oil and gas production",
      "agriculture",
      "GDP",
      "human population",
      "fish health",
      "future",
      "land use planning",
      "historical change",
      "industrial activity",
    ],
    learningOutcomes: ["create a landuse plan for Alberta’s Future"],
    videos: [],
    relatedUrls: [
      [
        "Alberta Landuse Framework",
        "https://landuse.alberta.ca/Pages/default.aspx",
      ],
    ], // optional list of external urls. Each link should be an array in the form ["title", "url"]
  },
  {
    title:
      "Climate Change in Alberta: Investigating Mild, Medium and Hot Climate Scenarios",
    key: "ClimateChangeInAlberta",
    theme: "Climate Change",
    description:
      "Students will use the Aberta Tomorrow simulator to investigate different climate change scenarios and see the consequences the different scenarios and the benefits of using beneficial managment practices on the future.",
    approximateTime: "",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Climate Change in Alberta: Using the Alberta Tomorrow Simuator",
        "ClimateChangeinAlbertaUsingtheAlbertaTomorrowSimuator.pdf",
      ],
    ], // list of files to include - suggested types are pdf, zip.  Each file should be an array in the form ["title", "filename_or_url"]
    grades: ["4", "5", "6", "7", "8", "9", "10", "11"], // list of grades as strings - should match grades from RegionSchools.gradeOptions
    subjects: ["Social Studies", "Science", "Biology"], // list of subjects as strings - should match curriculum options from RegionSchools.curriculumOptions
    tags: [
      "Science 14",
      "Biology 20",
      "A Sense of the Land",
      "Wetland Ecosystems",
      "Trees and Forests",
      "Interactions and Ecosystems",
      "Freshwater and Saltwater Ecosystems",
      "Govenment Decision Making",
      "Environmental Chemistry",
      "Globalization",
      "Energy and Matter in Chemical Change",
      "The Changing Earth",
      "Energy and Matter Exchange in the Biosphere",
      "land use",
      "wildlife",
      "climate scenarios",
      "RCPs",
      "natural landscapes",
      "shifting ecosystems",
      " water qualtiy",
      "water consumption",
      "alberta",
      "economy",
      "ecosystem services",
      "beneneficial magment practices",
      "business as usual",
      "ghgs",
      "climate change",
      "biotic carbon storage",
      "forestry",
      "oil and gas production",
      "agriculture",
      "GDP",
      "human population",
      "fish health",
      "future",
      "land use planning",
      "historical change",
      "indutstrial activity",
    ],
    learningOutcomes: [
      "Investigate the impact of climate change on Alberta’s precipitation, climate",
      "Investigate the impact of climate change on Alberta’s natural regions",
      "Investigate the impact of climate change on our environment and economy",
    ],
    videos: [
      // optional list of videos
      {
        title: "Climate Change",
        description: "",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=Okh7vn60kxg",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/climate_change_video.jpg",
        },
      },
    ],
    relatedUrls: [
      [
        "Climate Change in Alberta",
        "https://www.alberta.ca/climate-change-alberta.aspx",
      ],
    ], // optional list of external urls. Each link should be an array in the form ["title", "url"]
  },
  {
    title:
      "How do our Landuse Decisions affect the Biogochemicals and Cycling of Matter in our Ecosystem?",
    key: "LanduseAffectsCyclingOfMatter",
    theme: "Land Use",
    description:
      "Everything we do on the land affects the water, air, land and wildlife.  Through investigating the biogeochemical cycles, students learn how our activities disrupt these natural cycles.  They are then challenged to use the Alberta Tomorrow simulator to develop a future land use plan that limits those disruptions.",
    approximateTime: "180  min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      ["Bio 20 Lesson Plan", "ATBio20LessonPlanv6.pdf"],
      ["Bio 20 Student Worksheet", "ATBio20Studentworksheetv6.pdf"],
      ["Bio 20 Answer Key", "ATBio20AnswerKeyv6.pdf"],
    ], // list of files to include - suggested types are pdf, zip.  Each file should be an array in the form ["title", "filename_or_url"]
    grades: ["11"], // list of grades as strings - should match grades from RegionSchools.gradeOptions
    subjects: ["Biology"], // list of subjects as strings - should match curriculum options from RegionSchools.curriculumOptions
    tags: [
      "Biology 20",
      "Energy and Matter Exchange in the Biosphere",
      "land use",
      "nitrogen",
      "phosphorus",
      "oxygen",
      "carbon",
      "cycle",
      "biogeochemical",
      "matter",
      "unintended consequences",
      "fertilizer",
      "eutrophication",
      "algae",
      "greehhouse effect",
      "pesticides",
      " wildlife",
      "water qualtiy",
      "water consumption",
      "alberta",
      "economy",
      "ecosystem services",
      "beneneficial magment practices",
      "business as usual",
      "ghgs",
      "climate change",
      "biotic carbon storage",
      "forestry",
      "oil and gas production",
      "agriculture",
      "GDP",
      "human population",
      "fish health",
      "future",
      "land use planning",
      "historical change",
      "indutstrial activity",
    ],
    learningOutcomes: [
      "define biogeochemical cycles",
      "investigate the water, carbon, nitrogen and phosphorus biogeochemical cycles",
      "learn ways in which the biogeochemical cycles are disrupted by human activities",
      "use the AT simulator to investigate past landuse changes, the business as usual future, and then create their own landuse plan for the future",
    ],
    videos: [
      {
        title: "Natural Landscapes",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/a87c6ba3b82849e48619d4027ed41e81/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/7d120260-62f4-48f9-8d72-63fb519fd9a7/thumbnails/Natural Landscapes v3_H264_tumb.0000064.jpg",
        },
      },
      {
        title: "Caribou Habitat",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/92b720ba2d60441d97a416358c3b700e/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/6d77c369-dc90-454f-a833-ace354a38e40/thumbnails/Caribou Habitat v4_H264_tumb.0000035.jpg",
        },
      },
      {
        title: "Grizzly Habitat",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/7d5e0260a35c45af8755c0186b82e3d3/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/4aec75a3-5a3a-44fa-8350-8f8dfda26f0c/thumbnails/Grizzly Bear habitat v1_tumb.0000021.jpg",
        },
      },
      {
        title: "Fish Habitat",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/edc8301613324a6699d44e7e8ad91427/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/28da03d6-aee2-4c23-be8f-bee1d50d7a4a/thumbnails/Fish Habitat v2_H264_tumb.0000030.jpg",
        },
      },
      {
        title: "Water Quality",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/5cde617d12f6457c8c1021b18aaa4796/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/71ac9e8a-e577-4765-9783-71eaee4c08dd/thumbnails/Water Quality v2_H264_tumb.0000063.jpg",
        },
      },
      {
        title: "Greenhouse Gasses",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/0ae65744bb3847bb9a6d8b8eb33c2ef0/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/be03a00b-b27d-4afe-be64-332ec61b92c5/thumbnails/Greenhouse Gases v2_H264_tumb.0000039.jpg",
        },
      },
      {
        title: "Human Population",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/88c0e1da186a403b9d8f6504062316a9/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/020d0ab8-d1d3-4cc4-b064-ce5c86f1b702/thumbnails/Human Population v2_H264_tumb.0000010.jpg",
        },
      },
      {
        title: "Gross Domestic Product",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/b8a59f71ae254c71ab89fcb1bdc48d07/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/e6bbc086-0392-43b3-9a2b-3aa3fef83eea/thumbnails/GDP v2_H264_tumb.0000011.jpg",
        },
      },
      {
        title: "Hydrocarbon Production",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/d48b44f7b1294f1d811ac728af797c42/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/cd19ded8-4eea-4b3a-941b-a42e65800907/thumbnails/Oil Gas v2_tumb.0000033.jpg",
        },
      },
      {
        title: "Timber Production",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/82edfc851a914347a416aafc0108d37b/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/741f8b05-8f79-4994-8234-01592f4688e2/thumbnails/Forestry v2_H264_tumb.0000025.jpg",
        },
      },
      {
        title: "Agricultural Production",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/ae54d05645274ba78eb07c8c480d8455/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/760a93f5-2cff-49e0-843c-3dd759b7232d/thumbnails/Agriculture Production v2_H264_tumb.0000025.jpg",
        },
      },
      {
        title: "Water Consumption",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/15cffe272bc44d51a21fda366d42a26d/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/a42e73bb-ede0-415a-b12b-a873782a69a1/thumbnails/Water Consumption v3_H264_tumb.0000039.jpg",
        },
      },
    ],
    relatedUrls: [], // optional list of external urls. Each link should be an array in the form ["title", "url"]
  },
  {
    title: "Governments Have it Rough!  Can You Make Everyone Happy?",
    key: "GovtsHaveItRough",
    theme: "Land Use",
    description:
      "In this stakeholder debate, the class is divided into different stakeholder groups. Each stakeholder group must make a landuse plan that achieves their goals as a company/organization.  Each group presents to the class or government and in the end a consensus landuse plan that is meets the goals of society is decided upon.",
    approximateTime: "4 classes",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Social Studies 9 - Governments Have it Rough!",
        "ATSS9LessonPlanv6.pdf",
      ],
    ], // list of files to include - suggested types are pdf, zip.  Each file should be an array in the form ["title", "filename_or_url"]
    grades: ["9"], // list of grades as strings - should match grades from RegionSchools.gradeOptions
    subjects: ["Social Studies"], // list of subjects as strings - should match curriculum options from RegionSchools.curriculumOptions
    tags: [
      "Government Decision Making,",
      "land use",
      "quality of life",
      "wildlife",
      "water qualtiy",
      "water consumption",
      "alberta",
      "economy",
      "ecosystem services",
      "beneneficial magment practices",
      "business as usual",
      "ghgs",
      "climate change",
      "biotic carbon storage",
      "forestry",
      "oil and gas production",
      "agriculture",
      "GDP",
      "human population",
      "fish health",
      "future",
      "land use planning",
      "historical change",
      "industrial activity",
    ],
    learningOutcomes: [
      "students will learn about the interaction between the economy and environmental indicators",
      "students will investigate multiple perspectives on appropirate land use",
      "students will role play different stakeholders",
      "students will develop a landuse plan that would be best for all Albertans",
    ],
    videos: [
      {
        title: "Natural Landscapes",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/a87c6ba3b82849e48619d4027ed41e81/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/7d120260-62f4-48f9-8d72-63fb519fd9a7/thumbnails/Natural Landscapes v3_H264_tumb.0000064.jpg",
        },
      },
      {
        title: "Caribou Habitat",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/92b720ba2d60441d97a416358c3b700e/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/6d77c369-dc90-454f-a833-ace354a38e40/thumbnails/Caribou Habitat v4_H264_tumb.0000035.jpg",
        },
      },
      {
        title: "Grizzly Habitat",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/7d5e0260a35c45af8755c0186b82e3d3/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/4aec75a3-5a3a-44fa-8350-8f8dfda26f0c/thumbnails/Grizzly Bear habitat v1_tumb.0000021.jpg",
        },
      },
      {
        title: "Fish Habitat",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/edc8301613324a6699d44e7e8ad91427/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/28da03d6-aee2-4c23-be8f-bee1d50d7a4a/thumbnails/Fish Habitat v2_H264_tumb.0000030.jpg",
        },
      },
      {
        title: "Water Quality",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/5cde617d12f6457c8c1021b18aaa4796/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/71ac9e8a-e577-4765-9783-71eaee4c08dd/thumbnails/Water Quality v2_H264_tumb.0000063.jpg",
        },
      },
      {
        title: "Greenhouse Gasses",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/0ae65744bb3847bb9a6d8b8eb33c2ef0/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/be03a00b-b27d-4afe-be64-332ec61b92c5/thumbnails/Greenhouse Gases v2_H264_tumb.0000039.jpg",
        },
      },
      {
        title: "Human Population",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/88c0e1da186a403b9d8f6504062316a9/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/020d0ab8-d1d3-4cc4-b064-ce5c86f1b702/thumbnails/Human Population v2_H264_tumb.0000010.jpg",
        },
      },
      {
        title: "Gross Domestic Product",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/b8a59f71ae254c71ab89fcb1bdc48d07/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/e6bbc086-0392-43b3-9a2b-3aa3fef83eea/thumbnails/GDP v2_H264_tumb.0000011.jpg",
        },
      },
      {
        title: "Hydrocarbon Production",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/d48b44f7b1294f1d811ac728af797c42/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/cd19ded8-4eea-4b3a-941b-a42e65800907/thumbnails/Oil Gas v2_tumb.0000033.jpg",
        },
      },
      {
        title: "Timber Production",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/82edfc851a914347a416aafc0108d37b/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/741f8b05-8f79-4994-8234-01592f4688e2/thumbnails/Forestry v2_H264_tumb.0000025.jpg",
        },
      },
      {
        title: "Agricultural Production",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/ae54d05645274ba78eb07c8c480d8455/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/760a93f5-2cff-49e0-843c-3dd759b7232d/thumbnails/Agriculture Production v2_H264_tumb.0000025.jpg",
        },
      },
      {
        title: "Water Consumption",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/15cffe272bc44d51a21fda366d42a26d/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/a42e73bb-ede0-415a-b12b-a873782a69a1/thumbnails/Water Consumption v3_H264_tumb.0000039.jpg",
        },
      },
    ],
    relatedUrls: [], // optional list of external urls. Each link should be an array in the form ["title", "url"]
  },
  {
    title:
      "Did We Do That?  A Study of Alberta's Geography and the Benefits and Drawbacks of Landuse",
    key: "DidWeDoThat",
    theme: "Land Use",
    description:
      "Grade 4 students will investigate Alberta's natural ecoregions though viewing a series videos.",
    approximateTime: "12 classes",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [["Grade 4 Lesson Plan", "ATGrade4lessonplanv6.pdf"]], // list of files to include - suggested types are pdf, zip.  Each file should be an array in the form ["title", "filename_or_url"]
    grades: ["4"], // list of grades as strings - should match grades from RegionSchools.gradeOptions
    subjects: ["Social Studies"], // list of subjects as strings - should match curriculum options from RegionSchools.curriculumOptions
    tags: [
      "A sense of the Land",
      "land use",
      "quality of life",
      "wildlife",
      "water qualtiy",
      "water consumption",
      "alberta",
      "economy",
      "ecosystem services",
      "beneneficial magment practices",
      "business as usual",
      "ghgs",
      "climate change",
      "biotic carbon storage",
      "forestry",
      "oil and gas production",
      "agriculture",
      "GDP",
      "human population",
      "fish health",
      "future",
      "land use planning",
      "historical change",
      "industrial activity",
    ],
    learningOutcomes: [
      "investigate the geography of Alberta",
      "look at the benefits and drawbacks of landuse",
    ],
    videos: [
      {
        title: "Natural Landscapes",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/a87c6ba3b82849e48619d4027ed41e81/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/7d120260-62f4-48f9-8d72-63fb519fd9a7/thumbnails/Natural Landscapes v3_H264_tumb.0000064.jpg",
        },
      },
      {
        title: "Caribou Habitat",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/92b720ba2d60441d97a416358c3b700e/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/6d77c369-dc90-454f-a833-ace354a38e40/thumbnails/Caribou Habitat v4_H264_tumb.0000035.jpg",
        },
      },
      {
        title: "Grizzly Habitat",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/7d5e0260a35c45af8755c0186b82e3d3/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/4aec75a3-5a3a-44fa-8350-8f8dfda26f0c/thumbnails/Grizzly Bear habitat v1_tumb.0000021.jpg",
        },
      },
      {
        title: "Fish Habitat",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/edc8301613324a6699d44e7e8ad91427/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/28da03d6-aee2-4c23-be8f-bee1d50d7a4a/thumbnails/Fish Habitat v2_H264_tumb.0000030.jpg",
        },
      },
      {
        title: "Water Quality",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/5cde617d12f6457c8c1021b18aaa4796/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/71ac9e8a-e577-4765-9783-71eaee4c08dd/thumbnails/Water Quality v2_H264_tumb.0000063.jpg",
        },
      },
      {
        title: "Greenhouse Gasses",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/0ae65744bb3847bb9a6d8b8eb33c2ef0/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/be03a00b-b27d-4afe-be64-332ec61b92c5/thumbnails/Greenhouse Gases v2_H264_tumb.0000039.jpg",
        },
      },
      {
        title: "Human Population",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/88c0e1da186a403b9d8f6504062316a9/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/020d0ab8-d1d3-4cc4-b064-ce5c86f1b702/thumbnails/Human Population v2_H264_tumb.0000010.jpg",
        },
      },
      {
        title: "Gross Domestic Product",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/b8a59f71ae254c71ab89fcb1bdc48d07/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/e6bbc086-0392-43b3-9a2b-3aa3fef83eea/thumbnails/GDP v2_H264_tumb.0000011.jpg",
        },
      },
      {
        title: "Hydrocarbon Production",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/d48b44f7b1294f1d811ac728af797c42/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/cd19ded8-4eea-4b3a-941b-a42e65800907/thumbnails/Oil Gas v2_tumb.0000033.jpg",
        },
      },
      {
        title: "Timber Production",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/82edfc851a914347a416aafc0108d37b/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/741f8b05-8f79-4994-8234-01592f4688e2/thumbnails/Forestry v2_H264_tumb.0000025.jpg",
        },
      },
      {
        title: "Agricultural Production",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/ae54d05645274ba78eb07c8c480d8455/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/760a93f5-2cff-49e0-843c-3dd759b7232d/thumbnails/Agriculture Production v2_H264_tumb.0000025.jpg",
        },
      },
      {
        title: "Water Consumption",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/15cffe272bc44d51a21fda366d42a26d/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/a42e73bb-ede0-415a-b12b-a873782a69a1/thumbnails/Water Consumption v3_H264_tumb.0000039.jpg",
        },
      },
    ],
    relatedUrls: [], // optional list of external urls. Each link should be an array in the form ["title", "url"]
  },
  {
    title:
      "Did I Do That? The Relationship Between Humans and Their Environment",
    key: "DidIDoThat",
    theme: "Land Use",
    description:
      "Using the Alberta Tomorrow simulator, students investigate the unintended consequences of human activity on the environment.  They are challenged to come up with a future landuse plan for their watershed that balances the economy with the environement.",
    approximateTime: "180 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [["Science 7 Lesson Plan", "ATScience7LessonPlanv6.pdf"]], // list of files to include - suggested types are pdf, zip.  Each file should be an array in the form ["title", "filename_or_url"]
    grades: ["7"], // list of grades as strings - should match grades from RegionSchools.gradeOptions
    subjects: ["Science"], // list of subjects as strings - should match curriculum options from RegionSchools.curriculumOptions
    tags: [
      "Interactions and Ecosystems",
      "land use",
      "quality of life",
      "wildlife",
      "water qualtiy",
      "water consumption",
      "alberta",
      "economy",
      "ecosystem services",
      "beneneficial magment practices",
      "business as usual",
      "ghgs",
      "climate change",
      "biotic carbon storage",
      "forestry",
      "oil and gas production",
      "agriculture",
      "GDP",
      "human population",
      "fish health",
      "future",
      "land use planning",
      "historical change",
      "industrial activity",
    ],
    learningOutcomes: [
      "Investigate past changes on the landscape",
      "investigat a possible future",
      "create their own landuse plan for the future.",
    ],
    videos: [
      {
        title: "Natural Landscapes",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/a87c6ba3b82849e48619d4027ed41e81/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/7d120260-62f4-48f9-8d72-63fb519fd9a7/thumbnails/Natural Landscapes v3_H264_tumb.0000064.jpg",
        },
      },
      {
        title: "Caribou Habitat",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/92b720ba2d60441d97a416358c3b700e/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/6d77c369-dc90-454f-a833-ace354a38e40/thumbnails/Caribou Habitat v4_H264_tumb.0000035.jpg",
        },
      },
      {
        title: "Grizzly Habitat",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/7d5e0260a35c45af8755c0186b82e3d3/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/4aec75a3-5a3a-44fa-8350-8f8dfda26f0c/thumbnails/Grizzly Bear habitat v1_tumb.0000021.jpg",
        },
      },
      {
        title: "Fish Habitat",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/edc8301613324a6699d44e7e8ad91427/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/28da03d6-aee2-4c23-be8f-bee1d50d7a4a/thumbnails/Fish Habitat v2_H264_tumb.0000030.jpg",
        },
      },
      {
        title: "Water Quality",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/5cde617d12f6457c8c1021b18aaa4796/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/71ac9e8a-e577-4765-9783-71eaee4c08dd/thumbnails/Water Quality v2_H264_tumb.0000063.jpg",
        },
      },
      {
        title: "Greenhouse Gasses",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/0ae65744bb3847bb9a6d8b8eb33c2ef0/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/be03a00b-b27d-4afe-be64-332ec61b92c5/thumbnails/Greenhouse Gases v2_H264_tumb.0000039.jpg",
        },
      },
      {
        title: "Human Population",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/88c0e1da186a403b9d8f6504062316a9/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/020d0ab8-d1d3-4cc4-b064-ce5c86f1b702/thumbnails/Human Population v2_H264_tumb.0000010.jpg",
        },
      },
      {
        title: "Gross Domestic Product",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/b8a59f71ae254c71ab89fcb1bdc48d07/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/e6bbc086-0392-43b3-9a2b-3aa3fef83eea/thumbnails/GDP v2_H264_tumb.0000011.jpg",
        },
      },
      {
        title: "Hydrocarbon Production",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/d48b44f7b1294f1d811ac728af797c42/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/cd19ded8-4eea-4b3a-941b-a42e65800907/thumbnails/Oil Gas v2_tumb.0000033.jpg",
        },
      },
      {
        title: "Timber Production",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/82edfc851a914347a416aafc0108d37b/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/741f8b05-8f79-4994-8234-01592f4688e2/thumbnails/Forestry v2_H264_tumb.0000025.jpg",
        },
      },
      {
        title: "Agricultural Production",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/ae54d05645274ba78eb07c8c480d8455/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/760a93f5-2cff-49e0-843c-3dd759b7232d/thumbnails/Agriculture Production v2_H264_tumb.0000025.jpg",
        },
      },
      {
        title: "Water Consumption",
        description: "",
        media: {
          type: "video",
          url:
            "https://d2qcvmovr4fv.cloudfront.net/out/v1/15cffe272bc44d51a21fda366d42a26d/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/a42e73bb-ede0-415a-b12b-a873782a69a1/thumbnails/Water Consumption v3_H264_tumb.0000039.jpg",
        },
      },
    ],
    relatedUrls: [], // optional list of external urls. Each link should be an array in the form ["title", "url"]
  },
  {
    title: "Landuse Field Trip",
    key: "LanduseFieldTrip",
    theme: "Land Use",
    description:
      "This is a self led guide for teachers to go on a field trip with their class that investigates at least 2 different areas, one more pristine and one affected by landuse ie a city, golf course, etc The lesson plan includes student worksheets, water test procedures.  The users must supply their own test kits.",
    approximateTime: "1/2 day - full day depending on travel time",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [["Land Use Field Trip", "ATLandUseFieldTripv6.pdf"]], // list of files to include - suggested types are pdf, zip.  Each file should be an array in the form ["title", "filename_or_url"]
    grades: ["4", "5", "6", "7", "8", "9", "10", "11"], // list of grades as strings - should match grades from RegionSchools.gradeOptions
    subjects: ["Science", "Biology"], // list of subjects as strings - should match curriculum options from RegionSchools.curriculumOptions
    tags: [
      "Science 14",
      "Biology 20",
      "Physical Geography of Canada",
      "Wetlands Ecosystems",
      "Interactions and Ecosystems",
      "Freshwater and Saltwater Systems",
      "Environmental Chemistry",
      "Energy FLow in Global Systems",
      "Change in Living Systems",
      "Energy and Matter Exchange ",
      "Investigating Matter and Enschange in the Biosphere",
      "Investigating Matter and Energy in the Environment",
      "Agriculture 1010",
      "Environmental Stewarship 1110",
      "Environmental Stewarship 1115",
      "Environmental Stewarship 3040",
      "land use",
      "quality of life",
      "wildlife",
      "water qualtiy",
      "water consumption",
      "alberta",
      "economy",
      "ecosystem services",
      "beneneficial magment practices",
      "business as usual",
      "ghgs",
      "climate change",
      "biotic carbon storage",
      "forestry",
      "oil and gas production",
      "agriculture",
      "GDP",
      "human population",
      "fish health",
      "future",
      "land use planning",
      "historical change",
      "industrial activity",
    ],
    learningOutcomes: [
      "investigate and compare water quality in a relatively pristine area and compare to one that has been affected by landuse",
    ],
    videos: [],
    relatedUrls: [], // optional list of external urls. Each link should be an array in the form ["title", "url"]
  },
  {
    title: "Stakeholder Debate",
    key: "StakeholderDebate",
    theme: "Land Use",
    description:
      "In this stakeholder debate, the class is divided into different stakeholder groups. Each stakeholder group must make a landuse plan that achieves their goals as a company/organization.  Each group presents to the class or government and in the end a consensus landuse plan that is meets the goals of society is decided upon.",
    approximateTime: "4 classes",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [["Stakeholder Debate Lesson Plan", "ATStakeholderdebatev6.pdf"]], // list of files to include - suggested types are pdf, zip.  Each file should be an array in the form ["title", "filename_or_url"]
    grades: ["4", "5", "6", "7", "8", "9", "10", "11"], // list of grades as strings - should match grades from RegionSchools.gradeOptions
    subjects: ["Science", "Social Studies"], // list of subjects as strings - should match curriculum options from RegionSchools.curriculumOptions
    tags: [
      "Science 14",
      "Biology 20",
      "A Sense of the Land",
      "Wetland Ecosystems",
      "Trees and Forests",
      "Interactions and Ecosystems",
      "Freshwater and Saltwater Ecosystems",
      "Government Decision Making",
      "Environmenal Chemistry",
      "Globalization",
      "Energy and Matter in Chemical Change",
      "The Changing Earth",
      "Energy and Matter Exchange in the Biosphere",
      "land use",
      "wildlife",
      "water qualtiy",
      "water consumption",
      "alberta",
      "economy",
      "ecosystem services",
      "beneneficial magment practices",
      "business as usual",
      "ghgs",
      "climate change",
      "biotic carbon storage",
      "forestry",
      "oil and gas production",
      "agriculture",
      "GDP",
      "human population",
      "fish health",
      "future",
      "land use planning",
      "historical change",
      "industrial activity",
    ],
    learningOutcomes: [
      "students will learn about the interaction between the economy and environmental indicators",
      "students will investigate multiple perspectives on appropirate land use",
      "students will role play different stakeholders",
      "students will develop a landuse plan that would be best for all Albertans",
    ],
    videos: [],
    relatedUrls: [], // optional list of external urls. Each link should be an array in the form ["title", "url"]
  },
  {
    title: "Water Test Procedures",
    key: "WaterTestProcedures",
    theme: "Glaciers/Watersheds",
    description: "Water test procedures guide.",
    approximateTime: "",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [["Water Test Procedures", "WaterTestProcedures.pdf"]], // list of files to include - suggested types are pdf, zip.  Each file should be an array in the form ["title", "filename_or_url"]
    grades: ["4", "5", "6", "7", "8", "9", "10", "11"], // list of grades as strings - should match grades from RegionSchools.gradeOptions
    subjects: ["Science", "Biology"], // list of subjects as strings - should match curriculum options from RegionSchools.curriculumOptions
    tags: [
      "Science 14",
      "Biology 20",
      "Physical Geography of Canada",
      "Wetlands Ecosystems",
      "Interactions and Ecosystems",
      "Freshwater and Saltwater Systems",
      "Environmental Chemistry",
      "Energy FLow in Global Systems",
      "Change in Living Systems",
      "Energy and Matter Exchange ",
      "Investigating Matter and Enschange in the Biosphere",
      "Investigating Matter and Energy in the Environment",
      "Agriculture 1010",
      "Environmental Stewarship 1110",
      "Environmental Stewarship 1115",
      "Environmental Stewarship 3040",
      "land use",
      "quality of life",
      "wildlife",
      "water qualtiy",
      "water consumption",
      "alberta",
      "economy",
      "ecosystem services",
      "beneneficial magment practices",
      "business as usual",
      "ghgs",
      "climate change",
      "biotic carbon storage",
      "forestry",
      "oil and gas production",
      "agriculture",
      "GDP",
      "human population",
      "fish health",
      "future",
      "land use planning",
      "historical change",
      "industrial activity",
    ],
    learningOutcomes: [],
    videos: [],
    relatedUrls: [], // optional list of external urls. Each link should be an array in the form ["title", "url"]
  },
  {
    title: "Water Quality, Climate Change and Alberta's Glaciers",
    key: "WaterQualityClimChangeGlaciers",
    theme: "Climate Change",
    description:
      "This lesson plan is to accomany the Webinar Recording Found under the Video Section.  The webinar was held on May 27, 2020 and features 4 prominent scientists talking about climate change and how it will affect glaciers, glacier melt, water policy and our water quality.",
    approximateTime: "180 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Water Quality, Climate Change and Alberta’s Glaciers",
        "WaterQualityClimateChangeandAlbertasGlaciers.pdf",
      ],
      [
        "Webinar Worksheet",
        "WaterQualityClimateChangeandAlbertasGlaciersStudentWorksheet.pdf",
      ],
      [
        "Glaciers in Alberta (Google Slides)",
        "https://docs.google.com/presentation/d/1rBX6NS-xWfHD3HxAV341q1ZC4wZV5FvNaUdsLi94ptg/edit?usp=sharing",
      ],
    ], // list of files to include - suggested types are pdf, zip.  Each file should be an array in the form ["title", "filename_or_url"]
    grades: ["4", "5", "6", "7", "8", "9", "10", "11"],
    subjects: ["Science", "Social Studies"],
    tags: [
      "water",
      "water quality",
      "water consumption",
      "glaciers",
      "glacier melt",
      "climate change",
      "ice coring",
      "forest fires",
      "water policy",
      "persistent chemicals",
      "cfcs",
      "ddt",
      "albedo",
    ],
    learningOutcomes: [
      "learn about water quality in Alberta",
      "identity what watershed you live within",
      "understand basic glacier terminology",
      "describe the effect of climate change on Alberta’s glaciers",
      "Be introduced to the impact of forest fires on glaciers",
      "Become aware of the importance of water policy in dealing with the effects of climate change",
      "consider the impact of our activities on climate change and Alberta’s glaciers",
    ],
    videos: [
      // optional list of videos
      {
        title: "Water Quality, Climate Change and Alberta's Glaciers Webinar",
        description:
          "This webinar was held on May 27, 2020 and features 4 prominent scientists talking about climate change and how it will affect glaciers, glacier melt, water policy and our water quality.",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=Xgb3_I8nagY",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/water-climate-change-glaciers-webinar.jpg",
        },
      },
    ],
    relatedUrls: [
      [
        "Guardians of the Ice",
        "https://guardiansoftheice.com/the-columbia-icefield/",
      ],
    ], // optional list of external urls. Each link should be an array in the form ["title", "url"]
  },
  {
    title: "360 Virtual Field Trips to Alberta's 6 Ecoregions",
    key: "VirtualToursEcoregions",
    theme: "360 Tours of AB Natural Regions",
    description:
      "In this scavenger hunt lesson, students will learn about what makes each of Alberta's 6 ecoregions unique, but fining icons for geogaphy, geology and soils, landforms, climate, plants, animals, and natural resource use.  They will also find icons for intersting facts and species at risk.",
    approximateTime: "120 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Alberta's Ecoregions - 360 Virtual Field Trips Lesson Plan",
        "AlbertasEcoregions-360VirtualFieldTrips.pdf",
      ],
      [
        "360 Virtual Tours Scavenger Hunt Student Worksheet",
        "360VirtualToursScavengerHuntStudentWorksheet.pdf",
      ],
      ["Scavenger Hunt Sheet", "360ToursScavengerHuntSheet.pdf"],
      [
        "360 Virtual Tours Scavenger Hunt - Fillable Google Slides",
        "https://docs.google.com/presentation/d/1cqF8G4yz8O23Jg65PTvcoe-juJLizHjjP8vPkZY1H4k/edit?usp=sharing"
      ],
      [
        "Scavenger Hunt Sheet - Fillable Google Slides",
        "https://docs.google.com/presentation/d/1iGXFpDscj18TBWM_f03jUxc7rGtzPWRxn6Yxvrn-Pfs/edit#slide=id.p"
      ],
    ], // list of files to include - suggested types are pdf, zip.  Each file should be an array in the form ["title", "filename_or_url"]
    grades: ["3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
    subjects: ["Science", "Social Studies", "Biology", "CTS"],
    tags: [
      "Animal Life Cycles",
      "A sense of the land",
      "Trees and Forests",
      "Interactions and Ecosystems",
      "Biological Diversity",
      "Energy and Flow in Global Systems",
      "Changes in Living Things",
      "Wildlife",
      "Environmental Stewardship",
      "Resources",
      "Forestry",
      "adaptations",
      "environment",
      "prairie",
      "plants",
      "species at risk",
      "climate change",
      "geographic diversity",
      "ecoregions",
      "ecozones",
      "climate",
      "landforms",
      "shield",
      "grassland",
      "foothills",
      "boreal forest",
      "rocky mountains",
      "geology",
      "geography",
      "animals",
      "logging",
      "forestry",
      "environmental issues",
      "responsible use",
      "economy",
      "trees",
      "aspen",
      "human impacts",
      "unintended consequences",
      "resource use",
      "ecosystems",
      "environmental impacts",
      "interactions",
      "diveristy",
      "forests",
      "parkland",
      "adaptation",
      "biomes",
      "taiga",
      "habitat descruction",
      "reclamation",
      "forest fires",
      "soils",
      "landuse",
      "wildlifer",
      "forest managment practices",
      "adaptions",
      "habitat",
      "interaction",
      "structural adaptations",
      "behavioural adaptations",
      "forest use",
    ],
    learningOutcomes: [
      "students will demonstrate and understanding and appreciation of how geography, climate, soils, geology, plants and animals define a natural ecoregion",
      "Students will identify species and risk in each ecoregion",
    ],
    videos: [
      // optional list of videos
      {
        title: "Introducing Alberta Tomorrow’s 360 Virtual Field Trips",
        description:
          "The time has finally come! Check out the 360 Virtual Field Trips to Alberta's Ecoregions!  1 for each ecoregion exploring the plants, animals, geology, landforms, climate, land uses and species at risk!",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=rL13Xz_x2dA",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/virtualToursEcoRegions.jpg",
        },
      },
    ],
    relatedUrls: [], // optional list of external urls. Each link should be an array in the form ["title", "url"]
  },
  {
    title: "Animal Adpatations - Using the 360 Virtual Field Trips",
    key: "VirtualToursAnimalAdaptations",
    theme: "360 Tours of AB Natural Regions",
    description:
      "In this lesson students will explore animals in all 6 ecoregions within Alberta and learn about how they are specially adapted to living there.",
    approximateTime: "approximately 2-4 classes",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Grade 3 Science Adaptations and the 360 Virtual Field Trips",
        "Grade3ScienceAdaptationsandthe360VirtualFieldTrips.pdf",
      ],
      [
        "Animal Adaptations Student Worksheet - Fillable Google Slides",
        "https://docs.google.com/presentation/d/1BcHISVOfng7MYn0MFUiFrYbpL9XYm8x-lCDFQvLPmEY/edit#slide=id.p",
      ],
    ], // list of files to include - suggested types are pdf, zip.  Each file should be an array in the form ["title", "filename_or_url"]
    grades: ["3"],
    subjects: ["Science"],
    tags: [
      "Animal Life Cycles",
      "adaptations",
      "environment",
      "animals",
      "adaptation",
    ],
    learningOutcomes: [
      "Students will learn how animals are adapated to life in each of the 6 natural regions of Alberta.",
    ],
    videos: [
      // optional list of videos
      {
        title: "Introducing Alberta Tomorrow’s 360 Virtual Field Trips",
        description:
          "Check out the 360 Virtual Field Trips to Alberta's Ecoregions!  1 for each ecoregion exploring the plants, animals, geology, landforms, climate, land uses and species at risk!",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=rL13Xz_x2dA",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/virtualToursEcoRegions.jpg",
        },
      },
    ],
    relatedUrls: [], // optional list of external urls. Each link should be an array in the form ["title", "url"]
  },
  {
    title: "Plant and Animal Adaptations - Using the 360 Virtual Field Trips",
    key: "VirtualToursPlantAndAnimalAdaptations",
    theme: "360 Tours of AB Natural Regions",
    description:
      "In this lesson students will explore the plants and animals in all 6 ecoregions within Alberta, learn how they are specially adapted to living there, what human activities affect their survival and which species are at risk in each ecoregion.",
    approximateTime: "one 60 min period",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Grade 9 Science Adaptations and the 360 Virtual Field Trips",
        "Grade9Scienceandthe360VirtualFieldTrips.pdf",
      ],
      [
        "Grade 9 Plant and Animal Adaptations Student Worksheet - Fillable Google Slides",
        "https://docs.google.com/presentation/d/1b_EosZ3fXr638ZeakaiqvjUYDMXGrXiwV7Yw3o04w8E/edit#slide=id.p",
      ],
    ], // list of files to include - suggested types are pdf, zip.  Each file should be an array in the form ["title", "filename_or_url"]
    grades: ["9"],
    subjects: ["Science"],
    tags: [
      "Biological Diversity",
      "Changes in Living Things",
      "Wildlife",
      "environment",
      "animals",
      "plants",
      "species at risk",
      "ecoregions",
      "ecozones",
      "adaptation",
      "adaptations",
      "variation",
      "biodiversity",
      "structural adaptations",
      "behavioural adaptations" ,
    ],
    learningOutcomes: [
      "students will describe how Plants and animals are adapted to life in each natural ecoregion of ALberta.",
      "Students will identify risks to species survival in each ecoregion.",
      "Students will identify species at risk in each region",
    ],
    videos: [
      // optional list of videos
      {
        title: "Introducing Alberta Tomorrow's 360 Virtual Field Trips",
        description:
          "The time has finally come! Check out the 360 Virtual Field Trips to Alberta's Ecoregions!  1 for each ecoregion exploring the plants, animals, geology, landforms, climate, land uses and species at risk!",
        media: {
          type: "video",
          url: "https://www.youtube.com/watch?v=rL13Xz_x2dA",
          thumbUrl:
            "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/virtualToursEcoRegions.jpg",
        },
      },
    ],
    relatedUrls: [], // optional list of external urls. Each link should be an array in the form ["title", "url"]
  },
  {
    title: "Mistakes, Mitigations, and Making Amends. A Webinar by CPAWS and Alberta Tomorrow",
    key: "MistakesMitigationsMakingAmends",
    theme: "Land Use",
    description:
      `A land based learning webinar presented by Alberta Tomorrow and CPAWS Southern Alberta`,
    approximateTime: "1 hour",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Land Based Learning Webinar Worksheet",
        "AT_CPAWSWebinarStudentWorksheet.pdf",
      ],
    ], // list of files to include - suggested types are pdf, zip.  Each file should be an array in the form ["title", "filename_or_url"]
    grades: ["4", "5", "6", "7", "8", "9", "10", "11", "12"],
    subjects: [
      "Science",
      "Biology",
      "Science 14",
    ],
    tags: [
      "landuse",
      "water quality",
      "Riparian zones",
      "indigenous knowledge",
    ],
    learningOutcomes: [],
    videos: [],
    relatedUrls: [], // optional list of external urls. Each link should be an array in the form ["title", "url"]
  },
  {
    title: "Where is my Home? Using the Indigenous Map Overlays",
    key: "WhereIsMyHomeIndigenousMapOverlays", // make sure this key is unique
    theme: "Indigenous Voices",
    description: "A step by step lesson plan to using the Indigenous Map Overlays",
    approximateTime: "60 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Where is my Home? Using the Indigenous Map Layers - Google Doc",
        "https://docs.google.com/document/d/1EUVmgLZMq1EftnLbtPEaxvwCxFxBGtwvyTy-P4mCA1g/edit?usp=sharing",
      ],
    ],
    grades: ["4", "5", "6", "7", "8", "9", "10", "11", "12"],
    subjects: ["Social Studies", "Science"], 
    tags: [
      "Residential Schools", 
      "Traditional Territories",
      "Treatie Areas", 
      "Indigenous", 
      "Traditional Languages", 
      "Reserves", 
      "Metis Regions"
    ],
    learningOutcomes: [
      "Students will locate their home, school and community on the satellite imagery using the Indigenous map layers, see their location in relation to treaty area, traditional territory, traditional languages, reserves and settlements, metis regions, residential school locations",
      "compare sizes of traditional territories to reserves and settlements"
    ],
    videos: [],
    relatedUrls: [], // optional list of external urls. Each link should be an array in the form ["title", "url"]    
  },
  {
    title: "Isotopes and Climate Change",
    key: "IsotopesandClimateChange", // make sure this key is unique
    theme: "Bow River Watershed",
    description: "We can track climate changes by looking at what types of istopes are in the water.",
    approximateTime: "30 to 60 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Isotopes and Climate Change - Google Doc",
        "https://docs.google.com/document/d/1PcI2jRXdspNZqIkJeppDro2Ub0wXz9bi/",
      ],
    ],
    grades: ["10", "11", "12"],
    subjects: ["Science", "Chemistry"], 
    tags: [
      "isotopes", 
      "climate change", 
      "oxygen isotopes", 
      "hydrogen isotopes",
      "watershed", 
      "environmental change",
      "water cycle", 
      "streamflow", 
      "Bow River"
    ],
    learningOutcomes: [
      "Examine the use of isotope tracking",
      "Investige the consequences of climate change",
      "Examine the negative imipact of climate change"
    ],
    videos: [],
    relatedUrls: [], // optional list of external urls. Each link should be an array in the form ["title", "url"]    
  },
  {
    title: "Glenmore Dam and Resevoir: Water Services",
    key: "GlenmoreDamandResevoirWaterServices",
    theme: "Bow River Watershed",
    description: "In this activity you will learn about the movement and managment of water within the city of Calgary, along with the history of the glenmore Resevoir and Dam.",
    approximateTime: "30 to 60 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Glenmore Dam and Resevoir: Water Services - Google Doc",
        "https://docs.google.com/document/d/1X8bXoEl8IDyHjAtW5nSc5mtB-jVN8DU0/",
      ],
    ],
    grades: ["6", "7", "8", "9", "10"],
    subjects: ["Science"], 
    tags: [
      "water treatment", 
      "dam", 
      "resevoir", 
      "drinking water", 
      "water quality", 
      "Calgary", 
      "Glenmore Resevoir", 
      "Glenmore Dam", 
      "water management", 
      "Bow River"
    ],
    learningOutcomes: [],
    videos: [
      brbcVideos.find((video) => video.title == 'Water Services')
    ],   
    relatedUrls: []
  },
  {
    title: "Water Quality: Water Treatment",
    key: "WaterQualityWaterTreatment",
    theme: "Bow River Watershed",
    description: "In this activity you will learn about the process of cleaning water to meat drinking standards within the City of Calgary",
    approximateTime: "30 to 60 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Water Quality: Water Treatment - Google Doc",
        "https://docs.google.com/document/d/1unCOQUIHRLS5YZJ3J5ekBFurcbr9zaL7/",
      ],
    ],
    grades: ["8", "9", "10", "11", "12"],
    subjects: ["Science"], 
    tags: [
      "water quality", 
      "water treatment", 
      "Glenmore Resevoir", 
      "pre treatment", 
      "disinfectcion", 
      "Glenmore Dam", 
      "City of Calgary", 
      "drinking water", 
      "Bow River"
    ],
    learningOutcomes: [
      "Investigate the stages of water treatement"
    ],
    videos: [
      brbcVideos.find((video) => video.title == 'Water Quality')
    ],   
    relatedUrls: []
  },  
  {
    title: "Constructed Wetlands and Stormwater",
    key: "ConstructedWetlandsandStormwater",
    theme: "Bow River Watershed",
    description: "In this activity you will learn about stormwater and how wetlands are constructed to manage it.",
    approximateTime: "30 to 60 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Constructed Wetlands and Stormwater - Google Doc",
        "https://docs.google.com/document/d/1GrkDcND2Fflt_-S-R6DyNrJL8lwalLG_/",
      ],
    ],
    grades: ["8", "9", "10", "11"],
    subjects: ["Science", "Biology"], 
    tags: [
      "contructed wetlands",
      "wetlands",
      "stormwater",
      "water management", 
      "Bow River"
    ],
    learningOutcomes: [
      "Examine stormwater functions and the role of wetlands in water management and quality",
      "Investigage the consequences of resource production, uranization, and economic growth on wetlands and water quality",
      "Examine the negative enivronmental impact of disruption fo the watersehd as a result of resource use"
    ],
    videos: [
      brbcVideos.find((video) => video.title == 'Wetlands and Stormwater')
    ],
    relatedUrls: []
  },  
  {
    title: "Aquifers",
    key: "Aquifers",
    theme: "Bow River Watershed",
    description: "In this activity you will learn about the aquifer and groundwater within the Elbow River sub basin.",
    approximateTime: "30 to 60 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Aquifers - Google Doc",
        "https://docs.google.com/document/d/1GBnAfLDry3L8DEJTw1hqczlE5lSD0r5O/",
      ],
    ],
    grades: ["5", "6", "7", "8", "9", "10", "11"],
    subjects: ["Science", "Biology"], 
    tags: [
      "aquifers", 
      "Elbow River", 
      "Bow River", 
      "Groundwater", 
      "water quality", 
      "water usage", 
      "geology", 
      "Bragg Creek"
    ],
    learningOutcomes: [
      "examine the landscape and compare them to what the geology below could look like",
      "investiage the consequences of resource production, urbanization, and economic growh on aquifers and groundwater"
    ],
    videos: [
      brbcVideos.find((video) => video.title == 'Elbow River Watershed Partnership (ERWP) Aquifers')
    ],
    relatedUrls: []
  },  
  {
    title: "The Watershed",
    key: "TheWatershed",
    theme: "Bow River Watershed",
    description: "In this activity you will learn about how land use changes impact how water moves through the landscape and how that changes our interactions with it.",
    approximateTime: "30 to 60 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "The Watershed- Google Doc",
        "https://docs.google.com/document/d/1X8bXoEl8IDyHjAtW5nSc5mtB-jVN8DU0/",
      ],
    ],
    grades: ["K", "1", "2", "3"],
    subjects: ["Science"], 
    tags: [
      "watershed", 
      "Bow River", 
      "water", 
      "water cycle"
    ],
    learningOutcomes: [
      "examine the land and spaces where water exists",
      "investigate the different ways that people can interact iwth the rivers and water",
      "examine the need for water in our lives"
    ],
    videos: [
      brbcVideos.find((video) => video.title == 'Watershed')
    ],
    relatedUrls: []
  },  
  {
    title: "Hydrology and Land Development",
    key: "HydrologyandLandDevelopment",
    theme: "Bow River Watershed",
    description: "In this activity you will learn about how land use changes impact how water moves throuh the landscape and how that changes our interactions with it.",
    approximateTime: "30 to 60 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Hydrology and Land Development - Google Doc",
        "https://docs.google.com/document/d/1d4mr6hIi_J3jNzJVj77lyBNeCW_jGhWq/edit",
      ],
    ],
    grades: ["2", "3", "4", "5", "6", "7", "8", "9", "10", "11"],
    subjects: ["Science", "Biology"], 
    tags: [
      "water", 
      "land use", 
      "Bow River", 
      "eutrophication", 
      "water allocation", 
      "water use", 
      "2013 flood"
    ],
    learningOutcomes: [
      "examine the watershed and hydrological practices",
      "investigate how a watershed functions"
    ],
    videos: [
      brbcVideos.find((video) => video.title == 'Hydrology and Land Development')
    ],
    relatedUrls: []
  },  
  {
    title: "Watersheds and Hydrology",
    key: "WatershedsandHydrology",
    theme: "Bow River Watershed",
    description: "In this acctivity you will learn about the basic functions of a watershed, and the hydrology that is involved.",
    approximateTime: "30 to 60 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Watersheds and Hydrology - Google Doc",
        "https://docs.google.com/document/d/1pluUXun-r0TxsYWRj8b5X__UlWWcJMcW/edit",
      ],
    ],
    grades: ["2", "3", "4", "5", "6", "7", "8"],
    subjects: ["Science"], 
    tags: [
      "water", 
      "watershed", 
      "water storage", 
      "Bow River", 
      "water use"
    ],
    learningOutcomes: [
      "examine the stage of the watershed",
      "investigate watershed functions"
    ],
    videos: [
      brbcVideos.find((video) => video.title == 'Watersheds and Hydrology')
    ],
    relatedUrls: []
  },  
  {
    title: "Hydrology and Climate Change",
    key: "HydrologyandClimateChange",
    theme: "Bow River Watershed",
    description: "In this activity you will learn about how to monitor climate change and hydrologic functions within a watershed.",
    approximateTime: "30 to 60 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Hydrology and Climate Change - Google Doc",
        "https://docs.google.com/document/d/1RgpNqFu8Muak1gNkwCZbaxPoFc6qzXM4/edit",
      ],
    ],
    grades: ["3", "4", "5", "6", "7", "8", "9", "10"],
    subjects: ["Science"], 
    tags: [
      "water cycle", 
      "extreme weather", 
      "precipitation", 
      "glaciers", 
      "climate change"
    ],
    learningOutcomes: [
      "examine the effects of climate change on the water cycle and hydrology"
    ],
    videos: [
      brbcVideos.find((video) => video.title == 'Hydrology and Climate Change')
    ],
    relatedUrls: []
  },  
  {
    title: "Invasive Species and Control",
    key: "InvasiveSpeciesandControl",
    theme: "Bow River Watershed",
    description: "In this activity you will learn about invasive species found in Alberta, along with methods to control them",
    approximateTime: "30 to 60 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Invasive Species and Control - Google Doc",
        "https://docs.google.com/document/d/1qqVuwjHNtqhLvMsjksYxXIQTULxbah9S/",
      ],
    ],
    grades: ["2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
    subjects: ["Science", "Biology"], 
    tags: [
      "invasive species", 
      "integrated pest managment", 
      "native prairie",  
      "biodiversity"
    ],
    learningOutcomes: [
      "examine the role of invasive, introduced and threatened species",
      "investigate the methods of addressing the magagement of invasive species",
      "observe spaces that native and introduced species are seen to examine the role of biodiversity"
    ],
    videos: [
      brbcVideos.find((video) => video.title == 'Invasive Species and Control')
    ],
    relatedUrls: []
  },  
  {
    title: "Native Prairies and Plants",
    key: "NativePrairiesandPlants",
    theme: "Bow River Watershed",
    description: "In this activity, you will learn about the biodiversity of the native prairies, and the many different types of functions native plants have roles in, as well as the importance of bees and other pollinators.",
    approximateTime: "30 to 60 min",
    provider: ["Alberta Tomorrow", "https://www.albertatomorrow.ca"],
    files: [
      [
        "Native Prairies and Plants - Google Doc",
        "https://docs.google.com/document/d/1uTt5BFsG986N8SgW9H4nNCfXDPj5Wta7/",
      ],
    ],
    grades: ["7", "8", "9", "10", "11", "12"],
    subjects: ["Science", "Biology"], 
    tags: [
      "watershed", 
      "Bow River", 
      "Pollincation", 
      "biodiversity", 
      "native plants", 
      "native prairie", 
      "invasive species", 
      "agriculture"
    ],
    learningOutcomes: [
      "examine the ecological roles of plants in the prairies",
      "investigate the methods of addressing threatened native species",
      "observe spaces that native and introduced species are seen to examine the role of biodiversity"
    ],
    videos: [
      brbcVideos.find((video) => video.title == 'Native Prairies and Plants')
    ],
    relatedUrls: []
  },                  
];

export default RegionResources;
