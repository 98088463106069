<template>
  <h2 :class="badgeIconStatus">
    <span :class="badgeClass">
      <i class="fas fa-trophy fa-stack-2x" />
      <i
        v-if="'icon' in badge && badge.icon !== ''"
        :class="badgeIcon"
      />
    </span>
  </h2>
</template>

<script>
export default {
  name: "BadgeIcon",

  components: {
  },

  props: {
    badge: {
      type: Object,
      required: true
    },
    achieved: {
      type: Boolean,
      default: false
    },
    xlarge: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
    };
  },

  computed: {
    badgeClass() {
      return this.xlarge ? `fa-stack fa-2x` : `fa-stack fa-1x`
    },
    badgeIconStatus() {
        // classes based on achieved or not
      return this.achieved ? `badge-icon-achieved` : `badge-icon`
    },
    badgeIcon() {
      // get an icon to display for the badge
      return `fas ${this.badge.icon} fa-inverse fa-stack-1x badge-stack-icon`;
    },
  },

  methods: {
  }
};
</script>

<style lang="scss" scoped>
.badge-icon {
  color: $grey-400;
  text-align: center;
}
.badge-icon i:first-child {
  background: linear-gradient(80deg, $grey-400 0%, $grey-600 80%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.badge-icon-achieved {
  color: $brand-warning;
  text-align: center;
}
.badge-icon-achieved i:first-child {
  background: linear-gradient(
    80deg,
    lighten($brand-warning, 20%) 0%,
    $brand-warning 80%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.badge-stack-icon {
  color: $white-color;
  font-size: 0.7em;
  transform: translate(0, -0.4em);
}
</style>
