<template>
  <simple-wizard
    id="scenario-wizard"
    ref="wizard"
    :show-header="!rightSidebarFullScreen"
    prev-button-text="Back"
    finish-button-text="Run Scenario"
    :show-tab-content="showTabContent"
    :show-actions="showTabActions"
    :show-cancel="true"
    :on-cancel="closeCreateMode"
    :class="{'mobile-wizard': rightSidebarFullScreen}"
    @on-tab-update="updateTab"
  >
    <template slot="header">
      <h3 class="title">
        {{ ACTIVE_SCENARIO.name }}
      </h3>
    </template>
    <!-- STUDY AREA -->
    <wizard-tab
      id="tab_region"
      :before-change="() => validateStep('tab_region')"
    >
      <template slot="label">
        <i class="fas fa-map" />
        <div v-if="showTabText">
          Study Area
        </div>
      </template>
      <scenario-study-area
        v-if="!rightSidebarFullScreen"
        ref="tab_region"
        @on-validated="onStepValidated"
      />
      <scenario-study-area-renderless
        v-else
        ref="tab_region"
        @on-validated="onStepValidated"
      >
        <div slot-scope="{}" />
      </scenario-study-area-renderless>
    </wizard-tab>
    <!-- GOALS/INFO -->
    <wizard-tab
      id="tab_goals"
      :before-change="() => validateStep('tab_goals')"
      :class="{'mobile-tab-content': rightSidebarFullScreen}"
    >
      <template slot="label">
        <i class="fas fa-info-circle" />
        <div v-if="showTabText">
          {{ goalsLabel }}
        </div>
      </template>
      <scenario-goals
        v-if="bitmapsReady"
        ref="tab_goals"
        :region-indicators-ready="regionIndicatorsReady"
        :region-indicators="regionIndicators"
        :bitmaps-ready="bitmapsReady"
        :scenario="ACTIVE_SCENARIO"
        :simulation-state="simulationState"
        @on-validated="onStepValidated"
      />
      <div
        v-else
        class="md-layout md-layout-item md-size-100 md-alignment-center-center"
      >
        <md-progress-spinner
          :md-diameter="140"
          :md-stroke="8"
          md-mode="indeterminate"
        />
      </div>
    </wizard-tab>
    <!-- CHANGES -->
    <wizard-tab
      v-if="!systemScenario"
      id="tab_changes"
      :before-change="() => validateStep('tab_changes')"
    >
      <template slot="label">
        <i class="fas fa-chart-pie" />
        <div v-if="showTabText">
          Changes
        </div>
      </template>
      <scenario-changes
        v-if="bitmapsReady && !rightSidebarFullScreen"
        ref="tab_changes"
        :simulation-state="simulationState"
        @on-validated="onStepValidated"
      />
      <scenario-changes-renderless
        v-else-if="bitmapsReady"
        ref="tab_changes"
        @on-validated="onStepValidated"
      >
        <div slot-scope="{}" />
      </scenario-changes-renderless>
    </wizard-tab>
    <!-- MANAGEMENT -->
    <wizard-tab
      v-if="!systemScenario"
      id="tab_mgmt"
      :before-change="() => validateStep('tab_mgmt')"
      :class="{'mobile-tab-content': rightSidebarFullScreen}"
    >
      <template slot="label">
        <i class="fas fa-tasks" />
        <div v-if="showTabText">
          Management
        </div>
      </template>
      <scenario-practices
        ref="tab_mgmt"
        :scenario="ACTIVE_SCENARIO"
        :simulation-state="simulationState"
        :region-indicators-ready="regionIndicatorsReady"
        :scroll-practices="false"
        @on-validated="onStepValidated"
      />
    </wizard-tab>
    <!-- CLIMATE -->
    <wizard-tab
      v-if="!historicScenario"
      id="tab_climate"
      :before-change="() => validateStep('tab_climate')"
      :class="{'mobile-tab-content': rightSidebarFullScreen}"
    >
      <template slot="label">
        <i class="fas fa-cloud-sun-rain" />
        <div v-if="showTabText">
          Climate
        </div>
      </template>
      <scenario-climate
        ref="tab_climate"
        :scenario="ACTIVE_SCENARIO"
        :show-title="false"
        @on-validated="onStepValidated"
      />
    </wizard-tab>
  </simple-wizard>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import { SimpleWizard, WizardTab } from '@/components';
import ScenarioStudyArea from "./ScenarioStudyArea";
import ScenarioStudyAreaRenderless from "./ScenarioStudyAreaRenderless";
import ScenarioGoals from "./ScenarioGoals";
import ScenarioChanges from "./ScenarioChanges";
import ScenarioChangesRenderless from "./ScenarioChangesRenderless";
import ScenarioPractices from "./ScenarioPractices";
import ScenarioClimate from "./ScenarioClimate";
import { ScenarioWizardHelpers } from "@/pages/Dashboard/Components/mixins/ScenarioWizardHelpers";
import { User } from "@/pages/Dashboard/Components/mixins/User";
import { ResponsiveLayoutHelpers } from "@/pages/Dashboard/Components/mixins/ResponsiveLayoutHelpers";
export default {
  name: "ScenarioWizard",

  components: {
    SimpleWizard,
    WizardTab,
    ScenarioStudyArea,
    ScenarioStudyAreaRenderless,
    ScenarioGoals,
    ScenarioChanges,
    ScenarioChangesRenderless,
    ScenarioPractices,
    ScenarioClimate
  },
  mixins: [ScenarioWizardHelpers, User, ResponsiveLayoutHelpers], // user profile methods for updating points

  props: {
    regionIndicators: {
      type: Array,
      required: true
    },
    regionIndicatorsReady: {
      type: Boolean,
      default: false
    },
    bitmapsReady: {
      type: Boolean,
      default: false
    },
    simulationState: {
      type: Object,
      required: true
    },
  },

  data: function () {
    return {
    };
  },

  computed: {
    ...mapGetters([
      types.getters.ACTIVE_SCENARIO_WIZARD_TAB,
      types.getters.ACTIVE_SCENARIO_WIZARD_NEXT,
      types.getters.ACTIVE_SCENARIO_WIZARD_PREV,
      types.getters.ACTIVE_RUNID,
      types.getters.SCENARIO_CONNECTION_ACTIVE,
      types.getters.SELECTED_REGIONS,
      types.getters.USER_SCENARIOS
    ]),

    showTabText() {
      // is there enough space to show text labels with tabs
      return (!this.rightSidebarFullScreen)
    },
    showTabContent() {
      return (['tab_goals', 'tab_mgmt', 'tab_climate'].includes(this.ACTIVE_SCENARIO_WIZARD_TAB) || !this.rightSidebarFullScreen);
    },
    showTabActions() {
      return ['tab_goals', 'tab_mgmt', 'tab_climate'].includes(this.ACTIVE_SCENARIO_WIZARD_TAB);
    },
    goalsLabel() {
      return this.systemScenario ? 'Info' : 'Goals';
    },
    newScenarioTransaction: function () {
      // create a new transaction id to save to dynamo
      let id = -1;
      this.USER_SCENARIOS.forEach(scen => {
        if (scen.id > id) {
          id = scen.id;
        }
      });
      return "scen" + (id + 1).toString();
    },
  },

  watch: {
    ACTIVE_SCENARIO_WIZARD_NEXT() {
      // trigger next tab on wizard
      this.$refs.wizard.nextTab();
    },
    ACTIVE_SCENARIO_WIZARD_PREV() {
      // trigger previous tab on wizard
      this.$refs.wizard.prevTab();
    }
  },

  methods: {
    ...mapActions([
      types.actions.SET_SCENARIO_WIZARD_TAB,
      types.actions.TOGGLE_REFRESH_REGIONS_LAYER,
      types.actions.TOGGLE_REMOVE_REGIONS_LAYER,
      types.actions.SET_SHOW_PLANNING_LAYER,
      types.actions.PLANNING_MARKERS_DRAGGABLE_ON,
      types.actions.PLANNING_MARKERS_DRAGGABLE_OFF,
      types.actions.UPDATE_ACTIVE_SCENARIO,
      types.actions.SET_EXPLORE_MODE,
      types.actions.CLEAR_SCENARIO_AND_REGIONS,
      types.actions.OPEN_SCENARIO_COMPUTING_DIALOG,
      types.actions.NEW_ACTIVE_RUNID,
      types.actions.SET_ACTIVE_RUNID_NEW_SCENARIO_ID,
      types.actions.LOAD_USER_SCENARIOS
    ]),

    validateStep(ref) {
      // validate using the validate() function on the ref component
      return this.$refs[ref].validate()
    },
    onStepValidated(res, tab) {
      // update the data from the child component
      if (res && this.historicScenario && tab === 'tab_goals') {
        // historic scenario
        this.submitScenario();
      } else if (res && tab === 'tab_climate') {
        // bau and land use scenarios both have climate as the last tab
        this.submitScenario();
      } else if (res && !this.systemScenario && tab === 'tab_goals' && !this.rightSidebarFullScreen) {
        // force the tab charts to render properly - hackish but can't figure out how else to make chartist behave
        setTimeout(() => this.$refs.tab_changes.SET_ACTIVE_LT(this.$Region.landUseTypes[0].name), 100);
        setTimeout(() => this.$refs.tab_changes.SET_ACTIVE_LT(null), 100);
      }
    },
    submitScenario() {
      // submit, save and run the new scenario being created

      // get a runid
      // DashboardLayout.vue will automatically subscribe and start listening for message for this runID
      this.NEW_ACTIVE_RUNID();
      // get new scenario id
      const newUserTransaction = this.newScenarioTransaction;
      const newID = parseInt(newUserTransaction.replace("scen", ""));
      // make sure dashboard listener knows it is waiting for a new scenario (not an existing one)
      this.SET_ACTIVE_RUNID_NEW_SCENARIO_ID(newID);

      // launch collecting data animation
      const runTimeEstimate = this.historicScenario ? 150 : 40; // max estimated runtime length in seconds
      this.OPEN_SCENARIO_COMPUTING_DIALOG(runTimeEstimate);

      // get baserasterpath
      let baseRasterPath = this.$Region.base_raster_path;
      if (this.systemScenario) {
        baseRasterPath = this.systemScenario.base_raster_path;
      }

      // setup dynamo payload
      const payload = { ...this.ACTIVE_SCENARIO };
      payload.UserTransaction = newUserTransaction;
      payload.complete = false;
      payload.runTime = 0;
      // DynamoDB needs a different quote configuration than JSON
      // allocations are stored in a modified format
      payload.allocations = JSON.stringify(this.$Helpers.mapScenAllocations(payload.allocations)).replace(/"/g, "'");
      payload.goals = JSON.stringify(payload.goals).replace(/"/g, "'");
      payload.management = JSON.stringify(payload.management).replace(/"/g, "'");
      payload.devRate = (payload.devRate !== null) ? payload.devRate.toString() : "0"; // system scenarios might not have devRate initialized
      if (!this.systemScenario) {
        // update start and end time for land use scenarios
        payload.startTime = this.$Region.landuse_simulation_start_time;
        payload.endTime = this.$Region.landuse_simulation_end_time;
      }
      // sanitize the payload
      this.$Helpers.sanitizeDBPayload(payload);

      // some tracking for checking points later
      const pointsEligible = this.scenarioPointsEligible(this.USER_SCENARIOS, payload.type, payload.studyArea, payload.climate, -1, 0);

      // Push to the DB
      this.$Auth.currentSession().then(authData => {
        const username = authData.accessToken.payload.username;
        payload.UserName = username;
        const path = "/simulation/" + username;
        const myInit = {
          body: payload,
          headers: { Authorization: authData.idToken.jwtToken }
        };

        this.$API
          .put("api", path, myInit)
          .then(() => {
            // Refresh the scenario data or error
            // TODO: Notify error if scenario data were not successfully pushed
            this.LOAD_USER_SCENARIOS({ vm: this });

            // Initiate Lambda Function once the data transfer is complete
            const scenarioInit = {
              body: {
                UserTransaction: payload.UserTransaction,
                UserSub: this.$Auth.user.attributes.sub,
                runID: this.ACTIVE_RUNID,
                baseRaster: baseRasterPath,
                userBucket: this.$Bucket,
                weights: JSON.stringify(this.$Region.simulation_weights),
                allocationAttemptsCutoff: this.$Region
                  .allocation_attempts_cutoff,
                allocationEfficiencyCutoff: this.$Region
                  .allocation_efficiency_cutoff,
                ltTypes: JSON.stringify(this.$Region.lt_types).replace(
                  /"/g,
                  "'"
                ),
                cellSize: this.$Region.cell_size,
                indCellSize: this.$Region.ind_cell_size,
                climateTransitions: JSON.stringify(this.$Helpers.getScenClimateTransitions(this.$Region.climateModuleActive, this.$Region.climateScenarios, payload.climate)).replace(
                  /"/g,
                  "'"
                ),
                climateIndicators: JSON.stringify(this.$Helpers.getScenClimateIndicatorDetails(this.$Region.climateModuleActive, this.$Region.climateScenarios, this.$Region.climateIndicators, payload.climate)).replace(
                  /"/g,
                  "'"
                )
              },
              headers: { Authorization: authData.idToken.jwtToken }
            };

            // notify scenario is computing
            this.USER_SCENARIOS.forEach(scen => {
              if (scen.id === newID) {
                this.scenarioComplete(scen);
              }
            });

            const waitingConnectionPostScenario = () => {
              // make sure the IoT is connected and listening before posting in case the scenario returns very quickly
              if (!this.SCENARIO_CONNECTION_ACTIVE) {
                setTimeout(waitingConnectionPostScenario, 200);
              } else {
                // processing results will automatically be detected by IoT subscription in DashboardLayout.vue
                this.$API.post("api", path, scenarioInit);
                if (pointsEligible) {
                  // give the user some points!
                  this.addUserPoints(this.$Region.awardPoints.scenarioCreate);
                }
                // analytics tracking
                const label = `${payload.type}, ${payload.studyArea}, ${payload.climate}`;
                this.$Helpers.gtagEvent(this, `create_scenario`, `scenarios`, label);
              }
            };
            // post scenario for processing
            waitingConnectionPostScenario();
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error);
          });
      });

      // Leave the page
      this.$notify({
        message: "Your scenario is being calculated",
        icon: "memory",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success"
      });
      // clean up wizard screens and planning map layers
      this.SET_EXPLORE_MODE('explore');
      this.PLANNING_MARKERS_DRAGGABLE_OFF();
      this.CLEAR_SCENARIO_AND_REGIONS();
    },
    scenarioComplete(scen) {
      // notification when scenario complete
      if (scen.incompleteRun) {
        this.$notify({
          message: 'There was an error running scenario "' + scen.name + '"',
          icon: "error_outline",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger"
        });
      } else {
        this.$notify({
          message: 'Scenario "' + scen.name + '" is processing!',
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "success"
        });
      }
    },
    updateTab(activeTab) {
      // when a tab is clicked we need to trigger some things
      const prevTab = this.ACTIVE_SCENARIO_WIZARD_TAB;
      this.SET_SCENARIO_WIZARD_TAB(activeTab);
      if (prevTab === 'tab_region') {
        // trigger map regions refresh if previous tab was tab_regions - need to switch the region json
        this.TOGGLE_REFRESH_REGIONS_LAYER();
      }
      if (activeTab === 'tab_region') {
        // trigger map regions refresh if tab is tab_regions
        this.SET_SHOW_PLANNING_LAYER(false);
        this.TOGGLE_REFRESH_REGIONS_LAYER();
      } else {
        // show map changes layer for other tabs
        this.SET_SHOW_PLANNING_LAYER(true);
      }
      if (activeTab === 'tab_changes') {
        this.PLANNING_MARKERS_DRAGGABLE_ON();
      } else {
        this.PLANNING_MARKERS_DRAGGABLE_OFF();
      }
      return true;
    }
  }
};
</script>
<style>
.mobile-wizard .moving-tab {
  margin-top: 6px;
}
.mobile-wizard .nav-link i {
  transform: translateY(-6px);
}
</style>
<style lang="scss" scoped>
/deep/ .wizard-container {
  width: 100%;
}
/deep/ .moving-tab i {
  font-size: 30px;
}

/deep/ .nav-link {
  margin-left: -15px;
}
/deep/ .nav-link i {
  height: 45px;
}
.mobile-tab-content {
  height: calc(100vh - 265px);
  overflow-y: scroll;
}
.button-align-right {
  display: inline-block;
  width: 100%;
  text-align: right;
}
/* run button transition */
.bounce-enter-active {
  animation: bounce-in 0.3s;
}
.bounce-leave-active {
  animation: bounce-out 0.3s;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bounce-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0);
  }
}
</style>