import { render, staticRenderFns } from "./UserBadgeCard.vue?vue&type=template&id=5dd74568&scoped=true&"
import script from "./UserBadgeCard.vue?vue&type=script&lang=js&"
export * from "./UserBadgeCard.vue?vue&type=script&lang=js&"
import style0 from "./UserBadgeCard.vue?vue&type=style&index=0&id=5dd74568&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dd74568",
  null
  
)

export default component.exports