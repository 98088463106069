<template>
  <md-card
    md-with-hover
    class="md-card-no-elevation allocationCard"
  >
    <div class="md-layout">
      <div class="md-layout-item md-size-15 text-no-padding">
        <md-button
          class="md-button md-dense md-fab allocationIcon"
          :class="allocDataFromName(allocation.name).color"
        >
          {{ allocation.id }}
        </md-button>
      </div>
      <div class="md-layout-item md-size-55">
        <p class="allocationLabel">
          {{ areaToString(areaInHa) }} {{ allocation.name }}
        </p>
      </div>
      <div
        v-if="enableEdits"
        class="md-layout-item md-size-15"
      >
        <md-button
          class="md-simple md-just-icon"
          @click="removeAllocation(allocation.id)"
        >
          <md-icon>delete</md-icon>
          <md-tooltip md-direction="bottom">
            Remove
          </md-tooltip>
        </md-button>
      </div>
      <div
        class="md-layout-item md-size-100 allocationSliderContainer"
        :style="cssVars"
      >
        <slider
          v-model="areaPercent"
          type="allocation"
          :disabled="!enableEdits"
        />
      </div>
    </div>
  </md-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import { Slider } from "@/components";
import { debounce } from "debounce";

export default {
  name: "ScenarioAllocationCard",

  components: {
    Slider
  },

  props: {
    allocation: {
      type: Object,
      required: true,
    },
    enableEdits: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      areaPercent: null,
      areaUpdatedExternal: false // track if an area change was updated by an outside source - don't update scenario if so 
    };
  },

  computed: {
    ...mapGetters([
      types.getters.ACTIVE_SCENARIO,
      types.getters.ALLOCATION_AREA_MAX,
      types.getters.ALLOCATIONS_UPDATED,
      types.getters.ACTIVE_LT
    ]),

    cssVars() {
      // to pass the landtype color as a background to the slider component
      return {
        '--bg-color': this.allocDataFromName(this.allocation.name).hex
      }
    },
    landUseTypes() {
      return this.$Region.landUseTypes;
    },
    areaInHa() {
      // this.areaPercent is a percent
      // this.ALLOCATION_AREA_MAX is the max allowable in ha for the study area
      return this.areaPercent * this.ALLOCATION_AREA_MAX;
    },
  },

  watch: {
    areaPercent(newVal, oldVal) {
      if (!this.areaUpdatedExternal && oldVal !== null) {
        this.debounceAllocationUpdate();
      } else {
        // set external flag back to false
        this.areaUpdatedExternal = false;
      }
    },
    allocation: {
      handler: function () {
        this.areaUpdatedExternal = true;
        this.areaPercent = this.allocation.area / this.ALLOCATION_AREA_MAX;
      },
      deep: true
    }
  },

  beforeMount() {
    this.areaPercent = this.allocation.area / this.ALLOCATION_AREA_MAX;
  },

  methods: {
    ...mapActions([
      types.actions.ALLOCATIONS_UPDATED_PUSH,
      types.actions.ALLOCATIONS_UPDATED_REMOVE,
      types.actions.UPDATE_ACTIVE_SCENARIO,
      types.actions.SET_REMOVE_ALLOCATION_ID,
      types.actions.SHOW_PLANNING_MARKERS_ON,
      types.actions.SHOW_PLANNING_MARKERS_LOCK_ON,
      types.actions.SHOW_PLANNING_MARKERS_LOCK_OFF
    ]),

    removeAllocation(id) {
      // remove an allocation from the scenario
      const allocIndex = this.ACTIVE_SCENARIO.allocations.findIndex(allocation => allocation.id === id),
        _allocations = JSON.parse(JSON.stringify(this.ACTIVE_SCENARIO.allocations));

      // remove the allocation from the scenario
      _allocations.splice(allocIndex, 1);

      // update allocation numbering and reset allocation_id based on new length
      _allocations.forEach(alloc => {
        if (alloc.id > allocIndex + 1) {
          // decrement id's of any allocations higher than the removed one
          alloc.id -= 1;
        }
      });
      this.SET_REMOVE_ALLOCATION_ID(id); // trigger marker removal from map
      //   clean up
      this.UPDATE_ACTIVE_SCENARIO({ allocations: _allocations });
      if ("complete" in this.ACTIVE_SCENARIO && this.ACTIVE_SCENARIO.complete === true) {
        this.ALLOCATIONS_UPDATED_PUSH(this.ACTIVE_SCENARIO.id); // allocation updated in an existing scenario
      }
    },
    areaToString(area) {
      return this.$Helpers.abbreviateNumber(area) + " ha";
    },
    allocDataFromName(name) {
      let landUseType;
      this.landUseTypes.forEach(lt => {
        if (name === lt.name) {
          landUseType = lt;
        }
      });
      return landUseType;
    },
    debounceAllocationUpdate: debounce(function () {
      // scenario updates when allocation slider changes
      if (!isNaN(this.areaPercent)) {
        const _allocations = JSON.parse(JSON.stringify(this.ACTIVE_SCENARIO.allocations));
        _allocations[this.allocation.id - 1].area = (parseInt(this.areaInHa / 10, 10) + 1) * 10; // round to nearst 10
        this.UPDATE_ACTIVE_SCENARIO({ allocations: _allocations });
        // mark the scenario as updated
        if ("complete" in this.ACTIVE_SCENARIO && this.ACTIVE_SCENARIO.complete === true) {
          this.ALLOCATIONS_UPDATED_PUSH(this.ACTIVE_SCENARIO.id); // allocation updated in an existing scenario
        }
      }
    }, 500),
  }
};
</script>

<style lang="scss" scoped>
.md-card-no-elevation {
  background: transparent;
  box-shadow: none;
}

/deep/ .slider-allocation {
  background: #eeeeee;
  height: 20px;
}

/deep/ .slider-allocation .noUi-connect {
  background-color: var(--bg-color);
}

/deep/ .slider-allocation .noUi-handle {
  height: 20px;
  width: 20px;
  border-radius: 20%;
  border-color: var(--bg-color);
  left: 0;
  top: 0;

  &.noUi-active {
    transform: scale3d(1.25, 1.25, 1);
  }
}

.allocationIcon {
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  width: 28px;
  min-width: 28px;
  height: 28px;
  margin-top: 10px;
}

/deep/ .allocationIcon .md-ripple .md-button-content {
  top: 0;
  left: -0.95em;
}

.allocationCard {
  margin: 10px 0 10px 0;
  padding: 0px;
}

.allocationLabel {
  margin: 0;
  padding: 5px 0 0 10px;
  line-height: 18px;
}

.allocationSliderContainer {
  margin: -10px 0 0 0;
  height: 44px;
}
</style>
