<template>
  <div>
    <h5 class="info-text">
      Include a photo or video with your observation
    </h5>
    <vue-dropzone
      id="dropzone"
      ref="mediaDropzone"
      :options="dropzoneOptions"
      @vdropzone-complete="onDropzoneComplete"
      @vdropzone-error="onDropzoneError"
    />
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  name: "ObservationNewMedia",

  components: {
    vueDropzone: vue2Dropzone
  },

  props: {
    observationUserTransaction: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      dropzoneOptions: {
        acceptedFiles: "image/jpeg,image/png,image/gif,.mp4,.mpg,.m4v,.m2ts,.mov",
        addRemoveLinks: true,
        autoProcessQueue: false,
        maxFiles: 1,
        maxFilesize: 500,
        timeout: 0,
        renameFile: this.getDropzoneFileRename,
        resizeWidth: 1920,
        resizeHeight: 1920,
        thumbnailWidth: 150,
        url: '/'
      }
    };
  },

  computed: {
  },

  methods: {
    validate() {
      // there are no required field validations
      return new Promise(resolve => {
        resolve(true)
      })
        .then(res => {
          this.onSuccess(res);
          return res;
        })
    },
    async onSuccess(res) {
      // submit data to parent wizard
      let fileUploaded = false;
      const _data = {
        media: {
          type: 'none'
        }
      };
      // check if there is a media attachment
      const queuedFiles = this.$refs.mediaDropzone.getQueuedFiles();
      // if there is media to attach, add it
      if (queuedFiles.length > 0) {
        // gather some details
        const mediaType = queuedFiles[0].type.split('/')[0];
        const fileobj = queuedFiles[0].upload.filename
        const bucket = this.getDestBucket(mediaType);

        // add to observation meta
        _data.media.type = mediaType;
        if (mediaType === 'video') {
          _data.media.status = 'Pending';
        } else if (mediaType === 'image') {
          _data.media.status = 'Unoptimized'; // for future compatibilty... unoptimized simply means it hasn't been compressed or otherwise optimized on the server
          _data.media.url = fileobj;
        }
        // update dropzone with pre-signed url etc
        fileUploaded = await this.updateDropzoneOptions(bucket, fileobj);
      }
      this.$emit('on-validated', res, _data);
      return fileUploaded;
    },
    uploadMedia() {
      // process the dropzone queue and upload the media
      // notify user that files are uploading
      this.$notify({
        message: `Uploading your media and attaching to your observation.`,
        icon: "attachment",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success"
      });
      // process the queue
      this.$refs.mediaDropzone.processQueue();
    },
    getDestBucket(filetype) {
      // determine the destination bucket based on the file type
      if (filetype === 'video') {
        return this.$VideoBucket;
      } else if (filetype === 'image') {
        return this.$ImageBucket;
      }
      return 'NONE';
    },
    getDropzoneFileRename(file) {
      // rename uploaded file based on the observation uuid
      // get the original file extension
      const re = /(?:\.([^.]+))?$/;
      const file_ext = re.exec(file.name)[1];
      return `${this.observationUserTransaction}.${file_ext}`;
    },
    async updateDropzoneOptions(bucket, fileobj) {
      // update dropzone options with pre-signed url and auth info
      const _this = this;
      const tempLink = await this.$Auth.currentSession().then(authData => {
        const myInit = {
          headers: { Authorization: authData.idToken.jwtToken },
          body: {
            bucket: bucket
          }
        };
        return myInit;
      }).then(myInit => {
        return _this.getUploadURL(myInit, fileobj);
      }).then(tempLink => {
        // update dropzone params based on templink results
        const params = {
          key: fileobj,
          AWSAccessKeyId: tempLink.fields.AWSAccessKeyId,
          policy: tempLink.fields.policy,
          signature: tempLink.fields.signature,
          "x-amz-security-token": tempLink.fields['x-amz-security-token']
        }
        _this.$refs.mediaDropzone.setOption('url', tempLink.url);
        _this.$refs.mediaDropzone.setOption('params', params);
        return tempLink;
      });
      return tempLink;
    },
    async getUploadURL(init, fileobj) {
      // get a templink for uploading to s3
      const response = await this.$API.post(
        "api",
        `/upload/${fileobj}`,
        init
      );
      return response.body.tempLink;
    },
    onDropzoneComplete(resp) {
      // triggered when dropzone upload is complete either success or fail
      this.$emit('on-upload-complete', resp);
    },
    // eslint-disable-next-line
    onDropzoneError(file, msg, xhr) { // xhr is being ignored here
      // display message to user when a dropzone error occurs.
      let _msg = '';
      if (msg === 'You can not upload any more files.') {
        _msg = `Sorry, we couldn't to attach ${file.name} to your observation. You can only attach a maximum of 1 image or video to each observation.`;
      } else {
        _msg = `Sorry, we couldn't to attach ${file.name} to your observation. ${msg}`;
      }
      this.$notify({
        message: _msg,
        icon: "error_outline",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger"
      });
      this.$refs.mediaDropzone.removeFile(file);
    }
  }

};
</script>
<style lang="scss" scoped>
</style>