// mixin for handling mapbox layers and styles
// including options and methods for adding custom textureoverlay layers into mapbox
import * as mapboxgl from "mapbox-gl";
import "@/mapboxgl-texture-overlay"; // TODO build production version (should be smaller/minified?)
import proj4 from "proj4";
import { bbox } from "@turf/turf";
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
export const MapLayers = {
  data() {
    return {
      mapboxStyles: {
        // list of available pre-made mapbox styles: https://docs.mapbox.com/api/maps/#maps
        streets: "mapbox://styles/mapbox/streets-v11",
        light: "mapbox://styles/mapbox/light-v10",
        dark: "mapbox://styles/mapbox/dark-v10",
        satellite: "mapbox://styles/mapbox/satellite-streets-v11"
      },
      regionJson: null, // variable to store the region json when it's loaded so it doesn't need to be reloaded for planning mode
      mainRegionJson: null, // the main version with metadata and no interior borders
      altRegionJson: null, // alt version with interior sub-region borders
      progressAmount: 0,
      timeOutAmount: 0,
      collectingMapData: true // status to show progress bar
    };
  },

  // define textureLayer and intensityLayer as options so they are not reactive
  // when using them as normal reactive data variables, vue messes up the mapbox styling
  textureLayer: null,
  intensityLayer: null,
  planningMarkers: [],
  currentObsMarker: null,

  computed: {
    /* jshint ignore:start */
    ...mapGetters([
      types.getters.SHOW_LANDUSE_OVERLAY,
      types.getters.MAP_DATALAYER,
      types.getters.SELECTED_REGIONS,
      types.getters.SELECTED_REGION_TYPE,
      types.getters.EXPLORE_MODE,
      types.getters.ACTIVE_SCENARIO_WIZARD_TAB,
      types.getters.SHOW_OBSERVATIONS
    ])
    /* jshint ignore:end */
  },

  methods: {
    /* jshint ignore:start */
    ...mapActions([
      types.actions.SET_BASEMAP_STYLE,
      types.actions.TOGGLE_LANDUSE_OVERLAY
    ]),
    /* jshint ignore:end */

    // -----------------------------------------------------------------
    // TOC METHODS - UI LAYOUT GENERAL METHODS
    // -----------------------------------------------------------------
    incrementBar: function() {
      if (this.progressAmount < 98) {
        this.progressAmount++;
        setTimeout(this.incrementBar, Math.random() * 100);
        if (this.progressAmount === 98) {
          this.initiateTimeOut();
        }
      }
    },
    initiateTimeOut() {
      if (this.collectingMapData) {
        this.timeOutAmount++;
        if (this.timeOutAmount === 100) {
          // Provide an error message and stop trying after 10s
          this.dataError = true;
          this.closeProgressBar();
        } else {
          setTimeout(this.initiateTimeOut, 100);
        }
      }
    },
    launchProgressBar() {
      // set progress to 0 and display the progress bar
      this.progressAmount = 0;
      this.timeOutAmount = 0;
      this.incrementBar();
      this.collectingMapData = true;
    },
    closeProgressBar() {
      this.collectingMapData = false;
    },

    // -----------------------------------------------------------------
    // TOC METHODS - TIMELINE METHODS
    // -----------------------------------------------------------------
    setBitmapTime(year) {
      this.$options.intensityLayer.setTime(year); // intensityLayer comes from MapLayers.js mixin
      this.map.triggerRepaint();
    },

    // -----------------------------------------------------------------
    // TOC METHODS - GENERAL MAP METHODS
    // -----------------------------------------------------------------
    removeLayer(id) {
      // safe removal of mapbox layer
      // avoid race condition errors where async map layer is not there
      if (this.map.getLayer(id) !== undefined) {
        try {
          this.map.removeLayer(id);
        } catch (e) {
          return false; //do nothing
        }
      }
    },
    removeSource(id) {
      // safe removal of mapbox source
      // avoid race condition errors where async map source is not there
      if (this.map.getSource(id) !== undefined) {
        try {
          this.map.removeSource(id);
        } catch (e) {
          return false; //do nothing
        }
      }
    },
    addHillshadeLayer() {
      // adds DEM and hillshade layer
      if (typeof this.map.getLayer("hillshading") === "undefined") {
        let firstSymbolId;
        const layers = this.map.getStyle().layers;
        for (let i = 0; i < layers.length; i++) {
          if (layers[i].type === "symbol") {
            firstSymbolId = layers[i].id;
            break;
          }
        }

        // Add a hillshade
        this.map.addSource("dem", {
          type: "raster-dem",
          url: "mapbox://mapbox.terrain-rgb"
        });
        this.map.addLayer(
          {
            id: "hillshading",
            source: "dem",
            type: "hillshade"
          },
          firstSymbolId
        );
      }
    },
    setStyle(style) {
      // change the basemap style between satellite, streets, light or dark
      // TODO this is still buggy... probably a good idea to emit a pauseanimation before changing styles
      if (style !== null) {
        this.launchProgressBar();
        const _this = this;
        const waitingRemoveLayers = () => {
          // removing layers needs to be wrapped with a timeout because of async map loading stuff
          if (!_this.map.loaded()) {
            setTimeout(waitingRemoveLayers, 200);
          } else {
            _this.removeLayer("hillshading");
            _this.removeSource("dem");
            _this.removeLanduseLayer();
            _this.removeObservationsLayer();
            if (this.EXPLORE_MODE === "create") {
              if (this.ACTIVE_SCENARIO_WIZARD_TAB !== "tab_region") {
                _this.removePlanningLayers();
              } else {
                _this.removeRegionsLayers();
              }
            } else if (this.EXPLORE_MODE === "scenario") {
              _this.removePlanningLayers();
            }
          }
        };
        // add observations icons back to style sprite if they aren't there
        // add the icons used for observations
        this.loadObservationIcons();

        // add hillshade
        const waitingAddHillshade = () => {
          // adding layers needs to be wrapped with a timeout because of async map loading stuff
          if (!_this.map.loaded() || !_this.map.isStyleLoaded()) {
            setTimeout(waitingAddHillshade, 200);
          } else {
            // mapbox resets sprites sometimes.... make sure observation icons are loaded
            if (!_this.map.hasImage("obsgeneral")) {
              // use obsgeneral as a proxy for all of them
              _this.loadObservationIcons();
              setTimeout(waitingAddHillshade, 200);
            }
            _this.addHillshadeLayer();
            if (this.EXPLORE_MODE === "create") {
              if (this.ACTIVE_SCENARIO_WIZARD_TAB !== "tab_region") {
                _this.addPlanningLayers();
              } else {
                _this.addRegionsLayers();
              }
            } else if (this.EXPLORE_MODE === "scenario") {
              _this.addLanduseLayer(this.MAP_DATALAYER);
              _this.addPlanningLayers();
            }
            // observations layer
            this.$Region.observation_types_alt.forEach((type) => {
              if (this.SHOW_OBSERVATIONS[type]) {
                if (type === 'observations') {
                  this.addObservationsLayer();
                } else {
                  this.addSystemObservationsLayer(type);
                }
              }
            });
          }
        };
        waitingRemoveLayers();
        this.map.setStyle(this.mapboxStyles[style]);
        this.SET_BASEMAP_STYLE(style);
        waitingAddHillshade();
        if (
          this.EXPLORE_MODE === "create" &&
          this.ACTIVE_SCENARIO_WIZARD_TAB === "tab_region"
        ) {
          this.initRegionsLayers();
        }
        this.map.triggerRepaint();
        this.closeProgressBar();
      }
    },
    flyTo() {
      // fly to the study area
      let sw = this.getLatLngCoordsFromRegionProj(
        parseFloat(this.scenario.leftCoord),
        parseFloat(this.scenario.bottomCoord)
      );
      sw = [sw.x, sw.y];
      let ne = this.getLatLngCoordsFromRegionProj(
        parseFloat(this.scenario.rightCoord),
        parseFloat(this.scenario.topCoord)
      );
      ne = [ne.x, ne.y];
      this.map.fitBounds([ne, sw], {
        padding: {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20
        },
        speed: 0.8,
        pitch: 20,
        bearing: 5
      });
    },
    getLatLngCoordsFromRegionProj(x, y) {
      // convert base projection coords to lat lng
      const destproj = new proj4.Proj("EPSG:4326");
      const sourceproj = new proj4.Proj(this.$Region.base_projection);
      return proj4.transform(sourceproj, destproj, [x, y]);
    },

    // -----------------------------------------------------------------
    // TOC METHODS - OBSERVATION METHODS
    // -----------------------------------------------------------------
    newObsMarker(obsType, coordinates, draggable) {
      // create a new observation marker and add it to the map
      // note that the visibility will still be hidden until the 'bounceInMarker' class is added to the marker element
      if (this.$options.currentObsMarker) {
        this.$options.currentObsMarker.remove();
      }
      const marker = new mapboxgl.Marker({
        element: this.obsMarkerIcon(obsType, draggable), // default to general observation type
        anchor: "bottom",
        draggable: draggable
      })
        .setLngLat(coordinates)
        .addTo(this.map);

      // store the maker as currentObsMarker
      this.$options.currentObsMarker = marker;
      return marker;
    },
    obsMarkerIcon(obsType, draggable) {
      // get an observation marker icon element
      const el = document.createElement("div");
      el.className = "hideMarker";
      el.appendChild(this.obsMarkerIconInnerHTML(obsType));
      if (!draggable) {
        el.addEventListener("click", this.loadedObservationOnClick);
      }
      return el;
    },
    obsMarkerIconInnerHTML(obsType) {
      // get the innerHTML portion for an observation marker
      const iconTypes = this.$Region.observation_types;
      const el = document.createElement("span");
      el.className = "fa-stack fa-2x";
      el.style.color = iconTypes[obsType].color;
      el.innerHTML = `<i class="fas fa-map-marker fa-stack-2x" style="-webkit-text-stroke-width: 2px; -webkit-text-stroke-color: white;"/>
                      <i class="fas ${iconTypes[obsType].icon} fa-stack-1x fa-inverse" style="transform: scale(0.5, 0.5) translate(0, -0.25em);" />`;
      return el;
    },

    // -----------------------------------------------------------------
    // TOC METHODS - LANDUSE OVERLAY & BITMAP METHODS
    // -----------------------------------------------------------------
    getLTBounds() {
      // return the uppermost and lowermost LT's based on their lt_type id's (example: Forest: 1 would be min and Alpine: 7 would be max)
      // used to help set color buffer for textureoverlay to ensure all LT's render properly
      const minLT = Object.keys(this.$Region.lt_types).filter(ltType => {
        return (
          this.$Region.lt_types[ltType] ==
          Math.min.apply(null, Object.values(this.$Region.lt_types))
        );
      });
      const maxLT = Object.keys(this.$Region.lt_types).filter(ltType => {
        return (
          this.$Region.lt_types[ltType] ==
          Math.max.apply(null, Object.values(this.$Region.lt_types))
        );
      });
      return [minLT, maxLT];
    },
    setLTTextureColors() {
      // return colors for all LT's in format needed for textureoverlay
      // start and end colors are duplicated with 0 and ltTypes.length as centers for the 2 ends of the colormap
      const ltBounds = this.getLTBounds(),
        alpha = 0.65, // TODO this could be dynamically set by user via ui
        colors = [this.textureColorDef(ltBounds[0], alpha)]; // add the start color
      colors[0].center = 0; // set center to 0 for first color
      // get the colors for all LT's
      Object.keys(this.$Region.colors).forEach(ltType => {
        // const rgb = this.hexToRgb(this.$Region.colors[ltType]);
        colors.push(this.textureColorDef(ltType, alpha));
      });
      // add the end color
      colors.push(this.textureColorDef(ltBounds[1], alpha));
      colors[colors.length - 1].center += 1;
      return colors;
    },
    textureColorDef(id, alpha) {
      // get a color definition for an LT in the format required for textureoverlay
      const rgb = this.hexToRgb(this.$Region.colors[id]);
      return {
        r: rgb[0],
        g: rgb[1],
        b: rgb[2],
        alpha: alpha,
        center: this.$Region.lt_types[id]
      };
    },
    setTextureColors(type) {
      // set the intensity map color palette
      let colors = [];
      if (type == "landuse") {
        colors = this.setLTTextureColors();
      } else {
        colors = this.setClimateTextureColors(type);
      }
      this.$options.intensityLayer.setColormap(colors);
      this.map.triggerRepaint();
    },
    setClimateTextureColors(type) {
      const alpha = 0.65;
      const colors = [];
      const ind = this.$Region.climateIndicators.find(ind => ind.name === type);
      Object.keys(ind.mapColors).forEach(col => {
        const rgb = this.hexToRgb(ind.mapColors[col]);
        const _alpha = parseInt(col) !== 0 ? alpha : 0;
        colors.push({
          r: rgb[0],
          g: rgb[1],
          b: rgb[2],
          alpha: _alpha,
          center: parseInt(col)
        });
      });
      return colors;
    },
    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      /* jshint ignore:start */
      return result
        ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16)
          ]
        : null;
      /* jshint ignore:end */
    },
    addLanduseLayer(type = "landuse", isReportMap = false) {
      // add textureoverlay landuse layer to map
      this.addHillshadeLayer();
      if (this.SHOW_LANDUSE_OVERLAY) {
        this.$options.textureLayer = new window.MapboxGLTextureOverlay(
          "landuseOverlay",
          mapboxgl
        );

        // settings dependent on layer type
        const layerReady =
          type === "landuse" ? this.bitmapsReady : this.climateIndsReady;
        const interpFun = type === "landuse" ? "hex-nearest" : "nearest"; // "bell" for heatmap "nearest" for grids
        const fadeFun = type === "landuse" ? "dissolve" : "crossfade";

        // add the layer
        if (typeof this.map.getLayer("landuseOverlay") === "undefined") {
          this.map.addLayer(this.$options.textureLayer, "hillshading");
          this.$options.intensityLayer = this.$options.textureLayer.addLayer();
          this.$options.intensityLayer.setInterpolation(interpFun);
          this.$options.intensityLayer.setFadeFun(fadeFun);
          // todo clipping not working
          // this.$options.textureLayer.setClip(this.regionJson);
        }
        this.setTextureColors(type);
        if (layerReady) {
          // add the data
          this.loadIntensityMapData(isReportMap);
        }
      }
    },
    removeLanduseLayer() {
      // remove the textureoverlay landuse layer from the map
      this.removeLayer("landuseOverlay");
      this.$options.intensityLayer = null;
      this.$options.textureLayer = null;
    },
    loadIntensityMapData(isReportMap = false) {
      // if map is setup with the intensityLayer, go ahead and load the bitmaps
      if (
        typeof this.$options.intensityLayer !== "undefined" &&
        this.$options.intensityLayer !== null &&
        Object.keys(this.intensityMapData).length > 0
      ) {
        this.$options.intensityLayer.setData({
          width: this.intensityMapData.width,
          height: this.intensityMapData.height,
          bitmaps: this.intensityMapData.bitmaps,
          projection: this.$Region.base_projection,
          bounds: {
            left: this.intensityMapData.bounds.left,
            right: this.intensityMapData.bounds.right,
            top: this.intensityMapData.bounds.top,
            bottom: this.intensityMapData.bounds.bottom
          }
        });
        this.closeProgressBar(); // this should always be the last layer to load
        if (!isReportMap && this.EXPLORE_MODE === "scenario") {
          // set the time for computed scenarios, but not for new scenarios being created
          // new scenarios should only have 1 time bitmap
          this.setBitmapTime(this.year);
        }
      }
      setTimeout(() => this.map.resize(), 200);
    },
    loadScenarioStudyAreaJson(previewMode = false) {
      // get the json for the study area associated with an existing scenario
      this.$Auth.currentSession().then(authData => {
        const myInit = {
          headers: { Authorization: authData.idToken.jwtToken }
        };
        const dsPath = `/public/${this.scenario.studyArea}`;
        this.$API
          .get("api", dsPath, myInit)
          .then(body => {
            this.regionJson = body;
            this.addPlanningLayers(previewMode);
            this.closeProgressBar();
          })
          // eslint-disable-next-line
          .catch(err => console.log(err));
      });
    },

    // -----------------------------------------------------------------
    // TOC METHODS - PLANNING REGIONS METHODS
    // -----------------------------------------------------------------
    initRegionsLayers() {
      // Load region data
      this.launchProgressBar();
      // set between main and alt region json
      if (this.ACTIVE_SCENARIO_WIZARD_TAB !== "tab_region") {
        this.regionJson = this.mainRegionJson;
      } else {
        this.regionJson = this.altRegionJson;
      }
      if (this.regionJson !== null && this.SELECTED_REGIONS.length > 0) {
        // region was already loaded and regions were not reset
        this.addRegionsLayers();
        this.map.fitBounds(bbox(this.regionJson), { padding: 20 }); // zoom to region
        this.closeProgressBar();
      } else {
        this.$Auth.currentSession().then(authData => {
          const myInit = {
            headers: { Authorization: authData.idToken.jwtToken }
          };
          let dsPath = this.$Region.base_region;
          if (this.SELECTED_REGION_TYPE !== null && this.SELECTED_REGION_TYPE !== this.$Region.region_type) {
            // selected region type is indigenous instead of watersheds
            dsPath = this.$Region.indigenousStudyAreas;
          }
          let regionPath = [];
          if (this.SELECTED_REGIONS.length > 0) {
            regionPath = this.SELECTED_REGIONS.slice();
            this.fieldIndex = this.SELECTED_REGIONS.length;
            if (this.SELECTED_REGIONS.length === 1) {
              dsPath = regionPath[0];
            } else {
              dsPath = regionPath[0];
              let i;
              for (i = 1; i < regionPath.length; i++) {
                dsPath = dsPath + "__" + regionPath[i];
              }
            }
            dsPath = dsPath + ".geojson";
            this.regionJsonPath = dsPath;
          }
          this.$API
            .get("api", "/public/" + dsPath, myInit)
            .then(body => {
              this.regionJson = body;
              this.addRegionsLayers();
              this.map.fitBounds(bbox(body), { padding: 20 }); // zoom to region
              this.closeProgressBar();
            })
            // eslint-disable-next-line
            .catch(err => console.log(err));
        });
      }
    },
    addPlanningLayers(previewMode = false) {
      // load map layers for planning mode
      // add study area but make it transparent
      // this layer will accept click events for new allocations
      if (
        this.map.getSource("studyArea") === undefined &&
        this.regionJson !== null
      ) {
        this.launchProgressBar(); // this will get cloased later by another function
        const studyAreaJson = JSON.parse(JSON.stringify(this.regionJson));

        this.map.addSource("studyArea", {
          type: "geojson",
          data: studyAreaJson
        });

        let firstSymbolId;
        const layers = this.map.getStyle().layers;
        for (let i = 0; i < layers.length; i++) {
          if (layers[i].type === "symbol") {
            firstSymbolId = layers[i].id;
            break;
          }
        }

        if (typeof this.map.getLayer("studyAreaOutline") === "undefined") {
          this.map.addLayer(
            {
              id: "studyAreaOutline",
              type: "line",
              source: "studyArea",
              layout: {},
              paint: {
                "line-color": "#627BC1",
                "line-width": 3
              }
            },
            firstSymbolId
          );
        }

        if (typeof this.map.getLayer("studyAreaFill") === "undefined") {
          this.map.addLayer(
            {
              id: "studyAreaFill",
              type: "fill",
              source: "studyArea",
              layout: {},
              paint: {
                "fill-color": "#fff",
                "fill-opacity": previewMode ? 0.5 : 0
              }
            },
            "studyAreaOutline"
          );
        }

        // add mouseover event listeners
        this.map.on("mousemove", "studyAreaFill", this.planningFillOnMouseMove);
        this.map.on(
          "mouseleave",
          "studyAreaFill",
          this.planningFillOnMouseLeave
        );
        // add listener for new allocation points
        if (previewMode) {
          // add event listener to launch scenario into explore screen
          this.map.on("click", "studyAreaFill", this.planningPreviewOnClick); // needs to be defined per component (not defined in this mixin)
        } else {
          // add event listener to add planning markers
          this.map.on("click", "studyAreaFill", this.planningOnClick); // needs to be defined per component (not defined in this mixin)
        }

        if (this.EXPLORE_MODE === "create" || previewMode) {
          // get study area extents and zoom map
          this.map.fitBounds(bbox(this.regionJson), { padding: 20 });

          // get the bitmap for the landcover
          if (!previewMode) {
            this.removeLanduseLayer(); // make sure the map is cleaned
            this.$emit("newPlanningJickle", this.regionJicklePath); // send data to parent (Explore.vue) - parent (Explore.vue) will load the bitmaps
            this.$emit("newPlanningRegionJson", this.regionJson.properties);
            this.addLanduseLayer();
          }
        }
        if (this.SHOW_PLANNING_MARKERS && !previewMode) {
          this.loadMarkers();
        }
        this.closeProgressBar();
      }
    },
    removePlanningLayers() {
      // remove the planning layers
      this.map.off("click", "studyAreaFill", this.planningOnClick);
      this.map.off("mousemove", "studyAreaFill", this.planningFillOnMouseMove);
      this.map.off(
        "mouseleave",
        "studyAreaFill",
        this.planningFillOnMouseLeave
      );
      this.removeLayer("studyAreaFill");
      this.removeLayer("studyAreaOutline");
      this.removeSource("studyArea");
      // remove the bitmaps too
      this.removeLanduseLayer();
      this.clearMarkers();
    },
    planningFillOnMouseMove() {
      // this function should be defined at the component level and override this mixin
      // eslint-disable-next-line no-console
      console.log("planningFillOnMouseMove not defined on component.");
    },
    planningFillOnMouseLeave() {
      // this function should be defined at the component level and override this mixin
      // eslint-disable-next-line no-console
      console.log("planningFillOnMouseLeave not defined on component.");
    },
    planningOnClick() {
      // this function should be defined at the component level and override this mixin
      // eslint-disable-next-line no-console
      console.log("planningOnClick not defined on component.");
    },
    planningPreviewOnClick() {
      // this function should be defined at the component level and override this mixin
      // eslint-disable-next-line no-console
      console.log("planningPreviewOnClick not defined on component.");
    },
  }
};
