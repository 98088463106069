/* jshint esversion: 6 */
import Vue from "vue";
import VueRouter from "vue-router";
import VueGtag from "vue-gtag";
import DashboardPlugin from "./material-dashboard";
import VueScrollTo from "vue-scrollto";
import VueChartist from "vue-chartist";

// Sentry error reporting
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  Vue,
  dsn: "https://5181cbab00fe4afba7da77d6691cba36@o559392.ingest.sentry.io/5694094",
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// Plugins
import App from "./App.vue";
import "./font-awesome";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Amplify, { Auth, API } from "aws-amplify";
const aws_exports = require(`${process.env.VUE_APP_CONFIG_ROOT}aws-exports.js`)
  .default;
import swal from "sweetalert2";
import VueTour from "vue-tour";
require("vue-tour/dist/vue-tour.css");
// eslint-disable-next-line
import * as ChartistTooltips from "chartist-plugin-tooltips"; // jshint ignore:line
// to make tooltips plugin available on chartist components use $chartist.plugins.tooltip()
import { ClientTable } from "vue-tables-2";

// App config. variables
Vue.config.productionTip = false;
Amplify.configure(aws_exports);
const Region = require(`${process.env.VUE_APP_CONFIG_ROOT}region-config.js`).default;

// Helpers
import * as Helpers from "./helpers";

// router setup
import routes from "./routes/routes";

// vuex store
import store from "./store/store";

// plugin setup
Vue.use(VueRouter);
Vue.use(VueScrollTo);
Vue.use(DashboardPlugin);
Vue.component(FontAwesomeIcon.name, FontAwesomeIcon);
Vue.use(VueChartist);
Vue.use(VueTour);
// tables
const tableOptions = {
  preserveState: true,
  sortIcon: {
    base: "fas",
    up: "fa-sort-up",
    down: "fa-sort-down",
    is: "fa-sort",
  },
};
Vue.use(ClientTable, tableOptions, false, "bootstrap4");

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "nav-item active",
});
router.beforeEach((to, from, next) => {
  // prevent navigating away when create/edit scenario dialog is open
  let text = "",
    title = "",
    confirmButtonText = "";
  if (store.getters.EDIT_SCENARIO_DIALOG_OPEN) {
    title = "Leave without saving?";
    text = `Your progress will be lost if you leave without submitting`;
    confirmButtonText = "Yes, leave please";
  } else if (
    from.name === "Explore" &&
    ["create", "edit"].includes(store.getters.EXPLORE_MODE)
  ) {
    title = "Leave before running?";
    text = `Your scenario settings may be lost if you leave before running your scenario.  Be sure to come back and finish before closing ${Region.app_name}!`;
    confirmButtonText = "Ok";
  }
  if (text === "") {
    next();
  } else {
    const _store = store;
    swal
      .fire({
        title: title,
        text: text,
        icon: "warning",
        iconHtml: `<i class="fas fa-exclamation"></i>`,
        showCancelButton: true,
        customClass: {
          confirmButton: "md-button md-success",
          cancelButton: "md-button md-danger",
        },
        confirmButtonText: confirmButtonText,
        buttonsStyling: false,
      })
      .then((result) => {
        if (result.value) {
          // Move on to the route
          _store.dispatch("CLOSE_EDIT_SCENARIO_DIALOG");
          next();
        } else {
          next(false);
        }
      });
  }
});

// google analytics setup
if ("googleAnalyticsID" in Region) {
  Vue.use(
    VueGtag,
    {
      config: { id: Region.googleAnalyticsID },
    },
    router
  );
}

// global library setup
const properties = [
  "Auth",
  "API",
  "Region",
  "Bucket",
  "ImageBucket",
  "VideoBucket",
  "Helpers",
];

properties.forEach((property) => {
  Object.defineProperty(Vue.prototype, `$${property}`, {
    get() {
      return this.$root[property];
    },
  });
});

// global filters
Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("pluralize", (word, amount) => (amount > 1 ? `${word}s` : word));

Vue.filter("formatNumber", function(number) {
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return "";
});

Vue.filter("formatNumberSigned", function(number) {
  if (number) {
    const sign = number > 0 ? "+" : "";
    const formattedNum = number
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `${sign} ${formattedNum}`;
  }
  return "";
});

Vue.filter("truncate", function(text, stop, clamp) {
  return text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  data() {
    return {
      Auth,
      API,
      Region,
      Helpers,
      Bucket: aws_exports.userfiles,
      ImageBucket: aws_exports.imageuploads,
      VideoBucket: aws_exports.videouploads,
    };
  },
  render: (h) => h(App),
  store,
  router,
});
