/* jshint ignore: start */
// mixin for some land type handling
import { mapGetters } from "vuex";
import { types } from "@/store/types";
import * as fillDonut from "chartist-plugin-fill-donut";
export const LandTypes = {
  data() {
    return {};
  },

  computed: {
    ...mapGetters([
      types.getters.ACTIVE_SCENARIO,
      types.getters.ACTIVE_SCENARIO_SHARED,
    ]),

    landUseTypes() {
      return this.$Region.landUseTypes.concat(
        this.$Region.noChangeLandUseTypes
      );
    },
    icons() {
      return this.$Region.icons;
    },
    landscapeCompositionChartOptions() {
      return {
        donut: true,
        donutWidth: 45,
        total: 100,
        startAngle: 0,
        showLabel: false,
        plugins: [
          this.$chartist.plugins.tooltip({
            anchorToPoint: false,
            appendToBody: false,
          }),
          fillDonut({ items: [{ content: "" }] }),
        ],
      };
    },
    allowEdits() {
      // if user is allowed to edit, show edit button
      return (
        this.ACTIVE_SCENARIO.type === "Land Use" && !this.ACTIVE_SCENARIO_SHARED
      );
    },
  },

  methods: {
    getButtonClass(landType) {
      return `md-icon-button md-mini ${landType.color} text-white`;
    },
    getIconClass(landType) {
      if (this.icons[landType.name].endsWith !== ".svg") {
        // standard class name icon for material-icons or fontawesome icons
        return `fal fa-${this.icons[landType.name]}`;
      }
      // return null for custom svg icons
      return null;
    },
    getIconSrc(landType) {
      if (this.icons[landType.name].endsWith(".svg") === true) {
        // return src for custom svg icons
        return `/img/${this.icons[landType.name]}`;
      }
      // return null for standard material or fontawesome icons
      return null;
    },
  },
};
