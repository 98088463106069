<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-66 md-medium-size-80 md-small-size-100 md-xsmall-size-100 mx-auto">
      <simple-wizard
        next-button-updating-text="Updating..."
        :wizard-locked="wizardLocked"
        :wizard-updating="wizardUpdating"
      >
        <template slot="header">
          <h3 class="title">
            Build your profile
          </h3>
          <h5 class="category">
            Please complete your profile to use the {{ $Region.app_name }} simulator
          </h5>
          <p class="md-caption">
            By creating a user account you agree to receive newsletters that can be unsubscribed from at any time.
          </p>
        </template>

        <wizard-tab
          v-if="!confirmOnlyStatus && !updateProfile && !isEmptyProfile"
          :before-change="() => validateStep('AboutStep')"
        >
          <template slot="label">
            About
          </template>
          <about-step
            ref="AboutStep"
            :wizard-model="wizardModel"
            :wizard-body="wizardBody"
            @on-validated="onStepValidated"
          />
        </wizard-tab>

        <wizard-tab
          v-if="!updateProfile && !isEmptyProfile"
          :before-change="() => validateStep('VerifyStep')"
        >
          <template slot="label">
            Verify
          </template>
          <verify-step
            ref="VerifyStep"
            :wizard-model="wizardModel"
            :confirm-only="confirmOnlyStatus"
            @on-validated="onStepValidated"
          />
        </wizard-tab>

        <wizard-tab
          v-if="!updateProfile"
          :before-change="() => validateStep('AccountTypeStep')"
        >
          <template slot="label">
            Account Type
          </template>
          <account-type-step
            ref="AccountTypeStep"
            :wizard-model="wizardModel"
            :wizard-body="wizardBody"
            @on-validated="onStepValidated"
          />
        </wizard-tab>

        <wizard-tab :before-change="() => validateStep('InfoStep')">
          <template slot="label">
            Info
          </template>
          <info-step
            ref="InfoStep"
            :wizard-model="wizardModel"
            :wizard-body="wizardBody"
            @on-validated="wizardComplete"
          />
        </wizard-tab>
      </simple-wizard>
    </div>
  </div>
</template>

<script>
import AboutStep from './registerwizard/AboutStep'
import AccountTypeStep from './registerwizard/AccountTypeStep'
import InfoStep from './registerwizard/InfoStep'
import VerifyStep from './registerwizard/VerifyStep'
import { SimpleWizard, WizardTab } from '@/components'
import { User } from "@/pages/Dashboard/Components/mixins/User";
import swal from 'sweetalert2'
import VueGeolocation from 'vue-browser-geolocation'

export default {
  name: "RegisterWizard",

  components: {
    AboutStep,
    AccountTypeStep,
    InfoStep,
    VerifyStep,
    SimpleWizard,
    WizardTab
  },
  mixins: [User], // user profile methods for api calls to database

  props: {
    email: {
      type: String,
      default: null
    },
    password: {
      type: String,
      default: null
    },
    confirmOnly: {
      type: Boolean,
      default: false
    },
    updateProfile: {
      type: Boolean,
      default: false
    },
    isEmptyProfile: {
      type: Boolean,
      default: false
    },
    userData: {
      type: Object,
      default: function () { return {} }
    }
  },
  data() {
    return {
      // The wizardbody shadows the database user information
      wizardModel: {},
      wizardBody: { UserTransaction: 'user0' }, // Registration will always be the first (0) transaction
      confirmOnlyStatus: false, // extend the confirmOnly prop
      confirmCodeReady: false,
      wizardLocked: false,
      wizardUpdating: false
    }
  },

  computed: {
    allEntered() {
      return !(this.$data.password == null || !this.$data.tac || this.$data.email == null)
    }
  },

  beforeMount() {
    this.confirmOnlyStatus = this.confirmOnly;
    if (this.isEmptyProfile) {
      // account is verified but has no profile
      this.wizardModel.username = this.email; // email is a misnomer.. should be username
    }
    else if ('c' in this.$route.query && this.$route.query.c !== '' && this.$route.query.c !== null) {
      // user coming from a confirmation link
      this.confirmOnlyStatus = true;
      this.confirmCodeReady = true;
    }
    else if (this.updateProfile) {
      this.wizardBody = this.userData;
    }
    else if (!this.confirmOnlyStatus) {
      swal.fire({
        title: 'Allow location',
        text: 'Please allow ' + this.$Region.app_name + ' to use your location',
        timer: 2000,
        showConfirmButton: false
      });
    }
    else if (!this.confirmCodeReady) {
      const title = `<p style="color:red;">Account not Verified</p>`;
      const msg = 'You must complete your profile and verify your email address before you can log in';
      swal.fire({
        title: title,
        text: msg,
        buttonsStyling: false,
        confirmButtonClass: 'md-button md-success'
      });
      if (!this.loggedInWithEmail(this.email)) {
        // assume if the user logged in with something not in the form of an email that it was their username
        this.wizardModel.username = this.email;
      }
      this.wizardModel.password = this.password;
      this.wizardBody.email = this.email;
    }
    this.collectLocation();
  },

  methods: {
    loggedInWithEmail(email) {
      // return if a user logged in with a username vs an email
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },

    collectLocation() {
      VueGeolocation.getLocation()
        .then(coordinates => {
          this.wizardBody['lat'] = coordinates.lat;
          this.wizardBody['lng'] = coordinates.lng
        })
    },

    validateStep(ref) {
      // validate using the validate() function on the ref component
      return this.$refs[ref].validate()
    },

    onStepValidated(res, model) {
      this.wizardModel = { ...this.wizardModel, ...model }
    },

    wizardComplete() {
      this.wizardLocked = true;
      this.wizardUpdating = true;

      if (this.updateProfile) {
        // profile update
        swal.fire({
          title: 'Profile Change Complete',
          customClass: {
            confirmButton: "md-button md-success",
          },
          buttonsStyling: false,
          icon: 'success'
        });
        // update the user in DB with API call plus update vuex
        // remove system-controlled fields from the update
        const profileUpdates = JSON.parse(JSON.stringify(this.wizardBody));
        const removeFields = ['history', 'points'];
        removeFields.forEach(field => {
          if (field in profileUpdates) {
            delete profileUpdates[field];
          }
        });
        this.updateUserProfile(profileUpdates);
        this.$router.push({ name: 'User Profile' });
      }
      else if (this.isEmptyProfile) {
        // logged in with an empty profile
        this.wizardBody['lat'] = this.wizardBody['lat'] || 0;
        this.wizardBody['lng'] = this.wizardBody['lng'] || 0;
        this.updateUserProfile(this.wizardBody);
        const swalOpts = this.getNewProfileCompleteSwalOpts();
        this.$router.push('/Dashboard');
        swal.fire(swalOpts);
      }
      else {
        // new user completing wizard
        try {
          // Send the user profile information to the DB
          this.wizardBody['lat'] = this.wizardBody['lat'] || 0;
          this.wizardBody['lng'] = this.wizardBody['lng'] || 0;
          this.saveUserUpdatesToDB(this.wizardBody, 'replace', this.wizardCompleteCallback);
        } catch (err) {
          // eslint-disable-next-line
          console.log(err);
        }

      }
    },

    wizardCompleteCallback() {
      // final stuff to do after user completes wizard and updates are saved to DB
      // used as a callback sent to saveUserUpdatesToDB
      // go to the dashboard
      const swalOpts = this.getNewProfileCompleteSwalOpts();
      const vm = this;
      swal.fire(swalOpts).then((result) => {
        if (result.value || result.isDismissed || result.dismiss) {
          vm.$router.push('/Dashboard')
        }
      });
      // setTimeout(this.$router.push('/Dashboard'), 5000);
      // analytics tracking
      this.$Helpers.gtagEvent(this, `sign_up`, `engagement`, 'cognito');
    },

    hasFeaturedSponsors() {
      // are there featured sponsors to display
      return ('sponsors' in this.$Region && (this.$Region.sponsors.length > 0) && (this.$Region.sponsors.filter(sponsor => sponsor.featured).length > 0));
    },

    getRandomFeaturedSponsor() {
      // get a featured sponsor for a thank-you message when new users complete registration
      const featuredSponsors = this.$Region.sponsors.filter(sponsor => sponsor.featured);
      return featuredSponsors[Math.floor(featuredSponsors.length * Math.random())];
    },

    getNewProfileCompleteSwalOpts() {
      // get the swal options to be fired when user profile is completed
      let user_first_name = this.wizardModel.firstName || '';
      if (user_first_name.length > 0) {
        user_first_name = ` ${user_first_name}`;
      }
      const swalOpts = {
        title: `Congratulations${user_first_name}!`,
        customClass: {
          confirmButton: "md-button md-success",
        },
        buttonsStyling: false,
        icon: 'success'
      }
      if (this.hasFeaturedSponsors()) {
        const sponsor = this.getRandomFeaturedSponsor();
        swalOpts.html = `<p>You are now able to use the ${this.$Region.app_name} simulator</p>
                        <p>Special thanks to <strong>${sponsor.name}</strong> for supporting ${this.$Region.app_name} and making this simulator possible!</p>`;
        if ('logo' in sponsor && sponsor.logo !== '') {
          swalOpts.html = `${swalOpts.html}<p><img src="${sponsor.logo}"></p>`
        }
      } else {
        swalOpts.text = `You are now able to use the ${this.$Region.app_name} simulator`;
      }
      return swalOpts;
    }
  }
}
</script>
<style>
</style>
