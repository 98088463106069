<template>
  <div class="md-layout flex-column">
    <div class="md-layout-item md-size-100 top-padding study-areas">
      <h4 v-if="showIndStudyAreas">
        What type of region would you like to use for your scenario?
      </h4>
      <div v-if="showIndStudyAreas">
        <md-button
          v-for="type in studyAreaTypes"
          :key="type[0]"
          :class="getButtonClass(type[0])"
          :disabled="SELECTED_REGIONS.length > 0"
          class="studyareatype-button md-dense"
          @click="SET_SELECTED_REGION_TYPE(type[0])"
        >
          <i :class="type[1]" />
          {{ type[0] }}
        </md-button>
      </div>
      <h4 v-if="!rightSidebarFullScreen && SELECTED_REGION_TYPE !== null">
        What {{ SELECTED_REGION_TYPE }} would you like to use for your scenario?
      </h4>
      <span
        v-if="SELECTED_REGIONS.length === 0 && SELECTED_REGION_TYPE !== null"
        class="badge badge-success"
        :class="[{ 'badge-mobile-fullscreen': rightSidebarFullScreen }]"
      >
        <i
          v-if="!rightSidebarFullScreen"
          class="fas fa-caret-left"
        />
        <i
          v-else
          class="fas fa-caret-up"
        />
        {{ selectedRegionTypeInstruction }}
      </span>
      <span
        v-for="(item, index) in SELECTED_REGIONS"
        :key="item"
        :class="getBadgeClass(index)"
      >
        {{ item }}
        <i
          v-if="index < SELECTED_REGIONS.length - 1"
          class="fas fa-chevron-right"
        />
      </span>
      <div v-if="SELECTED_REGIONS.length > 0 && SELECTED_REGIONS.length < 3 && SELECTED_REGION_TYPE === $Region.region_type">
        <p>
          You can use this {{ $Region.region_type }} or you can refine your study area by selecting a sub-{{
            $Region.region_type }}.
        </p>
      </div>
    </div>
    <div class="md-layout-item md-size-100 action-buttons">
      <div>
        <md-button @click.native="closeCreateMode">
          Cancel
        </md-button>
      </div>
      <div class="reset-button">
        <md-button
          :disabled="!SELECTED_REGIONS.length"
          class="md-button md-warning"
          @click="resetRegions()"
        >
          Reset
        </md-button>
      </div>
      <div>
        <md-button
          class="md-success"
          :disabled="!SELECTED_REGIONS.length"
          @click.native="nextTab"
        >
          Next
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import { StudyArea } from "@/pages/Dashboard/Components/mixins/StudyArea";
import { ScenarioWizardHelpers } from "@/pages/Dashboard/Components/mixins/ScenarioWizardHelpers";
import { ResponsiveLayoutHelpers } from "@/pages/Dashboard/Components/mixins/ResponsiveLayoutHelpers";
export default {
  name: "ScenarioStudyArea",

  components: {
  },
  mixins: [StudyArea, ScenarioWizardHelpers, ResponsiveLayoutHelpers],

  props: {
  },

  data() {
    return {
      fieldIndex: 0,
      regionPath: [],
      map: null,
      zIndex: 5,
      dataError: false,
      amount: 0,
      timeOutAmount: 0,
      collectingMapData: true,
      mapOptions: {
        disableDefaultUI: true,
        zoomControl: true
      },
      studyAreaType: this.$Region.region_type,
    };
  },

  computed: {
    ...mapGetters([
      types.getters.SELECTED_REGION_TYPE,
      types.getters.SELECTED_REGION_NAME
    ]),
    showIndStudyAreas() {
      // whether or not to show indigenous study areas
      if (
        'indigenousModuleActive' in this.$Region &&
        this.$Region.indigenousModuleActive &&
        'indigenousStudyAreas' in this.$Region
      ) {
        return true;
      }
      return false;
    },
    studyAreaTypes() {
      const types = [[this.$Region.region_type, 'fas fa-tint']]
      if (this.showIndStudyAreas) {
        types.push(['Indigenous Territory', 'fas fa-feather'])
      }
      return types
    },
    selectedRegionTypeInstruction() {
      const regex = new RegExp('^[aeiou].*', 'i');
      const determiner = regex.test(this.SELECTED_REGION_TYPE) ? 'an' : 'a';
      return `Select a study area by clicking on ${determiner} ${this.SELECTED_REGION_TYPE}`;
    },
  },

  watch: {
  },

  beforeMount() {
    // default to watersheds
    if (!this.showIndStudyAreas) {
      this.SET_SELECTED_REGION_TYPE(this.$Region.region_type);
    } else if (this.SELECTED_REGION_TYPE === null) {
      this.SET_SELECTED_REGION_TYPE(this.$Region.region_type);
    }
  },

  methods: {
    ...mapActions([
      types.actions.SET_SELECTED_REGION_TYPE,
      types.actions.SELECTED_REGIONS_CLEAR,
      types.actions.TOGGLE_REFRESH_REGIONS_LAYER,
      types.actions.TOGGLE_CLEAR_PLANNING_MARKERS
    ]),

    getButtonClass(type) {
      if (this.SELECTED_REGION_TYPE === type) {
        return `md-raised md-primary`;
      }
      return `md-flat md-accent`;
    },
    resetRegions() {
      // reset the region selection and clear the map
      this.SELECTED_REGIONS_CLEAR();
      this.TOGGLE_REFRESH_REGIONS_LAYER(); // trigger map refresh
      this.TOGGLE_CLEAR_PLANNING_MARKERS(); // trigger clearing all planning markers
    },
    incrementBar: function () {
      if (this.amount < 98) {
        this.amount++;
        setTimeout(this.incrementBar, Math.random() * 100);
        if (this.amount === 98) {
          this.initiateTimeOut();
        }
      }
    },
    initiateTimeOut() {
      if (this.collectingMapData) {
        this.timeOutAmount++;
        if (this.timeOutAmount === 100) {
          // Provide an error message and stop trying after 10s
          this.dataError = true;
          this.collectingMapData = false;
        } else {
          setTimeout(this.initiateTimeOut, 100);
        }
      }
    },
    regionSelectEvent(feature) {
      let color = "black";
      let strokeWeight = 2;
      if (feature.getProperty("makeBlue")) {
        color = "blue";
        strokeWeight = 3;
      }
      return {
        fillColor: color,
        strokeColor: color,
        strokeWeight: strokeWeight
      };
    },
    getBadgeClass(index) {
      if (index === this.SELECTED_REGIONS.length - 1) {
        // last item gets an extra class
        return `badge badge-info selected-region`;
      }
      return `badge badge-info`;
    },
    // refreshMap() {
    //   this.dataError = false;
    //   this.fieldIndex = -1;
    //   this.regionPath = [];
    //   this.loadNextRegion(true);
    // }

  }
};
</script>
<style lang="scss" scoped>
.studyareatype-button {
  margin: 2px;
}
.selected-region {
  font-size: 18px;
  transform: translate(10px, 4px);
  line-height: 30px;
}

.reset-button {
  text-align: center;
}

.badge-mobile-fullscreen {
  width: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.study-areas {
  flex-basis: calc(50vh - 140px);
  flex-grow: 4;
  overflow-y: scroll;
  padding-top: 15px;
}

.action-buttons {
  flex-basis: 70px;
  // min-height: 70px;
  align-self: flex-end;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 10px;
}

.align-right {
  text-align: right;
}
</style>