<template>
  <div class="user">
    <div class="photo">
      <img
        :src="AVATAR_TEMPLINK"
        alt="avatar"
      >
    </div>
    <div class="user-info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        @click.capture="clicked"
      >
        <span>{{ userName }}<b class="caret" />
        </span>
      </a>

      <collapse-transition>
        <div v-show="!isClosed">
          <ul class="nav">
            <slot>
              <li>
                <a @click="navigateProfileView">
                  <span class="sidebar-mini">
                    <i class="material-icons">person</i>
                  </span>
                  <span class="sidebar-normal">My Profile</span>
                </a>
              </li>
            </slot>
          </ul>
        </div>
      </collapse-transition>
      <div>
        <ul class="nav">
          <slot>
            <li>
              <a @click="navigateLock">
                <i class="material-icons">lock_open</i>
                <span class="sidebar-normal">Logout</span>
              </a>
            </li>
          </slot>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';
import { User } from "@/pages/Dashboard/Components/mixins/User";

export default {
  components: {
    CollapseTransition
  },
  mixins: [User], // avatar stuff

  props: {
    userName: {
      type: String,
      default: 'User'
    }
  },
  data() {
    return {
      isClosed: true
    }
  },
  beforeMount() {
    // load the profile picture
    this.debounceGetAvatarUrl();
  },
  methods: {
    clicked: function (e) {
      e.preventDefault()
    },
    toggleMenu: function () {
      this.isClosed = !this.isClosed
    },
    navigateProfileView() {
      this.$router.push({ name: 'User Profile' })
    },
    navigateLock() {
      this.$router.push({ name: 'Lock'})
    }
  }
}
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
</style>
