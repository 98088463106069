<template>
  <div>
    <h5 class="info-text">
      Who might you be?
    </h5>
    <div class="md-layout">
      <div class="md-layout-item">
        <div class="md-layout">
          <div class="md-layout-item md-size-33 md-small-size-100">
            <icon-checkbox
              v-model="model.student"
              :disabled="locked"
              icon="fas fa-user-graduate"
              title="Student"
              @input="validateOneOnly('student')"
            />
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <icon-checkbox
              v-model="model.teacher"
              :disabled="locked"
              icon="fas fa-chalkboard-teacher"
              title="Teacher"
              @input="validateOneOnly('teacher')"
            />
          </div>
          <div class="md-layout-item md-size-33 md-small-size-100">
            <icon-checkbox
              v-model="model.general_public"
              :disabled="locked"
              icon="fas fa-globe-americas"
              title="General Public"
              @input="validateOneOnly('general_public')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { IconCheckbox } from '@/components';
import swal from 'sweetalert2'

export default {
  name: "AccountTypeStep",

  components: {
    IconCheckbox
  },
  props: {
    wizardModel: {
      type: Object,
      required: true
    },
    wizardBody: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      locked: false,
      model: {
        student: true,
        teacher: false,
        general_public: false
      },
    }
  },
  methods: {
    validate() {
      // Prepare data for PUT
      let usertype = '';

      if (this.model.student) {
        usertype = 'student'
      }
      else if (this.model.teacher) {
        if ('restrictTeachersToDomains' in this.$Region &&
          this.$Region.restrictTeachersToDomains &&
          !this.$Region.acceptedTeacherDomains.includes(this.wizardBody.email.split('@')[1].toLowerCase())) {
          this.signUpAlert('Teachers must use one of the accepted school domains for their email');
          return
        }
        usertype = 'teacher'
      }
      else {
        usertype = 'general public'
      }
      this.wizardBody['usertype'] = usertype;

      this.$emit('on-validated', true, this.model);
      this.lockItIn = true;
      return Promise.resolve(true)
    },
    validateOneOnly(current) {
      this.model.student = false;
      this.model.teacher = false;
      this.model.general_public = false;
      this.model[current] = true
    },
    signUpAlert(message) {
      swal.fire({
        title: `<p style="color:red;">Not so fast...</p>`,
        text: message,
        customClass: {
          confirmButton: "md-button md-success",
        },
        buttonsStyling: false,
        confirmButtonText: 'OK'
      })
    }
  }
}
</script>
<style>
</style>
