<template>
  <div>
    <chartist
      :data="sparkChartData(modifiedData)"
      :options="sparkChartOptions"
      type="Line"
    />
    <span class="attr-value">
      {{ this.$Helpers.abbreviateNumber(parseFloat(modifiedData[endTime]).toFixed(2)) | formatNumber }}{{ unitsComputed }}
    </span>
    <span
      v-if="dataDelta != 0.0"
      class="attr-delta"
    >
      <i :class="getDeltaClass(dataDelta)" />
      {{ this.$Helpers.abbreviateNumber(dataDelta) | formatNumberSigned }}
    </span>
    <span
      v-else
      class="attr-delta"
    >
      -
    </span>
  </div>
</template>

<script>
export default {
  name: "SparkDataItem",

  props: {
    startTime: {
      type: Number,
      required: true
    },
    endTime: {
      type: Number,
      required: true
    },
    dataset: {
      type: Object,
      required: true
    },
    units: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      modifiedData: null
    }
  },

  computed: {
    sparkChartOptions() {
      return {
        height: '1.5em',
        width: '6ex',
        showPoint: false,
        fullWidth: true,
        chartPadding: { top: 1, right: 5, bottom: 1, left: 5 },
        axisX: { showGrid: false, showLabel: false, offset: 0 },
        axisY: { showGrid: false, showLabel: false, offset: 0 }
      }
    },
    dataDelta() {
      return parseFloat(this.modifiedData[this.endTime] - this.modifiedData[this.startTime]).toFixed(2);
    },
    unitsComputed() {
      // add a space to the units unless it's listed here to have no space
      return ['°C', '%'].includes(this.units) ? this.units : ` ${this.units}`;
    }
  },

  created() {
    // remap ind data from array of vals to single vals
    // indicator vals are returned as arrays not numbers... for indicator val arrays, get the 2nd value only
    this.modifiedData = JSON.parse(JSON.stringify(this.dataset));
    Object.keys(this.modifiedData).forEach(key => {
      if (Array.isArray(this.modifiedData[key])) {
        this.modifiedData[key] = this.modifiedData[key][1];
      }
      this.modifiedData[key] = parseFloat(this.modifiedData[key]);
    });
  },

  methods: {
    sparkChartData(indData) {
      const labels = Object.keys(indData);
      const series = Object.values(indData);
      series.forEach((val, index) => series[index] = { meta: `${labels[index]}:`, value: `${parseFloat(val).toFixed(2)}` });
      return {
        labels: labels,
        series: [series]
      };
    },
    getDeltaClass(number) {
      return number > 0 ? 'fas fa-caret-up' : 'fas fa-caret-down';
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .ct-square,
.ct-square::before {
  display: inline-block;
  max-height: 1.5em;
  padding: 0;
  padding-right: 6ex;
  float: left;
}
/deep/ .ct-chart svg {
  margin: 1px 10px 1px 10px;
  vertical-align: text-bottom;
  float: left;
}
/deep/ .ct-series-a .ct-line,
.ct-series-a .ct-bar {
  stroke: $brand-info;
  stroke-width: 1px;
}
/deep/ .ct-chart-pie .ct-label {
  display: none;
}
</style>
