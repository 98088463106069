// state store for gamification details
import { types } from "../types";

// initial state
const state = {
  challengeCountdown: -1,
  hintCountdown: -1,
  activeHint: null,
  prevHintIndex: -1,
  activeHintAllocation: null,
  activeHintTransition: null,
  showHintDialog: false,
  hintButtonAnimation: false,
  activeChallenge: null,
  showChallengeDialog: false,
  activeQuest: null,
  questButtonAnimation: false,
  challengeSuccessAnimation: false,
  activeBadge: null,
  pendingBadges: [],
  showBadgeDialog: false,
  missionUpdateTrigger: 0,
};

// getters
const getters = {
  [types.getters.CHALLENGE_COUNTDOWN]: (state) => state.challengeCountdown,
  [types.getters.HINT_COUNTDOWN]: (state) => state.hintCountdown,
  [types.getters.ACTIVE_HINT]: (state) => state.activeHint,
  [types.getters.PREVIOUS_HINT_INDEX]: (state) => state.prevHintIndex,
  [types.getters.ACTIVE_HINT_ALLOCATION]: (state) => state.activeHintAllocation,
  [types.getters.ACTIVE_HINT_TRANSITION]: (state) => state.activeHintTransition,
  [types.getters.SHOW_HINT_DIALOG]: (state) => state.showHintDialog,
  [types.getters.HINT_BUTTON_ANIMATION]: (state) => state.hintButtonAnimation,
  [types.getters.ACTIVE_CHALLENGE]: (state) => state.activeChallenge,
  [types.getters.SHOW_CHALLENGE_DIALOG]: (state) => state.showChallengeDialog,
  [types.getters.ACTIVE_QUEST]: (state) => state.activeQuest,
  [types.getters.QUEST_BUTTON_ANIMATION]: (state) => state.questButtonAnimation,
  [types.getters.CHALLENGE_SUCCESS_ANIMATION]: (state) =>
    state.challengeSuccessAnimation,
  [types.getters.SHOW_BADGE_DIALOG]: (state) => state.showBadgeDialog,
  [types.getters.ACTIVE_BADGE]: (state) => state.activeBadge,
  [types.getters.PENDING_BADGES]: (state) => state.pendingBadges,
  [types.getters.MISSION_UPDATE_TRIGGER]: (state) => state.missionUpdateTrigger,
};

// mutations
const mutations = {
  [types.mutations.SET_CHALLENGE_COUNTDOWN](state, count) {
    // set the user's challenge countdown until next challenge is offered
    state.challengeCountdown = count;
  },
  [types.mutations.DECREMENT_CHALLENGE_COUNTDOWN](state) {
    // decrement the user's challenge countdown until next challenge is offered
    state.challengeCountdown -= 1;
  },
  [types.mutations.SET_HINT_COUNTDOWN](state, count) {
    // set the user's hint countdown until next hint is offered
    state.hintCountdown = count;
  },
  [types.mutations.DECREMENT_HINT_COUNTDOWN](state) {
    // decrement the user's hint countdown until next hint is offered
    state.hintCountdown -= 1;
  },
  [types.mutations.SET_ACTIVE_HINT](state, hint) {
    // set the user's active hint
    state.activeHint = hint;
    // clear hint allocation if the hint is being cleared
    if (hint === null) {
      state.activeHintAllocation = null;
    }
  },
  [types.mutations.SET_PREVIOUS_HINT_INDEX](state, idx) {
    // set the user's previous active hint index
    state.prevHintIndex = idx;
  },
  [types.mutations.SET_ACTIVE_HINT_ALLOCATION](state, alloc) {
    // set the user's active hint allocation
    state.activeHintAllocation = alloc;
  },
  [types.mutations.TOGGLE_HINT_BUTTON_ANIMATION](state) {
    // toggle - any change will cause an animation
    state.hintButtonAnimation = !state.hintButtonAnimation;
  },
  [types.mutations.SET_ACTIVE_HINT_TRANSITION](state, transition) {
    // set the user's active hint transition
    state.activeHintTransition = transition;
  },
  [types.mutations.SET_ACTIVE_CHALLENGE](state, challenge) {
    // set the user's active challenge
    state.activeChallenge = challenge;
  },
  [types.mutations.SET_SHOW_HINT_CHALLENGE_DIALOG](state, payload) {
    // set the visibility of hint or challenge dialog modal
    if (payload.type === "hint") {
      state.showHintDialog = payload.status;
    } else {
      state.showChallengeDialog = payload.status;
    }
  },
  [types.mutations.SET_ACTIVE_QUEST](state, quest) {
    // set the user's active quest
    state.activeQuest = quest;
  },
  [types.mutations.TOGGLE_QUEST_BUTTON_ANIMATION](state) {
    // toggle - any change will cause an animation
    state.questButtonAnimation = !state.questButtonAnimation;
  },
  [types.mutations.TOGGLE_CHALLENGE_SUCCESS_ANIMATION](state) {
    // toggle - any change will cause an animation
    state.challengeSuccessAnimation = !state.challengeSuccessAnimation;
  },
  [types.mutations.SET_ACTIVE_BADGE](state, badge) {
    // set the user's active badge
    state.activeBadge = badge;
  },
  [types.mutations.UPDATE_PENDING_BADGES](state, payload) {
    // update list of pending badges
    /* expected payload
    {
      mode: 'push' or 'splice',
      badgeCode: 'someCode'
    }
    */
    if (payload.mode === "push") {
      state.pendingBadges.push(payload.badgeCode);
    } else {
      const badgeIndex = state.pendingBadges.findIndex(
        (badge) => badge === payload.badgeCode
      );
      state.pendingBadges.splice(badgeIndex, 1);
    }
  },
  [types.mutations.SET_SHOW_BADGE_DIALOG](state, status) {
    // set the badge dialog on or off
    state.showBadgeDialog = status;
  },
  [types.mutations.INCREMENT_MISSION_UPDATE_TRIGGER](state) {
    // increment the update trigger so that the mission/dashboard achievements will recalculate if needed
    state.missionUpdateTrigger += 1;
  },
};

// actions
const actions = {
  [types.actions.SET_CHALLENGE_COUNTDOWN]({ commit }, count) {
    // set the user's challenge countdown count
    commit(types.mutations.SET_CHALLENGE_COUNTDOWN, count);
  },
  [types.actions.DECREMENT_CHALLENGE_COUNTDOWN]({ commit }) {
    // set the user's challenge countdown count
    commit(types.mutations.DECREMENT_CHALLENGE_COUNTDOWN);
  },
  [types.actions.SET_HINT_COUNTDOWN]({ commit }, count) {
    // set the user's hint countdown count
    commit(types.mutations.SET_HINT_COUNTDOWN, count);
  },
  [types.actions.DECREMENT_HINT_COUNTDOWN]({ commit }) {
    // set the user's hint countdown count
    commit(types.mutations.DECREMENT_HINT_COUNTDOWN);
  },
  [types.actions.SET_ACTIVE_HINT]({ commit }, hint) {
    // set the user's active hint
    commit(types.mutations.SET_ACTIVE_HINT, hint);
    if (hint !== null) {
      commit(types.mutations.TOGGLE_HINT_BUTTON_ANIMATION);
    }
  },
  [types.actions.CLEAR_ACTIVE_HINT]({ commit }) {
    // set the user's active quest
    commit(types.mutations.SET_ACTIVE_HINT, null);
  },
  [types.actions.SET_PREVIOUS_HINT_INDEX]({ commit }, idx) {
    // set the user's active previous hint index
    commit(types.mutations.SET_PREVIOUS_HINT_INDEX, idx);
  },
  [types.actions.SET_ACTIVE_HINT_ALLOCATION]({ commit }, alloc) {
    // set the user's active hint allocation
    commit(types.mutations.SET_ACTIVE_HINT_ALLOCATION, alloc);
  },
  [types.actions.SET_ACTIVE_HINT_TRANSITION]({ commit }, transition) {
    // set the user's active hint transition
    commit(types.mutations.SET_ACTIVE_HINT_TRANSITION, transition);
  },
  [types.actions.SET_ACTIVE_CHALLENGE]({ commit }, challenge) {
    // set the user's active challenge
    commit(types.mutations.SET_ACTIVE_CHALLENGE, challenge);
  },
  [types.actions.CLEAR_ACTIVE_CHALLENGE]({ commit }) {
    // set the user's active challenge
    commit(types.mutations.SET_ACTIVE_CHALLENGE, null);
  },
  [types.actions.SET_SHOW_HINT_CHALLENGE_DIALOG]({ commit }, payload) {
    // set the dialog status
    commit(types.mutations.SET_SHOW_HINT_CHALLENGE_DIALOG, payload);
  },
  [types.actions.SET_ACTIVE_QUEST]({ commit }, quest) {
    // set the user's active quest
    commit(types.mutations.SET_ACTIVE_QUEST, quest);
    if (quest !== null) {
      commit(types.mutations.TOGGLE_QUEST_BUTTON_ANIMATION);
    }
  },
  [types.actions.CLEAR_ACTIVE_QUEST]({ commit }) {
    // set the user's active quest
    commit(types.mutations.SET_ACTIVE_QUEST, null);
  },
  [types.actions.TOGGLE_QUEST_BUTTON_ANIMATION]({ commit }) {
    commit(types.mutations.TOGGLE_QUEST_BUTTON_ANIMATION);
  },
  [types.actions.TOGGLE_CHALLENGE_SUCCESS_ANIMATION]({ commit }) {
    commit(types.mutations.TOGGLE_CHALLENGE_SUCCESS_ANIMATION);
  },
  [types.actions.SET_ACTIVE_BADGE]({ commit }, badge) {
    // set the user's active badge
    commit(types.mutations.SET_ACTIVE_BADGE, badge);
  },
  [types.actions.CLEAR_ACTIVE_BADGE]({ commit }) {
    // set the user's active badge
    commit(types.mutations.SET_ACTIVE_BADGE, null);
  },
  [types.actions.UPDATE_PENDING_BADGES]({ commit }, payload) {
    // update list of pending badges
    commit(types.mutations.UPDATE_PENDING_BADGES, payload);
  },
  [types.actions.SET_SHOW_BADGE_DIALOG]({ commit }, status) {
    // set the badge dialog status
    commit(types.mutations.SET_SHOW_BADGE_DIALOG, status);
  },
  [types.actions.INCREMENT_MISSION_UPDATE_TRIGGER]({ commit }) {
    commit(types.mutations.INCREMENT_MISSION_UPDATE_TRIGGER);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
