/* jshint ignore: start */
// mixin for handling some basic responsive layout stuff
export const ResponsiveLayoutHelpers = {
  data() {
    return {
      responsive: false,
      rightSidebarMedium: true,
      rightSidebarFullScreen: false,
      isMdXSmall: false,
      isMdSmall: false,
      isMdMed: false,
      isMdLarge: false,
      isMdXLarge: false
    };
  },

  computed: {
    sidebarWidePanels() {
      // whether or not to show wide panels (single column) vs narrow (2 column) panel layout
      if (this.rightSidebarMedium || this.rightSidebarFullScreen) {
        return true;
      }
      return false;
    },
    maxPreviewMaps() {
      // compute how many preview maps to show
      // maximum of 8 to prevent opening too many webGL contexts
      // make full rows based on number of cards per row
      // <= small - single card per row, only get 3
      // medium - 3 cards per row, get 6
      // >= large - 4 cards per row, get 8
      if (this.isMdXSmall || this.isMdSmall) {
        return 3;
      }
      return this.isMdMed ? 6 : 8;
    }
  },

  beforeMount() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },

  methods: {
    onResponsiveInverted() {
      // MAIN NAV ELEMENTS ARE SET BASED ON this.responsive
      // set whether the current window is 990px or less - main nav top and sidebar changes
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
      // RIGHT SIDEBAR BEHAVIOUR IS CONTROLLED BY this.rightSidebarFullScreen and this.rightSidebarMedium
      // set whether the current window is 959px or less - right sidebar should become fullscreen
      if (window.innerWidth < 960) {
        this.rightSidebarFullScreen = true;
      } else {
        this.rightSidebarFullScreen = false;
      }
      // check if window is between 991px and 1220 px and sidebar is not minimized - right sidebar should be a single column wide
      if (
        991 <= window.innerWidth &&
        window.innerWidth < 1220 &&
        !this.$sidebar.isMinimized
      ) {
        this.rightSidebarMedium = true;
      } else {
        this.rightSidebarMedium = false;
      }
      // set variables to track md-layout-sizes
      this.isMdXSmall = window.innerWidth <= 599;
      this.isMdSmall = window.innerWidth > 599 && window.innerWidth <= 960;
      this.isMdMed = window.innerWidth > 960 && window.innerWidth <= 1280;
      this.isMdLarge = window.innerWidth > 1280 && window.innerWidth <= 1919;
      this.isMdXLarge = window.innerWidth > 1920;
    }
  }
};
