<template>
  <md-table class="layout-gray layout-padding-none">
    <md-table-row class="layout-gray">
      <md-table-head>
        <md-button
          id="LandSummaryMapLayerBtn"
          class="md-icon-button md-fab lt-change-button"
          @click="setMapDataLayer()"
        >
          <md-icon>
            map
          </md-icon>
        </md-button>
      </md-table-head>
      <md-table-head>{{ minYear }}</md-table-head>
      <md-table-head>{{ getTableChangeYear() }}</md-table-head>
      <md-table-head v-if="allowEdits">
        <md-button
          id="LandSummaryEditBtn"
          class="md-icon-button md-fab lt-change-button"
          @click="showScenarioChanges()"
        >
          <md-icon>
            edit
          </md-icon>
        </md-button>
      </md-table-head>
      <md-table-head v-else>
        Change
      </md-table-head>
    </md-table-row>
    <md-table-row
      v-for="item in landUseTypes"
      :key="item.id"
      class="layout-gray"
    >
      <md-table-cell>
        <md-button
          :class="getButtonClass(item)"
          @click="showLTDetails(item.name)"
        >
          <md-icon
            :class="getIconClass(item)"
            :md-src="getIconSrc(item)"
          />
        </md-button>
        <md-tooltip md-direction="top">
          {{ item.name }}
        </md-tooltip>
      </md-table-cell>
      <md-table-cell align="right">
        {{ simulationState.simAreas[item.name][minYear] | formatNumber }}
      </md-table-cell>
      <md-table-cell align="right">
        {{ simulationState.simAreas[item.name][getTableChangeYear()] | formatNumber }}
      </md-table-cell>
      <md-table-cell align="right">
        {{ getLTAreaChange(item.name) | formatNumber }}
      </md-table-cell>
    </md-table-row>
  </md-table>
</template>

<script>
import { mapActions } from "vuex";
import { types } from "@/store/types";
import { LandTypes } from "./mixins/LandTypes";
export default {
  name: "LandSummaryTable",

  components: {
  },
  mixins: [LandTypes], //some common lt stuff

  props: {
    year: {
      type: Number,
      required: true
    },
    minYear: {
      type: Number,
      required: true
    },
    maxYear: {
      type: Number,
      required: true
    },
    simulationState: {
      type: Object,
      required: true
    }
  },

  computed: {},

  methods: {
    ...mapActions([
      types.actions.SET_ACTIVE_LT,
      types.actions.TOGGLE_SIDEBAR_CONTENT,
      types.actions.SET_SIDEBAR_CONTENT_TYPE,
      types.actions.SET_MAP_DATALAYER,
      types.actions.SET_EXPLORE_MOBILE_FS_CONTENT
    ]),

    getLTAreaChange(type) {
      const _year = this.year !== this.minYear ? this.year : this.maxYear; // show maxYear-minYear at start of simulation
      const num =
        this.simulationState.simAreas[type][_year] -
        this.simulationState.simAreas[type][this.minYear];
      return (num <= 0 ? "" : "+") + num;
    },
    getTableChangeYear() {
      if (this.minYear !== this.year) {
        return this.year;
      }
      return this.maxYear;
    },
    showLTDetails(type) {
      const landtype = this.$Region.landUseTypes.concat(this.$Region.noChangeLandUseTypes).find(LT => LT.name === type);
      this.SET_SIDEBAR_CONTENT_TYPE('landtype');
      this.SET_ACTIVE_LT(landtype);
      this.TOGGLE_SIDEBAR_CONTENT();
    },
    showScenarioChanges() {
      this.SET_SIDEBAR_CONTENT_TYPE('changes');
      this.TOGGLE_SIDEBAR_CONTENT();
    },
    setMapDataLayer() {
      this.SET_MAP_DATALAYER('landuse');
      this.SET_EXPLORE_MOBILE_FS_CONTENT('map');
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .md-table-head {
  padding: 8px 8px 0 8px;
}
/deep/ .md-table-head-label {
  color: $white-color;
  font-size: 14px;
  text-align: right;
  padding: 0;
  line-height: 40px;
  min-height: 50px;
}
/deep/ .md-table-head-container {
  min-height: 50px;
  text-align: right;
}
/deep/ .lt-change-button {
  margin: 0 0 0 0;
  padding: 0;
}
</style>