/* jshint ignore: start */
// mixin for jickle file handling
import { mapActions } from "vuex";
import { types } from "@/store/types";
export const ScenarioDataHandler = {
  props: {},

  data() {
    return {
      simulationState: {},
      climateIndData: {}, // climate indicator summary data for display in charts
      landuseMapData: {}, // landuse map data, sent to covermap via intensityMapData when landuse map layer selected
      climateMapData: {}, // object with embedded climate map data objects.  sent to covermap via intensityMapData when a specific climate map layer selected
      intensityMapData: {}, // data sent to covermap component for rendering textureOverlay
    };
  },

  computed: {
    emptySimulationState() {
      return {
        totalArea: 0, // total size of the current study area
        simYear: 0,
        simAreas: {}, // actively simulated area of LT's (changes with timeline)
        regionData: {},
      };
    },
  },

  methods: {
    ...mapActions([
      types.actions.CLEAR_INDICATOR_VALS,
    ]),
    getJicklePath(scenario) {
      // determine the path to the bitmaps jickle for a scenario based on the type
      if (scenario.type === "Land Use") {
        // land use scenario
        // shared scenarios have sub specified in their record, non-shared scenario needs to lookup user sub
        const usersub =
          "sub" in scenario ? scenario.sub : this.$Auth.user.attributes.sub;
        return `/${usersub}/scen${scenario.id}_bitmaps.jickle`;
      }
      // system scenario
      const base_raster = this.$Region.system_scenarios.find(
        (s) => s.type === scenario.type
      ).base_raster_path;
      const study_area = scenario.studyArea.replace(".geojson", "");
      const climate_scen =
        scenario.climate.toLowerCase() === "none" ? "" : `_${scenario.climate}`;
      return `/public/${base_raster}_${study_area}${climate_scen}_bitmaps.jickle`;
    },

    /**
     * Load a jickle from API request
     *
     * @param {*} path for file
     * @param {*} onload callback function
     */
    getJickle(path, onload) {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", path, true);
      xhr.responseType = "arraybuffer";
      xhr.onload = function() {
        onload(window.JICKLE.parse(xhr.response));
      };
      xhr.send();
    },

    parseAndLoadLanduseJickle(
      data,
      forceIntensityMap = false,
      updateActiveScenario = true,
      isReportScenario = false
    ) {
      this.loadSimulationBitmapAreas(data, isReportScenario);
      // reset the timeline to 0 once stuff has loaded;
      if (!isReportScenario && this.simulationProgress == 0) {
        this.simulationProgress = 0.0001;
        this.resetAnimation();
        this.initSimAreas();
      }

      // gather some additional scenario stats
      const completedAllocations =
        "allocations" in data
          ? JSON.parse(data.allocations.replace(/'/g, '"'))
          : {};
      const climateTransitions =
        "climateTransitions" in data
          ? JSON.parse(data.climateTransitions.replace(/'/g, '"'))
          : [];
      if (updateActiveScenario) {
        this.UPDATE_ACTIVE_SCENARIO({
          completedAllocations: completedAllocations,
          climateTransitions: climateTransitions,
        });
        // get a clean slate
        this.CLEAR_INDICATOR_VALS();
        // calculate the indicator vals if it's not a report & is an active scenario
        this.calculateIndicatorVals(); // also relies on parent having calculateIndicatorVals function
      }
      // prepare bitmap data for covermap
      this.landuseMapData.width = data.width;
      this.landuseMapData.height = data.height;
      this.landuseMapData.resolution = data.resolution;
      this.landuseMapData.bitmaps = data.bitmaps;
      this.landuseMapData.projection = this.$Region.base_projection;
      this.landuseMapData.bounds = {
        left: data.bounds[0],
        right: data.bounds[2],
        top: data.bounds[3],
        bottom: data.bounds[1],
      };
      if (
        forceIntensityMap ||
        this.MAP_DATALAYER === "landuse" ||
        this.MAP_DATALAYER === null
      ) {
        this.intensityMapData = this.landuseMapData;
      }
    },

    loadSimulationBitmapAreas(data, isReportScenario = false) {
      // parse bitmap data and compute areas for each LT
      this.simulationState.totalArea = 0;
      if ("simAreas" in data) {
        // as of 30-Jan-2020, sim areas are all pre-calculated on the server side lambda function
        this.simulationState.simAreas = JSON.parse(data.simAreas);
        // get the total area
        Object.keys(this.$Region.lt_types).forEach((ltType) => {
          this.simulationState.totalArea += this.simulationState.simAreas[
            ltType
          ][this.minYear];
        });
      } else {
        // legacy code to compute sim areas on client side if they are not available in the jickle
        const yearArray = []; // track the years returned in bitmaps
        let timestep = 1;
        // initialize/reset the simAreas objects
        Object.keys(this.$Region.lt_types).forEach((ltType) => {
          this.simulationState.simAreas[ltType] = {};
        });

        // parse through the bitmaps and get areas of each ltType
        // years are inherently iterated by iterating bitmaps
        data.bitmaps.forEach((bitmap) => {
          // Iterate lt types
          Object.keys(this.$Region.lt_types).forEach((ltType) => {
            const num_cells = bitmap.bitmap.filter(
                (val) => val == this.$Region.lt_types[ltType]
              ).length,
              resolution = "resolution" in data ? data.resolution : null, // hackish backwards compatible for jickles with not resolution property to pass resolution to getLTArea_ha
              ltarea = this.getLTArea_ha(num_cells, resolution);
            this.simulationState.simAreas[ltType][bitmap.time] = ltarea;
            // add start year areas for each LT to totalArea
            if (bitmap.time == this.minYear) {
              this.simulationState.totalArea += ltarea;
            }
          });
          yearArray.push(bitmap.time);
        });

        // determine timestep (annual or decadal -- assume that the timesteps are regular)
        yearArray.sort((a, b) => a - b);
        if (yearArray.length > 1) {
          timestep = yearArray[1] - yearArray[0];
        }

        // interpolate yearly areas if timestep is not annual
        if (timestep > 1) {
          const missingYearsArray = [
            ...Array(this.maxYear - this.minYear + 1).keys(),
          ]
            .map((i) => i + this.minYear)
            .filter((yr) => !yearArray.includes(yr));

          // iterate missing years
          missingYearsArray.forEach((year) => {
            // get interpolation bounds
            const interpolator = new this.$Helpers.Interpolate();
            const prev_yr = this.$Helpers.getPrevOrNextVal(
              yearArray,
              year,
              "prev"
            );
            const next_yr = this.$Helpers.getPrevOrNextVal(
              yearArray,
              year,
              "next"
            );

            // iterate LT's and interpolate
            Object.keys(this.$Region.lt_types).forEach((ltType) => {
              const from_val = this.simulationState.simAreas[ltType][prev_yr],
                to_val = this.simulationState.simAreas[ltType][next_yr],
                ltarea = interpolator
                  .from(prev_yr, next_yr)
                  .to(from_val, to_val)
                  .linear(year);
              this.simulationState.simAreas[ltType][year] = ltarea;
            });
          });
        }
      }
      // set area multiplier as a max value based on study area size: max any single allocation can take is defined in region-config.js
      if (!isReportScenario) {
        this.SET_ALLOCATION_AREA_MAX(
          (this.simulationState.totalArea *
            this.$Region.max_allocation_percent) /
            100
        );
      }
      // ready to go!
      this.landuseSummaryReady = true;
      this.bitmapsReady = true;
    },

    loadStudyAreaData(myInit, studyArea = this.ACTIVE_SCENARIO.studyArea) {
      // load data from the study area geojson into the simulationState
      // load the global region data first
      this.loadGlobalRegionScenarioData();
      // load settings from region-geojson (region-geojson keys should override global region settings)
      this.$API
        .get("api", "/public/" + studyArea, myInit)
        .then((response) => {
          if (!!response && "properties" in response) {
            const regiondata = response.properties;
            this.loadRegionDataKeys(regiondata);
          }
        })
        .then(() => {
          // indicator icons/gauges ready to be rendered now
          this.regionIndicatorsReady = true;
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.error(err));
    },

    loadGlobalRegionScenarioData() {
      // load data from region-config into the simulationState
      Object.keys(this.$Region.scenario_settings).forEach((key) => {
        this.simulationState.regionData[key] = this.$Region.scenario_settings[
          key
        ];
      });
    },

    loadRegionDataKeys(regiondata) {
      // load the regiondata from json into regionIndicators and simulationState
      Object.keys(regiondata).forEach((key) => {
        if (key === "regionIndicators") {
          this.regionIndicators = regiondata[key];
        } else {
          this.simulationState.regionData[key] = regiondata[key];
        }
      });
    },

    getClimateIndPath(ind, scenario) {
      // return the path for a specific climate indicator, scenario and region
      const study_area = scenario.studyArea.replace(".geojson", "");
      const climate_scen = this.$Region.climateScenarios.find(
        (scen) => scen.variable === scenario.climate
      );
      return `/public/${ind.baseRasterPrefix}_${climate_scen.indicatorBaseRasterKey}_${scenario.startTime}-${scenario.endTime}_${study_area}_bitmaps.jickle`;
    },

    async loadClimateBitmaps(ind, myInit, scenario) {
      // load jickle from s3 and parse into object
      // init the mapdata object
      this.climateMapData[ind.name] = {};

      const dsPath = this.getClimateIndPath(ind, scenario);

      const response = await this.$API.post("api", dsPath, myInit);

      // don't know why jickles coming from /public/ folder get different formatted tempLink's than those from /username/ folder
      // might be because of the filenames with special characters and spaces
      let jickleEndpoint = response.body.tempLink;
      if (dsPath.startsWith("/public/")) {
        // replace the space characters with + signs
        // remove the querystring -- causes forbidden error
        jickleEndpoint = jickleEndpoint.replace(/%2520/g, "+").split("?")[0];
      }

      this.getJickle(jickleEndpoint, (data) => {
        this.climateIndData[ind.name] = this.loadClimateIndSummaries(data);

        // prepare bitmap data for covermap
        this.climateMapData[ind.name].width = data.width;
        this.climateMapData[ind.name].height = data.height;
        this.climateMapData[ind.name].bitmaps = data.bitmaps;
        this.climateMapData[ind.name].resolution = data.resolution;
        this.climateMapData[ind.name].projection = this.$Region.base_projection;
        this.climateMapData[ind.name].bounds = {
          left: data.bounds[0],
          right: data.bounds[2],
          top: data.bounds[3],
          bottom: data.bounds[1],
        };

        // data is ready
        this.numClimateIndsReady += 1;
        if (
          this.numClimateIndsReady === this.$Region.climateIndicators.length
        ) {
          this.climateIndsReady = true;
        }

        // load climate data to map if a climate ind is selected for the data layer
        if (this.MAP_DATALAYER === ind.name) {
          this.intensityMapData = this.climateMapData[ind.name];
          this.TRIGGER_MAP_DATALAYER_RELOAD();
        }

        // emit event for reports
        this.$emit(
          "on-climate-data-ready",
          scenario.id,
          ind.name,
          this.climateIndData[ind.name]
        );
      });
    },

    getClimateInds(myInit, scenario) {
      // following 2 lines part of manual debugging...
      this.$Region.climateIndicators.forEach((ind) => {
        this.loadClimateBitmaps(ind, myInit, scenario);
      });
    },

    loadClimateIndSummaries(data) {
      // grab the pre-computed averages for use in charts
      return JSON.parse(data.averages);
    },

    calculateIndicatorVals() {
      // dummy function - parent should have this function
      // eslint-disable-next-line
      console.log(
        `Warning - component called calculateIndicatorVals but no function was available`
      );
    },
  },
};
