import RegionConditions from "./region-conditions";

const RegionIndigenousVoices = {
  title: "Indigenous Voices",
  description: `<p>Welcome to the Indigenous Voices section where you can learn about the Indigenous Peoples in Alberta using the Two Eyed Seeing Model.</p><p>This module achieves two purposes:<ol><li>Allows users to view map overlays to view Treaty areas, Traditional Territories and languages, Reserves and Settlements, Metis Region and Residential School Locations.</li><li>Enables land use planning using an Indigenous lens with Traditional Territory Study areas and Indicators important to individual Indigenous Communities.</li></ol></p>`,
  media: {
    type: "none",
    url: "",
    thumbUrl: "",
  },
  missionItems: [
    {
      title: "Explore the map",
      description:
        "Click on the Explore icon:  Explore the map",
      helpAction: RegionConditions.launchExplore,
      successConditions: [RegionConditions.userHasUsedMap],
      successMsg: "Good job! You found the map.",
      successPrompt:
        "Try creating a historic scenario to see how the landscape has changed over time.",
      icon: "explore",
      iconClass: "",
    },
    {
      title: "Locate your home",
      description:
        "Locate your home, school and community on the satellite imagery. You can click on the location button to zoom to your location.",
      helpAction: RegionConditions.launchExplore,
      successConditions: [RegionConditions.userHasUsedMapZoom],
      successMsg: "Good job! You found your home on the map.",
      successPrompt: "Now try to change your view in the next mission.",
      icon: "explore",
      iconClass: "",
    },
    {
      title: "Choose your view",
      description:
        "Click on the (purple icon for layers) and choose your view.",
      helpAction: RegionConditions.launchExplore,
      successConditions: [RegionConditions.userHasUsedMapView],
      successMsg: "Great job on changing your view.",
      successPrompt: "Now your location in relation to Indigenous Map content.",
      icon: "explore",
      iconClass: "",
    },
    {
      title: "Explore Indigenous map content",
      description:
        "See your location in relation to Treaty area, Traditional territory, Traditional languages, Reserves and settlements, Metis regions, and Residential school locations.",
      helpAction: RegionConditions.launchExplore,
      successConditions: [RegionConditions.userTurnedOnAllItemsOnIndigenousMap],
      successMsg: "Congratulations! You found all the Indigenous Map layers.",
      successPrompt: "Now let's watch an important video.",
      icon: "layers",
      iconClass: ""
    },
    /*
    {
      title: "Lesson plan: Teachers only",
      description:
        "For classroom ready lesson plan see Teacher Resources Section",
      helpAction: RegionConditions.falseConditionTest,
      successConditions: [RegionConditions.falseConditionTest],
      successMsg: "Success message goes here.",
      successPrompt: "Success prompt goes here.",
      icon: "",
      iconClass: "fal fa-chalkboard"
    },
    */
    {
      title: "Watch the videos",
      description:
        "Click on the Video icon and scroll down to the Blackfoot and Stoney Voices videos and watch at least 5 videos.",
      helpAction: RegionConditions.launchBlackfootPerspectiveVideo,
      successConditions: [RegionConditions.videoBlackfootPerspective],
      successMsg: "Thanks for watching this video!",
      successPrompt: "Try creating a historic scenario to see how the landscape has changed over time.",
      icon: "videocam",
      iconClass: ""
    },
    {
      title: "Explore a Traditional Territory in the Past",
      description:
        "Take a look at how the landscape has changed over time by creating a Historic scenario using a traditional territory as your study area.",
      helpAction: RegionConditions.launchScenarios,
      successConditions: [RegionConditions.userHasHistoricIndigenousScenarios],
      successMsg: "Awesome - you created your first Historic Scenario examining an Indigenous Traditional Territory!",
      successPrompt:
        "What do you notice about where the landscape changed in relation to the location of the reserves and settlements?",
      icon: "rotate_left",
      iconClass: "",
    },
    {
      title: "Explore a Traditional Territory in the Future",
      description:
        "Complete the same steps as for the Historic, but this time choose the BAU Scenario.",
      helpAction: RegionConditions.launchScenarios,
      successConditions: [RegionConditions.userHasBAUIndigenousScenarios],
      successMsg: "Good job - you created your first future BAU Scenario examining an Indigenous Traditional Territory!",
      successPrompt:
        "Did you select Environmental and Socio-Economic indiators, or Indigenous Voices indicators? Did the landscape changes over time affect those indicators?",
      icon: "rotate_right",
      iconClass: "",
    },
    {
      title: "Create Your Own Future",
      description:
        "Now create your own future for an Indigenous Traditional Territory by making your own land use plan.",
      helpAction: RegionConditions.launchScenarios,
      successConditions: [RegionConditions.userHasLandUseIndigenousScenarios],
      successMsg: "Nice work! You explored a potential land use plan for an Indigenous Traditional Territory!",
      successPrompt:
        "Keep going! Try running different scenarios to see how outcomes might differ. Try building the same scenario but looking at different indicators.",
      icon: "rotate_right",
      iconClass: "",
    },
    {
      title: "Investigate the land through Two-Eyed Seeing",
      description:
        "Run different scenarios for the same study area looking at both the conventional Environmental and Socio-Economic indicators and the Indigenous Voices indicators to see how they compare.",
      helpAction: RegionConditions.launchScenarios,
      successConditions: [RegionConditions.userHasComparedIndicatorCollections],
      successMsg: "Nice work! You explored a scenario from two different perspectives!",
      successPrompt:
        "Keep going! Try to practice Two-Eyed Seeing on more scenarios!",
      icon: "search",
      iconClass: "",
    },
  ]
};

export default RegionIndigenousVoices;
