/* jshint ignore: start */
// mixin for some object sharing handling
import { v4 as uuidv4 } from "uuid";
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";

export const Sharing = {
  data() {
    return {};
  },

  computed: {
    ...mapGetters([
      types.getters.ACTIVE_SCENARIO,
      types.getters.USER_SCENARIOS,
    ]),
  },

  methods: {
    ...mapActions([
      types.actions.USER_SCENARIOS_UPDATE,
      types.actions.USER_REPORTS_UPDATE,
      types.actions.SET_REPORT_MODE,
    ]),

    getShareObj(objType, shareKey, remapFn, setFn, setKey) {
      // get a shared object from API call
      const authLib = this.$Auth;
      const apiLib = this.$API;
      const path = `/share/${objType}/${shareKey}`;
      const vm = this;
      // API Call
      authLib
        .currentSession()
        .then((authData) => {
          const myInit = {
            headers: { Authorization: authData.idToken.jwtToken },
          };

          apiLib
            .get("api", path, myInit)
            .then((body) => {
              // Parse from DynamoDB
              let objData = null;
              if (objType === "scen") {
                // --------- SHARED SCENARIO ------------
                objData = remapFn(body.Items[0], uuidv4);
                const setParams = {
                  vm: vm,
                };
                setParams[setKey] = objData;
                setFn(setParams);
              } else {
                // --------- SHARED REPORT ------------
                const scenKeys = JSON.parse(
                  body.Items[0].scenKeys.S.replace(/'/g, '"')
                );
                const _scens = [];
                const addSharedScen = function(_payload) {
                  _scens.push(_payload.sharedScen);
                };
                const waitingGetScens = () => {
                  // wrap get scens in a waiting function so that the API promises can all return before proceeding
                  if (_scens.length != scenKeys.length) {
                    setTimeout(waitingGetScens, 100);
                  } else {
                    objData = remapFn(body.Items[0], _scens);
                    const setParams = {
                      vm: vm,
                    };
                    setParams[setKey] = objData;
                    setFn(setParams);
                  }
                };
                scenKeys.forEach((scenKey) => {
                  vm.getShareObj(
                    "scen",
                    scenKey,
                    vm.$Helpers.remapScenData,
                    addSharedScen,
                    "sharedScen"
                  );
                });
                waitingGetScens();
                vm.SET_REPORT_MODE("view");
              }
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.log(error.response);
            });
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.error(err));
    },

    /**
     * update an object in the DB with sharing settings/shareKey
     *
     * @param {string} objType - the object type to be updated (rep or scen)
     * @param {object} obj - the object being updated, a report or scenario object
     * @param {boolean} status - the sharing status to update the object to
     * @param {string} shareKey - the key to be saved/updated (either a valid key or NONE to remove)
     */
    updateShareObj(objType, obj, status, shareKey) {
      // update the sharing settings for an object in the db (report or scenario)
      // setup dynamo payload
      const payload = {
        UserTransaction: `${objType}${obj.id.toString()}`,
      };
      const updates = {
        shareKey: shareKey,
      };
      if (status && objType === "scen") {
        // need to save the user's sub to the record so we can look up land use scenario files when shared
        updates.sub = this.$Auth.user.attributes.sub;
      }
      payload.UpdatePayload = JSON.stringify(updates).replace(/"/g, "'");
      // Push to the DB
      const vm = this;
      this.$Auth.currentSession().then((authData) => {
        const username = authData.accessToken.payload.username;
        payload.UserName = username;
        const path = "/simulation/" + username; // the scenario patch api will also patch reports based on the usertransaction
        const myInit = {
          body: payload,
          headers: { Authorization: authData.idToken.jwtToken },
        };
        this.$API
          .patch("api", path, myInit)
          .then(() => {
            if (status) {
              obj.shareKey = shareKey;
              obj.privateKey = shareKey;
            } else {
              delete obj.shareKey;
            }
            if (objType === "scen") {
              // update the scenarios array
              vm.USER_SCENARIOS_UPDATE(obj);
              // it's possible that the ACTIVE_SCENARIO is different than the obj
              // if the scen id is the same as the ACTIVE_SCENARIO update it too
              if (
                vm.ACTIVE_SCENARIO !== undefined &&
                vm.ACTIVE_SCENARIO !== null &&
                vm.ACTIVE_SCENARIO.id === obj.id
              ) {
                if (status) {
                  vm.ACTIVE_SCENARIO.shareKey = shareKey;
                  vm.ACTIVE_SCENARIO.privateKey = shareKey;
                } else {
                  delete vm.ACTIVE_SCENARIO.shareKey;
                }
              }
            } else if (objType === "rep") {
              // update each scenario in the report - turn sharing on if not already on
              if (status) {
                const scenKeys = []; // track the share keys for this report
                let _shareKey = "";
                let _scens = obj.scenarios;
                if (_scens[0] === undefined) {
                  // vuex hasn't provided scenarios, need to look them up directly from id's
                  _scens = vm.USER_SCENARIOS.filter((scen) =>
                    obj.scenIDs.includes(scen.id)
                  );
                }
                _scens.forEach((scen) => {
                  // if scenario is not shared, get a shareKey and share it
                  if (!("shareKey" in scen)) {
                    _shareKey =
                      "privateKey" in scen ? scen.privateKey : uuidv4();
                    vm.updateShareObj("scen", scen, status, _shareKey);
                  } else {
                    _shareKey = scen.shareKey;
                  }
                  scenKeys.push(_shareKey);
                });
                // save the scenKeys to the report
                const keyUpdates = {
                  scenKeys: scenKeys,
                };
                payload.UpdatePayload = JSON.stringify(keyUpdates).replace(
                  /"/g,
                  "'"
                );
                myInit.body = payload;
                vm.$API.patch("api", path, myInit);
              }
              // else - do nothing... previously shared scenarios will continue to be shared but report will not
              // update the reports array
              vm.USER_REPORTS_UPDATE(obj);
            }
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.log(error);
          });
      });
    },
  },
};
