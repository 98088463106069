// state store for teacher related functionality
import { types } from "../types";

// initial state
const state = {
  students: [],
  studentsDataLoaded: false,
  studentsLastLoaded: null
};

// getters
const getters = {
  [types.getters.STUDENTS]: state => state.students,
  [types.getters.STUDENTS_DATA_LOADED]: state => state.studentsDataLoaded,
  [types.getters.STUDENTS_LAST_LOADED]: state => state.studentsLastLoaded
};

// mutations
const mutations = {
  [types.mutations.STUDENTS_LOAD](state, students) {
    // set the studentsData
    state.students = students;
  },
  [types.mutations.SET_STUDENTS_DATA_LOADED](state, status) {
    // set the status
    state.studentsDataLoaded = status;
  },
  [types.mutations.SET_STUDENTS_LAST_LOADED](state, timestamp) {
    // set the timestamp
    state.studentsLastLoaded = timestamp;
  }
};

// actions
const actions = {
  [types.actions.STUDENTS_LOAD]({ commit }, payload) {
    // Collect the most recent list of students for the current authenticated teacher
    // load user data from the database via api call
    // payload expects a vue model as a {vm: this} ... this should be called from a model like so: this.STUDENTS_LOAD({vm: this})
    // relies on loading global options
    const authLib = payload.vm.$Auth;
    const apiLib = payload.vm.$API;
    const students = [];

    const remapStudentData = function(item) {
      // remap data from API call into data row
      const studentData = {};
      Object.keys(item).forEach(key => {
        if (key === "points") {
          studentData.points = parseInt(item[key].N);
        } else if (key === "history") {
          studentData.history = JSON.parse(item[key].S.replace(/'/g, '"'));
        } else if ("N" in item[key]) {
          studentData[key] = parseFloat(item[key].N);
        } else {
          studentData[key] = item[key].S;
        }
      });
      return studentData;
    };

    // API Call
    authLib
      .currentSession()
      .then(authData => {
        const path = `/students/teacher`;
        const myInit = {
          headers: { Authorization: authData.idToken.jwtToken },
          body: {
            username: authData.accessToken.payload.username
          }
        };
        apiLib
          .post("api", path, myInit)
          .then(body => {
            body.Items.forEach(item => {
              students.push(remapStudentData(item));
            });
            commit(types.mutations.STUDENTS_LOAD, students);
            commit(types.mutations.SET_STUDENTS_DATA_LOADED, true);
            commit(types.mutations.SET_STUDENTS_LAST_LOADED, Date.now());
          })
          .catch(error => {
            console.log(error.response); // eslint-disable-line
          });
      })
      .catch(error => {
        console.log(error.response); // eslint-disable-line
      });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
