<template>
  <div class="md-layout">
    <div
      v-if="!apiResponseReceived"
      class="md-layout-item md-size-100"
    >
      <h5 class="info-text">
        Analyzing username imports...
      </h5>
      <p
        class="text-center md-layout md-layout-item md-alignment-center-center"
      >
        <md-progress-spinner
          class="md-secondary"
          :md-stroke="10"
          :md-diameter="150"
          md-mode="indeterminate"
        />
      </p>
    </div>
    <div
      v-else
      class="md-layout-item md-size-100"
    >
      <h5 class="info-text">
        {{ rowsToImport }} new users ready to import!
      </h5>
      <md-card
        v-if="apiErrorMessages.length > 0"
        class="md-secondary"
      >
        <md-card-header class="md-card-header-danger">
          <md-card-header-text>
            <div class="text-white api-messages-title">
              Some users can not be imported:
            </div>
          </md-card-header-text>
        </md-card-header>
        <md-card-content>
          <ul>
            <li
              v-for="item in apiErrorMessages"
              :key="item"
            >
              {{ getTextFromAPICode(item) }}
            </li>
          </ul>
        </md-card-content>
      </md-card>
      <md-card
        v-if="apiMessages.length > 0"
        class="md-secondary"
      >
        <md-card-header class="md-card-header-warning">
          <md-card-header-text>
            <div class="text-white api-messages-title">
              Some user details were automatically generated or updated:
            </div>
          </md-card-header-text>
        </md-card-header>
        <md-card-content>
          <ul>
            <li
              v-for="item in apiMessages"
              :key="item"
            >
              {{ getTextFromAPICode(item) }}
            </li>
          </ul>
        </md-card-content>
      </md-card>
      <p>
        Please review the list of users and click next to complete the bulk
        import process.
      </p>
    </div>
    <div class="md-layout-item">
      <grid
        v-show="apiResponseReceived"
        ref="grid"
        :data="gridProps.data"
        :columns="gridProps.columns"
        :options="gridProps"
        @click="preventEditing"
        @beforeChange="cancelEditing"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { types } from "@/store/types";
import "tui-grid/dist/tui-grid.css";
import { Grid } from "@toast-ui/vue-grid";
// uses TUI Grid
// https://github.com/nhn/tui.grid/tree/master/packages/toast-ui.vue-grid
// http://nhn.github.io/tui.grid/latest/Grid#restore
export default {
  name: "BulkWizardReview",

  components: {
    grid: Grid,
  },

  props: {
    school: {
      type: String,
      required: true,
    },
    grade: {
      type: String,
      required: true,
    },
    gridData: {
      type: Array,
      required: true,
    },
    toggleUpdate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      validated: false,
      gridProps: {},
      apiResponseReceived: false,
      apiMessages: [],
      apiErrorMessages: [],
      apiWarnings: [
        "generatedPassword",
        "passwordInvalid",
        "userAlreadyExists",
        "generatedEmail",
      ],
      apiErrors: ["noUserName", "userEmailAlreadyExists"],
      importData: [],
      invalidIDs: [],
    };
  },

  computed: {
    ...mapGetters([types.getters.USER_DATA]),
    rowsToImport() {
      return this.gridData.length;
    },
  },

  watch: {
    toggleUpdate() {
      this.$refs.grid.invoke("resetData", this.gridData);
      this.postAPIValidateUsers();
    },
  },

  created() {
    this.gridProps = {
      data: [],
      columns: [
        { name: "firstname", header: "First Name*", editor: "text" },
        { name: "lastname", header: "Last Name*", editor: "text" },
        { name: "UserName", header: "Username*", editor: "text" },
        { name: "email", header: "Email", editor: "text" },
        { name: "password", header: "Password", editor: "text" },
      ],
      usageStatistics: false,
      bodyHeight: 400,
      heightResizable: true,
    };
  },

  beforeMount() {},

  mounted() {
    this.focus();
    this.$nextTick(() => this.focus());
  },

  methods: {
    validate() {
      // clean up - remove any invalidIDs
      if (this.invalidIDs.length) {
        const grid = this.$refs.grid;
        const gridData = grid.invoke("getData");
        this.importData = gridData.filter(
          (row) => !this.invalidIDs.includes(row.importID)
        );
      }
      // post data to API to create new users
      this.postAPICreateUsers();
      this.$emit("on-data-updated", this.importData);

      return new Promise((resolve) => {
        resolve(true);
      });
    },
    addAPIMsg(msg) {
      if (!this.apiMessages.includes(msg)) {
        this.apiMessages.push(msg);
      }
    },
    addAPIErrorMsg(msg) {
      if (!this.apiErrorMessages.includes(msg)) {
        this.apiErrorMessages.push(msg);
      }
    },
    preventEditing() {
      this.$refs.grid.invoke("blur");
    },
    cancelEditing(ev) {
      // prevent any editing
      ev.stop();
    },
    markCellChanged(rowKey, fieldname) {
      this.$refs.grid.invoke(
        "addCellClassName",
        rowKey,
        fieldname,
        "grid-change"
      );
    },
    markCellInvalid(rowKey, fieldname) {
      // also mark first and last name red for easier reviewing
      [fieldname, "firstname", "lastname"].forEach((fld) => {
        this.$refs.grid.invoke("addCellClassName", rowKey, fld, "grid-invalid");
      });
    },
    toggleApiResponseReceived() {
      this.apiResponseReceived = false;
    },
    parseAPIResponse(importValidations) {
      // Parse API response
      // API response importValidations will come in this example shape:
      // importValidations = [
      //   {
      //     field: "UserName",
      //     code: "userAlreadyExists",
      //     oldval: "jdoe234",
      //     newval: "jdoe762",
      //     importID: 0,
      //   },
      //   {
      //     field: "password",
      //     code: "generatedPassword",
      //     oldval: "",
      //     newval: "roundGlass10",
      //     importID: 0,
      //   },
      //   {
      //     field: "password",
      //     code: "generatedPassword",
      //     oldval: "",
      //     newval: "noisyRoad90",
      //     importID: 1,
      //   },
      //   {
      //     field: "password",
      //     code: "generatedPassword",
      //     oldval: "",
      //     newval: "goodmOuse25",
      //     importID: 2,
      //   },
      // ];
      this.apiMessages = [];
      this.apiErrorMessages = [];
      this.invalidIDs = [];
      const grid = this.$refs.grid;
      grid.invoke("resetData", this.gridData);
      importValidations.forEach((row) => {
        // find the row in the grid
        const changeRows = this.$refs.grid.invoke("findRows", {
          importID: row.importID,
        });
        changeRows.forEach((change) => {
          // should theoreticalliy be only 1 changeRow
          const newRow = { ...change };
          newRow[row.field] = row.newval;
          grid.invoke("setRow", change.rowKey, newRow);
          if (this.apiWarnings.includes(row.code)) {
            this.markCellChanged(change.rowKey, row.field);
            this.addAPIMsg(row.code);
          } else {
            // errors that can not be imported
            this.markCellInvalid(change.rowKey, row.field);
            this.addAPIErrorMsg(row.code);
            // flag import id for ignoring
            this.invalidIDs.push(row.importID);
          }
        });
      });
      // if any generic emails were generated, hide them
      if (this.apiMessages.includes("generatedEmail")) {
        importValidations.forEach((row) => {
          if (row.code === "generatedEmail") {
            // find the row in the grid
            const changeRows = this.$refs.grid.invoke("findRows", {
              importID: row.importID,
            });
            changeRows.forEach((change) => {
              // should theoreticalliy be only 1 changeRow
              const newRow = { ...change };
              newRow["email"] = "";
              grid.invoke("setRow", change.rowKey, newRow);
            });
          }
        });
      }
      // update the importData object
      this.importData = grid.invoke("getData");
      this.$emit("on-data-updated", this.importData);
      // done api post - unlock the wizard
      this.$emit("on-api-post-done", true);
      // good to go
      this.apiResponseReceived = true;
      setTimeout(() => {
        this.$refs.grid.invoke("refreshLayout");
      }, 200);
    },
    getTextFromAPICode(code) {
      // return descriptive text for each code
      const codeTexts = {
        generatedPassword: "Passwords were automatically generated",
        passwordInvalid:
          "Password provided was not valid. A revised password was automatically generated.",
        userAlreadyExists:
          "Username already exists. New username was automatically suggested.",
        noUserName:
          "No username was provided. Each new user must have a username.",
        generatedEmail:
          "No email provided. User will be imported, but password retrieval or change requests will not work for this user. Instead they will need to get support from you for password issues. As their teacher, you will be able to reset their password if needed.",
        userEmailAlreadyExists:
          "Email already exists in the system for one or more users. Either use a new email, or have the user login with their existing account. Users with existing emails in the system will not be imported.",
      };
      return codeTexts[code];
    },
    postAPIValidateUsers() {
      // process new users - pre-valideate all users via API
      // lock wizard / updating
      this.$emit("on-api-post-start", true);
      this.$Auth.currentSession().then((authData) => {
        // AWS API gateway wants json in different format
        const _payload = [...this.gridData].map(
          ({
            firstname,
            lastname,
            UserName,
            email,
            password,
            importID,
            // eslint-disable-next-line
            ...rest
          }) => {
            // only keep these fields...
            return { firstname, lastname, UserName, email, password, importID };
          }
        );
        const payload = JSON.stringify(_payload).replace(/"/g, "`");
        // api init
        const path = `/bulkadmin`;
        const myInit = {
          headers: { Authorization: authData.idToken.jwtToken },
          body: {
            UserName: this.USER_DATA.UserName,
            AdminAction: "ValidateNewUsersList",
            Payload: payload,
          },
        };
        this.$API
          .post("api", path, myInit)
          .then((body) => {
            this.parseAPIResponse(body.body.importValidations);
          })
          .catch((error) => {
            console.log(error.response); // eslint-disable-line
          });
      });
    },
    postAPICreateUsers() {
      // process new users - create users via API
      this.$emit("on-api-post-start", true);
      this.$Auth.currentSession().then((authData) => {
        // AWS API gateway wants json in different format
        const _payload = {
          school: this.school,
          grade: this.grade,
          teacher: `${this.USER_DATA.firstname} ${this.USER_DATA.lastname}`,
        };
        _payload.users = [...this.gridData].map(
          ({
            firstname,
            lastname,
            UserName,
            email,
            password,
            importID,
            // eslint-disable-next-line
            ...rest
          }) => {
            // only keep these fields...
            return { firstname, lastname, UserName, email, password, importID };
          }
        );
        const payload = JSON.stringify(_payload).replace(/"/g, "`");
        // api init
        const path = `/bulkadmin`;
        const myInit = {
          headers: { Authorization: authData.idToken.jwtToken },
          body: {
            UserName: this.USER_DATA.UserName,
            AdminAction: "CreateNewUsers",
            Payload: payload,
          },
        };
        this.$API
          .post("api", path, myInit)
          .then(() => {
            this.$emit("on-users-created", true);
            this.validated = true;
            this.$emit("on-api-post-done", true);
            return new Promise((resolve) => {
              resolve(true);
            });
          })
          .catch((error) => {
            console.log(error.response); // eslint-disable-line
          });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .grid-reset-btn {
  margin-left: 10px !important;
}
/deep/ .grid-change {
  background-color: lighten($brand-warning, 35%) !important;
}
/deep/ .grid-invalid {
  background-color: lighten($brand-danger, 35%) !important;
}
.api-messages-title {
  font-weight: bold;
}
.text-white {
  color: $white-color;
}
</style>