// sponsors setup
/*
************ SAMPLE SPONSOR ************
{
  name: "name", // the name of the sponsor
  url: "url", // the sponsor's website
  logo: "logo", // the logo. If no logo is provided, the name will be displayed
  featured: false, // [true, false] whether to show a thank-you pop up for this sponsor when a new user registers
  description: "description", // description to show with thank-you pop-up
},
{
  name: "name",
  url: "url",
  logo: "logo",
  featured: false,
  description: "description",
},
*/

const RegionSponsors = [
  // ------------------- FEATURED SPONSORS -------------------
  {
    name: "Government of Alberta",
    url: "https://landuse.alberta.ca/Pages/default.aspx",
    logo:
      "https://abtom-media.s3.amazonaws.com/media/partners/GoA-FundedInPartBy-Sky-RGB.png",
    featured: true,
    description: "",
  },
  {
    name: "The Samuel Hanen Society for Resource Conservation",
    url: "http://www.hanensociety.com/",
    logo: "",
    featured: true,
    description: "",
  },
  {
    name: "Calgary Foundation",
    url: "https://calgaryfoundation.org/",
    logo:
      "https://abtom-media.s3.amazonaws.com/media/partners/sponsor_CF.565992ca62f2.jpg",
    featured: true,
    description: "",
  },
  {
    name: "Alberta Ecotrust Foundation", // the name of the sponsor
    url: "https://albertaecotrust.com/", // the sponsor's website
    logo:
      "https://abtom-media.s3.amazonaws.com/media/partners/Alberta_Ecotrust_Logo_Blue.png", // the logo. If no logo is provided, the name will be displayed
    featured: true, // [true, false] whether to show a thank-you pop up for this sponsor when a new user registers
    description: "", // description to show with thank-you pop-up
  },
  {
    name: "ALCES",
    url: "https://www.alces.ca/",
    logo:
      "https://abtom-media.s3.amazonaws.com/media/partners/sponsor_alces.009309979c9d.jpg",
    featured: true,
    description: "",
  },
  // ------------------- BASIC SPONSORS -------------------
  {
    name: "Energy Efficiency Alberta",
    url: "https://efficiencyalberta.ca/",
    logo:
      "https://abtom-media.s3.amazonaws.com/media/partners/EEA_Logo_Primary_JPEG_ReverseColour.jpg",
    featured: false,
    description: "",
  },
  {
    name: "Integral Ecology Group",
    url: "https://www.integralecologygroup.com/",
    logo:
      "https://abtom-media.s3.amazonaws.com/media/partners/sponsor_integral.130096cad058.jpg",
    featured: false,
    description: "",
  },
  {
    name: "Canada Helps",
    url: "https://www.canadahelps.org/en/",
    logo:
      "https://abtom-media.s3.amazonaws.com/media/partners/canadahelps-logo-thumbnail.png",
    featured: false,
    description: "",
  },
  {
    name: "RBC",
    url: "https://www.rbcroyalbank.com/",
    logo: "https://abtom-media.s3.amazonaws.com/media/partners/rbc-logo.png",
    featured: false,
    description: "",
  },
  {
    name: "TD Friends of the Environment Foundation",
    url:
      "https://www.td.com/ca/en/about-td/ready-commitment/vibrant-planet/fef/",
    logo: "https://abtom-media.s3.amazonaws.com/media/partners/td-logo.png",
    featured: false,
    description: "",
  },
  {
    name: "Water Rangers",
    url:
      "https://waterrangers.ca/",
    logo: "https://abtom-media.s3.amazonaws.com/media/partners/Water-Rangers-Logo.png",
    featured: false,
    description: "",
  },
];

export default RegionSponsors;
