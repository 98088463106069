<template>
  <div class="text-center land-comp-chart">
    <md-button
      class="md-icon-button md-fab lt-map-button"
      @click="setMapDataLayer()"
    >
      <md-icon>
        map
      </md-icon>
    </md-button>
    <md-button
      v-if="allowEdits"
      class="md-icon-button md-fab lt-change-button"
      @click="showScenarioChanges()"
    >
      <md-icon>
        edit
      </md-icon>
    </md-button>
    <chartist
      :data="landscapeCompositionChartData('start')"
      :options="landscapeCompositionChartOptions"
      type="Pie"
    />
    <span class="md-caption">{{ minYear }}</span>
    <chartist
      :data="landscapeCompositionChartData('end')"
      :options="landscapeCompositionChartOptions"
      type="Pie"
    />
    <span class="md-caption">{{ getTableChangeYear() }}</span>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { types } from "@/store/types";
import { LandTypes } from "./mixins/LandTypes";

export default {
  name: "LandCompositionChart",

  components: {
  },
  mixins: [LandTypes], //some common lt stuff

  props: {
    year: {
      type: Number,
      required: true
    },
    minYear: {
      type: Number,
      required: true
    },
    maxYear: {
      type: Number,
      required: true
    },
    simulationState: {
      type: Object,
      required: true
    }
  },

  computed: {},

  methods: {
    ...mapActions([
      types.actions.TOGGLE_SIDEBAR_CONTENT,
      types.actions.SET_SIDEBAR_CONTENT_TYPE,
      types.actions.SET_MAP_DATALAYER,
      types.actions.SET_EXPLORE_MOBILE_FS_CONTENT
    ]),

    getTableChangeYear() {
      // whether to show the max year or current simulation year
      if (this.minYear !== this.year) {
        return this.year;
      }
      return this.maxYear;
    },
    landscapeCompositionChartData(timeFrame) {
      const labels = [],
        series = [];
      const _this = this;
      const year = (timeFrame === 'start') ? this.minYear : this.getTableChangeYear();
      this.$Region.landUseTypes
        .concat(this.$Region.noChangeLandUseTypes)
        .forEach(ltType => {
          labels.push(ltType.name);
          series.push({
            meta: ltType.name,
            value:
              ((_this.simulationState.simAreas[ltType.name][year] /
                _this.simulationState.totalArea) *
                100).toFixed(2),
            className: ltType.series
          });
        });
      return {
        labels: labels,
        series: series
      };
    },
    showScenarioChanges() {
      this.SET_SIDEBAR_CONTENT_TYPE('changes');
      this.TOGGLE_SIDEBAR_CONTENT();
    },
    setMapDataLayer() {
      this.SET_MAP_DATALAYER('landuse');
      this.SET_EXPLORE_MOBILE_FS_CONTENT('map');
    }
  }
};
</script>

<style lang="scss" scoped>
.text-center {
  text-align: center;
}
.text-no-padding {
  position: relative;
  margin: auto;
  padding: 0px;
}
.land-comp-chart {
  margin: 20px 35px 10px 35px;
}
.md-caption {
  color: $white-color !important;
  line-height: 16px;
  width: 70px;
  position: fixed;
  margin: -110px 0 0 -35px;
  font-size: 15px;
}
/deep/ .chartist-tooltip {
  position: absolute;
  display: none;
  min-width: 5em;
  padding: 8px 10px;
  margin-top: 10px;
  background: #383838;
  color: #fff;
  text-align: center;
  pointer-events: none;
  z-index: 100;
  transition: opacity 0.2s linear;
}
/deep/ .chartist-tooltip:before {
  position: absolute;
  bottom: -14px;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: rgba(251, 249, 228, 0);
  border-top-color: #383838;
  border-width: 7px;
  margin-left: -8px;
}
/deep/ .chartist-tooltip.tooltip-show {
  display: inline-block !important;
}
/deep/ .chartist-tooltip-value:after {
  content: "%";
}
/deep/ .md-white {
  stroke: #fff !important;
}
/deep/ .lt-map-button {
  position: absolute;
  top: 30px;
  background-color: $gray;
  margin: 0 0 0 -126px;
  padding: 0;
}
/deep/ .lt-change-button {
  position: absolute;
  top: 30px;
  background-color: $gray;
  margin: 0 0 0 88px;
  padding: 0;
}
</style>
