/* jshint esversion: 6 */
import RegionScenarios from "./region-scenarios";
import RegionObservations from "./region-observations";
import RegionLandTypes from "./region-landtypes";
import RegionIndicators from "./region-indicators";
import RegionPractices from "./region-practices";
import RegionClimate from "./region-climate";
import RegionSchools from "./region-schools";
import RegionResources from "./region-resources";
import RegionPolicies from "./region-policies";
import RegionMission from "./region-mission";
import RegionVideos from "./region-videos";
import RegionChallenges from "./region-challenges";
import RegionHints from "./region-hints";
import RegionBadges from "./region-badges";
import RegionConditions from "./region-conditions";
import RegionSponsors from "./region-sponsors";
import RegionSocial from "./region-social";
import RegionDidYouKnow from "./region-didyouknow";
import RegionVirTours from "./region-virtours";
import RegionIndigenous from "./region-indigenous";
import RegionIndigenousVoices from "./region-voices";

const Region = {
  // general app info
  app_name: "Alberta Tomorrow",
  environment: "Beta",
  logo: "ab_tomorrow_logo.png",
  homeUrl: "https://albertatomorrow.ca/", // to link back to the organization's home page (leave the simulator)
  showLoginWarning: true, // whether or not to display a registration warning when users fail to sign-in
  map_coords: { lat: 55.037024, lng: -115.0 },
  mapbox_token:
    "pk.eyJ1IjoidXJzdWRpbyIsImEiOiJja2E4OWQ5ZzkwYzVoMnJwaXYwYXhscmViIn0.sbbt5UxiZlv9EGz7jF1TzQ",
  googleAnalyticsID: "UA-41161530-2",

  // regions and sub regions setup
  region_type: "Watershed",
  base_region: "ab_drainage_ws.geojson",
  base_region_field: ["WSCMDANAME", "WSCSDANAME", "WSCSSDANAM"],

  // scenarios and simulations setup
  base_projection:
    'PROJCS["NAD_1983_Transverse_Mercator",GEOGCS["GCS_North_American_1983",DATUM["North_American_Datum_1983",SPHEROID["GRS_1980",6378137.0,298.257222101]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Transverse_Mercator"],PARAMETER["False_Easting",500000.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",-115.0],PARAMETER["Scale_Factor",0.9992],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]',
  base_raster_path: "ab_cover",
  base_raster_time: 2020, // year that the reference bitmaps return as for new scenario computing
  landuse_simulation_start_time: 2020,
  landuse_simulation_end_time: 2050,
  max_allocation_percent: 0.02, // the maximum area a single allocation can be as a percentage of the selected study area
  allocation_attempts_cutoff: 2.5, // as a factor of allocation area, how many attempts before testing for cutoff.  IE, if an area is equivalent to 100 cells, a value of 4 would allow for 400 cell attempts before evaluating for cutoff.
  allocation_efficiency_cutoff: 0.2, // after attempts cutoff is reached, the minimum sustained allocation efficiency required to continue
  simulation_weights: RegionLandTypes.simulation_weights,
  cell_size: 1000,
  ind_cell_size: 5000, // cell size used for climate indicators

  // land type stuff
  lt_types: RegionLandTypes.lt_types,
  colors: RegionLandTypes.colors,
  landUseTypes: RegionLandTypes.landUseTypes,
  noChangeLandUseTypes: RegionLandTypes.noChangeLandUseTypes,
  icons: RegionLandTypes.icons,

  // default scenarios setup
  scenario_settings: RegionScenarios.scenario_settings,
  system_scenarios: RegionScenarios.system_scenarios,
  default_scenario_title: "New Land Use Scenario",
  modelling_description: RegionScenarios.modelling_description,

  // default observations setup
  observation_types: RegionObservations.observation_types,
  observation_types_alt: ["observations", ...Object.keys(RegionObservations.observation_types).filter(key => !["water", "general"].includes(key))],
  system_observations: RegionObservations.system_observations,

  // schools, teachers and classes settings
  schools: RegionSchools.schools,
  curriculumOptions: RegionSchools.curriculumOptions,
  gradeOptions: RegionSchools.gradeOptions,
  restrictTeachersToDomains: RegionSchools.restrictTeachersToDomains,
  acceptedTeacherDomains: RegionSchools.acceptedTeacherDomains,

  // teacher resources and lesson plans
  teacherResources: RegionResources,
  resourceThemeOrder: [
    "Land Use",
    "Indigenous Voices",
    "Climate Change",
    "Glaciers/Watersheds",
    "360 Tours of AB Natural Regions",
    "Bow River 360 Videos",
    "Bow River Watershed"
  ],

  // management practices settings
  mgmtInfo: RegionPractices.GeneralInfo,
  mgmtPractices: RegionPractices.MgmtPractices,
  mgmtPracticesAdvancedActive: true,

  // climate change settings
  climateModuleActive: true,
  climateInfo: RegionClimate.GeneralInfo,
  climateIndicators: RegionClimate.ClimateIndicators,
  climateScenarios: RegionClimate.ClimateScenarios,
  climateDefaultIndex: 2, // default climate change scenario index

  // policies
  policy: RegionPolicies.policy,

  // user mission
  mission: RegionMission,

  // indigenous voices
  voices: RegionIndigenousVoices,

  // general info videos
  system_videos: RegionVideos,

  // indicators and equations
  devRateSliderHelper: RegionIndicators.devRateSliderHelper, // to calculate what to show on the dev rate slider over time
  equationHelpers: RegionIndicators.equationHelpers, // these functions are passed through back to individual indicator equations so they have access as-needed to additional helper utilities
  indicators: RegionIndicators.indicators,
  defaultIndicators: RegionIndicators.defaultIndicators, // list of indicators to be used by all regions

  // gamification - points, achievements, hints, challenges, feedback
  conditionHelpers: RegionConditions.helpers,
  hints: RegionHints,
  questsInfo: RegionChallenges.QuestsGeneralInfo,
  challenges: RegionChallenges.Challenges,
  challengesMinScenarioRuns: 3, // user needs to do at least this many scenario runs before being offered any challenges
  challengesFrequency: 4, // challenge will be proposed for every 5 event fires
  hintsFrequency: 2, // hints will be proposed every 3 event fires
  awardPoints: {
    videoView: 20,
    scenarioCreate: 150,
    scenarioRun: 100,
    observationCreate: 125,
    observationMedia: 75,
    reportCreate: 150,
  },
  awardBadges: RegionBadges,

  // default user preferences
  defaultUserPrefs: {
    scenarioFeedback: true,
    showHints: true,
    showChallenges: true,
    timelineCommentary: "once",
    basemapStyle: "streets",
    showObservations: true,
  },

  // project sponsors
  sponsors: RegionSponsors,

  // social networks
  socialSettings: RegionSocial,
  socialSharingOn:
    "networks" in RegionSocial && RegionSocial.networks.length > 0,

  // did you know statements during scenario computes
  didYouKnow: RegionDidYouKnow,

  // virtual tours
  virtualToursOn: true,
  virtualTours: RegionVirTours,

  // indigenous module
  indigenousModuleActive: true,
  indigenousLayers: RegionIndigenous,
  indigenousModuleTitle: "Indigenous Voices",
  indigenousStudyAreas: "ab_ind_studyareas.geojson",
  indigenousIndicatorsCollection: true,
  getAltIndicators: RegionIndicators.collectionIndicators,

  // additional general stuff
  technicalDocURL: 'https://simulator.albertatomorrow.ca/documents/AB_Tomorrow_Technical_Document_v2-0.pdf',
  bulkUploadTemplateURL: 'https://simulator.albertatomorrow.ca/documents/BulkStudentRegistrationTemplate.csv',
};

export default Region;
