<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <div class="md-title">
        <span
          v-if="showTitle"
          class="table-title"
          :class="{'text-white': EXPLORE_MODE !== 'create'}"
        >
          Climate Change
          <md-button
            v-if="showInfoBtn"
            id="ClimateInfoBtn"
            class="md-icon-button md-fab info-button"
            @click="showClimateDetails()"
          >
            <md-icon>
              info
            </md-icon>
          </md-button>
        </span>
      </div>
      <div v-if="showVideoPlayerComputed">
        <video-player
          :options="getVideoOptions($Region.climateInfo)"
          :title="$Region.climateInfo.title"
        />
      </div>
      <div v-if="showInstructions">
        <h4 :class="{'text-white': EXPLORE_MODE !== 'create'}">
          What climate change forecast would you like to use?
        </h4>
        <p :class="{'text-white': EXPLORE_MODE !== 'create'}">
          Select the degree of climate change and global warming you would like applied to your scenario.
        </p>
      </div>
    </div>
    <div class="md-layout-item md-size-100 md-layout clim-buttons-container">
      <div
        v-for="climScen in climateScenarios"
        :key="climScen.variable"
      >
        <md-button
          :disabled="climateBtnsLocked || ACTIVE_SCENARIO_SHARED"
          :class="getButtonClass(climScen.variable)"
          class="clim-button md-dense"
          @click="activateClimScen(climScen.variable)"
          @mouseover="setScenarioDescription(climScen.variable)"
          @mouseleave="setScenarioDescription(selectedClimateScenario)"
        >
          <md-icon :class="`fal fa-${climScen.icon}`" />
          {{ climScen.name }}
        </md-button>
      </div>
    </div>
    <div
      v-if="showInstructions"
      class="md-layout-item md-size-100"
    >
      <p
        class="climate-description"
        :class="{'text-white': EXPLORE_MODE !== 'create'}"
      >
        {{ activeScenarioDescription }}
      </p>
      <technical-doc />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import VideoPlayer from "@/components/VideoPlayer";
import { Scenario } from "./mixins/Scenario";
import { VideoOptions } from "./mixins/VideoOptions";
import TechnicalDoc from './TechnicalDoc.vue';
export default {
  name: "ScenarioClimate",

  components: {
    VideoPlayer,
    TechnicalDoc,
  },
  mixins: [Scenario, VideoOptions], //scenario saving/running functions, video player options setup

  props: {
    scenario: {
      type: Object,
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showVideoPlayer: {
      type: Boolean,
      default: true
    },
    showInfoBtn: {
      type: Boolean,
      default: false
    },
    showInstructions: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      generalDescription: this.$Region.climateInfo.description,
      climateScenarios: this.$Region.climateScenarios,
      selectedClimateScenario: null,
      activeScenarioDescription: '',
      climateBtnsLocked: false,
    };
  },

  computed: {
    ...mapGetters([
      types.getters.ACTIVE_SCENARIO,
      types.getters.ACTIVE_SCENARIO_SHARED,
      types.getters.EXPLORE_MODE
    ]),
    showVideoPlayerComputed() {
      // if (this.EXPLORE_MODE === 'create' && this.showVideo(this.$Region.climateInfo)) {
      if (this.showVideoPlayer && this.showVideo(this.$Region.climateInfo)) {
        return true;
      }
      return false;
    }
  },

  watch: {
    selectedClimateScenario(newVal, oldVal) {
      if (oldVal !== null) {
        // update vuex
        this.UPDATE_ACTIVE_SCENARIO({ climate: this.selectedClimateScenario });
        // re-run simulation if updating an existing scenario
        if (this.EXPLORE_MODE !== 'create') {
          // prevent too many clicks/gaming for points... only allow 1 selection before a run is triggered
          this.climateBtnsLocked = true;
          // update scenario status
          this.UPDATE_ACTIVE_SCENARIO({ complete: false, runTime: 0 });
          // save to DB and run scenario
          this.saveScenarioUpdatesToDB(this.ACTIVE_SCENARIO, ["complete", "runTime", "climate"], true);
        }
      }
    }
  },

  created() {
    // initialize selected climate setting
    if (this.ACTIVE_SCENARIO !== null) {
      if (this.scenario.climate !== "") {
        this.selectedClimateScenario = this.scenario.climate;
      } else {
        this.selectedClimateScenario = "none";
      }
      this.setScenarioDescription(this.selectedClimateScenario);
    }
  },

  beforeMount() {
  },

  mounted() {
  },

  beforeDestroy() {
  },

  methods: {
    ...mapActions([
      types.actions.UPDATE_ACTIVE_SCENARIO,
      types.actions.TOGGLE_SIDEBAR_CONTENT,
      types.actions.SET_SIDEBAR_CONTENT_TYPE
    ]),

    validate() {
      // there are no required field validations... just submit
      return new Promise(resolve => {
        resolve(true);
      }).then(res => {
        this.$emit("on-validated", res, "tab_climate");
        return res;
      });
    },
    getButtonClass(climVariable) {
      if (this.selectedClimateScenario === climVariable) {
        return `md-raised md-primary`;
      }
      return `md-flat md-accent`;
    },
    activateClimScen(climVariable) {
      // update selected climate change scenario
      if (!this.$Region.climateModuleActive) {
        // climate scenarios not ready to use yet
        this.$notify({
          message: `Climate change scenarios are currently in development and will be available soon! For now, please use "None".`,
          icon: "add_alert",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "info"
        });
      } else {
        this.selectedClimateScenario = climVariable;
      }
    },
    showClimateDetails() {
      this.SET_SIDEBAR_CONTENT_TYPE('climate');
      this.TOGGLE_SIDEBAR_CONTENT();
      // notify user views climate panel
      this.$emit('onViewClimatePanel');
    },
    setScenarioDescription(scen) {
      this.activeScenarioDescription = this.getClimateScenarioDescription(scen);
    },
    getClimateScenarioDescription(scen) {
      // get the description for a scenario based on its variable name example: getClimateScenarioDescription('rcp45')
      return this.climateScenarios.find(_scen => _scen.variable === scen).description;
    }
  }
};
</script>

<style lang="scss" scoped>
.clim-button {
  margin: 2px;
  max-width: 114px;
}
.clim-buttons-container {
  top: -10px;
  padding-bottom: 10px;
  flex: 0;
}
.table-title {
  display: block;
  margin-top: 30px;
  margin-bottom: 5px;
}
.text-white {
  color: $white-color;
}
.climate-description {
  min-height: 225px;
}
.info-button {
  margin-left: 100px;
  margin-top: -16px;

  .md-icon {
    transform: scale(1.2, 1.2);
  }
}
</style>
