<template>
  <md-card class="side-panel-card layout-padding-none">
    <md-card-header class="layout-gray-dark layout-padding-small">
      <div
        id="ClimatePanelTitle"
        class="md-title"
      >
        <i class="fa fa-cloud-sun-rain" />
        Climate
      </div>
    </md-card-header>
    <md-content v-if="scenario.type !== 'Historic'">
      <scenario-climate
        :scenario="scenario"
        :show-info-btn="true"
        :show-video-player="false"
        :show-instructions="false"
        class="layout-gray-mid-dark climate-change-compact"
        @onViewClimatePanel="onViewClimatePanel"
      />
    </md-content>
    <md-content v-else-if="!($Region.climateModuleActive)">
      <!-- no climate change module is active yet... -->
      <div class="layout-gray-mid-dark climate-change-compact">
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <p>
              Historic climate data along with future climate change scenarios are currently in development and will be available soon!
            </p>
          </div>
        </div>
      </div>
    </md-content>
    <md-tabs
      :md-active-tab="ACTIVE_CLIMATE_TAB"
      class="md-primary"
      @md-changed="updateTab"
    >
      <md-tab
        v-for="ind in indicators"
        :id="`tab_${ind.name}`"
        :key="ind.name"
        :md-label="ind.name"
        :md-icon="`/img/${ind.icon}`"
        class="layout-gray"
      >
        <div
          v-if="indicatorsReady"
          class="md-layout md-layout-item md-size-100 ind-chart"
        >
          <div class="md-layout-item md-size-30">
            <md-button
              :id="`ClimatePanel${ind.name}MapLayerBtn`"
              class="md-icon-button md-fab lt-change-button"
              @click="setMapDataLayer(ind.name)"
            >
              <md-icon>
                map
              </md-icon>
            </md-button>
          </div>
          <div class="md-layout-item md-size-70 md-title">
            {{ ind.chartTitle }} ({{ ind.chartUnits }})
          </div>
          <chartist
            :data="indicatorChartData(ind.name)"
            :options="indicatorChartOptions(ind.name)"
            ratio=".ct-perfect-fourth"
            type="Line"
          />
        </div>
        <div
          v-else
          class="md-layout md-layout-item md-size-100 ind-chart"
        >
          <div class="md-layout-item md-size-100 md-title">
            {{ ind.name }} data loading...
          </div>
        </div>
      </md-tab>
    </md-tabs>
  </md-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import ScenarioClimate from "./ScenarioClimate";
export default {
  name: "ClimatePanel",

  components: {
    ScenarioClimate
  },

  props: {
    scenario: {
      type: Object,
      required: true,
    },
    climateIndData: {
      type: Object,
      required: true,
    },
    climateIndsReady: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
    };
  },

  computed: {
    ...mapGetters([types.getters.ACTIVE_CLIMATE_TAB]),

    indicators() {
      return this.$Region.climateIndicators;
    },
    indicatorsReady() {
      return this.climateIndsReady;
    }
  },

  beforeMount() {
    if (this.$Region.climateModuleActive && this.ACTIVE_CLIMATE_TAB === null) {
      const activeTab = `tab_${this.$Region.climateIndicators[0].name}`;
      this.SET_CLIMATE_TAB(activeTab);
    }
  },

  methods: {
    ...mapActions([
      types.actions.SET_CLIMATE_TAB,
      types.actions.SET_MAP_DATALAYER,
      types.actions.SET_EXPLORE_MOBILE_FS_CONTENT
    ]),

    updateTab(activeTab) {
      this.SET_CLIMATE_TAB(activeTab);
    },
    indicatorChartData(ind) {
      const labels = Object.keys(this.climateIndData[ind]);
      const series = Object.values(this.climateIndData[ind]);
      series.forEach((val, index) => series[index] = { meta: `${labels[index]}:`, value: `${parseFloat(val).toFixed(2)}` });
      return {
        labels: labels,
        series: [series]
      };
    },
    indicatorChartOptions(ind) {
      if (ind in this.climateIndData) {
        const index = this.indicators.findIndex(_ind => _ind.name === ind);
        const units = this.indicators[index].chartUnits;
        const unitsAddOn = function (val) {
          return `${val}${units}`;
        }
        const tooltipClass = (index > 0) ? 'pad-left' : '';
        const numYears = Object.keys(this.climateIndData[ind]).length;
        const skipNum = (numYears <= 40) ? 10 : 30;
        return {
          showLabel: false,
          height: '200px',
          axisX: {
            // skip labels on x axis... only show a subset
            labelInterpolationFnc: function skipLabels(value, _index) {
              return _index % skipNum === 0 ? value : null;
            }
          },
          plugins: [
            this.$chartist.plugins.tooltip({
              anchorToPoint: false,
              appendToBody: false,
              class: tooltipClass,
              transformTooltipTextFnc: unitsAddOn
            })
          ]
        };
      }
      return {};
    },
    setMapDataLayer(ind) {
      this.SET_MAP_DATALAYER(ind);
      this.SET_EXPLORE_MOBILE_FS_CONTENT('map');
    },
    onViewClimatePanel() {
      // notify user views climate panel
      this.$emit('onViewClimatePanel');
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .climate-change-compact .md-layout {
  margin: 0;
}
/deep/ .climate-change-compact h4 {
  line-height: 20px;
  font-size: 14px;
}
/deep/ .chartist-tooltip {
  position: absolute;
  display: none;
  min-width: 5em;
  padding: 8px 10px;
  margin-top: 7em;
  margin-left: 0.5em;
  background: #383838;
  color: #fff;
  text-align: center;
  pointer-events: none;
  z-index: 100;
  transition: opacity 0.2s linear;
}
/deep/ .chartist-tooltip:before {
  position: absolute;
  bottom: -14px;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: rgba(251, 249, 228, 0);
  border-top-color: #383838;
  border-width: 7px;
  margin-left: 0;
}
/deep/ .chartist-tooltip.tooltip-show {
  display: inline-block !important;
}
/deep/ .pad-left {
  margin-left: 280px;
}
.ind-chart {
  height: 300px;
}
/deep/ .ct-grids line {
  stroke: $grey-500;
}
/deep/ .ct-labels span {
  color: $white-color;
}
/deep/ .ct-labels .ct-horizontal {
  margin: 0 0 0 -20px;
}
</style>