// social networks setup

const RegionSocial = {
  networks: [
    {
      network: "email",
      name: "Email",
      icon: "far fah fa-lg fa-envelope",
      color: "#333333",
    },
    {
      network: "facebook",
      name: "Facebook",
      icon: "fab fah fa-lg fa-facebook-f",
      color: "#1877f2",
    },
    {
      network: "pinterest",
      name: "Pinterest",
      icon: "fab fah fa-lg fa-pinterest",
      color: "#bd081c",
    },
    {
      network: "twitter",
      name: "Twitter",
      icon: "fab fah fa-lg fa-twitter",
      color: "#1da1f2",
    },
    // {
    //   network: "whatsapp",
    //   name: "Whatsapp",
    //   icon: "fab fah fa-lg fa-whatsapp",
    //   color: "#25d366",
    // },
  ],
  twitterUser: "AlbertaTomorrow", // user to mention on twitter shares
  //   pinterestMediaUrl: "", // url of image to share on posts to Pinterest // todo
  shareProperties: {
    observation: {
      title: "%TITLE%. You can too. Register for a free account today!",
      description: "%DESC%",
      hashtags: "ABTomorrow,citizenscience",
      waterHashtags: "abwater,abwaterquality",
      generalHashtags: "ablanduse,landuse,landuseobservation",
    },
    report: {
      title:
        "I created a land use report: %TITLE%. You can too. Register for a free account today!",
      description: "%DESC%",
      hashtags: "ABTomorrow,ablanduse,landuseplanning,landusereport",
    },
    scenario: {
      title:
        "I created a modelling scenario to look at land use impacts %TITLE%. You can too. Register for a free account today!",
      description: "%DESC%",
      hashtags: "ABTomorrow,landuse,futureofalberta",
    },
    video: {
      title:
        "Check out this video about %TITLE%. Learn more at www.albertatomorrow.ca",
      description: "%DESC%",
      hashtags: "ABTomorrow",
    },
    virtualtour: {
      title:
        "Check out this virtual tour about %TITLE%. Learn more at www.albertatomorrow.ca",
      description: "%DESC%",
      hashtags: "ABTomorrow",
    },
  },
};

export default RegionSocial;
