<template>
  <li :class="[{'timeline-inverted': inverted}, {'timeline-simple': !inverted}]">
    <slot name="badge">
      <div
        class="timeline-badge"
      >
        <md-button
          :class="`md-icon-button md-fab md-${badgeType} timeline-item`"
          @click="launchAction(btnAction)"
        >
          <md-icon
            v-if="badgeIcon !== ''"
            class=""
          >
            {{ badgeIcon }}
          </md-icon>
          <md-icon
            v-else
            :class="`${badgeIconClass} timeline-icon-shift`"
          />
        </md-button>
      </div>
    </slot>
    <div
      :style="{opacity: show}"
      class="timeline-panel"
    >
      <div class="timeline-heading">
        <slot name="header" />
      </div>
      <div
        v-if="$slots.content"
        class="timeline-body"
      >
        <slot name="content" />
      </div>
      <h6 v-if="$slots.footer">
        <slot name="footer" />
      </h6>
    </div>
  </li>
</template>
<script>
  export default {
    name: 'TimeLineItem',
    props: {
      iconOnly: {
        type: Boolean,
        default: false
      },
      inverted: Boolean,
      badgeType: {
        type: String,
        default: 'success'
      },
      badgeIcon: {
        type: String,
        default: ''
      },
      badgeIconClass: {
        type: String,
        default: ''
      },
      btnAction: {
        type: Function,
        default: null
      }
    },
    computed: {
      show: function () {
        if (this.iconOnly) {
          return 0
        }
          return 1
      }
    },
    methods: {
      launchAction(fn) {
        if (fn !== null) {
          fn({ vm: this });
        }
      }
    }
  }
</script>
<style lang="scss" >
.timeline-todo {
  background-color: $grey-300;
}
.timeline-icon-shift {
  transform: translateY(-12px);
}
.timeline-item {
  border-radius: 50%;
}
</style>
