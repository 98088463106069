/* jshint ignore: start */
// mixin for some user profile handling
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import { debounce } from "debounce";
export const User = {
  data() {
    return {};
  },

  computed: {
    ...mapGetters([
      types.getters.USER_DATA,
      types.getters.AVATAR_TEMPLINK,
      types.getters.AVATAR_TEMPLINK_LAST_LOADED,
    ]),

    avatar() {
      return this.USER_DATA.avatar;
    },
  },

  watch: {
    avatar() {
      this.debounceGetAvatarUrl();
    },
  },

  methods: {
    ...mapActions([
      types.actions.USER_DATA_UPDATE,
      types.actions.SET_AVATAR_TEMPLINK,
      types.actions.SET_AVATAR_TEMPLINK_LAST_LOADED,
    ]),

    addUserPoints(points) {
      // add points to user profile.
      // this function updates the USER_DATA store
      // and updates the points in the database via api call
      const updates = { points: points };
      const newPoints =
        "points" in this.USER_DATA && this.USER_DATA.points != null
          ? this.USER_DATA.points + points
          : points;
      const vm = this;
      const pointsUpdateCallback = function() {
        // update the vuex store when the db has been updated
        vm.USER_DATA_UPDATE({ points: newPoints });
      };
      // call the API and update the user profile
      this.saveUserUpdatesToDB(updates, "increment", pointsUpdateCallback);
    },
    /**
     * scenarioPointsEligible evaluates whether a particular scenario is eligible for award points or not
     * used to prevent punks gaming the system by constantly re-running scenarios
     *
     * @param {[]} userScenarios - list of user scenarios from the USER_SCENARIOS vuex store
     * @param {string} scenType - the scenario type, one of ['Historic', 'Business as Usual', 'Land Use']
     * @param {string} scenRegion - the scenario study area
     * @param {string} scenClimate - the scenario climate model
     * @param {number} scenID - the scenario ID
     * @param {number} numRuns - the number of runs completed for the scenario
     * @return {boolean} true if scenario is eligible for points
     */
    scenarioPointsEligible(
      userScenarios,
      scenType,
      scenRegion,
      scenClimate,
      scenID,
      numRuns
    ) {
      if (scenType === "Land Use" && numRuns >= 10) {
        // only give points for up to 10 runs on a Land Use scenario
        return false;
      } else if (scenType === "Business as Usual") {
        if (this.$Region.climateModuleActive) {
          if (numRuns >= 5) {
            // max of 5 runs allowed for points for BAU with climate module
            return false;
          }
        } else {
          if (numRuns >= 1) {
            // max of 1 runs allowed for points for BAU with no climate module
            // not sure how this could ever be triggered TBH
            return false;
          }
        }
      }
      // make sure the basic settings haven't already been run for this user elsewhere
      const duplicates = userScenarios.some(
        (scen) =>
          ["Business as Usual", "Historic"].includes(scen.type) &&
          scen.type === scenType &&
          scen.studyArea === scenRegion &&
          scenClimate === scen.climate &&
          scenID !== scen.id
      );
      return !duplicates;
    },
    updateUserInProgressItem(progressItem, remove = false) {
      // update DB as well as vuex store
      // progressItem = the object to add or update
      // if item does not yet exist, it will be automatically added
      /*
      inprogress field setup as list of objects
      --- SAMPLE QUEST OBJECT --- to track partially completed quest
      [
        {
          type: "quest", // type = quest
          code: "someQuestCode" // the quest code
          completed: [0,3,4] // indexes of completed passConditions (NOTE: this index is NOT the same as the activity index.  Some activities may have multiple pass conditions.)
        },
      ]
      */
      const vm = this;
      const inprogress =
        "inprogress" in vm.USER_DATA
          ? JSON.parse(JSON.stringify(vm.USER_DATA.inprogress))
          : [];

      // find the item from inprogress if it's already there
      const updateIndex = inprogress.findIndex(
        (item) =>
          item.type === progressItem.type && item.code === progressItem.code
      );
      if (remove) {
        // remove the item
        if (updateIndex !== -1) {
          inprogress.splice(updateIndex, 1);
        }
      } else {
        // update/add item
        if (updateIndex !== -1) {
          // update the existing item
          inprogress[updateIndex] = progressItem;
        } else {
          // add new item
          inprogress.push(progressItem);
        }
      }
      // const updates = { inprogress: inprogress };
      const updates = {
        inprogress: JSON.stringify(inprogress).replace(/"/g, "'"),
      };
      const inprogressUpdateCallback = function() {
        // update the vuex store when the db has been updated
        vm.USER_DATA_UPDATE({ inprogress: inprogress });
      };
      // call the API and update the user profile
      this.saveUserUpdatesToDB(updates, "replace", inprogressUpdateCallback);
    },
    updateUserHistory(historyItem) {
      // update DB as well as vuex store
      /*
      history items are generally setup with a code and underscore followed by the item key or name
      codes:
      c_ = challenge completed
      v_ = video viewed
      p_ = page viewed (ie, mission)
      t_ = tutorial item viewed
      m_ = map item viewed
      */
      const updates = { history: historyItem };
      const vm = this;
      const historyUpdateCallback = function() {
        // update the vuex store when the db has been updated
        const history = "history" in vm.USER_DATA ? vm.USER_DATA.history : [];
        if (!history.includes(historyItem)) {
          history.push(historyItem);
          vm.USER_DATA_UPDATE({ history: history });
        }
      };
      // call the API and update the user profile
      this.saveUserUpdatesToDB(updates, "append", historyUpdateCallback);
    },
    updateUserAchievements(item) {
      // update achievements in DB as well as vuex store
      const updates = { achievements: item };
      const vm = this;
      const achievementsUpdateCallback = function() {
        // update the vuex store when the db has been updated
        const achievements =
          "achievements" in vm.USER_DATA ? vm.USER_DATA.achievements : [];
        if (!achievements.includes(item)) {
          achievements.push(item);
          vm.USER_DATA_UPDATE({ achievements: achievements });
        }
      };
      // call the API and update the user profile
      this.saveUserUpdatesToDB(updates, "append", achievementsUpdateCallback);
    },
    updateUserProfile(profileUpdates) {
      // update the user profile in both the database and the USER_DATA vuex store
      const vm = this;
      const profileUpdateCallback = function() {
        // update the vuex store when the db has been updated
        vm.USER_DATA_UPDATE(profileUpdates);
      };
      this.saveUserUpdatesToDB(
        profileUpdates,
        "replace",
        profileUpdateCallback
      );
    },
    saveUserUpdatesToDB(profileUpdates, updateMode, callback = null) {
      // save profile updates to the database via API
      // sends data to the user/PATCH method on the API
      // profileUpdates = object of key/vals to update in database
      // updateMode = how to apply update. options are ['replace', 'append', 'increment']

      // remove unnecessary/unallowed fields from payload
      const _profileUpdates = JSON.parse(JSON.stringify(profileUpdates));
      const allowedFields = [
        "usertype",
        "grade",
        "curriculum",
        "lat",
        "lng",
        "postal_code",
        "rationale",
        "school",
        "teacher",
        "teacherUsername",
        "preferences",
        "points",
        "achievements",
        "inprogress",
        "history",
        "avatar",
      ];
      // remove unallowed fields, empty strings, nulls
      Object.keys(_profileUpdates).forEach((key) => {
        if (
          !allowedFields.includes(key) ||
          _profileUpdates[key] === "" ||
          _profileUpdates[key] === null
        ) {
          delete _profileUpdates[key];
        }
      });
      // set null teacher fields to empty strings - allow users to remove teachers
      // TODO the following code causes dynamodb update to fail - not allowed to pass empty strings
      // TODO need an alternate way of removing a teacher if it becomes an issue.  Perhaps set to " " or "NONE"?
      // ["teacher", "teacherUsername"].forEach((key) => {
      //   if (
      //     key in profileUpdates &&
      //     (profileUpdates[key] === null || profileUpdates[key] === "")
      //   ) {
      //     _profileUpdates[key] = "";
      //   }
      // });
      // setup dynamo payload
      const payload = {
        UpdatePayload: JSON.stringify(_profileUpdates)
          .replace(/`/g, "'")
          .replace(/"/g, "`"),
        UpdateMode: updateMode,
      };
      // sanitize the payload
      this.$Helpers.sanitizeDBPayload(payload);

      const authLib = this.$Auth;
      const apiLib = this.$API;

      // Push to the DB
      authLib.currentSession().then((authData) => {
        const username = authData.accessToken.payload.username;
        payload.UserName = username;
        const path = `/user/${username}`;
        const myInit = {
          body: payload,
          headers: { Authorization: authData.idToken.jwtToken },
        };
        apiLib
          .patch("api", path, myInit)
          .then(() => {
            // fire callback if there is one
            if (callback !== null) {
              callback();
            }
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.log(error);
          });
      });
    },
    async getAvatarUrl(forceReload = false) {
      // get the templink for viewing an uploaded avatar image
      let avatarUrl = this.avatar;
      // if avatar is not the default and it has not been loaded from the server in > 2hrs (7200000ms) reload it
      if (
        avatarUrl !== "./img/default-avatar.png" &&
        (forceReload ||
          this.AVATAR_TEMPLINK_LAST_LOADED === null ||
          Date.now() > this.AVATAR_TEMPLINK_LAST_LOADED + 7200000)
      ) {
        avatarUrl = await this.$Helpers.getImageURL(
          this.$ImageBucket,
          this.$Auth,
          this.$API,
          avatarUrl
        );
        this.SET_AVATAR_TEMPLINK_LAST_LOADED(Date.now());
        this.SET_AVATAR_TEMPLINK(avatarUrl);
      }
    },
    debounceGetAvatarUrl: debounce(function(forceReload = false) {
      // get an avatar templink url - debounced to prevent excessive loads
      this.getAvatarUrl(forceReload);
    }, 5000),
  },
};
