// mixin for some observation handling
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import swal from "sweetalert2";
import { debounce } from "debounce";

export const Observation = {
  data() {
    return {
      observationsLoaded: false,
      generalFields: {
        // general fields
        // used for labels and validation text
        descriptionOfEcosystem: {
          label: "Description of the Ecosystem",
          helperText: "Describe the ecosystem you see at this location.",
          field: "descriptionOfEcosystem"
        },

        humanActivity: {
          label: "Human Activity",
          helperText:
            "What signs of human activity or modern development do you see?",
          field: "humanActivity"
        },

        weatherConditions: {
          label: "Weather Conditions",
          helperText: "Describe the weather at the time of your observation.",
          field: "weatherConditions"
        }
      },
      waterFields: {
        // water fields
        // used for labels and validation text
        locationName: {
          label: "Location Name",
          helperText: "example: Blue Pond south end",
          field: "locationName",
        },
        locationDescription: {
          label: "Location Description",
          helperText: "How would you describe this location?",
          field: "locationDescription",
        },
        bodyOfWater: {
          label: "Body of Water",
          helperText: "The name of the body of water",
          field: "bodyOfWater",
        },
        bodyOfWaterType: {
          label: "Type of Body of Water",
          helperText: "type",
          field: "bodyOfWaterType",
        },
        weatherNow: {
          label: "Weather at time of observation",
          helperText: "Describe the weather at the time of observation (select all that apply)",
          field: "weatherNow",
        },
        weatherPast24h: {
          label: "Weather in the previous 24 hours",
          helperText: "Describe the weather during the day before observation (select all that apply)",
          field: "weatherPast24h",
        },
        airTemp: {
          label: "Air Temperature",
          helperText: "°C",
          field: "airTemp",
        },
        waterTemp: {
          label: "Water Temperature",
          helperText: "°C",
          field: "waterTemp"
        },
        totalPhosphates: {
          label: "Total Phosphates",
          helperText: "µg/L",
          field: "totalPhosphates"
        },
        nitrates: {
          label: "Nitrates",
          helperText: "mg/L",
          field: "nitrates"
        },
        fecalColiform: {
          label: "Fecal Coliform",
          helperText: "cfu/100mL",
          field: "fecalColiform"
        },
        bod: {
          label: "Biochemical Oxygen Demand (BOD)",
          helperText: "mg/L",
          field: "bod"
        },
        dissolvedOxygen: {
          label: "Dissolved Oxygen",
          helperText: "mg/L",
          field: "dissolvedOxygen"
        },
        ph: {
          label: "pH Level",
          helperText: "pH",
          field: "ph",
        },
        alkalinity: {
          label: "Alkalinity",
          helperText: "ppm",
          field: "alkalinity",
        },
        hardness: {
          label: "Hardness",
          helperText: "ppm",
          field: "hardness",
        },
        turbidity: {
          label: "Turbidity",
          helperText: "JTU (NTU)",
          field: "turbidity",
        },
        conductivity: {
          label: "Conductivity",
          helperText: "μS/cm",
          field: "conductivity",
        },
        chlorine: {
          label: "Chlorine",
          helperText: "ppm",
          field: "chlorine",
        },
        waterflow: {
          label: "Water Flow",
          helperText: "L/s",
          field: "waterflow",
        },
      },
      waterFieldsLocation: [
        "locationName",
        "locationDescription",
        "bodyOfWater",
        "bodyOfWaterType",
      ],
      waterFieldsWeather: [
        "weatherNow",
        "weatherPast24h",
      ],
    };
  },

  computed: {
    /* jshint ignore:start */
    ...mapGetters([
      types.getters.EXPLORE_MODE,
      types.getters.SELECTED_OBSERVATION,
      types.getters.HIDDEN_OBSERVATION,
      types.getters.OBSERVATIONS_LAST_LOADED,
      types.getters.OBSERVATIONS_ALL_JSON
    ]),
    /* jshint ignore:end */

    isFlagged() {
      // check if a SELECTED_OBSERVATION has been flagged inappropriate
      if (
        "properties" in this.SELECTED_OBSERVATION &&
        "status" in this.SELECTED_OBSERVATION.properties &&
        this.SELECTED_OBSERVATION.properties.status === "flagged"
      ) {
        return true;
      }
      return false;
    },
    waterFieldsNonNumeric() {
      return [...this.waterFieldsLocation, ...this.waterFieldsWeather];
    }
  },

  mounted() {
    // load all observations in the background so we are ready to launch to explore if needed
    if (
      this.OBSERVATIONS_LAST_LOADED === null ||
      Date.now() > this.OBSERVATIONS_LAST_LOADED + 300000
    ) {
      this.debounceObservationsLoadAll();
    } else {
      this.observationsLoaded = true;
    }
  },

  methods: {
    /* jshint ignore:start */
    ...mapActions([
      types.actions.CLOSE_SIDEBAR,
      types.actions.TOGGLE_SIDEBAR_CONTENT,
      types.actions.CLEAR_SELECTED_OBSERVATION,
      types.actions.CLEAR_HIDDEN_OBSERVATION,
      types.actions.RESTORE_HIDDEN_OBSERVATION,
      types.actions.SHOW_OBSERVATIONS_ON,
      types.actions.SET_OBSERVATION_FEATURE_TO_LOAD,
      types.actions.OBSERVATIONS_LOAD_ALL,
      types.actions.SET_EXPLORE_MOBILE_FS_CONTENT,
      types.actions.SET_MAP_FLYTO,
    ]),
    /* jshint ignore:end */

    closeObservation() {
      // clear the observation and sidebar
      this.CLEAR_SELECTED_OBSERVATION();
      if (this.EXPLORE_MODE === "explore") {
        this.CLOSE_SIDEBAR();
      }
      // reset mobile fullscreen content back to map
      this.SET_EXPLORE_MOBILE_FS_CONTENT("map");
      // toggle the sidebar
      this.TOGGLE_SIDEBAR_CONTENT();
    },
    errorAlert(message) {
      swal.fire({
        title: `<p style="color:red;">Not so fast...</p>`,
        text: message,
        timer: 2000,
        showConfirmButton: false
      });
    },
    explore(id, obsLayer = 'observations', isSystemObs = false, transactionFound = false, routerUpdate = true, flyTo = false) {
      // take emitted observation and launch explore view with observation selected
      let obsKey = "id";
      let obsId = id;
      if (this.HIDDEN_OBSERVATION) {
        this.RESTORE_HIDDEN_OBSERVATION();
      }
      if (!isSystemObs) {
        // get the usertransaction in order to find the observation in the global list
        obsKey = "UserTransaction";
        if (!transactionFound) {
          obsId = this.OBSERVATIONS_ALL_USER.find(obs => obs.id === id)[obsKey];
        }
      }
      // turn observations layer on
      this.SHOW_OBSERVATIONS_ON();
      let obs = null;

      // make sure the observations json has been loaded from the api and is not stale - ie haven't been loaded for 5min (300000ms)
      if (
        this.OBSERVATIONS_LAST_LOADED === null ||
        Date.now() > this.OBSERVATIONS_LAST_LOADED + 300000
      ) {
        this.observationsLoaded = false;
        this.debounceObservationsLoadAll();
      }
      const waiting = () => {
        if (!this.observationsLoaded) {
          setTimeout(waiting, 100);
        } else {
          // find the correct observation
          obs = this.OBSERVATIONS_ALL_JSON[obsLayer].features.find(
            feat => feat.properties[obsKey] === obsId
          );
          if (obs === undefined || obs === "undefined") {
            // the selected observation is already in the store and was hidden from the json... must have been already selected for details display
            if (
              this.SELECTED_OBSERVATION !== null &&
              this.SELECTED_OBSERVATION.properties[obsKey] === obsId
            ) {
              obs = this.SELECTED_OBSERVATION;
            }
          }
        }
      };
      waiting(); // don't proceed until the observations are loaded

      obs = JSON.parse(JSON.stringify(obs));
      // set the obs for loading
      obs.layer = {id: obsLayer};
      this.SET_OBSERVATION_FEATURE_TO_LOAD(obs);
      if (flyTo) {
        // fly to the observation
        this.SET_MAP_FLYTO(obs.geometry.coordinates.toString());
      }

      // go to explore view if routerUpdate = true
      if (routerUpdate) {
        this.$router.push({
          name: "Explore"
        });
      }
    },
    debounceObservationsLoadAll: debounce(function() {
      // load all observations from api... debounced to prevent simultaneous loads
      this.OBSERVATIONS_LOAD_ALL({ vm: this });
      this.CLEAR_HIDDEN_OBSERVATION();
      this.observationsLoaded = true;
    }, 1000),
    saveObservationUpdatesToDB(obsKeys) {
      // save an updated existing obs to database
      // sends data to the observation/username/PATCH method on the API
      // uses SELECTED_OBSERVATION as source
      // obsKeys = array of fields to update in database

      // setup dynamo payload
      const payload = {
        UserTransaction: this.SELECTED_OBSERVATION.properties.UserTransaction
      };
      const updates = {};
      obsKeys.forEach(obsKey => {
        updates[obsKey] = this.SELECTED_OBSERVATION.properties[obsKey];
      });
      payload.UpdatePayload = JSON.stringify(updates).replace(/"/g, "'");

      // sanitize the payload
      this.$Helpers.sanitizeDBPayload(payload);
      // Push to the DB
      this.$Auth.currentSession().then(authData => {
        const username = authData.accessToken.payload.username;
        payload.UserName = this.SELECTED_OBSERVATION.properties.UserName;
        const path = `/observation/${username}`;
        const myInit = {
          body: payload,
          headers: { Authorization: authData.idToken.jwtToken }
        };
        this.$API
          .patch("api", path, myInit)
          .then(() => {
            // Refresh the obs data or error
            this.debounceObservationsLoadAll();
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error);
          });
      });
    }
  }
};
