/* jshint ignore: start */
// mixin for resource/lesson plan handling
export const TeacherResources = {
  props: {
    resource: {
      type: Object,
      required: true
    }
  },

  data() {
    return {};
  },

  computed: {
    grades() {
      // the grades for this resource
      return this.resource.grades.map(grade => `Grade ${grade}`);
    },
    numFiles() {
      // the number of files
      return "files" in this.resource ? this.resource.files.length : 0;
    },
    numVideos() {
      // the number of videos
      return "videos" in this.resource ? this.resource.videos.length : 0;
    },
    numTags() {
      // the number of curriculum tags
      return "tags" in this.resource ? this.resource.tags.length : 0;
    }
  },

  methods: {}
};
