/* jshint ignore: start */
// mixin for teacher-specific functions like loading list of students
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import { debounce } from "debounce";
export const TeacherFunctions = {
  props: {},

  data() {
    return {};
  },

  computed: {
    ...mapGetters([
      types.getters.STUDENTS,
      types.getters.STUDENTS_DATA_LOADED,
      types.getters.STUDENTS_LAST_LOADED
    ])
  },

  methods: {
    ...mapActions([types.actions.STUDENTS_LOAD]),

    studentsLoad(forceReload=false) {
      // load students if they haven't been loaded in the last 5min
      if (
        forceReload ||
        this.STUDENTS_LAST_LOADED === null ||
        Date.now() > this.STUDENTS_LAST_LOADED + 300000
      ) {
        this.debounceStudentsLoadAll();
      }
    },
    debounceStudentsLoadAll: debounce(function() {
      // load all students from api... debounced to prevent simultaneous loads
      this.STUDENTS_LOAD({ vm: this });
    }, 1000)
  }
};
