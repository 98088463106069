<template>
  <button
    :class="[open ? $style.active : '', $style.button]"
    @click="handleClick"
  >
    <md-icon :class="[open ? $style.openSidebar : '', $style.closedSidebar]">
      chevron_left
    </md-icon>
  </button>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
export default {
  name: "RightSidebarToggle",
  computed: {
    ...mapGetters([types.getters.SIDEBAR_OPEN]),
    open() {
      return this.SIDEBAR_OPEN;
    }
  },
  methods: {
    ...mapActions([types.actions.TOGGLE_SIDEBAR]),
    handleClick() {
      this.TOGGLE_SIDEBAR();
    }
  }
};
</script>

<style lang="scss" module>
$width: 3em;
$height: 7em;

.button {
  position: absolute;
  display: fixed;
  top: 1em;
  // top: calc(70px + 1em);
  left: calc(100% - 3em);
  // left: calc(100vw - 600px - 3em);
  padding: 0;
  height: $height;
  width: $width;
  border: 3px solid transparent;
  background-color: $gray-darker;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  line-height: 0;
  transition: transform 300ms ease-out, border-color 300ms ease-out;
  z-index: 5;

  i {
    color: white !important;
  }
}

.openSidebar {
  transform: rotate(180deg);
}
.openSidebar,
.closedSidebar {
  background: $gray-darker;
  transition: transform 200ms linear;
}
</style>