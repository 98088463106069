<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <h4 v-if="showTitle">
        Management Practices
      </h4>
      <div v-if="showVideoPlayer && mgmtInfo.media">
        <video-player
          :options="getVideoOptions(mgmtInfo)"
          :title="mgmtInfo.title"
        />
      </div>
      <p :class="{'description': ('complete' in ACTIVE_SCENARIO)}">
        {{ mgmtInfo.description }}
      </p>
    </div>
    <div class="md-layout-item md-size-100">
      <land-practices-table
        v-if="regionIndicatorsReady"
        :scenario="ACTIVE_SCENARIO"
        :region-dev-rate="regionDevRate()"
        :scroll-practices="scrollPractices"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import VideoPlayer from "@/components/VideoPlayer";
import LandPracticesTable from "./LandPracticesTable";
import { VideoOptions } from "./mixins/VideoOptions";

export default {
  name: "ScenarioPractices",

  components: {
    VideoPlayer,
    LandPracticesTable
  },
  mixins: [VideoOptions], //video player options setup

  props: {
    scenario: {
      type: Object,
      default: function () {
        return {};
      },
    },
    simulationState: {
      type: Object,
      required: true,
    },
    regionIndicatorsReady: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    showInfoBtn: {
      type: Boolean,
      default: false
    },
    scrollPractices: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      mgmtInfo: this.$Region.mgmtInfo,
    };
  },

  computed: {
    ...mapGetters([
      types.getters.ACTIVE_SCENARIO
    ]),
    showVideoPlayer() {
      if (this.showVideo(this.$Region.mgmtInfo)) {
        return true;
      }
      return false;
    },
  },

  watch: {
    simulationState: {
      handler: function () {
        if (this.scenario.devRate === null) {
          this.UPDATE_ACTIVE_SCENARIO({ devRate: this.regionDevRate() });
        }
      },
      deep: true
    }
  },

  methods: {
    ...mapActions([
      types.actions.UPDATE_ACTIVE_SCENARIO
    ]),

    validate() {
      // there are no required field validations... just submit
      return new Promise(resolve => {
        resolve(true);
      }).then(res => {
        this.$emit("on-validated", res, "tab_mgmt");
        return res;
      });
    },
    regionDevRate() {
      return this.simulationState.regionData.currentValues.devRate;
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .mgmt-practices-table {
  margin-bottom: 20px;
}
.description {
  color: $white-color;
  font-size: 1.2em;
}
</style>
