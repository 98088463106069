// state store for managing ui components
import { types } from "../types";

// initial state
const state = {
  exploreMode: "explore", // options ['explore', 'scenario', 'create'] what activity is happening on explore screen (explore: no scenario loaded; scenario: scenario loaded; create: create scenario wizard)
  mobileFullScreenContent: "map", // one of ['map', 'stats']
  sidebarOpen: true, // right sidebar on Explore screen
  sidebarContentOpen: false, //content overlay on right sidebar
  sidebarContentType: null, // options ['indicator', 'landtype', 'climate', 'practices'] what type of feature content to display on right sidebar (indicator, land type, climate, best practices)
  activeIndicator: null, // the active indicator to show in a details panel
  activeLT: null, // the active land type to show in a details panel and used for planning mode allocations
  showScenarioDialog: false, // scenario choosing dialogue
  showEditScenarioDialog: false, // new scenario create dialogue
  scenarioEditMode: "create", // one of ["create", "edit"] to determine what mode to launch EditScenarioDialog in
  showScenarioComputingDialog: false, // scenario computing progress dialog
  scenarioComputingRuntime: 0, // estimated max run time for a scenario
  basemapStyle: "streets", // mapbox basemap style  "streets" "satellite"
  mapDataLayer: "landuse", // options ['landuse', '<climate_ind>', '<climate_ind>'] what land use data layer is currently selected (landuse or a climate indicator)
  triggerDataLayerReload: false, // toggle to force mapdata to reload/repaint... any toggle causes trigger to fire
  mapFlyTo: null, // flyto settings for map
  mapActionTrigger: null, // options ['newObservation'] map activity/action to trigger from another component... ie: newObservation launches new observation listener
  showLanduseOverlay: true, // display the landuse data layer
  showPlanningMarkers: true, // display the planning markers when viewing a 'Land Use' type scenario
  showPlanningMarkersLocked: false, // force the planning markers to stay on in land-use changes editing/creating screens
  activeLandSummaryTab: "tab_lt_table", // default tab on LandSummaryPanel.vue
  activeClimateTab: null, // default tab on ClimatePanel
  activeScenarioWizardTab: "tab_region", // default tab on ScenarioWizard
  activeScenarioWizardNext: false, // toggle to trigger next button on scenario wizard from other components
  activeScenarioWizardPrev: false, // toggle to trigger next button on scenario wizard from other components
  selectedVirtualTour: null, // the virtual tour to show
  showIndLayers: [], // show indigenous territories map layer
  showIndFeatures: [], // show indigenous territories map feature
};

// getters
const getters = {
  [types.getters.EXPLORE_MODE]: (state) => state.exploreMode,
  [types.getters.EXPLORE_MOBILE_FS_CONTENT]: (state) =>
    state.mobileFullScreenContent,
  [types.getters.SIDEBAR_OPEN]: (state) => state.sidebarOpen,
  [types.getters.SHOW_SIDEBAR_CONTAINER]: (state, rootGetters) => {
    if (
      state.sidebarOpen ||
      state.exploreMode === "create" ||
      rootGetters.SELECTED_OBSERVATION !== null
    ) {
      return true;
    }
    return false;
  },
  [types.getters.SIDEBAR_CONTENT_OPEN]: (state) => state.sidebarContentOpen,
  [types.getters.SIDEBAR_CONTENT_TYPE]: (state) => state.sidebarContentType,
  [types.getters.ACTIVE_INDICATOR]: (state) => state.activeIndicator,
  [types.getters.ACTIVE_LT]: (state) => state.activeLT,
  [types.getters.SCENARIO_DIALOG_OPEN]: (state) => state.showScenarioDialog,
  [types.getters.EDIT_SCENARIO_DIALOG_OPEN]: (state) =>
    state.showEditScenarioDialog,
  [types.getters.SCENARIO_EDIT_MODE]: (state) => state.scenarioEditMode,
  [types.getters.SCENARIO_COMPUTING_DIALOG_OPEN]: (state) =>
    state.showScenarioComputingDialog,
  [types.getters.SCENARIO_COMPUTING_RUNTIME]: (state) =>
    state.scenarioComputingRuntime,
  [types.getters.BASEMAP_STYLE]: (state) => state.basemapStyle,
  [types.getters.MAP_DATALAYER]: (state) => state.mapDataLayer,
  [types.getters.MAP_DATALAYER_RELOAD]: (state) => state.triggerDataLayerReload,
  [types.getters.MAP_FLYTO]: (state) => state.mapFlyTo,
  [types.getters.MAP_ACTION_TRIGGER]: (state) => state.mapActionTrigger,
  [types.getters.SHOW_LANDUSE_OVERLAY]: (state) => state.showLanduseOverlay,
  [types.getters.SHOW_PLANNING_MARKERS]: (state) => state.showPlanningMarkers,
  [types.getters.SHOW_PLANNING_MARKERS_LOCKED]: (state) =>
    state.showPlanningMarkersLocked,
  [types.getters.ACTIVE_LAND_SUMMARY_TAB]: (state) =>
    state.activeLandSummaryTab,
  [types.getters.ACTIVE_CLIMATE_TAB]: (state) => state.activeClimateTab,
  [types.getters.ACTIVE_SCENARIO_WIZARD_TAB]: (state) =>
    state.activeScenarioWizardTab,
  [types.getters.ACTIVE_SCENARIO_WIZARD_NEXT]: (state) =>
    state.activeScenarioWizardNext,
  [types.getters.ACTIVE_SCENARIO_WIZARD_PREV]: (state) =>
    state.activeScenarioWizardPrev,
  [types.getters.SELECTED_VIRTUAL_TOUR]: (state) =>
    state.selectedVirtualTour,
  [types.getters.SHOW_IND_LAYERS]: (state) =>
    state.showIndLayers,
  [types.getters.SHOW_IND_FEATURES]: (state) =>
    state.showIndFeatures,
};

// mutations
const mutations = {
  [types.mutations.SET_EXPLORE_MODE](state, mode) {
    // set the current activity mode for explore screen
    state.exploreMode = mode;
  },
  [types.mutations.SET_EXPLORE_MOBILE_FS_CONTENT](state, mode) {
    // set the mobile full screen content mode for explore screen
    state.mobileFullScreenContent = mode;
  },
  [types.mutations.OPEN_SIDEBAR](state) {
    state.sidebarOpen = true;
  },
  [types.mutations.TOGGLE_SIDEBAR](state) {
    state.sidebarOpen = !state.sidebarOpen;
  },
  [types.mutations.CLOSE_SIDEBAR](state) {
    state.sidebarOpen = false;
  },
  [types.mutations.TOGGLE_SIDEBAR_CONTENT](state) {
    state.sidebarContentOpen = !state.sidebarContentOpen;
  },
  [types.mutations.OPEN_SIDEBAR_CONTENT](state) {
    state.sidebarContentOpen = true;
  },
  [types.mutations.CLOSE_SIDEBAR_CONTENT](state) {
    state.sidebarContentOpen = false;
  },
  [types.mutations.SET_SIDEBAR_CONTENT_TYPE](state, type) {
    // set the current active indicator
    state.sidebarContentType = type;
  },
  [types.mutations.SET_INDICATOR](state, indicator) {
    // set the current active indicator
    state.activeIndicator = indicator;
  },
  [types.mutations.SET_ACTIVE_LT](state, lt) {
    // set the current active indicator
    state.activeLT = lt;
  },
  [types.mutations.TOGGLE_SCENARIO_DIALOG](state) {
    state.showScenarioDialog = !state.showScenarioDialog;
  },
  [types.mutations.CLOSE_SCENARIO_DIALOG](state) {
    state.showScenarioDialog = false;
  },
  [types.mutations.TOGGLE_EDIT_SCENARIO_DIALOG](state) {
    state.showEditScenarioDialog = !state.showEditScenarioDialog;
  },
  [types.mutations.CLOSE_EDIT_SCENARIO_DIALOG](state) {
    state.showEditScenarioDialog = false;
  },
  [types.mutations.SET_SCENARIO_EDIT_MODE](state, mode) {
    state.scenarioEditMode = mode;
  },
  [types.mutations.OPEN_SCENARIO_COMPUTING_DIALOG](state) {
    state.showScenarioComputingDialog = true;
  },
  [types.mutations.SET_SCENARIO_COMPUTING_RUNTIME](state, runtime) {
    state.scenarioComputingRuntime = runtime;
  },
  [types.mutations.CLOSE_SCENARIO_COMPUTING_DIALOG](state) {
    state.showScenarioComputingDialog = false;
  },
  [types.mutations.SET_BASEMAP_STYLE](state, style) {
    // record the current basemap style setting
    state.basemapStyle = style;
  },
  [types.mutations.SET_MAP_DATALAYER](state, layer) {
    // record the current map datalayer setting
    state.mapDataLayer = layer;
  },
  [types.mutations.TRIGGER_MAP_DATALAYER_RELOAD](state) {
    state.triggerDataLayerReload = !state.triggerDataLayerReload;
  },
  [types.mutations.SET_MAP_FLYTO](state, payload) {
    // update the map flyto setting
    state.mapFlyTo = payload;
  },
  [types.mutations.SET_MAP_ACTION_TRIGGER](state, mapaction) {
    // update the map action trigger setting
    state.mapActionTrigger = mapaction;
  },
  [types.mutations.SHOW_LANDUSE_OVERLAY_ON](state) {
    state.showLanduseOverlay = true;
  },
  [types.mutations.TOGGLE_LANDUSE_OVERLAY](state) {
    state.showLanduseOverlay = !state.showLanduseOverlay;
  },
  [types.mutations.SHOW_PLANNING_MARKERS_ON](state) {
    state.showPlanningMarkers = true;
  },
  [types.mutations.SHOW_PLANNING_MARKERS_OFF](state) {
    state.showPlanningMarkers = false;
  },
  [types.mutations.TOGGLE_PLANNING_MARKERS](state) {
    state.showPlanningMarkers = !state.showPlanningMarkers;
  },
  [types.mutations.SHOW_PLANNING_MARKERS_LOCK_ON](state) {
    state.showPlanningMarkersLocked = true;
  },
  [types.mutations.SHOW_PLANNING_MARKERS_LOCK_OFF](state) {
    state.showPlanningMarkersLocked = false;
  },
  [types.mutations.SET_LAND_SUMMARY_TAB](state, tab) {
    // record the current tab setting
    state.activeLandSummaryTab = tab;
  },
  [types.mutations.SET_CLIMATE_TAB](state, tab) {
    // record the current tab setting
    state.activeClimateTab = tab;
  },
  [types.mutations.SET_SCENARIO_WIZARD_TAB](state, tab) {
    // record the current tab setting
    state.activeScenarioWizardTab = tab;
  },
  [types.mutations.TOGGLE_ACTIVE_SCENARIO_WIZARD_BTN](state, tab) {
    // trigger a next or prev tab change
    if (tab === "next") {
      state.activeScenarioWizardNext = !state.activeScenarioWizardNext;
    } else {
      state.activeScenarioWizardPrev = !state.activeScenarioWizardPrev;
    }
  },
  [types.mutations.SET_SELECTED_VIRTUAL_TOUR](state, tour) {
    state.selectedVirtualTour = tour;
  },
  [types.mutations.IND_LAYERS_INIT](state, payload) {
    state.showIndLayers = payload;
  },
  [types.mutations.TOGGLE_IND_LAYER](state, index) {
    state.showIndLayers[index] = !state.showIndLayers[index];
  },
  [types.mutations.IND_FEATURES_INIT](state, payload) {
    state.showIndFeatures = payload;
  },
  [types.mutations.TOGGLE_IND_FEATURE](state, index) {
    state.showIndFeatures[index] = !state.showIndFeatures[index];
  },
};

// actions
const actions = {
  [types.actions.SET_EXPLORE_MODE]({ commit }, mode) {
    commit(types.mutations.SET_EXPLORE_MODE, mode);
    if (mode === "create") {
      // force close any sidebar content
      commit(types.mutations.CLOSE_SIDEBAR_CONTENT);
    }
  },
  [types.actions.SET_EXPLORE_MOBILE_FS_CONTENT]({ commit }, mode) {
    commit(types.mutations.SET_EXPLORE_MOBILE_FS_CONTENT, mode);
  },
  [types.actions.OPEN_SIDEBAR]({ commit }) {
    commit(types.mutations.OPEN_SIDEBAR);
  },
  [types.actions.TOGGLE_SIDEBAR]({ commit }) {
    commit(types.mutations.TOGGLE_SIDEBAR);
  },
  [types.actions.CLOSE_SIDEBAR]({ commit }) {
    commit(types.mutations.CLOSE_SIDEBAR);
  },
  [types.actions.TOGGLE_SIDEBAR_CONTENT]({ commit }) {
    commit(types.mutations.TOGGLE_SIDEBAR_CONTENT);
  },
  [types.actions.OPEN_SIDEBAR_CONTENT]({ commit }) {
    commit(types.mutations.OPEN_SIDEBAR_CONTENT);
  },
  [types.actions.CLOSE_SIDEBAR_CONTENT]({ commit }) {
    commit(types.mutations.CLOSE_SIDEBAR_CONTENT);
  },
  [types.actions.SET_SIDEBAR_CONTENT_TYPE]({ commit }, type) {
    commit(types.mutations.SET_SIDEBAR_CONTENT_TYPE, type);
  },
  [types.actions.SET_INDICATOR]({ commit }, indicator) {
    commit(types.mutations.SET_INDICATOR, indicator);
  },
  [types.actions.SET_ACTIVE_LT]({ commit }, type) {
    commit(types.mutations.SET_ACTIVE_LT, type);
  },
  [types.actions.TOGGLE_SCENARIO_DIALOG]({ commit }) {
    commit(types.mutations.TOGGLE_SCENARIO_DIALOG);
  },
  [types.actions.CLOSE_SCENARIO_DIALOG]({ commit }) {
    commit(types.mutations.CLOSE_SCENARIO_DIALOG);
  },
  [types.actions.TOGGLE_EDIT_SCENARIO_DIALOG]({ commit }) {
    commit(types.mutations.TOGGLE_EDIT_SCENARIO_DIALOG);
  },
  [types.actions.CLOSE_EDIT_SCENARIO_DIALOG]({ commit }) {
    commit(types.mutations.CLOSE_EDIT_SCENARIO_DIALOG);
  },
  [types.actions.SET_SCENARIO_EDIT_MODE]({ commit }, mode) {
    commit(types.mutations.SET_SCENARIO_EDIT_MODE, mode);
  },
  [types.actions.OPEN_SCENARIO_COMPUTING_DIALOG]({ commit }, runtime) {
    commit(types.mutations.OPEN_SCENARIO_COMPUTING_DIALOG);
    commit(types.mutations.SET_SCENARIO_COMPUTING_RUNTIME, runtime);
  },
  [types.actions.CLOSE_SCENARIO_COMPUTING_DIALOG]({ commit }) {
    commit(types.mutations.CLOSE_SCENARIO_COMPUTING_DIALOG);
  },
  [types.actions.SET_BASEMAP_STYLE]({ commit }, style) {
    commit(types.mutations.SET_BASEMAP_STYLE, style);
  },
  [types.actions.SET_MAP_DATALAYER]({ commit }, layer) {
    commit(types.mutations.SET_MAP_DATALAYER, layer);
  },
  [types.actions.TRIGGER_MAP_DATALAYER_RELOAD]({ commit }) {
    commit(types.mutations.TRIGGER_MAP_DATALAYER_RELOAD);
  },
  [types.actions.SET_MAP_FLYTO]({ commit }, payload) {
    commit(types.mutations.SET_MAP_FLYTO, payload);
  },
  [types.actions.SET_MAP_ACTION_TRIGGER]({ commit }, mapaction) {
    commit(types.mutations.SET_MAP_ACTION_TRIGGER, mapaction);
  },
  [types.actions.SHOW_LANDUSE_OVERLAY_ON]({ commit }) {
    commit(types.mutations.SHOW_LANDUSE_OVERLAY_ON);
  },
  [types.actions.TOGGLE_LANDUSE_OVERLAY]({ commit }) {
    commit(types.mutations.TOGGLE_LANDUSE_OVERLAY);
  },
  [types.actions.SHOW_PLANNING_MARKERS_ON]({ commit }) {
    commit(types.mutations.SHOW_PLANNING_MARKERS_ON);
  },
  [types.actions.SHOW_PLANNING_MARKERS_OFF]({ commit }) {
    commit(types.mutations.SHOW_PLANNING_MARKERS_OFF);
  },
  [types.actions.TOGGLE_PLANNING_MARKERS]({ commit }) {
    commit(types.mutations.TOGGLE_PLANNING_MARKERS);
  },
  [types.actions.SHOW_PLANNING_MARKERS_LOCK_ON]({ commit }) {
    commit(types.mutations.SHOW_PLANNING_MARKERS_LOCK_ON);
  },
  [types.actions.SHOW_PLANNING_MARKERS_LOCK_OFF]({ commit }) {
    commit(types.mutations.SHOW_PLANNING_MARKERS_LOCK_OFF);
  },
  [types.actions.SET_LAND_SUMMARY_TAB]({ commit }, tab) {
    commit(types.mutations.SET_LAND_SUMMARY_TAB, tab);
  },
  [types.actions.SET_CLIMATE_TAB]({ commit }, tab) {
    commit(types.mutations.SET_CLIMATE_TAB, tab);
  },
  [types.actions.SET_SCENARIO_WIZARD_TAB]({ commit }, tab) {
    commit(types.mutations.SET_SCENARIO_WIZARD_TAB, tab);
  },
  [types.actions.TOGGLE_ACTIVE_SCENARIO_WIZARD_BTN]({ commit }, tab) {
    commit(types.mutations.TOGGLE_ACTIVE_SCENARIO_WIZARD_BTN, tab);
  },
  [types.actions.SET_SELECTED_VIRTUAL_TOUR]({ commit }, tour) {
    commit(types.mutations.SET_SELECTED_VIRTUAL_TOUR, tour);
  },
  [types.actions.IND_LAYERS_INIT]({ commit }, layersCount) {
    // initialize the indigenous layers statuses
    const indLayerStatuses = new Array(layersCount).fill(false);
    commit(types.mutations.IND_LAYERS_INIT, indLayerStatuses);
  },
  [types.actions.TOGGLE_IND_LAYER]({ commit }, index) {
    // turn a specific layer on
    commit(types.mutations.TOGGLE_IND_LAYER, index);
  },
  [types.actions.IND_FEATURES_INIT]({ commit }, count) {
    // initialize the indigenous layer features statuses
    const indLayerStatuses = new Array(count).fill(true);
    commit(types.mutations.IND_FEATURES_INIT, indLayerStatuses);
  },
  [types.actions.TOGGLE_IND_FEATURE]({ commit }, index) {
    // track indigenous layer feature visibility
    commit(types.mutations.TOGGLE_IND_FEATURE, index);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
