<template>
  <md-card md-with-hover>
    <md-card-header class="md-card-header-blue">
      <div class="md-layout">
        <div class="md-layout-item md-size-100 card-title">
          <md-icon class="card-title-icon">
            map
          </md-icon>
          <a @click="explore(scenarioData.id)">
            <span class="md-title">
              {{ scenarioData.name }}
            </span></a>
        </div>
        <div class="md-layout-item md-size-100">
          <md-icon class="card-title">
            place
          </md-icon>
          <span class="card-subtitle">{{ studyAreaName }}</span>
          <br>
          <md-chip class="scen-type">
            {{ scenarioData.type }}
          </md-chip>
          <md-chip
            v-if="scenarioData.type !== 'Historic'"
            :class="climateClass"
          >
            <i class="fas fa-thermometer-half" />
            {{ climateName }}
          </md-chip>
        </div>
      </div>
    </md-card-header>

    <md-card-content class="md-layout">
      <div class="md-layout-item md-size-100 layout-no-padding">
        <study-area-map
          v-if="showMap"
          ref="map"
          :scenario="scenarioData"
          :year="scenarioData.startTime"
          :show-study-area-overlay="true"
          class="scenario-map"
          @on-launch-scenario="explore(scenarioData.id)"
        />
      </div>
      <div class="md-layout-item md-size-100 layout-no-padding">
        <p
          v-if="dateCreated !== 'Invalid Date'"
          class="md-caption"
        >
          {{ dateCreated }}
        </p>
        <p>{{ scenarioData.description }}</p>
      </div>
      <!-- ACTIONS -->
      <div class="md-layout-item md-size-100 card-actions">
        <!-- DELETE -->
        <md-button class="md-simple md-just-icon">
          <span @click="confirmDeleteScenario">
            <md-icon>delete</md-icon>
            <md-tooltip md-direction="bottom">Delete</md-tooltip>
          </span>
        </md-button>
        <!-- SPINNER FOR INCOMPLETE SCENARIO -->
        <div
          v-if="!scenarioData.complete"
          style="display:inline; padding: 10px;"
        >
          <md-progress-spinner
            :md-diameter="20"
            :md-stroke="3"
            md-mode="indeterminate"
            style="margin-top:15px;"
          />
        </div>
        <div
          v-else
          style="display:inline;"
        >
          <!-- REPORT ADD -->
          <md-button
            v-if="!scenarioInActiveReport"
            class="md-simple md-just-icon"
          >
            <span @click="addToReport">
              <md-icon>addchart</md-icon>
              <md-tooltip md-direction="bottom">Add to Report</md-tooltip>
            </span>
          </md-button>
          <!-- REPORT REMOVE -->
          <md-button
            v-else
            class="md-simple md-just-icon md-success"
          >
            <span @click="removeFromReport">
              <md-icon>insert_chart</md-icon>
              <md-tooltip md-direction="bottom">Remove from Report</md-tooltip>
            </span>
          </md-button>
          <!-- SHARE -->
          <share-dialog
            v-if="$Region.socialSharingOn"
            ref="shareDialog"
            obj-type="scen"
            :obj="scenarioData"
            gtag-action="share_scenario_close"
            gtag-category="scenarios"
            @on-status-change="updateShareObj"
          >
            <md-button class="md-simple md-just-icon">
              <span @click="toggleShareDialog">
                <md-icon>share</md-icon>
                <md-tooltip md-direction="bottom">Share</md-tooltip>
              </span>
            </md-button>
          </share-dialog>
          <!-- EXPLORE -->
          <md-button class="md-raised md-dense md-success explore-btn">
            <span @click="explore(scenarioData.id)">
              <md-icon>explore</md-icon> Explore
              <md-tooltip md-direction="bottom">Explore</md-tooltip>
            </span>
          </md-button>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import StudyAreaMap from "./StudyAreaMap";
import ShareDialog from "@/pages/Dashboard/Components/ShareDialog";
import { Sharing } from "@/pages/Dashboard/Components/mixins/Sharing";
import swal from "sweetalert2";

export default {
  name: "UserScenarioCard",
  components: {
    StudyAreaMap,
    ShareDialog
  },
  mixins: [Sharing],
  props: {
    scenarioData: {
      type: Object,
      required: true
    },
    regionPath: {
      type: String,
      required: true
    },
    showMap: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hoverCount: 0,
      imgHovered: false,
      fixedHeader: false,
      climateName: '',
      climateClass: ''
    };
  },
  computed: {
    ...mapGetters([
      types.getters.ACTIVE_SCENARIO,
      types.getters.REPORT_MODE,
      types.getters.ACTIVE_REPORT,
      types.getters.ACTIVE_REPORT_SCENARIO_COUNT,
    ]),

    headerDown() {
      return this.hoverCount > 15;
    },
    studyAreaName() {
      const name = this.regionPath.replace(".geojson", "").split("__");
      return name[name.length - 1];
    },
    dateCreated() {
      return new Date(parseInt(this.scenarioData.dateCreated)).toDateString();
    },
    scenarioInActiveReport() {
      // is this scenario part of the current active report
      return (this.REPORT_MODE === 'create' && this.ACTIVE_REPORT.scenarios.findIndex(scen => scen.id === this.scenarioData.id) !== -1);
    },
  },

  beforeMount() {
    // set some climate info
    this.climateName = this.getClimateScenario(this.scenarioData.climate).name;
    this.climateClass = `climate-chip-${this.scenarioData.climate}`;
  },

  methods: {
    ...mapActions([
      types.actions.SET_ACTIVE_SCENARIO,
      types.actions.USER_SCENARIOS_REMOVE,
      types.actions.LOAD_USER_SCENARIOS,
      types.actions.CLOSE_SCENARIO,
      types.actions.ACTIVE_REPORT_CLEAR,
      types.actions.ACTIVE_REPORT_ADD_SCENARIO,
      types.actions.ACTIVE_REPORT_REMOVE_SCENARIO
    ]),

    getClimateScenario(code) {
      return this.$Region.climateScenarios.filter(scen => scen.variable === code)[0];
    },
    confirmDeleteScenario() {
      swal.fire({
        title: "Are you sure?",
        text: `This scenario will be a distant memory`,
        icon: "warning",
        iconHtml: `<i class="fas fa-exclamation"></i>`,
        showCancelButton: true,
        customClass: {
          confirmButton: "md-button md-success",
          cancelButton: "md-button md-danger",
        },
        confirmButtonText: "Yes, delete it",
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          // Move on to actually delete it...
          // if it's the scenario that's currently open, clear it
          if (this.ACTIVE_SCENARIO !== null && this.ACTIVE_SCENARIO.id === this.scenarioData.id) {
            this.CLOSE_SCENARIO();
          }
          this.USER_SCENARIOS_REMOVE(this.scenarioData); // remove from the USER_SCENARIOS array
          // api call to delete it
          const payload = {
            UserTransaction: "scen" + this.scenarioData.id,
            UserSub: this.$Auth.user.attributes.sub,
            userBucket: this.$Bucket
          };

          this.$Auth
            .currentSession()
            .then(authData => {
              const username = authData.accessToken.payload.username;
              payload.UserName = username;
              const path = "/simulation/" + username;
              const myInit = {
                body: payload,
                headers: { Authorization: authData.idToken.jwtToken }
              };
              this.$API
                .del("api", path, myInit)
                // eslint-disable-next-line
                .then(response => {
                  // Refresh the scenario data or error
                  this.LOAD_USER_SCENARIOS({ vm: this });
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error);
                });
            })
            // eslint-disable-next-line
            .then(resp => {
              swal.fire({
                title: "It's gone",
                text: "Your scenario has been deleted",
                icon: "success",
                customClass: {
                  confirmButton: "md-button md-success",
                },
                buttonsStyling: false
              });
            });
        }
      });
    },
    explore() {
      this.SET_ACTIVE_SCENARIO({ vm: this, scenario: this.scenarioData });
      this.$router.push({
        name: "Explore"
      });
    },
    async addToReport() {
      if (this.REPORT_MODE === 'view') {
        // reset the active report and set report mode to create
        await this.ACTIVE_REPORT_CLEAR();
      }
      if (this.ACTIVE_REPORT_SCENARIO_COUNT < 4) {
        this.ACTIVE_REPORT_ADD_SCENARIO(this.scenarioData);
        this.$emit('on-report-add-scenario');
      } else {
        this.$emit('on-report-scenarios-max');
      }
    },
    removeFromReport() {
      this.ACTIVE_REPORT_REMOVE_SCENARIO(this.scenarioData.id);
    },
    toggleShareDialog() {
      this.$refs.shareDialog.toggleShowDialog();
    }
  }
};
</script>

<style lang="scss" scoped>
.card-title-icon {
  position: relative;
  // margin: 0 0 0 -15px;
  padding: 0px;
  color: $white-color !important;
}
.md-title {
  font-size: 16px;
  line-height: 20px;
}
.card-title {
  color: $white-color !important;
}
.card-subtitle {
  color: darken($white-color, 2%) !important;
}
i.card-title {
  color: darken($white-color, 4%) !important;
}
.scen-type {
  background-color: darken($brand-info, 10%) !important;
}
.climate-chip-none {
  background-color: $gray !important;
}
.climate-chip-rcp26 {
  background-color: $brand-warning !important;
}
.climate-chip-rcp45 {
  background-color: $brand-danger !important;
}
.climate-chip-rcp85 {
  background-color: $brand-rose !important;
}
.card-actions {
  text-align: right;
}
.explore-btn {
  left: 5px;
  margin-top: 10px;
}
/deep/ .explore-btn .md-ripple {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
</style>
