<template>
  <modal
    v-show="isOpen"
    @close="closeFromOuterClick"
  >
    <template slot="header">
      <h4 class="modal-title">
        {{ tour.title }}
      </h4>
    </template>
    <template slot="body">
      <div
        v-show="!isLoaded"
        class="md-layout md-alignment-center-center"
      >
        <div class="loading md-layout-item">
          <p>
            Loading...
          </p>
          <md-progress-bar
            class="md-primary"
            md-mode="indeterminate"
          />
        </div>
      </div>

      <div
        v-show="isLoaded"
        class="md-layout md-alignment-center-center"
      >
        <p>
          {{ tour.description }}
        </p>
        <div
          id="kuula_tour"
          ref="tour"
        />
      </div>
    </template>
    <template slot="footer">
      <div
        v-if="isLoaded"
        class="md-layout-item md-size-100 md-layout md-alignment-center-center footer-buttons"
      >
        <share-dialog
          v-if="$Region.socialSharingOn"
          ref="shareDialog"
          obj-type="vt"
          :obj="tour"
          gtag-action="share_virtualtour_close"
          gtag-category="virtualtours"
        >
          <md-button class="md-simple md-just-icon">
            <span @click="toggleShareDialog">
              <md-icon>share</md-icon>
              <md-tooltip md-direction="bottom">Share</md-tooltip>
            </span>
          </md-button>
        </share-dialog>

        <md-button
          class="md-raised md-success close"
          @click="close"
        >
          Close
        </md-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import { types } from "@/store/types";
import { Modal } from "@/components";
import { User } from "@/pages/Dashboard/Components/mixins/User";
import ShareDialog from "@/pages/Dashboard/Components/ShareDialog";

function initialState() {
  return {
    isOpen: false,
    isLoaded: false,
    timer: null,
    posts: null,
    postsClicked: new Set(), // ids of clicked posts
    postsAllClicked: false,
    hotspotsClicked: new Set(), //uids of clicked hotspots
    hotspotsAllClicked: false, // arbitrary # of hotspots clicked (since we don't know how many hotspots there are)
    tourCompleted: false, // true when user has clicked sufficient posts & hotspots
    tour: {
      title: null,
      description: null,
      kuula_id: null
    },
    isSharing: false, //if sharing dialog is opened
  };
}

export default {
  name: "VirtualTourDialog",
  components: {
    Modal,
    ShareDialog
  },
  mixins: [User],
  data() {
    return initialState();
  },
  computed: {
    ...mapGetters([
      types.getters.SELECTED_VIRTUAL_TOUR
    ]),
  },
  watch: {
    SELECTED_VIRTUAL_TOUR(tour) {
      if (tour === null) return;

      //display tour
      this.isOpen = true;
      this.tour = tour.properties;
      const kuula_url = 'https://kuula.co/share/collection/' + this.tour.kuula_id + '?zoom=1';

      const tourDiv = this.$refs['tour'];
      tourDiv.textContent = '';
      const script = document.createElement('script');
      script.setAttribute('type', 'application/javascript')
      // script.setAttribute('src', 'https://static.kuula.io/embed.js');
      script.setAttribute('src', '/js/kuula_embed.js');
      script.setAttribute('data-kuula', kuula_url);
      script.setAttribute('data-width', '100%');
      script.setAttribute('data-height', '100%');
      tourDiv.appendChild(script);

      script.addEventListener('load', function() {
        // tell kuula_embed.js to spawn iframe
        window.dispatchEvent( new Event('kuula_load') );
      })

      this.$Helpers.gtagEvent(this, `view_virtualtour`, `virtualtours`, this.tour.code);

      // exit after 7 seconds if tour hasn't loaded
      this.timer = setTimeout(() => {
        if (this.isLoaded || !this.isOpen) return;
        this.$notify({
          message: this.tour.title + ' failed to load',
          icon: "error_outline",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger"
        });
        this.close();
      }, 10000);

    },
  },
  mounted() {

    const addKuulaListeners = () => {
      // KuulaPlayerAPI is defined within kuula_api.js
      /* eslint-disable */
      KuulaPlayerAPI.addEventListener("frameloaded", this.onLoaded);
      KuulaPlayerAPI.addEventListener("postloaded", this.postLoaded);
      KuulaPlayerAPI.addEventListener("hotspot", this.hotspotLoaded);
      /* eslint-enable */
    }

    if (document.querySelector('#kuula_api')) {
      // api already loaded
      addKuulaListeners();
    }
    else {
      // add kuula api
      const script_api = document.createElement('script')
      script_api.setAttribute('src', '/js/kuula_api.js')
      script_api.setAttribute('id', 'kuula_api')
      document.head.appendChild(script_api)
      // attach tour event handlers upon kuula_api load
      script_api.addEventListener('load', addKuulaListeners);
    }

  },
  methods: {
    onLoaded(e) {
      this.isLoaded = true;
      this.posts = e.data.posts;
    },
    postLoaded(e) {
      this.postsClicked.add(e.data.id);
      if (!this.postsAllClicked && this.postsClicked.size === this.posts.length) {
        this.postsAllClicked = true;
        this.checkTourCompleted();
      }
    },
    hotspotLoaded(e) {
      this.hotspotsClicked.add(e.data.uid);
      if (!this.hotspotsAllClicked && this.hotspotsClicked.size > 3) {
        this.hotspotsAllClicked = true;
        this.checkTourCompleted();
      }
    },
    checkTourCompleted() {
      if (!this.tourCompleted && this.postsAllClicked && this.hotspotsAllClicked) {
        this.tourCompleted = true;
        this.$Helpers.gtagEvent(this, `completed_virtualtour`, `virtualtours`, this.tour.code);
        this.updateUserHistory(this.tour.code);
      }
    },
    closeFromOuterClick() {
      //trigered from clicking outside modal
      //ignore outer clicks if sharing because clicking on share modal triggers outer cick
      if (this.isSharing) return;
      // ignore outer clicks if still loading, because initial click on a tour triggers outer click
      if (!this.isLoaded) return;
      this.close();
    },
    close() {
      clearTimeout(this.timer);
      Object.assign(this.$data, initialState()); //reset data
      window.dispatchEvent( new Event('kuula_destroy') );
    },
    toggleShareDialog() {
      this.$refs.shareDialog.toggleShowDialog();
      this.isSharing = true;
    },
  },
};
</script>

<style lang="scss" scoped>

/deep/ .modal-container {
  width: 85%;
  max-width: 1200px;
}

.loading {
  max-width: 500px;
  p {
    text-align:center;
  }
}

#kuula_tour {
  width:95%;
  max-width:1000px;
  height:500px
}

.footer-buttons {
  margin-top: 2em;
}

.close {
  margin-left: 10px;
}

</style>
