<template>
  <div
    id="top"
    class="wrapper"
    :class="[{'nav-open': $sidebar.showSidebar}, {'rtl': $route.meta.rtlActive}]"
  >
    <div v-if="scenarioRunIsActive">
      <IoTSub
        :topic="ACTIVE_RUNID"
        @on-topic-update="updateTopicScenario"
        @on-connected="verifyTopicScenarioConnection"
      />
    </div>
    <notifications />
    <side-bar
      id="SidebarMainMenu"
      :logo="`./img/${this.$Region.logo}`"
    >
      <user-menu
        v-if="authenticated"
        :user-name="userName"
      />
      <mobile-menu />
      <template slot="links">
        <sidebar-item :link="{name: 'Dashboard', icon: 'dashboard', path: '/dashboard'}" />
        <sidebar-item
          v-if="isTeacher"
          :link="{name: 'Teacher Resources', icon: 'assignment', path: '/dashboard/resources'}"
        />
        <sidebar-item :link="{name: 'Your Mission', icon: 'work', path: '/dashboard/mission'}" />
        <sidebar-item 
          is-fontawesome
          :link="{name: 'Indigenous Voices', icon: 'feather', path: '/dashboard/voices'}" 
        />
        <sidebar-item :link="{name: 'Explore', icon: 'explore', path: '/dashboard/explore'}" />
        <sidebar-item :link="{name: 'Videos', icon: 'videocam', path: '/dashboard/videos'}" />
        <sidebar-item
          v-if="virtualToursOn"
          :link="{name: 'Virtual Tours', icon: 'camera_alt', path: '/dashboard/virtual-tours'}"
        />
        <sidebar-item :link="{name: 'Scenarios', icon: 'map', path: '/dashboard/scenarios'}" />
        <sidebar-item :link="{name: 'Observations', icon: 'place', path: '/dashboard/observations'}" />
        <sidebar-item :link="{name: 'Reports', icon: 'insert_chart_outlined', path: '/dashboard/reports'}" />
        <sidebar-item :link="{name: 'Achievements', icon: 'emoji_events', path: '/dashboard/achievements'}" />
        <sidebar-item :link="{name: 'Leaderboard', icon: 'star', path: '/dashboard/leaderboard'}" />
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar
        :new-messages="newMessages"
        @search-scroll="scrollTo"
      />

      <div
        :class="{content: !$route.meta.hideContent}"
        @click="toggleSidebar"
      >
        <zoom-center-transition
          :duration="200"
          mode="out-in"
        >
          <router-view @emit-new-messages="newMessagesToNavbar" />
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter" />
    </div>
    <!-- rocket animation -->
    <rocket-animation />
    <intro-tour
      name="DashboardSidebar"
      :steps="dashboardSidebarTourSteps"
    />
  </div>
</template>
<script>
/* eslint-disable no-new */
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import IoTSub from '@/components/IoTSub';
import RocketAnimation from "@/pages/Dashboard/Components/RocketAnimation";
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
// import DashboardContent from "./Content.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
// import UserLayout from "../StudentContent.vue";
import { ZoomCenterTransition } from "vue2-transitions";
import { scroller } from "vue-scrollto/src/scrollTo";
import IntroTour from "@/pages/Dashboard/Components/IntroTour";
import { Tours } from "@/pages/Dashboard/Components/mixins/Tours";
import { TourSteps } from "@/pages/Dashboard/Components/mixins/TourSteps";
import swal from "sweetalert2";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    IoTSub,
    TopNavbar,
    ContentFooter,
    // DashboardContent,
    MobileMenu,
    UserMenu,
    // UserLayout,
    // SlideYDownTransition,
    ZoomCenterTransition,
    RocketAnimation,
    IntroTour
  },
  mixins: [Tours, TourSteps],

  data() {
    return {
      newMessages: [],
      authenticated: false,
      userName: "User",
      searchLocations: {
        Profile: {
          name: "User Profile",
          location: null
        },
        Scenarios: {
          name: "Scenarios",
          location: "/dashboard/scenarios"
        },
        Achievements: {
          name: "Dashboard",
          location: "achievements"
        },
        Reports: {
          name: "Dashboard",
          location: "reports"
        },
        Messages: {
          name: "Dashboard",
          location: "messages"
        }
      },
      scenarioRunIsActive: false,
      virtualToursOn: this.$Region.virtualToursOn,
    };
  },

  computed: {
    ...mapGetters([
      types.getters.ACTIVE_SCENARIO,
      types.getters.ACTIVE_RUNID,
      types.getters.ACTIVE_RUNID_NEW_SCENARIO_ID,
      types.getters.USER_SCENARIOS,
      types.getters.USER_DATA
    ]),

    userDataLoaded() {
      return this.USER_DATA.usertype !== null;
    },
    seenIntro() {
      return this.USER_DATA.seenIntro;
    },
    isTeacher() {
      return this.authenticated && this.userDataLoaded && this.USER_DATA.usertype === 'teacher';
    }
  },

  watch: {
    $route() {
      // Scroll to top
      const scroll = scroller();
      scroll("#top");
    },
    ACTIVE_RUNID(newVal) {
      // track whether we are subscribed/waiting on a scenario run
      if (newVal !== null) {
        this.scenarioRunIsActive = true;
      }
    },
    userDataLoaded(newVal) {
      // when user data is first loaded, launch the intro tour if user hasn't seen it before
      if (newVal !== false && !this.seenIntro && this.AVAILABLE_TOURS.length) {
        this.launchIntroTour();
      }
    },
    AVAILABLE_TOURS_TRIGGER() {
      // watch for new tours...
      // wait a few seconds before launching to make sure VUEX has a chance to catch up and all tours are registered
      if (this.authenticated) {
        // if authenticated and the user hasn't already seen the intro
        const vm = this;
        setTimeout(function () {
          const tours = vm.firstTimeTours;
          if (vm.firstTimeTours.length && !vm.TOUR_ACTIVE && vm.seenIntro) {
            // launch if they are new for the user and no tour is active
            vm.setPriorityTours(tours);
            vm.$tours[tours[0]].start();
            vm.SET_TOUR_ACTIVE(true);
          } else if (vm.TOUR_ACTIVE) {
            // check for subtractions to remove from the list of PRIORITY_AVAILABLE_TOURS
            const filteredTours = vm.PRIORITY_AVAILABLE_TOURS.filter(tour => vm.AVAILABLE_TOURS.includes(tour));
            // check for additions to add to the list of PRIORITY_AVAILABLE_TOURS
            const priorityAdditions = [];
            let spliceIndex = -1;
            tours.forEach(tour => {
              if (!filteredTours.includes(tour)) {
                // new tour needs to be added to the PRIORITY_AVAILABLE_TOURS
                // by default, let's just add any new tours to start immediately after the current tour
                if (spliceIndex === -1) {
                  // get the current tour
                  const currentTour = Object.keys(vm.$tours).find(key => {
                    return vm.$tours[key].isRunning;
                  });
                  spliceIndex = filteredTours.findIndex(_tour => _tour === currentTour);
                }
                priorityAdditions.push(tour);
              }
            });
            if (priorityAdditions.length) {
              // new tours need to be added
              filteredTours.splice(spliceIndex + 1, 0, ...priorityAdditions);
              // update the tour priorities
            }
            vm.setPriorityTours(filteredTours);
          }
        }, 2000);
      }
    }
  },

  async beforeCreate() {
    // Collect the user name and authentication
    try {
      const auth_status = await this.$Auth.currentAuthenticatedUser();
      const { given_name, family_name } = auth_status.attributes;
      this.authenticated = Boolean(given_name);
      this.userName = `${given_name} ${family_name}`;
    } catch (err) {
      this.authenticated = false;
    }
  },

  mounted() {
    const docClasses = document.body.classList;
    const isWindows = navigator.platform.startsWith("Win");
    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar("sidebar");
      initScrollbar("sidebar-wrapper");
      initScrollbar("main-panel");

      docClasses.add("perfect-scrollbar-on");
    } else {
      docClasses.add("perfect-scrollbar-off");
    }
  },

  methods: {
    ...mapActions([
      types.actions.CLEAR_ACTIVE_RUNID,
      types.actions.SET_ACTIVE_RUNID_NEW_SCENARIO_ID,
      types.actions.SET_SCENARIO_CONNECTION_ACTIVE,
      types.actions.LOAD_USER_SCENARIOS,
      types.actions.SET_ACTIVE_SCENARIO,
      types.actions.UPDATE_ACTIVE_SCENARIO,
      types.actions.TOGGLE_REFRESH_SCENARIO,
      types.actions.SCENARIO_UNDOS_PUSH,
      types.actions.CLOSE_SIDEBAR_CONTENT
    ]),

    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    launchIntroTour() {
      // launch the intro tour
      const vm = this;
      swal.fire({
        title: `Welcome to ${this.$Region.app_name}!`,
        text: `Before getting started, let's take a quick look around.`,
        icon: "success",
        customClass: {
          confirmButton: "md-button md-success",
        },
        buttonsStyling: false,
        confirmButtonText: "Let's Go!"
      }).then((result) => {
        if (result.value) {
          const tours = vm.firstTimeTours.length ? vm.firstTimeTours : vm.priorityTours;
          vm.setPriorityTours(tours);
          vm.$tours[tours[0]].start();
          vm.SET_TOUR_ACTIVE(true);
        }
      });
    },
    scrollTo(location) {
      if (location.includes("message")) {
        if (this.$route.name === "Dashboard") {
          // Scroll Directly
          const scroll = scroller();
          scroll("#" + location);
        } else {
          this.$router.push({
            name: "Dashboard",
            params: { scrollLocation: location }
          });
        }
      } else {
        const address = this.searchLocations[location];
        if (address.name === "Dashboard" && this.$route.name === "Dashboard") {
          // Scroll Directly
          const scroll = scroller();
          scroll("#" + address.location);
        } else {
          this.$router.push({
            name: address.name,
            params: { scrollLocation: address.location }
          });
        }
      }
    },
    newMessagesToNavbar(messages) {
      this.newMessages = messages;
    },
    verifyTopicScenarioConnection(topic) {
      if (topic === this.ACTIVE_RUNID) {
        this.SET_SCENARIO_CONNECTION_ACTIVE(true);
      }
    },
    // eslint-disable-next-line
    updateTopicScenario(topic, msg) {
      // udpdate a scenario run status based on IoT topic message
      // safe to ignore topic and msg. The only messages that will come are messages saying the scenario is complete
      this.scenarioRunIsActive = false;
      this.CLEAR_ACTIVE_RUNID();
      this.SET_SCENARIO_CONNECTION_ACTIVE(false);
      this.LOAD_USER_SCENARIOS({ vm: this });

      // get the updated scenario
      const waitingLoadUpdatedScen = () => {
        // loading updated scenario needs to be wrapped with a timeout because of async api loading stuff
        if (this.USER_SCENARIOS.length === 0) {
          setTimeout(waitingLoadUpdatedScen, 200);
        } else {
          const _scenID = (this.ACTIVE_RUNID_NEW_SCENARIO_ID !== null) ? this.ACTIVE_RUNID_NEW_SCENARIO_ID : this.ACTIVE_SCENARIO.id;
          const _scen = this.USER_SCENARIOS.find(
            scen => scen.id === _scenID
          );
          if (_scen.complete !== true || this.USER_SCENARIOS.length === 0) {
            // scenario is still computing
            setTimeout(waitingLoadUpdatedScen, 200);
          } else {
            if (this.ACTIVE_RUNID_NEW_SCENARIO_ID !== null) {
              this.SET_ACTIVE_SCENARIO({vm: this, scenario: _scen});
              this.SET_ACTIVE_RUNID_NEW_SCENARIO_ID(null);
            } else {
              this.UPDATE_ACTIVE_SCENARIO({ complete: true });
              this.TOGGLE_REFRESH_SCENARIO();
              this.CLOSE_SIDEBAR_CONTENT();
            }
          }
        }
      };
      waitingLoadUpdatedScen();
    }
  }
};
</script>
<style lang="scss" scoped>
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
