<template>
  <div>
    <md-card
      md-with-hover
      class="md-card-scenario md-card-no-elevation md-primary"
      :md-theme="cardTheme"
    >
      <div class="md-layout md-alignment-center-center">
        <div class="md-layout-item md-size-10 md-xsmall-size-30">
          <md-avatar>
            <img
              v-if="'avatar' in user"
              class="img"
              :src="avatarUrlComputed"
            >
            <md-icon v-else>
              person
            </md-icon>
          </md-avatar>
        </div>
        <div class="md-layout-item md-size-20 md-xsmall-size-50">
          <b>{{ user.UserName }}</b>
        </div>
        <div class="md-layout-item md-size-60 md-xsmall-size-40 md-xsmall-hide">
          <span v-if="'usertype' in user">
            {{ user.usertype | capitalize }}
          </span>
          <span v-else>
            {{ user.school }}
          </span>
        </div>
        <div class="md-layout-item md-size-10 md-xsmall-size-20">
          <b>
            <animated-number
              :value="userPointsComputed"
              :continue-from-previous="true"
              :reset="pointsResetToken"
              :duration="800"
              class="points-counter"
            />
          </b>
        </div>
      </div>
    </md-card>
    <md-card-actions md-alignment="space-between" />
  </div>
</template>

<script>
import { AnimatedNumber } from '@/components'
export default {
  name: "LeaderboardProfileCard",

  components: {
    AnimatedNumber
  },

  props: {
    user: {
      type: Object,
      required: true
    },
    activeUser: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      pointsResetToken: false,
      avatarUrl: null
    };
  },

  computed: {
    userPointsComputed() {
      return parseInt(this.user.points);
    },
    avatarUrlComputed() {
      return this.avatarUrl;
    },
    cardTheme() {
      return this.activeUser ? 'active-card' : 'leader-card';
    }
  },

  watch: {
    userPointsComputed() {
      this.pointsReset();
    },
  },

  beforeMount() {
    if ('avatar' in this.user) {
      this.getAvatarUrl();
    }
  },

  methods: {
    pointsReset() {
      this.pointsResetToken = !this.pointsResetToken;
    },
    async getAvatarUrl() {
      // get the templink for viewing an uploaded avatar image
      let avatarUrl = this.user.avatar;
      if (avatarUrl !== "./img/default-avatar.png") {
        avatarUrl = await this.$Helpers.getImageURL(
          this.$ImageBucket,
          this.$Auth,
          this.$API,
          avatarUrl
        );
        this.avatarUrl = avatarUrl;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import "~vue-material/dist/theme/engine"; // Import the theme engine

@include md-register-theme(
  "leader-card",
  (
    primary: md-get-palette-color(grey, 50)
  )
);

@include md-register-theme(
  "active-card",
  (
    primary: md-get-palette-color(amber, 500)
  )
);

@import "~vue-material/dist/components/MdCard/theme";
</style>
