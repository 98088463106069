<template>
  <div>
    <md-list v-if="authenticated">
      <md-list-item
        to="/"
        @click="linkClick"
      >
        <md-icon>dashboard</md-icon>
        Dashboard
      </md-list-item>
      <md-list-item
        to="/lock"
        @click="signOut"
      >
        <md-icon>lock_open</md-icon>
        Logout
      </md-list-item>
    </md-list>

    <md-list v-else>
      <md-list-item
        to="/landing"
        @click="linkClick"
      >
        <md-icon>dashboard</md-icon>
        Dashboard
      </md-list-item>
      <md-list-item
        to="/registerwizard"
        @click="linkClick"
      >
        <md-icon>person_add</md-icon>
        Register
      </md-list-item>
      <md-list-item
        to="/login"
        @click="linkClick"
      >
        <md-icon>fingerprint</md-icon>
        Login
      </md-list-item>
    </md-list>
  </div>
</template>
<script>
import swal from 'sweetalert2'
export default {
  name: "AuthLinks",
  components: {
  },

  props: {
      authenticated: {
          type: Boolean,
          required: true
      }
  },
  data() {
    return {
    }
  },

  methods: {
    linkClick() {
      this.$emit('on-link-clicked');
    },
    signOut() {
      this.$Auth.signOut()
        // eslint-disable-next-line no-console
        .then(data => console.log(data))
        .catch(err => {
          this.errorAlert(err.message)
        })
      this.$emit('on-link-clicked', true);
    },
    errorAlert(message) {
      swal.fire({
        title: `<p style="color:red;">Sorry</p>`,
        text: message,
        timer: 2000,
        showConfirmButton: false
      })
    }
  }
}
</script>
<style>
</style>
