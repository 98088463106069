// management practices and best practices setup

const RegionPractices = {
  // general info to describe all management practices
  GeneralInfo: {
    title: "Beneficial Management Practices",
    description:
      "What are Beneficial Management Practices and how do they affect the future of Alberta?  Find out what large companies, farmers, and you can do to minimize the effect on the environment.",
    media: {
      type: "video",
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/883ad936d3e74ad29f7aebfa3ae56dc2/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/a3db110f-8d4b-4190-b512-a84cf1472eb5/thumbnails/Management Practices_H264_tumb.0000039.jpg",
      shareUrl: "https://www.youtube.com/watch?v=WBKwcDIqJ0U",
    },
    hashtags:
      "landuse,landuseplanning,beneficialmanagementpractices,BMPs,lowerimpact,futurealberta",
    sliderDescription:
      "The Industrial activity slider represents the percentage of the landscape that is being used for Forestry and Oil and Gas production.  You can change this and see how it affects both your environmental and socio/economic indicators.",

  },
  MgmtPractices: [
    {
      name: "Agriculture Fertilizer Application Practices",
      variable: "agFertilizer",
      description:
        "Fertilizer application beneficial management practices include using injection application methods and determining phosphorus-based application rates instead of nitrogen-based application rates for manure application.  Switching from broadcast to injection application of fertilizer can reduce phorphorus runoff by 50% and reduces losses of phosphorus from crops by as much as 30 to 60% depending on source and application rate. The use of nitrogen-based manure application rates instead of phosphorus-based manure application rates can result in twice as much manure being applied to crops than is necessary to achieve phosphorus requirements for crops.  Preventing phosphorus runoff is a key element in maintaining water quality.",
    },
    {
      name: "Agricultural Water Conservation",
      variable: "waterConservationAg",
      description:
        "Agriculture is one of the main sectors that utilize water in Alberta.  By adopting water conservation practices, agricultural water use can be reduced by 30% or more.  Water conservation strategies include: changeover to low pressure pivots, growing of crops that require less water, replacing smaller canals with pipelines, lining major canals, and installing automatic flow monitoring and remotely controlled structures.",
    },
    {
      name: "Municipal Water Conservation",
      variable: "waterConservationMuni",
      description:
        "Power generation and municipal water use are important drivers in water consumption.  It has been found that the following management practices can help reduce water demand by 26% or more: financial incentives that target the residential subsector, increased water metering, improved water treatment, and application of new power generation technology (e.g., wind) and equipment.",
    },
    {
      name: "Energy Sector Water Conservation",
      variable: "waterConservationEnergy",
      description:
        "The energy sector can reduce water consumption by 35% or more by following these beneficial management practices: performing updates to equipment and operating procedures, new leak detection and repair programs, internal water reuse and recycling, less water-intensive oil sands tailings technologies, and alternatives to non-saline water for drilling and fracturing fluids.",
    },
    {
      name: "More Renewable Electricity Generation",
      variable: "increasedRenewables",
      description:
        "Electricity generation is a major source of greenhouse gases in Alberta.  Adopting more renewables for electricity generation reduces the electricity emissions per person by 20.3%.  Renewable energy sources such as solar, wind, and geothermal do not produce greenhouse gas emissions beyond their initial construction and they have lower embodied energy costs of construction than fossil fuel conterparts.  Renewables currently account for 9.7% of Alberta’s electricity.  As such, producing 30% of Alberta’s electricity from renewables would reduce greenhouse gas emissions associated with electricity generation by 20.3%",
    },
    {
      name: "Phase Out Coal Electricity",
      variable: "coalPhaseOut",
      description:
        "In 2020, a large portion of Alberta's electricity generation still comes from coal.  Replacing Alberta’s electricity generated from coal with electricity generated from gas reduces the electricity greenhouse gas emissions per person by 3.25 tonne/person.  Emission intensity by source is : 90.87 g CO2e/MJ for coal, 49.88 g CO2e/MJ for natural gas, 4.59 g CO2e/MJ for biomass, and 0 g CO2e/MJ for renewables.  As such, replacing coal with natural gas will reduce electricity emissions by 31%.  Emissions associated with electricity generation in Alberta are 10.58 tonne/person, so a 31% reduction is equivalent to 3.25 tonne/person",
    },
    {
      name: "Electricity and heating conservation",
      variable: "electricConservation",
      description:
        "According to a study of energy efficiency potential in Alberta, the province has the potential to reduce greenhouse gas emissions by 8.6% across the residential, commercial, and industrial subsector. The study evaluated energy savings and GHG emission reduction under an achievable potential forecast compared to business-as-usual at the sector level and customer segment level.",
    },
    {
      name: "Increased use of EV's",
      variable: "useEVs",
      description:
        "A study of energy efficiency opportunities in Alberta suggests that switching from internal combustion engine vehicles to hybrid electric vehicles for passenger and freight transportation has the potential to reduce GHG emissions by 12%.",
    },
    {
      name: "Urban Densification",
      variable: "urbanDensity",
      description:
        `Urban Densification means more people living in the same area of land.  By applying urban densification, population is able to increase without taking away from other areas like agriculture, grasslands or forest.  Project Drawdown evaluates global climate solutions according to their potential to reduce GHG emissions. Based on previous research, the project assumes that denser cities can result in 5% fewer trips by car which in-turn has a significant impact on the volume of GHG emissions in cities. But be careful; as population increases, so do GHG emissions as a total, even though per-capita GHG emissions will decrease due to the benefits of walkable cities.`,
    },
    {
      name: "Decreased Industrial Footprint",
      variable: "decreaseIndFootprint",
      description:
        "Industrial footprint has a significant impact on Natural Landscapes, as well as Caribou, Grizzly and Fish Habitats.  By minimizing industrial footprint and managing human access to landscapes, the risk to indicator species can be reduced and habitat can be improved.  Some benefial managment practices for reducing footprint include: reclamation of industrial infrastructure such as wells and roads, locating multiple wells per well pad, sharing/combined use of private roads (forestry, mining and energy), and implementing strategies that minimze the size and lifespan of industrial infrastructure.",
    },
    {
      name: "Carbon Capture",
      variable: "carbonCapture",
      description:
        "A study conducted by The Pembina Institute (2014) evaluated GHG emissions under 5 different levels of CCS penetration in the oil sands industry: 5%, 15%, 40%, 80%, and 100%. The study found that emissions could be stabilized at 2020 levels with a penetration rate of just under 40%. Although penetration rates of 80% and 100% achieve higher potential GHG reductions, these scenarios are less plausible given the current costs of emerging CCS technologies. Based on a capture efficiency of 90% and penetration of 40%, the overall reduction in emission intensity is 36%.",
    },
  ],
};

export default RegionPractices;
