// terms of use, privacy, other policies

const RegionPolicies = {
  policy: `
  <p>

                <strong>Alberta Tomorrow Foundation ("Alberta Tomorrow" or "we", "us", "our", etc.) </strong>
</p>
<p>

                    <strong>WEBSITE PRIVACY POLICY </strong>
</p>
<p>
Effective date: June 9, 2020. 
</p>
<p>
Alberta Tomorrow is committed to maintaining the privacy of individuals and protecting personal information in its custody or control in accordance with privacy legislation applicable to Alberta Tomorrow. 
</p>
<p>
We may from time to time revise this Policy to reflect changes in our procedures regarding personal information and post the revised version of the Policy on this Website. Upon posting, the revised Policy will come into effect. 
</p>
<p>
<strong>Terminology </strong>
</p>
<p>
<em>Personal information </em>means information about an identifiable individual. This does not include information of an aggregate or anonymous nature where a specific individual or individuals cannot be identified, nor does it include business contact information used to contact an individual in their capacity as an employee or official of an organization. 
</p>
<p>
<strong>Application </strong>
</p>
<p>
This Policy is a summary of Alberta Tomorrow's privacy practices and policies governing this website ("<strong>Website</strong>") and parties to our License and Services Agreement (“<strong>Services Agreement</strong>”). 
</p>
<p>
<strong>Consent </strong>
</p>
<p>
By accessing this Website, and/or entering into the Services Agreement, you confirm your consent to the collection, use and disclosure of your personal information as described in this Policy. If you do not agree with the contents of this Policy, or to our collection, use or disclosure of personal information as described in this Policy, then you may not access this Website and you may not enter into the Services Agreement, or access or use the Services or the Materials. 
</p>
<p>
It is our policy to collect, use and disclose personal information only with consent, but we reserve our right to do so without consent where required to or permitted to by law, such as, for example, in course of an investigation or in relation to a legal proceeding, or to comply with a court order. We do not sell your personal information to any third party. 
</p>
<p>
<strong>Privacy and this Website </strong>
</p>
<p>
We do not collect any personal information from you when you simply visit our website without having entered into our Services Agreement, or any other contractual relationship with Alberta Tomorrow, or having voluntarily provided us with your personal information. The information we collect when you simply visit our Website is of an aggregate or anonymous nature. 
</p>
<p>
However, you should be aware that most web servers do collect some information about visitors, such as the browser and version you are using, your operating system, and your "IP" or 
</p>
<p>
internet address, which may identify your Internet Service Provider or computer but typically not the person using it. We may also use cookies as described below. 
</p>
<p>
<strong>Privacy and our Services Agreement </strong>
</p>
<p>
If you choose to enter into our Services Agreement in order to access the Services and Materials (as defined in the Services Agreement), you will be asked for personal information to confirm your identity, which may include your name, address, phone number and email address, and also the school or learning institution at which you are a student, teacher, faculty or staff member, or your place of employment, and other information as stated to you at the time of collection of your personal information, or as described in this Policy. This information is collected, used, and disclosed to provide the Services and Materials to you, to ensure your compliance with the Services Agreement, and, where applicable, to process payment from you or on your behalf. 
</p>
<p>
<strong>Cookies </strong>
</p>
<p>
We may use cookies as described in this Policy. A cookie is a small file containing a string of information that our Website stores on a visitor's computer, and that the visitor's browser provides to the Website each time the visitor returns. 
</p>
<p>
We may use cookies to count visitors to the Website, to monitor, assess, and understand how visitors navigate the Website and which portions of the Website are used the most or the least, or appear to be the most or the least interesting. We do so to permit us to enhance or improve the Website, its features, and operations, and how visitors can use and navigate it. 
</p>
<p>
Where you simply visit the Website, any use by us of cookies does not collect any personal information, and you remain anonymous. 
</p>
<p>
Where you are a party to our Services Agreement, and you visit this Website after you have logged in, we may use cookies for the above purposes, but you may no longer be anonymous as we may be able to link your personal information associated with your log in account to your use of the Website. In addition to the purposes for using cookies described above, we may also use cookies to remember you and your preferences to allow you to avoid having to log in every time, and to specifically tailor relevant content for you based on how you interact with the Website, or the Services or Materials you select. 
</p>
<p>
Cookies may generally be disabled or removed by tools that may be available as part of most commercial browsers, and in some but not all instances can be blocked in the future by selecting certain settings. Each browser you use will need to be set separately and different browsers offer different functionality and options in this regard. Also, these tools may not be effective with all cookies. Please be aware that if you disable or remove cookies, some parts of the Website may not work, and when you revisit the Website your ability to limit cookies will be subject to your browser settings and limitations. 
</p>
<p>
<strong>Security </strong>
</p>
<p>
While we make reasonable efforts to maintain the security of personal information in our custody or control, please be advised that the Internet and email are inherently insecure media, and we accept no liability for the security or privacy of personal information in transit over the Internet. 
</p>
<p>
<strong>Unsolicited or Voluntary Personal Information </strong>
</p>
<p>
If you choose to voluntarily submit personal information to us by email, online forms, or other means, we will consider that you have done so with your consent for purposes reasonably related to your providing the information. If reasonable to do so, or if you request ongoing provision of information after any initial response from us, we may communicate further with you with information that may be useful, but we will include instructions on how to terminate receiving such further information. Nothing in this Policy obligates us to respond to any communication from anybody. We may share your personal information with our employees or contractors if reasonable to do so having regard to the purposes for which you have provided us with your information. It is important that any information you provide to us is up to date and accurate so, if you have provided personal information to us, we request that you keep us informed of any changes to your personal information. 
</p>
<p>
<strong>Outsourcing and Data Hosting Outside of Canada </strong>
</p>
<p>
We may use third party service providers to process or deal with data and information on our behalf, and that may include personal information. In order to protect the confidentiality and security of any personal information processed on our behalf by our service providers, we use contractual and similar measures with such service providers, including contractual non- disclosure provisions. 
</p>
<p>
We may use cloud computing third party service providers, and those providers may be either in or outside Canada, and the data housed, hosted and processed by such providers may reside in or outside of Canada, and may include personal information. Where consent or notification is legally required, it is our policy to notify individuals about such service providers outside of Canada, and such notification will include the way in which the individual may obtain access to written information about our policies and practices with respect to service providers outside of Canada and the name or tile of a person who can answer any questions about the collection, use, disclosure or storage of personal information by any service providers outside Canada. 
</p>
<p>
Currently, Alberta Tomorrow uses an Amazon data centre located in Oregon, in the United States, to store data, including personal information. This means that data may be subject to access by US law enforcement. Further information is available about this by contacting Jennifer Janzen using the contact information under "Further Information" below. 
</p>
<p>
<strong>Other Websites </strong>
</p>
<p>
Please note that our Website may contain links to other websites which are provided as a convenience for visitors to our website only. Any third-party websites will have their own privacy policies and practices, and we cannot be responsible for such third parties or their websites. 
</p>
<p>
<strong>CASL Compliance </strong>
</p>
<p>
Canada has implemented stringent legislation (Generally referred to as Canadian Anti-Spam Legislation, or "<strong>CASL</strong>") prohibiting commercial electronic communications without adequate consent, and without an adequate unsubscribe mechanism. Emails and text messages of a commercial nature are generally considered to be commercial electronic communications ("<strong>CEM's</strong>"). It is the policy of Alberta Tomorrow to not send CEMs without valid legal consent from the recipient, and it is our policy to always use the unsubscribe mechanisms prescribed by CASL. 
</p>
<p>
<strong>Further Information </strong>
</p>
<p>
For further information with respect to our privacy practices, or with respect to inquiries concerning personal information about you in our custody or control, or to update or correct your information, you may contact us at: 
</p>
<p>
Alberta Tomorrow Executive Director: Jennifer Janzen Email: info@albertatomorrow.ca Alberta Tomorrow Foundation 40 Riverview Circle Cochrane, AB, Canada T4C 1K3 
</p>
  <p>

  <strong>ALBERTA TOMORROW WEBSITE TERMS OF USE LICENSE AND SERVICES AGREEMENT </strong>
</p>
<p>
<strong>APPLICATION: </strong>The provisions of this Agreement (the “Agreement”) apply to all access to or use of the Alberta Tomorrow Website and, where and as applicable, the Services (as defined below). 
</p>
<p>
<strong>Please read this Agreement carefully. This Agreement restricts and limits your rights. You agree to transact and contract electronically. By accepting this Agreement, and/or by accessing or using the Albert Tomorrow website and/or the Services and/or the Materials, you enter into a binding legal agreement and contract with the Alberta Tomorrow Foundation (“Alberta Tomorrow”), and you accept all the terms and conditions of this Agreement, as set out below: </strong>
</p>
<p>
<strong>1. Governing Law and Enforcement </strong>
</p>
<p>
(a) This Agreement will be governed by and interpreted according to the laws of the province of Alberta, Canada, and the laws of Canada applicable therein. Regardless of your location of residence, domicile, or physical presence, you irrevocably attorn and submit to the exclusive jurisdiction of the courts of Alberta, sitting at Calgary, without regard to the conflicts of law rules or principles of such courts. You agree that you will not bring any action in any other jurisdiction, and you agree that you will not commence or maintain any action by way of class proceedings whatsoever. 
</p>
<p>
(b) Although the general policy of Alberta Tomorrow is to provide reasonable notice of changes to this Agreement, or the nature, quality, operation or specifications of the Services, including any Materials (as defined below) provided or made available by Alberta Tomorrow from time to time, Alberta Tomorrow assumes no obligation to do so. You agree that Alberta Tomorrow may modify the Services, or the Materials, and/or amend this Agreement at any time without notice to you, and you agree to regularly review this Agreement for any amendments. Your continued use of the Services or Materials following such changes constitutes your acceptance of any amendments to this Agreement or modification to the Services or Materials. 
</p>
<p>
(c) The Services and the Materials are not available and are not to be used where prohibited or limited by law. The Services and the Materials are not available to you and must not be accessed or used by you from any location or jurisdiction, or under any circumstances, where your access to the Services or the Materials renders Alberta Tomorrow subject to the laws of any jurisdiction which may or does limit the rights of Alberta Tomorrow, or increases the liability of Alberta Tomorrow, or limits or makes void all or any part of the terms, conditions, disclaimers and limitations of this Agreement with respect to Alberta Tomorrow. 
</p>
<p>
(d) In the event of any breach, or threatened or pending breach, of this Agreement by you, Alberta Tomorrow will be entitled to seek injunctive or other equitable relief in any court of competent jurisdiction without any requirement as to posting a bond, giving an undertaking as to damages, or providing proof of damages not being an adequate remedy. 
</p>
<p>
<strong>2. The Services </strong>
</p>
<p>
(a) The “<strong>Services</strong>” means access to the Alberta Tomorrow website and, to the extent permitted by Alberta Tomorrow (which requires your completing a click-through confirmation of your acceptance of this Agreement, or other mechanism confirming acceptance), your ability to use any software provided on a “software as a service” basis, including any mobile applications, and your ability to download, copy, print and display educational and similar materials (the “<strong>Materials</strong>”). 
</p>
<p>
(b) The Services and the Materials are owned by and are the exclusive property of Alberta Tomorrow. 
</p>
<p>

The Services and the Materials are licensed, not sold. 
</p>
<p>
(c) Alberta Tomorrow reserves the right to refuse access to the Services and the Materials to anyone for any reason at any time, and to reject or terminate the use of, or force forfeiture of any username for any reason. 
</p>
<p>
(d) Alberta Tomorrow has the right, but no obligation to, remove, edit, block, and/or monitor any data, documents, records, materials, information, text, files, usernames, images, graphics, photos, profiles, audio and video clips, sounds, musical works, works of authorship, applications, links and any other content or materials of any kind (collectively, “<strong>User Content</strong>”) or accounts containing User Content that Alberta Tomorrow determines in its sole discretion violate this Agreement. 
</p>
<p>
(e) Alberta Tomorrow may grant you additional rights to the Services, the Materials, or any software, 
</p>
<p>

but that will require a separate written agreement with Alberta Tomorrow, in its sole discretion. 
</p>
<p>
(f) Alberta Tomorrow has the right to modify or terminate this Agreement, including your account, your access to the Services, or any right, permission or license to use the Materials, for any reason, without notice, at any time, and without liability to you. 
</p>
<p>
(g) The United Nations International Convention on the Sale of Goods does not apply to this 
</p>
<p>

Agreement. 
</p>
<p>
<strong>3. Your Rights </strong>
</p>
<p>
(a) You do not own the Services or the Materials. Your rights under this Agreement are personal to 
</p>
<p>

you. You cannot transfer any rights you may have under this Agreement to any other person. 
</p>
<p>
(b) Alberta Tomorrow permits you to create an account which includes a revocable, non-exclusive, personal, and non-transferrable license to access and use the Services, and to download, copy, print and display the Materials, including minimal and reasonable modifications to the Materials, provided that your purposes for doing any of the foregoing must be limited to educational and related purposes, or for such other purposes as Alberta Tomorrow may direct or permit. You will not under any circumstances remove any proprietary markings, copyright or other intellectual property notices, or attributions of origin or authorship from the Services or the Materials. The rights granted to you under this Agreement are conditional on your continuing compliance with this Agreement. 
</p>
<p>
(c) You are exclusively responsible for all costs of accessing or using the Services and the Materials, 
</p>
<p>

including charges for data, connectivity, software, and hardware. 
</p>
<p>
<strong>4. Your Responsibilities </strong>
</p>
<p>
(a) By accepting this Agreement, or accessing or using the Services, you warrant and represent that you have full legal capacity and authority to do so, that you will not be in breach of any obligation or duty, whether legal, equitable, fiduciary or otherwise, and that your jurisdiction does not limit the rights of Alberta Tomorrow, or increase the liability of Alberta Tomorrow, or limit or make void all or any part of the terms, conditions, disclaimers and limitations of this Agreement with respect to Alberta Tomorrow. 
</p>
<p>
(b) You use the Services and the Materials voluntarily and entirely at your own risk. 
</p>
<p>
(c) You agree to conduct yourself in compliance with this Agreement and any other acceptable use 
</p>
<p>

policy or other directive of Alberta Tomorrow. 
</p>
<p>
(d) You are responsible for keeping your password secret and secure. You will be responsible for any activity that occurs through your account and you agree you will not disclose, sell, transfer, license or assign your account, username, password, or any account rights to anyone else. 
</p>
<p>
(e) You warrant and represent that all information you provide to Alberta Tomorrow will always be true, accurate, current, and complete and you agree to update your information as necessary to maintain its truth and accuracy. 
</p>
<p>
(f) You are exclusively responsible for your conduct and any User Content that that you submit, post, 
</p>
<p>

or display on or via the Services. 
</p>
<p>
(g) You will not use the Services or the Materials for any illegal or unauthorized purpose. You agree to comply with all laws and regulations applicable to the Services and the Materials, and to your use of the Services and the Materials, including but not limited to privacy laws, and intellectual property laws (including copyright and trademark laws). 
</p>
<p>
(h) You will not solicit, collect, or use the login credentials of other Alberta Tomorrow users. 
</p>
<p>
(i) You will not defame, stalk, bully, abuse, harass, threaten, impersonate or intimidate any other person or entity, you will not invade another person’s privacy, or collect or store personal data about other users except as permitted by applicable privacy laws and legislation, and you will not post private or confidential information via the Services. 
</p>
<p>
(j) You will not post violent, nude, partially nude, discriminatory, unlawful, infringing, hateful, 
</p>
<p>

pornographic, obscene, or sexually suggestive User Content via the Services. 
</p>
<p>
(k) You will not modify, translate, reverse engineer, decompile, disassemble, alter, copy, disseminate, distribute, publish or broadcast the Services or any Alberta Tomorrow software, or create compilations or derivative works based on the Services or such software, or assist or allow anyone to perform any one or more of those activities, or change, modify or alter another website so as to falsely imply that it is associated with the Services or Alberta Tomorrow. 
</p>
<p>
(l) You will not interfere with or disrupt the Services, or any servers or networks connected to the 
</p>
<p>

Services. 
</p>
<p>
(m) You will not upload, transfer, send, receive, or transmit any malicious, destructive, or disruptive computer code or malware, including but not limited to worms, viruses, spyware, phishing or bulk commercial emails, or any material of a similar nature. 
</p>
<p>
(n) You will not inject content or code or otherwise alter or interfere with the Services or the way any 
</p>
<p>

Alberta Tomorrow webpage is rendered or displayed in a user's browser or device. 
</p>
<p>
(o) You will not create an account with the Services through unauthorized means, including but not 
</p>
<p>

limited to, by using an automated device, script, bot, spider, crawler, or scraper. 
</p>
<p>
(p) You will not attempt to restrict another user from using or enjoying the Services and you will not encourage or facilitate violations of this Agreement or any other agreement, policy, or relationship of Alberta Tomorrow. 
</p>
<p>
<strong>5. Violation of this Agreement by You </strong>
</p>
<p>
(a) Any breach or violation of this Agreement, including the spirit of this Agreement, may, in Alberta Tomorrow's sole discretion, result in immediate suspension or termination without notice to you, or liability to you, of your Alberta Tomorrow account and/or access to the Services. In addition, you will be fully liable to, and indemnify Alberta Tomorrow for, any damages occasioned to Alberta Tomorrow by your breach or violation of this Agreement. 
</p>
<p>
<strong>6. Proprietary Rights </strong>
</p>
<p>
(a) While this Agreement grants you a revocable, non-exclusive, personal, and non-transferrable license as provided in this Agreement, the Services, the Alberta Tomorrow Website, and all Materials made available through the Services or otherwise by Alberta Tomorrow, including all intellectual property rights (including copyright and moral rights) therein, are the exclusive property of Alberta Tomorrow, or its service providers, suppliers or licensors, all rights reserved. The Alberta Tomorrow name and logo are trademarks of Alberta Tomorrow, all rights reserved. 
</p>
<p>
(b) It is Alberta Tomorrow's policy not to accept or consider content, information, ideas, submissions, suggestions, or other materials (collectively, “<strong>Suggestions</strong>”) other than those we have specifically requested. This is to avoid any misunderstandings if your Suggestions are the same or similar to those Alberta Tomorrow has independently developed, is developing, or may develop in the future. Accordingly, Alberta Tomorrow does not accept unsolicited Suggestions, and takes no responsibility for any Suggestions provided to Alberta Tomorrow. If, despite Alberta Tomorrow’s policy, you choose to send Suggestions to Alberta Tomorrow, your doing so confirms that you agree that Alberta Tomorrow is free to use any Suggestions or other materials without any compensation or attribution to you, and for any purposes whatsoever, including, without limitation, developing and marketing products and services, without any liability or payment of any kind to you. 
</p>
<p>
<strong>7. Disclaimers and Limitations </strong>
</p>
<p>
<strong>EXCEPT AS EXPRESSLY STATED OTHERWISE IN THIS AGREEMENT: </strong>
</p>
<p>
(a) <strong>YOU ACKNOWLEDGE, WARRANT AND REPRESENT THAT YOU HAVE INDEPENDENTLY DETERMINED THAT THE SERVICES AND THE MATERIALS MEET YOUR REQUIREMENTS AND THAT YOU HAVE NOT, AND DO NOT, RELY ON ANY REPRESENTATION MADE BY ALBERTA TOMORROW AS TO THE SUITABILITY OF THE SERVICES OR THE MATERIALS FOR ANY PARTICULAR PURPOSE. </strong>
</p>
<p>
(b) <strong>YOU ACKNOWLEDGE AND AGREE THAT EMAIL, ELECTRONIC COMMUNICATIONS, THE INTERNET, AND THE WORLD WIDE WEB ARE NOT FULLY DEPENDABLE MEDIA OR MEANS OF COMMUNICATION, AND YOU USE THE FOREGOING SOLELY AT YOUR OWN RISK. ALBERTA TOMORROW SHALL HAVE NO LIABILITY TO YOU FOR LOSS OF, DAMAGE TO, OR ALTERATION OF DATA OR INFORMATION, INCLUDING BUT NOT LIMITED TO USER CONTENT, IN TRANSIT OVER THE INTERNET, THE WORLD WIDE WEB, VIA ELECTRONIC MEANS, THE SERVICES OR VIA EMAIL. </strong>
</p>
<p>
(c) <strong>YOU ARE EXCLUSIVELY RESPONSIBLE FOR OBTAINING AND DEALING WITH YOUR OWN INTERNET SERVICES PROVIDER, AND FOR YOUR OWN CONNECTIONS TO THE SERVICES, THE INTERNET, AND THE WORLD WIDE WEB. ALBERTA TOMORROW WILL HAVE NO LIABILITY TO YOU FOR FAILURE OF ANY INTERNET SERVICES PROVIDER OR OTHER THIRD PARTY OF ANY KIND TO PROVIDE SERVICES, INCLUDING ANY FAILURE BY ALBERTA TOMORROW’S INTERNET SERVICES PROVIDER OR OTHER SERVICE PROVIDERS TO ALBERTA TOMORROW </strong>
</p>
<p>
(d) <strong>THE SERVICES, INCLUDING, WITHOUT LIMITATION, ANY ALBERTA TOMORROW SOFTWARE, AND THE MATERIALS, ARE ALL PROVIDED OR MADE AVAILABLE STRICTLY ON AN "AS IS", “WHERE IS”, "AS AVAILABLE" AND "WITH ALL FAULTS ACCEPTED" BASIS. </strong>
</p>
<p>
(e) <strong>THERE ARE AND WILL BE NO REPRESENTATIONS, WARRANTIES, TERMS, CONDITIONS. GUARANTEES, OR COVENANTS, EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, GIVEN BY ALBERTA TOMORROW, OR AFFECTING ANYTHING TO BE PROVIDED, LICENSED, MADE AVAILABLE OR DELIVERED BY ALBERTA TOMORROW UNDER THIS AGREEMENT, INCLUDING WITHOUT LIMITATION THE SERVICES (INCLUDING ANY SOFTWARE, GOODS, SERVICES OR CONTENT OF ANY KIND), AND THE MATERIALS. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, YOU </strong>
</p>
<p>

<strong>HEREBY WAIVE AND ALBERTA TOMORROW HEREBY DISCLAIMS ANY WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED, OF TITLE, RIGHT TO GRANT A LICENSE, MERCHANTABLE QUALITY, COMPLIANCE WITH DESCRIPTION, NON-INFRINGEMENT OF THIRD PARTY RIGHTS, QUIET ENJOYMENT, FREEDOM FROM MALICIOUS COMPUTER CODE, MALWARE, VIRUSES OR OTHER DELETERIOUS CONTENT OR CODE, OR FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTY OR CONDITION ARISING BY STATUTE OR OTHERWISE IN LAW OR FROM A COURSE OF DEALING OR USAGE OF TRADE. </strong>
</p>
<p>
(f) <strong>ALBERTA TOMORROW DOES NOT WARRANT AND GIVES NO CONDITION THAT THE OPERATION, APPLICATION OR AVAILABILITY OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE, OR THAT THE MATERIALS OR ANY CONTENT PROVIDED BY ALBERTA TOMORROW, OR AVAILABLE THROUGH THE SERVICES, WILL BE AVAILABLE, COMPLETE, UNALTERED, ACCURATE, TIMELY, UP TO DATE, TRUTHFUL, OR RELIABLE. </strong>
</p>
<p>
(g) <strong>ALBERTA TOMORROW’S AFFILIATES, PARENTS, SUBSIDIARIES, RELATED ENTITIES, OR ANY OF ITS OR THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS (COLLECTIVELY, THE “ALBERTA TOMORROW PARTIES”) WILL NOT, UNDER ANY CIRCUMSTANCES, HAVE ANY LIABILITY WHATSOEVER TO YOU OR ANYONE CLAIMING THROUGH YOU. </strong>
</p>
<p>
(h) <strong>YOU AGREE THAT, EVEN IF ALBERTA TOMOROW HAS BEEN ADVISED BY YOU OF THE POSSIBILITY OF SUCH DAMAGES, IN NO EVENT WILL ALBERTA TOMORROW BE LIABLE FOR ANY DAMAGES IN THE NATURE OF PUNITIVE, EXEMPLARY, INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES, INCLUDING WITHOUT LIMITATION, ECONOMIC LOSS, LOST BUSINESS REVENUE, LOST PROFITS, FAILURE TO REALIZE EXPECTED SAVINGS, LOSS OF DATA, LOSS OF BUSINESS OPPORTUNITY, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES OR ANY CLAIM AGAINST YOU BY ANY THIRD PARTY. </strong>
</p>
<p>
(i) <strong>WITHOUT DEROGATING FROM ANY OTHER LIMITATION OF LIABILITY CONTAINED IN THIS AGREEMENT, YOU AGREE THAT THE AGGREGATE OF ALL LIABILITY ON THE PART OF ALBERTA TOMORROW FOR BREACH OF ANY WARRANTY, REPRESENTATION, TERM OR CONDITION CONTAINED IN THIS AGREEMENT OR ANY OTHER BREACH GIVING RISE TO LIABILITY, INCLUDING A BREACH OF A CONDITION OR FUNDAMENTAL TERM OR FUNDAMENTAL BREACH OR BREACHES OR IN ANY OTHER WAY ARISING OUT OF OR RELATED TO THIS AGREEMENT OR ANY AGREEMENT CONTEMPLATED BY THIS AGREEMENT, FOR ANY AND ALL CAUSES OF ACTION WHATSOEVER AND, REGARDLESS OF THE FORM OF ACTION (INCLUDING BREACH OF CONTRACT, STRICT LIABILITY OR TORT, INCLUDING NEGLIGENCE, BREACH OF ANY DUTY, OR ANY OTHER LEGAL OR EQUITABLE THEORY), SHALL BE LIMITED TO YOUR ACTUAL DIRECT PROVABLE DAMAGES IN AN AMOUNT NOT TO EXCEED TEN DOLLARS ($10.00) IN CANADIAN CURRENCY. </strong>
</p>
<p>
(j) <strong>EACH PARTY AGREES AND ACKNOWLEDGES THAT THE FOREGOING DISCLAIMERS AND LIMITATIONS ARE VALID AND REASONABLE, ARE REFLECTED IN THE PRICING FOR THE SERVICES, ARE KNOWN TO YOU AND ARE ACCEPTED BY YOU. </strong>
</p>
<p>
(k) <strong>THE DISCLAIMERS LIMITATIONS HEREIN PROVIDED SHALL ENURE TO THE BENEFIT OF THE PARTIES AND THEIR SERVANTS, AGENTS, EMPLOYEES, SUB-CONTRACTORS AND THEIR RESPECTIVE OFFICERS, AGENTS AND EMPLOYEES. THESE LIMITATIONS APPLY NOTWITHSTANDING THE FAILURE OF THE ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. </strong>
</p>
<p>
<strong>8. Indemnification </strong>
</p>
<p>
(a) <strong>YOU (AND ALSO ANY THIRD PARTY FOR WHOM YOU OPERATE AN ACCOUNT OR ACTIVITY ON THE SERVICES, WHETHER WITH THE CONSENT OF ALBERTA TOMORROW OR NOT) AGREE TO DEFEND, </strong>
</p>
<p>

<strong>INDEMNIFY AND FOREVER HOLD HARMLESS THE ALBERTA TOMORROW PARTIES HARMLESS FROM AND AGAINST ANY CLAIMS, LIABILITIES, DAMAGES, LOSSES, AND EXPENSES, INCLUDING WITHOUT LIMITATION, LEGAL FEES, COSTS AND DISBURSEMENTS ON A SOLICITOR AND CLIENT BASIS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH ANY OF THE FOLLOWING (INCLUDING AS A RESULT OF YOUR DIRECT ACTIVITIES ON THE SERVICES OR THOSE CONDUCTED ON YOUR BEHALF): (I) YOUR CONTENT OR YOUR ACCESS TO OR USE OF THE SERVICES OR THE MATERIALS; (II) YOUR BREACH OR ALLEGED BREACH OF THIS AGREEMENT; (III) YOUR VIOLATION OF ANY THIRD-PARTY RIGHT, INCLUDING WITHOUT LIMITATION, ANY INTELLECTUAL PROPERTY RIGHT, PUBLICITY, CONFIDENTIALITY, PROPERTY OR PRIVACY RIGHT; (IV) YOUR VIOLATION OF ANY LAWS, RULES, REGULATIONS, CODES, STATUTES, ORDINANCES OR ORDERS OF ANY GOVERNMENTAL AND QUASI-GOVERNMENTAL AUTHORITIES, INCLUDING, WITHOUT LIMITATION, ALL REGULATORY, ADMINISTRATIVE AND LEGISLATIVE AUTHORITIES; OR (V) ANY MISREPRESENTATION MADE BY YOU. YOU WILL COOPERATE AS FULLY REQUIRED BY ALBERTA TOMORROW IN THE DEFENSE OF ANY SUCH CLAIM. ALBERTA TOMORROW RESERVES THE RIGHT TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER SUBJECT TO INDEMNIFICATION BY YOU, AND YOU WILL NOT IN ANY EVENT SETTLE ANY CLAIM WITHOUT THE PRIOR WRITTEN CONSENT OF ALBERTA TOMORROW. </strong>
</p>
<p>
<strong>9. Your User Content </strong>
</p>
<p>
(a) Alberta Tomorrow accepts no obligation to store, retain, make available or back-up User Content or any portion thereof, and Alberta Tomorrow will have no liability whatsoever to you for any loss, deletion, alteration, modification or change to User Content for any reason whatsoever. Storage, retention, and back-up of User Content is exclusively your obligation. You warrant and represent that in all cases you retain adequate copies, including back-up copies, of your User Content that that you can replace or recover all your User Content from any loss of, or damage to your User Content from the Services. 
</p>
<p>
<strong>10. Additional Services, Licenses or Statements of Work </strong>
</p>
<p>
(a) Alberta Tomorrow may, in its exclusive discretion, agree to provide further or additional products, services or licenses (“<strong>Additional Offerings</strong>”). Any such Additional Offerings will only be made available, if at all, in a separate written statement of work or schedule, appendix, exhibit, attachment or similar document (collectively, each a “<strong>Statement of Work</strong>”) with Alberta Tomorrow, provided that, in all cases, any such agreement or Statement of Work will always be subject to this Agreement. In the event of any conflict between this Agreement and any such other agreement or Statement of Work, the terms and conditions of this Agreement will prevail and take priority to the full extent of any such conflict. 
</p>
<p>
11. <strong>General Provisions </strong>
</p>
<p>
(a) You may not transfer or assign this Agreement. Alberta Tomorrow may assign or transfer this 
</p>
<p>

Agreement, including its rights, benefits, and obligations hereunder, without your consent. 
</p>
<p>
(b) This Agreement will enure to the benefit of and will be binding on and enforceable by you and 
</p>
<p>

Alberta Tomorrow and our respective successors and permitted assigns. 
</p>
<p>
(c) This Agreement does not create or imply any agency, partnership, joint venture, or other joint relationship between you and Alberta Tomorrow, and does not authorize you to bind or obligate Alberta Tomorrow. 
</p>
<p>
(d) Any delay or failure of Alberta Tomorrow to perform its obligations under this Agreement or under any Statement of Work shall be excused if the delay or failure is caused by an event or occurrence 
</p>
<p>

beyond the reasonable control of Alberta Tomorrow and without its fault or negligence, such as, by way of example and not by way of limitation, acts of God, action by any governmental authority (whether valid or invalid), fires, flood, wind storms, explosions, epidemics, pandemics, riots, natural disasters, wars, terrorist acts, sabotage, labour problems (including lock-outs, strikes and slow downs), or court order or injunction. 
</p>
<p>
(e) If any part of this Agreement is void, prohibited or unenforceable, the rest of this Agreement will continue in force and effect and will be construed as if such part had never been part of this Agreement. 
</p>
<p>
(f) The failure of Alberta Tomorrow to exercise any right under this Agreement, or to insist upon strict or full performance of the obligations under this Agreement will not constitute a waiver of Alberta Tomorrow’s rights under this Agreement, or a relinquishment of any provision of this Agreement. 
</p>
<p>
(g) Any provision of this Agreement that, expressly or by its nature, extends beyond the termination of this Agreement will survive any termination of this Agreement. Without limitation, sections 1, 7, 8 and 9 shall survive any termination or expiry of this Agreement, howsoever caused, and shall continue in full force and effect. 
</p>
<p>
(h) This Agreement, including any Statement of Work or other referenced schedule, attachment, addendum, exhibit, purchase order or similar documentation, forms the entire agreement between you and Alberta Tomorrow, and supersedes all prior written and oral communications and agreements. 
</p>
<p>
(i) If requested to do so, you will promptly and duly execute and deliver to Alberta Tomorrow such further documents and assurances and take such further action as Alberta Tomorrow may from time to time reasonably request in order to more effectively carry out the intent and purpose of this Agreement and to establish and protect the rights and remedies created or intended to be created hereby. 
</p>`
};

export default RegionPolicies;
