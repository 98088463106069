// observations setup
import abResSchools from "./ab_residential_schools";
import brbcVideos from "./brbc_360_videos";
import stoneyVoicesVideos from "./stoney_voices_videos";
import blackfootVoicesVideos from "./blackfoot_voices_videos";
import guardiansOfTheIceObservations from "./guardians_of_the_ice";

// filter stoney voices videos to only those that have coordinates in them
const stoneyVoicesVideosFiltered = stoneyVoicesVideos.filter((video) => 'coordinates' in video && video.coordinates.length === 2);

const RegionObservations = {
  // default observations setup
  // settings used by region-equations.js that can be treated globally for all sub-regions
  // individual regions can override these settings in geojson root properties
  observation_types: {
    water: {
      color: "#037ffc",
      icon: "fa-tint",
      image: "obswater.png",
      title: "Water Quality",
    },
    general: {
      color: "#3b3b3b",
      icon: "fa-binoculars",
      image: "obsgeneral.png",
      title: "Land Use",
    },
    system: {
      color: "#00b802",
      icon: "fa-info-circle",
      image: "obssystem.png",
      title: "Points of Interest",
    },
    resschool: {
      color: "#b6182e",
      icon: "fa-school",
      image: "obsresschool.png",
      title: "Residential Schools",
      isIndigenous: true,
    },
    brbc: {
      color: "#2c33a0",
      icon: "fa-water",
      image: "obsbrbc.png",
      title: "Bow River Watershed 360 Video Series",
    },
    iceguardian: {
      color: "#7dadef",
      icon: "fa-snowflake",
      image: "obsiceguardian.png",
      title: "Guardians of the Ice Video Series",
    },
    stoneyvoices: {
      color: "#ff9500",
      icon: "fa-feather",
      image: "obsstoneyvoices.png",
      title: "Stoney Voices Videos",
      isIndigenous: true,
    },
    blackfootvoices: {
      color: "#ff9500",
      icon: "fa-feather",
      image: "obsstoneyvoices.png",
      title: "Blackfoot Voices Videos",
      isIndigenous: true,
    },
  },
  // system observations
  system_observations: [
    {
      id: 25,
      title: "Investigating a Fish Kill",
      description:
        "Michael Sullivan, provincial fish science specialist with Alberta Fish and Wildlife talks at Lake Isle where thousands of sucker fish are poking their heads out of the water literally gasping for air because of oxygen deficiency in the water in this video from March 2014.",
      coordinates: [-114.7121597, 53.63705],
      media: {
        type: "video",
        status: "Complete",
        url: "https://www.youtube.com/watch?v=PD8FnEmi-l0",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/michael_sullivan_isle_lake_fish_kill.jpg",
        shareUrl: "https://www.youtube.com/watch?v=PD8FnEmi-l0",
      },
      observedTime: 1394823600000,
      hashtags:
        "waterquality,abwater,landuse,abag,eutrophication,fishkill,abenvironment",
    },
    {
      id: 35,
      title: "Native Trout in Alberta",
      description:
        "Watch this recording of a LiveStream presented by CPAWS SAB, Trout Unlimited and Cows and Fish to learn about Native Trout in Alberta.",
      coordinates: [-114.51295548244191, 50.28114220769186],
      media: {
        type: "video",
        status: "Complete",
        url:
          "https://d2qcvmovr4fv.cloudfront.net/out/v1/d97ba23aca8543fb8635bb205dfd9882/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/7e1583c8-8c39-47f1-89ad-12c7433c8798/thumbnails/obs_7db174da-e122-4667-82c8-824f6787c863_tumb.0000514.jpg",
        shareUrl: "",
      },
      observedTime: 1595618236362,
      hashtags:
        "waterquality,abwater,OHV,Recreation,Hiking,Agriculture,CPAWS,Trout",
    },
    {
      id: 36,
      title: "Athabasca Sand Dunes Ecological Reserve",
      description: "Open, moving sand dunes surrounded by lakes.",
      coordinates: [-110.89052249220633, 58.17244060712531],
      media: {
        type: "video",
        status: "Complete",
        url: "https://www.youtube.com/watch?v=KrkcyfUn274",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/LGO_AthabascaSandDunes.jpg",
        shareUrl: "https://www.youtube.com/watch?v=KrkcyfUn274",
      },
      observedTime: 1607104608043,
      hashtags: "athabasca,sand,dunes",
    },
    {
      id: 37,
      title: "Writing on Stone Provincial Park",
      description:
        "Learn about the landforms and the significance of the area to the Blackfoot people.",
      coordinates: [-111.63423545056287, 49.06794067595865],
      media: {
        type: "video",
        status: "Complete",
        url: "https://www.youtube.com/watch?v=-MJXlSxkka8",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/LGO_WritingOnStone.jpg",
        shareUrl: "https://www.youtube.com/watch?v=-MJXlSxkka8",
      },
      observedTime: 1607104608043,
      hashtags: "writingonstone,petroglyphs,milkriver",
    },
    {
      id: 38,
      title: "Hanging Culvert",
      description:
        "Listen to this fisheries biologist explain what a hanging culvert is in the upper reaches of the Red Deer River Watershed.",
      coordinates: [-115.34838293901707, 51.6563101205077],
      media: {
        type: "video",
        status: "Complete",
        url: "https://www.youtube.com/watch?v=r-_LsSi7FJ0",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/LGO_HangingCulvert.jpg",
        shareUrl: "https://www.youtube.com/watch?v=r-_LsSi7FJ0",
      },
      observedTime: 1607104608043,
      hashtags: "roads,culverts,fish",
    },
    {
      id: 39,
      title: "Forest Attack - Mountain Pine Beetle",
      description:
        "Follow biologists as the look for trees attacked by Mountain Pine Beetles.",
      coordinates: [-119.163818359374, 54.47916440896674],
      media: {
        type: "video",
        status: "Complete",
        url: "https://www.youtube.com/watch?v=3ojS4z9Z68c",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/LGO_PineBeetle.jpg",
        shareUrl: "https://www.youtube.com/watch?v=3ojS4z9Z68c",
      },
      observedTime: 1607104608043,
      hashtags: "forests,pinebeetle",
    },
    {
      id: 40,
      title: "Grassland Restoration",
      description:
        "This ranch south of Manyberries is an Alberta Conservation Association site.",
      coordinates: [-110.69863851935013, 49.39300483924299],
      media: {
        type: "video",
        status: "Complete",
        url: "https://www.youtube.com/watch?v=u1qMoL3V61g",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/LGO_GrasslandRestoration.jpg",
        shareUrl: "https://www.youtube.com/watch?v=u1qMoL3V61g",
      },
      observedTime: 1607104608043,
      hashtags: "ranch,ranching,grassland,restoration",
    },
    {
      id: 41,
      title: "Beneath the Surface: Water Monitoring in the Ghost Watershed",
      description: `Peek into a bucket of bugs and see why these aquatic invertebrates are indicators of water quality. Learn where the Ghost Watershed is located in relation to Calgary and Banff and take a virtual visit to its scenic landscapes. Watch how the Ghost Watershed Alliance Society went from strategy to stewardship, from drafting a desktop water monitoring program to completing a successful field season employing the protocols established by the Canadian Aquatic Biomonitoring Network (CABiN) alongside an emerging technology for identifying aquatic invertebrates, Sequencing the Rivers for Environmental Assessment and Monitoring (STREAM). Learn about the important role Living Lakes Canada plays in helping local watershed groups become trained in these nation-wide protocols. Hear volunteers describe how this work has meaning for the local area and the downstream communities, as the Ghost Watershed is an important source water area for the City of Calgary and surrounding region.`,
      coordinates: [-114.8371996666042, 51.28249611577988],
      media: {
        type: "video",
        status: "Complete",
        url: "https://www.youtube.com/watch?v=KdEN85OgG4o",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/gwas-ghost-waiparous.jpg",
        shareUrl: "https://www.youtube.com/watch?v=KdEN85OgG4o",
      },
      observedTime: 1607104608043,
      hashtags: "water,watershed, monitoring",
    },
    {
      id: 42,
      title: "Finding Water: Healthy Land, Healthy Stream",
      description: `Do you know where your water actually comes from? The Livingstone Landowners Group thinks it’s important that you do. That’s why, just in time for World Water Day 2020, we have released a new documentary video on the sources of Canada’s prairie rivers. Finding Water: Healthy Land, Healthy Stream is both a celebration of some of the most beautiful streams anywhere, and a cautionary warning about their vulnerability to our use of the surrounding land. Finding Water was produced with the help of a grant from the Alberta Land Stewardship Cen-tre’s Watershed Stewardship Grant Program, and extensive voluntary and in-kind contributions from members of the Livingstone Landowners Group.`,
      coordinates: [-114.359536, 49.865747],
      media: {
        type: "video",
        status: "Complete",
        url: "https://www.youtube.com/watch?v=E2wYNZJ-tC4",
        thumbUrl:
          "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/livingstone_findingwater.jpg",
        shareUrl: "https://www.youtube.com/watch?v=E2wYNZJ-tC4",
      },
      observedTime: 1607104608043,
      hashtags: "water,watershed, monitoring",
    },
    // append residential schools
    ...abResSchools,
    ...brbcVideos,
    ...guardiansOfTheIceObservations,
    ...stoneyVoicesVideosFiltered,
    ...blackfootVoicesVideos
  ],
};

export default RegionObservations;
