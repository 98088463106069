// Stoney Voices videos

const stoneyVoicesVideos = [ 
  {
    id: 3000,
    title: 'What Will Land Look Like In The Future',
    description: ``,
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/c179f3d78bb74efc80802db5b15903c4/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/695381a7-7628-4818-990f-89d3211aa303/thumbnails/What%20Will%20Land%20Look%20Like%20in%20Future_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/UpJUo8M5Bck?si=PHyuoBHHtza4vsmy'
    },
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },
  {
    id: 3001,
    title: 'Consulting With The Stoney',
    description: ``,
    coordinates: [-115.151605, 51.069646],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/b745602b6e764e30bbc4c2757bf56abd/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/6efd0003-1620-480a-95d4-49bf1d429d89/thumbnails/Consulting%20With%20Stoney_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/CEmbcJ_2oPA?si=aqTK4AvzdPw2yPXx',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },
  {
    id: 3002,
    title: 'Cement Factory',
    description: ``,
    coordinates: [-115.176119, 51.062842],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/cb7cae889c1e4bc4a47b860d1fff5b2d/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/b647ff76-e29d-438c-85c3-5f9b64e5ce32/thumbnails/Cement%20Factory_tumb.0000001.jpg',
      shareUrl: 'https://youtu.be/KFbHx-jduws?si=IpEgy6vTFzxCDL-u',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },   
  {
    id: 3003,
    title: 'The Railway',
    description: ``,
    coordinates: [-115.151605, 51.069646],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/85d6fd325c624e8e9ba016f1766bac8e/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/55aaaa60-b4e8-4a17-8e57-a160624b63a7/thumbnails/Railway_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/NvR7dT83UKE?si=MWipJUoMutYdAGzY',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },   
  {
    id: 3004,
    title: 'Great Depression',
    description: ``,
    coordinates: [],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/ee71ff1d90cb489eaf10de03d0772267/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/f6e8f0b0-7db4-4bc4-b13e-81a31217b801/thumbnails/Great%20Depression_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/57vkAX541xY?si=pygUdIJEajIdovUC',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },  
  {
    id: 3005,
    title: 'Water - Now and The Future',
    description: ``,
    coordinates: [],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/eee47828da824aeb93a087271924c641/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/91762971-98d6-4e2a-91d0-8cce43dfb69f/thumbnails/Water%20-%20now%20and%20future_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/eUKsxcJtYNY?si=ocljo1c9IxdRESlq',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  }, 
  {
    id: 3006,
    title: 'Using Land For Protection',
    description: ``,
    coordinates: [-114.941348, 51.054147],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/02f88ebbe40148fab9dfd8411262d278/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/ec2fe1f5-06e1-47b5-b9c4-7ca8e985e17d/thumbnails/Using%20Land%20for%20Protection_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/ZqpwZ1irYFY?si=sdiru7LP4nhu6jox',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },
  {
    id: 3007,
    title: 'Stoney Voice At The Table',
    description: ``,
    coordinates: [],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/039a9fe3282b4a1db7ecaa1fb69d87c4/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/1bd74c11-faa1-41f4-8d12-cab9e5b0767c/thumbnails/Stoney%20Voice%20at%20the%20Table_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/kuFB4CrTufw?si=rVjuYL9xCxY5HwVm',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },   
  {
    id: 3008,
    title: 'Glaciers',
    description: ``,
    coordinates: [-116.400787, 51.640221],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/aa887ef962c443d389e6329a81631e6f/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/88e5ec8f-5109-493e-a3ba-94c10f630a08/thumbnails/Glaciers_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/IxZgkmJnYrw?si=2haLY-beyIpsZQ5W',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },   
  {
    id: 3009,
    title: 'Creator Story',
    description: ``,
    coordinates: [-115.118069, 51.120761],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/9413cdebc33046d997eb4929e9824866/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/7d5e3424-03c7-40c0-b1f6-e26ef8dd99ec/thumbnails/Creator Story_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/HKy3Sb2u-Mk?si=FUDzF1rx4dzmQzKz',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },  
  {
    id: 3010,
    title: 'Fishing On The Bow',
    description: ``,
    coordinates: [],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/64b6a087dc63427fa227b08165994242/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/e9bd9064-c34e-4769-a3c3-8d702623d91b/thumbnails/Fishing%20on%20the%20Bow_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/vN_X59jznLE?si=qs742rPJ9WvbOe1y',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },
  {
    id: 3011,
    title: 'Reclimatizing',
    description: ``,
    coordinates: [-114.941348, 51.054147],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/f9f942d3df3e4df0b0f10ed526aaa212/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/b88daa80-37fc-4079-869a-7f9f5a0fdb96/thumbnails/Reclimatizing_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/PYuxiSw06jM?si=ZT9Il2CxUSq80vc7',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },
  {
    id: 3012,
    title: 'Tornado',
    description: ``,
    coordinates: [],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/33ed848c7fc340c78f27189eb57bdc71/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/5d37a660-4f5b-40b7-badc-9bbf98077e92/thumbnails/Tornado_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/Yhx8ylh81EM?si=gPY1Fj7h65WvWSk1',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },   
  {
    id: 3013,
    title: 'Before Treaty',
    description: ``,
    coordinates: [-115.118069, 51.120761],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/4de5a67c0f5e4ab4af0ed4c2a2a1ef94/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/a9b99252-a854-4a77-aaa2-f7fe43a1a6c1/thumbnails/Before%20Treaty_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/tuNheHwbU7c?si=uTlYq-LqELvWmpuf',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },   
  {
    id: 3014,
    title: 'Life Of A Stoney Boy',
    description: ``,
    coordinates: [-115.118069, 51.120761],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/7441081f7b8f42a2bea5fc2e2f3a2e33/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/cc961a95-b604-4cba-9c34-993437f3f823/thumbnails/Life%20of%20a%20Stoney%20Boy_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/2CaTbIIOHNE?si=yevh87KVNQY-uCua',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },  
  {
    id: 3015,
    title: 'Bringing Back the Bison',
    description: ``,
    coordinates: [-114.941348, 51.054147],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/596772a9b3c7416fa7c5c9fc407c1680/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/09bee351-3a94-49b1-9a86-a4978e7c53cd/thumbnails/Bring%20Back%20Bison_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/Y5ib6lh4HLE?si=p3HpiNYvKy9rFqPT',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },
  {
    id: 3016,
    title: 'Using The Land',
    description: ``,
    coordinates: [],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/31daf805c8d44748a6a14f7d38cc0084/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/a7edc400-3c29-48bb-bcc6-296883adc89c/thumbnails/Using%20the%20Land_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/yz52Ysp_meQ?si=2uYyt4W4Qjq31z2N',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },
  {
    id: 3017,
    title: 'Moose Story',
    description: ``,
    coordinates: [],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/0b5e0c2176c843228629c01f7775b085/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/18212f4c-ba95-443b-9a9c-8cea0186f22a/thumbnails/Moose%20Story_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/rb0WIzUHnYw?si=r438JuVzkL4PxsaR',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },
  {
    id: 3018,
    title: 'Land Use and Wildlife',
    description: ``,
    coordinates: [],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/5eefc5c24b0d4099bd93ae333cf1ec23/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/4682cb45-4133-437f-b341-98c1a7254a06/thumbnails/Land%20Use%20wildlife_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/d_wKqUZMaTk?si=3dnMMNcSOKg8pW5X',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },
  {
    id: 3019,
    title: 'Protected Areas',
    description: ``,
    coordinates: [],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/a8a4fbaf9189470e922b26d22087ba9c/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/eb6c9fb2-43cb-4c4c-bc42-d08c1228837b/thumbnails/Protected%20Areas_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/IuvVMb1y0oU?si=mka-5Q1s3IP_kDPU',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },
  {
    id: 3020,
    title: 'Reconcili-ACTION',
    description: ``,
    coordinates: [],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/0f562ac8997a4da3bafd009f8ced4ae0/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/a84b6134-084c-4223-b8eb-ace6e197e71e/thumbnails/ReconciliACTION_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/P65TspAOwrU?si=JEA8buNzlAZus7Jl',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },
  {
    id: 3021,
    title: 'Yamnuska Meaning',
    description: ``,
    coordinates: [-115.118069, 51.120761],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/d80414f9729d413e90e43feedeca4954/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/6735981d-73e2-4ca4-8239-31f2a45921d5/thumbnails/Yamnuska%20Meaning_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/GX0PKaCqJiI?si=lL2dqXTkoa-tjh3r',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },
  {
    id: 3022,
    title: 'Cougar Story',
    description: ``,
    coordinates: [-114.89722, 50.67361],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/d9fe4679c597486e9cc81f546482c16c/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/ea8539b2-f1e7-4b50-bd6b-b41af7d06c94/thumbnails/Cougar%20Story_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/9269H-WWRBg?si=DX65Ftlw44R5YvbW',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },
  {
    id: 3023,
    title: 'First Contact With Settlers',
    description: ``,
    coordinates: [],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/09712087a0d0458aaceab78d5d4a83b8/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/cdf75db1-7b00-473f-bf7c-927b33b3589a/thumbnails/First%20Contact%20w%20Settlers_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/7pI3iyUuhK0?si=Y-8Gc_UmAStwrK1Y',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },
  {
    id: 3024,
    title: 'Food and Camp',
    description: ``,
    coordinates: [-115.118069, 51.120761],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/ee087a318f124a518400d11ecf696504/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/1821b035-16d6-461d-ab1f-9341cd6124be/thumbnails/Food%20and%20Camp_tumb.0000000.jpg',
      shareUrl: 'https://youtu.be/s1NAu1rTZzQ?si=8RolJGkfnYz5Bm06',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },
  {
    id: 3025,
    title: 'Access To Land - Sibbald Flats',
    description: ``,
    coordinates: [],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/a6f5f938da924f6880a27fdf9bc0da59/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/8fd91908-cba7-4774-9faa-113d7c686c01/thumbnails/Access%20to%20Land%20-%20Sibbald%20Flats_tumb.0000000.jpg',
      shareUrl: '',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },
  {
    id: 3026,
    title: 'Introductions',
    description: ``,
    coordinates: [],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/8a8abe4d2ab84cd6ae428cfd5c381c62/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/0b286b2d-05fe-4d19-b923-da03d9afd668/thumbnails/Introductions_tumb.0000001.jpg',
      shareUrl: 'https://youtu.be/E1vzAxvrgR4?si=vCtjAcHbtMPzJsNf',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  },
  {
    id: 3027,
    title: 'Concept of Time',
    description: ``,
    coordinates: [-115.118069, 51.120761],
    subtype: 'stoneyvoices',
    media: {
      type: 'video',
      status: 'Complete',
      url:
        'https://d2qcvmovr4fv.cloudfront.net/out/v1/8572595c46bb453087dd76a47c633998/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8',
      thumbUrl:
        'https://d2qcvmovr4fv.cloudfront.net/16e0288c-1b7e-4323-8470-91ed4f317b65/thumbnails/Concept%20of%20Time_tumb.0000000.jpg',
      shareUrl: '',
      is360: false
    },
    observedTime: 1705966403883,
    hashtags: 'indigenousstories, stoneynakoda, indigenouslanduse, morley, landuse, abwater, alberta, stoneyvoices, traditionallanduse'
  }
];

export default stoneyVoicesVideos;
