<template>
  <div class="md-layout">
    <div
      v-if="!apiResponseReceived"
      class="md-layout-item md-size-100"
    >
      <h5 class="info-text">
        Importing new users...
      </h5>
      <p
        class="text-center md-layout md-layout-item md-alignment-center-center"
      >
        <md-progress-spinner
          class="md-secondary"
          :md-stroke="10"
          :md-diameter="150"
          md-mode="indeterminate"
        />
      </p>
    </div>
    <div
      v-else
      class="md-layout-item md-size-100"
    >
      <h5 class="info-text">
        {{ rowsToImport }} new users were imported!
      </h5>
      <p>
        You may copy this list for your records. If any of the imported users
        need assistance logging in, as their teacher you will be able to reset
        their password.
      </p>
      <p>
        <md-button
          class="md-success"
          @click="clipWrite"
        >
          <md-icon>content_copy</md-icon>
          Copy list to clipboard
        </md-button>
      </p>
    </div>
    <div class="md-layout-item">
      <grid
        v-show="apiResponseReceived"
        ref="grid"
        :data="gridProps.data"
        :columns="gridProps.columns"
        :options="gridProps"
        @click="preventEditing"
        @beforeChange="cancelEditing"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { types } from "@/store/types";
import "tui-grid/dist/tui-grid.css";
import { Grid } from "@toast-ui/vue-grid";
import { write as clipboardyWrite } from "clipboardy";
// uses TUI Grid
// https://github.com/nhn/tui.grid/tree/master/packages/toast-ui.vue-grid
// http://nhn.github.io/tui.grid/latest/Grid#restore
export default {
  name: "BulkWizardConfirm",

  components: {
    grid: Grid,
  },

  props: {
    school: {
      type: String,
      required: true,
    },
    grade: {
      type: String,
      required: true,
    },
    gridData: {
      type: Array,
      required: true,
    },
    toggleUpdate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      gridProps: {},
      apiResponseReceived: false,
      importData: [],
    };
  },

  computed: {
    ...mapGetters([types.getters.USER_DATA]),
    rowsToImport() {
      return this.gridData.length;
    },
  },

  watch: {
    toggleUpdate() {
      this.$refs.grid.invoke("resetData", this.gridData);
      setTimeout(() => {
        this.$refs.grid.invoke("refreshLayout");
      }, 200);
      this.apiResponseReceived = true;
    },
  },

  created() {
    this.gridProps = {
      data: [],
      columns: [
        { name: "firstname", header: "First Name*", editor: "text" },
        { name: "lastname", header: "Last Name*", editor: "text" },
        { name: "UserName", header: "Username*", editor: "text" },
        { name: "email", header: "Email", editor: "text" },
        { name: "password", header: "Password", editor: "text" },
      ],
      usageStatistics: false,
      bodyHeight: 400,
      heightResizable: true,
    };
  },

  beforeMount() {},

  mounted() {
    this.focus();
    this.$nextTick(() => this.focus());
  },

  methods: {
    validate() {
      // nothing to validate since no changes are allowed on this screen
      // emit complete and close import wizard
      this.$emit("on-import-complete", true);
      return true;
    },
    preventEditing() {
      this.$refs.grid.invoke("blur");
    },
    cancelEditing(ev) {
      // prevent any editing
      ev.stop();
    },
    clipWrite() {
      // copy table to clipboard
      // eslint-disable-next-line
      const userData = [...this.gridData].map(({ firstname, lastname, UserName, email, password, ...rest }) => {
          // only keep these fields...
          return {
            firstname,
            lastname,
            UserName,
            email,
            password,
          };
        }
      );
      let clipData = userData
        .map((rows) => Object.values(rows).join("\t"))
        .join("\n");
      clipData = "First Name\tLast Name\tUsername\tEmail\tPassword\n".concat(clipData);
      clipboardyWrite(clipData);

      // also highlight the table selection for extra giggles
      const grid = this.$refs.grid;
      const rangeEnd = [grid.invoke("getRowCount") - 1, 4];
      grid.invoke("setSelectionRange", { start: [0, 0], end: rangeEnd });

      this.$notify({
        message: "User data was copied to your clipboard.",
        icon: "content_copy",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "info",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .grid-reset-btn {
  margin-left: 10px !important;
}
/deep/ .grid-change {
  background-color: lighten($brand-warning, 35%) !important;
}
/deep/ .grid-invalid {
  background-color: lighten($brand-danger, 35%) !important;
}
.api-messages-title {
  font-weight: bold;
}
.text-white {
  color: $white-color;
}
</style>