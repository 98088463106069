/* jshint ignore: start */
// mixin for tour handling
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";

export const Tours = {
  data() {
    return {
      newTourOrder: [
        "DashboardSidebar",
        "UserScenarios",
        "UserScenariosFilter",
        "TopNavbarScenario",
        "Observations",
        "ObservationsFilter",
        "CoverMap",
        "CoverMapSidebarToggle",
        "ExploreTimeSlider",
        "RightSidebarCoverIndicators",
        "RightSidebarCoverIndicatorGoal",
        "RightSidebarLandscapeSummary",
        "RightSidebarLandscapeSummaryEdit",
        "RightSidebarClimatePanel"
      ]
    };
  },

  computed: {
    ...mapGetters([
      types.getters.TOUR_ACTIVE,
      types.getters.USER_DATA,
      types.getters.AVAILABLE_TOURS,
      types.getters.AVAILABLE_TOURS_TRIGGER,
      types.getters.PRIORITY_AVAILABLE_TOURS
    ]),

    firstTimeTours() {
      // look for any tours that have never been launched and launch those
      const vm = this;
      const firstTimeTours = this.AVAILABLE_TOURS.filter(
        tour =>
          !this.getUserHistoryTours().includes(tour) &&
          tour.split("_v")[0] !== "HelpButton"
      ).sort((a, b) => {
        return (
          vm.newTourOrder.indexOf(a.split("_v")[0]) -
          vm.newTourOrder.indexOf(b.split("_v")[0])
        );
      });
      return firstTimeTours;
    },
    orderedAvailableTours() {
      // get ordered list of available tours
      const vm = this;
      const orderedAvailable = this.AVAILABLE_TOURS.filter(
        tour => tour.split("_v")[0] !== "HelpButton"
      ).sort((a, b) => {
        return (
          vm.newTourOrder.indexOf(a.split("_v")[0]) -
          vm.newTourOrder.indexOf(b.split("_v")[0])
        );
      });
      return orderedAvailable;
    },
    priorityTours() {
      // get priority-ordered list of available tours
      // takes into account default tour order combined with first time tours
      // first time tours are added to the front of the list
      const orderedTours = [
        ...this.firstTimeTours,
        ...this.orderedAvailableTours
      ];
      const priorityTours = new Set(orderedTours);
      return [...priorityTours];
    },
    reversedAvailableTours() {
      // get ordered list of available tours
      const vm = this;
      const orderedAvailable = this.AVAILABLE_TOURS.filter(
        tour => tour.split("_v")[0] !== "HelpButton"
      ).sort((a, b) => {
        return (
          vm.newTourOrder.indexOf(b.split("_v")[0]) -
          vm.newTourOrder.indexOf(a.split("_v")[0])
        );
      });
      return orderedAvailable;
    },
    reversePriorityTours() {
      // get a reverse ordered list...
      // when launching from the help button, launch with first time tours first
      // followed by available tours in reverse order
      const orderedTours = [
        ...this.firstTimeTours,
        ...this.reversedAvailableTours
      ];
      const priorityTours = new Set(orderedTours);
      return [...priorityTours];
    }
  },

  methods: {
    ...mapActions([
      types.actions.SET_PRIORITY_AVAILABLE_TOURS,
      types.actions.SET_TOUR_ACTIVE
    ]),

    /**
     * Set the priority list of available tours
     *
     * @param {array} tours - a list of tours
     */
    setPriorityTours(tours) {
      this.SET_PRIORITY_AVAILABLE_TOURS(tours);
    },
    getUserHistoryTours() {
      // return all tours from user's history
      return "history" in this.USER_DATA
        ? this.USER_DATA.history
            .filter(item => item.startsWith("t_"))
            .map(tour => tour.substring(2))
        : [];
    }
  }
};
