<template>
  <md-card>
    <md-card-header class="md-card-header-text md-card-header-primary">
      <div class="card-text">
        <h4 class="title">
          Student observations
        </h4>
        <p
          v-if="observations.length === 0"
          class="category"
        >
          None of your students have recorded any observations yet.
        </p>
        <p
          v-else
          class="category"
        >
          The following observations have been recorded by your students.
          <br>
          <md-chip class="obs-chip">
            <i class="fas fa-map-marker-alt" />
            {{ observations.length }} {{ 'observation' | pluralize(observations.length) }}
          </md-chip>
        </p>
      </div>
    </md-card-header>
    <md-card-content v-if="observations.length === 0">
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <p>
            Check out some of the <router-link to="/dashboard/resources">
              Teacher Resources
            </router-link> for observation recording acitivities you can do with your students.
          </p>
        </div>
      </div>
    </md-card-content>
    <md-card-content v-else>
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <v-client-table
            :columns="columns"
            :data="observations"
            :options="options"
          >
            <span
              slot="observedTime"
              slot-scope="props"
            >
              {{ observedDate(props.row.observedTime) }}
            </span>
            <div
              slot="type"
              slot-scope="props"
            >
              <i
                :class="observationIcon(props.row.type)"
                class="card-title"
              />
              <span class="card-subtitle">{{ observationType(props.row.type) }}</span>
            </div>
            <div
              slot="status"
              slot-scope="props"
            >
              <md-button class="md-simple md-just-icon status-icon">
                <i :class="obsStatusIconClass(props.row.status)" />
                <md-tooltip md-direction="bottom">
                  {{ obsStatusTooltip(props.row.status) }}
                </md-tooltip>
              </md-button>
              <md-button
                class="md-simple md-just-icon explore-icon"
                @click="explore(props.row.UserTransaction, 'observations', false, true)"
              >
                <md-icon>explore</md-icon>
                <md-tooltip md-direction="bottom">
                  View on map
                </md-tooltip>
              </md-button>
            </div>
            <div
              v-if="!isMdXSmall"
              slot="child_row"
              slot-scope="props"
            >
              {{ props.row.description }}
              <span v-if="hasImg(props.row)">
                <i class="fas fa-camera" />
                <md-tooltip md-direction="bottom">Image Uploaded</md-tooltip>
              </span>
              <span v-if="hasVideo(props.row)">
                <i class="fas fa-video" />
                <md-tooltip md-direction="bottom">Video Uploaded</md-tooltip>
              </span>
            </div>
            <div
              v-else
              slot="child_row"
              slot-scope="props"
            >
              {{ props.row.description }}
              <div v-if="hasImg(props.row) || hasVideo(props.row)">
                Media:
                <span v-if="hasImg(props.row)">
                  <i class="fas fa-camera" />
                  <md-tooltip md-direction="bottom">Image Uploaded</md-tooltip>
                </span>
                <span v-if="hasVideo(props.row)">
                  <i class="fas fa-video" />
                  <md-tooltip md-direction="bottom">Video Uploaded</md-tooltip>
                </span>
              </div>
              <div>
                Type:
                <i
                  :class="observationIcon(props.row.type)"
                  class="card-title"
                />
                <span class="card-subtitle">{{ observationType(props.row.type) }}</span>
              </div>
              <div>
                Status:
                <md-button class="md-simple md-just-icon status-icon">
                  <i :class="obsStatusIconClass(props.row.status)" />
                  <md-tooltip md-direction="bottom">
                    {{ obsStatusTooltip(props.row.status) }}
                  </md-tooltip>
                </md-button>
                <md-button
                  class="md-simple md-just-icon explore-icon"
                  @click="explore(props.row.UserTransaction, 'observations', false, true)"
                >
                  <md-icon>explore</md-icon>
                  <md-tooltip md-direction="bottom">
                    View on map
                  </md-tooltip>
                </md-button>
              </div>
            </div>
          </v-client-table>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import { ResponsiveLayoutHelpers } from "@/pages/Dashboard/Components/mixins/ResponsiveLayoutHelpers";
import { Observation } from "@/pages/Dashboard/Components/mixins/Observation";
import { ObservationSummary } from "@/pages/Dashboard/Components/mixins/ObservationSummary";
export default {
  name: "DashboardStudentObservations",

  mixins: [ResponsiveLayoutHelpers, Observation, ObservationSummary],

  props: {
    observations: {
      type: Array,
      required: true
    }
  },

  data() {
    return {}
  },

  computed: {
    columns() {
      // table columns, list of columns to show - show fewer columns on smaller screens
      const columns = [
        'UserName',
        'title',
        'observedTime'
      ];
      if (!this.isMdXSmall) {
        columns.push(
          'type',
          'status'
        )
      }
      return columns;
    },
    options() {
      // table options
      const options = {
        headings: {
          UserName: 'Username',
          title: 'Title',
          observedTime: 'Date',
          status: 'Status'
        },
        columnsClasses: {
          observedTime: 'align-center',
          type: 'align-center',
          status: 'align-center'
        },
        sortable: [
          'UserName',
          'title',
          'observedTime',
          'status'
        ],
        filterable: [
          'UserName',
          'title'
        ],
        orderBy: {
          column: 'observedTime'
        },
        multiSorting: {
          UserName: [
            {
              column: 'observedTime',
              matchDir: false
            }
          ]
        },
        showChildRowToggler: true,
        uniqueKey: "UserTransaction"
      }
      if (!this.isMdXSmall) {
        // phone screens have fewer headings, bigger screens have all
        options.headings.type = 'Type';
        options.sortable.push(
          'type'
        );
      }
      return options;
    }
  },

  beforeMount() {
    // initialize observations
    this.initObservations();
  },

  methods: {
    initObservations() {
      // organize a few things about observations
      // make sure all observations have a status -- set to '_unseen' if no status present
      this.observations.forEach(obs => {
        if (!('status' in obs)) {
          obs.status = '_unseen';
        }
      });
    },
    obsStatusIconClass(status) {
      if (status === '_unseen') {
        return `fas fa-star`;
      } else if (status === 'viewed') {
        return `fas fa-eye`;
      }
      return `fas fa-exclamation-circle`;
    },
    obsStatusTooltip(status) {
      if (status === '_unseen') {
        return `New Observation`;
      } else if (status === 'viewed') {
        return `Viewed`;
      }
      return `Flagged as inappropriate`;
    },
    hasImg(properties) {
      if ('media' in properties && properties.media.type === 'image') {
        return true;
      }
      return false;
    },
    hasVideo(properties) {
      if ('media' in properties && properties.media.type === 'video') {
        return true;
      }
      return false;
    },
  }
}
</script>

<style lang="scss" scoped>
.md-card-no-elevation {
  background: transparent;
  box-shadow: none;
}
.obs-chip {
  background-color: darken($brand-primary, 10%) !important;
}
.obs-chip i {
  height: 16px !important;
  line-height: 16px !important;
  width: 16px !important;
}
/deep/ .VueTables__search__input {
  margin-left: 10px;
}
/deep/ .VueTables__limit-field label {
  padding-right: 10px;
}
/deep/ .VueTables__table {
  width: 100%;
  margin-top: 12px;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
}
/deep/ .VueTables__heading {
  font-size: 16px;
  font-weight: 300;
}
/deep/ .VueTables__sort-icon {
  padding-left: 8px;
}
/deep/ .fa-sort {
  color: $gray-light;
}
/deep/ .align-right {
  text-align: right;
}
/deep/ .align-center {
  text-align: center;
}
/deep/ .VueTables__child-row-toggler {
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}

/deep/ .VueTables__child-row-toggler--closed::before {
  content: "+";
}

/deep/ .VueTables__child-row-toggler--open::before {
  content: "-";
}
.card-subtitle {
  color: $gray-light !important;
  font-size: 12px;
  padding-left: 4px;
}
.fa-tint {
  color: #037ffc;
}
/deep/ .explore-icon i {
  color: $brand-success !important;
}
/deep/ .status-icon .fa-star {
  color: $brand-warning !important;
}
/deep/ .status-icon .fa-eye {
  color: $brand-info !important;
}
/deep/ .status-icon .fa-exclamation-circle {
  color: $brand-danger !important;
}
/deep/ .VueTables__child-row i {
 color: $gray-light !important;
 margin-left: 5px !important;
 margin-right: 5px !important;
}
</style>
