<template>
  <md-list-item>
    <div>
      Points:
    </div>
    <div>
      <animated-number
        :value="userPointsComputed"
        :continue-from-previous="true"
        :reset="pointsResetToken"
        :duration="800"
        class="points-counter"
      />
    </div>
  </md-list-item>
</template>

<script>
import { mapGetters } from "vuex";
import { types } from "@/store/types";
import { AnimatedNumber } from '@/components'
export default {
  name: "PointsCounter",

  components: {
    AnimatedNumber
  },

  props: {},

  data() {
    return {
      pointsResetToken: false,
    }
  },

  computed: {
    ...mapGetters([
      types.getters.USER_DATA
    ]),
    userPointsComputed() {
      return this.USER_DATA.points;
    }
  },

  watch: {
    userPointsComputed() {
      this.pointsReset();
    },
  },

  methods: {
    pointsReset() {
      this.pointsResetToken = !this.pointsResetToken;
    },
  }
};
</script>

<style lang="scss" scoped>
.points-counter {
  margin-left: 10px;
}
</style>
