<template>
  <div class="content">
    <div class="md-layout">
      <div
        class="md-layout-item md-size-100"
      >
        <md-card>
          <div class="md-card-content">
            <div class="md-layout">
              <div class="md-layout-item md-xsmall-size-100 md-size-50">
                <h3>There are {{ tours.length }} virtual {{ 'tour' | pluralize(tours.length) }}</h3>
              </div>
            </div>
          </div>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div
        v-for="(tour, index) in tours"
        :key="`tour${index}`"
        class="md-layout-item md-medium-size-33 md-small-size-100 md-large-size-25"
      >
        <virtual-tour-card
          :tour="tour"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ResponsiveLayoutHelpers } from "@/pages/Dashboard/Components/mixins/ResponsiveLayoutHelpers";
import VirtualTourCard from "@/pages/Dashboard/Components/VirtualTourCard";

export default {
  name: "VirtualTours",
  components: { VirtualTourCard },
  mixins: [ResponsiveLayoutHelpers],
  data() {
    return {
      tours: this.$Region.virtualTours,
    };
  },
};
</script>
