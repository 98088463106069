<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-text md-card-header-warning">
            <div class="card-text">
              <h4 class="title">
                <md-icon>emoji_events</md-icon>
                Your Awards
              </h4>
            </div>
          </md-card-header>
          <md-card-content>
            <div v-if="numUserBadges > 0">
              <p>
                Congratulations! You have achieved {{ numUserBadges }} {{ 'badge' | pluralize(numUserBadges) }}!
              </p>
            </div>
            <div v-else>
              <p>
                You don't have any awards yet, but keep at it!
              </p>
              <p>
                Work towards
                <router-link to="mission">
                  your mission
                </router-link>
                or take on some of the quests below to earn points and awards!
              </p>
            </div>
          </md-card-content>
        </md-card>
      </div>
      <div
        v-for="(item) in getUserBadges()"
        :key="`badge-${item.code}`"
        class="md-layout-item md-size-20 md-medium-size-25 md-small-size-50 md-xsmall-size-100"
      >
        <user-badge-card
          :badge="item"
          :achieved="true"
        />
      </div>
      <!-- <div
        v-for="(item) in getAvailableBadges()"
        :key="item.code"
        class="md-layout-item md-size-20 md-medium-size-25 md-small-size-50"
      >
        <user-badge-card
          :badge="item"
        />
      </div> -->
      <div
        v-if="showQuests"
        class="md-layout-item md-size-100"
      >
        <md-card>
          <md-card-header class="md-card-header-text md-card-header-primary">
            <div class="card-text">
              <h4 class="title">
                <i class="fas fa-rocket" />
                {{ $Region.questsInfo.title }}
              </h4>
            </div>
          </md-card-header>
          <md-card-content>
            <p>{{ $Region.questsInfo.description }}</p>
          </md-card-content>
        </md-card>
      </div>
      <div
        v-for="(item, index) in allQuests"
        :key="item.code"
        class="md-layout-item md-size-25 md-medium-size-33 md-small-size-100"
      >
        <user-quest-card
          :quest="item"
          :index="index"
          :completed="questCompleted(item.code)"
          @on-launch-quest="launchQuest"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UserQuestCard from '@/pages/Dashboard/Components/UserQuestCard';
import UserBadgeCard from '@/pages/Dashboard/Components/UserBadgeCard';
import { Gamification } from "@/pages/Dashboard/Components/mixins/Gamification";

export default {
  name: "Achievements",

  components: {
    UserQuestCard,
    UserBadgeCard
  },
  mixins: [Gamification], // for launching quests
  props: {},

  data() {
    return {};
  },

  computed: {
    showQuests() {
      // are there any quests in the system config
      if ('questsInfo' in this.$Region && 'challenges' in this.$Region && this.$Region.challenges.length && this.$Region.challenges.filter(obj => obj.type === 'quest').length) {
        return true;
      }
      return false;
    },
    allQuests() {
      // get all quests in system
      if (this.showQuests) {
        return this.$Region.challenges.filter(obj => obj.type === 'quest');
      }
      return [];
    }
  },

  methods: {
    questCompleted(code) {
      // check if a particular quest has been completed by the user
      return ('history' in this.USER_DATA && this.USER_DATA.history.includes(`c_${code}`));
    },
    launchQuest(index) {
      // launch a quest
      this.getChallengeByCode(this.allQuests[index].code);
    },
  }
};
</script>

<style scoped>
</style>
