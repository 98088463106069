<template>
  <!--Loading bar (symbolic)-->
  <div>
    <div
      v-if="collectingData"
      class="md-layout"
    >
      <div class="md-layout-item md-size-100">
        <h4 class="card-title">
          Collecting some things...
        </h4>
        <md-progress-bar
          class="md-info"
          md-mode="buffer"
          :md-value="amount"
          :md-buffer="amount"
        />
      </div>
    </div>

    <!--Error collecting data-->
    <div
      v-else-if="dataError"
      class="md-layout"
    >
      <div class="md-layout-item md-size-100">
        <h4
          class="card-title"
          style="{color: red}"
        >
          {{ errorMsg }}
          <span v-if="retryCountdown > 0">Trying again in {{ retryCountdown }} seconds...</span>
        </h4>
      </div>
    </div>

    <!--Use the route if not a user dashboard-->
    <router-view v-else-if="useRouterView" />

    <!--Load respective user dashboard based on type-->
    <student-content
      v-else-if="usertype === 'student'"
      :scroll-location="scrollLocation"
    />
    <teacher-content v-else-if="usertype === 'teacher'" />
    <public-content v-else-if="usertype === 'general public'" />

    <!-- hint/challenge modal -->
    <zoom-center-transition>
      <md-dialog
        :md-active="SHOW_HINT_DIALOG"
        @md-clicked-outside="closeHintOrChallenge"
      >
        <hint
          v-if="ACTIVE_HINT"
          :hint="ACTIVE_HINT"
          @closeDialog="closeHintOrChallenge"
        />
      </md-dialog>
    </zoom-center-transition>
    <zoom-center-transition>
      <md-dialog
        :md-active="SHOW_CHALLENGE_DIALOG"
        @md-clicked-outside="closeHintOrChallenge"
      >
        <challenge
          v-if="ACTIVE_CHALLENGE"
          :challenge="ACTIVE_CHALLENGE"
          @closeDialog="closeHintOrChallenge"
        />
      </md-dialog>
    </zoom-center-transition>
    <zoom-center-transition>
      <md-dialog
        :md-active="SHOW_BADGE_DIALOG"
        @md-clicked-outside="closeBadge"
      >
        <badge
          v-if="ACTIVE_BADGE"
          :badge="ACTIVE_BADGE"
          @closeDialog="closeBadge"
        />
      </md-dialog>
    </zoom-center-transition>
  </div>
</template>

<script>
// import LandingContent from "./LandingContent.vue";
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import { Gamification } from "@/pages/Dashboard/Components/mixins/Gamification";
import { ZoomCenterTransition } from "vue2-transitions";
import Challenge from "./Components/Challenge";
import Hint from "./Components/Hint";
import Badge from "./Components/Badge";
import PublicContent from "./PublicContent.vue";
import StudentContent from "./StudentContent.vue";
import TeacherContent from "./TeacherContent.vue";
import swal from "sweetalert2";

export default {

  components: {
    // LandingContent,
    ZoomCenterTransition,
    Challenge,
    Hint,
    Badge,
    PublicContent,
    StudentContent,
    TeacherContent
  },
  mixins: [Gamification], // for badge/hint/challenge conditions & events

  props: {
    scrollLocation: {
      type: String,
      default: '',
    },
    newMessages: {
      type: Array,
      default: () => [],
    }
  },

  data() {
    return {
      userProfile: false,
      authenticated: false, // if user can not be authenticated and set this to true, the router will redirect
      amount: 0,
      timeOutAmount: 0,
      collectingData: true,
      dataError: false,
      numRetries: 0,
      retryCountdown: 0
    };
  },

  computed: {
    ...mapGetters([
      types.getters.USER_SCENARIOS,
      types.getters.USER_DATA
    ]),

    usertype() {
      return this.USER_DATA.usertype == null ? null : this.USER_DATA.usertype;
    },
    route() {
      return this.$route;
    },
    teacherAdded() {
      return this.USER_DATA.teacher;
    },
    scenarioCount() {
      return this.USER_SCENARIOS.length;
    },
    useRouterView() {
      return this.$route.name !== "Dashboard";
    },
    errorMsg() {
      if (this.numRetries < 2) {
        return `We are still busy processing a few things.`;
      } else if (this.numRetries < 5) {
        return `Unable to collect data.`;
      }
      return `Sorry, we tried but were unable to collect data, please refresh the page to try again.`
    }
  },

  watch: {
    usertype: function (newValue) {
      if (newValue) {
        this.collectingData = false;
      }
      // Send new messages to the navbar for search
      // this.$emit("emit-new-messages", this.getNewMessages());
    },
    route: function () {
      this.changeToProfile();
    },
    // teacherAdded (newValue, oldValue) {
    //   if (oldValue === false && this.teacherAdded) {
    //     this.showAchievement("You've added a teacher to your profile!")
    //   }
    // },
    // scenarioCount (newValue, oldValue) {
    //   if (newValue === 1) {
    //     this.showAchievement("You've created your first Scenario!")
    //   }
    // }
    dataError(newValue) {
      if (newValue === true) {
        if (this.numRetries < 5) {
          this.initiateRetryCounter();
          setTimeout(this.tryAgain, 2000);
        }
      }
    }
  },
  created() {
    this.authenticateAndLoad();
  },
  beforeMount() {
    // Initiate loading bar
    this.incrementBar();
    this.changeToProfile();
  },

  methods: {
    ...mapActions([
      types.actions.USER_LOAD,
      types.actions.OBSERVATIONS_LOAD_ALL_USER,
      types.actions.LOAD_USER_REPORTS
    ]),
    async authenticateAndLoad() {
      // authenticate the user and load user data
      // Collect the user name and authentication
      try {
        const auth_status = await this.$Auth.currentAuthenticatedUser();
        this.authenticated = Boolean(auth_status.attributes.given_name);
        this.USER_LOAD({ vm: this });
        this.OBSERVATIONS_LOAD_ALL_USER({ vm: this });
        this.LOAD_USER_REPORTS({ vm: this });
      } catch (err) {
        // if the user is not authenticated, send them away to the landing page
        // NOTE this ensures any router routes listed in dashboardContent using DashboardLayout must be authenticated.
        this.$router.push({ name: "Simulator" });
      }
    },
    incrementBar: function () {
      if (this.amount < 98) {
        this.amount++;
        setTimeout(this.incrementBar, Math.random() * 100);
        if (this.amount === 98) {
          this.initiateTimeOut();
        }
      }
    },
    changeToProfile() {
      this.userProfile = this.$route.name === "User Profile";
    },
    showAchievement(message) {
      swal.fire({
        title: `Achievement Reached!`,
        text: message,
        icon: "success",
        customClass: {
          confirmButton: "md-button md-success",
        },
        buttonsStyling: false,
        confirmButtonText: "Awesome!"
      });
    },
    // getNewMessages() {
    //   if (this.apiData.newMessages.length === 1) {
    //     this.$notify({
    //       message: "New message from " + this.apiData.newMessages[0].sender,
    //       icon: "add_alert",
    //       horizontalAlign: "right",
    //       verticalAlign: "top",
    //       type: "success"
    //     });
    //   } else if (this.apiData.newMessages.length > 1) {
    //     this.$notify({
    //       message:
    //         "You have " +
    //         this.apiData.newMessages.length.toString() +
    //         " new messages",
    //       icon: "add_alert",
    //       horizontalAlign: "right",
    //       verticalAlign: "top",
    //       type: "success"
    //     });
    //   }
    //   return this.apiData.newMessages;
    // },
    initiateTimeOut() {
      if (this.collectingData) {
        // if USER_DATA is loaded but profile is not yet setup, redirect to register wizard
        if (this.USER_DATA.usertype === null && this.USER_DATA.UserName !== null && this.numRetries > 0) {
          this.collectingData = false;
          this.$router.push({
            name: 'Register',
            params: {
              isEmptyProfile: true,
              email: this.USER_DATA.UserName
            }
          });
        } else if (this.USER_DATA.usertype !== null) {
          // data has been loaded
          this.collectingData = false;
        }
        this.timeOutAmount++;
        if (this.timeOutAmount === 50) {
          // Provide an error message and stop trying after 5s
          this.dataError = true;
          this.collectingData = false;
        } else {
          setTimeout(this.initiateTimeOut, 100);
        }
      }
    },
    initiateRetryCounter() {
      if (this.dataError) {
        if (this.retryCountdown === 0) {
          this.retryCountdown = 3;
          setTimeout(this.initiateRetryCounter, 1000);
        } else {
          this.retryCountdown--;
          setTimeout(this.initiateRetryCounter, 1000);
        }
      } else {
        this.retryCountdown = 0;
      }
    },
    async tryAgain() {
      // try loading again if there's a data error
      this.numRetries++;
      this.dataError = false;
      this.amount = 0;
      this.timeOutAmount = 0;
      this.collectingData = true;
      this.incrementBar();
      await this.authenticateAndLoad();
      if (this.USER_DATA.usertype !== null) {
        this.collectingData = false;
      }
    }
  }
};
</script>
