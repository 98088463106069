<template>
  <!-- a challenge with title, description, question, multiple choice answers -->
  <div class="md-layout">
    <div class="md-layout-item md-size-100 md-layout md-alignment-center-center">
      <span class="fa-stack fa-2x hint-title">
        <i class="fas fa-comment fa-stack-2x" />
        <i class="fas fa-info fa-stack-1x fa-inverse" />
      </span>
      <span class="md-layout-item md-size-100 hint-title">
        <h3 class="md-layout-item md-size-100">
          {{ hint.title }}
        </h3>
      </span>
      <p class="md-layout-item md-size-100">
        {{ descriptionComputed }}
      </p>
      <!-- allocation type hint -->
      <div v-if="hint.type === 'allocation'">
        <div class="md-layout-item md-size-100">
          <scenario-allocation-card
            :allocation="allocationComputed"
            :enable-edits="false"
          />
        </div>
        <div class="md-layout-item md-size-100 md-layout md-alignment-center-center">
          <md-button
            class="md-info"
            @click="flyToAllocation()"
          >
            <md-icon class="my_location">
              my_location
            </md-icon>
            Take Me There
          </md-button>
        </div>
      </div>
      <!-- climate transition type hint icons -->
      <div
        v-if="hint.type === 'transition'"
        class="md-layout-item md-size-100 md-layout md-alignment-center-center"
      >
        <md-button
          class="md-button-fill md-icon-button md-fab transition-button"
          :class="[{[fromLT.color]: true}]"
        >
          <md-icon
            :class="getIconClass(fromLT)"
            :md-src="getIconSrc(fromLT)"
          />
        </md-button>
        <md-tooltip md-direction="right">
          {{ fromLT.name }}
        </md-tooltip>

        <i class="fas fa-angle-double-right transition-button" />

        <md-button
          class="md-button-fill md-icon-button md-fab transition-button"
          :class="[{[toLT.color]: true}]"
        >
          <md-icon
            :class="getIconClass(toLT)"
            :md-src="getIconSrc(toLT)"
          />
        </md-button>
        <md-tooltip md-direction="right">
          {{ toLT.name }}
        </md-tooltip>
      </div>
      <!-- end climate transition type hint icons -->
      <!-- allocation & transition both have secondary content-->
      <div
        v-if="['allocation', 'transition'].includes(hint.type)"
        class="md-layout-item md-size-100"
      >
        <span class="md-layout-item md-size-100 hint-title">
          <h3 class="md-layout-item md-size-100">
            {{ hint.followTitle }}
          </h3>
        </span>
        <p class="md-layout-item md-size-100">
          {{ hint.followDescription }}
        </p>
      </div>
      <!-- hint media -->
      <div
        v-if="hasVideo"
        class="md-layout-item md-size-100"
      >
        <video-player
          :options="getVideoOptions(hint)"
          :title="hint.media.title"
        />
      </div>
      <div class="md-layout-item md-size-100 md-layout md-alignment-center-center close-section">
        <md-button
          class="md-raised md-success"
          @click="closeHint"
        >
          Ok
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { types } from "@/store/types";
import { Gamification } from "@/pages/Dashboard/Components/mixins/Gamification";
import { VideoOptions } from "@/pages/Dashboard/Components/mixins/VideoOptions";
import { LandTypes } from "@/pages/Dashboard/Components/mixins/LandTypes";
import VideoPlayer from "@/components/VideoPlayer";
import ScenarioAllocationCard from "./ScenarioAllocationCard";

export default {
  name: "Hint",

  components: {
    ScenarioAllocationCard,
    VideoPlayer
  },
  mixins: [LandTypes, VideoOptions, Gamification], // for video player options setup

  props: {
    hint: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
    }
  },

  computed: {
    hasVideo() {
      return "media" in this.hint && "type" in this.hint.media && this.hint.media.type === 'video';
    },
    descriptionComputed() {
      // return either the description text, or generate the text via function call if description is a function
      if (this.hint.description instanceof Function) {
        // function
        return this.hint.description({ vm: this, helpers: this.$Region.conditionHelpers });
      }
      // text
      return this.hint.description;
    },
    allocationIndex() {
      // the index of the allocation in the ACTIVE_SCENARIO.allocations
      return parseInt(this.ACTIVE_HINT_ALLOCATION[0]) - 1;
    },
    allocationComputed() {
      // return an allocation to pass to allocation card
      return this.ACTIVE_SCENARIO.allocations[this.allocationIndex];
    },
    fromLT() {
      // return the from LT of a climate transition
      return this.landUseTypes.find(lt => lt.name === this.ACTIVE_HINT_TRANSITION.fromLT);
    },
    toLT() {
      // return the to LT of a climate transition
      return this.landUseTypes.find(lt => lt.name === this.ACTIVE_HINT_TRANSITION.toLT);
    }
  },

  methods: {
    ...mapActions([
      types.actions.SET_MAP_FLYTO
    ]),

    flyToAllocation() {
      // zoom map to the highlighted allocation
      const coords = [this.allocationComputed.coords.lng, this.allocationComputed.coords.lat];
      this.SET_MAP_FLYTO(coords.toString());
      this.closeHint();
    },
    closeHint() {
      // close the dialog
      this.$emit('closeDialog');
    }

  }
};
</script>

<style lang="scss" scoped>
.hint-title i:nth-child(1) {
  color: $brand-info;
}
.hint-title i {
  margin-top: 30px;
  margin-bottom: 20px;
  display: block;
  text-align: center;
}
.hint-title h3 {
  text-align: center;
  font-weight: 600;
  margin-top: 40px;
}
.close-section {
  margin-top: 30px;
  margin-bottom: 30px;
}
.transition-button {
  display: inline;
  margin: 10px;
}
</style>
