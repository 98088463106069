<template>
  <md-toolbar
    md-elevation="0"
    class="md-accent"
    :class="{
      'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
    }"
  >
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <md-button
          class="md-just-icon md-simple md-white md-toolbar-toggle"
          @click="toggleSidebar"
        >
          <span class="icon-bar" />
          <span class="icon-bar" />
          <span class="icon-bar" />
        </md-button>

        <h3 class="md-title">
          <span :class="{ 'scen-title-desktop': !rightSidebarFullScreen }">
            {{ pageTitle() }}
          </span>
          <!-- SHARE INFO -->
          <md-chip
            v-if="
              ACTIVE_SCENARIO_SHARED &&
                !rightSidebarFullScreen &&
                $route.name === 'Explore'
            "
            class="share-chip"
          >
            Shared by: {{ ACTIVE_SCENARIO.UserName }}
          </md-chip>
          <!-- SCENARIO ACTIONS MENU -->
          <scenario-actions-btn v-if="showScenarioActionsButton" />
        </h3>
        <!-- SCENARIO CHOOSER (choose existing or add new) DIALOG -->
        <md-dialog
          :md-active.sync="showScenarioDialog"
          class="md-dialog chooser-dialog"
        >
          <md-dialog-title class="md-layout md-alignment-center-center">
            <md-button
              class="md-just-icon title-button"
              @click="showScenarioDialog = false"
            >
              <md-icon>arrow_back</md-icon>
            </md-button>
            <span> <md-icon class="title-icon">map</md-icon>Scenario </span>
          </md-dialog-title>
          <md-content class="md-layout md-alignment-top-center">
            <div
              v-if="scenarioCount > 0"
              class="md-layout-item md-size-55 md-alignment-top-center"
            >
              <p>
                You have created {{ scenarioCount }}
                {{ "scenario" | pluralize(scenarioCount) }}.
              </p>
              <md-button
                class="md-raised md-success"
                @click="goToScenariosList"
              >
                Load an Existing Scenario
              </md-button>
              <p class="text-center">
                or
              </p>
            </div>
            <div
              class="
                md-layout-item md-size-55 md-alignment-top-center
                bottom-padding
              "
            >
              <md-button
                class="md-raised md-success"
                @click="newScenario"
              >
                <md-icon>add</md-icon>Create a New Scenario
              </md-button>
            </div>
          </md-content>
        </md-dialog>
        <!-- END SCENARIO CHOOSER (choose existing or add new) DIALOG -->
        <!-- CREATE/EDIT SCENARIO DIALOG -->
        <edit-scenario-dialog
          v-if="showCreateScenarioDialog"
          :open="showCreateScenarioDialog"
          :edit-mode="SCENARIO_EDIT_MODE"
          :quick-escape="SCENARIO_EDIT_MODE === 'edit'"
        />
        <!-- END CREATE/EDIT SCENARIO DIALOG -->
        <!-- SCENARIO LAUNCH BUTTON -->
        <md-button
          v-if="showScenarioObsButton"
          id="TopNavbarScenarioBtn"
          class="md-raised md-success"
          :class="[{ condensedFAB: responsive }]"
          @click="launchFabAction"
        >
          <md-icon>add</md-icon>
          {{ scenarioObsFABText }}
        </md-button>
      </div>
      <div class="md-toolbar-section-end">
        <div>
          <md-list>
            <!-- HINT NOTIFIER -->
            <slide-y-down-transition>
              <md-list-item
                v-if="showHintButton"
                class="hint-button"
                @click="launchHint"
              >
                <span
                  class="fa-stack fa-1x hint-button"
                  :class="[{ bouncyButton: bouncyHintButton }]"
                >
                  <i class="fas fa-comment fa-stack-2x" />
                  <i class="fas fa-info fa-stack-1x fa-inverse" />
                </span>
              </md-list-item>
            </slide-y-down-transition>
            <!-- QUEST NOTIFIER -->
            <slide-y-down-transition>
              <md-list-item
                v-if="showQuestButton"
                class="quest-button"
                @click="launchQuest"
              >
                <span
                  class="quest-button"
                  :class="[{ bouncyButton: bouncyQuestButton }]"
                >
                  <i class="fas fa-rocket fa-2x" />
                </span>
              </md-list-item>
            </slide-y-down-transition>
            <!-- POINTS -->
            <!-- TODO only show points for non-mobile -->
            <div v-if="showPointsCounter">
              <points-counter />
            </div>

            <!-- LEADERBOARD BADGES -->
            <!-- TODO only show leaderboard badges for non-mobile -->
            <!-- TODO change if statement to proper variable and change icon -->
            <!-- <md-list-item
              v-if="authenticated"
              to="#"
            >
              <i class="material-icons">new_releases</i>
              <p class="hidden-lg hidden-md">
                Global Leaderboard
              </p>
            </md-list-item> -->
            <!-- TODO change if statement to proper variable and change icon -->
            <!-- <md-list-item
              v-if="authenticated"
              to="#"
            >
              <i class="material-icons">star</i>
              <p class="hidden-lg hidden-md">
                Class Leaderboard
              </p>
            </md-list-item> -->

            <!-- HELP -->
            <md-list-item
              v-if="authenticated"
              :class="{ 'mobile-menu-item': responsive }"
            >
              <md-button
                id="HelpTourBtn"
                class="md-icon-button help-button"
                @click="launchHelp"
              >
                <md-icon class="help-button">
                  help
                </md-icon>
              </md-button>
            </md-list-item>
            <!-- mobile explore screen actions -->
            <md-list-item
              v-if="showExploreMobileFullScreenContentToggle"
              :class="{ 'mobile-menu-item': responsive }"
            >
              <md-menu
                md-size="medium"
                :md-offset-x="15"
              >
                <!-- mobile explore full screen toggle BUTTON -->
                <md-button
                  md-menu-trigger
                  class="md-icon-button md-fab"
                >
                  <md-icon>more_vert</md-icon>
                </md-button>
                <!-- END mobile explore full screen toggle BUTTON -->
                <md-menu-content>
                  <md-menu-item @click="showMapContent()">
                    <md-icon>map</md-icon>
                    <span class="scenarioActionItem">Map</span>
                  </md-menu-item>
                  <!-- <md-menu-item @click="generateReport()">
                <md-icon>post_add</md-icon>
                <span class="scenarioActionItem">Generate Report</span>
              </md-menu-item> -->
                  <md-menu-item @click="showStatsPanelContent()">
                    <md-icon>insert_chart</md-icon>
                    <span class="scenarioActionItem">Stats Panel</span>
                  </md-menu-item>
                </md-menu-content>
                <!-- END SCENARIO ACTIONS MENU -->
              </md-menu>
            </md-list-item>
          </md-list>
        </div>
      </div>
    </div>
    <intro-tour
      v-if="
        showScenarioObsButton &&
          !showScenarioActionsButton &&
          USER_SCENARIOS.length > 0
      "
      name="TopNavbarScenario"
      :version="topNavbarScenarioTourVersion"
      :steps="topNavbarScenarioTourSteps"
    />
    <!-- HelpButton tour.  NOTE this tour always fires at the end of any other tours -->
    <intro-tour
      :name="helpTourName"
      :version="helpTourVersion"
      :steps="helpTourSteps"
      type="help"
    />
  </md-toolbar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import { SlideYDownTransition } from "vue2-transitions";
import EditScenarioDialog from "../Forms/EditScenarioDialog";
import ScenarioActionsBtn from "@/pages/Dashboard/Components/ScenarioActionsBtn";
import PointsCounter from "@/pages/Dashboard/Components/PointsCounter";
import { ResponsiveLayoutHelpers } from "@/pages/Dashboard/Components/mixins/ResponsiveLayoutHelpers";
import IntroTour from "@/pages/Dashboard/Components/IntroTour";
import { Tours } from "@/pages/Dashboard/Components/mixins/Tours";
import { TourSteps } from "@/pages/Dashboard/Components/mixins/TourSteps";

export default {
  components: {
    EditScenarioDialog,
    ScenarioActionsBtn,
    PointsCounter,
    SlideYDownTransition,
    IntroTour,
  },
  mixins: [ResponsiveLayoutHelpers, Tours, TourSteps], // for parsing and launching tours

  props: {
    newMessages: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      viewedMessages: [],
      authenticated: false,
      bouncyHintButton: false,
      bouncyQuestButton: false,
    };
  },

  computed: {
    ...mapGetters([
      types.getters.EXPLORE_MODE,
      types.getters.ACTIVE_SCENARIO,
      types.getters.ACTIVE_SCENARIO_SHARED,
      types.getters.ACTIVE_HINT,
      types.getters.HINT_BUTTON_ANIMATION,
      types.getters.ACTIVE_QUEST,
      types.getters.QUEST_BUTTON_ANIMATION,
      types.getters.SCENARIO_DIALOG_OPEN,
      types.getters.EDIT_SCENARIO_DIALOG_OPEN,
      types.getters.SCENARIO_EDIT_MODE,
      types.getters.USER_SCENARIOS,
      types.getters.SELECTED_OBSERVATION_TYPE,
    ]),

    scenarioCount() {
      return this.USER_SCENARIOS.length;
    },
    showScenarioObsButton() {
      // don't show on videos, leaderboard, achievements
      // don't show on small mobile devices with width < 360
      return (
        ![
          "Videos",
          "Achievements",
          "Leaderboard",
          "User Profile",
          "Teacher Resources",
          "Add Students",
        ].includes(this.$route.name) && window.innerWidth >= 360
      );
    },
    showScenarioDialog: {
      get: function () {
        return this.SCENARIO_DIALOG_OPEN;
      },
      set: function () {
        this.TOGGLE_SCENARIO_DIALOG();
      },
    },
    showCreateScenarioDialog: {
      get: function () {
        return this.EDIT_SCENARIO_DIALOG_OPEN;
      },
      set: function () {
        this.TOGGLE_EDIT_SCENARIO_DIALOG();
      },
    },
    showScenarioActionsButton() {
      if (
        this.$route.name === "Explore" &&
        !this.rightSidebarFullScreen &&
        (this.ACTIVE_SCENARIO !== null || this.EXPLORE_MODE === "create")
      ) {
        return true;
      }
      return false;
    },
    showExploreMobileFullScreenContentToggle() {
      // whether or not to show a toggle button for switching between map and right panel stats content in Explore screen on mobile
      if (
        this.$route.name === "Explore" &&
        this.rightSidebarFullScreen &&
        this.ACTIVE_SCENARIO !== null &&
        this.EXPLORE_MODE !== "create"
      ) {
        return true;
      }
      return false;
    },
    scenarioObsFABText() {
      if (this.responsive) {
        return "";
      }
      if (this.$route.name === "Observations") {
        return "Observation";
      } else if (this.$route.name === "Reports") {
        return "Report";
      }
      return "Scenario";
    },
    showPointsCounter() {
      return !this.responsive;
    },
    showHintButton() {
      return this.authenticated && this.ACTIVE_HINT !== null;
    },
    showQuestButton() {
      return this.authenticated && this.ACTIVE_QUEST !== null;
    },
  },

  watch: {
    HINT_BUTTON_ANIMATION() {
      // make the button animate
      this.bouncyHintButton = true;
      const vm = this;
      setTimeout(() => {
        vm.bouncyHintButton = false;
      }, 3000);
    },
    QUEST_BUTTON_ANIMATION() {
      // make the button animate
      this.bouncyQuestButton = true;
      const vm = this;
      setTimeout(() => {
        vm.bouncyQuestButton = false;
      }, 3000);
    },
  },

  async beforeCreate() {
    // Collect the user name and authentication
    try {
      const auth_status = await this.$Auth.currentAuthenticatedUser();
      this.authenticated = Boolean(auth_status.attributes.given_name);
    } catch (err) {
      this.authenticated = false;
    }
  },
  // TODO
  // components: {
  //   UserMenu
  // },

  beforeMount() {
    // get scenarios data
    this.collectData();
  },

  methods: {
    ...mapActions([
      types.actions.CLOSE_SCENARIO,
      types.actions.CLEAR_SCENARIO_AND_REGIONS,
      types.actions.SET_EXPLORE_MODE,
      types.actions.TOGGLE_SCENARIO_DIALOG,
      types.actions.CLOSE_SCENARIO_DIALOG,
      types.actions.TOGGLE_EDIT_SCENARIO_DIALOG,
      types.actions.SET_SCENARIO_EDIT_MODE,
      types.actions.CLOSE_SIDEBAR,
      types.actions.LOAD_USER_SCENARIOS,
      types.actions.SET_EXPLORE_MOBILE_FS_CONTENT,
      types.actions.SET_ACTIVE_CHALLENGE,
      types.actions.SET_SHOW_HINT_CHALLENGE_DIALOG,
      types.actions.SET_MAP_ACTION_TRIGGER,
      types.actions.SET_SELECTED_OBSERVATION_TYPE,
      types.actions.SET_NEW_REPORT_MSG,
    ]),

    errorAlert(message) {
      alert(message);
    },

    collectData: function () {
      // Scenario Data
      this.LOAD_USER_SCENARIOS({ vm: this });
    },
    pageTitle() {
      if (
        this.$route.name === "Explore" &&
        this.ACTIVE_SCENARIO !== null &&
        !this.rightSidebarFullScreen
      ) {
        return this.ACTIVE_SCENARIO.name;
      }
      return this.$route.name;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    linkClick() {
      this.CLOSE_SCENARIO_DIALOG();
      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false);
      }
    },
    newScenario() {
      this.SET_SCENARIO_EDIT_MODE("create");
      this.CLOSE_SCENARIO_DIALOG();
      this.TOGGLE_EDIT_SCENARIO_DIALOG();
    },
    goToScenariosList() {
      this.CLOSE_SCENARIO_DIALOG();
      this.$router.push({
        name: "Scenarios",
      });
    },
    launchFabAction() {
      // launch a new scenario or observation
      if (this.$route.name === "Observations") {
        // new observation
        // launch explore screen ready to create a new observation
        this.SET_MAP_ACTION_TRIGGER("newObservation");
        // if current observation type is system obs, set it to general
        if (!["water", "general"].includes(this.SELECTED_OBSERVATION_TYPE)) {
          this.SET_SELECTED_OBSERVATION_TYPE("general");
        }
        // go to explore view
        this.$router.push({
          name: "Explore",
        });
      } else if (this.$route.name === "Reports") {
        this.SET_NEW_REPORT_MSG(true);
        this.$router.push({ name: "Scenarios" });
      } else {
        // new scenario
        this.showScenarioDialog = true;
      }
    },
    launchHint() {
      // open the hints dialog
      this.SET_SHOW_HINT_CHALLENGE_DIALOG({ type: "hint", status: true });
    },
    launchQuest() {
      // open the quest in the challenge dialog
      this.SET_ACTIVE_CHALLENGE(this.ACTIVE_QUEST);
      this.SET_SHOW_HINT_CHALLENGE_DIALOG({ type: "challenge", status: true });
    },
    launchHelp() {
      // launch help / intro tours
      if (this.TOUR_ACTIVE) {
        // help is already open... reset it
        Object.keys(this.$tours).forEach((tour) => {
          this.$tours[tour].stop();
        });
      }
      // when launching from help button, use the reversed priority list
      const tours = this.reversePriorityTours;
      this.setPriorityTours(tours);
      this.$tours[tours[0]].start();
      this.SET_TOUR_ACTIVE(true);
    },
    showMapContent() {
      // toggle display of map content in explore mode on mobile devices
      this.SET_EXPLORE_MOBILE_FS_CONTENT("map");
    },
    showStatsPanelContent() {
      // toggle display of map content in explore mode on mobile devices
      this.SET_EXPLORE_MOBILE_FS_CONTENT("stats");
    },
  },
};
</script>
<style lang="scss" scoped>
.chooser-dialog {
  min-width: 350px;
}
.text-center {
  text-align: center;
}
.bottom-padding {
  margin-bottom: 30px;
}
.scenarioActionItem {
  text-align: right;
}

.md-dialog-title {
  position: relative;
  background-color: $grey-600;
  color: $white-color;
  padding-top: 20px;
  padding-bottom: 15px;
}

.condensedFAB {
  width: 41px !important;
  min-width: 41px;
}
/deep/ .condensedFAB .md-ripple {
  padding-left: 20px !important;
}

/deep/ .title-button {
  position: absolute;
  left: 0.5em;
  top: 6px;
  color: $white-color !important;
}
.title-icon {
  color: $white-color !important;
  margin-top: -0.15em;
  margin-right: 10px;
}
li.mobile-menu-item {
  margin: 0 !important;
}
li.mobile-menu-item div.md-list-item-container {
  width: 30px;
}
/deep/ li.mobile-menu-item div.md-list-item-content {
  padding: 0 !important;
}
li.mobile-menu-item
  div.md-list-item-container
  div.md-list-item
  .md-list-item-container
  .md-ripple {
  padding: 0 !important;
}
li.mobile-menu-item div.md-list-item-container button.md-icon-button {
  min-width: 30px;
}
li.md-list-item.hint-button {
  margin-left: 8px;
  width: 36px;
}
span.fa-stack.fa-1x.hint-button {
  height: 48px;
  padding-top: 8px;
}
.hint-button i:nth-child(1) {
  color: $brand-info;
}
.hint-button i {
  height: 48px;
  display: inline-block;
  text-align: center;
}
li.md-list-item.quest-button {
  margin-left: 8px;
  width: 36px;
}
span.quest-button {
  height: 48px;
  padding-top: 9px;
}
.quest-button i:nth-child(1) {
  color: $brand-primary;
}
.quest-button i {
  height: 48px;
  display: inline-block;
  text-align: center;
}
.help-button {
  height: 28px;
  text-align: center;
}
.help-button i {
  transform: scale(1.2, 1.2);
}

@keyframes bouncyButton {
  0% {
    transform: scale(1) translateY(0);
  }
  10% {
    transform: scale(1.2, 0.6);
  }
  30% {
    transform: scale(0.8, 1.1) translateY(-8px);
  }
  50% {
    transform: scale(1) translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.bouncyButton {
  animation: bouncyButton 2s;
  animation-timing-function: ease;
}
.share-chip {
  position: absolute;
  margin-top: 40px;
  background-color: $brand-info !important;
}
.scen-title-desktop {
  min-width: 170px;
}
</style>
