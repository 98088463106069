<template>
  <md-card class="card-medium-opacity">
    <div class="md-layout">
      <div class="md-layout-item md-size-15 md-medium-size-30 md-small-size-40 md-xsmall-size-60  mx-auto">
        <a
          :href="$Region.homeUrl"
          :title="`Go to the ${$Region.app_name} Home Page`"
        >
          <img
            alt="Logo"
            :src="logoImage"
          >
        </a>
      </div>
      <div class="md-layout-item md-size-85 ml-auto">
        <h1 class="description text-white">
          {{ this.$Region.app_name }} Simulator
        </h1>
      </div>
      <div
        v-for="item in $Region.landUseTypes.concat($Region.noChangeLandUseTypes)"
        :key="item.id"
        class="md-layout-item md-size-25 md-medium-size-33 md-small-size-50 md-xsmall-size-100"
      >
        <h3 class="text-center text-white">
          {{ item.name.replace(' and Transportation', '').replace('Short ', '') }}
        </h3>
        <div class="button-middle md-size-25 md-medium-size-33 md-small-size-50 md-xsmall-size-80">
          <md-button
            class="md-button-fill"
            :class="[{[item.color]: true}]"
            @click="reset"
          >
            <md-icon
              :class="getBigIconClass(item)"
              :md-src="getIconSrc(item)"
            />
          </md-button>
        </div>
        <h4 class="text-center text-white">
          +
          <animated-number
            :value="item.currentArea"
            :reset="resetToken"
            :duration="duration"
          /> Ha
        </h4>
      </div>
    </div>
    <h3 class="description text-center text-white">
      Sign up to explore and learn about land use scenarios
    </h3>
    <div class="text-center">
      <md-button
        class="md-size-50 md-raised md-success register-btn"
        @click="goToRegister"
      >
        <md-icon>check</md-icon>
        Sign up
      </md-button>
    </div>
  </md-card>
</template>
<script>
import { AnimatedNumber } from '../../components';
import { LandTypes } from "./Components/mixins/LandTypes";

export default {
  name: "LandingContentDesktop",

  components: {
    AnimatedNumber
  },
  mixins: [LandTypes], //some common lt stuff, responsive layout stuff
  data() {
    return {
      logoImage: `./img/${this.$Region.logo}`,
      duration: 800,
      resetToken: false
    }
  },
  methods: {
    reset() {
      this.resetToken = !this.resetToken;
    },
    getBigIconClass(landtype) {
      return `${this.getIconClass(landtype)} md-size-4x`;
    },
    goToRegister() {
      this.$router.push('/registerwizard');
    }
  }
}
</script>
<style lang="scss" scoped>
.text-white {
  color: white;
}
.text-right {
  text-align: right;
  vertical-align: middle;
}
.text-center {
  text-align: center;
}
.button-middle {
  margin: auto;
  width: 50%;
}
.card-medium-opacity {
  background-color: rgba(255, 255, 255, 0.4) !important;
  margin-bottom: 100px;
}
.register-btn {
  border: solid 1px $white-color;
  margin-bottom: 30px;
}
</style>
