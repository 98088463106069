// mixin for some observation summary info (for cards, lists etc)
export const ObservationSummary = {
  methods: {
    observedDate(observedTime) {
      // parse the observedTime into a date string
      return new Date(parseInt(observedTime)).toDateString();
    },
    observationIcon(type) {
      // return the icon used for this observation type
      return `fas ${this.$Region.observation_types[type].icon}`;
    },
    observationType(type) {
      // return the type in a nice title
      return this.$Region.observation_types[type].title;
    }
  }
};
