import { library } from "@fortawesome/fontawesome-svg-core";

// Add font awesome icons here
import {
  faArrowsAlt,
  faCity,
  faClawMarks,
  faCompressArrowsAlt,
  faIndustryAlt,
  faMountain,
  faPlay,
  faTh,
  faTractor,
  faTrees,
  faWater
} from "@fortawesome/pro-light-svg-icons";

library.add(
  faArrowsAlt,
  faCity,
  faClawMarks,
  faCompressArrowsAlt,
  faIndustryAlt,
  faMountain,
  faPlay,
  faTh,
  faTractor,
  faTrees,
  faWater
);
