<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <h5 class="info-text">
        Please enter some information
      </h5>
    </div>
    <!-- profile picture -->
    <div class="md-layout-item md-size-40">
      <div class="picture-container">
        <div class="picture">
          <div v-if="!avatarIsUploading">
            <img
              :src="AVATAR_TEMPLINK"
              class="picture-src"
              title=""
            >
          </div>
          <div
            v-else
            class=""
          >
            <md-icon class="md-size-4x">
              cloud
            </md-icon>
            <md-icon class="md-size-2x upload-animate">
              arrow_upward
            </md-icon>
          </div>
        </div>
        <h6 class="description">
          Choose Picture
        </h6>
        <md-button
          id="pick-avatar"
          class="md-raised md-success md-layout-item"
        >
          <md-icon>account_circle</md-icon>Upload
        </md-button>
        <avatar-cropper
          trigger="#pick-avatar"
          :upload-url="profileUploadUrl"
          :upload-form-data="profileUploadData"
          :output-options="avatarCropperOptions"
          :output-quality="0.9"
          :labels="{ submit: 'Upload', cancel: 'Cancel' }"
          @changed="getUploadURL"
          @submit="confirmUploadURL"
          @uploading="avatarUploading"
          @uploaded="avatarUploaded"
          @completed="avatarCompleted"
          @error="avatarError"
        />
      </div>
    </div>
    <!-- basic info container -->
    <div class="md-layout-item md-size-60">
      <div class="md-layout">
        <!-- Teachers & Students need to specify a school -->
        <div
          v-if="isStudent || isTeacher"
          class="md-layout-item md-size-100"
        >
          Please select your school
          <md-autocomplete
            v-model="school"
            :md-options="schoolSearch"
            :md-open-on-focus="false"
            @md-changed="getSchools"
            @md-opened="getSchools"
          >
            <label>Search...</label>
          </md-autocomplete>
        </div>
        <!-- Teachers & Students need to specify a grade -->
        <div
          v-if="isStudent || isTeacher"
          class="md-layout-item md-size-100"
        >
          Please select your grade
          <md-field>
            <md-select
              v-model="grade"
              name="grade"
            >
              <md-option
                v-for="item in $Region.gradeOptions"
                :key="item.id"
                :value="item.name"
              >
                {{ item.name }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <!-- Teachers need to specify a class -->
        <div
          v-if="isTeacher"
          class="md-layout-item md-size-100"
        >
          Please select your curriculum
          <md-field>
            <md-select
              v-model="curriculum"
              name="curriculum"
            >
              <md-option
                v-for="item in $Region.curriculumOptions"
                :key="item.id"
                :value="item.name"
              >
                {{ item.name }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <!-- Students may specify a teacher -->
        <div
          v-if="isStudent && schoolSelected"
          class="md-layout-item md-size-100"
        >
          Please select your teacher
          <md-autocomplete
            v-model="studentTeacher"
            :md-options="teacherSearch"
            :md-open-on-focus="false"
            @md-changed="getTeachers"
            @md-opened="getTeachers"
            @md-selected="selectTeacher"
          >
            <label>Search...</label>
          </md-autocomplete>
        </div>
        <!-- Public specifies postal code for analytics -->
        <div
          v-if="isPublic"
          class="md-layout-item md-size-100"
        >
          <md-field
            :class="[
              {'md-valid': !errors.has('postal code') && touched.postalCode},
              {'md-error': errors.has('postal code')}]"
          >
            <label>Postal Code</label>
            <md-input
              v-model="postalCode"
              v-validate="modelValidations.postalCode"
              data-vv-name="postal code"
              type="text"
              name="postal code"
              required
            />
            <slide-y-down-transition>
              <md-icon
                v-show="errors.has('postal code')"
                class="error"
              >
                close
              </md-icon>
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon
                v-show="!errors.has('postal code') && touched.postalCode"
                class="success"
              >
                done
              </md-icon>
            </slide-y-down-transition>
          </md-field>
        </div>
      </div>
    </div>

    <!-- rationale -->
    <div
      v-if="!isStudent"
      class="md-layout-item md-size-100"
    >
      <md-field maxlength="5">
        <label>Please provide a sentence or two describing why you would like to use the {{ $Region.app_name }} Simulator</label>
        <md-textarea
          v-model="rationale"
          v-validate="modelValidations.rationale"
          name="rationale"
        />
      </md-field>
    </div>

    <div
      v-else
      class="md-layout-item md-size-100"
    >
      <md-field maxlength="5">
        <label>What do you hope to learn using the {{ $Region.app_name }} Simulator</label>
        <md-textarea
          v-model="rationale"
          v-validate="modelValidations.rationale"
          name="rationale"
        />
      </md-field>
    </div>
  </div>
</template>

<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { User } from "@/pages/Dashboard/Components/mixins/User";
import AvatarCropper from "vue-avatar-cropper"
import swal from 'sweetalert2'

export default {
  name: "InfoStep",

  components: {
    SlideYDownTransition,
    AvatarCropper
  },
  mixins: [User], // avatar stuff

  // Need to collect the user type to guide the form elements
  props: {
    wizardModel: {
      type: Object,
      required: true
    },
    wizardBody: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      disabled: true,
      postalCode: '',
      school: null,
      schoolSearch: [],
      studentTeacher: null,
      selectedTeacher: null,
      selectedTeacherUsername: null,
      teacherSearch: [],
      curriculum: null,
      grade: null,
      rationale: '',
      rawTeacherList: [],
      touched: {
        school: false,
        postalCode: false
      },
      modelValidations: {
        postalCode: {
          required: true,
          min: 6,
          max: 7,
          regex: "[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY][0-9][abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ] ?[0-9][abceghjklmnprstvwxyzABCEGHJKLMNPRSTVWXYZ][0-9]"
        },
        school: {
          required: true
        },
        rationale: {
          required: true,
          min: 25,
          regex: "^[.!?\\-$,&\t\r\nA-Za-z 0-9]*$"
        }
      },
      avatarCropperOptions: {
        width: 512,
        height: 512,
        minWidth: 100,
        minHeight: 100,
        maxWidth: 300,
        maxHeight: 300
      },
      avatarIsUploading: false,
      profileImage: '',
      profileUploadUrl: null,
      profileUploadData: {}
    }
  },

  computed: {
    schoolSelected: function () {
      return this.$Region.schools.includes(this.school)
    },
    teacherList() {
      return this.rawTeacherList.filter((value, index, self) => {
        return self.indexOf(value) === index;
      })
    },
    teacherIsValid() {
      // confirm if a teacher username is in the list
      return (this.teacherList.findIndex(teacher => teacher[1] === this.selectedTeacherUsername) !== -1);
    },
    isStudent() {
      return (this.wizardModel.student || this.wizardBody.usertype === 'student');
    },
    isTeacher() {
      return (this.wizardModel.teacher || this.wizardBody.usertype === 'teacher');
    },
    isPublic() {
      return (this.wizardModel.general_public || this.wizardBody.usertype === 'general public');
    }
  },

  watch: {
    postalCode() {
      this.touched.postalCode = true
    },
    schoolSelected: function () {
      // get updated list of teachers
      if (this.schoolSelected) {
        const school = this.school;
        const path = '/teachers/' + school;
        const myInit = {};

        this.$API.get('api', path, myInit)
          .then(response => {
            this.rawTeacherList = response.Items.map((body) => {
              // collect teachers into array of tuples [teachername, teacherusername]
              return [`${body.firstname.S} ${body.lastname.S}`, body.UserName.S];
            })
          }).catch(error => {
            console.log(error.response) // eslint-disable-line
          })
      }
    },
    studentTeacher(newVal) {
      if (newVal === '' || newVal === null) {
        this.selectedTeacher = null;
        this.selectedTeacherUsername = null;
      }
    }
  },

  beforeMount() {
    // load the profile picture
    this.debounceGetAvatarUrl();
    // populate model data if doing profile update
    if (Object.keys(this.wizardModel).length === 0) {
      const modelKeys = [
        'school',
        'curriculum',
        'grade',
        'rationale'
      ]
      modelKeys.forEach(key => {
        this.$data[key] = this.wizardBody[key];
      });
      // not sure why but postalCode uses different field name than the model key
      if ('postal_code' in this.wizardBody && this.wizardBody['postal_code'] !== null) {
        this.postalCode = this.wizardBody['postal_code'];
      }
      // teacher autocomplete is compiled from 2 fields
      if ('teacher' in this.wizardBody && this.wizardBody.teacher !== null && this.wizardBody.teacher !== "") {
        this.selectedTeacher = this.wizardBody.teacher;
        if ('teacherUsername' in this.wizardBody && this.wizardBody.teacherUsername !== null && this.wizardBody.teacherUsername !== "") {
          this.selectedTeacherUsername = this.wizardBody.teacherUsername;
          this.studentTeacher = `${this.wizardBody.teacher} (${this.wizardBody.teacherUsername})`;
        } else {
          this.studentTeacher = this.wizardBody.teacher;
        }
      }
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    validate() {
      // The school must be included in the list
      if (!this.$Region.schools.includes(this.school) && !this.isPublic) {
        return new Promise(resolve => {
          this.signUpAlert('Please select a school from the list.  If you can not find your school in the list, select OTHER.');
          resolve(false);
        })
      }
      // the teacher must be in the list
      if (!this.isPublic && this.selectedTeacherUsername !== null && !this.teacherIsValid) {
        return new Promise(resolve => {
          this.signUpAlert(`Please select a teacher from the list.
                            If your teacher is not in the list, you can leave this field empty and update later once your teacher has registered.
                            If your teacher is registered and you still can't find them, make sure you have selected the correct school!`);
          resolve(false);
        })
      }
      else if (!RegExp(this.modelValidations.postalCode.regex).test(this.postalCode) && this.isPublic) {
        return new Promise(resolve => {
          this.signUpAlert('Please provide a valid postal code');
          resolve(false);
        })
      }
      else if (this.curriculum == null && this.isTeacher) {
        return new Promise(resolve => {
          this.signUpAlert('Please select a curriculum');
          resolve(false);
        })
      }
      else if (this.grade == null && this.isStudent) {
        return new Promise(resolve => {
          this.signUpAlert('Please select your grade');
          resolve(false);
        })
      }
      else if (this.rationale.length < 25) {
        return new Promise(resolve => {
          this.signUpAlert('Please provide at least 25 characters describing why you would like to use the Simulator');
          resolve(false);
        })
      }
      else if (!RegExp(this.modelValidations.rationale.regex).test(this.rationale)) {
        return new Promise(resolve => {
          this.signUpAlert('Please do not use "quotations" or other special characters in your description');
          resolve(false);
        })
      }

      return new Promise(resolve => {
        resolve(true);
      })
        .then(res => {
          this.onSuccess(res);
          return res;
        })
    },

    onSuccess(res) {
      // Prepare data for PUT
      this.wizardBody['school'] = this.school;
      this.wizardBody['postal_code'] = this.postalCode !== null ? this.postalCode.replace(' ', '') : null;
      this.wizardBody['rationale'] = this.rationale;
      this.wizardBody['curriculum'] = this.curriculum;
      this.wizardBody['teacher'] = this.selectedTeacher;
      this.wizardBody['teacherUsername'] = this.selectedTeacherUsername;
      this.wizardBody['grade'] = this.grade;

      // Increment the wizard;
      this.$emit('on-validated', res, this.$data);
    },

    filterSchools(school, term) {
      try {
        return school.toLowerCase().replace(' ', '').replace('.', '').includes(
          term.toLowerCase().replace(' ', '').replace('.', '')
        )
      } catch (err) {
        return false
      }
    },

    getSchools(searchTerm) {
      this.schoolSearch = new Promise(resolve => {
        window.setTimeout(() => {
          if (!searchTerm || searchTerm.length < 2) {
            resolve([])
          } else {
            resolve(this.$Region.schools.filter(school => this.filterSchools(school, searchTerm)))
          }
        }, 500)
      })
    },

    filterTeachers(term) {
      return function (teacher) {
        return teacher[0].toLowerCase().replace(' ', '').includes(term);
      }
    },

    getTeachers(searchTerm) {
      this.teacherSearch = new Promise(resolve => {
        window.setTimeout(() => {
          // Query teachers
          try {
            const term = searchTerm.toLowerCase().replace('mrs', '').replace('mr', '').replace('ms', '').replace('.', '').replace(' ', '');
            // get filtered list and map it to a combined string with `teacher (teacherusername)`
            resolve(this.teacherList.filter(this.filterTeachers(term)).map(teacher => `${teacher[0]} (${teacher[1]})`));
          }
          catch (err) {
            resolve([])
          }
        }, 500)
      })
    },

    selectTeacher(teacher) {
      // parse the `<teacher name> (teacher username)` back out to individual fields
      // eslint-disable-next-line
      const re = /(\((?:\(??[^\(]*?\)))/g;
      const _teacherUsername = re.exec(teacher)[0];
      this.selectedTeacher = teacher.replace(
        ` ${_teacherUsername}`,
        ""
      );
      this.selectedTeacherUsername = _teacherUsername.replace(/[()]/g, "");
    },

    signUpAlert(message) {
      swal.fire({
        title: `<p style="color:red;">Not so fast...</p>`,
        text: message,
        timer: 5000,
        showConfirmButton: false
      })
    },

    avatarUploading() {
      // flag that the avatar pic is busy uploading
      this.avatarIsUploading = true;
    },
    avatarUploaded() {
      // update the user's profile
      this.updateUserProfile({ 'avatar': this.profileImage });
      this.debounceGetAvatarUrl(true); // force an update
    },
    avatarCompleted() {
      // turn off the uploading status
      this.avatarIsUploading = false;
    },
    avatarError(message) {
      // error processing avatar image
      message = `We had some trouble processing your profile picture: ${message}`;
      swal.fire({
        title: `<p style="color:red;">Uh oh...</p>`,
        text: message,
        timer: 2000,
        showConfirmButton: false
      })
    },
    async getUploadURL(file) {
      // get a templink for uploading to s3
      // construct a filename to use based on the user's sub
      const re = /(?:\.([^.]+))?$/;
      const ext = re.exec(file.name)[1];
      const filename = `profile_${this.$Auth.user.attributes.sub}.${ext}`;
      this.profileImage = filename;
      // get an upload templink
      const authData = await this.$Auth.currentSession();
      const myInit = {
        headers: { Authorization: authData.idToken.jwtToken },
        body: {
          bucket: this.$ImageBucket
        }
      };
      const preflight = await this.$API.post(
        "api",
        `/upload/${filename}`,
        myInit
      );
      this.profileUploadUrl = preflight.body.tempLink.url;
      this.profileUploadData = preflight.body.tempLink.fields;
    },
    confirmUploadURL() {
      // make sure an upload templink has been set before trying to upload
      const vm = this;
      const waitingHasUrl = () => {
        if (!vm.profileUploadUrl) {
          setTimeout(waitingHasUrl, 200);
        } else {
          return true;
        }
      }
      waitingHasUrl();
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .upload-animate {
  display: block;
  color: $white-color !important;
  animation: upload 2s linear infinite;
}

@keyframes upload {
  0% {
    transform: translateY(0%);
  }
  90% {
    transform: translateY(-70px);
  }
  100% {
    transform: translateY(-70px);
  }
}
</style>
