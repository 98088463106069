<template>
  <div>
    <!--Terms and conditions modal-->
    <md-dialog-confirm
      :md-active.sync="model.showTAC"
      :md-click-outside-to-close="false"
      md-title="Privacy Policy & Terms of Use"
      :md-content="$Region.policy"
      md-confirm-text="I Agree"
      @md-confirm="acceptTAC"
      @md-cancel="cancelTAC"
    />

    <h5 class="info-text">
      Review the terms and conditions and verify {{ wizardModel.email }}
    </h5>
    <div class="md-layout">
      <div class="md-layout-item">
        <div class="md-layout">
          <!-- terms and conditions -->
          <div class="md-layout-item md-size-33 md-small-size-100">
            <icon-checkbox
              v-model="tacAcceptedIcon"
              v-validate="model.tacAccepted"
              icon="fas fa-gavel"
              title="Terms & Conditions"
              name="tacAccepted"
              data-vv-name="tacAccepted"
              @input="showTAC"
            />
          </div>
          <!-- verify email -->
          <div
            v-if="model.tacAccepted"
            class="md-layout-item md-size-33 md-small-size-100"
          >
            <icon-checkbox
              v-model="model.verifyEmail"
              v-validate="model.verifyEmail"
              icon="fas fa-envelope-open"
              title="Verify Email"
              name="verifyEmail"
              data-vv-name="verifyEmail"
              @input="forceTrue('verifyEmail')"
            />
          </div>
          <!-- email verified -->
          <div
            v-if="model.tacAccepted && model.verifyEmailSuccess"
            class="md-layout-item md-size-33 md-small-size-100"
          >
            <icon-checkbox
              v-model="model.selected"
              icon="fas fa-check-double"
              title="Email Verified!"
              @input="forceTrue('selected')"
            />
          </div>
        </div>
      </div>
      <div
        v-if="model.tacAccepted && model.verifyEmail && !model.verifyEmailSuccess && !hasConfirmationCode"
        class="md-layout-item md-size-95 ml-auto mt-4 md-small-size-100"
      >
        An email has been sent to {{ wizardModel.email }}. Please enter the provided verification code:
      </div>
      <div
        v-if="newCodeMsg"
        class="md-layout-item md-size-95 ml-auto mt-4 md-small-size-100"
      >
        An new verification code has been sent to your email. Please enter the provided code:
      </div>
      <div
        v-if="model.tacAccepted && model.verifyEmail && !model.verifyEmailSuccess"
        class="md-layout-item md-size-95 ml-auto mt-4 md-small-size-100"
      >
        <md-field
          :class="[
            {'md-valid': !errors.has('verificationCodeValue') && touched.verificationCodeValue},
            {'md-error': errors.has('verificationCodeValue')}]"
        >
          <md-icon>lock</md-icon>
          <label>6-Digit Verification Code</label>
          <md-input
            ref="verificationCodeValue"
            v-model="verificationCodeValue"
            v-validate="modelValidations.verificationCodeValue"
            data-vv-name="verificationCodeValue"
            name="verificationCodeValue"
            type="verificationCodeValue"
            required
            @input="debounceVerificationCode"
          />
          <slide-y-down-transition>
            <md-icon
              v-show="errors.has('verificationCodeValue') && touched.verificationCodeValue"
              class="error"
            >
              close
            </md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              v-show="!errors.has('verificationCodeValue') && touched.verificationCodeValue"
              class="success"
            >
              done
            </md-icon>
          </slide-y-down-transition>
        </md-field>
        <div>
          Would you like a new verification code?
          <md-button
            class="md-raised md-success md-layout-item"
            @click="newSignupCode"
          >
            <md-icon>email</md-icon>Send New Code
          </md-button>
        </div>
      </div>
      <!-- USERNAME field if user has come from a link and needs to re-enter it -->
      <div
        v-if="model.tacAccepted && model.verifyEmail && !model.verifyEmailSuccess && hasConfirmationCode"
        class="md-layout-item md-size-95 ml-auto mt-4 md-small-size-100"
      >
        Please re-enter the Username and Password you provided earlier:
        <md-field
          slot="inputs"
          class="md-form-group"
        >
          <md-icon>person</md-icon>
          <label>Username</label>
          <md-input
            v-model="username"
            v-validate="modelValidations.username"
            data-vv-name="username"
            type="username"
            required
          />
        </md-field>
        <!--Password if user has come from a link and needs to re-enter it-->
        <md-field
          :class="[
            {'md-valid': !errors.has('password') && touched.password},
            {'md-error': errors.has('password')}]"
        >
          <md-icon>lock</md-icon>
          <label>Password</label>
          <md-input
            ref="password"
            v-model="password"
            v-validate="modelValidations.password"
            data-vv-name="password"
            type="password"
            required
          />
          <slide-y-down-transition>
            <md-icon
              v-show="errors.has('password') && touched.password"
              class="error"
            >
              close
            </md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              v-show="!errors.has('password') && touched.password"
              class="success"
            >
              done
            </md-icon>
          </slide-y-down-transition>
        </md-field>
      </div>
    </div>
  </div>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions'
import { IconCheckbox } from '@/components'
import swal from 'sweetalert2'
import { debounce } from "debounce";

export default {
  name: "VerifyStep",

  components: {
    SlideYDownTransition,
    IconCheckbox
  },
  props: {
    wizardModel: {
      type: Object,
      required: true
    },
    confirmOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      verificationCodeValue: '',
      username: null,
      password: null,
      tacAcceptedIcon: false,
      touched: {
        verificationCodeValue: false,
        password: false,
        username: false
      },
      model: {
        selected: true,
        showTAC: false,
        tacAccepted: false,
        verifyEmail: false,
        verifyEmailSuccess: false
      },
      modelValidations: {
        verificationCodeValue: {
          required: true,
          numeric: true,
          min: 6,
          max: 6
        },
        username: {
          required: true,
          min: 6,
          regex: "^[A-Za-z0-9_@.-]*$"
        },
        password: {
          required: false,
          min: 8,
          regex: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
        },
      },
      hasConfirmationCode: false,
      verificationCodeError: false, // track to resend signup code if desired,
      newCodeMsg: false
    }
  },

  watch: {
    verificationCodeValue() {
      this.touched.verificationCodeValue = true
    },
  },

  beforeMount() {
    if (this.confirmOnly) {
      // assume user has already accepted TAC
      this.model.tacAccepted = true;
      this.tacAcceptedIcon = true;
      // check if the confirmation code is provided
      if ('c' in this.$route.query && this.$route.query.c !== '' && this.$route.query.c !== null) {
        // verification email was already sent, use the code provided in the string
        this.model.verifyEmail = true;
        this.hasConfirmationCode = true;
        this.verificationCodeValue = this.$route.query.c;
        if (localStorage.username && localStorage.verificationCodeDate) {
          // username is in localstorage.  Make sure it was put there within the 24 hours that the code is valid
          const now = Date.now();
          if (now < (localStorage.verificationCodeDate + 86400000)) { // less than a day old
            this.username = localStorage.username;
            this.touched.username = true;
          }
        }
      } else if ('username' in this.wizardModel) {
        // from routed from login - logged in but not verified
        this.username = this.wizardModel.username;
      }
      // make password required if it's not in the model
      if (!(('password') in this.wizardModel)) {
        this.modelValidations.password.required = true;
      }
    }
  },

  methods: {
    showTAC() {
      this.model.showTAC = true
    },

    acceptTAC() {
      this.model.showTAC = false;
      this.model.tacAccepted = true;
      this.tacAcceptedIcon = true;
    },

    cancelTAC() {
      this.model.showTAC = false;
      this.model.tacAccepted = false;
      this.tacAcceptedIcon = false;
    },

    forceTrue(attr) {
      this.model[attr] = true;
    },

    getError(fieldName) {
      return this.errors.first(fieldName);
    },

    finalValidation(verified) {
      return new Promise(
        function (resolve, reject) {
          // Email verification must be true
          if (verified) {
            resolve(true);
          }
          else {
            reject(false);
          }
        }
      )
    },

    async validate() {
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        // show errors
        this.signUpAlert(this.errors.items[0].msg);
        return false;
      }
      if (this.hasConfirmationCode && !this.model.verifyEmailSuccess) {
        // verifying from an email link
        await this.verificationUsername();
      }
      const vm = this;
      return this.finalValidation(this.model.verifyEmailSuccess).then(res => {
        if (res) {
          // sign in the new user - make sure we have the username and password
          ['username', 'password'].forEach(key => {
            if (vm[key] === null) {
              vm[key] = vm.wizardModel[key];
            }
          })
          vm.$Auth.signIn(vm.username, vm.password).then(res => {
            vm.$emit('on-validated', res, vm.$data);
          });
        } else {
          this.$emit('on-validated', res);
        }
        return res
      });
    },

    signUpAlert(message) {
      swal.fire({
        title: `<p style="color:red;">Not so fast...</p>`,
        text: message,
        timer: 2000,
        showConfirmButton: false
      })
    },

    clearLocalStorageUsername() {
      // clean up any local storage vars that were set
      if (localStorage.username) {
        localStorage.removeItem("username");
      }
      if (localStorage.verificationCodeDate) {
        localStorage.removeItem("verificationCodeDate");
      }
    },

    newSignupCode() {
      // send a new verification code to the user
      const username = this.hasConfirmationCode ? this.username : this.wizardModel.username;
      this.$Auth.resendSignUp(username).then(() => {
        this.verificationCodeValue = '';
        setTimeout(() => { this.touched.verificationCodeValue = false }, 100);
        this.errors.remove('verificationCodeValue');
        this.verificationCodeError = false;
        this.newCodeMsg = true;
      })
        .catch((err) => {
          this.signUpAlert(err.message);
        });
    },

    verificationCode() {
      // verify the verification code
      const username = this.hasConfirmationCode ? this.username : this.wizardModel.username;
      const validation_code = this.verificationCodeValue;
      // make sure a password is available - it will be required for signing in the user
      const passwordReady = ((this.password !== null && this.password !== '') || ('password' in this.wizardModel && this.wizardModel.password !== null && this.wizardModel.password !== ''));

      if (passwordReady && String(validation_code).length === 6) {
        this.$Auth.confirmSignUp(username, validation_code)
          .then(() => {
            this.model.verifyEmailSuccess = true;
            this.clearLocalStorageUsername();
          })
          .catch(() => {
            this.model.verifyEmailSuccess = false;
            if (!this.hasConfirmationCode) {
              this.verificationCodeValue = '';
              this.signUpAlert('That verification code is incorrect')
            } else {
              this.signUpAlert('The verification code or username you provided is incorrect')
            }
            this.verificationCodeError = true;
          })
      }
    },

    debounceVerificationCode: debounce(function () {
      // @input event is firing twice for some reason sometimes... this should prevent that from happening
      this.verificationCode();
    }, 500),

    async verificationUsername() {
      // verify the verification code from a link - username needs to be confirmed by user too
      const username = this.username;
      const validation_code = this.verificationCodeValue;

      if (String(validation_code).length === 6) {
        await this.$Auth.confirmSignUp(username, validation_code)
          .then(() => {
            this.model.verifyEmailSuccess = true;
            this.clearLocalStorageUsername();
          })
          .catch(err => {
            // check if the user already verified themselves
            if (err.message === 'User cannot be confirmed. Current status is CONFIRMED') {
              // user already confirmed... ignore this!
              this.model.verifyEmailSuccess = true;
              this.clearLocalStorageUsername();
            } else {
              this.model.verifyEmailSuccess = false;
              this.signUpAlert('The verification code or username you provided is incorrect')
              this.verificationCodeError = true;
            }
          })
      }
    }
  }
}
</script>
<style>
</style>
