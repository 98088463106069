<template>
  <div>
    <div
      class="flex-row"
      :class="{ 'switch-container-description-on': showDescription }"
      @click="warning"
    >
      <div>
        <md-switch
          v-model="practiceValueData"
          :disabled="disabled"
          class="practice-switch"
        >
          <span v-if="showName">
            {{ practice.name }}
          </span>
        </md-switch>
      </div>
      <div
        v-if="showDetailsBtn"
        class="description-switch"
      >
        <i
          class="fas fa-angle-down"
          :class="[{'showDescription': showDescription}, {'hideDescription': true}]"
          @click="toggleDescription"
        />
      </div>
    </div>
    <slide-y-up-transition v-if="showDetailsBtn">
      <div
        v-show="showDescription"
        class="description-area"
      >
        {{ practice.description }}
      </div>
    </slide-y-up-transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import { SlideYUpTransition } from "vue2-transitions";
import { debounce } from "debounce";
import { Scenario } from "./mixins/Scenario";
export default {
  name: "MgmtPracticeSwitch",

  components: {
    SlideYUpTransition
  },
  mixins: [Scenario], // for saving updated scenario to db

  props: {
    practice: {
      type: Object,
      required: true
    },
    practiceValue: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    scenarioType: {
      type: String,
      required: true
    },
    showDetailsBtn: {
      type: Boolean,
      default: false
    },
    activePracticeDescription: {
      type: String,
      default: null
    },
    showName: {
      type: Boolean,
      default: true
    },
    showWarning: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      practiceValueData: null, // used to update
      showDescription: false
    }
  },

  computed: {
    ...mapGetters([
      types.getters.EXPLORE_MODE,
      types.getters.MGMT_PRACTICE_UPDATED
    ])
  },

  watch: {
    practiceValueData(newVal, oldVal) {
      if (oldVal !== null) {
        // only apply watcher after data is first initialized
        // update the scenario management practices when switch is toggled
        if (this.ACTIVE_SCENARIO !== null && 'management' in this.ACTIVE_SCENARIO) {
          this.UPDATE_ACTIVE_SCENARIO({ management: this.updatedMgmtArray(this.ACTIVE_SCENARIO, newVal) });
        }
        if (this.EXPLORE_MODE !== 'create') {
          // update existing scenario on db. No need to re-run the simulation though
          this.debounceSaveMgmtSettings();
        }
        // track in case it needs to be updated in another component instance
        this.SET_MGMT_PRACTICE_UPDATED(`${this.practice.variable},${this.practiceValueData}`);
      }
    },
    MGMT_PRACTICE_UPDATED(newVal) {
      // track if practice was updated from another component instance
      const [variable, value] = newVal.split(',')
      if (variable === this.practice.variable) {
        this.practiceValueData = value === 'true' ? true : false;
      }
    },
    activePracticeDescription(newVal) {
      // close the description area if a different description is activated
      if (this.showDescription && newVal !== this.practice.variable) {
        this.showDescription = false;
      }
    }
  },

  created() {
    // initialize the mgmt practice from passed props to data
    this.practiceValueData = this.practiceValue;
  },

  methods: {
    ...mapActions([
      types.actions.UPDATE_ACTIVE_SCENARIO,
      types.actions.CLEAR_INDICATOR_VALS,
      types.actions.SET_MGMT_PRACTICE_UPDATED
    ]),

    updatedMgmtArray(_scenario, newVal) {
      // create an updated management practices array from ACTIVE_SCENARIO.management
      // returns updated array
      const _this = this;
      let _management = null;
      if (newVal === false) {
        // remove from array of management practices variables in scenario
        _management = _scenario.management.filter(_practice => _practice !== _this.practice.variable);
      } else {
        // add to array of management practice variables
        _management = [..._scenario.management];
        _management.push(_this.practice.variable);
      }
      return _management;
    },
    debounceSaveMgmtSettings: debounce(function () {
      this.saveScenarioUpdatesToDB(this.ACTIVE_SCENARIO, ["management"], false);
      // clear indicator data
      this.CLEAR_INDICATOR_VALS();
    }, 500),
    warning() {
      if (this.showWarning && this.disabled) {
        this.$notify({
          message: `Sorry, ${this.practice.name} can not be changed for this scenario.`,
          icon: "error_outline",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "info"
        });
      }
    },
    toggleDescription() {
      // toggle display of the description for a practice
      this.showDescription = !this.showDescription;
      if (this.showDescription) {
        // prevent more than one description from showing at a time
        this.$emit('on-show-description', this.practice.variable);
      }
    }
  }
};
</script>
<style lang="scss" module>
</style>
<style lang="scss" scoped>
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/deep/ .practice-switch label {
  margin-bottom: 10px;
}
.description-switch i {
  padding: 17px 15px 17px 15px;
  color: $gray-lighter;
}
.description-switch i:hover {
  cursor: pointer;
}
.showDescription {
  transform: rotate(180deg);
}
.showDescription,
.hideDescription {
  transition: transform 200ms linear;
}
.description-area {
  color: $white-color;
  background-color: $gray-dark;
  border-radius: 0 0 5px 5px;
  padding: 0 20px 10px 20px;
  margin-top: 0px;
  margin-bottom: 10px;
}
.switch-container-description-on {
  background-color: $gray-dark;
  border-radius: 5px 5px 0 0;
  padding-left: 10px;
}
</style>
