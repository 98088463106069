/* jshint esversion: 6 */
// collection of images to use for different scenario types.  This could be moved to CSS classes or to region-config.js instead.
// TODO NPS 20190510 get better images for historic, bau sceanrio types
export const ScenarioImages = {
  data() {
    return {
      images: {
        Historic: "./img/card-water.jpg",
        "Business as Usual": "./img/card-land-use.jpg",
        "Land Use": "./img/card-land-use.jpg",
        "Climate Change": "./img/card-climate.jpg"
      }
    };
  }
};
