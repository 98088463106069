<template>
  <!--Loading bar (symbolic)-->
  <div class="md-layout">
    <div
      v-if="collectingMapData"
      class="md-layout-item md-size-100"
    >
      <h4 class="card-title">
        Collecting map data...
      </h4>
      <md-progress-bar
        class="md-primary"
        md-mode="buffer"
        :md-value="progressAmount"
        :md-buffer="progressAmount"
      />
    </div>
    <!--Error collecting data-->
    <div
      v-else-if="dataError"
      class="md-layout"
    >
      <div class="md-layout-item md-size-100">
        <h4
          class="card-title"
          style="{color: red}"
        >
          Unable to collect map data, please try again later
        </h4>
      </div>
    </div>
    <div
      :id="mapContainerID"
      class="md-layout-item md-size-100 mapCard"
    />
  </div>
</template>

<script>
import * as mapboxgl from "mapbox-gl";
import "@/mapboxgl-texture-overlay"; // TODO build production version (should be smaller/minified?)
import { MapLayers } from "./mixins/MapLayers";

export default {
  name: "StudyAreaMap",

  components: {
  },
  mixins: [MapLayers],

  props: {
    scenario: {
      type: Object,
      default: () => null
    },
    year: {
      type: Number,
      default: -1
    },
    intensityMapData: {
      type: Object,
      default: () => null
    },
    bitmapsReady: {
      type: Boolean,
      default: false
    },
    showStudyAreaOverlay: {
      type: Boolean,
      default: false
    },
    observation: {
      type: Object,
      default: () => null
    }
  },

  data() {
    return {
      dataError: false, // TODO this isn't being used, but maybe it should be? Would need to implement an error check if region data isn't loading
      regionPopup: new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        anchor: 'bottom'
      })
    };
  },

  computed: {
    mapContainerID() {
      return `mapCard${this._uid}`
    },
    mapOptions() {
      return {
        container: this.mapContainerID,
        style: this.mapboxStyles.streets, // from MapLayers mixin
        center: [this.$Region.map_coords.lng, this.$Region.map_coords.lat],
        zoom: 5,
        maxZoom: 22,
        antialias: true,
        interactive: false
      }
    },
    studyAreaName() {
      const name = this.scenario.studyArea.replace(".geojson", "").split("__");
      return name[name.length - 1];
    },
  },

  watch: {
  },

  beforeMount() {
    this.incrementBar();
  },

  mounted() {
    this.initMap();
  },

  beforeDestroy() {
    // Clean up the map
    this.map.remove();
  },

  methods: {
    initMap() {
      // Initiate the map instance
      mapboxgl.accessToken = this.$Region.mapbox_token;
      this.map = new mapboxgl.Map(this.mapOptions);

      // Initiate layers on load
      const _map = this.map;
      this.map.on("style.load", () => {
        const waiting = () => {
          if (!_map.isStyleLoaded()) {
            setTimeout(waiting, 200);
          } else {
            // add some layers
            this.addHillshadeLayer();
            if (this.scenario !== null) {
              if (this.intensityMapData !== null) {
                this.addLanduseLayer('landuse', true);
                this.setBitmapTime(this.year);
              }
              this.loadScenarioStudyAreaJson(this.showStudyAreaOverlay);
              this.flyTo();
            } else if (this.observation !== null) {
              const coords = this.observation.coordinates.split(',').map(x => parseFloat(x))
              const flyOptions = {
                center: coords,
                zoom: 12,
                speed: 0.8,
                pitch: 20,
                bearing: 5
              }
              this.newObsMarker(this.observation.type, coords, false);
              this.map.flyTo(flyOptions);
            }
            this.closeProgressBar();
          }
        };
        waiting();
      });
    },
    planningFillOnMouseMove(e) {
      // hover effect for planning - mouse cursor
      // needs to be a separate function (not inline) so it can be removed during layer updates
      if (e.features.length > 0) {
        this.map.getCanvas().style.cursor = "pointer";
      }
      const coordinates = e.lngLat;
      const yearDesc = this.showStudyAreaOverlay ? `` : `<br>Year: ${this.year}`;
      const description = `<p><strong>${this.studyAreaName}</strong>
                            ${yearDesc}
                            <br>
                            Scenario: ${this.scenario.name}
                            <br>
                            <i>Click to launch in explorer</i>
                            </p>`;
      this.regionPopup
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(this.map);
    },
    planningFillOnMouseLeave() {
      // turn off hover effect when leaving region
      // needs to be a separate function so it can be removed during layer updates
      this.map.getCanvas().style.cursor = "";
      this.regionPopup.remove();
    },
    planningOnClick() {
      // do nothing
      this.$emit('on-launch-scenario', this.scenario);
      return;
    },
    planningPreviewOnClick() {
      // do nothing
      return;
    }
  }
};
</script>

<style scoped>
.map-small {
  min-height: 150px;
  width: 100%;
}
.map-medium {
  min-height: 300px;
  width: 100%;
}
.map-large {
  min-height: 450px;
  width: 100%;
}
</style>
