<template>
  <div>
    <md-card class="card-medium-opacity">
      <div class="md-layout">
        <div class="md-layout-item md-size-60 mx-auto">
          <a
            :href="$Region.homeUrl"
            :title="`Go to the ${$Region.app_name} Home Page`"
          >
            <img
              class="logo"
              alt="Logo"
              :src="logoImage"
            >
          </a>
        </div>
      </div>
      <div class="text-center">
        <md-button
          class="md-size-80 md-raised md-info register-btn"
          @click="goToLogin"
        >
          <md-icon>login</md-icon>
          Login
        </md-button>
      </div>
      <div class="text-center">
        <h4 class="btn-header">
          New Users:
        </h4>
        <md-button
          class="md-size-80 md-raised md-success register-btn"
          @click="goToRegister"
        >
          <md-icon>check</md-icon>
          Sign up
        </md-button>
      </div>
    </md-card>
  </div>
</template>
<script>
export default {
  name: "LandingContentMobile",

  components: {},

  data() {
    return {
      logoImage: `./img/${this.$Region.logo}`,
    }
  },
  methods: {
    goToRegister() {
      this.$router.push('/registerwizard');
    },
    goToLogin() {
      this.$router.push('/login');
    }
  }
}
</script>
<style lang="scss" scoped>
.logo {
  padding-top: 40px;
  padding-bottom: 40px;
}
.text-center {
  text-align: center;
}
.card-medium-opacity {
  background-color: rgba(255, 255, 255, 0.4) !important;
}
.btn-header {
  color: $white-color;
  margin-bottom: 0 !important;
}
.register-btn {
  border: solid 1px $white-color;
  margin-bottom: 30px;
  width: 50%;
}
</style>
