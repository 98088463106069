// hints setup
import RegionConditions from "./region-conditions";

/*
hints can be defined in one of 3 different types: 'general', 'allocation', 'transition'
- general hints provide a general message
- allocation hints relate to a specific allocation from a user's land-use plan
- transition hints relate to a specific climate change land type transition

sample general hint:
  {
    id: 1,
    title: "a title",
    description:
      "description",
    comment: "admin comment",
    type: "general",
    event: "scenario-view", // event that can trigger this hint possible types: ['obs-create', 'obs-view', 'video-view', 'scenario-view', 'quest-complete', 'report-create', 'mission-complete']
    conditions: [ // conditions that must be in place for the hint to be launched
      RegionConditions.scenarioIsLandUse,
      RegionConditions.goalsFailMostEnv
    ],
    media: { // optional photo or video
      type: "video",
      title: "title", // this video title is used to track user's video history for awarding badges, must match the same title as elsewhere
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/883ad936d3e74ad29f7aebfa3ae56dc2/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/a3db110f-8d4b-4190-b512-a84cf1472eb5/thumbnails/Management Practices_H264_tumb.0000039.jpg"
    }
  }

sample allocation hint:
  {
    id: 101,
    title: "title",
    description: RegionConditions.someFunction, // either text or a function that will create the text using the ACTIVE_HINT_ALLOCATION
    followTitle: "title", // secondary title to appear below allocation details
    followDescription: "description", // secondary description to appear below allocation details
    comment: "adminComments",
    type: "allocation",
    event: "scenario-view", // event that can trigger this hint possible types: ['obs-create', 'obs-view', 'video-view', 'scenario-view', 'quest-complete', 'report-create', 'mission-complete']
    conditions: [ // conditions that must be in place for the hint to be launched
      RegionConditions.scenarioIsLandUse,
      RegionConditions.someOtherCondition // this condition should also SET_ACTIVE_HINT_ALLOCATION so that it can be referenced on dialog component
    ]
  }

sample climate transition hint:
  {
    id: 201,
    title: "Climate Change Impacts",
    description: RegionConditions.transitionMostDescription, // either text or a function that will create the text using the ACTIVE_HINT_TRANSITION
    followTitle: "title", // secondary title to appear below transition details
    followDescription: `description`, // secondary description to appear below transition details
    comment: "adminComments",
    type: "transition",
    event: "scenario-view", // event that can trigger this hint possible types: ['obs-create', 'obs-view', 'video-view', 'scenario-view', 'quest-complete', 'report-create', 'mission-complete']
    conditions: [ // conditions that must be in place for the hint to be launched
      RegionConditions.scenarioIsBAUOrLandUse,
      RegionConditions.transitionMost // this condition should also SET_ACTIVE_HINT_TRANSITION so that it can be referenced on dialog component
    ]
  }
*/

const RegionHints = [
  // ---------- GENERAL HINTS ----------------
  {
    id: 1,
    title: "Beneficial Management Practices",
    description:
      "Did you reach your environmental goals? Using Beneficial Management Practices might help. Watch the best practices video, then try adjusting management practices for for your simulation.",
    comment: "Failed to reach over 1/2 of environmental goals.",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.goalsFailMostEnv,
    ],
    media: {
      type: "video",
      title: "Beneficial Management Practices", // this video title is used to track user's video history for awarding badges, must match the same title as elsewhere
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/883ad936d3e74ad29f7aebfa3ae56dc2/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/a3db110f-8d4b-4190-b512-a84cf1472eb5/thumbnails/Management Practices_H264_tumb.0000039.jpg",
    },
  },
  {
    id: 2,
    title: "Achieving Enviromental Goals",
    description:
      "Not so fast. Unfortunately you did not meet the majority of your environmental goals.  Were your goals realistic? Have you considered changing the level of inudstrial activity? Have you conisdered using more mangement practices.",
    comment: "Failed to reach over 1/2 of environmental goals.",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.goalsFailMostEnv,
    ],
    media: {
      type: "video",
      title: "Beneficial Management Practices", // this video title is used to track user's video history for awarding badges, must match the same title as elsewhere
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/883ad936d3e74ad29f7aebfa3ae56dc2/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/a3db110f-8d4b-4190-b512-a84cf1472eb5/thumbnails/Management Practices_H264_tumb.0000039.jpg",
    },
  },
  {
    id: 3,
    title: "Balancing Act",
    description:
      "Yay!  You reached most of your environmental goals!  Were you also able to reach your socio-economic goals?  It is easy to balance all of them?",
    comment: "Passed at least 1/2 of environmental goals.",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.goalsPassMostEnv,
    ],
    media: {},
  },
  {
    id: 4,
    title: "Planning By Objective",
    description:
      "Yikes!  Unfortunately you didn't meet the majority of your goals.  You may need to have another look at your goals, and make more changes to your future map.",
    comment: "Passed at less than 1/2 of all goals.",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.goalsFailMost,
    ],
    media: {},
  },
  {
    id: 5,
    title: "Almost There!",
    description:
      "Fantastic! It looks like you've reached the majority of your goals.  Can you reach all of them?",
    comment: "Passed at more than 75% of all goals.",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.goalsPass75Percent,
    ],
    media: {},
  },
  {
    id: 6,
    title: "Balancing Economy and Environment",
    description:
      "Congratulations!  You have met the majority of your socio-economic goals.  Did you also reach your environmental goals?",
    comment: "Passed more than half of socio-economic.",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.goalsPassMostSocEco,
    ],
    media: {},
  },
  {
    id: 7,
    title: "Socio-Economic Goals",
    description:
      "Unfortunately, you have not reached the majority of your socio-economic goals.  What changes can you make to try to meet them?",
    comment: "Passed more than half of socio-economic.",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.goalsFailMostSocEco,
    ],
    media: {},
  },
  {
    id: 8,
    title: "Planning Goals",
    description: RegionConditions.goalsFailAnyDescription,
    comment: "any goal fails",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.goalsFailAny,
    ],
    media: {},
  },
  {
    id: 9,
    title: "High Industrial Activities",
    description:
      "You industry level is high. Try adjusting the level of industrial activity and see how that changes your plan.",
    comment: "if dev rate is >10% higher than 1980 OR dev rate is >60%",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.devRateHigh,
    ],
    media: {},
  },
  {
    id: 10,
    title: "Low Industrial Activities",
    description:
      "Your industry level is low.  How will your outcome change if you raise the level of industrial activity?",
    comment: "if dev rate is >10% lower than 1980 or dev rate is <20%",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.devRateLow,
    ],
    media: {},
  },
  {
    id: 11,
    title: "Beneficial Management Practices",
    description: RegionConditions.mgmtPracticeOnDescription,
    comment: "specific mgmt practice is on",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.mgmtPracticeIsOn,
    ],
    media: {},
  },
  {
    id: 12,
    title: "Beneficial Management Practices",
    description: RegionConditions.mgmtPracticeOffDescription,
    comment: "specific mgmt practice is OFF",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.mgmtPracticeIsOff,
    ],
    media: {},
  },
  {
    id: 13,
    title: "Industrial Activities & GDP",
    description:
      "The level of Industrial Activity will determine whether you reach your economic goals or not. Try adjusting the slider to either increase or decrease your industrial activity.",
    comment: "Didn't reach GDP goal",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.goalsFailGDP,
    ],
    media: {},
  },
  {
    id: 14,
    title: "Water Quality",
    description:
      "What could you change to improve your water quality goal? Watch the Water Quality video to see how human activity and land-use decisions can affect water quality.",
    comment:
      "Didn't reach water quality goal, or water quality did not  get better with their plan.",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.indsWaterQualityFail,
    ],
    media: {
      type: "video",
      title: "Water Quality", // this video title is used to track user's video history for awarding badges, must match the same title as elsewhere
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/5cde617d12f6457c8c1021b18aaa4796/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/71ac9e8a-e577-4765-9783-71eaee4c08dd/thumbnails/Water Quality v2_H264_tumb.0000063.jpg",
    },
  },
  {
    id: 15,
    title: "Natural Landscapes",
    description:
      "Your Natural Landscapes are falling.  This may be a tradeoff of increasing other indicators. Can you think of anything that can be done to increase natural landscapes?",
    comment: "Natural Landscapes went down",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.indsNaturalLandDecrease,
    ],
    media: {},
  },
  {
    id: 16,
    title: "Population",
    description:
      "In your simulation, did population increase? Is that realistic for this region of Alberta? If not, you may want to draw more population to represent realistic growth.",
    comment: "Population stayed the same",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.indsPopulationStatic,
    ],
    media: {},
  },
  {
    id: 17,
    title: "Population Density",
    description:
      "In your simulation, the area of population increased dramatically. One way to have cities and towns grow without taking up more areas is to increase the density of settlements. Try turning on the Walkable Cities, Urban Densification Beneficial Management Practices and see how it changes your other dials.",
    comment:
      "population increased by 50% or more and urban density management practice is off",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.indsPopulationIncrease,
    ],
    media: {},
  },
  {
    id: 18,
    title: "Water Quality Down",
    description:
      "Your water quality went down. When and how fertilizer is applied to crops can have an impact on water quality.  Are there any management practices you could turn on that would decrease the impact of agriculture on water quality.",
    comment: "water quality went down and management practices are not on",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.indsWaterQualityDecreaseNoMgmt,
    ],
    media: {},
  },
  {
    id: 19,
    title: "Fish Habitat",
    description:
      "Your fish habitat has fallen. This will affect fish population stability.  Is there anything you can reconsider in your plan to help fish habitat?",
    comment: "Fish Habitat Went Down",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.indsFishDecrease,
    ],
    media: {},
  },
  {
    id: 20,
    title: "Grizzly Habitat",
    description:
      "You are losing Grizzly Habitat for this area. Have you considered looking at the level of industrial activity in your area?",
    comment: "grizzly habitat decreases",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.indsGrizzlyDecrease,
    ],
    media: {},
  },
  {
    id: 21,
    title: "Grizzly Habitat",
    description:
      "You are losing Grizzly Habitat for this area. Have you considered any beneficial management pracitces that could help you reach your goal for this indicator?",
    comment: "grizzly habitat decreases",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.indsGrizzlyDecrease,
    ],
    media: {},
  },
  {
    id: 22,
    title: "Caribou Habitat",
    description:
      "You are losing Caribou Habitat for this area. Have you considered looking at the level of industrial activity in your area?",
    comment: "caribou habitat decreases",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.indsCaribouDecrease,
    ],
    media: {},
  },
  {
    id: 23,
    title: "Caribou Habitat",
    description:
      "You are losing Caribou Habitat for this area. Have you considered any beneficial management pracitces that could help you reach your goal for this indicator?",
    comment: "caribou habitat decreases",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.indsCaribouDecrease,
    ],
    media: {},
  },
  {
    id: 24,
    title: "Biotic Carbon Storage",
    description:
      "Your Biotic Carbon Storage Fell. If you are unsure what this is, watch the Greenhouse Gases and Biotic Carbon Storage Video. Increasing natural landscapes can help you reach your biotic carbon storage goal.",
    comment: "biotic carbon decreases",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.indsBioticCarbonDecrease,
    ],
    media: {
      type: "video",
      title: "Greenhouse Gasses", // this video title is used to track user's video history for awarding badges, must match the same title as elsewhere
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/0ae65744bb3847bb9a6d8b8eb33c2ef0/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/be03a00b-b27d-4afe-be64-332ec61b92c5/thumbnails/Greenhouse Gases v2_H264_tumb.0000039.jpg",
    },
  },
  {
    id: 25,
    title: "Greenhouse Gasses",
    description:
      "Your GHG emissions are rising.  Try using the best practices to decrease Greenhouse Gas levels.",
    comment: "ghg increases",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.indsGHGIncrease,
    ],
    media: {},
  },
  {
    id: 26,
    title: "Oil and Gas Production",
    description:
      "Your Oil and Gas Production went up. How will this affect the GDP? How might it affect your environmental indicators?",
    comment: "hydrocarbon production increases",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.indsEnergyIncrease,
    ],
    media: {},
  },
  {
    id: 27,
    title: "GDP",
    description:
      "Your Gross Domestic Product (GDP) didn't go up very much. We tend to use GDP as an indicator of how an economy is doing. Is it OK if GDP doesn't max out?",
    comment: "GDP stays the same or decreases",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.indsGDPDecrease,
    ],
    media: {},
  },
  {
    id: 28,
    title: "Forestry",
    description:
      "Your Timber Production is rising. How will this affect your environmental indicators?",
    comment: "Timber Production increases",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.indsTimberIncrease,
    ],
    media: {},
  },
  {
    id: 29,
    title: "Agriculture",
    description:
      "The amount of land devoted to Agriculture is falling. What does this mean for the people living in this area?",
    comment: "Agricultural Production decreases",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.indsAgDecrease,
    ],
    media: {},
  },
  {
    id: 30,
    title: "Water Consumption",
    description:
      "Water Shortages mean we must take action to decrease our water consumption. What can be done to decrease water consumption?",
    comment: "Water Consumption increases",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.indsWaterConsumptionIncrease,
    ],
    media: {},
  },
  {
    id: 31,
    title: "Water Use Restrictions",
    description:
      "Drought and water shortages mean you must decrease water consumption for the future. Try adjusting management practices to see how water conservation practices may help. Watch the water consumption video to see what some beneficial management practices are.",
    comment: "Water Consumption increases",
    type: "general",
    event: "scenario-view",
    conditions: [
      RegionConditions.scenarioIsLandUse,
      RegionConditions.indsWaterConsumptionIncrease,
    ],
    media: {
      type: "video",
      title: "Water Consumption", // this video title is used to track user's video history for awarding badges, must match the same title as the indicator
      url:
        "https://d2qcvmovr4fv.cloudfront.net/out/v1/15cffe272bc44d51a21fda366d42a26d/4ca13953c62041b89da9ee75727d50c9/d421596074b04016aafe5f186ec3ff53/index.m3u8",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/a42e73bb-ede0-415a-b12b-a873782a69a1/thumbnails/Water Consumption v3_H264_tumb.0000039.jpg",
    },
  },
  {
    id: 32,
    title: "Climate Change Impacts",
    description: `You currently have climate change set to None.
                  Scenario modelling without the impacts of climate change can be useful to see how things might affected
                  by land use decisions in the future in the absence of other variables.  However, climate change is
                  already impacting much of Alberta in measurable ways. You should make sure to also look at your
                  scenario under different climate change scenarios to see how the landscape might change in the future.`,
    comment: "adminComments",
    type: "general",
    event: "scenario-view", // event that can trigger this hint
    conditions: [
      RegionConditions.scenarioIsBAUOrLandUse,
      RegionConditions.climateChangeIsOff,
    ],
    media: {
      type: "video",
      title: "Climate Change",
      url: "https://www.youtube.com/watch?v=Okh7vn60kxg",
      thumbUrl:
        "https://d2qcvmovr4fv.cloudfront.net/youtube/thumbnails/climate_change_video.jpg",
      shareUrl: "https://www.youtube.com/watch?v=Okh7vn60kxg",
    },
  },
  // ---------- ALLOCATION HINTS ----------------
  {
    id: 101,
    title: "It Worked!",
    description: RegionConditions.allocationsMostSuccessfulDescription, // either text or a function that will create the text using the ACTIVE_HINT_ALLOCATION
    followTitle: "Does This Change Support Your Goals?",
    followDescription: `Are the environmental health and socio-economic performance indicators meeting your goals? Your plan may have worked as far as being able to grow this land cover type, but it's important that your planned changes are based on your goals.  In planning terms, this is called "Management by Objective".`,
    comment: "adminComments",
    type: "allocation",
    event: "scenario-view", // event that can trigger this hint
    conditions: [
      // conditions that must be in place for the hint to be launched
      RegionConditions.scenarioIsLandUse,
      RegionConditions.allocationsMostSuccessful, // this condition should also SET_ACTIVE_HINT_ALLOCATION so that it can be referenced on dialog component
    ],
  },
  {
    id: 102,
    title: "Your Change Partially Worked",
    description: RegionConditions.allocationsMediumSuccessfulDescription, // either text or a function that will create the text using the ACTIVE_HINT_ALLOCATION
    followTitle: "Why wasn't my allocation more successful?",
    followDescription: `There are a number of reasons why your plan may not have worked.  Are there other competing allocations in your plan?  Is the amount of change you are proposing realistic or is it too high? Is climate change affecting the landscape?  Perhaps the area you proposed is not a suitable land type for converting to your plan.`,
    comment: "adminComments",
    type: "allocation",
    event: "scenario-view", // event that can trigger this hint
    conditions: [
      // conditions that must be in place for the hint to be launched
      RegionConditions.scenarioIsLandUse,
      RegionConditions.allocationsMediumSuccessful, // this condition should also SET_ACTIVE_HINT_ALLOCATION so that it can be referenced on dialog component
    ],
  },
  {
    id: 103,
    title: "Uh oh...",
    description: RegionConditions.allocationsLeastSuccessfulDescription, // either text or a function that will create the text using the ACTIVE_HINT_ALLOCATION
    followTitle: "Why didn't it work?",
    followDescription: `Is the existing land type feasible to be converted for your plan? Are there too many competing plans and changes?  Even if the existing land cover types could change to meet your plan, perhaps there are too many other changes in the same area competing for the same limited resources.`,
    comment: "adminComments",
    type: "allocation",
    event: "scenario-view", // event that can trigger this hint
    conditions: [
      // conditions that must be in place for the hint to be launched
      RegionConditions.scenarioIsLandUse,
      RegionConditions.allocationsLeastSuccessful, // this condition should also SET_ACTIVE_HINT_ALLOCATION so that it can be referenced on dialog component
    ],
  },
  // ---------- CLIMATE TRANSITION HINTS ----------------
  {
    id: 201,
    title: "Climate Change Impacts",
    description: RegionConditions.transitionMostDescription, // either text or a function that will create the text using the ACTIVE_HINT_TRANSITION
    followTitle: "What's changing?",
    followDescription: `Play the simulation again and look for examples of areas changing due to these climate change impacts.`,
    comment: "adminComments",
    type: "transition",
    event: "scenario-view", // event that can trigger this hint
    conditions: [
      // conditions that must be in place for the hint to be launched
      RegionConditions.scenarioIsBAUOrLandUse,
      RegionConditions.transitionMost, // this condition should also SET_ACTIVE_HINT_TRANSITION so that it can be referenced on dialog component
    ],
  },
  {
    id: 202,
    title: "Climate Change Impacts",
    description: RegionConditions.transitionAnyDescription, // either text or a function that will create the text using the ACTIVE_HINT_TRANSITION
    followTitle: "What's changing?",
    followDescription: `Play the simulation again and look for examples of areas changing due to these climate change impacts.`,
    comment: "adminComments",
    type: "transition",
    event: "scenario-view", // event that can trigger this hint
    conditions: [
      // conditions that must be in place for the hint to be launched
      RegionConditions.scenarioIsBAUOrLandUse,
      RegionConditions.transitionRand, // this condition should also SET_ACTIVE_HINT_TRANSITION so that it can be referenced on dialog component
    ],
  },
];

export default RegionHints;
