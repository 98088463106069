// state store for user profile information (including points, preferences)
import { types } from "../types";

// default userData obj
const defaultUserData = {
  grade: null,
  lng: null,
  curriculum: null,
  email: null,
  UserName: null,
  postal_code: null,
  lastname: null,
  rationale: null,
  school: null,
  teacher: null,
  usertype: null,
  UserTransaction: null,
  firstname: null,
  lat: null,
  points: 0,
  avatar: "./img/default-avatar.png",
  seenIntro: true,
};

// initial state
const state = {
  userData: { ...defaultUserData },
  avatarTemplink: "./img/default-avatar.png",
  avatarTemplinkLastLoaded: null,
};

// getters
const getters = {
  [types.getters.USER_DATA]: (state) => state.userData,
  [types.getters.AVATAR_TEMPLINK]: (state) => state.avatarTemplink,
  [types.getters.AVATAR_TEMPLINK_LAST_LOADED]: (state) =>
    state.avatarTemplinkLastLoaded,
};

// mutations
const mutations = {
  [types.mutations.USER_LOAD](state, user) {
    // set the userData
    state.userData = user;
  },
  [types.mutations.USER_DATA_UPDATE](state, payload) {
    // update keys in userData - update all keys provided in payload
    Object.keys(payload).forEach((key) => {
      state.userData[key] = JSON.parse(JSON.stringify(payload[key]));
    });
  },
  [types.mutations.SET_AVATAR_TEMPLINK](state, link) {
    // set the link
    state.avatarTemplink = link;
  },
  [types.mutations.SET_AVATAR_TEMPLINK_LAST_LOADED](state, timestamp) {
    // set the timestamp
    state.avatarTemplinkLastLoaded = timestamp;
  },
};

// actions
const actions = {
  [types.actions.USER_LOAD]({ commit }, payload) {
    // Collect the most recent user profile information from the current authenticated user
    // load user data from the database via api call
    // payload expects a vue model as a {vm: this} ... this should be called from a model like so: this.USER_LOAD({vm: this})
    // relies on loading global options
    const authLib = payload.vm.$Auth;
    const apiLib = payload.vm.$API;
    const userData = { ...defaultUserData };

    // API Call
    authLib
      .currentSession()
      .then((authData) => {
        const myInit = {
          headers: { Authorization: authData.idToken.jwtToken },
        };
        const username = authData.accessToken.payload.username;

        apiLib
          .get("api", "/user/" + username, myInit)
          .then((body) => {
            // Parse a query from DynamoDB
            body.Items.map((item) => {
              if (item.UserTransaction.S === "user0") {
                Object.keys(item).map((data) => {
                  if (
                    ["history", "achievements", "inprogress"].includes(data)
                  ) {
                    userData[data] = JSON.parse(
                      item[data].S.replace(/'/g, '"')
                    );
                  } else if (Object.keys(item[data])[0] === "N") {
                    userData[data] = parseFloat(item[data].N);
                  } else if (Object.keys(item[data])[0] === "S") {
                    if (item[data].S === "null") {
                      userData[data] = null;
                    } else {
                      userData[data] = item[data].S;
                    }
                  } else {
                    userData[data] = item[data].Bool;
                  }
                });
              }
            });

            // set if user has seen intro yet
            if (
              !("history" in userData) ||
              ("history" in userData &&
                userData.history.findIndex((item) => item.startsWith("t_")) ===
                  -1)
            ) {
              userData.seenIntro = false;
            }

            //   set user data to store
            commit(types.mutations.USER_LOAD, userData);
            // trigger potential mission achievement updates
            commit(types.mutations.INCREMENT_MISSION_UPDATE_TRIGGER);
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error.response);
          });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  },
  [types.actions.USER_DATA_UPDATE]({ commit }, payload) {
    // note this only updates the vuex object.  It does not update the database.
    commit(types.mutations.USER_DATA_UPDATE, payload);
  },
  [types.actions.SET_AVATAR_TEMPLINK]({ commit }, link) {
    commit(types.mutations.SET_AVATAR_TEMPLINK, link);
  },
  [types.actions.SET_AVATAR_TEMPLINK_LAST_LOADED]({ commit }, timestamp) {
    commit(types.mutations.SET_AVATAR_TEMPLINK_LAST_LOADED, timestamp);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
