<template>
  <div class="content">
    <!-- active report details -->
    <zoom-y-transition
      v-if="ACTIVE_REPORT.scenarios.length"
      :duration="{enter: 300, leave: 0}"
    >
      <report-details
        :report="ACTIVE_REPORT"
        @on-back="clearCurrentReport"
      />
    </zoom-y-transition>
    <!-- else show list of reports -->
    <zoom-y-transition
      v-else
      :duration="{enter: 300, leave: 0}"
    >
      <div>
        <div class="md-layout">
          <div
            v-if="USER_REPORTS.length === 0 && collectingData"
            class="md-layout"
          >
            <div class="md-layout-item md-size-100">
              <h4 class="card-title">
                Collecting your reports...
              </h4>
              <md-progress-bar
                class="md-info"
                md-mode="buffer"
                :md-value="amount"
                :md-buffer="amount"
              />
            </div>
          </div>
          <div
            v-else-if="USER_REPORTS.length === 0"
            class="md-layout-item md-size-100"
          >
            <md-card>
              <div class="md-card-content">
                <h3>You don't have any reports yet</h3>
                <p>
                  Please select 1 to 4 scenarios to report from <a :href="newReport">
                    your scenarios
                  </a> to create your first report!
                </p>
                <md-button
                  id="scenariosBtn"
                  class="md-raised md-success"
                  @click="newReport"
                >
                  Select Scenarios
                </md-button>
              </div>
            </md-card>
          </div>

          <div
            v-else
            class="md-layout-item md-size-100"
          >
            <md-card>
              <div class="md-card-content">
                <div class="md-layout">
                  <div class="md-layout-item md-xsmall-size-100 md-size-90">
                    <h3>You have {{ USER_REPORTS.length }} {{ 'report' | pluralize(USER_REPORTS.length) }}</h3>
                  </div>
                  <!-- REPORT LIST ACTIONS  -->
                  <div class="md-layout-item md-xsmall-size-100 md-size-10">
                    <md-menu md-size="medium">
                      <md-button
                        id="UserReportsFilterBtn"
                        md-menu-trigger
                        class="md-icon-button md-fab title-button menu-button"
                      >
                        <md-icon>more_vert</md-icon>
                      </md-button>
                      <md-menu-content>
                        <md-menu-item @click="orderBy('name')">
                          Order by Name
                        </md-menu-item>
                        <md-menu-item @click="orderBy('dateCreated')">
                          Order by Date
                        </md-menu-item>
                      </md-menu-content>
                    </md-menu>
                  </div>
                </div>
              </div>
            </md-card>
          </div>
        </div>
        <div class="md-layout">
          <div
            v-for="(item, index) in USER_REPORTS"
            :key="item.id"
            class="md-layout-item md-size-25 md-medium-size-33 md-small-size-100"
          >
            <user-report-card
              :report="item"
              :index="index"
              @on-load-report="loadReportDetails"
              @on-delete-report="confirmDeleteReport"
            />
          </div>
        </div>
      </div>
    </zoom-y-transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import { ZoomYTransition } from "vue2-transitions";
import swal from "sweetalert2";
import ReportDetails from '@/pages/Dashboard/Components/ReportDetails';
import UserReportCard from '@/pages/Dashboard/Components/UserReportCard';
import { Sharing } from "@/pages/Dashboard/Components/mixins/Sharing";
export default {
  name: "UserReports",

  components: {
    ZoomYTransition,
    ReportDetails,
    UserReportCard
  },
  mixins: [Sharing],

  data() {
    return {
      collectingData: true,
      amount: 0,
      timeOutAmount: 0,
    }
  },

  computed: {
    ...mapGetters([
      types.getters.USER_REPORTS,
      types.getters.ACTIVE_REPORT
    ])
  },

  async beforeMount() {
    await this.LOAD_USER_REPORTS({ vm: this });
    this.incrementBar(); // prevent the "you have no scenarios" msg from flashing on the screen if it's because vuex hasn't yet caught up
    // load shared resource if it's in the url query
    if ('rep' in this.$route.query && this.$route.query.rep !== null && this.$route.query.rep !== '') {
      this.getShareObj('rep', this.$route.query.rep, this.$Helpers.remapRepData, this.SET_ACTIVE_REPORT, 'rep');
    }
  },

  methods: {
    ...mapActions([
      types.actions.LOAD_USER_REPORTS,
      types.actions.SET_ACTIVE_REPORT,
      types.actions.SET_REPORT_MODE,
      types.actions.SET_NEW_REPORT_MSG,
      types.actions.USER_REPORTS_REMOVE,
      types.actions.ACTIVE_REPORT_CLEAR
    ]),

    incrementBar: function () {
      if (this.amount < 98) {
        this.amount++;
        setTimeout(this.incrementBar, Math.random() * 10);
        if (this.amount === 98) {
          this.initiateTimeOut();
        }
      }
    },
    initiateTimeOut() {
      if (this.collectingData) {
        this.timeOutAmount++;
        if (this.timeOutAmount === 20) {
          this.collectingData = false;
        } else {
          setTimeout(this.initiateTimeOut, 100);
        }
      }
    },
    orderBy(key) {
      // re-order scenarios list by key
      if (key === 'dateCreated') {
        // numeric sort
        this.USER_SCENARIOS.sort((a, b) => a[key] - b[key]);
      } else {
        // text sort
        this.USER_SCENARIOS.sort(function (a, b) {
          let _a = a[key].toUpperCase();
          let _b = b[key].toUpperCase();
          if (key === 'studyArea') {
            // parse out the watershed that is actually displayed
            const getName = function (i) {
              const name = i.replace(".geojson", "").split("__");
              return name[name.length - 1];
            }
            _a = getName(_a);
            _b = getName(_b);
          }
          if (_a < _b) {
            return -1;
          }
          if (_b < _a) {
            return 1;
          }
          // vals must be equal
          return 0;
        })
      }
    },
    loadReportDetails(index) {
      // load a report details view
      this.SET_REPORT_MODE('view');
      this.SET_ACTIVE_REPORT({ vm: this, rep: this.USER_REPORTS[index] });
    },
    clearCurrentReport() {
      // clear the Report
      // clear any potential sharing query in the url
      this.$router.replace({ query: null });
      this.ACTIVE_REPORT_CLEAR();
    },
    newReport() {
      this.SET_NEW_REPORT_MSG(true);
      this.$router.push({ name: "Scenarios" });
    },
    confirmDeleteReport(index) {
      swal.fire({
        title: "Are you sure?",
        text: `This report will be deleted.  Don't worry though, any scenarios in the report will still be available.`,
        icon: "warning",
        iconHtml: `<i class="fas fa-exclamation"></i>`,
        showCancelButton: true,
        customClass: {
          confirmButton: "md-button md-success",
          cancelButton: "md-button md-danger",
        },
        confirmButtonText: "Yes, delete it",
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          // Move on to actually delete it...
          const userTransaction = `rep${this.USER_REPORTS[index].id}`
          // if it's the report that's currently open, clear it
          if (this.ACTIVE_REPORT.id === this.USER_REPORTS[index].id) {
            this.ACTIVE_REPORT_CLEAR();
          }
          this.USER_REPORTS_REMOVE(this.USER_REPORTS[index]); // remove from the USER_SCENARIOS array
          // api call to delete it
          const payload = {
            UserTransaction: userTransaction,
          };

          this.$Auth
            .currentSession()
            .then(authData => {
              const username = authData.accessToken.payload.username;
              payload.UserName = username;
              const path = "/report/" + username;
              const myInit = {
                body: payload,
                headers: { Authorization: authData.idToken.jwtToken }
              };
              this.$API
                .del("api", path, myInit)
                // eslint-disable-next-line
                .then(response => {
                  // Refresh the scenario data or error
                  this.LOAD_USER_REPORTS({ vm: this });
                })
                .catch(error => {
                  // eslint-disable-next-line
                  console.log(error);
                });
            })
            // eslint-disable-next-line
            .then(resp => {
              swal.fire({
                title: "It's gone",
                text: "Your report has been deleted",
                icon: "success",
                customClass: {
                  confirmButton: "md-button md-success",
                },
                buttonsStyling: false
              });
            });
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.menu-button {
  margin-top: 0.5em;
}
</style>
