<template>
  <div
    v-if="showRocket"
    class="rocket-container"
  >
    <div class="success-rocket">
      <i class="fas fa-rocket fa-10x" />
      <div class="flame" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { types } from "@/store/types";
export default {
  name: "RocketAnimation",

  components: {
  },

  props: {
  },

  data() {
    return {
      showRocket: false
    }
  },

  computed: {
    ...mapGetters([
      types.getters.CHALLENGE_SUCCESS_ANIMATION
    ]),
  },

  watch: {
    CHALLENGE_SUCCESS_ANIMATION() {
      this.showRocket = true;
      const vm = this;
      setTimeout(() => { vm.showRocket = false; }, 2500);
    }
  },

  mounted() { },

  methods: {
    rocketToggle() {
      this.showRocket = !this.showRocket;
    }
  }
};
</script>

<style lang="scss" scoped>
.rocket-container {
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: absolute;
}
.success-rocket {
  width: 100%;
  height: 100%;
  position: relative;
  top: calc(100% - 160px);
//   top: 100%;
  left: 80px;
  overflow: hidden;
  animation: rocketLaunch 3s;
  animation-timing-function: ease-in;
  z-index:10000;
}
.success-rocket i {
  color: $brand-primary;
  text-shadow: 2px 2px 8px $gray;
}

@keyframes rocketLaunch {
  0% {
    transform: scale(1) translate(0%, 0%);
  }
  100% {
    transform: scale(1) translate(200%, -200%);
  }
}

.flame {
  top: -45px;
  left: 0px;
  height: 25px;
  width: 60px;
  position: relative;
  border-radius: 50%;
  background-color: $brand-warning;
  transform: rotate(135deg);
  animation: flame 0.3s linear infinite;
  z-index: -1;
}

@keyframes flame {
  0% {
    transform: scale(1) translateX(0%) translateY(0%) rotate(135deg);
  }
  50% {
    transform: scale(1.25) translateX(10%) translateY(10%) rotate(135deg);
  }
  100% {
    transform: scale(1) translateX(0%) translateY(0%) rotate(135deg);
  }
}
</style>
