<template>
  <component
    :is="tag"
    class="badge"
    :class="`badge-${type}`"
  >
    <slot />
  </component>
</template>
<script>
  export default {
    name: 'Badge',
    props: {
      tag: {
        type: String,
        default: 'span'
      },
      type: {
        type: String,
        default: 'default',
        validator: (value) => {
          const acceptedValues = ['primary', 'info', 'success', 'warning', 'danger', 'rose'];
          return acceptedValues.indexOf(value) !== -1
        }
      }
    }
  }
</script>
<style>
</style>
