<template>
  <div class="md-layout indicator-legend">
    <div class="md-layout-item md-size-100 md-title">
      {{ climateLegendTitle }}
    </div>
    <div
      v-for="index in visibleMapColors"
      :key="index"
      class="md-layout-item md-size-100 md-layout"
    >
      <div class="md-layout-item md-size-30 text-no-padding">
        <span
          :style="getColorBox(index)"
          class="colorbox"
        />
      </div>
      <div class="md-layout-item md-size-70 text-no-padding legend-item">
        {{ getBreakpoint(index) }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "MapIndicatorLegend",

  components: {
  },

  props: {
    indicator: {
      type: Object,
      default: () => null
    }
  },

  data() {
    return {
    };
  },

  computed: {
    climateLegendTitle() {
      return `${this.indicator.chartTitle} (${this.indicator.chartUnits})`;
    },
    visibleMapColors() {
      // return all colors except for the 0 index - it is invisible on the map
      // reverse the order so high values are at the top of the legend
      return Object.keys(this.indicator.mapColors).filter(key => parseInt(key) !== 0).reverse();
    }
  },

  methods: {
    getBreakpoint(index) {
      // return the breakpoint number for a given color index
      const _index = (parseInt(index) !== 1) ? parseInt(index) : 0; // return the 0 value index for the first box
      // convert the 0-255 value into the actual indicator value based on the max and min -- the same max/min were used on the server script to generate the jickle bitmap values
      return Math.round(((this.indicator.maxval - this.indicator.minval) / 255 * _index) + this.indicator.minval);
    },
    getColorBox(index) {
      return {
        'background-color': this.indicator.mapColors[index]
        };
    }
  }
};
</script>

<style lang="scss" scoped>
.indicator-legend {
  color: $white-color;
  & > * {
    color: white;
  }
  padding: 10px;
}
.legend-item {
  font-size: 14px;
}
.colorbox {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin: 0;
  padding: 0;
  line-height: 20px;
}
.text-no-padding {
  position: relative;
  height: 20px;
  margin: 0;
  padding: 0px;
  line-height: 20px;
}
</style>