/* jshint esversion: 6 */
import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";

// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";
import LandingContent from "../pages/Dashboard/LandingContent";

// Pages
const User = () => import("@/pages/Dashboard/Pages/UserProfile.vue");
const Login = () => import("@/pages/Dashboard/Pages/Login.vue");
const ForgotPassword = () =>
  import("@/pages/Dashboard/Pages/ForgotPassword.vue");
const RegisterWizard = () =>
  import("@/pages/Dashboard/Pages/RegisterWizard.vue");
const Lock = () => import("@/pages/Dashboard/Pages/Lock.vue");

// main nav pages
const Mission = () => import("@/pages/Dashboard/Pages/Mission");
const IndigenousVoices = () => import("@/pages/Dashboard/Pages/IndigenousVoices");
const Explore = () => import("@/pages/Dashboard/Pages/Explore");
const TeacherResources = () =>
  import("@/pages/Dashboard/Pages/TeacherResources");
const BulkWizard = () =>
  import("@/pages/Dashboard/Pages/BulkWizard");
const Videos = () => import("@/pages/Dashboard/Pages/Videos");
const UserScenarios = () => import("@/pages/Dashboard/Pages/UserScenarios");
const Observations = () => import("@/pages/Dashboard/Pages/Observations");
const VirtualTours = () => import("@/pages/Dashboard/Pages/VirtualTours");
const Achievements = () => import("@/pages/Dashboard/Pages/Achievements");
const Leaderboard = () => import("@/pages/Dashboard/Pages/Leaderboard");
const UserReports = () => import("@/pages/Dashboard/Pages/UserReports");

const dashboardContent = {
  path: "/",
  component: DashboardLayout,
  children: [
    {
      path: "dashboard",
      name: "Dashboard",
      props: true,
      component: Dashboard,
      children: [
        {
          path: "profile",
          name: "User Profile",
          component: User,
          props: true,
        },
        {
          path: "mission",
          name: "Your Mission",
          component: Mission,
        },
        {
          path: "voices",
          name: "Indigenous Voices",
          component: IndigenousVoices,
        },
        {
          path: "resources",
          name: "Teacher Resources",
          component: TeacherResources,
        },
        {
          path: "addstudents",
          name: "Add Students",
          component: BulkWizard,
        },
        {
          path: "explore",
          name: "Explore",
          component: Explore,
          props: true,
          meta: {
            hideFooter: true,
          },
        },
        {
          path: "videos",
          name: "Videos",
          component: Videos,
          props: true,
        },
        {
          path: "scenarios",
          name: "Scenarios",
          component: UserScenarios,
          props: true,
        },
        {
          path: "observations",
          name: "Observations",
          component: Observations,
        },
        {
          path: "virtual-tours",
          name: "Virtual Tours",
          component: VirtualTours,
        },
        {
          path: "achievements",
          name: "Achievements",
          component: Achievements,
        },
        {
          path: "leaderboard",
          name: "Leaderboard",
          component: Leaderboard,
        },
        {
          path: "reports",
          name: "Reports",
          component: UserReports,
        },
      ],
    },
  ],
};

const authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "landing",
      name: "Simulator",
      component: LandingContent,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/forgotPassword",
      name: "Password",
      component: ForgotPassword,
    },
    {
      path: "/registerwizard",
      name: "Register",
      component: RegisterWizard,
      props: true,
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock,
    },
  ],
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home",
  },
  authPages,
  dashboardContent,
];

export default routes;
