<template>
  <div class="md-layout text-center">
    <div class="md-layout-item md-size-50 md-small-size-100">
      <lock-card>
        <img
          slot="imageProfile"
          class="img"
          :src="image"
        >
        <h4
          slot="title"
          class="title"
        >
          {{ name }}
        </h4>
        <md-field slot="content">
          <label>Enter Password</label>
          <md-input
            v-model="password"
            type="password"
            @keyup.13="logIn"
          />
        </md-field>
        <md-button
          slot="footer"
          :disabled="apiUpdating"
          class="md-success md-round"
          @click="logIn"
          @keyup.13="logIn"
        >
          <md-icon
            v-if="apiUpdating"
            class="updating-icon"
          >
            swap_horiz
          </md-icon>
          Unlock
        </md-button>
        <md-button
          slot="footer"
          class="md-simple md-sm"
          to="/Login"
        >
          Not<br>{{ email }}?
        </md-button>
      </lock-card>
    </div>
  </div>
</template>
<script>
import { LockCard } from '@/components'
import swal from 'sweetalert2'

export default {

  components: {
    LockCard
  },

  data() {
    return {
      authenticated: false,
      email: '',
      name: '',
      password: null,
      image: `./img/${this.$Region.logo}`,
      apiUpdating: false
    }
  },
  beforeMount() {
    // Collect the username and image to help with re-authentication
    this.$Auth.currentUserInfo()
      .then(data => {
        if (data) {
          this.email = data.username;
          this.name = data.username;
        } else {
          this.$router.push('/Login');
        }
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err)
      });

    // analytics tracking
    this.$Helpers.gtagEvent(this, `logout`, `engagement`, 'cognito');

    // reset all state data
    this.$store.commit('reset');

    this.$Auth.signOut()
      // eslint-disable-next-line no-console
      .catch(err => { console.error(err) });
  },

  methods: {
    errorAlert(message) {
      swal.fire({
        title: `<p style="color:red;">Sorry</p>`,
        text: message,
        timer: 2000,
        showConfirmButton: false
      })
    },

    async logIn() {
      if (this.email == null || this.password == null) {
        this.errorAlert('A user email and password must be provided');
      }
      else {
        try {
          this.apiUpdating = true;
          await this.$Auth.signIn(this.email, this.password);
          this.authenticated = true;
          swal.fire({
            timer: 1000,
            icon: 'success',
            showConfirmButton: false
          });
          this.$router.push('/Dashboard')
        } catch (err) {
          this.apiUpdating = false;
          this.errorAlert(err.message);
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/* updating icon animation */
.updating-icon {
  animation: flip 2s infinite;
}
@keyframes flip {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
