<template>
  <div class="content">
    <!-- resource details -->
    <zoom-y-transition
      v-if="currentResource"
      :duration="{ enter: 300, leave: 0 }"
    >
      <teacher-resource-details
        :resource="currentResource"
        @on-back="clearCurrentResource"
      />
    </zoom-y-transition>
    <!-- resource list -->
    <zoom-y-transition
      v-else
      :duration="{ enter: 300, leave: 0 }"
    >
      <div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <md-card>
              <div class="md-card-content">
                <div
                  v-if="
                    $Region.teacherResources && $Region.teacherResources.length
                  "
                  class="md-layout"
                >
                  <div
                    class="
                      md-layout-item
                      md-xsmall-size-100
                      md-small-size-100
                      md-size-30
                    "
                  >
                    <h3>{{ filteredResources.length }} Resources</h3>
                  </div>
                  <div
                    class="
                      md-layout-item
                      md-xsmall-size-100
                      md-small-size-30
                      md-size-20
                    "
                  >
                    <md-autocomplete
                      id="tagFilterInput"
                      v-model="newFilter"
                      :md-options="tagSearch"
                      :md-open-on-focus="false"
                      @md-changed="getTags"
                      @md-opened="getTags"
                    >
                      <label>Add Filter...</label>
                    </md-autocomplete>
                  </div>
                  <div
                    v-if="activeFilters.length > 0"
                    class="
                      md-layout-item
                      md-xsmall-size-100
                      md-small-size-70
                      md-size-50
                      tag-section
                      main-filters
                    "
                  >
                    <h6>Filters:</h6>
                    <!-- using individual md-chip components here (as opposed to md-chips) so they can be individually styled -->
                    <md-chip
                      v-for="tag in activeFilters"
                      :key="tag"
                      md-deletable
                      :class="getTagClass(tag)"
                      @md-delete="deleteFilter(tag)"
                    >
                      {{ tag }}
                    </md-chip>
                    <md-button
                      class="md-dense md-simple clear-filter-btn"
                      @click="clearAllFilters"
                    >
                      Clear
                    </md-button>
                  </div>
                  <div
                    class="
                      md-layout-item
                      md-xsmall-size-100
                      md-small-size-100
                      md-size-100
                      layout-no-padding
                      tag-section
                    "
                  >
                    <h6>Filter By:</h6>
                  </div>
                  <div
                    v-if="themes.length"
                    class="md-layout-item md-size-100 layout-no-padding tag-section"
                  >
                    <h6>
                      Theme:
                    </h6>
                    <md-chip
                      v-for="chip in themes"
                      :key="chip"
                      md-clickable
                      :md-deletable="false"
                      class="md-primary"
                      @click="addFilter(chip)"
                    >
                      {{ chip }}
                    </md-chip>
                  </div>
                  <div
                    v-if="gradeTags.length"
                    class="md-layout-item md-size-100 layout-no-padding tag-section"
                  >
                    <h6>
                      Grades:
                    </h6>
                    <md-chip
                      v-for="chip in gradeTags"
                      :key="chip"
                      md-clickable
                      :md-deletable="false"
                      class="md-info"
                      @click="addFilter(chip)"
                    >
                      {{ chip }}
                    </md-chip>
                  </div>
                  <div
                    v-if="subjectTags.length"
                    class="md-layout-item md-size-100 layout-no-padding tag-section"
                  >
                    <h6>
                      Subjects:
                    </h6>
                    <md-chip
                      v-for="chip in subjectTags"
                      :key="chip"
                      md-clickable
                      :md-deletable="false"
                      class="md-success"
                      @click="addFilter(chip)"
                    >
                      {{ chip }}
                    </md-chip>
                  </div>
                </div>
                <div
                  v-else
                  class="md-layout"
                >
                  <div class="md-layout-item md-size-100">
                    <h3>
                      We are busy building new lesson plans and resources!
                    </h3>
                  </div>
                  <div class="md-layout-item md-size-100">
                    <p>Check back soon.</p>
                  </div>
                </div>
              </div>
            </md-card>
          </div>
        </div>
        <!-- lesson plans with no themes -->
        <div
          v-if="!themes.length"
          class="md-layout"
        >
          <div
            v-for="(resource, index) in filteredResources"
            :key="index"
            class="
              md-layout-item md-size-25 md-medium-size-33 md-small-size-100
            "
          >
            <teacher-resource-card
              :resource="resource"
              @on-load-resource="loadResourceDetails"
              @on-set-tag="addFilter"
            />
          </div>
        </div>
        <!-- lesson plans organized by theme -->
        <div
          v-else
        >
          <div
            v-for="(theme, _index) in $Region.resourceThemeOrder"
            :key="_index"
            class="md-layout"
          >
            <div
              v-if="getFilteredResourcesByTheme(theme).length"
              class="md-layout-item md-size-100 md-title"
            >
              {{ theme }}
            </div>
            <div
              v-for="(resource, index) in getFilteredResourcesByTheme(theme)"
              :key="index"
              class="md-layout-item md-size-25 md-medium-size-33 md-small-size-100"
            >
              <teacher-resource-card
                :resource="resource"
                @on-load-resource="loadResourceDetails"
                @on-set-tag="addFilter"
              />
            </div>
          </div>
        </div>
      </div>
    </zoom-y-transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { types } from "@/store/types";
import { ZoomYTransition } from "vue2-transitions";
import TeacherResourceCard from "../Components/TeacherResourceCard";
import TeacherResourceDetails from "../Components/TeacherResourceDetails";

export default {
  name: "TeacherResources",

  components: {
    ZoomYTransition,
    TeacherResourceCard,
    TeacherResourceDetails,
  },
  props: {},

  data() {
    return {
      currentResource: null,
      filteredResources: this.$Region.teacherResources,
      newFilter: null,
      tagSearch: [],
      activeFilters: [],
      themes: [],
    };
  },

  computed: {
    ...mapGetters([types.getters.USER_DATA]),

    isTeacher() {
      return (
        "usertype" in this.USER_DATA && this.USER_DATA.usertype === "teacher"
      );
    },

    gradeTags() {
      // return a list of grade tags
      return this.$Region.gradeOptions.map((gr) => {
        if (gr.name.toLowerCase() === "k") {
          return "Kindergarten";
        } else if (gr.name.toLowerCase() === "post-secondary") {
          return gr.name;
        }
        return `Grade ${gr.name}`;
      });
    },
    subjectTags() {
      // return a list of subject tags
      return this.$Region.curriculumOptions.map((subj) => subj.name);
    },
    allTags() {
      // return a list of all tags including grades, subjects, tags
      const tags = [];
      this.$Region.teacherResources.forEach((res) => {
        res.tags.forEach((_res) => {
          tags.push(_res);
        });
      });
      const uniqueTags = new Set([
        ...this.gradeTags,
        ...this.subjectTags,
        ...this.themes,
        ...tags,
      ]);
      return [...uniqueTags];
    },
    availableTags() {
      // list of tags available to be selected
      // filters out tags that are already selected
      return this.allTags.filter((tag) => !this.activeFilters.includes(tag));
    },
    tagSelected: function () {
      // return if a current add filter tag matches any in allTags
      return this.availableTags.includes(this.newFilter);
    },
  },

  watch: {
    tagSelected(newVal) {
      // update filters when a new one is selected
      if (newVal === true) {
        // update filters
        this.activeFilters.push(this.newFilter);
        // filter resource cards
        this.filterResources(this.newFilter);
        // reset filter input
        this.newFilter = "";
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    // prevent access to non-teachers
    next((vm) => {
      if (!vm.isTeacher) {
        vm.$router.push(from.path);
      }
    });
  },

  beforeMount() {
    // load specific resource if it's in the url query
    if (
      "id" in this.$route.query &&
      this.$route.query.id !== null &&
      this.$route.query.id !== ""
    ) {
      // id provided - try to find it
      const _resource = this.$Region.teacherResources.find(
        (res) => res.key === this.$route.query.id
      );
      if (_resource !== undefined) {
        this.currentResource = _resource;
      }
    }
  },

  mounted() {
    // look for themes
    this.$Region.teacherResources.forEach((res) => {
      if ('theme' in res && !this.themes.includes(res.theme)) {
        this.themes.push(res.theme);
      }
    })
  },

  methods: {
    loadResourceDetails(key) {
      // load the resource details
      this.currentResource = this.filteredResources.find((res) => res.key === key);
      this.$router.push({ query: { id: key } });
    },
    clearCurrentResource() {
      // clear the resource
      this.currentResource = null;
      // clear the router query
      const query = Object.assign({}, this.$route.query);
      delete query.id;
      this.$router.replace({ query });
    },
    filterTags(tag, term) {
      try {
        return tag
          .toLowerCase()
          .replace(" ", "")
          .replace(".", "")
          .includes(term.toLowerCase().replace(" ", "").replace(".", ""));
      } catch (err) {
        return false;
      }
    },
    getTags(searchTerm) {
      this.tagSearch = new Promise((resolve) => {
        window.setTimeout(() => {
          if (!searchTerm || searchTerm.length < 2) {
            resolve([]);
          } else {
            resolve(
              this.availableTags.filter((tag) =>
                this.filterTags(tag, searchTerm)
              )
            );
          }
        }, 500);
      });
    },
    getTagClass(tag) {
      // return the class for a given tag -- different colors for grades, subjects, general tags
      if (this.gradeTags.includes(tag)) {
        return `md-info`;
      } else if (this.subjectTags.includes(tag)) {
        return `md-success`;
      } else if (this.themes.includes(tag)) {
        return `md-primary`;
      }
      return `md-default`;
    },
    deleteFilter(tag) {
      // remove a filter from active filters
      this.activeFilters = this.activeFilters.filter((_tag) => _tag !== tag);
      // grab the updated filteredResources
      this.resetFilteredResources();
      this.activeFilters.forEach((_tag) => {
        this.filterResources(_tag);
      });
    },
    resetFilteredResources() {
      this.filteredResources = this.$Region.teacherResources;
    },
    clearAllFilters() {
      this.resetFilteredResources();
      this.activeFilters = [];
    },
    filterResources(tag) {
      // filter resources for a specific tag
      this.filteredResources = this.filteredResources.filter((resource) => {
        return (
          resource.grades.includes(tag.replace("Grade ", "")) ||
          resource.subjects.includes(tag) ||
          'theme' in resource && resource.theme === tag ||
          resource.tags.includes(tag)
        );
      });
    },
    addFilter(tag) {
      // add a filter
      if (!this.activeFilters.includes(tag)) {
        this.activeFilters.push(tag);
        this.filterResources(tag);
      }
    },
    getFilteredResourcesByTheme(theme) {
      return this.filteredResources.filter((res) => res.theme === theme);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .layout-no-padding {
  padding-right: 0;
  padding-left: 0;
}
/deep/ .tag-section {
  margin: 10px 0 0 0;
}
/deep/ .tag-section h6 {
  margin: 8px 10px 0 0;
  float: left;
}
/deep/ .tag-section .md-field {
  margin: 0 0 0 0;
  padding-top: 0;
  display: inline;
}
/deep/ .layout-badges {
  padding: 10px 0 10px 0;
}
/deep/ .layout-badges span:nth-child(2) {
  margin-left: 10px;
}
/deep/ .badge-icon {
  color: $white-color !important;
}
/deep/ div.md-chip.md-info {
  background-color: $brand-info !important;
}
/deep/ div.md-chip.md-success {
  background-color: $brand-success !important;
}
/deep/ div.md-chip.md-primary {
  background-color: $brand-primary !important;
}
/deep/ div.md-chip.md-warning {
  background-color: $brand-warning !important;
}
.main-filters {
  margin-top: 20px;
}
.clear-filter-btn {
  margin-top: 2px;
}
.theme-chip {
  margin-top: -4px;
}
</style>
