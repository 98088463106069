<template>
  <div>
    <time-line
      v-if="showMissionItems"
      plain
      type="simple"
    >
      <time-line-item
        inverted
        icon-only
        badge-type="info"
        badge-icon="work"
        :btn-action="launchMission"
      />
      
      <!-- REGION-SPECIFIC TIMELINE ITEMS -->
      <time-line-item
        v-for="(item, index) in missionItems"
        :key="index"
        inverted
        :badge-type="item.badgeType"
        :badge-icon="item.icon"
        :badge-icon-class="item.iconClass"
        :btn-action="item.helpAction"
      >
        <h3 slot="header">
          {{ item.title }}
        </h3>
        <p slot="content">
          {{ item.statusDescription }}
        </p>
        <span
          v-if="item.showPrompt"
          slot="footer"
          class="badge badge-warning"
        >{{ item.successPrompt }}</span>
      </time-line-item>
    </time-line>
    <div style="padding:100px">
      <h5 style="font-weight:bold;">
        Disclaimer:
      </h5>
      <p>The Canadian government recognizes three groups of Indigenous Peoples:  First Nations, Inuit and Metis. There are more than 630 First Nations communities representing over 50 nations and languages. Maps were obtained from www.nativelands.ca and the Metis Nation of Alberta.  Residential School locations and information are sourced from The National Center for Truth and Reconciliation. These are some stories from individual Nations and they do not represent all nations. Our cultural understanding and relationships are evolving and emerging. As such, we may make mistakes in our interpretations and we are open to feedback and redirection.</p>
    </div>
  </div>
</template>

<script>
import { TimeLine, TimeLineItem } from '@/components'
import { Gamification } from "@/pages/Dashboard/Components/mixins/Gamification";

export default {
  name: "IndigenousAchievements",
  components: {
    TimeLine,
    TimeLineItem
  },
  mixins: [Gamification], // for evaluating mission item success conditions
  props: {
    teacher: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true
    },
    usertype: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      missionItems: [...this.$Region.voices.missionItems],
      showMissionItems: false
    }
  },

  watch: {
    MISSION_UPDATE_TRIGGER() {
      this.hideMissionItems();
      this.loadMissionItems();
    }
  },

  beforeMount() {
    this.loadMissionItems();
  },

  methods: {
    hideMissionItems() {
      // to hide the timeline
      // necessary to completely remove & reload the timeline component
      // when mission item statuses are potentially changing in order to trigger the status of each TimeLineItem to update
      this.showMissionItems = false;
    },
    loadMissionItems() {
      // load/parse the mission items, eval the conditions
      // compute the status of each missionItem
      let completedCount = 0;
      this.missionItems.forEach(item => {
        const status = this.evalConditions(item.successConditions);
        if (status) {
          completedCount += 1;
        }
        item.badgeType = status ? 'success' : 'timeline-todo';
        item.statusDescription = status ? item.successMsg : item.description;
        item.showPrompt = status;
      });
      this.showMissionItems = true;
      if (completedCount === this.missionItems.length) {
        // all tasks passed
        this.getGameActivity('mission-complete');
      }
    },
    launchMission() {
      if (this.$router.currentRoute.name !== 'Indigenous Voices') {
        this.$router.push({
          name: "Indigenous Voices"
        });
      }
    },
    launchUserProfile() {
      this.$router.push({
        name: "User Profile"
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.badge-warning {
  display: inline-block;
  white-space: normal;
  font-size: 11px;
}
</style>
