<template>
  <div class="content">
    <div class="md-layout">
      <IoTSub
        topic="leaderboard"
        @on-topic-update="updateTopic"
      />
      <div class="md-layout-item md-size-100">
        <!-- SCHOOL LEADERS -->
        <md-card
          v-if="showSchoolLeaders && schoolLeaders.length"
          class="leaderboard-panel"
        >
          <md-card-header class="md-card-header-text md-card-header-primary">
            <div class="card-text">
              <h4 class="title">
                School Leaders
              </h4>
              <p class="text-white">
                {{ USER_DATA.school }}
              </p>
            </div>
          </md-card-header>
          <md-card-content>
            <slide-y-up-transition
              group
              :duration="500"
            >
              <div
                v-for="user in schoolLeaders"
                :key="user.UserName"
              >
                <leaderboard-profile-card
                  :user="user"
                  :active-user="USER_DATA.UserName === user.UserName"
                />
              </div>
            </slide-y-up-transition>
          </md-card-content>
        </md-card>
        <!-- GLOBAL LEADERS -->
        <md-card class="leaderboard-panel">
          <md-card-header class="md-card-header-text md-card-header-blue">
            <div class="card-text">
              <h4 class="title">
                Global Leaders
              </h4>
            </div>
          </md-card-header>
          <md-card-content>
            <slide-y-up-transition
              group
              :duration="500"
            >
              <div
                v-for="user in globalLeaders"
                :key="user.UserName"
              >
                <leaderboard-profile-card
                  :user="user"
                  :active-user="USER_DATA.UserName === user.UserName"
                />
              </div>
            </slide-y-up-transition>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { types } from "@/store/types";
import { SlideYUpTransition } from 'vue2-transitions';
import IoTSub from '@/components/IoTSub';
import LeaderboardProfileCard from '@/pages/Dashboard/Components/LeaderboardProfileCard';

export default {
  name: "Leaderboard",

  components: {
    SlideYUpTransition,
    IoTSub,
    LeaderboardProfileCard
  },
  props: {},

  data() {
    return {
      topics: [{ topic: 'leaderboard', msg: '' }],
      globalLeaders: [],
      globalThreshold: 0, // minimum points threshold required to update leaderboard
      schoolLeaders: [],
      schoolThreshold: 0
    };
  },

  computed: {
    ...mapGetters([
      types.getters.USER_DATA
    ]),
    showSchoolLeaders() {
      return ('school' in this.USER_DATA && this.USER_DATA.school !== null && this.USER_DATA.school !== '');
    }
  },

  mounted() {
    if (this.showSchoolLeaders) {
      this.getSchoolLeaders();
    }
    this.getGlobalLeaders();
  },

  methods: {
    updateTopic(topic, msg) {
      // update leaderboards if minimum threshold met in a points update
      if (topic === 'leaderboard' && 'threshold' in msg && msg.threshold > this.globalThreshold) {
        this.getGlobalLeaders();
      }
      if (this.showSchoolLeaders && topic === 'leaderboard' && 'threshold' in msg && msg.threshold > this.schoolThreshold) {
        this.getSchoolLeaders();
      }
    },
    remapPointsData(item) {
      // remap data from API call into leaderboard data row
      const pointsData = {};
      Object.keys(item).forEach(key => {
        if ('N' in item[key]) {
          pointsData[key] = item[key].N;
        } else {
          pointsData[key] = item[key].S;
        }
      });
      return pointsData;
    },
    getGlobalLeaders() {
      // get list of global points leaders
      this.$Auth.currentSession().then(authData => {
        const path = `/leaderboard`;
        const myInit = {
          headers: { Authorization: authData.idToken.jwtToken }
        };
        this.$API.get("api", path, myInit)
          .then(body => {
            const leaders = [];
            body.Items.forEach(item => {
              leaders.push(this.remapPointsData(item));
            });
            this.globalLeaders = leaders;
            if (this.globalLeaders.length >= 25) {
              // don't apply threshold until list is at least 25 - the LIMIT number that the API returns
              this.globalThreshold = parseInt(this.globalLeaders[this.globalLeaders.length - 1].points);
            }
          })
          .catch(error => {
            console.log(error.response) // eslint-disable-line
          })
      });
    },
    getSchoolLeaders() {
      // get list of school points leaders
      this.$Auth.currentSession().then(authData => {
        const path = `/leaderboard/school`;
        const myInit = {
          headers: { Authorization: authData.idToken.jwtToken },
          body: {
            school: this.USER_DATA.school
          }
        };
        this.$API.post("api", path, myInit)
          .then(body => {
            const leaders = [];
            body.Items.forEach(item => {
              leaders.push(this.remapPointsData(item));
            });
            this.schoolLeaders = leaders;
            if (this.schoolLeaders.length >= 25) {
              // don't apply threshold until list is at least 25 - the LIMIT number that the API returns
              this.schoolThreshold = parseInt(this.schoolLeaders[this.schoolLeaders.length - 1].points);
            }
          })
          .catch(error => {
            console.log(error.response) // eslint-disable-line
          })
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding: 20px;
}
.leaderboard-panel {
  margin-top: 60px !important;
}
.text-white {
  color: $white-color;
}
</style>
