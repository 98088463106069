/* jshint ignore: start */
// mixin for some indicator handling
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
export const Indicators = {
  data() {
    return {
      simulationHelpers: { ...this.$Region.equationHelpers, ...this.$Helpers },
    };
  },

  computed: {
    ...mapGetters([types.getters.INDICATOR_VALS]),

    showGoals() {
      // does this scenario have goals associated with it?
      if (
        ["Land Use"].includes(this.scenario.type) &&
        "goals" in this.scenario &&
        this.scenario.goals !== undefined
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    ...mapActions([types.actions.SET_INDICATOR_VAL]),

    filteredIndicators(regionIndicators) {
      // return an ordered list of indicators to be used for the loaded region
      return this.$Region.indicators // indicator definitions
        .filter((ind) =>
          this.$Region.defaultIndicators // list of default indicators
            .concat(regionIndicators) // list of region-specific indicators
            .includes(ind.name)
        )
        .sort((a, b) => a.order - b.order);
    },

    /**
     * Calculate the actual and relative value of an indicator for a specific time
     *
     * @param {string} indName - the indicator name
     * @param {object} scenario - the scenario object with simulation details like start and end time
     * @param {object} simulationState - the object with data vals to query for driver information like areas, region coefficients needed for equations
     * @param {number} [simYear=null] - the year to calculate the value for.  If none provided, simulationState.simYear will be used
     * @param {object} [indValsObj=null] - the object to lookup or update with indicator vals. If none provided, vuex store INDICATOR_VALS is used.  INDICATOR_VALS is used in general for explore whereas a separate object is passed for each scenario in a report
     * @returns
     */
    indicatorValue(
      indName,
      scenario,
      simulationState,
      simYear = null,
      indValsObj = null
    ) {
      // calculate indicator values
      // returs an array of 2 values: the relative percent value and the actual value
      if (simYear === null) {
        simYear = simulationState.simYear;
      }
      // make sure simYear is valid
      if (simYear > scenario.endTime || simYear < scenario.startTime) {
        return;
      }

      // check for values already calculated and saved in store
      const dataObj = indValsObj === null ? this.INDICATOR_VALS : indValsObj;
      if (indName in dataObj && simYear in dataObj[indName]) {
        // simulation values have been calculated already
        return dataObj[indName][simYear];
      }

      // else need to calculate values
      const equationArgs = {
        scenario: scenario,
        simulationState: simulationState,
        helpers: this.simulationHelpers, // provide region helpers and global helpers
      };

      const eqVals = this.$Region.indicators
        .find((ind) => ind.name === indName)
        .equation(equationArgs);

      if (indValsObj === null) {
        // set the value to the store for future lookup
        this.SET_INDICATOR_VAL({
          indicator: indName,
          simYear: simYear,
          val: eqVals,
        });
      } else {
        // set the value to the passed obj
        if (!(indName in indValsObj)) {
          indValsObj[indName] = {};
        }
        indValsObj[indName][simYear] = eqVals;
      }

      return eqVals;
    },
  },
};
