<template>
  <md-card class="side-panel-card layout-padding-none">
    <md-toolbar
      class="layout-gray-dark"
      md-elevation="0"
    >
      <h3 class="md-title">
        <md-icon
          :md-src="`/img/chart-pie-solid.svg`"
          class="chart-icon"
        />
        <span>Landscape Changes</span>
      </h3>
      <md-button
        class="md-icon-button"
        @click="verifyAndCloseSidebar"
      >
        <md-icon>close</md-icon>
      </md-button>
    </md-toolbar>
    <md-card-content>
      <scenario-changes
        :simulation-state="simulationState"
        @on-submit="validateAndSubmitScenario"
      />
    </md-card-content>
  </md-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import { Scenario } from "./mixins/Scenario";
import swal from "sweetalert2";
import ScenarioChanges from "./ScenarioChanges";
export default {
  name: "LandChangesDetails",

  components: {
    ScenarioChanges
  },
  mixins: [Scenario], //some common scenario saving/running functions

  props: {
    simulationState: {
      type: Object,
      required: true,
    }
  },

  data: function () {
    return {
    };
  },

  computed: {
    ...mapGetters([
      types.getters.ALLOCATIONS_UPDATED
    ]),
  },

  methods: {
    ...mapActions([
      types.actions.SET_ACTIVE_LT,
      types.actions.SET_REMOVE_ALLOCATION_ID,
      types.actions.CLOSE_SIDEBAR_CONTENT,
      types.actions.UPDATE_ACTIVE_SCENARIO,
      types.actions.ALLOCATIONS_UPDATED_REMOVE
    ]),

    verifyAndCloseSidebar() {
      // check for changes and confirm before closing
      if (this.ALLOCATIONS_UPDATED.includes(this.ACTIVE_SCENARIO.id)) {
        // changes have been made... warn user, let them run it
        swal.fire({
          title: `Close edit panel without running?`,
          text: `You have made changes to your scenario but have not run the new changes.  Would you like to run your scenario again now?`,
          icon: "warning",
          iconHtml: `<i class="fas fa-exclamation"></i>`,
          showCancelButton: true,
          customClass: {
            confirmButton: "md-button md-danger",
            cancelButton: "md-button md-success",
          },
          cancelButtonText: `Run Scenario`,
          confirmButtonText: `Close Without Running`,
          buttonsStyling: false
        }).then(result => {
          if (result.value === true) {
            // close sidebar without running
            this.closeSidebar();
          } else {
            // close the sidebar and run the simulation
            this.closeSidebar();
            this.validateAndSubmitScenario();
          }
        });
      } else {
        // no changes were made, close sidebar
        this.closeSidebar();
      }
    },
    closeSidebar() {
      // close this component/the sidebar panel
      this.SET_ACTIVE_LT(null);
      // trigger panel close
      this.CLOSE_SIDEBAR_CONTENT();
    },
    validateAndSubmitScenario() {
      // perform any validation and submit scenario for running
      // submitScenario should only be called if run button is enabled
      // it's only enabled if most requirements are met
      // but we might do some additional validation too like this..
      if (this.ACTIVE_SCENARIO.allocations.length === 0) {
        this.$notify({
          message: `You don't have any proposed landscape changes!  Please set some changes before running.`,
          icon: "error_outline",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger"
        });
      } else {
        this.submitScenario();
      }
    },
    submitScenario() {
      // update scenario and re-run
      this.UPDATE_ACTIVE_SCENARIO({ complete: false, runTime: 0 });
      this.saveScenarioUpdatesToDB(this.ACTIVE_SCENARIO, ["complete", "runTime", "allocations"], true);
      this.ALLOCATIONS_UPDATED_REMOVE(this.ACTIVE_SCENARIO.id);
    }
  }

};
</script>
<style lang="scss" scoped>
.side-panel-card {
  height: 100%;
}
.text-white {
  color: $white-color;
}

// md-elevation 0 is not working :(
.md-toolbar {
  box-shadow: none !important;
  .md-title {
    flex: 1;

    :first-child {
      margin-right: 1em;
    }

    .md-icon {
      color: white;
    }
  }
  .md-button {
    box-shadow: none !important;
    background: none !important;
  }
}
</style>