<template>
  <div class="md-card-content">
    <h5 class="category">
      Please fill in some details to save your report.
    </h5>
    <md-field
      :class="[
        {'md-valid': !errors.has('title') && touched.title},
        {'md-error': errors.has('title')}]"
    >
      <label>Title</label>
      <md-input
        v-model="title"
        v-validate="modelValidations.title"
        data-vv-name="title"
        type="text"
        name="Title"
        :disabled="formLocked"
        required
      />
      <slide-y-down-transition>
        <div v-show="errors.has('title')">
          <md-icon class="error">
            close
          </md-icon>
          <span class="error-msg">{{ errors.first('title') }}</span>
        </div>
      </slide-y-down-transition>
      <slide-y-down-transition>
        <md-icon
          v-show="!errors.has('title') && touched.title"
          class="success"
        >
          done
        </md-icon>
      </slide-y-down-transition>
    </md-field>
    <md-field
      :class="[
        {'md-valid': !errors.has('description') && touched.description},
        {'md-error': errors.has('description')}]"
    >
      <label>Description</label>
      <md-textarea
        v-model="description"
        v-validate="modelValidations.description"
        name="description"
        :disabled="formLocked"
        required
      />
      <slide-y-down-transition>
        <div v-show="errors.has('description')">
          <md-icon class="error">
            close
          </md-icon>
          <span class="error-msg">{{ errors.first('description') }}</span>
        </div>
      </slide-y-down-transition>
      <slide-y-down-transition>
        <md-icon
          v-show="!errors.has('description') && touched.description"
          class="success"
        >
          done
        </md-icon>
      </slide-y-down-transition>
      <span class="md-helper-text">Give a brief description of this report</span>
    </md-field>
    <md-field
      :class="[
        {'md-valid': !errors.has('analysis') && touched.analysis},
        {'md-error': errors.has('analysis')}]"
    >
      <label>Analysis</label>
      <md-textarea
        v-model="analysis"
        v-validate="modelValidations.analysis"
        name="analysis"
        :disabled="formLocked"
        required
      />
      <slide-y-down-transition>
        <div v-show="errors.has('analysis')">
          <md-icon class="error">
            close
          </md-icon>
          <span class="error-msg">{{ errors.first('analysis') }}</span>
        </div>
      </slide-y-down-transition>
      <slide-y-down-transition>
        <md-icon
          v-show="!errors.has('analysis') && touched.analysis"
          class="success"
        >
          done
        </md-icon>
      </slide-y-down-transition>
      <span class="md-helper-text">What are your observations and conclusions when viewing the scenarios in your report?</span>
    </md-field>
    <div class="md-layout md-alignment-bottom-space-around report-buttons">
      <div class="md-layout-item md-size-40">
        <md-button
          id="reportsBtn"
          class="md-raised md-default"
          :disabled="formLocked"
          @click="clearReport"
        >
          Reset
        </md-button>
      </div>
      <div class="md-layout-item md-size-60">
        <md-button
          id="reportsBtn"
          class="md-raised md-success align-right"
          :disabled="formLocked"
          @click="validate"
        >
          <md-icon :class="{'updating-icon': formLocked}">
            {{ saveButtonIcon }}
          </md-icon> {{ saveButtonTxt }}
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import { SlideYDownTransition } from 'vue2-transitions'
import { User } from "@/pages/Dashboard/Components/mixins/User";
import { Gamification } from "@/pages/Dashboard/Components/mixins/Gamification";
export default {
  name: "NewReport",

  components: {
    SlideYDownTransition
  },
  mixins: [User, Gamification],

  props: {
    scenarios: { // a list of scenario id's included in the report
      type: Array,
      required: true
    }
  },

  data() {
    return {
      formLocked: false,
      title: "",
      description: "",
      analysis: "",
      touched: {
        title: false,
        description: false,
        analysis: false,
      },
      modelValidations: {
        title: {
          required: true
        },
        description: {
          required: true
        },
        analysis: {
          required: true
        }
      }
    }
  },

  computed: {
    ...mapGetters([types.getters.USER_REPORTS]),

    saveButtonTxt() {
      return this.formLocked ? "Saving" : "Save Report";
    },
    saveButtonIcon() {
      return this.formLocked ? "swap_horiz" : "save";
    },
    newReportID() {
      // get a new report id
      let id = -1;
      this.USER_REPORTS.forEach(rep => {
        if (rep.id > id) {
          id = rep.id;
        }
      });
      return id + 1;
    },
    newReportUserTransaction() {
      // create a new transaction id to save to dynamo
      return `rep${this.newReportID}`;
    }
  },

  methods: {
    ...mapActions([
      types.actions.ACTIVE_REPORT_CLEAR,
      types.actions.SET_REPORT_MODE,
      types.actions.UPDATE_ACTIVE_REPORT,
      types.actions.LOAD_USER_REPORTS
    ]),

    validate() {
      return this.$validator.validateAll()
        .then(res => {
          if (res) {
            this.saveReport();
          }
          return res;
        });
    },
    clearReport() {
      // clear the report
      this.ACTIVE_REPORT_CLEAR();
    },
    saveReport() {
      // save the report to the DB via API call
      this.formLocked = true;
      const payload = {
        title: this.title,
        description: this.description,
        analysis: this.analysis,
      };
      // update active report with title, description etc
      this.UPDATE_ACTIVE_REPORT(payload);
      // more stuff for the database
      payload.UserTransaction = this.newReportUserTransaction;
      payload.scenarios = JSON.stringify(this.scenarios).replace(/"/g, "'");
      // sanitize the payload
      this.$Helpers.sanitizeDBPayload(payload);
      // Push to the DB
      this.$Auth.currentSession().then(authData => {
        const username = authData.accessToken.payload.username;
        payload.UserName = username;
        const path = "/report";
        const myInit = {
          body: payload,
          headers: { Authorization: authData.idToken.jwtToken }
        };
        this.$API
          .put("api", path, myInit)
          .then(() => {
            // update view settings
            this.SET_REPORT_MODE('view');
            // put the proper id on the active report
            const timestamp = new Date().getTime();
            this.UPDATE_ACTIVE_REPORT({ id: this.newReportID, dateCreated: timestamp });
            // update the local user reports store
            this.LOAD_USER_REPORTS({ vm: this, callbackFn: this.getReportGameActivity });
            this.formLocked = false;
            // give the user some points!
            this.addUserPoints(this.$Region.awardPoints.reportCreate);
            // analytics tracking
            const label = `${payload.title}, ${this.scenarios.length} scenarios`;
            this.$Helpers.gtagEvent(this, `create_report`, `reports`, label);
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error);
          });
      });
    },
    getReportGameActivity() {
      // check for game activity after creating report.
      // passed as a callback to LOAD_USER_REPORTS
      setTimeout(this.getGameActivity('report-create'), 3000);
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .md-helper-text {
  padding-top: 5px;
}
.report-buttons {
  padding-top: 30px;
}
.align-right {
  float: right;
}
.error-msg {
  vertical-align: text-top;
  padding-right: 30px;
  color: $brand-danger;
}
/* updating icon animation */
.updating-icon {
  animation: flip 2s infinite;
}
@keyframes flip {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
