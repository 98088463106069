<template>
  <div>
    <h5 class="info-text">
      What type of observation would you like to record?
    </h5>
    <!-- observation type selection -->
    <div class="md-layout">
      <div class="md-layout-item md-size-50 md-small-size-100">
        <icon-checkbox
          v-model="observationTypes.general"
          :disabled="false"
          icon="fas fa-binoculars"
          :title="this.$Region.observation_types['general'].title"
          @input="selectType('general')"
        />
      </div>
      <div class="md-layout-item md-size-50 md-small-size-100">
        <icon-checkbox
          v-model="observationTypes.water"
          :disabled="false"
          icon="fas fa-tint"
          :title="this.$Region.observation_types['water'].title"
          @input="selectType('water')"
        />
      </div>
    </div>
    <!-- date, title and description -->
    <div class="md-layout-item md-size-100">
      <md-datepicker
        v-model="observedTime"
        type="text"
        name="observedTime"
        :disabled="wizardLocked"
        md-immediately
      >
        <label>Observation Date</label>
      </md-datepicker>
    </div>
    <div class="md-layout-item md-size-100">
      <md-field
        :class="[
          { 'md-valid': !errors.has('title') && touched.title },
          { 'md-error': errors.has('title') },
        ]"
      >
        <label>Title</label>
        <md-input
          v-model="title"
          v-validate="modelValidations.title"
          data-vv-name="title"
          type="text"
          name="Title"
          :disabled="wizardLocked"
          required
        />
        <slide-y-down-transition>
          <md-icon
            v-show="errors.has('title')"
            class="error"
          >
            close
          </md-icon>
        </slide-y-down-transition>
        <slide-y-down-transition>
          <md-icon
            v-show="!errors.has('title') && touched.title"
            class="success"
          >
            done
          </md-icon>
        </slide-y-down-transition>
      </md-field>
    </div>
    <div class="md-layout-item md-size-100">
      <md-field
        :class="[
          { 'md-valid': !errors.has('description') && touched.description },
          { 'md-error': errors.has('description') },
        ]"
      >
        <label>Description</label>
        <md-textarea
          v-model="description"
          v-validate="modelValidations.description"
          name="description"
          :disabled="wizardLocked"
          required
        />
        <slide-y-down-transition>
          <md-icon
            v-show="errors.has('description')"
            class="error"
          >
            close
          </md-icon>
        </slide-y-down-transition>
        <slide-y-down-transition>
          <md-icon
            v-show="!errors.has('description') && touched.description"
            class="success"
          >
            done
          </md-icon>
        </slide-y-down-transition>
      </md-field>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import { IconCheckbox } from "@/components";
import { SlideYDownTransition } from "vue2-transitions";
import { Observation } from "./mixins/Observation";
export default {
  name: "ObservationNewInfo",

  components: {
    IconCheckbox,
    SlideYDownTransition,
  },
  mixins: [Observation],

  props: {
    wizardLocked: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    // eslint-disable-next-line
    let now = new Date().now;
    return {
      observationTypes: {
        general: true,
        water: false,
      },
      selectedObservationType: "general",
      title: "",
      description: "",
      observedTime: now,
      touched: {
        title: false,
        description: false,
        observedTime: false,
      },
      modelValidations: {
        title: {
          required: true,
        },
        description: {
          required: true,
        },
        observedTime: {
          required: true,
        },
      },
    };
  },

  computed: {
    ...mapGetters([types.getters.SELECTED_OBSERVATION_TYPE]),
  },

  watch: {
    title() {
      this.touched.title = true;
    },
    description() {
      this.touched.description = true;
    },
  },

  beforeMount() {
    const newType = ["general", "water"].includes(
      this.SELECTED_OBSERVATION_TYPE
    )
      ? this.SELECTED_OBSERVATION_TYPE
      : "general";
    this.selectType(newType);
  },

  methods: {
    ...mapActions([types.actions.SET_SELECTED_OBSERVATION_TYPE]),

    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      // some required field validations
      if (this.observedTime === null) {
        return new Promise((resolve) => {
          this.errorAlert("Please enter a title");
          resolve(false);
        });
      } else if (this.description.trim().length < 25) {
        return new Promise((resolve) => {
          this.errorAlert(
            "Please provide at least 25 characters describing your observation"
          );
          resolve(false);
        });
      }
      if (this.title === null || this.title.trim() === "") {
        return new Promise((resolve) => {
          this.errorAlert("Please enter observation date");
          resolve(false);
        });
      }

      return new Promise((resolve) => {
        resolve(true);
      }).then((res) => {
        this.onSuccess(res);
        return res;
      });
    },
    onSuccess(res) {
      // update data in parent
      const _data = {
        type: this.selectedObservationType,
        title: this.title,
        description: this.description,
        observedTime: Number(this.observedTime),
      };

      // Increment the wizard;
      this.$emit("on-validated", res, _data);
    },
    selectType(type) {
      // identifying observation type on user click
      Object.keys(this.observationTypes).forEach((obsType) => {
        this.observationTypes[obsType] = false;
      });
      this.observationTypes[type] = true;
      this.selectedObservationType = type;
      this.SET_SELECTED_OBSERVATION_TYPE(type); // trigger marker change on map
    },
  },
};
</script>
<style lang="scss" scoped>
</style>