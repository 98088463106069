<template>
  <div class="md-layout">
    <div
      id="achievements"
      class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50"
    >
      <!--Achievements-->
      <dashboard-achievements
        :teacher="USER_DATA.teacher"
        :usertype="USER_DATA.usertype"
      />
    </div>

    <div class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-50">
      <div class="md-layout">
        <div
          id="scenarios"
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
        >
          <!--Scenarios-->
          <dashboard-scenarios
            :scenarios="scenarios"
            @edit-scenario="editScenario"
            @explore-scenario="exploreScenario"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { types } from "@/store/types";
import { scroller } from 'vue-scrollto/src/scrollTo'
import DashboardScenarios from "./Components/DashboardScenarios";
import DashboardAchievements from "./Components/DashboardAchievements";
import { DashboardScenariosMixin } from "@/pages/Dashboard/Components/mixins/DashboardScenariosMixin";
// import DashboardReports from "./Components/DashboardReports";
// import Messages from "./Components/Messages";

export default {

  components: {
    // Messages,
    // DashboardReports,
    DashboardAchievements,
    DashboardScenarios
  },
  mixins: [DashboardScenariosMixin],

  props: {
    scrollLocation: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      reports: [],
      messages: [
        // {
        //     id: 4,
        //     time: '11/28/18 6:42pm',
        //     sender: 'Barack Obama',
        //     content: "Hi BC Tomorrow, I just heard about the good work you're doing.",
        //     reply: false
        // },
        // {
        //     id: 3,
        //     time: '11/29/18 8:31am',
        //     sender: 'Barack Obama',
        //     content: "Thanks Barack! Good to hear from you.",
        //     reply: true
        // },
        // {
        //     id: 2,
        //     time: '11/29/18 6:42pm',
        //     sender: 'Barack Obama',
        //     content: "Remember, if you need anything- anything at all. Just shout out.",
        //     reply: false
        // },
        // {
        //     id: 1,
        //     time: '10/28/85 2:00am',
        //     sender: 'Ghandi',
        //     content: "Seek to build yourself first and then you will go on to build great things.",
        //     reply: false
        // }
      ]
    }
  },
  computed: {
    ...mapGetters([
      // types.getters.USER_SCENARIOS,
      types.getters.USER_DATA,
      types.getters.OBSERVATIONS_ALL_USER
    ]),
    // scenarios() { return this.USER_SCENARIOS }
  },

  mounted() {
    if (this.scrollLocation) {
      const scroll = scroller();
      scroll('#' + this.scrollLocation)
    }
  },

  methods: {
    // editScenario(id) {
    //   this.$router.push({ name: 'New Scenario', params: { id: id, targetTab: "Scenario" } })
    // },
    // exploreScenario() {
    //   this.$router.push({ name: 'Explore' })
    // },
    // getScenarioByID(id) {
    //   let returnScen;
    //   this.USER_SCENARIOS.forEach(scen => {
    //     if (scen.id === id) {
    //       returnScen = scen
    //     }
    //   });
    //   if (!returnScen) {
    //     throw "Could not find scenario " + id.toString()
    //   }
    //   return returnScen
    // }
  }
}
</script>
