<template>
  <div
    ref="container"
    :class="[$style.sidebar,
             {'sidebar-large': !rightSidebarMedium},
             {'sidebar-medium': rightSidebarMedium && !rightSidebarFullScreen},
             { noScroll: SIDEBAR_CONTENT_OPEN },
             { 'sidebar-mobile': (rightSidebarFullScreen && EXPLORE_MODE !== 'explore') },
             { 'sidebar-mobile-no-slider': (rightSidebarFullScreen && EXPLORE_MODE === 'explore') },
             { 'sidebar-light-bg': EXPLORE_MODE === 'create' }]"
  >
    <div class="md-layout-item md-layout">
      <template v-if="showScenarioData && !showAltIndicatorCollection">
        <cover-indicator-card
          :scenario="scenario"
          :simulation-progress="simulationProgress"
          :simulation-state="simulationState"
          :region-indicators="regionIndicators"
          :indicator-type="'Environmental'"
          class="md-layout-item"
          :class="[{'md-size-50': !sidebarWidePanels}, {'md-size-100': sidebarWidePanels}]"
        />
        <cover-indicator-card
          :scenario="scenario"
          :simulation-progress="simulationProgress"
          :simulation-state="simulationState"
          :region-indicators="regionIndicators"
          :indicator-type="'Socio-Economic'"
          class="md-layout-item"
          :class="[{'md-size-50': !sidebarWidePanels}, {'md-size-100': sidebarWidePanels}]"
        />
      </template>
      <template v-else-if="showScenarioData && showAltIndicatorCollection">
        <!-- indigenous / alternate indicator collection -->
        <cover-indicator-card
          :scenario="scenario"
          :simulation-progress="simulationProgress"
          :simulation-state="simulationState"
          :region-indicators="altIndicators"
          :indicator-type="scenario.indicatorCollection"
          class="md-layout-item md-size-100"
        />
      </template>
    </div>
    <div class="md-layout-item md-layout">
      <landscape-summary-panel
        v-if="showScenarioData"
        :scenario="scenario"
        :simulation-state="simulationState"
        :year="year"
        :min-year="minYear"
        :max-year="maxYear"
        class="md-layout-item"
        :class="[{'md-size-50': !sidebarWidePanels}, {'md-size-100': sidebarWidePanels}]"
        @onDevRateChange="onDevRateChange"
      />
      <climate-panel
        v-if="showScenarioData"
        :scenario="scenario"
        :climate-ind-data="climateIndData"
        :climate-inds-ready="climateIndsReady"
        class="md-layout-item"
        :class="[{'md-size-50': !sidebarWidePanels}, {'md-size-100': sidebarWidePanels}]"
        @onViewClimatePanel="onViewClimatePanel"
      />
    </div>
    <div
      v-if="EXPLORE_MODE === 'create'"
      class="sidebar-content-container"
      :class="[{'sidebar-large': !sidebarWidePanels},
               {'sidebar-medium': sidebarWidePanels},
               { 'sidebar-mobile': rightSidebarFullScreen },
               { 'md-layout-item': !rightSidebarFullScreen },
               { 'md-layout': !rightSidebarFullScreen},
      ]"
    >
      <scenario-wizard
        :region-indicators-ready="regionIndicatorsReady"
        :region-indicators="regionIndicators"
        :bitmaps-ready="bitmapsReady"
        :simulation-state="simulationState"
        :class="[{ 'md-layout-item': !rightSidebarFullScreen },
                 { 'md-size-100': !rightSidebarFullScreen}]"
      />
    </div>
    <transition name="slide">
      <div
        v-if="SIDEBAR_CONTENT_OPEN"
        class="md-layout-item md-layout sidebar-content-container"
        :class="[{'sidebar-large': !sidebarWidePanels}, {'sidebar-medium': sidebarWidePanels}, { 'sidebar-mobile': (rightSidebarFullScreen && EXPLORE_MODE !== 'explore') }, { 'sidebar-mobile-no-slider': (rightSidebarFullScreen && EXPLORE_MODE === 'explore') }]"
      >
        <indicator-details
          v-if="SIDEBAR_CONTENT_TYPE === 'indicator'"
          :indicator="ACTIVE_INDICATOR"
          :scenario="scenario"
          :simulation-progress="simulationProgress"
          :simulation-state="simulationState"
        />
        <land-type-details
          v-else-if="SIDEBAR_CONTENT_TYPE === 'landtype'"
          :land-type="ACTIVE_LT"
          :simulation-state="simulationState"
        />
        <land-changes-details
          v-else-if="SIDEBAR_CONTENT_TYPE === 'changes'"
          :simulation-state="simulationState"
        />
        <mgmt-practices-details
          v-else-if="SIDEBAR_CONTENT_TYPE === 'mgmt'"
          :scenario="scenario"
          :simulation-state="simulationState"
          :scroll-practices="false"
        />
        <climate-details
          v-else-if="SIDEBAR_CONTENT_TYPE === 'climate'"
          :scenario="scenario"
        />
        <observation-details v-else-if="SIDEBAR_CONTENT_TYPE === 'observation'" />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { types } from "@/store/types";
import CoverIndicatorCard from '../Components/CoverIndicatorCard';
import LandscapeSummaryPanel from "../Components/LandscapeSummaryPanel";
import ClimatePanel from "../Components/ClimatePanel";
import IndicatorDetails from "../Components/IndicatorDetails";
import LandTypeDetails from "../Components/LandTypeDetails";
import ScenarioWizard from "../Components/ScenarioWizard";
import LandChangesDetails from "../Components/LandChangesDetails";
import MgmtPracticesDetails from "../Components/MgmtPracticesDetails";
import ClimateDetails from "../Components/ClimateDetails";
import ObservationDetails from "../Components/ObservationDetails";
import { ResponsiveLayoutHelpers } from "@/pages/Dashboard/Components/mixins/ResponsiveLayoutHelpers";

export default {
  name: "RightSidebar",
  components: {
    CoverIndicatorCard,
    LandscapeSummaryPanel,
    ClimatePanel,
    IndicatorDetails,
    LandTypeDetails,
    ScenarioWizard,
    LandChangesDetails,
    MgmtPracticesDetails,
    ClimateDetails,
    ObservationDetails
  },
  mixins: [ResponsiveLayoutHelpers],
  props: {
    scenario: {
      type: Object,
      default: function () {
        return {};
      },
    },
    climateIndData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    year: {
      type: Number,
      required: true
    },
    minYear: {
      type: Number,
      required: true
    },
    maxYear: {
      type: Number,
      required: true
    },
    simulationProgress: {
      type: Number,
      default: 0,
    },
    simulationState: {
      type: Object,
      required: true
    },
    regionIndicators: {
      type: Array,
      required: true
    },
    regionIndicatorsReady: {
      type: Boolean,
      default: false
    },
    bitmapsReady: {
      type: Boolean,
      default: false
    },
    climateIndsReady: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      types.getters.EXPLORE_MODE,
      types.getters.ACTIVE_SCENARIO_WIZARD_TAB,
      types.getters.SHOW_SIDEBAR_CONTAINER,
      types.getters.SIDEBAR_CONTENT_OPEN,
      types.getters.SIDEBAR_CONTENT_TYPE,
      types.getters.ACTIVE_INDICATOR,
      types.getters.ACTIVE_LT,
      types.getters.SELECTED_OBSERVATION
    ]),
    open() {
      return this.SHOW_SIDEBAR_CONTAINER;
    },
    showScenarioData() {
      // whether or not to display the scenario data including indicator panels, landuse summary, climate
      return (this.bitmapsReady && this.regionIndicatorsReady && this.EXPLORE_MODE !== 'create');
    },
    showAltIndicatorCollection() {
      // whether or not to display the alternative indicator collection
      return (this.showScenarioData && 'indicatorCollection' in this.scenario && this.scenario.indicatorCollection !== 'standard');
    },
    altIndicators() {
      // get the alternative indicator collection
      if ('getAltIndicators' in this.$Region) {
        return this.$Region.getAltIndicators(this.scenario.indicatorCollection);
      }
      return [];
    },
  },

  watch: {
    open() {
      this.toggle();
    },
    SIDEBAR_CONTENT_OPEN() {
      const open = this.SIDEBAR_CONTENT_OPEN
      const { container } = this.$refs

      if (open) {
        // scroll to top, so the embedded side nav appears on top
        container.scrollTop = 0;
      }
    }
  },

  mounted() {
    this.toggle()
  },

  methods: {
    toggle() {
      const dX = this.open ? 0 : -1 * this.$el.offsetWidth;
      this.$el.style.marginRight = `${dX}px`;
    },
    onDevRateChange() {
      // notify of dev rate change
      this.$emit('onDevRateChange');
    },
    onViewClimatePanel() {
      // notify user views climate panel
      this.$emit('onViewClimatePanel');
    }
  }
};
</script>

<style lang="scss" module>
.sidebar {
  position: relative;
  background: $gray-darker;
  margin-right: 0;
  z-index: 4;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
<style lang="scss" scoped>
.sidebar-light-bg {
  background: $gray-lighter !important;
}
.sidebar-large {
  width: 600px;
}
.sidebar-medium {
  width: 400px;
}
.sidebar-mobile {
  height: calc(100% - 142px);
  width: 100%;
  max-width: 100%;
}
.sidebar-mobile-no-slider {
  height: 100%;
  width: 100%;
  max-width: 100%;
}
/* SIDEBAR-CONTENT CONTAINER */
.sidebar-content-container {
  position: absolute;
  top: 0;
  height: 100%;
  background: $gray-lighter;
  margin-right: 0;
  z-index: 3;
  padding: 0 !important;
}
.noScroll {
  overflow: hidden;
}
/* sidebar-content-container transitions */
.slide-enter-active {
  transition: 0.4s ease-out;
  overflow: hidden;
}
.slide-leave-active {
  transition: 0.4s ease-in;
  overflow: hidden;
}
.slide-enter,
.slide-leave-to {
  transform: translate(100%, 0);
  overflow: hidden;
}
.slide-leave {
  transform: translate(-100%, 0);
  overflow: hidden;
}
/* PANELS AND LAYOUT ITEMS ON RIGHT SIDEBAR */
/deep/ .layout-padding-none {
  padding: 0;
}
/deep/ .layout-padding-small {
  margin: 0;
  padding: 12px 20px 8px 20px;
}
/deep/ .layout-gray-mid-dark {
  background: $grey-800;
  color: $gray-lighter;
}
/deep/ .layout-gray {
  background: $gray;
  color: $gray-lighter;
}
/deep/ .layout-gray-dark {
  background: $gray-dark !important;
  color: $gray-lighter;
}
/deep/ .layout-gray-darker {
  background: $gray-darker;
  color: $gray-lighter;
}
/deep/ .md-title {
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  margin: 0;
}
/deep/ .side-panel-card {
  margin: 0;
  padding: 5px;
  background-color: $gray-darker !important;
  border-radius: 0;
}
/deep/ .md-card-header:first-child > .md-title:first-child {
  padding: 0;
  margin: 0;
}
/deep/ .md-title > i {
  margin: 0 5px 0 0;
}
/deep/ .md-card-header:first-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
/deep/ .md-card-header {
  min-height: 20px;
}
/deep/ .md-card-content {
  height: 100%;
  overflow: auto;
}
/deep/ .md-tabs-navigation {
  margin-top: 0px;
  padding: 0 !important;
}

.text-white {
  color: $white-color;
}
</style>