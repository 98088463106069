<template>
  <md-card class="side-panel-card layout-padding-none">
    <md-toolbar class="layout-gray-dark layout-padding-small">
      <h3 class="md-title">
        <md-icon
          :md-src="`/img/tasks-solid.svg`"
          class="chart-icon"
        />
        {{ generalTitle }}
      </h3>
      <md-button
        class="md-icon-button md-mini"
        @click="closeSidebar"
      >
        <md-icon>close</md-icon>
      </md-button>
    </md-toolbar>
    <md-card-content>
      <scenario-practices
        :scenario="scenario"
        :simulation-state="simulationState"
        :region-indicators-ready="true"
        :region-dev-rate="regionDevRate"
        :show-title="false"
        :scroll-practices="scrollPractices"
      />
    </md-card-content>
  </md-card>
</template>

<script>
import { mapActions } from "vuex";
import { types } from "@/store/types";
import ScenarioPractices from "./ScenarioPractices";
export default {
  name: "MgmtPracticesDetails",

  components: {
    ScenarioPractices
  },

  props: {
    scenario: {
      type: Object,
      default: function () {
        return {};
      },
    },
    simulationState: {
      type: Object,
      required: true,
    },
    scrollPractices: {
      type: Boolean,
      default: true
    }
  },

  data: function () {
    return {
    };
  },

  computed: {
    generalTitle() {
      return ('title' in this.$Region.mgmtInfo && this.$Region.mgmtInfo.title !== '') ? this.$Region.mgmtInfo.title : 'Management Practices'
    },
    regionDevRate() {
      // todo load historic, current, future dev rates and interpolate during playback
      return this.simulationState.regionData.currentValues.devRate;
    }
  },

  methods: {
    ...mapActions([
      types.actions.CLOSE_SIDEBAR_CONTENT,
    ]),

    closeSidebar() {
      // trigger panel close
      this.CLOSE_SIDEBAR_CONTENT();
    }
  }

};
</script>
<style lang="scss" scoped>
.side-panel-card {
  height: 100%;
}
.text-white {
  color: $white-color;
}
.md-toolbar {
  box-shadow: none !important;
  .md-title {
    flex: 1;

    :first-child {
      margin-right: 1em;
    }

    .md-icon {
      color: white;
    }
  }
  .md-button {
    box-shadow: none !important;
    background: none !important;
  }
}
</style>