<template>
  <div>
    <slot name="title" />
    <a
      class="ha-info"
      @click="toggleHaDialog()"
    >
      <slot>
        Changes are measured in ha
        <i class="fas fa-question-circle" />
        <md-tooltip md-direction="bottom">How big is a ha?</md-tooltip>
      </slot>
    </a>
    <md-dialog
      class="md-dialog md-alignment-centered"
      :md-active.sync="isOpen"
    >
      <div class="md-layout">
        <div class="md-layout-item md-size-100 md-layout md-alignment-center-center">
          <span class="md-layout-item md-size-100 dialog-title">
            <i class="fas fa-question-circle fa-4x" />
            <h3 class="md-layout-item md-size-100">
              How big is a hectare (ha)?
            </h3>
          </span>
          <p class="md-layout-item md-size-100">
            A hectare is a common unit of measurement used in land-use analysis and planning.  But exactly how big is a hectare?
          </p>
          <p class="md-layout-item md-size-100">
            <strong>1 ha = 10,000 m²</strong>
            <br>
            <img
              src="/img/visualize_1ha.png"
              class="ha-visualize"
            >
        &nbsp;
            <br>
            1 ha is equivalent to a square whose sides are 100m in length.
          </p>
          <p class="md-layout-item md-size-100">
            <img
              src="/img/visualize_athletics_track.png"
              class="ha-visualize"
            >
        &nbsp;
            <br>
            The infield on a standard sized 400m athletics track is 1.12 ha.
          </p>
          <p class="md-layout-item md-size-100">
            <img
              src="/img/visualize_soccer_pitch.png"
              class="ha-visualize"
            >
            An international soccer field is 0.62 ha to 0.82 ha.
          </p>
          <p class="md-layout-item md-size-100">
            <strong>100 ha = 1 km²</strong>
            <br>
            <img
              src="/img/visualize_100ha.png"
              class="ha-visualize"
            >
          </p>
          <p class="md-layout-item md-size-100">
            <img
              src="/img/visualize_cell.png"
              class="ha-visualize"
            >
        &nbsp;
            <br>
            The cells in {{ $Region.app_name }} are 100 ha.
          </p>
          <p class="md-layout-item md-size-100">
            <strong>1k ha = 1000 ha = 10 km²</strong>
            <br>
            <i class="fas fa-plane fa-4x ha-visualize" />
            When making land use plans, you might see areas measured in thousands of hectares (k ha).  International airports in the province's main cities are in the range of 1k ha.
          </p>
        </div>
      </div>
      <div class="md-layout-item md-size-100 md-layout md-alignment-center-center close-whatsaha">
        <md-button
          class="md-raised md-success"
          @click="toggleHaDialog()"
        >
          Ok
        </md-button>
      </div>
    </md-dialog>
  </div>
</template>

<script>
export default {
  name: "WhatsAHa",

  components: {},

  props: {
  },

  data() {
    return {
      isOpen: false
    }
  },

  computed: {
  },

  methods: {
    toggleHaDialog() {
      this.isOpen = !this.isOpen;
    }
  },
}
</script>

<style lang="scss" scoped>
.dialog-title i {
  color: $brand-info;
  margin-top: 30px;
  margin-bottom: 20px;
  display: block;
  text-align: center;
}
.dialog-title h3 {
  text-align: center;
  font-weight: 600;
}
.ha-info {
  cursor: pointer;
}
.ha-visualize {
  float: left;
  padding-right: 10px;
}
i.ha-visualize {
  color: $brand-info;
  transform: translate(0, 10px);
  padding-bottom: 50px;
  padding-right: 20px;
}
/deep/ .close-whatsaha {
  padding-bottom: 30px;
}
</style>
