// state store for observation related information
import { types } from "../types";
// collect observation types from the region config
const Region = require(`../../${process.env.VUE_APP_CONFIG_ROOT}region-config.js`).default;
// replace 'water' and 'general' with 'observations' in the observation types
const observationTypes = Region.observation_types_alt;

const emptyFeatureCollection = {
  type: "FeatureCollection",
  features: []
};

// initial state
const state = {
  observationsLastLoaded: null, // timestamp last time were loaded from the api ... have not been loaded if null
  allObservationsJson: {
    // geojson object to store all observations
    observations: JSON.parse(JSON.stringify(emptyFeatureCollection)), // 'user' observations contain all general and water observations
  },
  allUserObservations: [], // list of all the specific user's observations
  hiddenObservation: null, // to store a hidden observation that is temporarily removed from the map observations source
  featureToLoad: null, // to store a feature that is pending being activated - this can be used to make an observation load when selecting it from outside of covermap.vue
  selectedObservation: null, // currently selected observation
  selectedObservationType: "general", // the type of the currently selected observation - track this so we don't have to watch deep the selected observation to detect change in type
  showObservations: observationTypes.reduce((acc, type) => ({ ...acc, [type]: true }), {}), // show the observations on the map - a list of currently active observation types
  prevShowObservations: true, // track the previous state of showing observations so we can load it again when exiting create mode
  showVirtours: true, // show the virtual tours on the map
  refreshObservationData: null, // to trigger map to reload observations source when needed
};

// getters
const getters = {
  [types.getters.OBSERVATIONS_LAST_LOADED]: (state) =>
    state.observationsLastLoaded,
  [types.getters.OBSERVATIONS_ALL_JSON]: (state) => state.allObservationsJson,
  [types.getters.OBSERVATIONS_ALL_USER]: (state) => state.allUserObservations,
  [types.getters.HIDDEN_OBSERVATION]: (state) => state.hiddenObservation,
  [types.getters.OBSERVATION_FEATURE_TO_LOAD]: (state) => state.featureToLoad,
  [types.getters.SELECTED_OBSERVATION]: (state) => state.selectedObservation,
  [types.getters.SELECTED_OBSERVATION_TYPE]: (state) =>
    state.selectedObservationType,
  [types.getters.SHOW_OBSERVATIONS]: (state) => state.showObservations,
  [types.getters.PREV_SHOW_OBSERVATIONS]: (state) => state.prevShowObservations,
  [types.getters.SHOW_VIRTOURS]: (state) => state.showVirtours,
  [types.getters.REFRESH_OBSERVATION_DATA]: (state) =>
    state.refreshObservationData,
};

// mutations
const mutations = {
  [types.mutations.SET_OBSERVATIONS_LAST_LOADED](state) {
    // set the time when observations were loaded from the api
    state.observationsLastLoaded = Date.now();
  },
  [types.mutations.OBSERVATIONS_ALL_FEATURES_SPLICE](state, payload) {
    // add or remove a feature from the observations via splice...
    if (['water', 'general'].includes(payload.type)) {
      // water and general are mapped to the observations type
      payload.type = 'observations';
    }
    // if an observation object is provided, add a feature, else remove a feature
    if ("feature" in payload) {
      // add the feature to the allObservationsJson features array
      state.allObservationsJson[payload.type].features.splice(
        payload.index,
        0,
        payload.feature
      );
    } else {
      // remove the feature from the allObservationsJson features array
      state.allObservationsJson[payload.type].features.splice(payload.index, 1);
    }
  },
  [types.mutations.OBSERVATIONS_PUSH](state, payload) {
    // add an observation geom to the allObservationsJson features array
    // if the type does not exist, create it
    if (!(payload.type in state.allObservationsJson)) {
      state.allObservationsJson[payload.type] = JSON.parse(
        JSON.stringify(emptyFeatureCollection)
      );
    }
    // add the observation to the features array
    state.allObservationsJson[payload.type].features.push(payload.obsData);
  },
  [types.mutations.USER_OBSERVATIONS_REMOVE](state, id) {
    // remove an observation from the OBSERVATIONS_ALL_USER array
    const obsIndex = state.allUserObservations.findIndex(
      (obs) => obs.id === id
    );
    state.allUserObservations.splice(obsIndex, 1);
  },
  [types.mutations.OBSERVATIONS_CLEAR](state, obsTypes) {
    // clear the allObservationsJson features array for each type
    obsTypes.forEach((key) => {
      if (key in state.allObservationsJson && "features" in state.allObservationsJson[key]) {
        state.allObservationsJson[key].features = [];
      }
    });
  },
  [types.mutations.OBSERVATIONS_USER_PUSH](state, _obs) {
    // add an observation geom to the allObservationsJson features array
    state.allUserObservations.push(_obs);
  },
  [types.mutations.OBSERVATIONS_USER_CLEAR](state) {
    // clear the allObservationsJson features array
    state.allUserObservations = [];
  },
  [types.mutations.SET_HIDDEN_OBSERVATION](state, obs) {
    // set the current observation to the store
    state.hiddenObservation = obs;
  },
  [types.mutations.SET_OBSERVATION_FEATURE_TO_LOAD](state, feat) {
    // set the current feature to load to the store
    state.featureToLoad = feat;
  },
  [types.mutations.SET_SELECTED_OBSERVATION](state, obs) {
    // set the current observation to the store
    state.selectedObservation = obs;
  },
  [types.mutations.UPDATE_SELECTED_OBSERVATION](state, payload) {
    // update keys in selectedObservation - update all keys provided in payload
    Object.keys(payload).forEach((key) => {
      state.selectedObservation[key] = JSON.parse(JSON.stringify(payload[key]));
    });
  },
  [types.mutations.SET_SELECTED_OBSERVATION_TYPE](state, obsType) {
    // set the current observation to the store
    state.selectedObservationType = obsType;
  },
  [types.mutations.SHOW_OBSERVATIONS_ON](state, obsType) {
    state.showObservations[obsType] = true;
  },
  [types.mutations.SHOW_OBSERVATIONS_OFF](state, obsType) {
    state.showObservations[obsType] = false;
  },
  [types.mutations.SET_PREV_SHOW_OBSERVATIONS](state, status) {
    // track the previous show observations state
    state.prevShowObservations = [...status];
  },
  [types.mutations.TOGGLE_SHOW_VIRTOURS](state) {
    state.showVirtours = !state.showVirtours;
  },
  [types.mutations.TOGGLE_REFRESH_OBSERVATION_DATA](state) {
    state.refreshObservationData = !state.refreshObservationData;
  },
};

// actions
const actions = {
  [types.actions.SET_OBSERVATIONS_LAST_LOADED]({ commit }) {
    // set timestamp for observationsLastLoaded
    commit(types.mutations.SET_OBSERVATIONS_LAST_LOADED);
  },
  [types.actions.OBSERVATIONS_LOAD_ALL]({ commit }, payload) {
    // load all observations from the database via api call
    // populates a geojson object for use on the map
    // payload expects a vue model as a {vm: this} ... this should be called from a model like so: this.LOAD_USER_SCENARIOS({vm: this})
    // relies on loading global options

    const remapObsData = function (item) {
      // parse observation data from dynamo into geojson format
      const obs = {
        // individual feature object
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(item.coordinates.S.split(",")[0]),
            parseFloat(item.coordinates.S.split(",")[1]),
          ],
        },
        properties: {
          UserName: item.UserName.S,
          UserTransaction: item.UserTransaction.S,
          type: item.type.S,
          icon: `obs${item.type.S}`,
        },
      };

      return obs;
    };

    // first clear the existing features
    commit(types.mutations.OBSERVATIONS_CLEAR, ["observations"]);

    let id = 0; // add an id so we can easily remove/re-add observations to the json to hide/show icons
    // get all of the system observations
    const authLib = payload.vm.$Auth;
    const apiLib = payload.vm.$API;

    // API Call
    authLib
      .currentSession()
      .then((authData) => {
        const myInit = {
          headers: { Authorization: authData.idToken.jwtToken },
        };

        apiLib
          .get("api", "/observation", myInit)
          .then((body) => {
            // Parse from DynamoDB
            id = 0; // reset the id for each type
            body.Items.forEach((item) => {
              const obsData = remapObsData(item);
              obsData.id = id;
              id += 1;
              const _payload = {
                type: 'observations',
                obsData: obsData,
              }
              // add it to features in OBSERVATIONS_ALL_JSON
              commit(types.mutations.OBSERVATIONS_PUSH, _payload);
            });
          })
          // eslint-disable-next-line
          .then((res) => {
            commit(types.mutations.SET_OBSERVATIONS_LAST_LOADED); // stamp the lastloaded time
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error.response);
          });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  },
  [types.actions.OBSERVATIONS_LOAD_ALL_USER]({ commit }, payload) {
    // get all this user's observations via api call
    // payload expects a vue model, username, transaction
    // {vm: this, UserName: username, UserTransaction: usertransaction} ... this should be called from a model like so: this.LOAD_USER_SCENARIOS({vm: this...})
    // relies on loading global options via the vm

    // first clear the existing list
    commit(types.mutations.OBSERVATIONS_USER_CLEAR);

    const authLib = payload.vm.$Auth;
    const apiLib = payload.vm.$API;

    // API Call
    authLib
      .currentSession()
      .then((authData) => {
        const myInit = {
          headers: { Authorization: authData.idToken.jwtToken },
        };
        const username = authData.accessToken.payload.username;

        apiLib
          .get("api", `/observation/${username}`, myInit)
          .then((body) => {
            // Parse from DynamoDB
            let id = 0; // add an id so we can easily remove/re-add observations to the json to hide/show icons
            body.Items.forEach((item) => {
              const obsData = payload.vm.$Helpers.remapObsProperties(item);
              obsData.id = id;
              id += 1;
              // add it to features in OBSERVATIONS_ALL_JSON
              commit(types.mutations.OBSERVATIONS_USER_PUSH, obsData);
              // trigger potential mission achievement updates
              commit(types.mutations.INCREMENT_MISSION_UPDATE_TRIGGER);
            });
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error.response);
          });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  },
  [types.actions.OBSERVATIONS_LOAD_ALL_SYSTEM]({ commit }, payload) {
    // load all observations from the database via api call
    // populates a geojson object for use on the map
    // payload expects a vue model as a {vm: this} ... this should be called from a model like so: this.LOAD_USER_SCENARIOS({vm: this})
    // relies on loading global options

    const remapSystemObsData = function (item) {
      // parse observation data from dynamo into geojson format
      const obs = {
        // individual feature object
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: item.coordinates,
        },
        properties: {
          // type: "system",
          // icon: "obssystem",
          type: "subtype" in item ? item.subtype : "system",
          icon: "subtype" in item ? `obs${item.subtype}` : "obssystem",
          id: item.id,
        },
      };

      return obs;
    };

    // get list of system obs types
    const obsTypes = Object.keys(payload.vm.$Region.observation_types).filter(_key => !['water', 'general'].includes(_key));
    // first clear the existing features
    commit(types.mutations.OBSERVATIONS_CLEAR, obsTypes);

    let id = 0; // add an id so we can easily remove/re-add observations to the json to hide/show icons
    // get all of the system observations
    obsTypes.forEach((type) => {
      const obsCollection = type === 'system'
        ? payload.vm.$Region.system_observations.filter((obs) => !('subtype' in obs && obs.subtype !== 'system'))
        : payload.vm.$Region.system_observations.filter((obs) => ('subtype' in obs && obs.subtype === type));
      id = 0; // reset the id for each type
      obsCollection.forEach((item) => {
        const obsData = remapSystemObsData(item);
        obsData.id = id;
        id += 1;
        const _payload = {
          type: type,
          obsData: obsData,
        }
        // add it to features in OBSERVATIONS_ALL_JSON
        commit(types.mutations.OBSERVATIONS_PUSH, _payload);
      });
    });
  },
  [types.actions.OBSERVATIONS_LOAD_DETAILS]({ commit }, payload) {
    // load observation detais from database into the SELECTED_OBSERVATION via api call
    // returns a promise
    // populates a geojson object for use on the map
    // payload expects a vue model, username, transaction, type, id
    // {vm: this, UserName: 'username', UserTransaction: 'usertransaction', type: 'general', id: 123} ... this should be called from a model like so: this.LOAD_USER_SCENARIOS({vm: this...})
    // relies on loading global options via the vm

    return new Promise((resolve, reject) => {
      const systemTypes = Object.keys(payload.vm.$Region.observation_types).filter(_key => !['water', 'general'].includes(_key));
      if (systemTypes.includes(payload.type)) {
        // find the observation from the system observations
        const sysObs = {
          properties: payload.vm.$Region.system_observations.find(
            (obs) => obs.id === payload.id
          ),
        };
        commit(types.mutations.UPDATE_SELECTED_OBSERVATION, sysObs);
        resolve(true);
      } else {
        // do an api call to get it from the db
        const authLib = payload.vm.$Auth;
        const apiLib = payload.vm.$API;

        // API Call
        authLib
          .currentSession()
          .then((authData) => {
            const myInit = {
              headers: { Authorization: authData.idToken.jwtToken },
            };

            apiLib
              .get(
                "api",
                `/observation/${payload.UserName}/${payload.UserTransaction}`,
                myInit
              )
              .then((body) => {
                // Parse from DynamoDB
                const obsData = {
                  properties: payload.vm.$Helpers.remapObsProperties(
                    body.Items[0]
                  ),
                };
                commit(types.mutations.UPDATE_SELECTED_OBSERVATION, obsData);
                resolve();
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error.response);
                reject(error);
              });
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err);
            reject(err);
          });
      }
    });
  },
  [types.actions.OBSERVATIONS_ALL_FEATURES_SPLICE]({ commit }, payload) {
    // update keys in selected observation
    commit(types.mutations.OBSERVATIONS_ALL_FEATURES_SPLICE, payload);
  },
  [types.actions.OBSERVATIONS_PUSH]({ commit }, _obs) {
    // update keys in selected observation
    commit(types.mutations.OBSERVATIONS_PUSH, _obs);
  },
  [types.actions.USER_OBSERVATIONS_REMOVE]({ commit }, id) {
    // remove observation from user observations array
    commit(types.mutations.USER_OBSERVATIONS_REMOVE, id);
  },
  [types.actions.SET_HIDDEN_OBSERVATION]({ commit }, obs) {
    // set hidden observation
    commit(types.mutations.SET_HIDDEN_OBSERVATION, obs);
  },
  [types.actions.CLEAR_HIDDEN_OBSERVATION]({ commit }) {
    // clear hidden observation
    commit(types.mutations.SET_HIDDEN_OBSERVATION, null);
  },
  [types.actions.RESTORE_HIDDEN_OBSERVATION]({ commit, state }) {
    // if there is a hiddenObservation, restore it to the json features
    if (state.hiddenObservation) {
      const feature = JSON.parse(JSON.stringify(state.hiddenObservation));
      const payload = {
        type: feature.properties.type,
        index: state.hiddenObservation.id,
        feature: feature,
      };
      commit(types.mutations.OBSERVATIONS_ALL_FEATURES_SPLICE, payload);
      // clear the hidden observation
      commit(types.mutations.SET_HIDDEN_OBSERVATION, null);
    }
  },
  [types.actions.SET_OBSERVATION_FEATURE_TO_LOAD]({ commit }, feat) {
    // set hidden observation
    commit(types.mutations.SET_OBSERVATION_FEATURE_TO_LOAD, feat);
  },
  [types.actions.CLEAR_OBSERVATION_FEATURE_TO_LOAD]({ commit }) {
    // clear hidden observation
    commit(types.mutations.SET_OBSERVATION_FEATURE_TO_LOAD, null);
  },
  [types.actions.SET_SELECTED_OBSERVATION]({ commit }, obs) {
    // set active observation
    if ("type" in obs.properties) {
      commit(
        types.mutations.SET_SELECTED_OBSERVATION_TYPE,
        obs.properties.type
      );
    }
    commit(types.mutations.SET_SELECTED_OBSERVATION, obs);
  },
  [types.actions.UPDATE_SELECTED_OBSERVATION]({ commit }, payload) {
    // update keys in selected observation
    commit(types.mutations.UPDATE_SELECTED_OBSERVATION, payload);
  },
  [types.actions.CLEAR_SELECTED_OBSERVATION]({ commit }) {
    // set active observation
    commit(types.mutations.SET_SELECTED_OBSERVATION, null);
  },
  [types.actions.SET_SELECTED_OBSERVATION_TYPE]({ commit }, obsType) {
    // set active observation
    commit(types.mutations.SET_SELECTED_OBSERVATION_TYPE, obsType);
  },
  [types.actions.SHOW_OBSERVATIONS_ON]({ commit }, obsType) {
    if (obsType === null) {
      // show all user observations
      commit(types.mutations.SHOW_OBSERVATIONS_ON, "observations");
    } else {
      commit(types.mutations.SHOW_OBSERVATIONS_ON, obsType);
    }
  },
  [types.actions.SHOW_OBSERVATIONS_OFF]({ commit }, obsType) {
    commit(types.mutations.SHOW_OBSERVATIONS_OFF, obsType);
  },
  [types.actions.SET_PREV_SHOW_OBSERVATIONS]({ commit }, status) {
    commit(types.mutations.SET_PREV_SHOW_OBSERVATIONS, status);
  },
  [types.actions.TOGGLE_SHOW_VIRTOURS]({ commit }) {
    commit(types.mutations.TOGGLE_SHOW_VIRTOURS);
  },
  [types.actions.TOGGLE_REFRESH_OBSERVATION_DATA]({ commit }, type) {
    commit(types.mutations.TOGGLE_REFRESH_OBSERVATION_DATA, type);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
