// did you know statements to use during scenario loading

/*
SAMPLE DID YOU KNOW STATEMENT OBJECT
{
  title: `Did you know...`,
  message: `Did you know the average Canadian uses 329 liters of water per day?`,
  image: 'someimgjpg.jpg', // optional image to be displayed between the title and the message... stored in public/img
  icon: 'fa-something' // optional icon to be displayed between title and message.  If both image and icon are provided, only image is displayed
}
*/
const RegionDidYouKnow = [
  {
    title: `Did you know...`,
    message: `Did you know you can see how temperature and precipatation has varried since 1910 and how it might vary by year in the future by clicking on the temperature and precipitation tabs in the climate section?`,
    image: "climate-maps.gif",
  },
  {
    title: `Hold tight!`,
    message: `Simulation times can vary depending on the complexity, the size of your study area, and the length of simulation.`,
    icon: "far fa-clock",
  },
  {
    title: `What are watersheds?`,
    message: `The Alberta Tomorrow study areas are based on watersheds. Do you know what a watershed is? A watershed is defined as any surface area from which runoff resulting from rainfall is collected and drained through a common point.  In other words, if you drop water in any area of a watershed, it will drain to the same point.`,
    image: "watershed_example.jpg",
  },
  {
    title: `Did you know...`,
    message: `Did you know there are over 4.4 million people in Alberta?`,
    icon: "fas fa-users",
  },
  {
    title: `That's a lot of water!`,
    message: `Did you know the average Canadian uses 329 liters of water per day?`,
    icon: "fas fa-tint",
  },
  {
    title: `The land of a thousand lakes...`,
    message: `Did you know Alberta has about 2.2% of Canada's fresh water? and Canada has about 20% of the world's fresh water? Canada has more lakes than the rest of the world combined!`,
    icon: "fas fa-water",
  },
  {
    title: `Protected area`,
    message: `Did you know that 14.9 % of Alberta is protected?  8.2% as National parks and 6.4% as provincial protected areas.`,
    icon: "fas fa-leaf",
  },
  {
    title: `Climate change`,
    message: `Did you know that you can investigate different climate change scenarios in Alberta Tomorrow?  RCP 2.6 (mild), RCP 4.5 (medium) and RCP 8.5 (hot)`,
    icon: "fas fa-cloud-sun",
  },
  {
    title: `Virtual field trips`,
    message: `Be sure to check out the 360 Virtual Field Tips to Alberta's 6 natural regions.  Look for climate, landforms, resources use, representative plants and animals and species at risk in each ecoregion.`,
    icon: "fas fa-camera",
  },
  {
    title: `Early Explorers`,
    message: `Did you know in 1754, Anthony Henday of the Hudson's Bay Company was sthe first European to discover Alberta and see the Rocky Mountains?`,
    icon: "fas fa-compass",
  },
  {
    title: `Treaty 6 Signing`,
    message: `Treaty 6, which covers central Alberta, was signed in 1876 at Fort Carleton and Fort Pitt.`,
    icon: "fas fa-pen-fancy",
  },
  {
    title: `Dominion of Canada`,
    message: `The Dominion of Canada was formed on July 1st, 1867`,
    icon: "fas fa-crown",
  },
  {
    title: `the Indian Act`,
    message: `Did you know, The Indian Act was Consolidated from different pieces of colonial legislation in 1876.`,
    icon: "fas fa-scroll",
  },
  {
    title: `Treaty 7 Signing`,
    message: `Treaty 7 was signed by Blackfoot, Blood, Peigan, Sarcee, Tsuu, Stony at Blackfoot Crossing in 1877`,
    icon: "fas fa-pen-fancy",
  },
  {
    title: `Did you know`,
    message: `A food source for the First Nations in Alberta, the buffalo, became virtually extinct in 1878 from overhunting and disease.`,
  },
  {
    title: `Ranching in Alberta`,
    message: `Did you know that in 1881, Ranching began in the Foothills of the Rocky Mountains?`,
  },
  {
    title: `The District of Alberta`,
    message: `In 1882, The Northwest Territories was divided and the District of Alberta was formed.`,
    icon: "fas fa-pen-fancy",
  },
  {
    title: `Railway Connections`,
    message: `The CPR Railway reached Calgary in 1883, when the population was about 4000 people!`,
    icon: "fas fa-train",
  },
  {
    title: `That's not water!`,
    message: `Natural Gas was discovered in Alberta in 1883 by the CPR. They were looking for water, but found natural gas!`,
    icon: "fas fa-burn",
  },
  {
    title: `Canada's First National Park`,
    message: `Did you know, Banff National Park, Canada's first National Park was created in the Rockies in 1887?`,
    icon: "fas fa-tree",
  },
  {
    title: `Frank Slide`,
    message: `In 1903, the Town of Frank was burried under a rock slide when 110 million tonnes of rock came off of Turtle Mountain.  90 people were killed.`,
    icon: "fas fa-mountain",
  },
  {
    title: `Spanish Flu`,
    message: `The Spanish Flu hit Alberta in 1918.  Of the 500,000 people, 38,308 cases  were reported (many think this is too low) and 4,380 died.`,
    icon: "fas fa-ambulance",
  },
  {
    title: `Alberta's First Oil Well`,
    message: `Did you know that Alberta's first oil well was discovered in Turner Valley in 1936?`,
    icon: "fas fa-oil-can",
  },
  {
    title: `Leduc #1`,
    message: `In 1947, vast oil reserved were discovered near Leduc, Alberta by Imperial Oil, turning Alberta into the major oil producer for Canada`,
    icon: "fas fa-oil-can",
  },
  {
    title: `Alberta's First Oil Sands`,
    message: `Alberta's first oil sands plant was completed in 1967.`,
    icon: "fas fa-oil-can",
  },
  {
    title: `Winter Olympics`,
    message: `Calgary hosted the 1988 Winter Olympics.  The Canmore Nordic Center, the Speed Skating Oval, and Canada Olympic Park were all built for the olympic games.`,
    icon: "fas fa-snowflake",
  },
  {
    title: `Flood of the Century`,
    message: `In 2013, Southern Alberta witnessed the worst flooding since 1932.`,
    icon: "fas fa-water",
  },
  {
    title: `Wildfires`,
    message: `Did you know that in 2016, Wildfires forced over 88,000 people from the city of Fort mcMurray?`,
    icon: "fas fa-fire",
  },
  {
    title: `Live Long and Prosper`,
    message: `Did you know that Vulcan Alberta, known as Canada’s quirkiest town, located between Calgary and Lethbridge built a 31-foot Enterprise replica next to the spaceship-shaped visitors’ centre. The sculpture’s plaque is written in three languages: English, Vulcan, and Klingon!`,
    icon: "fas fa-rocket",
  },
  {
    title: `Chinook Winds`,
    message: `Southern Alberta is know for its Chinook WInds. Chinook is the name given to the warm, damp winds that come from the west down off the Rocky Mountains, over Calgary and on towards the prairies. Chinooks can increase the temperature by up to 15°C!`,
    icon: "fas fa-wind",
  },
  {
    title: `UNESCO World Heritage Sites`,
    message: `Did you know that Alberta is home to 5 of Canada's 16 UNESCO's World Heritage Sites? Waterton Glacier International Peace Park, Dinosaur Provincial Park, Head-Smashed-In Buffalo Jump, Wood Buffalo National Park and Canadian Rocky Mountain Parks (Banff, Jasper, Kootenay and Yoho National parks and Mount Robson, Mount Assiniboine and Hamber provincial parks)`,
    icon: "fas fa-tree",
  },
  {
    title: `Arctic Drainage`,
    message: `Did you know that oveer 86% of Alberta's water ends up in the Arctic Ocean? Just over 13% flows to Hudson Bay, and only 0.1% flows south to the gulf of Mexico.`,
    icon: "fas fa-water",
  },
  {
    title: `Storm Water Treatment`,
    message: `Did you know that the water that goes into the storm drains in most cities flows directly into the nearest body of water (untreated)?`,
    icon: "fas fa-tint-slash",
  },
];

export default RegionDidYouKnow;
