<template>
  <span>{{ animatedNumber | formatNumber }}</span>
</template>

<script>
import TWEEN from "@tweenjs/tween.js";

export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    duration: {
      type: Number,
      default: 800
    },
    continueFromPrevious: {
      type: Boolean,
      default: false
    },
    reset: Boolean
  },
  data() {
    return {
      animatedNumber: 0,
      previousValue: 0
    };
  },
  watch: {
    reset() {
      this.initAnimation(this.value, this.previousValue);
    }
  },
  mounted() {
    this.initAnimation(this.value, this.previousValue);
  },
  methods: {
    initAnimation(newValue, oldValue) {
      const vm = this;

      function animate() {
        if (TWEEN.update()) {
          requestAnimationFrame(animate);
        }
      }

      new TWEEN.Tween({ tweeningNumber: oldValue })
        .easing(TWEEN.Easing.Quadratic.In)
        .to({ tweeningNumber: newValue }, this.duration)
        .onUpdate(function() {
          vm.animatedNumber = this.tweeningNumber.toFixed(0);
        })
        .start();

      animate();
      if (this.continueFromPrevious) {
        this.previousValue = newValue;
      }
    }
  }
};
</script>
