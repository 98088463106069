// state store for reports
import { types } from "../types";

// default activeReport object
const defaultReport = {
  id: -1,
  title: "",
  description: "",
  analysis: "",
  scenarios: [],
};

// initial state
const state = {
  reportMode: "create", // options ['create', 'view']
  newReportMsg: false, // toggle a message when user jumps to scenarios page
  userReports: [], // list of all user's reports loaded from api
  activeReport: JSON.parse(JSON.stringify(defaultReport)), // current active report
  activeReportShared: false, // if the current activeReport is shared and owned by a different user
};

// getters
const getters = {
  [types.getters.REPORT_MODE]: (state) => state.reportMode,
  [types.getters.NEW_REPORT_MSG]: (state) => state.newReportMsg,
  [types.getters.USER_REPORTS]: (state) => state.userReports,
  [types.getters.ACTIVE_REPORT]: (state) => state.activeReport,
  [types.getters.ACTIVE_REPORT_SHARED]: (state) => state.activeReportShared,
  [types.getters.ACTIVE_REPORT_SCENARIO_COUNT]: (state) =>
    state.activeReport.scenarios.length,
};

// mutations
const mutations = {
  [types.mutations.SET_REPORT_MODE](state, mode) {
    // set the report mode
    state.reportMode = mode;
  },
  [types.mutations.SET_NEW_REPORT_MSG](state, status) {
    // set the report msg status
    state.newReportMsg = status;
  },
  [types.mutations.LOAD_USER_REPORTS](state, payload) {
    // populate array with user reports loaded from api
    state.userReports = [...payload];
  },
  [types.mutations.USER_REPORTS_PUSH](state, rep) {
    // add a report to the userReports array
    state.userReports.push(rep);
  },
  [types.mutations.USER_REPORTS_UPDATE](state, rep) {
    // update a single report in the userReports array
    const repIndex = state.userReports.findIndex((_rep) => _rep.id === rep.id);
    state.userReports[repIndex] = JSON.parse(JSON.stringify(rep));
  },
  [types.mutations.USER_REPORTS_REMOVE](state, rep) {
    // remove a single report from the userReports array
    const repIndex = state.userReports.findIndex((_rep) => _rep.id === rep.id);
    state.userReports.splice(repIndex, 1);
  },
  [types.mutations.UPDATE_ACTIVE_REPORT](state, payload) {
    // update keys in activeReport - update all keys provided in payload
    Object.keys(payload).forEach((key) => {
      state.activeReport[key] = JSON.parse(JSON.stringify(payload[key]));
    });
  },
  [types.mutations.SET_ACTIVE_REPORT](state, rep) {
    // set the active report
    state.activeReport = JSON.parse(JSON.stringify(rep));
  },
  [types.mutations.SET_ACTIVE_REPORT_SHARED](state, status) {
    // set the ownership/sharing status of active report
    state.activeReportShared = status;
  },
  [types.mutations.ACTIVE_REPORT_ADD_SCENARIO](state, scen) {
    // add a scenario to the active report
    if (state.activeReport.scenarios.length < 4) {
      // only allow a max of 4 scenarios in a report
      const index = state.activeReport.scenarios.findIndex(
        (_scen) => _scen.id === scen.id
      );
      if (index === -1) {
        state.activeReport.scenarios.push(scen);
      }
    }
  },
  [types.mutations.ACTIVE_REPORT_REMOVE_SCENARIO](state, id) {
    // remove a scenario from the active report
    const index = state.activeReport.scenarios.findIndex(
      (_scen) => _scen.id === id
    );
    if (index !== -1) {
      state.activeReport.scenarios.splice(index, 1);
    }
  },
  [types.mutations.ACTIVE_REPORT_UPDATE](state, payload) {
    // update keys in activeReport - update all keys provided in payload
    Object.keys(payload).forEach((key) => {
      state.activeReport[key] = JSON.parse(JSON.stringify(payload[key]));
    });
  },
};

// actions
const actions = {
  [types.actions.SET_REPORT_MODE]({ commit }, mode) {
    // set the report mode
    commit(types.mutations.SET_REPORT_MODE, mode);
  },
  [types.actions.SET_NEW_REPORT_MSG]({ commit }, status) {
    // set the report msg status
    commit(types.mutations.SET_NEW_REPORT_MSG, status);
  },
  [types.actions.LOAD_USER_REPORTS]({ commit, rootGetters }, payload) {
    // load the array of user reports
    // note, this adds to the already existing array.  If a report is being deleted, the array should be cleared first before loading
    // payload expects a vue model as a {vm: this} ... this should be called from a model like so: this.LOAD_USER_REPORTS({vm: this})
    // relies on loading global options
    const reports = [];
    const ids = [];
    const authLib = payload.vm.$Auth;
    const apiLib = payload.vm.$API;
    const callbackFn = "callbackFn" in payload ? payload.callbackFn : null;
    // API Call
    authLib
      .currentSession()
      .then((authData) => {
        const myInit = {
          headers: { Authorization: authData.idToken.jwtToken },
        };
        const username = authData.accessToken.payload.username;
        apiLib
          .get("api", "/report/" + username, myInit)
          .then((body) => {
            // Parse from DynamoDB
            body.Items.map((item) => {
              const repData = payload.vm.$Helpers.remapRepData(
                item,
                rootGetters.USER_SCENARIOS
              );
              reports.push(repData);
              ids.push(repData.id);
            });
            // Reverse the reports based on ID
            ids.sort();
            ids.reverse().forEach((id) => {
              reports.forEach((rep) => {
                if (id === rep.id) {
                  // find the rep by id
                  const _rep = state.userReports.find(
                    (_rep) => _rep.id === rep.id
                  );
                  if (!_rep) {
                    // add it if it's not in USER_SCENARIOS
                    commit(types.mutations.USER_REPORTS_PUSH, rep);
                  } else {
                    // else update with the latest info for existing rep
                    commit(types.mutations.USER_REPORTS_UPDATE, rep);
                  }
                }
              });
            });
            // trigger potential mission achievement updates
            commit(types.mutations.INCREMENT_MISSION_UPDATE_TRIGGER);
            if (callbackFn !== null) {
              callbackFn();
            }
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log(error.response);
          });
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  },
  [types.actions.CLEAR_USER_REPORTS]({ commit }) {
    // reset the array of user reports
    commit(types.mutations.LOAD_USER_REPORTS, []);
  },
  [types.actions.USER_REPORTS_PUSH]({ commit }, rep) {
    commit(types.mutations.USER_REPORTS_PUSH, rep);
  },
  [types.actions.USER_REPORTS_UPDATE]({ commit }, rep) {
    commit(types.mutations.USER_REPORTS_UPDATE, rep);
  },
  [types.actions.USER_REPORTS_REMOVE]({ commit }, rep) {
    commit(types.mutations.USER_REPORTS_REMOVE, rep);
  },
  [types.actions.SET_ACTIVE_REPORT]({ commit }, payload) {
    // set the active report
    const vm = payload.vm;
    const rep = payload.rep;
    commit(types.mutations.SET_ACTIVE_REPORT, rep);
    // check ownership/share status
    const isShared =
      "UserName" in rep && rep.UserName !== vm.$Auth.user.username;
    commit(types.mutations.SET_ACTIVE_REPORT_SHARED, isShared);
    // analytics tracking
    const label = `${rep.title}, ${rep.scenarios.length} scenarios`;
    vm.$Helpers.gtagEvent(vm, `view_report`, `reports`, label);
  },
  [types.actions.UPDATE_ACTIVE_REPORT]({ commit }, payload) {
    // note this only updates the vuex object.  It does not update the database.
    commit(types.mutations.UPDATE_ACTIVE_REPORT, payload);
  },
  [types.actions.ACTIVE_REPORT_ADD_SCENARIO]({ commit }, scen) {
    // add scenario to active report
    commit(types.mutations.ACTIVE_REPORT_ADD_SCENARIO, scen);
  },
  [types.actions.ACTIVE_REPORT_REMOVE_SCENARIO]({ commit }, id) {
    // remove scenario from active report
    commit(types.mutations.ACTIVE_REPORT_REMOVE_SCENARIO, id);
  },
  [types.actions.ACTIVE_REPORT_UPDATE]({ commit }, payload) {
    // note this only updates the vuex object.  It does not update the database.
    commit(types.mutations.ACTIVE_REPORT_UPDATE, payload);
  },
  [types.actions.ACTIVE_REPORT_CLEAR]({ commit }) {
    // note this only updates the vuex object.  It does not update the database.
    const payload = JSON.parse(JSON.stringify(defaultReport));
    payload.dateCreated = null;
    commit(types.mutations.SET_ACTIVE_REPORT_SHARED, false);
    commit(types.mutations.ACTIVE_REPORT_UPDATE, payload);
    commit(types.mutations.SET_REPORT_MODE, "create"); // create is default mode when no report is loaded
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
