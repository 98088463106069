<template>
  <md-card
    class="md-card-tabs"
    :class="[
      {'flex-column': flexColumn},
      {'nav-pills-icons': navPillsIcons},
      {'md-card-plain': plain}
    ]"
  >
    <md-card-header>
      <slot name="header-title" />
    </md-card-header>
    <md-card-content>
      <md-list class="nav-tabs">
        <md-list-item
          v-for="(item, index) in tabName"
          :key="item"
          :class="[
            {active: isActivePanel(tabName[index])},
            {[getColorButton(colorButton)]: isActivePanel(tabName[index])}]"
          @click="switchPanel(tabName[index])"
        >
          {{ tabName[index] }}
          <md-icon v-if="navPillsIcons">
            {{ tabIcon[index] }}
          </md-icon>
        </md-list-item>
      </md-list>

      <transition
        name="fade"
        mode="out-in"
      >
        <div class="tab-content">
          <div
            v-for="(item, index) in activeTabs"
            :key="item"
            class="tab-pane"
          >
            <slot :name="getTabContent(index + 1)">
              This is the default text!
            </slot>
          </div>
        </div>
      </transition>
    </md-card-content>
  </md-card>
</template>

<script>
export default {
  props: {
    flexColumn: Boolean,
    navPillsIcons: Boolean,
    plain: Boolean,
    tabName: {
      type: Array,
      default: () => [],
    },
    tabIcon: {
      type: Array,
      default: () => [],
    },
    colorButton: {
      type: String,
      default: ''
    },
    activePanel:  {
      type: String,
      default: ''
    }
  },
  computed: {
    activeTabs() {
      return this.tabName.filter((tab) => this.activePanel === tab)
    }
  },
  methods: {
    switchPanel(panel) {
      this.$emit('switch-tab', panel);
    },
    isActivePanel(panel) {
      return this.activePanel === panel;
    },
    getColorButton: function(colorButton) {
      return 'md-'+ colorButton + ''
    },
    getTabContent: function(index) {
      return 'tab-pane-'+ index +''
    }
  }
}
</script>

<style lang="css">
  .tab-pane {
    width: 100%;
  }
</style>
