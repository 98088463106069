/* jshint ignore: start */
// mixin for gamification hint/badge/challenge condition & event handling
/*
GAME ACTIVITY EVENT TYPES
'obs-create' - fires when an observation is created
'obs-view' - fires when an observation is viewed
'video-view' - fires when a video is viewed completely
'scenario-view' - fires when scenario is run
'quest-complete' - fires when a quest has successfully completed
'report-create' - fires when a new report is created/saved
'mission-complete' - fires when user has completed all tasks in mission
QUEST-ONLY EVENT TYPES: THE FOLLOWING ONLY AFFECT QUESTS
'report-share' - fires when a report is shared - NOTE: other share types can be added by adding the shareEvent prop to any instance of a ShareDialog component - see ReportDetails for example
'map-move' - fires when map is moved in any way (pan, zoom) - ONLY FIRES WHEN ACTIVE_QUEST IS NOT NULL AND ONLY AFFECTS QUEST ACTIVITIES
'panel-view' - fires when RightSidebar is opened or closed --- sort of... this is the event that's called when climate panel is viewed.  It's connected to a vue component emit: @onViewClimatePanel.  Similar event could be triggered for other panel activities.
*/
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import { User } from "@/pages/Dashboard/Components/mixins/User";
import swal from "sweetalert2";
export const Gamification = {
  mixins: [User], // user profile methods

  computed: {
    // be sure to also include getters that are required by any of the conditions in the config functions that may not otherwise be in the vue model
    ...mapGetters([
      types.getters.CHALLENGE_COUNTDOWN,
      types.getters.HINT_COUNTDOWN,
      types.getters.ACTIVE_HINT,
      types.getters.PREVIOUS_HINT_INDEX,
      types.getters.SHOW_HINT_DIALOG,
      types.getters.ACTIVE_CHALLENGE,
      types.getters.SHOW_CHALLENGE_DIALOG,
      types.getters.SHOW_BADGE_DIALOG,
      types.getters.ACTIVE_BADGE,
      types.getters.PENDING_BADGES,
      types.getters.ACTIVE_QUEST,
      types.getters.MISSION_UPDATE_TRIGGER,
      // actions used by event condition functions (see region-conditions.js)
      types.getters.ACTIVE_SCENARIO,
      types.getters.ACTIVE_HINT_ALLOCATION,
      types.getters.ACTIVE_HINT_TRANSITION,
      types.getters.USER_SCENARIOS,
      types.getters.INDICATOR_VALS,
      types.getters.USER_DATA,
      types.getters.OBSERVATIONS_ALL_USER,
      types.getters.USER_REPORTS,
    ]),

    showHints() {
      // whether or not to show hints
      if (
        "preferences" in this.USER_DATA &&
        "showHints" in this.USER_DATA.preferences
      ) {
        return this.USER_DATA.preferences.showHints;
      }
      return this.$Region.defaultUserPrefs.showHints;
    },
    showChallenges() {
      // whether or not to show challenges
      let showChallenges = false;
      if (
        "preferences" in this.USER_DATA &&
        "showChallenges" in this.USER_DATA.preferences
      ) {
        showChallenges = this.USER_DATA.preferences.showChallenges;
      } else {
        showChallenges = this.$Region.defaultUserPrefs.showChallenges;
      }
      if (showChallenges === true) {
        // user needs to have at least challengesMinScenarioRuns number of runs completed - either in one scenario, or via multiple scenarios
        if (
          this.ACTIVE_SCENARIO === null ||
          !("numRunsCompleted" in this.ACTIVE_SCENARIO) ||
          this.ACTIVE_SCENARIO.numRunsCompleted <
            this.$Region.challengesMinScenarioRuns
        ) {
          showChallenges = false;
        }
        if (
          this.USER_SCENARIOS.length < this.$Region.challengesMinScenarioRuns
        ) {
          showChallenges = false;
        } else {
          showChallenges = true;
        }
      }
      return showChallenges;
    },
    numUserBadges() {
      // how many user badges a user has - also a proxy for if they have any or not
      if ("achievements" in this.USER_DATA) {
        return this.USER_DATA.achievements.length;
      }
      return 0;
    },
  },

  methods: {
    // be sure to also include actions that are required by any of the conditions in the config functions that may not otherwise be in the vue model
    ...mapActions([
      types.actions.SET_CHALLENGE_COUNTDOWN,
      types.actions.DECREMENT_CHALLENGE_COUNTDOWN,
      types.actions.SET_HINT_COUNTDOWN,
      types.actions.DECREMENT_HINT_COUNTDOWN,
      types.actions.SET_ACTIVE_HINT,
      types.actions.CLEAR_ACTIVE_HINT,
      types.actions.SET_PREVIOUS_HINT_INDEX,
      types.actions.CLEAR_ACTIVE_CHALLENGE,
      types.actions.SET_ACTIVE_CHALLENGE,
      types.actions.SET_SHOW_HINT_CHALLENGE_DIALOG,
      types.actions.CLEAR_ACTIVE_QUEST,
      types.actions.SET_ACTIVE_QUEST,
      types.actions.SET_ACTIVE_BADGE,
      types.actions.CLEAR_ACTIVE_BADGE,
      types.actions.UPDATE_PENDING_BADGES,
      types.actions.SET_SHOW_BADGE_DIALOG,
      // actions used by event condition functions (see region-conditions.js)
      types.actions.SET_ACTIVE_HINT_ALLOCATION,
      types.actions.SET_ACTIVE_HINT_TRANSITION,
      types.actions.TOGGLE_QUEST_BUTTON_ANIMATION,
      types.actions.TOGGLE_CHALLENGE_SUCCESS_ANIMATION,
      types.actions.SHOW_OBSERVATIONS_ON,
      types.actions.SET_BASEMAP_STYLE,
    ]),

    getGameActivity(eventType) {
      // performs gamification stuff - looks for quest updates, or launches hints/challenges
      // check for quest updates
      this.checkQuestActivities(eventType);
      // check for newly completed badges
      if (this.checkBadgeAccomplishments(eventType)) {
        return; // skip more game activity if a badge was just awarded
      }
      // get either a hint or a challenge for a gamification event
      // ------- challenges -----------
      if (this.showChallenges) {
        // only look for challenges if user has them set on
        if (this.CHALLENGE_COUNTDOWN < 0) {
          // counter needs to be initiated
          this.SET_CHALLENGE_COUNTDOWN(this.$Region.challengesFrequency);
        } else if (this.CHALLENGE_COUNTDOWN === 0) {
          // it's time for a challenge
          const challenge = this.getRandomHintOrChallenge(
            eventType,
            "challenges"
          );
          if (challenge) {
            // reset the counter for next time
            this.SET_CHALLENGE_COUNTDOWN(this.$Region.challengesFrequency);
            this.SET_ACTIVE_CHALLENGE(challenge);
            this.SET_SHOW_HINT_CHALLENGE_DIALOG({
              type: "challenge",
              status: true,
            });
            return;
          }
        } else {
          this.DECREMENT_CHALLENGE_COUNTDOWN();
        }
      }
      // ------- hints -----------
      // if a challenge wasn't returned, try for a hint
      // we can load hints regardless of user settings... showHints will simply determine whether or not to launch the modal
      if (this.HINT_COUNTDOWN < 0) {
        // counter needs to be initiated
        this.SET_HINT_COUNTDOWN(this.$Region.hintsFrequency);
      } else if (this.HINT_COUNTDOWN === 0) {
        // it's time for a hint
        const hint = this.getRandomHintOrChallenge(eventType, "hints");
        if (hint) {
          // reset the counter for next time
          this.SET_HINT_COUNTDOWN(this.$Region.hintsFrequency);
          this.SET_ACTIVE_HINT(hint);
          if (this.showHints) {
            // only activate the dialog if showHints is set to true - otherwise just set it and allow user to look it up when they want
            this.SET_SHOW_HINT_CHALLENGE_DIALOG({ type: "hint", status: true });
          }
          return;
        }
      } else {
        this.DECREMENT_HINT_COUNTDOWN();
      }
      return;
    },
    // getHintById(id) {
    //   // launch a specific hint - used in testing only currently
    //   const hint = this.$Region.hints.find((hint) => hint.id === id);
    //   this.SET_ACTIVE_HINT(hint);
    //   this.SET_SHOW_HINT_CHALLENGE_DIALOG({ type: "hint", status: true });
    // },
    // testHintConditionsById(id) {
    //   // debug utility
    //   // test the conditions for a specific hint
    //   const hint = this.$Region.hints.find((hint) => hint.id === id);
    //   if (this.evalConditions(hint.conditions)) {
    //     console.log(`PASS. Hint passed conditions`);
    //   } else {
    //     console.log(`FAIL. Hint failed conditions`);
    //   }
    // },
    getChallengeByCode(code) {
      // launch a specific challenge or quest
      const challenge = this.$Region.challenges.find(
        (challenge) => challenge.code === code
      );
      this.SET_ACTIVE_CHALLENGE(challenge);
      this.SET_SHOW_HINT_CHALLENGE_DIALOG({ type: "challenge", status: true });
    },
    getRandomHintOrChallenge(eventType, type, alreadyTested = []) {
      // randomly select a hint or challenge to display
      // eventType = one of ['scenario-view', 'obs-view', 'view-view']
      // type = one of ['hints', 'challenges']
      // alreadyTested = array of indexes that were already tested against conditions - used for recursive calls
      // returns an object (either a hint or challenge)

      // collect recent hints or collect challenges that are already completed
      if (alreadyTested.length === 0) {
        if (type === "hints" && this.PREVIOUS_HINT_INDEX !== -1) {
          // don't repeat the same hint 2x in a row
          alreadyTested.push(this.PREVIOUS_HINT_INDEX);
          return this.getRandomHintOrChallenge(eventType, type, alreadyTested);
        } else if (type === "challenges" && "history" in this.USER_DATA) {
          // get list of challenges already completed
          const vm = this;
          const completedChallenges = this.USER_DATA.history
            .filter((item) => item.startsWith("c_"))
            .map((item) =>
              vm.$Region[type].findIndex(
                (itm) => itm.code === item.replace("c_", "")
              )
            );
          // console.log("completed challenges:", completedChallenges);
          if (completedChallenges.length > 0) {
            return this.getRandomHintOrChallenge(
              eventType,
              type,
              completedChallenges
            );
          }
        }
      }
      if (alreadyTested.length === this.$Region[type].length) {
        // all items have been tested, no conditions match
        // console.log("there are no items available");
        return null;
      }
      const randIndex = Math.floor(Math.random() * this.$Region[type].length);
      // console.log(`trying item # ${randIndex}`);
      let preFilter = this.$Region[type].filter(
        (item) => item.event === eventType
      );
      if (type === "challenges" && this.ACTIVE_QUEST) {
        // filter out any quests
        preFilter = preFilter.filter((item) => item.type !== "quest");
      }
      if (preFilter.length === 0) {
        // no items found
        return null;
      }
      const randItem = preFilter[randIndex];
      if (alreadyTested.includes(randIndex)) {
        // try another item
        // console.log("already tried that one... trying again....");
        return this.getRandomHintOrChallenge(eventType, type, alreadyTested);
      }
      // check item conditions
      // console.log("item is:", randItem);
      if (randItem.conditions.length === 0) {
        if (type === "hints") {
          this.SET_PREVIOUS_HINT_INDEX(randIndex);
        }
        return randItem;
      }
      if (this.evalConditions(randItem.conditions)) {
        // item meets the required conditions
        if (type === "hints") {
          this.SET_PREVIOUS_HINT_INDEX(randIndex);
        }
        return randItem;
      }
      // conditions failed
      // add to the list of already tested and try again
      // console.log("conditions failed... go again...");
      alreadyTested.push(randIndex);
      return this.getRandomHintOrChallenge(eventType, type, alreadyTested);
    },
    evalConditions(conditions) {
      // evaluate a list of conditions
      // return false if any of them fail
      const vm = this;
      const helpers = this.$Region.conditionHelpers;
      let i;
      for (i = 0; i < conditions.length; i++) {
        try {
          // test conditions in a try loop in case the variables are not in place
          if (conditions[i]({ vm: vm, helpers: helpers }) !== true) {
            return false;
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          // console.error(error);
          return false;
        }
      }
      return true;
    },
    checkQuestActivities(eventType) {
      // check to see if any quest activities are completed
      // eventType = the type of event that triggered this check
      if (this.ACTIVE_QUEST) {
        const remainingConditions = this.ACTIVE_QUEST.passConditions
          .map((cond, index) => ({ ...cond, index: index }))
          .filter((cond) => !Object.keys(cond).includes("complete"));
        const completedConds = this.ACTIVE_QUEST.passConditions
          .map((cond, index) => ({ ...cond, index: index }))
          .filter((cond) => Object.keys(cond).includes("complete"))
          .map((cond) => cond.index);

        remainingConditions.forEach((cond) => {
          // make sure conditions are passed as a list to evalConditions
          if (cond.event === eventType) {
            const conds = Array.isArray(cond.condition)
              ? cond.condition
              : [cond.condition];
            if (this.evalConditions(conds)) {
              // if condition passed, remove it from the list of remaining conditions to complete
              // remove the condition from the quest
              this.ACTIVE_QUEST.passConditions[cond.index].complete = true;
              completedConds.push(cond.index);
              if (
                completedConds.length ===
                this.ACTIVE_QUEST.passConditions.length
              ) {
                // all conditions passed - the quest is complete!
                // update the inprogress - remove the item
                this.updateUserInProgressItem(
                  { type: "quest", code: this.ACTIVE_QUEST.code },
                  true
                );
                // notify user saving
                let successAck = false;
                swal
                  .fire({
                    title: `Challenge Completed!`,
                    text: this.ACTIVE_QUEST.passMsg,
                    icon: "success",
                    customClass: {
                      confirmButton: "md-button md-success",
                    },
                    buttonsStyling: false,
                  })
                  .then(() => {
                    // either way, we want to set successAck to true
                    successAck = true;
                  });
                // award points!
                this.TOGGLE_CHALLENGE_SUCCESS_ANIMATION();
                this.addUserPoints(this.ACTIVE_QUEST.points);
                // update profile -- add to user's completed challenges
                const historyItem = `c_${this.ACTIVE_QUEST.code}`;
                // call the API and update the user profile
                this.updateUserHistory(historyItem); // updates DB and vuex store
                // close the quest
                this.CLEAR_ACTIVE_QUEST();
                // check for badges after successAck = true
                const waitingSuccesAck = () => {
                  if (!successAck) {
                    setTimeout(waitingSuccesAck, 500);
                  } else {
                    this.checkBadgeAccomplishments("quest-complete");
                  }
                };
                waitingSuccesAck();
              } else {
                // not done yet, but update the inprogress for the completed item
                this.updateUserInProgressItem({
                  type: "quest",
                  code: this.ACTIVE_QUEST.code,
                  completed: completedConds,
                });
              }
            }
          }
        });
      }
    },
    checkBadgeAccomplishments(eventType) {
      // check to see if conditions are met for a new badge
      // eventType = the type of event that triggered this check
      let foundBadges = 0;
      if ("awardBadges" in this.$Region && this.$Region.awardBadges.length) {
        // if there are badges in the system
        const availBadges = this.getAvailableBadges().filter(
          (badge) => badge.event === eventType
        );
        // find if any new badges pass
        availBadges.forEach((badge) => {
          if (this.evalConditions(badge.passConditions)) {
            // todo debug logs
            foundBadges += 1;
            // add to list of badges that are pending a notification
            this.UPDATE_PENDING_BADGES({ mode: "push", badgeCode: badge.code });
          }
        });
      }
      if (foundBadges === 0) {
        return false; // no badges found
      }
      // else start to process pending badges
      this.updateBadgeAchievement(); // process a badge
      return true; // return true so that other game activities can be skipped if a badge was awarded
    },
    updateBadgeAchievement() {
      // update user profile with the first badge in the list of PENDING_BADGES
      // do the points updating in vuex and DB and set a badge popup
      if (this.PENDING_BADGES.length > 0) {
        const badge = this.$Region.awardBadges.find(
          (_badge) => _badge.code === this.PENDING_BADGES[0]
        );
        this.SET_ACTIVE_BADGE(badge);
        this.updateUserAchievements(badge.code);
        this.addUserPoints(badge.points);
        this.SET_SHOW_BADGE_DIALOG(true);
      }
    },
    closeHintOrChallenge(callbackFn = null) {
      // close the challenge dialog
      this.SET_SHOW_HINT_CHALLENGE_DIALOG({ type: "challenge", status: false });
      this.SET_SHOW_HINT_CHALLENGE_DIALOG({ type: "hint", status: false });
      this.CLEAR_ACTIVE_CHALLENGE();
      if (callbackFn) {
        // if the challenge had a setup function(s) to be fired
        if (Array.isArray(callbackFn)) {
          const vm = this;
          callbackFn.forEach((fn) =>
            fn({ vm: vm, helpers: vm.$Region.conditionHelpers })
          );
        } else {
          callbackFn({ vm: this, helpers: this.$Region.conditionHelpers });
        }
      }
    },
    getUserBadges() {
      // return array of user badge objects that the user has achieved
      if (
        "awardBadges" in this.$Region &&
        this.$Region.awardBadges.length &&
        this.numUserBadges > 0
      ) {
        const vm = this;
        const _badges = this.USER_DATA.achievements.map((item) =>
          vm.$Region.awardBadges.find((itm) => itm.code === item)
        );
        if (_badges.length && _badges[0] !== "undefined") {
          return _badges; // found badges
        }
      }
      return []; // no badges
    },
    getAvailableBadges() {
      if ("awardBadges" in this.$Region && this.$Region.awardBadges.length) {
        if (this.numUserBadges === 0) {
          return this.$Region.awardBadges; // all badges still available
        }
        const vm = this;
        const _badges = this.$Region.awardBadges.filter(
          (badge) => !vm.USER_DATA.achievements.includes(badge.code)
        ); // only badges that aren't yet achieved
        if (_badges.length && _badges[0] !== "undefined") {
          return _badges; // found badges
        }
      }
      return []; // no badges available
    },
    closeBadge() {
      // close badge dialog
      const launchAnother = this.PENDING_BADGES.length > 1;
      this.UPDATE_PENDING_BADGES({
        mode: "splice",
        badgeCode: this.ACTIVE_BADGE.code,
      });
      this.SET_SHOW_BADGE_DIALOG(false);
      this.CLEAR_ACTIVE_BADGE();
      if (launchAnother) {
        setTimeout(this.updateBadgeAchievement, 800);
      }
    },
  },
};
