// state store for tour/tutorial details
import { types } from "../types";

// initial state
const state = {
  tourActive: false, // a tour is currently active
  helpBtnTourComplete: false, // user has seen where the help button is
  availableTours: [], // current available tours
  priorityAvailableTours: [], // ordered list of priority tours
  availableToursTrigger: 0
};

// getters
const getters = {
  [types.getters.TOUR_ACTIVE]: state => state.tourActive,
  [types.getters.HELP_BTN_TOUR_COMPLETE]: state => state.helpBtnTourComplete,
  [types.getters.AVAILABLE_TOURS]: state => state.availableTours,
  [types.getters.PRIORITY_AVAILABLE_TOURS]: state =>
    state.priorityAvailableTours,
  [types.getters.AVAILABLE_TOURS_TRIGGER]: state => state.availableToursTrigger
};

// mutations
const mutations = {
  [types.mutations.SET_TOUR_ACTIVE](state, status) {
    // set the status of tours
    state.tourActive = status;
  },
  [types.mutations.SET_HELP_BTN_TOUR_COMPLETE](state, status) {
    // set the status of user has viewed the helpbutton tour
    state.helpBtnTourComplete = status;
  },
  [types.mutations.AVAILABLE_TOURS_PUSH](state, tour) {
    // add a tour to the availableTours array
    state.availableTours.push(tour);
  },
  [types.mutations.AVAILABLE_TOURS_REMOVE](state, tour) {
    // remove a single tour from the availableTours array
    const tourIndex = state.availableTours.findIndex(_tour => _tour === tour);
    state.availableTours.splice(tourIndex, 1);
  },
  [types.mutations.SET_PRIORITY_AVAILABLE_TOURS](state, tours) {
    // set the priority of available tours
    state.priorityAvailableTours = [...tours];
  },
  [types.mutations.TOGGLE_AVAILABLE_TOURS_TRIGGER](state) {
    state.availableToursTrigger = !state.availableToursTrigger;
  }
};

// actions
const actions = {
  [types.actions.SET_TOUR_ACTIVE]({ commit }, status) {
    // set the status of tours
    commit(types.mutations.SET_TOUR_ACTIVE, status);
  },
  [types.actions.SET_HELP_BTN_TOUR_COMPLETE]({ commit }, status) {
    // set the status of user has viewed the helpbutton tour
    commit(types.mutations.SET_HELP_BTN_TOUR_COMPLETE, status);
  },
  [types.actions.AVAILABLE_TOURS_PUSH]({ commit }, tour) {
    // add a tour to the availableTours array
    commit(types.mutations.AVAILABLE_TOURS_PUSH, tour);
    commit(types.mutations.TOGGLE_AVAILABLE_TOURS_TRIGGER);
  },
  [types.actions.AVAILABLE_TOURS_REMOVE]({ commit }, tour) {
    // remove a tour from the availableTours array
    commit(types.mutations.AVAILABLE_TOURS_REMOVE, tour);
    commit(types.mutations.TOGGLE_AVAILABLE_TOURS_TRIGGER);
  },
  [types.actions.SET_PRIORITY_AVAILABLE_TOURS]({ commit }, tours) {
    // set priority list of tours
    commit(types.mutations.SET_PRIORITY_AVAILABLE_TOURS, tours);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
