// scenarios setup

const RegionScenarios = {
  // default scenarios setup
  // settings used by region-equations.js that can be treated globally for all sub-regions
  // individual regions can override these settings in geojson root properties
  scenario_settings: {
    bestPractices: false, // best practices switch - default to false for BAU
    rnvAreas: {
      Forest: 0.65,
      Water: 0.2,
      Grassland: 0.1,
      "Alpine and Exposed": 0.05
    }, // initial pre-industrial areas of LT's
    historicValues: {
      devRate: 0.15,
      energyProd: 765189229.5,
      humanPop: 31505.12836,
      timberProd: 200785.4983
    }, // historic indicator values (human pop, energy, timber... other)
    currentValues: {
      devRate: 0.45,
      energyProd: 536808861.4,
      humanPop: 58260.65759,
      timberProd: 2019716.34
    }, // current (present-day) indicator values (human pop, energy, timber... other)
    futureValues: {
      devRate: 0.75,
      energyProd: 929688664
    } // future indicator values (energy projection... other)
  },
  // system scenarios to make available
  system_scenarios: [
    {
      name: "Historic",
      description:
        "Simulation showing how the landscape has changed historically",
      type: "Historic",
      icon: "fas fa-history",
      startTime: 1910,
      endTime: 2020,
      base_raster_path: "ab_cover_historic"
    },
    {
      name: "Business as Usual",
      description:
        "Future land-use simulation based on current practices and trajectory",
      type: "Business as Usual",
      icon: "fas fa-arrow-right",
      startTime: 2020,
      endTime: 2050,
      base_raster_path: "ab_cover_bau"
    }
  ],
  modelling_description: `<p><strong>What are simulation models and how do they work?</strong></p>
        <p>
        When you create a scenario you are running a computer simulation that calculates what things <strong>might</strong> look like in the future. Some people call it wizardry, some call it computer modelling. You control the outcome of a simulation because you are entering some of the starting information. Try not to let this power get to your head. Making scenarios simply helps people look at what <strong>might</strong> occur in the future with different perspectives. 
        </p>
        <p>
        There is no way to see what actually happens in the future unless you are crafty enough to invent a time machine!  With computer simulations, we can try and make educated guesses using different scenarios. None of the scenarios will be exactly right, but some of them may be useful in looking at possible alternative futures. The more scenarios that are run, the better we can understand how making changes today may help us be more prosperous in the future.
        </p>
        <p>
        Keep in mind that some aspects of the scenario modelling engine are randomized based on probabilities.  Therefore, every time you make changes and re-run your scenario, the results will be different.  No two results are identical!  In this respect, the type of scenario modelling used in Alberta Tomorrow is not really intended to make specific plans for exactly how your community or neighbourhood might look and whether a park or school will be in this location or that, but rather to look more generally at how an entire region and indicators  might look like in the future.  In other words, it’s not so much about what features are exactly where on a map, but instead how the environmental, social and economic health is of your study area.
        </p>`
};

export default RegionScenarios;
