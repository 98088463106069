<!-- this component is just to provide validation to the scenario wizard in mobile screen view -->
<script>
import { ScenarioChanges } from "@/pages/Dashboard/Components/mixins/ScenarioChanges";
import { ScenarioWizardHelpers } from "@/pages/Dashboard/Components/mixins/ScenarioWizardHelpers";
export default {
  name: "ScenarioChangesRenderless",

  mixins: [ScenarioChanges, ScenarioWizardHelpers],

  data() {
    return {
    };
  },

  render() {
    return this.$scopedSlots.default();
  }
};
</script>