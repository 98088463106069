// residential schools in AB

const abResSchools = [
    {
      "id": 1000,
      "title": "Assumption",
      "description": "Point is on the site of the school building of the Assumption Indian Residential School. The building has been demolished. The school property is situated in Chateh, AB on Lot 1, north of Sousa creek.",
      "coordinates": [
        -118.68658800033592,
        58.70219200006079
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/10a-c000828-d0005-001.jpg"
      },
      "observedTime": 1510185600000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Assumption-Residential-School"],
      "OBJECTID": 53,
      "LocID": 491,
      "LocName": "Assumption",
      "AltNames": "Hay Lakes; Notre Dame de l'Assomption",
      "LocEarlyYR": 1951,
      "LocLastYR": 1974,
      "Latitude": 58.702192,
      "Longitude": -118.686588,
      "LocAuthor": "Hite / Orlandini",
      "LocDesc": "Point is on the site of the school building of the Assumption Indian Residential School. The building has been demolished. The school property is situated in Chateh, AB on Lot 1, north of Sousa creek.",
      "LocType": "School Location",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/10a-c000828-d0005-001.jpg",
      "PhotoTitle": "Assumption Indian Residential School [date unknown].",
      "PhotoSour": "Archives Deschâtelets and NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Roman Catholic",
      "IndigComm": "Chateh",
      "LegacyName": "Hay Lake 209",
      "LocDate": "2017-11-09",
      "subtype": "resschool"
    },
    {
      "id": 1001,
      "title": "Blue Quills (First Location)",
      "description": "Point is on the site of the first location of the Blue Quill's Indian Residential School (Saddle Lake-Blue Quill's Mission), specifically the site of the Mission. The mission was situated 6 miles southwest of Saddle Lake and one mile north of the river.",
      "coordinates": [
        -111.80467700024332,
        53.952402000352095
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "http://archives.algomau.ca/main/sites/default/files/2015-054_001_003_003.jpg"
      },
      "observedTime": 1513814400000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Blue-Quills-Residential-School"],
      "OBJECTID": 54,
      "LocID": 501,
      "LocName": "Blue Quills (First Location)",
      "AltNames": "Sacre-Coeur; Sacred Heart",
      "LocEarlyYR": 1898,
      "LocLastYR": 1931,
      "Latitude": 53.952402,
      "Longitude": -111.804677,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the site of the first location of the Blue Quill's Indian Residential School (Saddle Lake-Blue Quill's Mission), specifically the site of the Mission. The mission was situated 6 miles southwest of Saddle Lake and one mile north of the river.",
      "LocType": "School Location (First Site of Blue Quills)",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "http://archives.algomau.ca/main/sites/default/files/2015-054_001_003_003.jpg",
      "PhotoTitle": "Photo of the Sacred Heart Mission on the Saddle Lake Reserve [ca. 1908].",
      "PhotoSour": "Provincial Archives of Alberta. Blue Quill's Indian Residential School 1870-1970 Newsletter; OBI 1674.",
      "BldgStatus": "Not Present",
      "ReligiousA": "Roman Catholic",
      "IndigComm": "Saddle Lake First Nation",
      "LegacyName": null,
      "LocDate": "2017-12-21",
      "subtype": "resschool"
    },
    {
      "id": 1002,
      "title": "Blue Quills (Second Location)",
      "description": "Point is on the site of the main building of the Blue Quills indian Residential School. This site was the second location of the Blue Quills school. The buildings are now the Blue Quills First Nation's College.",
      "coordinates": [
        -111.36969899960911,
        54.00059499965954
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/05b-c005501-d0001-001.jpg"
      },
      "observedTime": 1498694400000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Blue-Quills-Residential-School"],
      "OBJECTID": 55,
      "LocID": 502,
      "LocName": "Blue Quills (Second Location)",
      "AltNames": "Blue Quill's",
      "LocEarlyYR": 1931,
      "LocLastYR": 1990,
      "Latitude": 54.000595,
      "Longitude": -111.369699,
      "LocAuthor": "Hite / RSIM",
      "LocDesc": "Point is on the site of the main building of the Blue Quills indian Residential School. This site was the second location of the Blue Quills school. The buildings are now the Blue Quills First Nation's College.",
      "LocType": "School Location (Second Site of Blue Quills)",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/05b-c005501-d0001-001.jpg",
      "PhotoTitle": "Blue Quill's Residential School [date unknown].",
      "PhotoSour": "Grey Nuns of Montreal and NCTR",
      "BldgStatus": "Present",
      "ReligiousA": "Roman Catholic",
      "IndigComm": "Blue Quills First Nation",
      "LegacyName": null,
      "LocDate": "2017-06-29",
      "subtype": "resschool"
    },
    {
      "id": 1003,
      "title": "Crowfoot (Second Location)",
      "description": "Point is on the site of the main building of the Crowfoot Indian Residential School that was opened in 1912 and closed in 1968, and has since been torn down. This was the second location of the Crowfoot Indian Residential School.",
      "coordinates": [
        -112.88221800016055,
        50.83341599974864
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/10a-c000537-d0007-001.jpg"
      },
      "observedTime": 1510012800000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Crowfoot-Residential-School"],
      "OBJECTID": 56,
      "LocID": 512,
      "LocName": "Crowfoot (Second Location)",
      "AltNames": null,
      "LocEarlyYR": 1912,
      "LocLastYR": 1968,
      "Latitude": 50.833416,
      "Longitude": -112.882218,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the site of the main building of the Crowfoot Indian Residential School that was opened in 1912 and closed in 1968, and has since been torn down. This was the second location of the Crowfoot Indian Residential School.",
      "LocType": "School Location (Second Site of Crowfoot)",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/10a-c000537-d0007-001.jpg",
      "PhotoTitle": "Crowfoot School.  [date unknown]",
      "PhotoSour": "Archives Deschâtelets and NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Roman Catholic",
      "IndigComm": "Siksika 146",
      "LegacyName": null,
      "LocDate": "2017-11-07",
      "subtype": "resschool"
    },
    {
      "id": 1004,
      "title": "Crowfoot (First Location)",
      "description": "Point is on the approximate site of the first location of the Crowfoot Indian Residential school. The school site was located at Blackfoot Crossing, on the north side of the Bow River.",
      "coordinates": [
        -112.85899899960413,
        50.783162999872616
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/10a-c000537-d0007-001.jpg"
      },
      "observedTime": 1510012800000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Crowfoot-Residential-School"],
      "OBJECTID": 57,
      "LocID": 511,
      "LocName": "Crowfoot (First Location)",
      "AltNames": "St. Joseph Crowfoot School",
      "LocEarlyYR": 1900,
      "LocLastYR": 1912,
      "Latitude": 50.783163,
      "Longitude": -112.858999,
      "LocAuthor": "Hite",
      "LocDesc": "Point is on the approximate site of the first location of the Crowfoot Indian Residential school. The school site was located at Blackfoot Crossing, on the north side of the Bow River.",
      "LocType": "School Location (First Site of Crowfoot)",
      "LocPrecise": "Approximate",
      "LocPreDist": 0.5,
      "PhotoURL": "n/a",
      "PhotoTitle": "n/a",
      "PhotoSour": "n/a",
      "BldgStatus": "Not Present",
      "ReligiousA": "Roman Catholic",
      "IndigComm": "Siksika 146",
      "LegacyName": null,
      "LocDate": "2017-11-07",
      "subtype": "resschool"
    },
    {
      "id": 1005,
      "title": "Desmarais",
      "description": "Point is on the site of the main building of the Desmarais Indian Residential School. The building has been demolished. The site is now the location of the Northern Lakes College Wabasca Campus.",
      "coordinates": [
        -113.80734299957976,
        55.94289999973523
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/10a-c000814-d0009-001.jpg"
      },
      "observedTime": 1510099200000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Desmarais-Residential-School-St-Martins"],
      "OBJECTID": 58,
      "LocID": 521,
      "LocName": "Desmarais",
      "AltNames": "St. Martin's; Wabiscaw; Wabasca",
      "LocEarlyYR": 1902,
      "LocLastYR": 1973,
      "Latitude": 55.9429,
      "Longitude": -113.807343,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the site of the main building of the Desmarais Indian Residential School. The building has been demolished. The site is now the location of the Northern Lakes College Wabasca Campus.",
      "LocType": "School Location",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/10a-c000814-d0009-001.jpg",
      "PhotoTitle": "Desmarais School.[date unknown]",
      "PhotoSour": "Archives Deschâtelets and NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Roman Catholic",
      "IndigComm": null,
      "LegacyName": null,
      "LocDate": "2017-11-08",
      "subtype": "resschool"
    },
    {
      "id": 1006,
      "title": "Edmonton",
      "description": "Point is on the site of the main building of the Edmonton Indian Residential School. The building no longer exists. The property of the school now contains the St. Albert's Catholic Cemetery and Poundmaker's Lodge Treatment Centre",
      "coordinates": [
        -113.57259700025133,
        53.65158500014769
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/13d-c005704-d0002-001.jpg"
      },
      "observedTime": 1510531200000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Edmonton-Residential-School"],
      "OBJECTID": 59,
      "LocID": 531,
      "LocName": "Edmonton",
      "AltNames": "Poundmaker; St Albert United",
      "LocEarlyYR": 1924,
      "LocLastYR": 1968,
      "Latitude": 53.651585,
      "Longitude": -113.572597,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the site of the main building of the Edmonton Indian Residential School. The building no longer exists. The property of the school now contains the St. Albert's Catholic Cemetery and Poundmaker's Lodge Treatment Centre",
      "LocType": "School Location",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/13d-c005704-d0002-001.jpg",
      "PhotoTitle": "Edmonton Indian Residential School [ca. 1925-1936].",
      "PhotoSour": "General Council of the United Church of Canada and NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Methodist / United Church",
      "IndigComm": null,
      "LegacyName": null,
      "LocDate": "2017-11-13",
      "subtype": "resschool"
    },
    {
      "id": 1007,
      "title": "Ermineskin",
      "description": "Point is on the site of the main building (1956-1968) of the Ermineskin Indian Residential School. See IRS_LocDescription.csv for more details about the location of the second residential school building (1939-1956) and original school building.",
      "coordinates": [
        -113.45932800008711,
        52.839354999833404
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/10a-c000560-d0017-001.jpg"
      },
      "observedTime": 1510531200000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Ermineskin-Residential-School"],
      "OBJECTID": 60,
      "LocID": 541,
      "LocName": "Ermineskin",
      "AltNames": "Hobbema; Ermineskin Residence",
      "LocEarlyYR": 1895,
      "LocLastYR": 1975,
      "Latitude": 52.839355,
      "Longitude": -113.459328,
      "LocAuthor": "Hite / Orlandini",
      "LocDesc": "Point is on the site of the main building (1956-1968) of the Ermineskin Indian Residential School. See IRS_LocDescription.csv for more details about the location of the second residential school building (1939-1956) and original school building.",
      "LocType": "School Location",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/10a-c000560-d0017-001.jpg",
      "PhotoTitle": "Ermineskin Indian Residential School property in 1954.",
      "PhotoSour": "Archives Deschâtelets and NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Roman Catholic",
      "IndigComm": "Ermineskin First Nation",
      "LegacyName": "Hobbema",
      "LocDate": "2017-11-13",
      "subtype": "resschool"
    },
    {
      "id": 1008,
      "title": "Fort Vermilion",
      "description": "Point is on the site of the convent building that was used as the St. Henri Residential School. It was on River Road between 45th Street and 47th Street in Fort Vermillion.",
      "coordinates": [
        -116.01007000043012,
        58.39314799998027
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/10a-c000822-d0028-001.jpg"
      },
      "observedTime": 1510617600000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Fort-Vermilion-Residential-School"],
      "OBJECTID": 61,
      "LocID": 551,
      "LocName": "Fort Vermilion",
      "AltNames": "St. Henri; St. Henry's",
      "LocEarlyYR": 1903,
      "LocLastYR": 1968,
      "Latitude": 58.393148,
      "Longitude": -116.01007,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the site of the convent building that was used as the St. Henri Residential School. It was on River Road between 45th Street and 47th Street in Fort Vermillion.",
      "LocType": "School Location",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/10a-c000822-d0028-001.jpg",
      "PhotoTitle": "Fort Vermillion School circa 1933.",
      "PhotoSour": "Archives Deschâtelets and NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Roman Catholic",
      "IndigComm": null,
      "LegacyName": null,
      "LocDate": "2017-11-14",
      "subtype": "resschool"
    },
    {
      "id": 1009,
      "title": "Grouard",
      "description": "Point is on the building that was used as the boys' residence of the Grouard Residential School on the grounds of the Grouard Roman Catholic Mission.  See IRS_LocDescription.csv for more details about the historical buildings.",
      "coordinates": [
        -116.155508000194,
        55.54508599971916
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/10a-c000820-d0011-001.jpg"
      },
      "observedTime": 1510704000000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Grouard-Residential-School"],
      "OBJECTID": 62,
      "LocID": 561,
      "LocName": "Grouard",
      "AltNames": "St. Bernard; Lesser Slave Lake Roman Catholic",
      "LocEarlyYR": 1894,
      "LocLastYR": 1957,
      "Latitude": 55.545086,
      "Longitude": -116.155508,
      "LocAuthor": "Orlandini / RSIM",
      "LocDesc": "Point is on the building that was used as the boys' residence of the Grouard Residential School on the grounds of the Grouard Roman Catholic Mission.  See IRS_LocDescription.csv for more details about the historical buildings.",
      "LocType": "School Location",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/10a-c000820-d0011-001.jpg",
      "PhotoTitle": "Grouard Residential School, 1938.",
      "PhotoSour": "Archives Deschâtelets and NCTR",
      "BldgStatus": "Present",
      "ReligiousA": "Roman Catholic",
      "IndigComm": "Kapawe'no First Nation 150B",
      "LegacyName": null,
      "LocDate": "2017-11-15",
      "subtype": "resschool"
    },
    {
      "id": 1010,
      "title": "Holy Angels",
      "description": "Point is on the site of the building that was used as the Holy Angels Indian Residential School. The building has been torn down. The school property was part of the Roman Catholic Mission site, formerly Lot 36, currently lot 2A.",
      "coordinates": [
        -111.1735111101496,
        58.71119999998007
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/10a-c000946-d0031-001.jpg"
      },
      "observedTime": 1510876800000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Holy-Angels-Residential-School"],
      "OBJECTID": 63,
      "LocID": 571,
      "LocName": "Holy Angels",
      "AltNames": "Fort Chipewyan; Holy Angels Indian Residential School",
      "LocEarlyYR": 1874,
      "LocLastYR": 1974,
      "Latitude": 58.7112,
      "Longitude": -111.17351111,
      "LocAuthor": "NCTR",
      "LocDesc": "Point is on the site of the building that was used as the Holy Angels Indian Residential School. The building has been torn down. The school property was part of the Roman Catholic Mission site, formerly Lot 36, currently lot 2A.",
      "LocType": "School Location",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/10a-c000946-d0031-001.jpg",
      "PhotoTitle": "Ecole des Saints-Anges, 1953.",
      "PhotoSour": "Archives Deschâtelets and NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Roman Catholic",
      "IndigComm": null,
      "LegacyName": null,
      "LocDate": "2017-11-17",
      "subtype": "resschool"
    },
    {
      "id": 1011,
      "title": "Joussard",
      "description": "Point is on the site of the convent building that was part of the Joussard Indian Residential School. The school site is located on the east side of Mission Creek. All of the buildings have been torn down. See IRS_LocDescription.csv for details",
      "coordinates": [
        -115.96893600019621,
        55.40048399983675
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/10a-c000831-d0005-001.jpg"
      },
      "observedTime": 1511395200000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Joussard-Residential-School"],
      "OBJECTID": 64,
      "LocID": 581,
      "LocName": "Joussard",
      "AltNames": "St. Bruno's; Lesser Slave Lake Roman Catholic",
      "LocEarlyYR": 1913,
      "LocLastYR": 1969,
      "Latitude": 55.400484,
      "Longitude": -115.968936,
      "LocAuthor": "Orlandini / RSIM",
      "LocDesc": "Point is on the site of the convent building that was part of the Joussard Indian Residential School. The school site is located on the east side of Mission Creek. All of the buildings have been torn down. See IRS_LocDescription.csv for details",
      "LocType": "School Location",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/10a-c000831-d0005-001.jpg",
      "PhotoTitle": "Mission St. Bruno (Joussard Residential School), 1947.",
      "PhotoSour": "Archives Deschâtelets and NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Roman Catholic",
      "IndigComm": null,
      "LegacyName": null,
      "LocDate": "2017-11-23",
      "subtype": "resschool"
    },
    {
      "id": 1012,
      "title": "Lac La Biche",
      "description": "Point is on the site of the convent building that was used as the Lac La Biche school. The convent is located on the Lac La Biche Mission property.",
      "coordinates": [
        -112.09146700020187,
        54.82381100027749
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/10a-c000563-d0017-001.jpg"
      },
      "observedTime": 1511740800000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Lac-la-Biche-Residential-School"],
      "OBJECTID": 65,
      "LocID": 591,
      "LocName": "Lac La Biche",
      "AltNames": "Notre Dame des Victoires",
      "LocEarlyYR": 1893,
      "LocLastYR": 1898,
      "Latitude": 54.823811,
      "Longitude": -112.091467,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the site of the convent building that was used as the Lac La Biche school. The convent is located on the Lac La Biche Mission property.",
      "LocType": "School Location",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/10a-c000563-d0017-001.jpg",
      "PhotoTitle": "Lac La Biche Mission [ca. 1906].",
      "PhotoSour": "Archives Deschâtelets and NCTR",
      "BldgStatus": "Present",
      "ReligiousA": "Roman Catholic",
      "IndigComm": null,
      "LegacyName": null,
      "LocDate": "2017-11-27",
      "subtype": "resschool"
    },
    {
      "id": 1013,
      "title": "Lesser Slave Lake",
      "description": "Point is on the Lesser Slave Lake residential school site. The school was located on the south end of Lot 64, Section 13, Township 76, Range 15, about 300m west of the Kapawe'no No. 150C reserve boundary.",
      "coordinates": [
        -116.1924722002318,
        55.58900000044969
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/13a-c004554-d0699-001.jpg"
      },
      "observedTime": 1511740800000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Lesser-Slave-Lake-Residential-School"],
      "OBJECTID": 66,
      "LocID": 601,
      "LocName": "Lesser Slave Lake",
      "AltNames": "St. Peter's; Anglican Mission at Lesser Slave Lake",
      "LocEarlyYR": 1895,
      "LocLastYR": 1932,
      "Latitude": 55.589,
      "Longitude": -116.1924722,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the Lesser Slave Lake residential school site. The school was located on the south end of Lot 64, Section 13, Township 76, Range 15, about 300m west of the Kapawe'no No. 150C reserve boundary.",
      "LocType": "School Location",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/13a-c004554-d0699-001.jpg",
      "PhotoTitle": "St. Peter's Indian Residential School [ca. 1917-1922].",
      "PhotoSour": "General Synod of the Anglican Church of Canada Archive and the NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Anglican",
      "IndigComm": null,
      "LegacyName": null,
      "LocDate": "2017-11-27",
      "subtype": "resschool"
    },
    {
      "id": 1014,
      "title": "Morley",
      "description": "Point is on the site of the main building of the Morley Indian Residential school buildings that were torn down in 1976. The Morley Community School (Secondary School) is situated on the same location as the residential school building.",
      "coordinates": [
        -114.85496999956581,
        51.17050900009036
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/13d-c005705-d0010-001.jpg"
      },
      "observedTime": 1512259200000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Morley-Residential-School"],
      "OBJECTID": 67,
      "LocID": 611,
      "LocName": "Morley",
      "AltNames": "Stony; Morley Student Residence",
      "LocEarlyYR": 1922,
      "LocLastYR": 1969,
      "Latitude": 51.170509,
      "Longitude": -114.85497,
      "LocAuthor": "Hite",
      "LocDesc": "Point is on the site of the main building of the Morley Indian Residential school buildings that were torn down in 1976. The Morley Community School (Secondary School) is situated on the same location as the residential school building.",
      "LocType": "School Location",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/13d-c005705-d0010-001.jpg",
      "PhotoTitle": "Morley Indian Residential School. Unknown Date.",
      "PhotoSour": "General Council of the United Church of Canada and the NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Methodist / United Church",
      "IndigComm": "Stoney",
      "LegacyName": null,
      "LocDate": "2017-12-03",
      "subtype": "resschool"
    },
    {
      "id": 1015,
      "title": "Old Sun (Second Location)",
      "description": "Point is on the residential school building that was constructed in 1928. The building is currently (2018) one of the buildings at the Old Sun Community College. The school building from 1912-1928 was located on the north side of the current building.",
      "coordinates": [
        -113.06037778041019,
        50.8481555598313
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/13a-c002177-d0183-001.jpg"
      },
      "observedTime": 1513641600000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Old-Sun-Residential-School"],
      "OBJECTID": 68,
      "LocID": 623,
      "LocName": "Old Sun (Second Location)",
      "AltNames": "No alternative names",
      "LocEarlyYR": 1912,
      "LocLastYR": 1971,
      "Latitude": 50.84815556,
      "Longitude": -113.06037778,
      "LocAuthor": "NCTR",
      "LocDesc": "Point is on the residential school building that was constructed in 1928. The building is currently (2018) one of the buildings at the Old Sun Community College. The school building from 1912-1928 was located on the north side of the current building.",
      "LocType": "School Location (Second Site of Old Sun)",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/13a-c002177-d0183-001.jpg",
      "PhotoTitle": "New Blackfoot School Building (Old Sun Residential School) [ca. 1930].",
      "PhotoSour": "General Synod of the Anglican Church of Canada Archives and the NCTR",
      "BldgStatus": "Present",
      "ReligiousA": "Anglican",
      "IndigComm": "Siksika 146",
      "LegacyName": null,
      "LocDate": "2017-12-19",
      "subtype": "resschool"
    },
    {
      "id": 1016,
      "title": "White Eagle (Branch location of Old Sun)",
      "description": "Point is on the approximate location of the White Eagle school. The school was located on the east side of the Bow River and south of the ridge, about 1 mile west of the first location of the Crowfoot School. See IRS_LocDescription.csv for details.",
      "coordinates": [
        -112.92472220026929,
        50.80086110015969
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "http://ww2.glenbow.org/dbimages/arc4/d/na-1773-8.jpg"
      },
      "observedTime": 1513555200000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Old-Sun-Residential-School"],
      "OBJECTID": 69,
      "LocID": 622,
      "LocName": "White Eagle (Branch location of Old Sun)",
      "AltNames": "White Eagle Boys Boarding School",
      "LocEarlyYR": 1889,
      "LocLastYR": 1901,
      "Latitude": 50.8008611,
      "Longitude": -112.9247222,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the approximate location of the White Eagle school. The school was located on the east side of the Bow River and south of the ridge, about 1 mile west of the first location of the Crowfoot School. See IRS_LocDescription.csv for details.",
      "LocType": "School Location (First site of the Boys' School that merged with first location of Old Sun)",
      "LocPrecise": "Approximate",
      "LocPreDist": 0.5,
      "PhotoURL": "http://ww2.glenbow.org/dbimages/arc4/d/na-1773-8.jpg",
      "PhotoTitle": "South camp boarding school, Gleichen area, Alberta. [ca. 1889].",
      "PhotoSour": "Glenbow Archives NA-1773-8.",
      "BldgStatus": "Not Present",
      "ReligiousA": "Anglican",
      "IndigComm": "Siksika 146",
      "LegacyName": null,
      "LocDate": "2017-12-18",
      "subtype": "resschool"
    },
    {
      "id": 1017,
      "title": "Old Sun (First Location)",
      "description": "Point is on the approximate location of the first site of the Old Suns' school (1886-1912). It was located on the east bank of the Bow River, south of the irrigation channel and to the west of the ridge.  See IRS_LocDescription.csv for more details.",
      "coordinates": [
        -113.12788400034628,
        50.82966500033956
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "http://ww2.glenbow.org/dbimages/arcnc/1/j/nc-5-4.jpg"
      },
      "observedTime": 1513555200000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Old-Sun-Residential-School"],
      "OBJECTID": 70,
      "LocID": 621,
      "LocName": "Old Sun (First Location)",
      "AltNames": "Blackfoot",
      "LocEarlyYR": 1886,
      "LocLastYR": 1912,
      "Latitude": 50.829665,
      "Longitude": -113.127884,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the approximate location of the first site of the Old Suns' school (1886-1912). It was located on the east bank of the Bow River, south of the irrigation channel and to the west of the ridge.  See IRS_LocDescription.csv for more details.",
      "LocType": "School Location (First Site of Old Sun)",
      "LocPrecise": "Approximate",
      "LocPreDist": 0.05,
      "PhotoURL": "http://ww2.glenbow.org/dbimages/arcnc/1/j/nc-5-4.jpg",
      "PhotoTitle": "Old Sun Anglican Mission [School on the left side of photo]. 1900.",
      "PhotoSour": "Glenbow Archives NC-5-4",
      "BldgStatus": "Not Present",
      "ReligiousA": "Anglican",
      "IndigComm": "Siksika 146",
      "LegacyName": null,
      "LocDate": "2017-12-18",
      "subtype": "resschool"
    },
    {
      "id": 1018,
      "title": "Sacred Heart - Piikani",
      "description": "Point is on the site of the main school building of the Sacred Heart (Peigan) Indian Residential School. The building was demolished 1963. The school property was located at SW Section 7, Township 7, Range 27, West 4th Meridian.",
      "coordinates": [
        -113.65342499996376,
        49.54312599956529
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/10a-c000531-d0008-001.jpg"
      },
      "observedTime": 1512432000000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Sacred-Heart-Residential-School-Peigan"],
      "OBJECTID": 71,
      "LocID": 631,
      "LocName": "Sacred Heart - Piikani",
      "AltNames": "Pegan; Brocket",
      "LocEarlyYR": 1887,
      "LocLastYR": 1961,
      "Latitude": 49.543126,
      "Longitude": -113.653425,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the site of the main school building of the Sacred Heart (Peigan) Indian Residential School. The building was demolished 1963. The school property was located at SW Section 7, Township 7, Range 27, West 4th Meridian.",
      "LocType": "School Location",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/10a-c000531-d0008-001.jpg",
      "PhotoTitle": "Brocket chez les Peigans. Ecole Indienne et ferme. 1945. [Sacred Heart Residential School].",
      "PhotoSour": "Archives Deschâtelets and NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Roman Catholic",
      "IndigComm": "Piikani",
      "LegacyName": "Peigan",
      "LocDate": "2017-12-05",
      "subtype": "resschool"
    },
    {
      "id": 1019,
      "title": "St. Albert",
      "description": "Point is on the site of the Youviille (St. Albert) residential school building that was built in 1920s. The building was demolished in the 1950s or 1960s. Today (2018) the Covenant Health Youville Home is located on the site.",
      "coordinates": [
        -113.63239599967562,
        53.63820400001464
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/10a-c000585-d0003-001.jpg"
      },
      "observedTime": 1512604800000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/St-Albert-Residential-School-Youville"],
      "OBJECTID": 72,
      "LocID": 641,
      "LocName": "St. Albert",
      "AltNames": "Youville; Sturgeon River",
      "LocEarlyYR": 1873,
      "LocLastYR": 1948,
      "Latitude": 53.638204,
      "Longitude": -113.632396,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the site of the Youviille (St. Albert) residential school building that was built in 1920s. The building was demolished in the 1950s or 1960s. Today (2018) the Covenant Health Youville Home is located on the site.",
      "LocType": "School Location",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/10a-c000585-d0003-001.jpg",
      "PhotoTitle": "Youville Indian Residential School, St. Albert, Alberta. 1947.",
      "PhotoSour": "Archives Deschâtelets and NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Roman Catholic",
      "IndigComm": null,
      "LegacyName": null,
      "LocDate": "2017-12-07",
      "subtype": "resschool"
    },
    {
      "id": 1020,
      "title": "St. Augustine",
      "description": "Point is on the site of the St. Augustine residential school building. It was located to the eastside of the church. The cemetery and church are present on the site and part of the mission site now the Peace River Correctional Institute.",
      "coordinates": [
        -117.4444450000367,
        56.15558699984024
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/10a-c000835-d0012-001.jpg"
      },
      "observedTime": 1512604800000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/St-Augustine-Residential-School-Smoky-River"],
      "OBJECTID": 73,
      "LocID": 651,
      "LocName": "St. Augustine",
      "AltNames": "Smoky River; Peace River",
      "LocEarlyYR": 1900,
      "LocLastYR": 1907,
      "Latitude": 56.155587,
      "Longitude": -117.444445,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the site of the St. Augustine residential school building. It was located to the eastside of the church. The cemetery and church are present on the site and part of the mission site now the Peace River Correctional Institute.",
      "LocType": "School Location",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/10a-c000835-d0012-001.jpg",
      "PhotoTitle": "Pensionnat à la Mission S. Augustin, Peace River. [Residential School at the St. Augustin Mission, Peace River]. 1944.",
      "PhotoSour": "Archives Deschâtelets and NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Roman Catholic",
      "IndigComm": null,
      "LegacyName": null,
      "LocDate": "2017-12-07",
      "subtype": "resschool"
    },
    {
      "id": 1021,
      "title": "St. Cyprian's (Third Location of St. Cyprian's)",
      "description": "Point is on the site of the main school building of Peigan/St. Cyprian Indian Residential School. The buildings no longer exist on the property.",
      "coordinates": [
        -113.72736110003473,
        49.51691670014566
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/13a-c002177-d0139-001.jpg"
      },
      "observedTime": 1512777600000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/St-Cyprians-Residential-School-Peigan"],
      "OBJECTID": 74,
      "LocID": 663,
      "LocName": "St. Cyprian's (Third Location of St. Cyprian's)",
      "AltNames": "Peigan",
      "LocEarlyYR": 1927,
      "LocLastYR": 1961,
      "Latitude": 49.5169167,
      "Longitude": -113.7273611,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the site of the main school building of Peigan/St. Cyprian Indian Residential School. The buildings no longer exist on the property.",
      "LocType": "School Location (Third Location of St. Cyprian's)",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/13a-c002177-d0139-001.jpg",
      "PhotoTitle": "New Peigan School [St. Cyprian's Indian Residential School], [ca. 1926].",
      "PhotoSour": "General Synod of the Anglican Church of Canada Archives and the NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Anglican",
      "IndigComm": "Piikani",
      "LegacyName": "Peigan",
      "LocDate": "2017-12-09",
      "subtype": "resschool"
    },
    {
      "id": 1022,
      "title": "Queen Victoria Jubillee Home (Second Location of the St. Cyprian's)",
      "description": "Point is on the site of the Queen Victoria Jubilee Home (Peigan School). There is a monument in the field that marks the location of the school. This was the second location of the school that became St. Cyprian's.",
      "coordinates": [
        -113.79749799973285,
        49.54690500026561
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/13a-c002177-d0139-001.jpg"
      },
      "observedTime": 1512691200000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/St-Cyprians-Residential-School-Peigan"],
      "OBJECTID": 75,
      "LocID": 662,
      "LocName": "Queen Victoria Jubillee Home (Second Location of the St. Cyprian's)",
      "AltNames": "Peigan; St. Cyprian's",
      "LocEarlyYR": 1897,
      "LocLastYR": 1927,
      "Latitude": 49.546905,
      "Longitude": -113.797498,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the site of the Queen Victoria Jubilee Home (Peigan School). There is a monument in the field that marks the location of the school. This was the second location of the school that became St. Cyprian's.",
      "LocType": "School Location (Second Site of St. Cyprian's)",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/13a-c002177-d0139-001.jpg",
      "PhotoTitle": "Victoria Jubilee Home - Peigan Reserve, Brocket, Alberta. [ca. 1920].",
      "PhotoSour": "General Synod of the Anglican Church of Canada Archives and the NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Anglican",
      "IndigComm": "Piikani",
      "LegacyName": "Peigan",
      "LocDate": "2017-12-08",
      "subtype": "resschool"
    },
    {
      "id": 1023,
      "title": "St. Peter's (First Location of St. Cyprian's)",
      "description": "Point is on the approximate location of the St. Peter's Mission School, the first location of the Peigan/St. Cyprian School. St. Peter's Mission was located near the mouth of Olsen Creek and about a mile to the east of the Agency buildings.",
      "coordinates": [
        -113.66469440002652,
        49.65277779965578
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "http://ww2.glenbow.org/dbimages/arc2/e/na-668-70.jpg"
      },
      "observedTime": 1512777600000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/St-Cyprians-Residential-School-Peigan"],
      "OBJECTID": 76,
      "LocID": 661,
      "LocName": "St. Peter's (First Location of St. Cyprian's)",
      "AltNames": "Peigan Mission School",
      "LocEarlyYR": 1890,
      "LocLastYR": 1897,
      "Latitude": 49.6527778,
      "Longitude": -113.6646944,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the approximate location of the St. Peter's Mission School, the first location of the Peigan/St. Cyprian School. St. Peter's Mission was located near the mouth of Olsen Creek and about a mile to the east of the Agency buildings.",
      "LocType": "School Location (First site of St. Cyprian's)",
      "LocPrecise": "Approximate",
      "LocPreDist": 1,
      "PhotoURL": "http://ww2.glenbow.org/dbimages/arc2/e/na-668-70.jpg",
      "PhotoTitle": "Peigan girls at Anglican mission school, southern Alberta. [ca. 1892].",
      "PhotoSour": "Glenbow Archives NA-668-70",
      "BldgStatus": "Not Present",
      "ReligiousA": "Anglican",
      "IndigComm": "Piikani",
      "LegacyName": "Peigan",
      "LocDate": "2017-12-09",
      "subtype": "resschool"
    },
    {
      "id": 1024,
      "title": "St. Joseph's - Dunbow",
      "description": "Point is on the site of the St. Joseph's Residential School. The school was located on the west bank of High River, 1.8km SW of its confluence with the Bow River. The precise location of the buildings aren't known, but are within 100 metres of the point.",
      "coordinates": [
        -113.79324799979531,
        50.803949999792735
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/10a-c000545-d0001-001.jpg"
      },
      "observedTime": 1510185600000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/St-Josephs-Residential-School-Dunbow"],
      "OBJECTID": 77,
      "LocID": 671,
      "LocName": "St. Joseph's - Dunbow",
      "AltNames": "Dunbow; High River",
      "LocEarlyYR": 1884,
      "LocLastYR": 1922,
      "Latitude": 50.80395,
      "Longitude": -113.793248,
      "LocAuthor": "Hite",
      "LocDesc": "Point is on the site of the St. Joseph's Residential School. The school was located on the west bank of High River, 1.8km SW of its confluence with the Bow River. The precise location of the buildings aren't known, but are within 100 metres of the point.",
      "LocType": "School Location",
      "LocPrecise": "Approximate",
      "LocPreDist": 0.1,
      "PhotoURL": "https://nctr.ca/map/img/10a-c000545-d0001-001.jpg",
      "PhotoTitle": "Dunbow Indian School Boy's Department [St. Joseph's Residential School]. 1911.",
      "PhotoSour": "Archives Deschâtelets and NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Roman Catholic",
      "IndigComm": null,
      "LegacyName": null,
      "LocDate": "2017-11-09",
      "subtype": "resschool"
    },
    {
      "id": 1025,
      "title": "St. Mary's -  Kainai (First Location)",
      "description": "Point is on the site of St. Mary's Mission Site, the first location of St. Mary's (Blood Roman Catholic) Indian Residential School. The school/mission were located west of Standoff on the south side of the river. See IRS_LocDescription.csv for details",
      "coordinates": [
        -113.31690600001184,
        49.46185400006209
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/05b-c005505-d0003-001.jpg"
      },
      "observedTime": 1512864000000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/St-Marys-Residential-School-Blood"],
      "OBJECTID": 78,
      "LocID": 682,
      "LocName": "St. Mary's -  Kainai (First Location)",
      "AltNames": "Immaculate Conception",
      "LocEarlyYR": 1898,
      "LocLastYR": 1926,
      "Latitude": 49.461854,
      "Longitude": -113.316906,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the site of St. Mary's Mission Site, the first location of St. Mary's (Blood Roman Catholic) Indian Residential School. The school/mission were located west of Standoff on the south side of the river. See IRS_LocDescription.csv for details",
      "LocType": "School Location (First site of St. Mary's)",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/05b-c005505-d0003-001.jpg",
      "PhotoTitle": "Reserve des Gens du Sang. [St. Mary's Mission, school building in background facing church]. 1898.",
      "PhotoSour": "Archives Deschâtelets and NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Roman Catholic",
      "IndigComm": "Kainai",
      "LegacyName": "Blood 148",
      "LocDate": "2017-12-10",
      "subtype": "resschool"
    },
    {
      "id": 1026,
      "title": "St. Mary's - Kanai (Second Location)",
      "description": "Point is on the second site of St. Mary's (Blood Roman Catholic) Residential School, specifically the main school building that opened in 1926 and closed in 1988. See the IRS_LocDescription.csv for more details",
      "coordinates": [
        -113.34410000041464,
        49.29872222035425
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/05b-c005505-d0003-001.jpg"
      },
      "observedTime": 1512864000000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/St-Marys-Residential-School-Blood"],
      "OBJECTID": 79,
      "LocID": 681,
      "LocName": "St. Mary's - Kanai (Second Location)",
      "AltNames": "Blood Roman Catholic Residential School",
      "LocEarlyYR": 1926,
      "LocLastYR": 1988,
      "Latitude": 49.29872222,
      "Longitude": -113.3441,
      "LocAuthor": "NCTR",
      "LocDesc": "Point is on the second site of St. Mary's (Blood Roman Catholic) Residential School, specifically the main school building that opened in 1926 and closed in 1988. See the IRS_LocDescription.csv for more details",
      "LocType": "School Location (Second Site of St. Mary's Blood)",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/05b-c005505-d0003-001.jpg",
      "PhotoTitle": "École Ste-Marie, Cardston, AB [circa 1948].",
      "PhotoSour": "Grey Nuns of Montreal and NCTR",
      "BldgStatus": "Remains Present",
      "ReligiousA": "Roman Catholic",
      "IndigComm": "Kainai",
      "LegacyName": "Blood 148",
      "LocDate": "2017-12-10",
      "subtype": "resschool"
    },
    {
      "id": 1027,
      "title": "St. Paul's - Kainai (First Location)",
      "description": "Point is on the site of first location of the St. Paul's (Blood Church of England) Indian Residential School, that was at this location from 1893-1925. The school grounds were located on an island opposite from the Blood Agency.",
      "coordinates": [
        -113.24697500009614,
        49.59142300007147
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/13a-c002177-d0004-001.jpg"
      },
      "observedTime": 1512950400000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/St-Pauls-Residential-School-Blood"],
      "OBJECTID": 80,
      "LocID": 691,
      "LocName": "St. Paul's - Kainai (First Location)",
      "AltNames": "Blood Church of England Indian Residential School",
      "LocEarlyYR": 1893,
      "LocLastYR": 1925,
      "Latitude": 49.591423,
      "Longitude": -113.246975,
      "LocAuthor": "Orlandini / Hite",
      "LocDesc": "Point is on the site of first location of the St. Paul's (Blood Church of England) Indian Residential School, that was at this location from 1893-1925. The school grounds were located on an island opposite from the Blood Agency.",
      "LocType": "School Location (First siteof St. Paul's)",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/13a-c002177-d0004-001.jpg",
      "PhotoTitle": "Girl's House, Blood Reserve, Alberta. [St. Paul's Indian Residential School]. 1920.",
      "PhotoSour": "Anglican Diocese of Calgary and the NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Anglican",
      "IndigComm": "Kainai",
      "LegacyName": "Blood 148",
      "LocDate": "2017-12-11",
      "subtype": "resschool"
    },
    {
      "id": 1028,
      "title": "St. Paul's - Kainai (Second Location)",
      "description": "Point is on the main school building of the St. Paul's Residential School, that was at this site from 1925 to 1975. See the IRS_LocDescription.csv for more details",
      "coordinates": [
        -113.38877500026604,
        49.230834999935325
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/13a-c002177-d0004-001.jpg"
      },
      "observedTime": 1513036800000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/St-Pauls-Residential-School-Blood"],
      "OBJECTID": 81,
      "LocID": 692,
      "LocName": "St. Paul's - Kainai (Second Location)",
      "AltNames": "Blood Church of England Indian Residential School",
      "LocEarlyYR": 1925,
      "LocLastYR": 1975,
      "Latitude": 49.230835,
      "Longitude": -113.388775,
      "LocAuthor": "Hite",
      "LocDesc": "Point is on the main school building of the St. Paul's Residential School, that was at this site from 1925 to 1975. See the IRS_LocDescription.csv for more details",
      "LocType": "School Location (Second Location of St. Paul's)",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/13a-c002177-d0004-001.jpg",
      "PhotoTitle": "St. Paul's Anglican Indian Residential School Cardston, ALTA. 1950.",
      "PhotoSour": "General Synod of the Anglican Church of Canada Archives and the NCTR",
      "BldgStatus": "Present",
      "ReligiousA": "Anglican",
      "IndigComm": "Kainai",
      "LegacyName": "Blood 148",
      "LocDate": "2017-12-12",
      "subtype": "resschool"
    },
    {
      "id": 1029,
      "title": "Sarcee",
      "description": "Point is on the site of the main building of the Sacree Indian Residential School. The building has been torn down. The school grounds are north of Old Agency Road to the east of the Anglican Church.",
      "coordinates": [
        -114.1481111001471,
        50.93316670028304
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/13a-c004554-d0617-001.jpg"
      },
      "observedTime": 1513123200000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Sarcee-Residential-School"],
      "OBJECTID": 82,
      "LocID": 701,
      "LocName": "Sacree",
      "AltNames": "St. Barnabas; St. Barnabas Mission Boarding School",
      "LocEarlyYR": 1892,
      "LocLastYR": 1921,
      "Latitude": 50.9331667,
      "Longitude": -114.1481111,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the site of the main building of the Sacree Indian Residential School. The building has been torn down. The school grounds are north of Old Agency Road to the east of the Anglican Church.",
      "LocType": "School Location",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/13a-c004554-d0617-001.jpg",
      "PhotoTitle": "The Sarcee School in 1920.",
      "PhotoSour": "General Synod of the Anglican Church of Canada Archives and the NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Anglican",
      "IndigComm": "T'suu Tina 145",
      "LegacyName": "Sacree 145",
      "LocDate": "2017-12-13",
      "subtype": "resschool"
    },
    {
      "id": 1030,
      "title": "Sturgeon Lake",
      "description": "Point is on the site of the convent building of Sturgeon Lake (St-Francois-Xavier) Residential School. The building no longer exists. The convent/school was located south of St. Francis Xavier church, on the north side of the road.",
      "coordinates": [
        -117.50238400002155,
        55.071641999578674
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/10a-c000830-d0004-001.jpg"
      },
      "observedTime": 1513296000000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Sturgeon-Lake-Residential-School"],
      "OBJECTID": 83,
      "LocID": 711,
      "LocName": "Sturgeon Lake",
      "AltNames": "Calais; St. Francis Xavier",
      "LocEarlyYR": 1907,
      "LocLastYR": 1961,
      "Latitude": 55.071642,
      "Longitude": -117.502384,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the site of the convent building of Sturgeon Lake (St-Francois-Xavier) Residential School. The building no longer exists. The convent/school was located south of St. Francis Xavier church, on the north side of the road.",
      "LocType": "School Location",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/10a-c000830-d0004-001.jpg",
      "PhotoTitle": "Le Couvent-Pensionnat Lac Sturgeon. [The Sturgeon Lake convent and residential school]. 1945.",
      "PhotoSour": "Archives Deschâtelets and NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Roman Catholic",
      "IndigComm": "Sturgeon Lake First Nation",
      "LegacyName": null,
      "LocDate": "2017-12-15",
      "subtype": "resschool"
    },
    {
      "id": 1031,
      "title": "Wabasca",
      "description": "Point is on the site of the school building of the Wabasca Indian Residential School. The school was situated near St. Theresa School on Neewatin Drive in Wabasca. The original buildings no longer exist on the site.",
      "coordinates": [
        -113.89119900043352,
        55.98275400029695
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/13a-c004553-d0004-001.jpg"
      },
      "observedTime": 1510272000000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Wabasca-Residential-School-Athabasca-Landing"],
      "OBJECTID": 84,
      "LocID": 721,
      "LocName": "Wabasca",
      "AltNames": "St. John's; Athabasca Landing",
      "LocEarlyYR": 1902,
      "LocLastYR": 1966,
      "Latitude": 55.982754,
      "Longitude": -113.891199,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the site of the school building of the Wabasca Indian Residential School. The school was situated near St. Theresa School on Neewatin Drive in Wabasca. The original buildings no longer exist on the site.",
      "LocType": "School Location",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/13a-c004553-d0004-001.jpg",
      "PhotoTitle": "New Wabasca School [ca. 1951]",
      "PhotoSour": "General Synod of the Anglican Church of Canada Archives and the NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Anglican",
      "IndigComm": null,
      "LegacyName": null,
      "LocDate": "2017-11-10",
      "subtype": "resschool"
    },
    {
      "id": 1032,
      "title": "Whitefish Lake",
      "description": "Point is on the site of the Whitefish Lake Indian Residential School grounds, which was in lot 4 of the Atikameg Settlement. The site is located between Imugumuk road and the shoreline. See the IRS_LocDescription.csv for more details",
      "coordinates": [
        -115.66033330014164,
        55.91858330017919
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/13a-c004554-d0761-001.jpg"
      },
      "observedTime": 1489536000000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Whitefish-Lake-Residential-School"],
      "OBJECTID": 85,
      "LocID": 731,
      "LocName": "Whitefish Lake",
      "AltNames": "St. Andrew's; Atikameg",
      "LocEarlyYR": 1908,
      "LocLastYR": 1950,
      "Latitude": 55.9185833,
      "Longitude": -115.6603333,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the site of the Whitefish Lake Indian Residential School grounds, which was in lot 4 of the Atikameg Settlement. The site is located between Imugumuk road and the shoreline. See the IRS_LocDescription.csv for more details",
      "LocType": "School Location",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/13a-c004554-d0761-001.jpg",
      "PhotoTitle": "St. Andrew's School, White Fish Lake, Atla. [ca. 193?]",
      "PhotoSour": "General Synod of the Anglican Church of Canada Archives and the NCTR",
      "BldgStatus": "Not Present",
      "ReligiousA": "Anglican",
      "IndigComm": "Utikoomak Lake 155",
      "LegacyName": null,
      "LocDate": "2017-03-15",
      "subtype": "resschool"
    },
    {
      "id": 1033,
      "title": "Calgary Industrial School",
      "description": "Point is on the site of the residential school building of the Calgary Industrial School. The school was located in the NE Quarter, Section 35, Township 23, Range 1, West 5th Meridian. See the IRS_LocDescription.csv for more details",
      "coordinates": [
        -114.02648100039784,
        51.00412200013028
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "http://ww2.glenbow.org/dbimages/arc1/e/na-75-2.jpg"
      },
      "observedTime": 1513123200000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Calgary-Industrial-School"],
      "OBJECTID": 174,
      "LocID": 1401,
      "LocName": "Calgary Industrial School",
      "AltNames": "St. Dunstan's",
      "LocEarlyYR": 1896,
      "LocLastYR": 1907,
      "Latitude": 51.004122,
      "Longitude": -114.026481,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the site of the residential school building of the Calgary Industrial School. The school was located in the NE Quarter, Section 35, Township 23, Range 1, West 5th Meridian. See the IRS_LocDescription.csv for more details",
      "LocType": "School Location",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "http://ww2.glenbow.org/dbimages/arc1/e/na-75-2.jpg",
      "PhotoTitle": "Pupils of Calgary Industrial School, Calgary, Alberta. 1907.",
      "PhotoSour": "Glenbow Archives NA-75-2",
      "BldgStatus": "Not Present",
      "ReligiousA": "Anglican",
      "IndigComm": null,
      "LegacyName": null,
      "LocDate": "2017-12-13",
      "subtype": "resschool"
    },
    {
      "id": 1034,
      "title": "Red Deer Industrial",
      "description": "Point is on the site of the Red Deer Industrial School, that was situated in the southeast corner of Section 14SE, Township 38, Range 28, West of the 4th Meridian. The property is a privately owned farm. See the IRS_LocDescription.csv for more details",
      "coordinates": [
        -113.88726099959541,
        52.26023099966096
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "https://nctr.ca/map/img/13d-c005706-d0008-001.jpg"
      },
      "observedTime": 1510531200000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/index.php/Red-Deer-Industrial-School"],
      "OBJECTID": 175,
      "LocID": 1411,
      "LocName": "Red Deer Industrial",
      "AltNames": "Red Deer Indian Industrial School",
      "LocEarlyYR": 1893,
      "LocLastYR": 1919,
      "Latitude": 52.260231,
      "Longitude": -113.887261,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the site of the Red Deer Industrial School, that was situated in the southeast corner of Section 14SE, Township 38, Range 28, West of the 4th Meridian. The property is a privately owned farm. See the IRS_LocDescription.csv for more details",
      "LocType": "School Location",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "https://nctr.ca/map/img/13d-c005706-d0008-001.jpg",
      "PhotoTitle": "Red Deer Institute [circa 1900].",
      "PhotoSour": "United Church of Canada Archives",
      "BldgStatus": "Not Present",
      "ReligiousA": "Methodist",
      "IndigComm": null,
      "LegacyName": null,
      "LocDate": "2017-11-13",
      "subtype": "resschool"
    },
    {
      "id": 1035,
      "title": "McDougall Orphanage and Residential School (First Location)",
      "description": "Point is on the site of the Mission House that was used as the first site of the McDougall orphanage and school. The McDougall mission house was located below the ridge to the east of the McDougall Church, north of the river and west of the creek.",
      "coordinates": [
        -114.82237500010615,
        51.18693400030395
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "http://ww2.glenbow.org/dbimages/arc2/c/na-626-1.jpg"
      },
      "observedTime": 1512086400000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/informationobject/browse?topLod=0&query=mcdougall+orphanage&repos="],
      "OBJECTID": 176,
      "LocID": 1421,
      "LocName": "McDougall Orphanage and Residential School (First Location)",
      "AltNames": null,
      "LocEarlyYR": 1880,
      "LocLastYR": 1885,
      "Latitude": 51.186934,
      "Longitude": -114.822375,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the site of the Mission House that was used as the first site of the McDougall orphanage and school. The McDougall mission house was located below the ridge to the east of the McDougall Church, north of the river and west of the creek.",
      "LocType": "School Location (First site of McDougall Orphanage)",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "http://ww2.glenbow.org/dbimages/arc2/c/na-626-1.jpg",
      "PhotoTitle": "Methodist Mission at Morley, Alberta [First location of the McDougall Orphanage]. June 1881.",
      "PhotoSour": "Glenbow Archives NA-626-1",
      "BldgStatus": "Not Present",
      "ReligiousA": "Methodist",
      "IndigComm": "Kainai",
      "LegacyName": null,
      "LocDate": "2017-12-01",
      "subtype": "resschool"
    },
    {
      "id": 1036,
      "title": "McDougall Orphanage and Residential School (Second Location)",
      "description": "Point is on the site of the building that was second location of the McDougall Orphanage/School, which was in the McDougall Settlement. See IRS_LocDescription.csv for details",
      "coordinates": [
        -114.76213800026846,
        51.213694399776784
      ],
      "media": {
        "type": "image",
        "status": "external",
        "url": "http://uccdigitalcollections.ca/archive/fullsize/80e103fa437abd1cacc88aeb16ae01a4.jpg"
      },
      "observedTime": 1512086400000,
      "hashtags": "landuse,indigenous",
      "relatedUrls": ["National Centre for Truth and Reconciliation", "https://archives.nctr.ca/informationobject/browse?topLod=0&query=mcdougall+orphanage&repos="],
      "OBJECTID": 177,
      "LocID": 1422,
      "LocName": "McDougall Orphanage and Residential School (Second Location)",
      "AltNames": null,
      "LocEarlyYR": 1885,
      "LocLastYR": 1908,
      "Latitude": 51.2136944,
      "Longitude": -114.762138,
      "LocAuthor": "Orlandini",
      "LocDesc": "Point is on the site of the building that was second location of the McDougall Orphanage/School, which was in the McDougall Settlement. See IRS_LocDescription.csv for details",
      "LocType": "School Location (Second Site of the McDougall Orphanage)",
      "LocPrecise": "Precise",
      "LocPreDist": 0,
      "PhotoURL": "http://uccdigitalcollections.ca/archive/fullsize/80e103fa437abd1cacc88aeb16ae01a4.jpg",
      "PhotoTitle": "McDougall Orphanage for Indian children, Morlet Alta. [circa 188?].",
      "PhotoSour": "United Church of Canada Archives",
      "BldgStatus": "Not Present",
      "ReligiousA": "Methodist",
      "IndigComm": "Kainai",
      "LegacyName": null,
      "LocDate": "2017-12-01",
      "subtype": "resschool"
    }
  ];

  export default abResSchools;