<template>
  <ul class="nav nav-mobile-menu" />
</template>
<script>
export default {
  data () {
    return {
      search: null,
      selectedEmployee: null,
      employees: [
      ]
    }
  }
}
</script>
