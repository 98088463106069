<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <div class="md-card-content">
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-xsmall-size-100 md-size-70">
              <h3 class="md-layout md-layout-item md-size-100">
                {{ resource.title }}
              </h3>
              <div class="md-layout-item md-size-100">
                <md-icon class="time-icon">
                  access_time
                </md-icon>
                {{ resource.approximateTime }}
              </div>
            </div>
            <div class="md-layout-item md-small-size-100 md-xsmall-size-100 md-size-30">
              <md-button
                id="ResourcesBackBtn"
                class="md-raised md-default center-vertical"
                @click="backToAll"
              >
                <md-icon>arrow_back</md-icon> Back to All Resources
              </md-button>
            </div>
          </div>
        </div>
      </md-card>
      <md-card>
        <div class="md-card-content">
          <div class="md-layout">
            <!-- left column - description, outcomes, files -->
            <div class="md-layout-item layout-no-padding md-xsmall-size-100 md-size-60 md-layout">
              <!-- description -->
              <div class="md-layout-item md-size-100">
                {{ resource.description }}
              </div>
              <!-- learning outcomes -->
              <div
                v-if="resource.learningOutcomes.length"
                class="md-layout-item md-size-100"
              >
                <h4>
                  <md-icon class="md-size-2x outcomes-icon">
                    check_circle
                  </md-icon>Learning Outcomes
                </h4>
                <div
                  v-for="item in resource.learningOutcomes"
                  :key="item"
                  class="md-layout"
                >
                  <div class="md-layout-item md-size-10 md-alignment-top-left learning-outcome">
                    <md-icon>
                      check_circle
                    </md-icon>
                  </div>
                  <div class="md-layout-item md-size-90 md-alignment-top-left learning-outcome">
                    {{ item }}
                  </div>
                </div>
              </div>
              <!-- files -->
              <div
                v-if="numFiles > 0"
                class="md-layout-item md-size-100"
              >
                <md-divider class="padded-divider" />
                <h4>
                  <md-icon class="file-badge md-size-2x">
                    folder
                  </md-icon>Files
                </h4>
                <div
                  v-for="item in resource.files"
                  :key="item[1]"
                  class="md-layout"
                >
                  <div class="md-layout-item md-size-10 md-alignment-top-left resource-file">
                    <i :class="getFileIconClass(item[1])" />
                  </div>
                  <div class="md-layout-item md-size-90 md-alignment-top-left resource-file">
                    <a
                      v-if="getFileResourceType(item[1]) === 'url'"
                      :href="item[1]"
                      target="_blank"
                      v-text="item[0]"
                    />
                    <a
                      v-else
                      :href="item[1]"
                      @click.prevent="downloadFile(item[1])"
                      v-text="item[0]"
                    />
                  </div>
                </div>
                <md-divider class="padded-divider" />
              </div>
            </div>
            <!-- right column - tags -->
            <div class="md-layout-item md-xsmall-size-100 md-size-40">
              <div class="md-layout-item md-size-100 layout-no-padding tag-section">
                <h6>
                  Grades:
                </h6>
                <md-chips
                  v-model="grades"
                  md-static
                  class="md-info"
                />
              </div>
              <div class="md-layout-item md-size-100 layout-no-padding tag-section">
                <h6>
                  Subjects:
                </h6>
                <md-chips
                  v-model="resource.subjects"
                  md-static
                  class="md-success"
                />
              </div>
              <div class="md-layout-item md-size-100 layout-no-padding tag-section">
                <h6>
                  Curriculum Tags:
                </h6>
                <div>
                  <md-chips
                    v-model="resource.tags"
                    md-static
                    class="md-default"
                  />
                </div>
              </div>
              <div
                v-if="resource.provider !== ''"
                class="md-layout-item md-size-100 layout-no-padding tag-section"
              >
                <h6>
                  This Resource Provided By:
                </h6>
                <md-chip
                  md-clickable
                  class="md-default"
                  @click="launchUrl(resource.provider[1])"
                >
                  {{ resource.provider[0] }}
                </md-chip>
              </div>
              <div
                v-if="resource.relatedUrls.length"
                class="md-layout-item md-size-100 layout-no-padding tag-section"
              >
                <h6>
                  Related Links:
                </h6>
                <md-chip
                  v-for="res in resource.relatedUrls"
                  :key="res[1]"
                  md-clickable
                  class="md-warning"
                  @click="launchUrl(res[1])"
                >
                  {{ res[0] }}
                </md-chip>
              </div>
            </div>
          </div>
        </div>
      </md-card>
      <!-- videos -->
      <md-card v-if="numVideos > 0">
        <div class="md-card-content">
          <div class="md-layout">
            <div class="md-layout-item md-size-100">
              <h4>
                <md-icon class="video-badge md-size-2x">
                  videocam
                </md-icon>Videos
              </h4>
            </div>
            <!-- video player -->
            <div
              class="md-layout-item active-video-player md-size-100"
            >
              <md-card>
                <md-card-header>
                  <div class="md-title">
                    {{ currentVideoTitle }}
                  </div>
                </md-card-header>
                <md-card-content>
                  <video-player
                    v-if="videoActive"
                    :options="getVideoOptions(currentVideo)"
                    :title="currentVideoTitle"
                    :is360="is360Video"
                  />
                </md-card-content>
              </md-card>
            </div>
            <!-- playlist -->
            <div
              v-if="numVideos > 1"
              class="md-layout-item md-size-100 md-layout"
            >
              <div
                v-for="vid in resource.videos"
                :key="vid.title"
                class="md-layout-item md-size-25 md-large-size-33 md-medium-size-33 md-small-size-50 md-xsmall-size-100"
              >
                <md-card md-with-hover>
                  <md-ripple>
                    <md-card-media-cover md-solid>
                      <md-card-media md-ratio="16:9">
                        <img
                          :src="vid.media.thumbUrl"
                          :alt="vid.title"
                          @click="launchVideo(vid)"
                        >
                      </md-card-media>
                      <md-card-header>
                        <span
                          class="title"
                          @click="launchVideo(vid)"
                        >
                          {{ vid.title }}
                        </span>
                      </md-card-header>
                    </md-card-media-cover>
                  </md-ripple>
                </md-card>
              </div>
            </div>
          </div>
        </div>
      </md-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VideoPlayer from "@/components/VideoPlayer";
import { VideoOptions } from "@/pages/Dashboard/Components/mixins/VideoOptions";
import { TeacherResources } from "@/pages/Dashboard/Components/mixins/TeacherResources";
export default {
  name: "TeacherResourceDetails",

  components: {
    VideoPlayer,
  },

  mixins: [TeacherResources, VideoOptions],

  props: {
  },

  data() {
    return {
      videoActive: false,
      currentVideoTitle: "Now Playing",
      is360Video: false,
      currentVideo: null
    }
  },

  beforeMount() {
    if (this.numVideos > 0) {
      this.currentVideoTitle = this.resource.videos[0].title;
      this.currentVideo = this.resource.videos[0];
      this.is360Video = 'is360' in this.resource.videos[0].media ? this.resource.videos[0].media.is360 : false;
      this.videoActive = true;
    }
  },
  methods: {
    backToAll() {
      // emit back to all resources
      this.$emit('on-back');
    },
    getFileResourceType(resource) {
      // returns whether a specific file resource is a URL or an actual document/file
      if (resource.toLowerCase().startsWith('http')) {
        return 'url';
      }
      return 'file';
    },
    getFileType(filename) {
      // return the file extension of a file
      const re = /(?:\.([^.]+))?$/;
      return re.exec(filename)[1].toLowerCase();
    },
    getFileIconClass(filename) {
      // return an icon to match a filetype
      // <i class="fas fa-file-archive"></i>
      // <i class="fas fa-file"></i>
      // <i class="fas fa-file-pdf"></i>
      const filetype = this.getFileType(filename);
      let iconClass = `fas fa-2x fa-file`;
      if (['pdf', 'zip'].includes(filetype)) {
        iconClass += filetype.replace('pdf', '-pdf').replace('zip', '-archive');
      }
      return iconClass;
    },
    async downloadFile(filename) {
      // dowload a resource file
      const vm = this;
      // analytics tracking
      const label = `${this.resource.key}, ${filename}`;
      this.$Helpers.gtagEvent(vm, `download_resource`, `resources`, label);
      const url = await this.$Helpers.getDownloadURL(this.$Bucket, this.$Auth, this.$API, filename);
      axios.get(url, { responseType: 'blob' })
        .then(response => {
          const filetype = `application/${vm.getFileType(filename)}`;
          const blob = new Blob([response.data], { type: filetype })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = filename
          link.click()
          URL.revokeObjectURL(link.href)
        })
        // eslint-disable-next-line no-console
        .catch(console.error)
    },
    launchUrl(url) {
      // launch a link in a new window/tab
      if (url !== "") {
        window.open(url, '_blank');
      }
    },
    launchVideo(videoObj) {
      // launch a selected video into the player
      const vm = this;
      const setupVideo = function (vm) {
        vm.currentVideoTitle = videoObj.title;
        vm.is360Video = 'is360' in videoObj.media ? videoObj.media.is360 : false;
        vm.currentVideo = videoObj;
        vm.videoActive = true;
      }
      if (this.videoActive) {
        // clear previous video and wait before relaunching
        this.videoActive = false;
        setTimeout(function () {
          setupVideo(vm)
          vm.$Helpers.scrollToElement(vm, 'active-video-player');
        }, 250);
      } else {
        // launch video
        setupVideo(vm);
        setTimeout(function () {
          vm.$Helpers.scrollToElement(vm, 'active-video-player');
        }, 250);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.padded-divider {
  margin-top: 20px;
}
.learning-outcome {
  margin: 10px 0 0 0;
  i {
    color: $brand-success !important;
  }
}
.outcomes-icon {
  color: $brand-success !important;
  margin-right: 12px;
}
.file-badge {
  color: $brand-warning !important;
  margin-right: 12px;
}
.resource-file {
  margin: 10px 0 0 0;
  i {
    margin-left: 5px;
    color: $brand-warning !important;
  }
}
.video-badge {
  color: $brand-info !important;
  margin-right: 12px;
}
</style>
