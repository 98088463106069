<template>
  <div />
</template>

<script>
// this component connects and subscribes to a single IoT topic
// whenever a new message is published on that topic, the message is emitted back to the parent component
import AWS from 'aws-sdk';
import AWSIoTData from 'aws-iot-device-sdk';
import { v4 as uuidv4 } from "uuid";
export default {
  name: 'IoTSub',
  props: {
    topic: {
      type: String,
      required: true
    }
  },

  data() {
    return {
    };
  },

  created() {
    // console.log('IoT component created for topic', this.topic);
    const vm = this;
    const AWSConfiguration = {
      poolId: vm.$API._options.aws_cognito_identity_pool_id, // 'YourCognitoIdentityPoolId'
      host: vm.$API._options.scenario_tracker, // 'YourAwsIoTEndpoint', e.g. 'prefix.iot.us-east-1.amazonaws.com'
      region: vm.$API._options.aws_cognito_region // 'YourAwsRegion', e.g. 'us-east-1'
    };

    const clientId = `${vm.topic}_${uuidv4()}`;
    AWS.config.region = AWSConfiguration.region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: AWSConfiguration.poolId
    });

    // setup the mqttClient
    // note at this stage there will be a js error that can be safely ignored.
    // client is connecting before it has received credentials from cognito (getting credentials is async) .. https://github.com/aws/aws-iot-device-sdk-js/issues/199
    const mqttClient = AWSIoTData.device({
      region: AWSConfiguration.region,
      host: AWSConfiguration.host,
      clientId: clientId,
      protocol: 'wss',
      maximumReconnectTimeMs: 8000,
      debug: false,
      accessKeyId: '',
      secretKey: '',
      sessionToken: ''
    });

    const cognitoIdentity = new AWS.CognitoIdentity();
    const getCreds = () => {
      AWS.config.credentials.get((err) => {
        if (!err) {
          // debugging
          // console.log('retrieved identity: ' + AWS.config.credentials.identityId);
          const params = {
            IdentityId: AWS.config.credentials.identityId
          };
          cognitoIdentity.getCredentialsForIdentity(params, (err, data) => {
            // debugging
            // console.log('CREDS:', data);
            if (!err) {
              mqttClient.updateWebSocketCredentials(data.Credentials.AccessKeyId,
                data.Credentials.SecretKey,
                data.Credentials.SessionToken);
              // } else {
              // debugging
              // console.log('error retrieving credentials: ' + err);
            }
          });
          // } else {
          // debugging
          // console.log('error retrieving identity:' + err);
        }
      })
    };

    mqttClient.on('connect', () => {
      mqttClient.subscribe(vm.topic);
      // debugging
      // console.log('mqttClient connected and subscribed to topic:', vm.topic);
      vm.$emit('on-connected', vm.topic);
    });

    // eslint-disable-next-line
    mqttClient.on('error', (err) => {
      if (vm.authDone) {
        // debugging
        // console.log('mqttClient error:', err);
      }
      getCreds();
    });

    mqttClient.on('message', (topic, payload) => {
      const msg = JSON.parse(payload.toString());
      // debugging
      // console.log('mqttClient message: ', msg);
      // Send the message back to parent component
      vm.$emit('on-topic-update', vm.topic, msg.message);
    });
  }
}
</script>
