// mixin for some scenario changes handling
import { mapGetters } from "vuex";
import { types } from "@/store/types";

export const ScenarioChanges = {
  computed: {
    /* jshint ignore:start */
    ...mapGetters([types.getters.ACTIVE_SCENARIO])
    /* jshint ignore:end */
  },

  methods: {
    validate() {
      // some required field validations
      if (!this.ACTIVE_SCENARIO.allocations.length) {
        return new Promise(resolve => {
          this.errorAlert(
            "You haven't proposed any landscape changes.  Please create at least one change to proceed."
          );
          resolve(false);
        });
      }

      return new Promise(resolve => {
        resolve(true);
      }).then(res => {
        this.$emit("on-validated", res, "tab_changes");
        return res;
      });
    }
  }
};
