<template>
  <div
    v-if="url"
    class="technical-doc"
  >
    <a
      :href="url"
      target="_blank"
    >
      <slot>
        <i class="fas fa-info-circle" />
        View Technical Document
      </slot>
    </a>
  </div>
</template>

<script>
export default {
  name: "TechnicalDoc",
  data() {
    return {
        url: this.$Region.technicalDocURL,
    };
  },

};
</script>

<style lang="scss" scoped>
.technical-doc {
  margin-top: 1.5em;
}
.technical-doc > a {
  color: white !important;
}
</style>
