<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <div class="md-card-content">
            <h3>Connecting With Your Students</h3>
            <p>
              Students can be linked to your teacher profile in several ways:
            </p>
            <ul>
              <li>
                Manually setting up new student accounts and selecting you as
                their teacher during sign-up.
              </li>
              <li>
                Students who already have accounts can update their profile and
                select you as their teacher.
              </li>
              <li>
                As a teacher, you can register one or more students using the
                bulk sign-up wizard below.
              </li>
            </ul>
          </div>
        </md-card>
      </div>
      <div class="md-layout-item md-size-100">
        <simple-wizard
          ref="wizard"
          :wizard-updating="wizardUpdating"
          :finish-button-text="finishButtonText"
          :on-cancel="cancelWizard"
          :show-prev-button="showPrevButton"
          :prevent-prev-nav="preventPrevNav"
          @tab-change="tabchange"
        >
          <template slot="header">
            <h3 class="title">
              Bulk Student Sign-up
            </h3>
            <div
              class="md-layout-item md-size-100"
            >
              What grade are the students you are registering?
              <md-field>
                <md-select
                  v-model="grade"
                  name="grade"
                >
                  <md-option
                    v-for="item in $Region.gradeOptions"
                    :key="item.id"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
          </template>
          <wizard-tab :before-change="() => validateStep('studentList')">
            <template slot="label">
              Student Info
            </template>
            <bulk-wizard-student-list
              ref="studentList"
              :toggle-reset="toggleReset"
              @on-validated="onStepValidated"
            />
          </wizard-tab>
          <wizard-tab
            :before-change="() => validateStep('reviewList')"
          >
            <template slot="label">
              Review
            </template>
            <bulk-wizard-review
              ref="reviewList"
              :school="USER_DATA.school"
              :grade="grade"
              :grid-data="bulkData.gridData"
              :toggle-update="toggleReview"
              @on-api-post-start="toggleUpdatingOn"
              @on-api-post-done="toggleUpdatingOff"
              @on-data-updated="onDataUpdated"
              @on-validated="onStepValidated"
              @on-users-created="onNewUsersCreated"
            />
          </wizard-tab>
          <wizard-tab :before-change="() => validateStep('confirmList')">
            <template slot="label">
              Confirm
            </template>
            <bulk-wizard-confirm
              ref="confirmList"
              :school="USER_DATA.school"
              :grade="grade"
              :grid-data="bulkData.gridData"
              :toggle-update="toggleConfirm"
              @on-import-complete="onImportComplete"
            />
          </wizard-tab>
        </simple-wizard>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { types } from "@/store/types";
import { SimpleWizard, WizardTab } from "@/components";
import BulkWizardStudentList from "@/pages/Dashboard/Components/BulkWizardStudentList";
import BulkWizardReview from "@/pages/Dashboard/Components/BulkWizardReview";
import BulkWizardConfirm from "@/pages/Dashboard/Components/BulkWizardConfirm";
import { TeacherFunctions } from "@/pages/Dashboard/Components/mixins/TeacherFunctions";
import swal from "sweetalert2";

export default {
  name: "BulkWizard",

  components: {
    SimpleWizard,
    WizardTab,
    BulkWizardStudentList,
    BulkWizardReview,
    BulkWizardConfirm,
  },
  mixins: [TeacherFunctions],
  props: {},

  data() {
    return {
      bulkData: {
        gridData: [],
      },
      grade: this.$Region.gradeOptions[0].name,
      wizardUpdating: false,
      finishButtonText: "Done",
      showPrevButton: true,
      toggleReset: false,
      toggleReview: false,
      toggleConfirm: false,
      preventPrevNav: false,
    };
  },

  computed: {
    ...mapGetters([types.getters.USER_DATA]),

    isTeacher() {
      return (
        "usertype" in this.USER_DATA && this.USER_DATA.usertype === "teacher"
      );
    },
  },

  watch: {},

  beforeRouteEnter(to, from, next) {
    // prevent access to non-teachers
    next((vm) => {
      if (!vm.isTeacher) {
        vm.$router.push(from.path);
      }
    });
  },

  methods: {
    validateStep(ref) {
      // validate using the validate() function on the ref component
      return this.$refs[ref].validate();
    },
    onStepValidated(res, model) {
      // update the data from the child component
      let uniqueID = 0;
      const generateID = () => {
        // generate ID's for each row so we can track server-side step2 validation
        const newID = uniqueID;
        uniqueID += 1;
        return newID;
      };
      // eslint-disable-next-line
      this.bulkData.gridData = [...model].map(({ firstname, lastname, UserName, email, password, ...rest }) => {
          // only keep these fields...
          return {
            firstname,
            lastname,
            UserName,
            email,
            password,
            importID: generateID(),
          };
        }
      );
      this.toggleReview = !this.toggleReview;
      this.$refs.wizard.nextTab();
    },
    onDataUpdated(model) {
      // update the data from the review list child component
      this.$refs.studentList.onDataUpdated(model);
      // eslint-disable-next-line
      this.bulkData.gridData = [...model].map(({ firstname, lastname, UserName, email, password, importID, ...rest }) => {
          // only keep these fields...
          return {
            firstname,
            lastname,
            UserName,
            email,
            password,
            importID,
          };
        }
      );
    },
    onNewUsersCreated() {
      // trigger actions when new users are successfully created
      this.toggleConfirm = !this.toggleConfirm;
      // force reload of students
      this.studentsLoad(true);
    },
    cancelWizard() {
      // clear wizard and any uploaded data
      this.toggleReset = !this.toggleReset;
      this.$refs.wizard.navigateToTab(0);
    },
    toggleApiResponseReceived(ref) {
      // toggle apiResponseReceived status on child component
      this.$refs[ref].toggleApiResponseReceived();
    },
    toggleListValidated() {
      // change validation status of studentlist
      this.$refs['studentList'].toggleListValidated();
    },
    tabchange(oldTab, newTab) {
      // watch for tab changes and manage the validation status
      if (newTab.tabId == '0') {
        this.toggleListValidated();
        this.toggleApiResponseReceived('reviewList');
        this.nextButtonText = "Next";
      } else if (newTab.tabId == '1') {
        this.nextButtonText = "Import Users";
      } else if (newTab.tabId == '2') {
        this.showPrevButton = false;
        this.nextButtonText = "Done";
        this.preventPrevNav = true;
      }
    },
    onImportComplete() {
      // close the wizard and notify user when all processes are completed
      swal.fire({
        title: `User accounts creation complete!`,
        text: `The users you registered are now ready to go. Direct users to login and start working with the simulator!`,
        icon: 'success',
        customClass: {
          confirmButton: "md-button md-success",
        },
        buttonsStyling: false
      });
      // return to dashboard
      this.$router.push({
        name: "Dashboard",
      });
    },
    toggleUpdatingOn() {
      this.wizardUpdating = true;
    },
    toggleUpdatingOff() {
      this.wizardUpdating = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
