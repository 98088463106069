<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <h2>{{ tabTitle }}</h2>
      <p>{{ tabSubtitle }}</p>
    </div>
    <label
      class="md-layout-item md-size-15 md-xsmall-size-100 md-small-size-30 md-medium-size-20 md-form-label"
      :class="{'align-left': rightSidebarFullScreen}"
    >Scenario Title</label>
    <div class="md-layout-item md-size-85 md-xsmall-size-100 md-small-size-70 md-medium-size-80">
      <md-field>
        <md-input
          v-model="scenarioTitle"
          type="text"
        />
        <span class="md-helper-text">Use a concise, short title like "Urban Densification"</span>
      </md-field>
    </div>

    <label
      class="md-layout-item md-size-15 md-xsmall-size-100 md-small-size-30 md-medium-size-20 md-form-label"
      :class="{'align-left': rightSidebarFullScreen}"
    >Scenario Description</label>
    <div class="md-layout-item md-size-85 md-xsmall-size-100 md-small-size-70 md-medium-size-80">
      <md-field>
        <label>{{ scenarioDescriptionInfo }}</label>
        <md-textarea
          v-model="scenarioDescription"
          type="text"
        />
        <span class="md-helper-text" />
      </md-field>
    </div>
    <div v-if="showIndIndicators">
      <h4>
        What collection of indicators would you like to explore with your scenario?
      </h4>
      <div>
        <md-button
          v-for="type in indCollections"
          :key="type[0]"
          :class="getButtonClass(type[2])"
          class="studyareatype-button md-dense"
          @click="setIndCollection(type[2])"
        >
          <i :class="type[1]" />
          {{ type[0] }}
        </md-button>
      </div>
    </div>
    <div v-if="!systemScenario">
      <div class="md-layout-item md-size-100">
        <h4>Your Planning Goals</h4>
      </div>
      <template v-if="regionIndicatorsReady && bitmapsReady && SELECTED_REGIONS.length > 0">
        <!-- indigenous / alternate indicator collection -->
        <cover-indicator-card
          v-if="showAltIndicatorCollection"
          :activity="'create'"
          :scenario="scenario"
          :simulation-progress="0"
          :simulation-state="simulationState"
          :region-indicators="altIndicators"
          :indicator-type="indicatorCollection"
          class="md-layout-item md-size-100"
        />
        <template v-else>
          <cover-indicator-card
            :activity="'create'"
            :scenario="scenario"
            :simulation-progress="0"
            :simulation-state="simulationState"
            :region-indicators="regionIndicators"
            :indicator-type="'Environmental'"
            class="md-layout-item md-size-100"
          />
          <cover-indicator-card
            :activity="'create'"
            :scenario="scenario"
            :simulation-progress="0"
            :simulation-state="simulationState"
            :region-indicators="regionIndicators"
            :indicator-type="'Socio-Economic'"
            class="md-layout-item md-size-100"
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import CoverIndicatorCard from '../Components/CoverIndicatorCard';
import { ScenarioWizardHelpers } from "@/pages/Dashboard/Components/mixins/ScenarioWizardHelpers";
import { ResponsiveLayoutHelpers } from "@/pages/Dashboard/Components/mixins/ResponsiveLayoutHelpers";

export default {
  name: "ScenarioGoals",

  components: {
    CoverIndicatorCard
  },
  mixins: [ScenarioWizardHelpers, ResponsiveLayoutHelpers],

  props: {
    regionIndicators: {
      type: Array,
      required: true
    },
    regionIndicatorsReady: {
      type: Boolean,
      default: false
    },
    bitmapsReady: {
      type: Boolean,
      default: false
    },
    scenario: {
      type: Object,
      default: function () {
        return {};
      },
    },
    simulationState: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      scenarioTitle: null,
      scenarioDescription: null,
      indicatorCollection: 'standard',
    };
  },

  computed: {
    tabTitle() {
      return this.systemScenario ? `General Info` : `Goal Setting`;
    },
    tabSubtitle() {
      const subtitle = `Let's give your scenario a name`;
      return this.systemScenario ? subtitle : `${subtitle} and set some goals`;
    },
    scenarioDescriptionInfo() {
      return this.systemScenario ? `Provide a brief description of your scenario` : `Provide a brief explanation outlining your goals`;
    },
    showIndIndicators() {
      // whether or not to show indigenous indicators
      if (
        'indigenousModuleActive' in this.$Region &&
        this.$Region.indigenousModuleActive &&
        'indigenousIndicatorsCollection' in this.$Region &&
        this.$Region.indigenousIndicatorsCollection
      ) {
        return true;
      }
      return false;
    },
    indCollections() {
      const types = [['Environmental / Socio-Economic', 'fas fa-leaf', 'standard']]
      if (this.showIndIndicators) {
        types.push(['Indigenous Voices', 'fas fa-feather', 'Indigenous'])
      }
      return types
    },
    showAltIndicatorCollection() {
      // whether or not to display the alternative indicator collection
      return (this.indicatorCollection !== 'standard');
    },
    altIndicators() {
      // get the alternative indicator collection
      if ('getAltIndicators' in this.$Region) {
        return this.$Region.getAltIndicators(this.indicatorCollection);
      }
      return [];
    },
  },

  watch: {
  },

  beforeMount() {
  },

  mounted() {
  },

  beforeDestroy() {
  },

  methods: {
    validate() {
      // some required field validations
      // make sure user has actually entered some goals and not just used defaults
      if (!this.systemScenario) {
        let goalsSet = false;
        Object.keys(this.ACTIVE_SCENARIO.goals).forEach(goal => {
          const ind = this.$Region.indicators.find(ind => ind.name === goal)
          // goals initialize using defaults from region-config indicators
          if (this.ACTIVE_SCENARIO.goals[goal] !== ind.goal) {
            goalsSet = true;
          }
        });
        if (!goalsSet) {
          return new Promise(resolve => {
            this.errorAlert("Please set some goals for your scenario before proceeding");
            resolve(false);
          });
        }
      }

      // needs a title
      if (!this.scenarioTitle) {
        return new Promise(resolve => {
          this.errorAlert("Please provide a title for your scenario")
          resolve(false);
        });
      }

      // needs a description
      if (!this.scenarioDescription) {
        return new Promise(resolve => {
          this.errorAlert("Please enter a description for your scenario")
          resolve(false);
        });
      }

      // all valid -- update active scenario title and description
      this.UPDATE_ACTIVE_SCENARIO({ name: this.scenarioTitle, description: this.scenarioDescription, indicatorCollection: this.indicatorCollection });
      return new Promise(resolve => {
        resolve(true);
      }).then(res => {
        this.$emit("on-validated", res, 'tab_goals');
        return res;
      });
    },
    getButtonClass(type) {
      if (this.indicatorCollection === type) {
        return `md-raised md-primary`;
      }
      return `md-flat md-accent`;
    },
    setIndCollection(type) {
      this.indicatorCollection = type;
    }
  }
};
</script>

<style lang="scss" scoped>
.align-left {
  text-align: left !important;
}
</style>
