<template>
  <!-- a challenge with title, description, and either a quiz or a quest -->
  <div class="md-layout">
    <div class="md-layout-item md-size-100 md-layout md-alignment-center-center">
      <span class="md-layout-item md-size-100 challenge-title">
        <i class="fas fa-rocket fa-4x" />
        <h3 class="md-layout-item md-size-100">
          Challenge: {{ challenge.title }}
        </h3>
      </span>
      <p class="md-layout-item md-size-100">
        {{ challenge.description }}
      </p>
    </div>
    <!-- QUIZ TYPE CHALLENGE -->
    <div
      v-if="challenge.type === 'quiz'"
      class="md-layout-item md-size-100 md-layout md-alignment-center-center"
    >
      <p class="md-layout-item md-size-100 md-subheading">
        {{ challenge.question }}
      </p>
      <div class="md-layout-item md-layout md-size-100 md-alignment-center-center answers-section">
        <collapse-transition>
          <p
            v-if="showWarning"
            class="challenge-warning"
          >
            Please select an answer or choose "Not Sure"
          </p>
        </collapse-transition>
        <md-radio
          v-for="(answer, index) in challenge.answers"
          :key="index"
          v-model="selectedAnswer"
          :value="answer"
          :disabled="answersDisabled"
          class="md-layout-item md-size-80"
          :class="{correctanswer: (index === challenge.correctIndex && activeContentSection !== 'answers'), incorrectanswer: (answer === selectedAnswer && index !== challenge.correctIndex && activeContentSection !== 'answers')}"
        >
          <i
            v-if="index === challenge.correctIndex && activeContentSection !== 'answers'"
            class="fas fa-check-circle md-success correctanswer"
          />
          <i
            v-else-if="answer === selectedAnswer && index !== challenge.correctIndex && activeContentSection !== 'answers'"
            class="fas fa-times-circle md-danger incorrectanswer"
          />
          {{ answer }}
        </md-radio>
        <span
          v-if="activeContentSection === 'pass'"
          class="badge badge-primary points-counter"
        >
          <points-counter />
        </span>
      </div>
      <div class="md-layout-item md-size-100">
        <p>&nbsp;</p>
      </div>
      <div
        v-if="activeContentSection === 'answers'"
        class="md-layout-item md-size-80 md-layout md-alignment-center-center"
      >
        <div class="md-layout-item md-size-50">
          <md-button
            class="md-raised md-default"
            @click="skipChallenge"
          >
            Not sure
          </md-button>
        </div>
        <div class="md-layout-item md-size-50">
          <md-button
            class="md-raised md-success"
            @click="answerChallenge"
          >
            Submit
          </md-button>
        </div>
      </div>
      <div
        v-else
        class="md-layout-item md-size-100 md-layout md-alignment-center-center md-body-2"
      >
        <p>
          <span class="md-bold">
            {{ challenge[`${activeContentSection}Msg`] }}
          </span>
        </p>
      </div>
      <collapse-transition>
        <div
          v-if="activeContentSection !== 'answers'"
          class="md-layout-item md-size-80 md-layout md-alignment-center-center"
        >
          <md-button
            class="md-raised md-success"
            @click="closeChallenge"
          >
            Continue
          </md-button>
        </div>
      </collapse-transition>
    </div>
    <!-- QUEST TYPE CHALLENGE -->
    <div
      v-else-if="challenge.type === 'quest'"
      class="md-layout-item md-size-100 md-layout"
    >
      <p class="md-layout-item md-size-100 md-subheading">
        {{ challenge.prompt }}
      </p>
      <md-list class="md-layout-item md-size-100 activities-list">
        <md-list-item
          v-for="(item, index) in challenge.activities"
          :key="item"
        >
          <md-icon
            v-if="questTaskComplete(index)"
            class="task-complete"
          >
            check_box
          </md-icon>
          <md-icon v-else>
            check_box_outline_blank
          </md-icon>
          <span class="md-list-item-text">{{ item }}</span>
        </md-list-item>
      </md-list>
      <div
        v-if="activeContentSection === 'answers' && userCompletedQuest"
        class="md-layout-item md-size-100 md-layout md-alignment-center-center quest-accepted"
      >
        <div class="md-layout-item md-size-100 text-align-center">
          <md-button
            class="md-raised md-success"
            @click="closeQuest"
          >
            <md-icon>
              check
            </md-icon>
            You Completed this Quest - Way to Go!
          </md-button>
        </div>
      </div>
      <div
        v-else-if="activeContentSection === 'answers'"
        class="md-layout-item md-size-100 md-layout md-alignment-center-center"
      >
        <div class="md-layout-item md-size-50 md-xsmall-size-100 text-align-center">
          <md-button
            class="md-raised md-default"
            @click="closeQuest"
          >
            No thanks
          </md-button>
        </div>
        <div class="md-layout-item md-size-50 md-xsmall-size-100 text-align-center">
          <md-button
            class="md-raised md-success"
            @click="acceptQuest"
          >
            {{ questAcceptBtnMsg }}
          </md-button>
        </div>
      </div>
      <div
        v-else
        class="md-layout-item md-size-100 md-layout md-alignment-center-center quest-accepted"
      >
        <h3 class="md-layout-item md-size-100">
          Challenge Accepted!
        </h3>
        <p class="md-layout-item md-size-100">
          Good luck. You can do it!
        </p>
        <md-button
          class="md-raised md-success"
          @click="closeAcceptedQuest(false)"
        >
          Continue
        </md-button>
      </div>
    </div>
    <div class="md-layout-item md-size-100">
      <p>&nbsp;</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import { CollapseTransition } from 'vue2-transitions';
import { User } from "@/pages/Dashboard/Components/mixins/User";
import PointsCounter from "@/pages/Dashboard/Components/PointsCounter";

export default {
  name: "Challenge",

  components: {
    CollapseTransition,
    PointsCounter
  },
  mixins: [User], // user profile methods for updating points, view history

  props: {
    challenge: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      selectedAnswer: null,
      showWarning: false,
      activeContentSection: "answers" // one of ['answers', 'pass', 'fail', 'learn', 'accepted'] to determine which content block to display
    }
  },

  computed: {
    ...mapGetters([
      types.getters.ACTIVE_QUEST
    ]),

    answersDisabled() {
      return this.activeContentSection !== 'answers';
    },
    questAcceptBtnMsg() {
      return (this.currentQuestIsActive) ? `Continue the Quest` : `I accept - let's go!`;
    },
    userCompletedQuest() {
      // has the user already done this quest
      return ('history' in this.USER_DATA && this.USER_DATA.history.includes(`c_${this.challenge.code}`));
    },
    noActiveQuest() {
      // there is no active quest
      return this.ACTIVE_QUEST === null;
    },
    currentQuestIsActive() {
      // the current challenge quest is already the active quest
      return (this.ACTIVE_QUEST !== null && this.ACTIVE_QUEST.code === this.challenge.code);
    },
    activeQuestIsDifferent() {
      // there is an active quest and it's different than the current challenge quest
      return (this.ACTIVE_QUEST !== null && this.ACTIVE_QUEST.code !== this.challenge.code);
    }
  },

  methods: {
    ...mapActions([
      types.actions.SET_ACTIVE_QUEST,
      types.actions.CLEAR_ACTIVE_QUEST,
      types.actions.TOGGLE_QUEST_BUTTON_ANIMATION,
      types.actions.TOGGLE_CHALLENGE_SUCCESS_ANIMATION
    ]),

    skipChallenge() {
      // user clicks not sure
      this.showWarning = false;
      this.activeContentSection = 'learn';
    },
    answerChallenge() {
      // when user answers challenge
      if (this.selectedAnswer === null) {
        // if no answer was provided, show a warning
        this.showWarning = true;
      } else {
        this.showWarning = false;
        if (this.selectedAnswer === this.challenge.answers[this.challenge.correctIndex]) {
          // correct answer - pass
          this.activeContentSection = 'pass';
          // give some points!
          this.addUserPoints(this.challenge.points);
          // yay rockets!
          this.TOGGLE_CHALLENGE_SUCCESS_ANIMATION();
          // update profile -- add to user's completed challenges
          const historyItem = `c_${this.challenge.code}`;
          // call the API and update the user profile
          this.updateUserHistory(historyItem); // updates DB and vuex store
        } else {
          // fail
          this.activeContentSection = 'fail';
        }
      }
    },
    acceptQuest() {
      // accept the quest
      if (this.currentQuestIsActive) {
        // quest was already accepted previously... just close and move on
        this.closeAcceptedQuest(true);
      } else {
        // update any inprogress items to set into new quest
        if ('inprogress' in this.USER_DATA && this.USER_DATA.inprogress.filter(item => item.type === "quest").length > 0) {
          const progressIndex = this.USER_DATA.inprogress.findIndex(item => item.type === "quest" && item.code === this.challenge.code);
          if (progressIndex !== -1) {
            const vm = this;
            this.USER_DATA.inprogress[progressIndex].completed.forEach((item) => {
              vm.challenge.passConditions[item].complete = true;
            });
          }
        }
        // new quest... set it up
        this.SET_ACTIVE_QUEST(this.challenge);
        this.activeContentSection = 'accepted';
      }
    },
    questTaskComplete(activityIndex) {
      // check if a specific task in a quest has been completed or not
      if (this.userCompletedQuest) {
        // user already completed the quest - all tasks done
        return true;
      }
      // check tasks based purely on status of active quest (doesn't check historical activities)
      if (this.noActiveQuest || this.activeQuestIsDifferent) {
        // check for inprogress items
        const vm = this;
        if ('inprogress' in this.USER_DATA && this.USER_DATA.inprogress.length > 0 && this.USER_DATA.inprogress.some(item => item.type === "quest")) {
          const progressIndex = this.USER_DATA.inprogress.findIndex(item => item.type === "quest" && item.code === vm.challenge.code);
          if (progressIndex !== -1) {
            return vm.$Region.challenges.find(obj => obj.type === 'quest' && obj.code === vm.challenge.code).passConditions
            .map((cond, index) => ({ ...cond, index: index }))
            .filter((cond) => cond.activity === activityIndex)
            .every(cond => vm.USER_DATA.inprogress[progressIndex].completed.includes(cond.index));
          }
        }
        return false;
      }
      // return (!this.ACTIVE_QUEST.passConditions.some(cond => cond.activity === activityIndex));
      return (!this.ACTIVE_QUEST.passConditions.some(cond => cond.activity === activityIndex && !Object.keys(cond).includes('complete')));
    },
    closeQuest() {
      this.CLEAR_ACTIVE_QUEST();
      // close the dialog
      this.$emit('closeDialog');
    },
    closeAcceptedQuest(skipSetup) {
      // close the dialog and send callback setup function
      this.TOGGLE_QUEST_BUTTON_ANIMATION();
      if (this.challenge.setupConditions && !skipSetup) {
        this.$emit('closeDialog', this.challenge.setupConditions);
      } else {
        this.$emit('closeDialog');
      }
    },
    closeChallenge() {
      // close the dialog
      this.$emit('closeDialog');
    }

  }
};
</script>

<style lang="scss" scoped>
.challenge-title i {
  color: $brand-primary;
  margin-top: 30px;
  margin-bottom: 20px;
  display: block;
  text-align: center;
}
.challenge-title h3,
.quest-accepted h3 {
  text-align: center;
  font-weight: 600;
}
.challenge-warning {
  color: $brand-danger;
  font-weight: 500;
}
.quest-accepted p {
  text-align: center;
}
/deep/ .md-radio.md-disabled .md-radio-label,
.md-radio.md-disabled .md-radio-container {
  opacity: 1;
}
/deep/ .correctanswer label {
  color: $brand-success;
  font-weight: 600;
}
/deep/ .correctanswer .md-radio-container {
  visibility: hidden;
}
/deep/ .correctanswer i {
  margin-left: -25px;
  margin-right: 8px;
}
/deep/ .incorrectanswer label {
  color: $brand-danger;
  font-weight: 600;
}
/deep/ .incorrectanswer .md-radio-container {
  visibility: hidden;
}
/deep/ .incorrectanswer i {
  margin-left: -25px;
  margin-right: 8px;
}
.answers-section {
  margin-bottom: 20px;
}
.activities-list {
  margin-top: -20px;
  margin-bottom: 20px;
}
.activities-list li .md-list-item-text {
  white-space: normal;
  overflow: visible;
  position: relative;
  top: 5px;
  left: -20px;
}
/deep/ .task-complete {
  color: $brand-success !important;
}
.points-counter {
  margin-top: 20px;
}
.text-align-center {
  text-align: center;
}
</style>
