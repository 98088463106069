<template>
  <md-card md-with-hover>
    <md-card-header :class="headerClass">
      <div class="md-layout">
        <div class="md-layout-item md-size-100 card-title">
          <span class="md-title">
            {{ badge.title }}
          </span>
        </div>
      </div>
    </md-card-header>
    <md-card-content class="md-layout">
      <div class="md-layout-item md-size-100 layout-no-padding">
        <badge-icon
          :badge="badge"
          :achieved="achieved"
        />
      </div>
      <div class="md-layout-item md-size-100 layout-no-padding">
        <p
          v-if="achieved"
          class="badge-text"
        >
          {{ badge.successMsg }}
          <br>
          <md-chip class="tasks-chip">
            {{ badge.points }} {{ 'point' | pluralize(badge.points) }}
          </md-chip>
        </p>
        <p
          v-else
          class="badge-text"
        >
          {{ badge.description }}
        </p>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import BadgeIcon from "@/pages/Dashboard/Components/BadgeIcon";
export default {
  name: "UserBadgeCard",

  components: {
    BadgeIcon
  },

  props: {
    badge: {
      type: Object,
      required: true
    },
    achieved: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
    };
  },

  computed: {
    headerClass() {
      return this.achieved ? `md-card-header-danger` : `md-card-header-primary card-header-gray`;
    },
  },

  methods: {
  }
};
</script>

<style lang="scss" scoped>
.card-title-icon {
  position: relative;
  margin: 0 0 0 -15px;
  padding: 0px;
  color: $white-color !important;
}
.card-title {
  color: $white-color !important;
}
.md-title {
  font-size: 16px;
  line-height: 20px;
}
.md-subhead {
  margin: 5px 0 0 0;
}
.card-actions {
  text-align: right;
}
.launch-btn {
  left: 5px;
  margin-top: 10px;
}
.tasks-chip {
  background: linear-gradient(60deg, $orange-400, $orange-600) !important;
}
/deep/ .card-header-gray {
  background: linear-gradient(60deg, $grey-300, $grey-400) !important;
}
.badge-text {
    margin-top: 0px !important;
    padding-top: 0 !important;
    text-align: center;
}
</style>
