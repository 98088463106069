<template>
  <md-card class="side-panel-card layout-padding-none">
    <md-card-header class="layout-gray-dark layout-padding-small">
      <div
        id="LandscapeSummaryPanelTitle"
        class="md-title"
      >
        <i class="fa fa-chart-pie" />
        Landscape Summary
      </div>
    </md-card-header>
    <md-tabs
      :md-active-tab="ACTIVE_LAND_SUMMARY_TAB"
      class="md-primary layout-padding-none"
      @md-changed="updateTab"
    >
      <md-tab
        id="tab_lt_table"
        md-label=""
        md-icon="/img/th-list-solid.svg"
        class="layout-gray"
      >
        <div class="md-title table-title">
          <whats-a-ha>
            <template v-slot:title>
              Landscape & Landuse by Type
            </template>
            (ha)
            <i class="fas fa-question-circle" />
            <md-tooltip md-direction="bottom">
              How big is a ha?
            </md-tooltip>
          </whats-a-ha>
        </div>
        <land-summary-table
          :year="year"
          :min-year="minYear"
          :max-year="maxYear"
          :simulation-state="simulationState"
        />
      </md-tab>
      <md-tab
        id="tab_lt_pie"
        md-label=""
        md-icon="/img/chart-pie-solid.svg"
        class="layout-gray"
      >
        <div class="md-title table-title">
          Landscape Composition (%)
        </div>
        <land-composition-chart
          :year="year"
          :min-year="minYear"
          :max-year="maxYear"
          :simulation-state="simulationState"
        />
      </md-tab>
      <md-tab
        id="tab_lt_mgmt"
        md-label="Management"
        md-icon="/img/tasks-solid.svg"
        class="layout-gray"
      >
        <land-practices-table
          :scenario="scenario"
          :region-dev-rate="regionDevRate"
          :show-info-btn="true"
          class="layout-gray"
          @onDevRateChange="onDevRateChange"
        />
      </md-tab>
    </md-tabs>
  </md-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import LandSummaryTable from "./LandSummaryTable";
import LandCompositionChart from "./LandCompositionChart";
import LandPracticesTable from "./LandPracticesTable";
import WhatsAHa from "./WhatsAHa";

export default {
  name: "LandscapeSummaryPanel",

  components: {
    LandSummaryTable,
    LandCompositionChart,
    LandPracticesTable,
    WhatsAHa
  },

  props: {
    year: {
      type: Number,
      required: true
    },
    minYear: {
      type: Number,
      required: true
    },
    maxYear: {
      type: Number,
      required: true
    },
    scenario: {
      type: Object,
      required: true,
    },
    simulationState: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters([types.getters.ACTIVE_LAND_SUMMARY_TAB]),
    landUseTypes() {
      return this.$Region.landUseTypes.concat(this.$Region.noChangeLandUseTypes);
    },
    icons() {
      return this.$Region.icons;
    },
    regionDevRate() {
      if ('devRateSliderHelper' in this.$Region) {
        // if available, load historic, current, future dev rates and interpolate during playback
        return this.$Region.devRateSliderHelper(this.scenario, this.simulationState, this.$Helpers.Interpolate);
      }
      return this.simulationState.regionData.currentValues.devRate;
    }
  },

  methods: {
    ...mapActions([types.actions.SET_LAND_SUMMARY_TAB]),
    updateTab(activeTab) {
      this.SET_LAND_SUMMARY_TAB(activeTab);
    },
    getLTAreaChange(type) {
      const _year = this.year !== this.minYear ? this.year : this.maxYear; // show maxYear-minYear at start of simulation
      const num =
        this.simulationState.simAreas[type][_year] -
        this.simulationState.simAreas[type][this.minYear];
      return (num <= 0 ? "" : "+") + num;
    },
    onDevRateChange() {
      // notify of dev rate change
      this.$emit('onDevRateChange');
    }
  }
};
</script>

<style lang="scss" scoped>
.md-tab {
  padding: 0 0 24px 0;
}
.table-title {
  margin: 5px 0 0px 10px !important;
}
/deep/ .ha-info {
  color: $white-color !important;
}
</style>