/* jshint esversion: 8 */
// DynamoDB does not accept empty strings - make them "null"
const bodyEmptyStringToNull = function(body) {
  const new_body = {};

  Object.keys(body).map((key) => {
    new_body[key] = String(body[key]) || "null";
  });

  return new_body;
};

const mapScenAllocations = function(allocations) {
  // map allocations into format saved in dynamo/used by the lambda function
  const _allocations = {};
  allocations.forEach((alloc) => {
    _allocations[alloc.id] = Object.assign({}, alloc); // j.s.hint didn't seem to like spread ...alloc
    delete _allocations[alloc.id].id; //remove the id key from inside the obj
  });
  return _allocations;
};

const remapScenData = function(body, uuidv4) {
  // scen data is stored in dynamo in slightly different form
  // remap the data from dynamo into format usable by app
  // uuidv4 function passed so we can add uuidv4's where needed to be backwards compatible
  const scen = {};
  Object.keys(body).forEach((key) => {
    if (key === "UserTransaction") {
      scen.id = parseInt(body[key].S.replace("scen", ""));
    } else if (key === "allocations") {
      const allocArray = [];
      const alloc = JSON.parse(body[key].S.replace(/'/g, '"'));
      Object.keys(alloc).forEach((allocKey) => {
        const _uuid =
          "uuid" in alloc[allocKey] ? alloc[allocKey].uuid : uuidv4();
        allocArray.push({
          name: alloc[allocKey].name,
          id: parseInt(allocKey),
          area: alloc[allocKey].area,
          coords: alloc[allocKey].coords,
          coordsProj: alloc[allocKey].coordsProj,
          uuid: _uuid,
        });
      });
      scen[key] = allocArray;
    } else if (key === "goals") {
      scen[key] = JSON.parse(body[key].S.replace(/'/g, '"'));
    } else if (key === "management") {
      scen[key] = JSON.parse(body[key].S.replace(/'/g, '"'));
    } else if (key === "devRate") {
      scen[key] = parseFloat(body[key].S);
    } else if (Object.keys(body[key])[0] === "N") {
      scen[key] = parseFloat(body[key].N);
    } else if (Object.keys(body[key])[0] === "S") {
      scen[key] = body[key].S;
    } else if (Object.keys(body[key])[0] === "BOOL") {
      scen[key] = body[key].BOOL;
    }
  });
  return scen;
};

const remapObsProperties = function(item) {
  // parse observation data from dynamo into geojson format
  const obsProperties = {};
  const _keys = Object.keys(item);
  // remove the fields that don't need updating
  const ignoreKeys = ["geohash", "GeoEntityType"];

  // parse the data from the api into the observation properties
  _keys.forEach((key) => {
    // skip the ignoreKeys
    if (!ignoreKeys.includes(key)) {
      // details and media fields need to be parsed into JSON
      if (["details", "media"].includes(key)) {
        obsProperties[key] = JSON.parse(item[key].S.replace(/'/g, '"'));
      } else if (Object.keys(item[key])[0] === "N") {
        obsProperties[key] = parseFloat(item[key].N);
      } else if (Object.keys(item[key])[0] === "S") {
        obsProperties[key] = item[key].S;
      } else if (Object.keys(item[key])[0] === "BOOL") {
        obsProperties[key] = item[key].BOOL;
      }
    }
  });
  return obsProperties;
};

/**
 * remap the report data coming from a dynamodb api call into format used in app
 *
 * @param {Object} body - the API response body
 * @param {Array} userScenarios - the list of user scenarios for looking up references
 * @returns a report object
 */
const remapRepData = function(body, userScenarios) {
  // rep data is stored in dynamo in slightly different form
  // remap the data from dynamo into format usable by app
  const rep = {};
  Object.keys(body).forEach((key) => {
    if (key === "UserTransaction") {
      rep.id = parseInt(body[key].S.replace("rep", ""));
    } else if (key === "scenarios") {
      // get the actual scenario objects based on the id's stored in the report db record
      const scenIDs = JSON.parse(body[key].S.replace(/'/g, '"'));
      const scens = [];
      scenIDs.forEach((scenID) => {
        scens.push(userScenarios.find((scen) => scen.id === scenID));
      });
      rep[key] = scens;
      // also save just the id's for simple lookups
      rep.scenIDs = scenIDs;
    } else if (Object.keys(body[key])[0] === "N") {
      rep[key] = parseFloat(body[key].N);
    } else if (Object.keys(body[key])[0] === "S") {
      rep[key] = body[key].S;
    } else if (Object.keys(body[key])[0] === "BOOL") {
      rep[key] = body[key].BOOL;
    }
  });
  return rep;
};

// collect the climate transition details for a scenario
const getScenClimateTransitions = function(
  climateModuleActive,
  climateScenarios,
  selectedScenario
) {
  if (climateModuleActive) {
    const transitions = climateScenarios.find(
      (scen) => scen.variable === selectedScenario
    ).transitions;
    return transitions;
  }
  return [];
};

// collect the climate indicator details for a scenario
const getScenClimateIndicatorDetails = function(
  climateModuleActive,
  climateScenarios,
  climateIndicators,
  selectedScenario
) {
  if (climateModuleActive) {
    const transitions = [];
    const climscen = climateScenarios.find(
      (scen) => scen.variable === selectedScenario
    );
    climateIndicators.forEach((ind) => {
      transitions.push({
        baseRaster: `${ind.baseRasterPrefix}_${climscen.indicatorBaseRasterKey}`,
        minval: ind.minval,
        maxval: ind.maxval,
        repeatAfer: climscen.indicatorRepeatAfter,
      });
    });
    return transitions;
  }
  return [];
};

const sanitizeDBPayload = function(payload) {
  // remove any newlines, carriage returns, tabs from json values
  Object.keys(payload).forEach((key) => {
    if (typeof payload[key] === "string") {
      payload[key] = payload[key]
        .replace(/\n/g, " ")
        .replace(/\\n/g, " ")
        .replace(/\r/g, " ")
        .replace(/\\r/g, " ")
        .replace(/\t/g, " ")
        .replace(/\\t/g, " ");
    }
  });
};

const abbreviateNumber = function(number) {
  // text number formatting for large numbers
  // var SI_POSTFIXES = ["", "k", "M", "G", "T", "P", "E"];
  // more common postfixes used in NA (use B instead of G for billion)
  const SI_POSTFIXES = ["", "k", "M", "B", "T", "P", "E"];
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;
  if (tier == 0) return parseFloat(number).toFixed(2);
  const postfix = SI_POSTFIXES[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = number / scale;
  let formatted = scaled.toFixed(1) + "";
  if (/\.0$/.test(formatted))
    formatted = formatted.substr(0, formatted.length - 2);
  return formatted + postfix;
};

const getPrevOrNextVal = function(array, val, dir) {
  // to get the closest lower or higher year in the bitmaps to interpolate to
  for (let i = 0; i < array.length; i++) {
    if (dir == "prev") {
      if (array[i] > val) {
        return array[i - 1] || 0;
      }
    } else {
      if (array[i] >= val) {
        return array[i];
      }
    }
  }
};

/*
Interpolate constructor

With properties for the expected min/max outputs
Usage:
> translate = new Interpolate();
> val = translate.from(0, 5).to(0, 100).linear(2.5);
> console.assert(val === 50);
OR
> val = translate.from(0, 5).to(0, 100).exponential(2.5);
> console.assert(val === 9.04987562112089);

*/
function Interpolate() {
  this.input_from = null;
  this.input_to = null;
  this.output_from = null;
  this.output_to = null;
  this.output_min = null;
  this.output_max = null;
}

Interpolate.prototype.from = function(min, max) {
  /*
	x values (input range)
	*/
  this.input_from = Number(min);
  this.input_to = Number(max);
  return this;
};

Interpolate.prototype.to = function(_min, _max) {
  /*
	y values (desired output range)
	*/
  const min = Number(_min),
    max = Number(_max);
  this.output_from = min;
  this.output_to = max;
  this.output_min = Math.min(min, max);
  this.output_max = Math.max(min, max);
  return this;
};

Interpolate.prototype.checkBounds = function(output) {
  /*
	check if value is between max/min
	*/
  if (output > this.output_max) {
    return this.output_max;
  }

  if (output < this.output_min) {
    return this.output_min;
  }

  return output;
};

Interpolate.prototype.linear = function(val) {
  const output =
    ((val - this.input_from) / (this.input_to - this.input_from)) *
      (this.output_to - this.output_from) +
    this.output_from;

  return this.checkBounds(output);
};

Interpolate.prototype.exponential = function(val) {
  /*
	exponential rise
	y = ab^x
	*/
  let offset = 0;
  let a = this.output_from;
  let y2 = this.output_to;
  const x2 = this.input_to - this.input_from;

  if (val === this.input_to) {
    return this.output_to;
  }

  if (val === this.input_from) {
    return this.output_from;
  }

  // x is an index (starts at 0)
  val -= this.input_from;

  if (a <= 0 || y2 <= 0) {
    // can't have y = 0 or less for pow functions
    offset = Math.abs(Math.min(a, y2)) + 1;
    a += offset;
    y2 += offset;
  }

  // I don't remember why I did this - BJD
  // however, b is supposed to equal y
  // when x == 0; so apparently this
  // is a formula to find that
  const b = Math.pow(y2 / a, 1 / x2);

  // formula here (reapply offset to deal with 0's)
  const output = a * Math.pow(b, val) - offset;

  return this.checkBounds(output);
};

// image templinks
const getImageURL = async function(bucket, authLib, apiLib, filekey) {
  // return a promise for a tempLink for retrieving a file from an s3 bucket
  // to use the link the caller will need to either use an await or a then statement to set it to the vue model data
  const authData = await authLib.currentSession();
  const myInit = {
    headers: { Authorization: authData.idToken.jwtToken },
    body: {
      bucket: bucket,
    },
  };
  const response = await apiLib.post("api", `/download/${filekey}`, myInit);
  return response.body.tempLink;
};

// file download templinks
const getDownloadURL = async function(bucket, authLib, apiLib, filekey) {
  // return a promise for a tempLink for retrieving a file from an s3 bucket
  // to use the link the caller will need to either use an await or a then statement to set it to the vue model data
  const authData = await authLib.currentSession();
  const myInit = {
    headers: { Authorization: authData.idToken.jwtToken },
    body: {
      bucket: bucket,
    },
  };
  const response = await apiLib.post("api", `/resources/${filekey}`, myInit);
  return response.body.tempLink;
};

/**
 * Returns TRUE if the first specified array contains all elements
 * from the second one. FALSE otherwise.
 *
 * @param {array} superset
 * @param {array} subset
 *
 * @returns {boolean}
 */
const arrayContainsArray = function(superset, subset) {
  if (0 === subset.length) {
    return false;
  }
  return subset.every(function(value) {
    return superset.indexOf(value) >= 0;
  });
};

const scrollToElement = function(vm, className) {
  // scroll to an element
  const el = vm.$el.getElementsByClassName(className)[0];
  if (el) {
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
};

const gtagEvent = function(vm, action, category, label, value = null) {
  // track an event with google analytics if installed
  if ("googleAnalyticsID" in vm.$Region) {
    const payload = {
      event_category: category,
      event_label: label,
    };
    if (value !== null) {
      payload.value = value;
    }
    vm.$gtag.event(action, payload);
  }
};

export {
  bodyEmptyStringToNull,
  mapScenAllocations,
  remapScenData,
  remapObsProperties,
  remapRepData,
  getScenClimateTransitions,
  getScenClimateIndicatorDetails,
  sanitizeDBPayload,
  abbreviateNumber,
  getPrevOrNextVal,
  Interpolate,
  getImageURL,
  getDownloadURL,
  arrayContainsArray,
  scrollToElement,
  gtagEvent,
};
