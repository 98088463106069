/* jshint esversion: 6 */
// hint/challenge/badge condition testing functions
// all functions need to be passed args containing a vue model and helper functions
// example: goalsFailMostEnv({vm: vm, helpers: helpers})
// it is assumed that the vue model calling the equation would contain all data necessary to perform the function
// all functions should return either true or false
// some functions may also be used to setup UI for user to proceed (exmaple, turning on observations layer)

const RegionConditions = {
  // HELPER FUNCTIONS
  // the following helper functions can be referenced by any condition testing function as they are always passed in via args
  helpers: {
    getAllIndicatorNames: function (vm) {
      // return a list of all indicator names for a scenario
      return vm.$Region.defaultIndicators.concat(vm.regionIndicators);
    },
    getAllIndicators: function (vm) {
      // return a list of all indicators for a scenario
      return vm.$Region.indicators // indicator definitions
        .filter((ind) =>
          vm.$Region.defaultIndicators // list of default indicators
            .concat(vm.regionIndicators) // list of region-specific indicators
            .includes(ind.name)
        );
    },
    getIndicatorsByType: function (vm, type) {
      // return a list of all indicators by type (environmental, socio-economic) for a scenario
      return vm.$Region.indicators // indicator definitions
        .filter((ind) =>
          vm.$Region.defaultIndicators // list of default indicators
            .concat(vm.regionIndicators) // list of region-specific indicators
            .includes(ind.name)
        )
        .filter((ind) => ind.type === type);
    },
    getIndicatorByName: function (vm, name) {
      // return a single indicator obj based on name
      return vm.$Region.indicators.find((ind) => ind.name === name);
    },
    indicatorGoalMet(vm, indicator) {
      // get the current status of a goal
      const indVal =
        vm.INDICATOR_VALS[indicator.name][vm.ACTIVE_SCENARIO.endTime][0];
      const goalVal = vm.ACTIVE_SCENARIO.goals[indicator.name];
      let val1 = indVal,
        val2 = goalVal;
      if (indicator.target === "low") {
        // low values are better - invert calculation
        val1 = val2;
        val2 = indVal;
      }
      if (val1 >= val2) {
        return true;
      }
      return false;
    },
    getObsInVm(vm) {
      // check if args contains payload and payload is an observation
      return "observation" in vm ? vm.observation : null;
    },
  },
  // --- end HELPER FUNCTIONS
  //
  // CONDITION TESTING FUNCTIONS
  falseConditionTest: function (args) {
    // for testing purposes - always returns false
    if (args) {
      return false;
    }
  },
  trueConditionTest: function (args) {
    // for testing purposes - always returns true
    if (args) {
      return true;
    }
  },
  // ---------- APP WORKFLOW TYPE CONDITIONS ----------------
  userHasUsedMap: function (args) {
    // user has visited the map page
    // assumption is that if map help has been shown, the user has visited the page
    const vm = args.vm;
    return (
      "history" in vm.USER_DATA &&
      vm.USER_DATA.history.findIndex((item) => {
        return item.startsWith("t_CoverMap");
      }) !== -1
    );
  },
  userHasUsedMapZoom: function (args) {
    // user has visited the map page and located their home address
    const vm = args.vm;
    return (
      "history" in vm.USER_DATA &&
      vm.USER_DATA.history.findIndex((item) => {
        return item.startsWith("m_LocateMap");
      }) !== -1
    );
  },
  userHasUsedMapView: function (args) {
    // user has visited the map page and clicked Layers icon
    const vm = args.vm;
    return (
      "history" in vm.USER_DATA &&
      vm.USER_DATA.history.findIndex((item) => {
        return item.startsWith("m_ViewMapLayers");
      }) !== -1
    );
  },
  userFoundTraditionalTerritoryMap: function (args) {
    //
    const vm = args.vm;
    return (
      "history" in vm.USER_DATA &&
      vm.USER_DATA.history.findIndex((item) => {
        return item.startsWith("m_FindTraditionalTerritory");
      }) !== -1
    );
  },
  userFoundTreatiesAreaMap: function (args) {
    //
    const vm = args.vm;
    return (
      "history" in vm.USER_DATA &&
      vm.USER_DATA.history.findIndex((item) => {
        return item.startsWith("m_FindTreatyArea");
      }) !== -1
    );
  },
  userFoundTraditionalLanguagesnMap: function (args) {
    //
    const vm = args.vm;
    return (
      "history" in vm.USER_DATA &&
      vm.USER_DATA.history.findIndex((item) => {
        return item.startsWith("m_FindTraditionalLanguages");
      }) !== -1
    );
  },
  userFoundReservesSettlementsMap: function (args) {
    //
    const vm = args.vm;
    return (
      "history" in vm.USER_DATA &&
      vm.USER_DATA.history.findIndex((item) => {
        return item.startsWith("m_FindReservesSettlements");
      }) !== -1
    );
  },
  userFoundMetisRegionsMap: function (args) {
    //
    const vm = args.vm;
    return (
      "history" in vm.USER_DATA &&
      vm.USER_DATA.history.findIndex((item) => {
        return item.startsWith("m_FindMetisRegions");
      }) !== -1
    );
  },
  userTurnedOnAllItemsOnIndigenousMap: function (args) {
    //
    const vm = args.vm;
    return (
      "history" in vm.USER_DATA &&
      vm.USER_DATA.history.findIndex((item) => {
        return item.startsWith("m_FindTraditionalTerritory");
      }) &&
      vm.USER_DATA.history.findIndex((item) => {
        return item.startsWith("m_FindTreatyArea");
      }) &&
      vm.USER_DATA.history.findIndex((item) => {
        return item.startsWith("m_FindTraditionalLanguages");
      }) &&
      vm.USER_DATA.history.findIndex((item) => {
        return item.startsWith("m_FindReservesSettlements");
      }) &&
      vm.USER_DATA.history.findIndex((item) => {
        return item.startsWith("m_FindMetisRegions");
      }) !== -1
    );
  },
  userHasViewedClimatePanel: function (args) {
    // user has viewed the climate change info panel
    const vm = args.vm;
    return vm.hasViewedClimatePanel; // on Explore.vue component only
  },
  // ---------- GENERAL MAP/EXPLORE CONDITIONS --------------
  mapViewIrrigation: function (args) {
    // user has seen crop irrigation in southern AB
    const vm = args.vm;
    const center = vm.$refs.covermap.map.getCenter();
    return (
      vm.$refs.covermap.BASEMAP_STYLE === "satellite" && // satellite imagery on
      vm.$refs.covermap.map.getZoom() > 10 && // zoomed in
      center.lat > 49.945 && // map is centered within general area of Vauxhall AB
      center.lat < 50.198 &&
      center.lng < -111.93 &&
      center.lng > -112.279
    );
  },
  mapViewCutblocks: function (args) {
    // user has seen cutblocks in ghost river area
    const vm = args.vm;
    const center = vm.$refs.covermap.map.getCenter();
    return (
      vm.$refs.covermap.BASEMAP_STYLE === "satellite" && // satellite imagery on
      vm.$refs.covermap.map.getZoom() > 9 && // zoomed in
      center.lat > 51.207 && // map is centered within general area of Ghost Public Landuse Zone
      center.lat < 51.802 &&
      center.lng < -114.588 &&
      center.lng > -115.521
    );
  },
  mapViewFtMac: function (args) {
    // user has seen ft mac area
    const vm = args.vm;
    const center = vm.$refs.covermap.map.getCenter();
    return (
      vm.$refs.covermap.BASEMAP_STYLE === "satellite" && // satellite imagery on
      vm.$refs.covermap.map.getZoom() > 9 && // zoomed in
      center.lat > 56.495 && // map is centered within general area of Ghost Public Landuse Zone
      center.lat < 56.988 &&
      center.lng < -110.963 &&
      center.lng > -111.753
    );
  },
  mapViewOilSands: function (args) {
    // user has seen oil sands area
    const vm = args.vm;
    const center = vm.$refs.covermap.map.getCenter();
    return (
      vm.$refs.covermap.BASEMAP_STYLE === "satellite" && // satellite imagery on
      vm.$refs.covermap.map.getZoom() > 9 && // zoomed in
      center.lat > 56.84 && // map is centered within general area of Ghost Public Landuse Zone
      center.lat < 57.201 &&
      center.lng < -111.246 &&
      center.lng > -111.917
    );
  },
  // ---------- SCENARIO TYPE CONDITIONS ----------------
  userHasScenarios: function (args) {
    // user has at least one scenario
    const vm = args.vm;
    return vm.USER_SCENARIOS.length >= 1;
  },
  userHasHistoricScenarios: function (args) {
    // user has at least one scenario
    const vm = args.vm;
    return (
      vm.USER_SCENARIOS.filter((scen) => scen.type === "Historic").length >= 1
    );
  },
  userHasHistoricIndigenousScenarios: function (args) {
    // user has at least one scenario
    const vm = args.vm;
    return (
      vm.USER_SCENARIOS.filter((scen) => scen.type === "Historic" && scen.studyAreaType === "Indigenous Territory").length >= 1
    );
  },
  userHasBAUScenarios: function (args) {
    // user has at least one scenario
    const vm = args.vm;
    return (
      vm.USER_SCENARIOS.filter((scen) => scen.type === "Business as Usual")
        .length >= 1
    );
  },
  userHasBAUIndigenousScenarios: function (args) {
    // user has at least one scenario
    const vm = args.vm;
    return (
      vm.USER_SCENARIOS.filter((scen) => scen.type === "Business as Usual" && scen.studyAreaType === "Indigenous Territory")
        .length >= 1
    );
  },
  userHasLandUseScenarios: function (args) {
    // user has at least one scenario
    const vm = args.vm;
    return (
      vm.USER_SCENARIOS.filter((scen) => scen.type === "Land Use").length >= 1
    );
  },
  userHasLandUseIndigenousScenarios: function (args) {
    // user has at least one scenario
    const vm = args.vm;
    return (
      vm.USER_SCENARIOS.filter((scen) => scen.type === "Land Use" && scen.studyAreaType === "Indigenous Territory").length >= 1
    );
  },
  userHasComparedIndicatorCollections: function (args) {
    // user has at least 2 scenarios in the same region but with different indicator collections
    const vm = args.vm;
    const mapScens = function (type) {
      return vm.USER_SCENARIOS.filter((scen) => scen.type === type).map(
        (scen) => {
          return [scen.studyArea, scen.indicatorCollection];
        }
      );
    };
    // make sure user has 2 scenarios with the same region and type
    const histScens = mapScens("Historic");
    const bauScens = mapScens("Business as Usual");
    const luScens = mapScens("Land Use");
    if (histScens.length < 2 && bauScens.length < 2 && luScens.length < 2) {
      return false;
    }
    const indCompare = function (arr) {
      return arr.some((element, index) => {
        const _compare = arr.findIndex(
          (scen) => scen[0] === element[0] && scen[1] !== element[1]
        );
        return ![index, -1].includes(_compare);
      });
    };
    if (indCompare(histScens)) {
      return true;
    }
    if (indCompare(bauScens)) {
      return true;
    }
    if (indCompare(luScens)) {
      return true;
    }
    return false;
  },
  userHasComparedClimateScenarios: function (args) {
    // user has at least 2 future scenarios in the same region but with different climate settings
    const vm = args.vm;
    const mapScens = function (type) {
      return vm.USER_SCENARIOS.filter((scen) => scen.type === type).map(
        (scen) => {
          return [scen.studyArea, scen.climate];
        }
      );
    };
    // make sure user has 2 future scenarios with the same region
    const bauScens = mapScens("Business as Usual");
    const luScens = mapScens("Land Use");
    if (bauScens.length < 2 && luScens.length < 2) {
      return false;
    }
    const climCompare = function (arr) {
      return arr.some((element, index) => {
        const _compare = arr.findIndex(
          (scen) => scen[0] === element[0] && scen[1] !== element[1]
        );
        return ![index, -1].includes(_compare);
      });
    };
    if (climCompare(bauScens)) {
      return true;
    }
    if (climCompare(luScens)) {
      return true;
    }
    return false;
  },
  userHas3BauClimateScenarios: function (args) {
    // see if user has created a BAU for all 3 climate scenarios (mild, med, hot) for same region
    const vm = args.vm;
    if (vm.USER_SCENARIOS.length < 3) {
      // not enough scenarios
      return false;
    }
    // get unique study areas
    const regions = [
      ...new Set(
        vm.USER_SCENARIOS.filter(
          (scen) => scen.type === "Business as Usual" && scen.climate !== "none"
        ).map((scen) => scen.studyArea)
      ),
    ];
    // find study areas with all 3 climate scenarios
    return regions.some(
      (reg) =>
        [
          ...new Set(
            vm.USER_SCENARIOS.filter(
              (scen) =>
                scen.studyArea === reg &&
                scen.type === "Business as Usual" &&
                scen.climate !== "none"
            ).map((scen) => scen.climate)
          ),
        ].length === 3
    );
  },
  scenarioIsLandUse: function (args) {
    // whether a scenario is a user-defined land-use scenario (and has goals)
    const vm = args.vm;
    return vm.ACTIVE_SCENARIO.type === "Land Use";
  },
  scenarioIsBAUOrLandUse: function (args) {
    // whether a scenario is a future scenario (either land-use or bau)
    const vm = args.vm;
    return ["Land Use", "Business as Usual"].includes(vm.ACTIVE_SCENARIO.type);
  },
  userHasElbowRiverBAUScenario: function (args) {
    // user has created a BAU scenario for the elbow watershed
    const vm = args.vm;
    return (
      vm.USER_SCENARIOS.findIndex(
        (scen) =>
          scen.type === "Business as Usual" &&
          scen.studyArea === "Nelson River Drainage Area__Bow__Elbow.geojson"
      ) !== -1
    );
  },
  userHasLowerAthabascaBAUScenario: function (args) {
    // user has created a BAU scenario for the lower athabasca watershed
    const vm = args.vm;
    return (
      vm.USER_SCENARIOS.findIndex(
        (scen) =>
          scen.type === "Business as Usual" &&
          scen.studyArea ===
          "Great Slave Lake Drainage Area__Lower Athabasca.geojson"
      ) !== -1
    );
  },
  scenarioDevRateChanged: function (args) {
    // user has adjusted the development rate slider
    const vm = args.vm;
    return vm.devRateChanged;
  },
  scenarioHasForestry: function (args) {
    // current scenario has timber production > 0
    const vm = args.vm,
      helpers = args.helpers;
    return (
      helpers.getAllIndicatorNames(vm).includes("Timber Production") &&
      vm.INDICATOR_VALS["Timber Production"][vm.ACTIVE_SCENARIO.startTime][0] >
      0
    );
  },
  // ---------- OBSERVATION CONDITIONS ----------------
  userHasObservations: function (args) {
    // user has recorded at least 1 observation
    const vm = args.vm;
    return vm.OBSERVATIONS_ALL_USER.length >= 1;
  },
  userHasLandUseObservations: function (args) {
    // user has recorded at least 1 land-use observation
    const vm = args.vm,
      helpers = args.helpers;
    // check brand new observation if call is from obs-create event
    const _obs = helpers.getObsInVm(vm);
    if (_obs !== null && "type" in _obs && _obs.type === "general") {
      return true;
    }
    // check all user's observations
    return (
      vm.OBSERVATIONS_ALL_USER.length > 0 &&
      vm.OBSERVATIONS_ALL_USER.filter((obs) => obs.type === "general").length >
      0
    );
  },
  userHasWaterObservations: function (args) {
    // user has recorded at least 1 water observation
    const vm = args.vm,
      helpers = args.helpers;
    // check brand new observation if call is from obs-create event
    const _obs = helpers.getObsInVm(vm);
    if (_obs !== null && "type" in _obs && _obs.type === "water") {
      return true;
    }
    // check all user's observations
    return (
      vm.OBSERVATIONS_ALL_USER.length > 0 &&
      vm.OBSERVATIONS_ALL_USER.filter((obs) => obs.type === "water").length > 0
    );
  },
  userHasObservationWithMedia: function (args) {
    // user has recorded at least 1 observation with attached media
    const vm = args.vm,
      helpers = args.helpers;
    // check brand new observation if call is from obs-create event
    const _obs = helpers.getObsInVm(vm);
    if (
      _obs !== null &&
      "media" in _obs &&
      ["video", "image"].includes(_obs.media.type)
    ) {
      return true;
    }
    // check all user's observations
    return (
      vm.OBSERVATIONS_ALL_USER.length > 0 &&
      vm.OBSERVATIONS_ALL_USER.filter((obs) => {
        return "media" in obs && ["video", "image"].includes(obs.media.type);
      }).length > 0
    );
  },
  obsViewAthabascaGlacier: function (args) {
    // user viewed the Sask glacier observation
    const vm = args.vm;
    return (
      vm.SELECTED_OBSERVATION !== null &&
      vm.SELECTED_OBSERVATION.properties.id === 1 &&
      vm.SELECTED_OBSERVATION.properties.title === "The Athabasca Glacier"
    );
  },
  obsViewIceCoreLab: function (args) {
    // user viewed the ice core lab observation
    const vm = args.vm;
    return (
      vm.SELECTED_OBSERVATION !== null &&
      vm.SELECTED_OBSERVATION.properties.id === 10 &&
      vm.SELECTED_OBSERVATION.properties.title ===
      "Dr. Ali Criscitiello on the importance of glaciers"
    );
  },
  obsViewFishKill: function (args) {
    // user viewed the mike sullivan fish kill observation
    const vm = args.vm;
    return (
      vm.SELECTED_OBSERVATION !== null &&
      vm.SELECTED_OBSERVATION.properties.id === 25 &&
      vm.SELECTED_OBSERVATION.properties.title === "Investigating a Fish Kill"
    );
  },
  obsViewRaeGlacier: function (args) {
    // user viewed the rae glacier observation
    const vm = args.vm;
    return (
      vm.SELECTED_OBSERVATION !== null &&
      vm.SELECTED_OBSERVATION.properties.id === 9 &&
      vm.SELECTED_OBSERVATION.properties.title === "Rae Glacier"
    );
  },
  obsViewElbowWaterObs: function (args) {
    // user viewed a water quality observation within the elbow watershed
    // not precise --- based on the rectangular bounds of the elbow watershed
    const vm = args.vm;
    const top = 51.115,
      bottom = 50.605,
      left = -115.095,
      right = -114.047;
    return (
      vm.SELECTED_OBSERVATION !== null &&
      vm.SELECTED_OBSERVATION.properties.type === "water" &&
      vm.SELECTED_OBSERVATION.geometry.coordinates[1] < top &&
      vm.SELECTED_OBSERVATION.geometry.coordinates[1] > bottom &&
      vm.SELECTED_OBSERVATION.geometry.coordinates[0] > left &&
      vm.SELECTED_OBSERVATION.geometry.coordinates[0] < right
    );
  },
  userHasMLAObservation: function (args) {
    // user has created an observation geo-tagging an mla office
    const vm = args.vm;
    // check brand new observation if call is from obs-create event
    return vm.observation.title.toLowerCase().includes("mla");
  },
  userHasFishWantedObservation: function (args) {
    // user has created an observation geo-tagging an mla office
    const vm = args.vm;
    return (
      vm.observation.title.toLowerCase().includes("wanted alive") &&
      vm.observation.media.type === "image"
    );
  },
  // ---------- VIDEO CONDITIONS ----------------
  videoViewIceCoreLab: function (args) {
    // user fully watched the ice core lab video
    const vm = args.vm;
    return vm.title === "Dr. Ali Criscitiello on the importance of glaciers";
  },
  videoViewWaterQuality: function (args) {
    // user fully watched the water quality video
    const vm = args.vm;
    return vm.title === "Water Quality";
  },
  videoViewFishHabitat: function (args) {
    // user fully watched the fish habitat video
    const vm = args.vm;
    return vm.title === "Fish Habitat";
  },
  videoViewWaterConsumption: function (args) {
    // user fully watched the water consumption video
    const vm = args.vm;
    return vm.title === "Water Consumption";
  },
  videoViewForestry: function (args) {
    // user fully watched the Timber Production video
    const vm = args.vm;
    return vm.title === "Timber Production";
  },
  videoViewOil: function (args) {
    // user fully watched the Hydrocarbon Production video
    const vm = args.vm;
    return vm.title === "Hydrocarbon Production";
  },
  videoViewClimateChange: function (args) {
    // user fully watched the climate change video
    const vm = args.vm;
    return vm.title === "Climate Change";
  },
  userHasViewedAllIndicatorVideos: function (args) {
    // user has seen all of the environmental videos
    const vm = args.vm;
    const envVideos = vm.$Region.indicators
      .filter((ind) => "media" in ind && ind.media.type === "video")
      .map((ind) => `v_${ind.name}`);
    return vm.$Helpers.arrayContainsArray(vm.USER_DATA.history, envVideos);
  },
  videoBlackfootPerspective: function (args) {
    // user fully watched the blackfoot perspective video
    const vm = args.vm;
    return vm.title === "A Blackfoot Perspective";
  },
  // ---------- GOAL-BASED CONDITIONS ----------------
  goalsFailMost: function (args) {
    // test whether a user failed majority of goals
    const vm = args.vm,
      helpers = args.helpers;
    const allInds = helpers.getAllIndicators(vm);
    let goalsMet = 0;
    allInds.forEach((ind) => {
      if (helpers.indicatorGoalMet(vm, ind)) {
        goalsMet += 1;
      }
    });
    // return true if user passed less than half
    return goalsMet < allInds.length / 2;
  },
  goalsPass75Percent: function (args) {
    // test whether a user passed all 75% or more of their goals but not all
    const vm = args.vm,
      helpers = args.helpers;
    const allInds = helpers.getAllIndicators(vm);
    let goalsMet = 0;
    allInds.forEach((ind) => {
      if (helpers.indicatorGoalMet(vm, ind)) {
        goalsMet += 1;
      }
    });
    // return true if user passed at least 75% but not all
    return goalsMet >= allInds.length * 0.75 && goalsMet < allInds.length;
  },
  goalsPassAll: function (args) {
    // test whether a user passed all of their goals
    const vm = args.vm,
      helpers = args.helpers;
    const inds = helpers.getAllIndicators(vm);
    // return true if user passed all
    return inds.every((ind) => {
      return helpers.indicatorGoalMet(vm, ind);
    });
  },
  goalsFailAny: function (args) {
    // test whether a user failed any of their goals
    const vm = args.vm,
      helpers = args.helpers;
    const inds = helpers.getAllIndicators(vm);
    // return true if user failed any
    return inds.some((ind) => {
      return !helpers.indicatorGoalMet(vm, ind);
    });
  },
  goalsFailAnyDescription: function (args) {
    // create a message for a goal that failed
    const vm = args.vm,
      helpers = args.helpers;
    const inds = helpers.getAllIndicators(vm).filter((ind) => {
      return !helpers.indicatorGoalMet(vm, ind);
    });
    const failInd = inds[Math.floor(Math.random() * inds.length)];
    return `You didn't reach your ${failInd.name} goal. Is your goal realistic?
            Do you need to make more changes to your plan allocations?
            What about the industrial activity rate and management activities?`;
  },
  goalsFailMostEnv: function (args) {
    // test whether a user failed majority of environmental goals
    const vm = args.vm,
      helpers = args.helpers;
    const envInds = helpers.getIndicatorsByType(vm, "Environmental");
    let goalsMet = 0;
    envInds.forEach((ind) => {
      if (helpers.indicatorGoalMet(vm, ind)) {
        goalsMet += 1;
      }
    });
    // return true if user passed less than half
    return goalsMet < envInds.length / 2;
  },
  goalsPassMostEnv: function (args) {
    // test whether a user passed majority of environmental goals
    const vm = args.vm,
      helpers = args.helpers;
    const envInds = helpers.getIndicatorsByType(vm, "Environmental");
    let goalsMet = 0;
    envInds.forEach((ind) => {
      if (helpers.indicatorGoalMet(vm, ind)) {
        goalsMet += 1;
      }
    });
    // return true if user passed more than half
    return goalsMet > envInds.length / 2;
  },
  goalsFailMostSocEco: function (args) {
    // test whether a user failed majority of Socio-Economic goals
    const vm = args.vm,
      helpers = args.helpers;
    const inds = helpers.getIndicatorsByType(vm, "Socio-Economic");
    let goalsMet = 0;
    inds.forEach((ind) => {
      if (helpers.indicatorGoalMet(vm, ind)) {
        goalsMet += 1;
      }
    });
    // return true if user passed less than half
    return goalsMet < inds.length / 2;
  },
  goalsPassMostSocEco: function (args) {
    // test whether a user passed majority of Socio-Economic goals
    const vm = args.vm,
      helpers = args.helpers;
    const inds = helpers.getIndicatorsByType(vm, "Socio-Economic");
    let goalsMet = 0;
    inds.forEach((ind) => {
      if (helpers.indicatorGoalMet(vm, ind)) {
        goalsMet += 1;
      }
    });
    // return true if user passed more than half
    return goalsMet > inds.length / 2;
  },
  goalsFailGDP: function (args) {
    // test if a user failed their GDP goal
    const vm = args.vm,
      helpers = args.helpers;
    const ind = helpers.getIndicatorByName(vm, "Gross Domestic Product");
    return helpers.indicatorGoalMet(vm, ind);
  },
  // ---------- MGMT PRACTICES CONDITIONS ----------------
  devRateHigh: function (args) {
    // test if dev rate is >10% higher than 1980 OR dev rate is >60%
    // NOTE condition may only work in events tied to Explore.vue due to needing to look up simulationState
    const vm = args.vm;
    return (
      vm.ACTIVE_SCENARIO.devRate > 0.6 ||
      vm.ACTIVE_SCENARIO.devRate >
      vm.simulationState.regionData.historicValues.devRate + 0.1
    );
  },
  devRateLow: function (args) {
    // test if dev rate is >10% lower than 1980 OR dev rate is <20%
    // NOTE condition may only work in events tied to Explore.vue due to needing to look up simulationState
    const vm = args.vm;
    return (
      vm.ACTIVE_SCENARIO.devRate < 0.2 ||
      vm.ACTIVE_SCENARIO.devRate <
      vm.simulationState.regionData.historicValues.devRate - 0.1
    );
  },
  mgmtPracticeIsOn: function (args) {
    // are any mgmt practices on?
    const vm = args.vm;
    return vm.ACTIVE_SCENARIO.management.length > 0;
  },
  mgmtPracticeOnDescription: function (args) {
    // are any mgmt practices on?
    const vm = args.vm;
    const mgmtPractices = vm.$Region.mgmtPractices.filter((item) => {
      return vm.ACTIVE_SCENARIO.management.includes(item.variable);
    });
    const item =
      mgmtPractices[Math.floor(Math.random() * mgmtPractices.length)];
    return `You have turned on ${item.name}. How is this affecting your plan?`;
  },
  mgmtPracticeIsOff: function (args) {
    // are any mgmt practices off?
    const vm = args.vm;
    return (
      vm.ACTIVE_SCENARIO.management.length < vm.$Region.mgmtPractices.length
    );
  },
  mgmtPracticeOffDescription: function (args) {
    // are any mgmt practices on?
    const vm = args.vm;
    const mgmtPractices = vm.$Region.mgmtPractices.filter((item) => {
      return !vm.ACTIVE_SCENARIO.management.includes(item.variable);
    });
    const item =
      mgmtPractices[Math.floor(Math.random() * mgmtPractices.length)];
    return `Your ${item.name} button is currently off.  What would happen if you turned it on?`;
  },
  // ---------- INDICATOR-BASED CONDITIONS ----------------
  indsWaterQualityFail: function (args) {
    // test if user Didn't reach water quality goal, or water quality did not get better with their plan.
    const vm = args.vm,
      helpers = args.helpers;
    const indName = "Water Quality";
    const ind = helpers.getIndicatorByName(vm, indName);
    if (!helpers.indicatorGoalMet(vm, ind)) {
      // didn't reach goal
      return true;
    }
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal <= startVal;
  },
  indsNaturalLandDecrease: function (args) {
    // test if natural landscapes decreased
    const vm = args.vm;
    const indName = "Natural Landscapes";
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal < startVal;
  },
  indsFishDecrease: function (args) {
    // test if fish indicator decreased
    const vm = args.vm;
    const indName = "Fish Habitat";
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal < startVal;
  },
  indsGrizzlyDecrease: function (args) {
    // test if grizzly indicator decreased
    const vm = args.vm;
    const indName = "Grizzly Habitat";
    if (!(indName in vm.INDICATOR_VALS)) {
      // not every region uses this indicator - check first
      return false;
    }
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal < startVal;
  },
  indsCaribouDecrease: function (args) {
    // test if caribou indicator decreased
    const vm = args.vm;
    const indName = "Caribou Habitat";
    if (!(indName in vm.INDICATOR_VALS)) {
      // not every region uses this indicator - check first
      return false;
    }
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal < startVal;
  },
  indsPopulationStatic: function (args) {
    // test if population stayed the same
    const vm = args.vm;
    const indName = "Human Population";
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal === startVal;
  },
  indsPopulationIncrease: function (args) {
    // test if population increased by 50% or more and urbanDensity practice is off
    const vm = args.vm;
    const indName = "Human Population";
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    if (endVal / startVal >= 1.5) {
      // population increased by at least 50%
      if (!vm.ACTIVE_SCENARIO.management.includes("urbanDensity")) {
        return true;
      }
    }
    return false;
  },
  indsWaterQualityIncrease: function (args) {
    // test if water quality increased
    const vm = args.vm;
    const indName = "Water Quality";
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal > startVal;
  },
  indsWaterQualityDecrease: function (args) {
    // test if water quality decreased
    const vm = args.vm;
    const indName = "Water Quality";
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal < startVal;
  },
  indsWaterQualityDecreaseNoMgmt: function (args) {
    // test if water quality decreased and management practices are off
    const vm = args.vm;
    const indName = "Water Quality";
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return (
      !vm.ACTIVE_SCENARIO.management.includes("agFertilizer") &&
      endVal < startVal
    );
  },
  indsBioticCarbonDecrease: function (args) {
    // test if biotic carbon indicator decreased
    const vm = args.vm;
    const indName = "Biotic Carbon";
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal < startVal;
  },
  indsGHGIncrease: function (args) {
    // test if Greenhouse Gasses indicator increased
    const vm = args.vm;
    const indName = "Greenhouse Gasses";
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal > startVal;
  },
  indsGHGIncrease30Percent: function (args) {
    // test if Greenhouse Gasses indicator increased over 30%
    const vm = args.vm;
    const indName = "Greenhouse Gasses";
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal / startVal > 1.3; // increased over 30%
  },
  indsEnergyIncrease: function (args) {
    // test if Hydrocarbon Production indicator increased
    const vm = args.vm;
    const indName = "Hydrocarbon Production";
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal > startVal;
  },
  indsEnergyIncrease30Percent: function (args) {
    // test if Hydrocarbon Production indicator increased over 30%
    const vm = args.vm;
    const indName = "Hydrocarbon Production";
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal / startVal > 1.3; // increased over 30%
  },
  indsEnergyDecrease: function (args) {
    // test if Hydrocarbon Production indicator decreased
    const vm = args.vm;
    const indName = "Hydrocarbon Production";
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal < startVal;
  },
  indsEnergyDecrease30Percent: function (args) {
    // test if Hydrocarbon Production indicator decreased
    const vm = args.vm;
    const indName = "Hydrocarbon Production";
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal / startVal < 0.7;
  },

  indsGDPDecrease: function (args) {
    // test if GDP stayed the same
    const vm = args.vm;
    const indName = "Gross Domestic Product";
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal <= startVal;
  },
  indsTimberIncrease: function (args) {
    // test if timber Production indicator increased
    const vm = args.vm;
    const indName = "Timber Production";
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal > startVal;
  },
  indsTimberIncrease30Percent: function (args) {
    // test if timber Production indicator increased over 30%
    const vm = args.vm;
    const indName = "Timber Production";
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal / startVal > 1.3; // increased over 30%
  },
  indsAgDecrease: function (args) {
    // test if Agricultural Production indicator decreased
    const vm = args.vm;
    const indName = "Agricultural Production";
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal < startVal;
  },
  indsWaterConsumptionIncrease: function (args) {
    // test if Water Consumption indicator increased
    const vm = args.vm;
    const indName = "Water Consumption";
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal > startVal;
  },
  indsWaterConsumptionDecrease50Percent: function (args) {
    // test if Water Consumption indicator Decreased 50 Percent
    const vm = args.vm;
    const indName = "Water Consumption";
    const startVal =
      vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.startTime][0],
      endVal = vm.INDICATOR_VALS[indName][vm.ACTIVE_SCENARIO.endTime][0];
    return endVal < startVal * 0.5;
  },
  // ---------- ALLOCATION-BASED CONDITIONS ----------------
  allocationsMostSuccessful: function (args) {
    // make sure there is a successful allocation and find/set the most successful one to ACTIVE_HINT_ALLOCATION
    const vm = args.vm;
    if (vm.ACTIVE_SCENARIO.allocations.length === 0) {
      // no allocations in the scenario
      return false;
    }
    // load the run analysis
    const alloc_results = vm.ACTIVE_SCENARIO.completedAllocations;
    if (Object.keys(alloc_results).length === 0) {
      // no allocation results in the run analysis
      return false;
    }
    // find most successful allocation
    const mostSuccessful = Object.entries(alloc_results).reduce((max, alloc) =>
      max[1].success > alloc[1].success ? max : alloc
    );
    if (mostSuccessful[1].success < 0.7) {
      // not super successful
      return false;
    }
    // set the most successful allocation and return true
    vm.SET_ACTIVE_HINT_ALLOCATION(mostSuccessful);
    return true;
  },
  allocationsMostSuccessfulDescription: function (args) {
    // return message to use with successful allocation
    const vm = args.vm;
    const alloc_index = parseInt(vm.ACTIVE_HINT_ALLOCATION[0]) - 1,
      landType = vm.ACTIVE_SCENARIO.allocations[alloc_index].name,
      alloc_area = vm.ACTIVE_SCENARIO.allocations[alloc_index].area,
      actual_area = vm.ACTIVE_HINT_ALLOCATION[1].changedArea;
    const qualifyMsg =
      actual_area !== alloc_area
        ? `Not all of it, but most of the area was allocated.  After running the simulation, `
        : `The entire allocation amount of `;
    const msg = `One of your planned landscape changes was to increase ${landType}
            by ${vm.$Helpers.abbreviateNumber(alloc_area)} ha.
            ${qualifyMsg}
            ${vm.$Helpers.abbreviateNumber(actual_area)} ha was allocated!
            This change was successful because the existing land cover types were able to be converted into ${landType}
            and there weren't too many competing land-use allocations.`;
    return msg;
  },
  allocationsMediumSuccessful: function (args) {
    // find an allocation that has a success rate of 30% < success < 70%
    const vm = args.vm;
    if (vm.ACTIVE_SCENARIO.allocations.length === 0) {
      // no allocations in the scenario
      return false;
    }
    // load the run analysis
    const alloc_results = vm.ACTIVE_SCENARIO.completedAllocations;
    if (Object.keys(alloc_results).length === 0) {
      // no allocation results in the run analysis
      return false;
    }
    // find an allocation between 30-70% successful
    const filteredEntries = Object.entries(alloc_results).filter(
      (alloc) => alloc[1].success > 0.3 && alloc[1].success < 0.7
    );
    if (filteredEntries.length === 0) {
      return false;
    }
    // set the allocation and return true
    const randAlloc =
      filteredEntries[Math.floor(Math.random() * filteredEntries.length)];
    vm.SET_ACTIVE_HINT_ALLOCATION(randAlloc);
    return true;
  },
  allocationsMediumSuccessfulDescription: function (args) {
    // return message to use with successful allocation
    const vm = args.vm;
    const alloc_index = parseInt(vm.ACTIVE_HINT_ALLOCATION[0]) - 1,
      landType = vm.ACTIVE_SCENARIO.allocations[alloc_index].name,
      alloc_area = vm.ACTIVE_SCENARIO.allocations[alloc_index].area,
      actual_area = vm.ACTIVE_HINT_ALLOCATION[1].changedArea;
    const msg = `One of your planned landscape changes was to increase ${landType}
            by ${vm.$Helpers.abbreviateNumber(alloc_area)} ha.
            After running the simulation,
            ${vm.$Helpers.abbreviateNumber(actual_area)} ha was allocated.`;
    return msg;
  },
  allocationsLeastSuccessful: function (args) {
    // make sure there is a poorly successful allocation and find/set the least successful one to ACTIVE_HINT_ALLOCATION
    const vm = args.vm;
    if (vm.ACTIVE_SCENARIO.allocations.length === 0) {
      // no allocations in the scenario
      return false;
    }
    // load the run analysis
    const alloc_results = vm.ACTIVE_SCENARIO.completedAllocations;
    if (Object.keys(alloc_results).length === 0) {
      // no allocation results in the run analysis
      return false;
    }
    // find least successful allocation
    const leastSuccessful = Object.entries(alloc_results).reduce((min, alloc) =>
      min[1].success < alloc[1].success ? min : alloc
    );
    if (leastSuccessful[1].success > 0.5) {
      // too successful
      return false;
    }
    // set the least successful allocation and return true
    vm.SET_ACTIVE_HINT_ALLOCATION(leastSuccessful);
    return true;
  },
  allocationsLeastSuccessfulDescription: function (args) {
    // return message to use with successful allocation
    const vm = args.vm;
    const alloc_index = parseInt(vm.ACTIVE_HINT_ALLOCATION[0]) - 1,
      landType = vm.ACTIVE_SCENARIO.allocations[alloc_index].name,
      alloc_area = vm.ACTIVE_SCENARIO.allocations[alloc_index].area,
      actual_area = vm.ACTIVE_HINT_ALLOCATION[1].changedArea;
    const msg = `One of your planned landscape changes was to increase ${landType}
            by ${vm.$Helpers.abbreviateNumber(alloc_area)} ha but
            it doesn't look like much ${landType} was able to grow - only
            ${vm.$Helpers.abbreviateNumber(actual_area)} ha was allocated.`;
    return msg;
  },
  // ---------- CLIMATE CHANGE ON/OFF CONDITIONS ----------------
  climateChangeIsOn: function (args) {
    // return whether climate change scenario is on or off
    const vm = args.vm;
    return (
      vm.$Region.climateModuleActive &&
      ["Land Use", "Business as Usual"].includes(vm.ACTIVE_SCENARIO.type) &&
      vm.ACTIVE_SCENARIO.climate !== "none"
    );
  },
  climateChangeIsOff: function (args) {
    // return whether climate change scenario is on or off
    const vm = args.vm;
    return (
      vm.$Region.climateModuleActive &&
      ["Land Use", "Business as Usual"].includes(vm.ACTIVE_SCENARIO.type) &&
      vm.ACTIVE_SCENARIO.climate === "none"
    );
  },
  // ---------- CLIMATE TRANSITION-BASED CONDITIONS ----------------
  transitionMost: function (args) {
    // make sure there is land area affecte by climate transition, and set the largest transition to ACTIVE_HINT_TRANSITION
    const vm = args.vm;
    const transitions = vm.ACTIVE_SCENARIO.climateTransitions;
    if (transitions.length === 0) {
      // no climate transition results in the run analysis
      return false;
    }
    // find largest transition
    const largestTransition = transitions.reduce((max, trans) =>
      max.changedArea > trans.changedArea ? max : trans
    );
    if (largestTransition.changedArea < 500) {
      // not enough area changed to be very visible
      return false;
    }
    // set the largest transition and return true
    vm.SET_ACTIVE_HINT_TRANSITION(largestTransition);
    return true;
  },
  transitionMostDescription: function (args) {
    // return a message to display on a climate transition hint
    const vm = args.vm;
    const climScen = vm.$Region.climateScenarios.find(
      (scen) => scen.variable === vm.ACTIVE_SCENARIO.climate
    ).name;
    const transArea = vm.$Helpers.abbreviateNumber(
      vm.ACTIVE_HINT_TRANSITION.changedArea
    );
    const msg = `${transArea} ha of your study area transitioned
                from ${vm.ACTIVE_HINT_TRANSITION.fromLT}
                to ${vm.ACTIVE_HINT_TRANSITION.toLT}
                due to the effects of a ${climScen} climate change scenario.
                Some of the impacts in Alberta are a result of reduced precipitation combined with increased temperature.
                Areas that were wet become more arid and less able to support ${vm.ACTIVE_HINT_TRANSITION.fromLT}.`;
    return msg;
  },
  transitionAnyDescription: function (args) {
    // return a message to display on a climate transition hint
    const vm = args.vm;
    const studyArea = vm.ACTIVE_SCENARIO.studyArea
      .replace(".geojson", "")
      .split("__"),
      watershedName = studyArea[studyArea.length - 1];
    const msg = `How might climate change affect the ${watershedName} watershed?
                Differences in temperature and precipitation, changes in weather patterns and extreme weather events,
                cold days, hot days and other impacts can all affect the landscape.
                Did you notice any ${vm.ACTIVE_HINT_TRANSITION.fromLT}
                transitioning to ${vm.ACTIVE_HINT_TRANSITION.toLT} in your scenario?`;
    return msg;
  },
  transitionRand: function (args) {
    // make sure there is land area affecte by climate transition, and set ACTIVE_HINT_TRANSITION to a random transition as long as transition > 0
    const vm = args.vm;
    const transitions = vm.ACTIVE_SCENARIO.climateTransitions;
    if (transitions.length === 0) {
      // no climate transition results in the run analysis
      return false;
    }
    // find a transition > 0
    const filteredTransitions = transitions.filter(
      // look for transitions of at least 5 cells (500ha = 10x 100ha cells)
      (trans) => trans.changedArea > 500
    );
    if (filteredTransitions.length > 0) {
      // set the transition and return true
      const randTrans =
        filteredTransitions[
        Math.floor(Math.random() * filteredTransitions.length)
        ];
      vm.SET_ACTIVE_HINT_TRANSITION(randTrans);
      return true;
    }
    return false;
  },
  // ---------- REPORT CREATE - BADGE AWARD CONDITIONS ----------------
  userHasReports: function (args) {
    // test if user has at least 1 report saved
    const vm = args.vm;
    return vm.USER_REPORTS.length > 0;
  },
  userHasComparisonReports: function (args) {
    // test if user has a report with at least 2 scenarios on it
    const vm = args.vm;
    return (
      vm.USER_REPORTS.findIndex((item) => {
        return item.scenarios.length > 1;
      }) !== -1
    );
  },
  userHasComparisonReportsBAUWatersheds: function (args) {
    // test if user has compared BAU in 2 or more watersheds in a report
    const vm = args.vm;
    return (
      vm.USER_REPORTS.findIndex((item) => {
        return (
          item.scenarios.length > 1 &&
          item.scenarios.every((scen) => scen.type === "Business as Usual") &&
          [...new Set(item.scenarios.map((scen) => scen.studyArea))].length > 1
        );
      }) !== -1
    );
  },
  userHasComparisonReportClimate: function (args) {
    // test if user has compared climate scenarios within a single watershed
    const vm = args.vm;
    return (
      vm.USER_REPORTS.findIndex((item) => {
        return (
          item.scenarios.length > 1 &&
          item.scenarios.every((scen) => scen.type === "Business as Usual") &&
          [...new Set(item.scenarios.map((scen) => scen.studyArea))].length ===
          1 &&
          [...new Set(item.scenarios.map((scen) => scen.climate))].length > 1
        );
      }) !== -1
    );
  },
  userHasComparisonReportClimateQuest: function (args) {
    // test if user has a report that compares BAU with all 3 climate scenarios within a single watershed
    const vm = args.vm;
    return (
      vm.USER_REPORTS.findIndex((item) => {
        return (
          item.scenarios.length > 2 &&
          item.scenarios.every(
            (scen) =>
              scen.type === "Business as Usual" && scen.climate !== "none"
          ) &&
          [...new Set(item.scenarios.map((scen) => scen.studyArea))].length ===
          1 &&
          [...new Set(item.scenarios.map((scen) => scen.climate))].length === 3
        );
      }) !== -1
    );
  },
  // ---------- QUEST COMPLETION - BADGE AWARD CONDITIONS ----------------
  glaciersQuestComplete: function (args) {
    // test if user has completed the glaciersQuest
    const vm = args.vm;
    return (
      "history" in vm.USER_DATA &&
      vm.USER_DATA.history.findIndex((item) => {
        return item.startsWith("c_glaciersQuest");
      }) !== -1
    );
  },
  waterAndFishQuestComplete: function (args) {
    // test if user has completed the waterAndFishQuest
    const vm = args.vm;
    return (
      "history" in vm.USER_DATA &&
      vm.USER_DATA.history.findIndex((item) => {
        return item.startsWith("c_waterAndFishQuest");
      }) !== -1
    );
  },
  elbowWatershedQuestComplete: function (args) {
    // test if user has completed the elbowWatershedQuest
    const vm = args.vm;
    return (
      "history" in vm.USER_DATA &&
      vm.USER_DATA.history.findIndex((item) => {
        return item.startsWith("c_elbowWatershedQuest");
      }) !== -1
    );
  },
  waterUseQuestComplete: function (args) {
    // test if user has completed the waterUseQuest
    const vm = args.vm;
    return (
      "history" in vm.USER_DATA &&
      vm.USER_DATA.history.findIndex((item) => {
        return item.startsWith("c_waterUseQuest");
      }) !== -1
    );
  },
  forestryQuestComplete: function (args) {
    // test if user has completed the forestryQuest
    const vm = args.vm;
    return (
      "history" in vm.USER_DATA &&
      vm.USER_DATA.history.findIndex((item) => {
        return item.startsWith("c_forestryQuest");
      }) !== -1
    );
  },
  oilSandsQuestComplete: function (args) {
    // test if user has completed the oilSandsQuest
    const vm = args.vm;
    return (
      "history" in vm.USER_DATA &&
      vm.USER_DATA.history.findIndex((item) => {
        return item.startsWith("c_oilSandsQuest");
      }) !== -1
    );
  },
  climateChangeQuestComplete: function (args) {
    // test if user has completed the climateChangeQuest
    const vm = args.vm;
    return (
      "history" in vm.USER_DATA &&
      vm.USER_DATA.history.findIndex((item) => {
        return item.startsWith("c_climateChangeQuest");
      }) !== -1
    );
  },
  // ---------- UI SETUP CONDITIONS ----------------
  obsLayerOn: function (args) {
    // make sure observations layer is turned on
    const vm = args.vm;
    vm.SHOW_OBSERVATIONS_ON();
  },
  satelliteLayerOn: function (args) {
    // turn on satellite layer
    const vm = args.vm;
    if (vm.$options.name === "Explore") {
      vm.$refs.covermap.setStyle("satellite");
    } else {
      vm.SET_BASEMAP_STYLE("satellite");
    }
  },
  launchExplore: function (args) {
    // go to the explore screen
    const vm = args.vm;
    vm.$router.push({
      name: "Explore",
    });
  },
  launchScenarios: function (args) {
    // go to the scenarios screen
    const vm = args.vm;
    vm.$router.push({
      name: "Scenarios",
    });
  },
  launchObservations: function (args) {
    // go to the observations screen
    const vm = args.vm;
    vm.$router.push({
      name: "Observations",
    });
  },
  launchReports: function (args) {
    // go to the reports screen
    const vm = args.vm;
    vm.$router.push({
      name: "Reports",
    });
  },
  launchVideos: function (args) {
    // go to the videos screen
    const vm = args.vm;
    vm.$router.push({
      name: "Videos",
    });
  },
  launchClimateVideo: function (args) {
    // go to the videos screen
    const vm = args.vm;
    vm.$router.push({
      name: "Videos",
      params: {
        "auto-play-video": {
          title: vm.$Region.climateInfo.title,
          media: vm.$Region.climateInfo.media,
        },
      },
    });
  },
  launchWaterQualityVideo: function (args) {
    // go to the videos screen
    const vm = args.vm;
    const ind = vm.$Region.indicators.find(
      (ind) => ind.name === "Water Quality"
    );
    vm.$router.push({
      name: "Videos",
      params: {
        "auto-play-video": {
          title: ind.name,
          media: ind.media,
        },
      },
    });
  },
  launchBlackfootPerspectiveVideo: function (args) {
    // go to the videos screen
    const vm = args.vm;
    vm.$router.push({
      name: "Videos",
      params: {
        "auto-play-video": {
          title: "A Blackfoot Perspective on Water",
          media: "video",
        },
      },
    });
  },
};

export default RegionConditions;
