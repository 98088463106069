<template>
  <div class="content">
    <div class="md-layout">
      <!-- VIDEO PLAYER AREA -->
      <slide-y-up-transition>
        <div
          v-if="videoActive"
          class="md-layout-item md-size-100 active-video-player"
        >
          <md-card>
            <md-card-header>
              <div class="md-title">
                {{ currentVideoTitle }}
              </div>
            </md-card-header>
            <md-card-content>
              <video-player
                ref="videoplayer"
                :options="getVideoOptions(currentVideoSrc, currentVideoThumb)"
                :is360="currentVideo360"
                :title="currentVideoTitle"
              />
              <div
                class="md-layout"
                style="padding-top: 20px"
              >
                <div class="md-layout-item md-size-80 md-xsmall-size-100">
                  <p>{{ currentVideoDesc }}</p>
                  <!-- 360 video -->
                  <md-chip
                    v-if="currentVideo360"
                    class="threesixty-chip"
                  >
                    360°
                    <md-tooltip md-direction="top">
                      Drag video to explore!
                    </md-tooltip>
                  </md-chip>
                </div>
                <div class="md-layout-item md-size-20 md-xsmall-size-100">
                  <!-- SHARING DIALOG -->
                  <share-dialog
                    v-if="$Region.socialSharingOn"
                    obj-type="vid"
                    :obj="currentVideoShareObj"
                    gtag-action="share_video_close"
                    gtag-category="videos"
                    @on-show-dialog="pausePlayback"
                  />
                </div>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </slide-y-up-transition>
      <div class="md-layout-item md-size-100 md-scrollbar">
        <!-- GENERAL VIDEOS: MISSION, SYSTEM VIDEOS, CLIMATE, MGMT PRACTICES -->
        <div v-if="generalVideos.length > 0">
          <md-card
            v-for="(cat, index) in generalVideos"
            :key="`vidcat-${index}`"
          >
            <md-card-header>
              <div class="md-title">
                {{ cat.title }}
              </div>
            </md-card-header>
            <div class="md-card-content md-layout">
              <div
                v-for="vid in cat.videos"
                :key="vid.id"
                class="md-layout-item md-size-25 md-medium-size-33 md-small-size-100"
              >
                <md-card md-with-hover>
                  <md-ripple>
                    <md-card-media-cover md-solid>
                      <md-card-media md-ratio="16:9">
                        <img
                          :src="vid.media.thumbUrl"
                          :alt="vid.title"
                          @click="launchVideo(vid)"
                        >
                      </md-card-media>
                      <md-card-header>
                        <span
                          class="title"
                          @click="launchVideo(vid)"
                        >
                          {{ vid.title }}
                        </span>
                      </md-card-header>
                    </md-card-media-cover>
                  </md-ripple>
                </md-card>
              </div>
            </div>
          </md-card>
        </div>
        <!-- END GENERAL VIDEOS: MISSION, SYSTEM VIDEOS, CLIMATE, MGMT PRACTICES -->
        <!-- INDICATORS -->
        <md-card
          v-for="type in indicatorTypes"
          :key="type"
        >
          <md-card-header>
            <div class="md-title">
              {{ type }} Indicators
            </div>
          </md-card-header>
          <div class="md-card-content md-layout">
            <div
              v-for="ind in indicatorsByType(type)"
              :key="ind.name"
              class="md-layout-item md-size-25 md-medium-size-33 md-small-size-100"
            >
              <md-card md-with-hover>
                <md-ripple>
                  <md-card-media-cover md-solid>
                    <md-card-media md-ratio="16:9">
                      <img
                        :src="ind.media.thumbUrl"
                        :alt="ind.name"
                        @click="launchVideo(ind)"
                      >
                    </md-card-media>
                    <md-card-header>
                      <span
                        class="title"
                        @click="launchVideo(ind)"
                      >
                        {{ ind.name }}
                      </span>
                    </md-card-header>
                  </md-card-media-cover>
                </md-ripple>
              </md-card>
            </div>
          </div>
        </md-card>
        <!-- END INDICATORS -->
        <!-- THEMED SYSTEM OBSERVATIONS (OBSERVATIONS WITH A subtype SPECIFIED) -->
        <div v-if="Object.keys(categorizedObservations).length > 0">
          <div
            v-for="obsCat in Object.keys(categorizedObservations)"
            :key="obsCat"
          >
            <md-card>
              <md-card-header>
                <div class="md-title">
                  {{ $Region.observation_types[obsCat].title }}
                </div>
              </md-card-header>
              <div class="md-card-content md-layout">
                <div
                  v-for="obs in categorizedObservations[obsCat]"
                  :key="obs.id"
                  class="md-layout-item md-size-25 md-medium-size-33 md-small-size-100"
                >
                  <md-card md-with-hover>
                    <md-ripple>
                      <md-card-media-cover md-solid>
                        <md-card-media md-ratio="16:9">
                          <img
                            :src="obs.media.thumbUrl"
                            :alt="obs.title"
                            @click="launchVideo(obs)"
                          >
                        </md-card-media>
                        <md-card-header>
                          <span
                            class="title"
                            @click="launchVideo(obs)"
                          >
                            {{ obs.title }}
                            <!-- 360 video -->
                            <md-chip
                              v-if="'is360' in obs.media && obs.media.is360 == true"
                              class="threesixty-chip"
                            >
                              360°
                              <md-tooltip md-direction="top">
                                Drag video to explore!
                              </md-tooltip>
                            </md-chip>
                          </span>
                        </md-card-header>
                        <md-card-actions>
                          <md-button
                            class="md-raised md-info center-vertical"
                            @click="explore(obs.id, obsCat, true)"
                          >
                            <md-icon>explore</md-icon>Explore
                            <md-tooltip md-direction="bottom">
                              View on map
                            </md-tooltip>
                          </md-button>
                        </md-card-actions>
                      </md-card-media-cover>
                    </md-ripple>
                  </md-card>
                </div>
              </div>
            </md-card>
          </div>
        </div>
        <!-- END THEMED SYSTEM OBSERVATIONS -->
        <!-- POINTS OF INTEREST (GENERAL UNCATEGORIZED SYSTEM OBSERVATIONS) -->
        <md-card v-if="pointsOfInterest.length > 0">
          <md-card-header>
            <div class="md-title">
              Points of Interest
            </div>
          </md-card-header>
          <div class="md-card-content md-layout">
            <div
              v-for="obs in pointsOfInterest"
              :key="obs.id"
              class="md-layout-item md-size-25 md-medium-size-33 md-small-size-100"
            >
              <md-card md-with-hover>
                <md-ripple>
                  <md-card-media-cover md-solid>
                    <md-card-media md-ratio="16:9">
                      <img
                        :src="obs.media.thumbUrl"
                        :alt="obs.title"
                        @click="launchVideo(obs)"
                      >
                    </md-card-media>
                    <md-card-header>
                      <span
                        class="title"
                        @click="launchVideo(obs)"
                      >
                        {{ obs.title }}
                      </span>
                    </md-card-header>
                    <md-card-actions>
                      <md-button
                        class="md-raised md-info center-vertical"
                        @click="explore(obs.id, 'system', true)"
                      >
                        <md-icon>explore</md-icon>Explore
                        <md-tooltip md-direction="bottom">
                          View on map
                        </md-tooltip>
                      </md-button>
                    </md-card-actions>
                  </md-card-media-cover>
                </md-ripple>
              </md-card>
            </div>
          </div>
        </md-card>
        <!-- END POINTS OF INTEREST -->
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer";
import { SlideYUpTransition } from "vue2-transitions";
import ShareDialog from "@/pages/Dashboard/Components/ShareDialog";
import { Observation } from "@/pages/Dashboard/Components/mixins/Observation";

export default {
  name: "Videos",

  components: {
    VideoPlayer,
    SlideYUpTransition,
    ShareDialog,
  },
  mixins: [Observation], // for launching system observations into explore mode

  props: {
    autoPlayVideo: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      videoActive: false,
      currentVideoTitle: "Now Playing",
      currentVideoDesc: null,
      currentVideoSrc: null,
      currentVideoThumb: null,
      currentVideoShareObj: null,
      currentVideo360: false,
    };
  },

  computed: {
    indicatorsWithVideos() {
      // return a list of indicators with videos
      const inds = this.$Region.indicators.filter((ind) => {
        if ("media" in ind && ind.media.type === "video") {
          return ind;
        }
      });
      return inds;
    },
    indicatorTypes() {
      // return a list of indicator types based on only indicators with videos
      const indTypes = [
        ...new Set(this.indicatorsWithVideos.map((ind) => ind.type)),
      ];
      return indTypes;
    },
    generalVideos() {
      const videoCats = [];
      let catCount = 0;
      let count = 0;
      let catInit = false;
      const initCat = function (cat) {
        // initialize a video category in the videoCats obj
        videoCats.push({
          title: cat.title,
          videos: [],
        });
        catInit = true;
      };
      // find the category to use for other general videos (mission, climate, mgmt practices)
      // use the first category if none specified
      const catIndex =
        this.$Region.system_videos.findIndex(
          (cat) => "includeOtherVideos" in cat
        ) !== -1
          ? this.$Region.system_videos.findIndex(
              (cat) => "includeOtherVideos" in cat
            )
          : 0;

      // get system videos by category
      this.$Region.system_videos.forEach((cat) => {
        catInit = false; // new category not yet initialized
        // get the videos for mission, climate change, mgmt practices if there are any
        if (catCount === catIndex) {
          [
            this.$Region.mission,
            this.$Region.climateInfo,
            this.$Region.mgmtInfo,
          ].forEach((vid) => {
            if (
              "media" in vid &&
              "type" in vid.media &&
              vid.media.type === "video" &&
              vid.media.url !== ""
            ) {
              if (!catInit) {
                // setup a new category if it's not setup yet
                initCat(cat);
              }
              count += 1;
              const videoObj = {
                id: count,
                title: vid.title,
                description: vid.description,
                media: {
                  url: vid.media.url,
                  thumbUrl: vid.media.thumbUrl,
                  shareUrl: vid.media.shareUrl,
                },
              };
              if ("hashtags" in vid) {
                videoObj.hashtags = vid.hashtags;
              }
              // mission, climate, mgmt videos always go into the first category
              videoCats[catCount].videos.push(videoObj);
            }
          });
        }

        // get any additional system videos by category
        if ("videos" in cat && cat.videos.length > 0) {
          if (!catInit) {
            // setup a new category if it's not setup yet
            initCat(cat);
          }
          cat.videos.forEach((vid) => {
            count += 1;
            const videoObj = {
              id: count,
              title: vid.title,
              description: vid.description,
              media: {
                url: vid.media.url,
                thumbUrl: vid.media.thumbUrl,
                shareUrl: vid.media.shareUrl,
              },
            };
            if ("hashtags" in vid) {
              videoObj.hashtags = vid.hashtags;
            }
            videoCats[catCount].videos.push(videoObj);
          });
          catCount += 1;
        }
      });
      if (count > 0) {
        return videoCats;
      }
      return []; // no videos found
    },
    pointsOfInterest() {
      // any general system observations that have videos and are not otherwise categorized with a subtype become Points of Interest
      return this.$Region.system_observations.filter(
        (obs) =>
          !("subtype" in obs) && "media" in obs && obs.media.type === "video"
      );
    },
    categorizedObservations() {
      // any system observations that have a subtype specified and include a video will be put into a section for that subtype
      const obsCategories = Object.keys(this.$Region.observation_types).filter(
        (_key) => !["water", "general", "system"].includes(_key)
      );
      const obsVideos = {};
      obsCategories.forEach((obsCat) => {
        const _videos = this.$Region.system_observations.filter(
          (obs) =>
            "subtype" in obs &&
            obs.subtype == obsCat &&
            "media" in obs &&
            obs.media.type === "video"
        );
        if (_videos.length) {
          obsVideos[obsCat] = _videos;
        }
      });
      return obsVideos;
    },
  },

  mounted() {
    if (this.autoPlayVideo !== null) {
      this.launchVideo(this.autoPlayVideo);
    }
  },

  methods: {
    indicatorsByType(type) {
      // return list of indicators/videos by type
      return this.indicatorsWithVideos.filter((ind) => ind.type === type);
    },
    getVideoOptions(src, thumb) {
      // get video player options for a single video
      return {
        autoplay: true,
        fluid: true,
        preload: "none",
        poster: thumb,
        controls: true,
        techOrder: ["html5", "youtube"],
        sources: [
          {
            src: src,
            type: this.getVideoType(src),
            youtube: {
              autoplay: 1,
              rel: 0,
            },
          },
        ],
      };
    },
    getVideoType(src) {
      // determine whether a given video is youtube or not
      if (src.includes("youtube.com")) {
        return "video/youtube";
      }
      return "application/x-mpegURL";
    },
    launchVideo(videoObj) {
      // launch a selected video into the player
      const title = "title" in videoObj ? videoObj.title : videoObj.name;
      const vm = this;
      const setupVideo = function (vm) {
        vm.currentVideoShareObj = videoObj;
        vm.currentVideoTitle = title;
        vm.currentVideoDesc = videoObj.description;
        vm.currentVideoSrc = videoObj.media.url;
        vm.currentVideoThumb = videoObj.media.thumbUrl;
        vm.videoActive = true;
        vm.currentVideo360 = 'is360' in videoObj.media && videoObj.media.is360 == true ? true : false;
      };
      if (this.videoActive) {
        // clear previous video and wait before relaunching
        this.videoActive = false;
        setTimeout(function () {
          setupVideo(vm);
          vm.$Helpers.scrollToElement(vm, "active-video-player");
        }, 250);
      } else {
        // launch video
        setupVideo(vm);
        setTimeout(function () {
          vm.$Helpers.scrollToElement(vm, "active-video-player");
        }, 250);
      }
    },
    pausePlayback() {
      // pause playback of current video if playing
      this.$refs.videoplayer.pausePlayback();
    },
  },
};
</script>

<style lang="scss" scoped>
.share-button {
  background: none !important;
  color: red !important;
}
.threesixty-chip {
  background-color: $brand-info !important;
}
</style>
