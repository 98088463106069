/* jshint ignore: start */
// mixin for some video player handling
export const VideoOptions = {
  data() {
    return {};
  },

  computed: {},

  methods: {
    showVideo(info_obj) {
      // is there a video available to display
      return (
        "media" in info_obj &&
        info_obj.media.type == "video" &&
        info_obj.media.url !== ""
      );
    },
    getVideoOptions(info_obj) {
      // get video player options for a single video
      const options = {
        autoplay: false,
        preload: "none",
        fluid: true,
        controls: true,
        techOrder: ["html5", "youtube"],
        sources: [
          {
            src: info_obj.media.url,
            type: this.getVideoType(info_obj.media.url),
            youtube: {
              autoplay: 0,
              rel: 0
            }
          }
        ]
      };
      // backwards compatible with observations with no thumb... get thumbnail
      if ("thumbUrl" in info_obj.media && info_obj.media.thumbUrl !== "") {
        options.poster = info_obj.media.thumbUrl;
      }
      return options;
    },
    getVideoType(src) {
      // determine whether a given video is youtube or not
      if (src.includes("youtube.com")) {
        return "video/youtube";
      }
      return "application/x-mpegURL";
    }
  }
};
