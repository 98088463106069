<!-- this component provides a data object to handle scenario bitmap data which can then be emitted back to the report -->
<script>
import { ScenarioDataHandler } from "@/pages/Dashboard/Components/mixins/ScenarioDataHandler";
export default {
  name: "ReportScenarioRenderless",

  mixins: [ScenarioDataHandler],

  props: {
    scenario: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      regionIndicators: [] // list of indicators specific to the study area
    };
  },

  computed: {
    minYear() {
      return this.scenario.startTime;
    },
    maxYear() {
      return this.scenario.endTime;
    },
  },

  created() {
    // load and parse jickle data
    this.simulationState = this.emptySimulationState; //setup simulationState object
    this.loadScenarioData();
  },

  methods: {
    loadScenarioData() {
      // load bitmaps and related scenario data
      this.$Auth.currentSession().then(authData => {
        const myInit = {
          headers: { Authorization: authData.idToken.jwtToken },
          body: {
            bucket: this.$Bucket
          }
        };
        // get study area settings from region geojson
        this.loadStudyAreaData(myInit, this.scenario.studyArea);
        // get the jickle bitmap data
        this.loadReportSimulationBitmaps(myInit, this.scenario);
        // get climate indicators
        this.getClimateInds(myInit, this.scenario);
      });
    },

    async loadReportSimulationBitmaps(myInit, scenario) {
      // load jickle from s3 and parse into object
      const dsPath = this.getJicklePath(scenario);

      const response = await this.$API.post("api", dsPath, myInit);

      // don't know why jickles coming from /public/ folder get different formatted tempLink's than those from /username/ folder
      // might be because of the filenames with special characters and spaces
      let jickleEndpoint = response.body.tempLink;
      if (dsPath.startsWith("/public/")) {
        // replace the space characters with + signs
        // remove the querystring -- causes forbidden error
        jickleEndpoint = jickleEndpoint.replace(/%2520/g, "+").split("?")[0];
      }

      // parse the jickle and load settings into intensityLayer
      this.getJickle(jickleEndpoint, this.parseAndLoadReportLanduseJickle);
    },

    async parseAndLoadReportLanduseJickle(data) {
      await this.parseAndLoadLanduseJickle(data, true, false, true);
      // send data back to the parent
      this.$emit('on-jickle-loaded', this.scenario.id, this.regionIndicators, this.simulationState, this.intensityMapData);
    },
  },

  render() {
    return this.$scopedSlots.default();
  }
};
</script>