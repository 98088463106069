// mixin for some scenario wizard handling
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import swal from "sweetalert2";

export const ScenarioWizardHelpers = {
  computed: {
    /* jshint ignore:start */
    ...mapGetters([
      types.getters.SELECTED_REGIONS,
      types.getters.ACTIVE_SCENARIO
    ]),
    /* jshint ignore:end */

    systemScenario() {
      // return matching system scenario from region config
      return this.$Region.system_scenarios.find(
        scen => scen.type == this.ACTIVE_SCENARIO.type
      );
    },
    historicScenario() {
      // return matching system scenario from region config
      return this.ACTIVE_SCENARIO.type === "Historic";
    }
  },

  methods: {
    ...mapActions([
      types.actions.SET_EXPLORE_MODE,
      types.actions.CLEAR_SCENARIO_AND_REGIONS,
      types.actions.TOGGLE_ACTIVE_SCENARIO_WIZARD_BTN,
      types.actions.UPDATE_ACTIVE_SCENARIO
    ]),

    errorAlert(message) {
      swal.fire({
        title: `<p style="color:red;">Not so fast...</p>`,
        text: message,
        timer: 4000,
        showConfirmButton: false
      });
    },
    closeCreateMode() {
      // quit the wizard and close the scenario
      this.CLEAR_SCENARIO_AND_REGIONS();
      this.SET_EXPLORE_MODE("explore");
    },
    nextTab() {
      // trigger wizard to move to next tab
      this.TOGGLE_ACTIVE_SCENARIO_WIZARD_BTN("next");
    },
    prevTab() {
      // trigger wizard to move to previous tab
      this.TOGGLE_ACTIVE_SCENARIO_WIZARD_BTN("prev");
    }
  }
};
