<template>
  <md-card md-with-hover>
    <md-card-header class="md-card-header-blue">
      <div class="md-layout">
        <div class="md-layout-item md-size-100 card-title">
          <md-icon class="card-title-icon">
            place
          </md-icon>
          <a @click="explore()">
            <span class="md-title">
              {{ observationData.title }}
            </span></a>
        </div>
        <div class="md-layout-item md-size-100">
          <span class="md-caption text-white">{{ observedDate(observationData.observedTime) }}</span>
        </div>
        <div class="md-layout-item md-size-100">
          <i
            :class="observationIcon(observationData.type)"
            class="card-title"
          />
          <span class="card-subtitle">{{ observationType(observationData.type) }}</span>
        </div>
      </div>
    </md-card-header>
    <md-card-content class="md-layout">
      <div class="md-layout-item md-size-100">
        <study-area-map
          v-if="showMap"
          ref="map"
          :observation="observationData"
          class="scenario-map"
        />
        {{ observationData.description }}
      </div>
      <div class="md-layout-item md-size-100 card-actions">
        <!-- DELETE -->
        <md-button
          class="md-simple md-just-icon"
          @click="confirmDeleteObservation()"
        >
          <md-icon>delete</md-icon>
          <md-tooltip md-direction="bottom">
            Delete this observation
          </md-tooltip>
        </md-button>
        <!-- SHARE -->
        <share-dialog
          v-if="$Region.socialSharingOn"
          ref="shareDialog"
          obj-type="obs"
          :obj="observationData"
          gtag-action="share_observation_close"
          gtag-category="observations"
        >
          <md-button class="md-simple md-just-icon">
            <span @click="toggleShareDialog">
              <md-icon>share</md-icon>
              <md-tooltip md-direction="bottom">Share</md-tooltip>
            </span>
          </md-button>
        </share-dialog>
        <!-- EXPLORE -->
        <md-button
          class="md-raised md-dense md-success explore-btn"
          @click="explore()"
        >
          <md-icon>explore</md-icon>Explore
          <md-tooltip md-direction="bottom">
            View on map
          </md-tooltip>
        </md-button>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import StudyAreaMap from "./StudyAreaMap";
import ShareDialog from "@/pages/Dashboard/Components/ShareDialog";
import { ObservationSummary } from "@/pages/Dashboard/Components/mixins/ObservationSummary";

export default {
  name: "UserObservationCard",
  components: {
    StudyAreaMap,
    ShareDialog
  },
  mixins: [ObservationSummary],

  props: {
    observationData: {
      type: Object,
      required: true
    },
    showMap: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    };
  },
  computed: {},
  methods: {
    explore() {
      // emit observation for launching into explore view
      this.$emit('on-explore', this.observationData.id);
    },
    confirmDeleteObservation() {
      // emit observation for deleting
      this.$emit('on-delete', this.observationData.id);
    },
    toggleShareDialog() {
      this.$refs.shareDialog.toggleShowDialog();
    }
  }
};
</script>

<style lang="scss" scoped>
.card-title-icon {
  position: relative;
  padding: 0;
  color: $white-color !important;
}
.md-title {
  font-size: 16px;
  line-height: 20px;
}
.card-title {
  color: $white-color !important;
}
.card-subtitle {
  color: darken($white-color, 2%) !important;
}
i.card-title {
  color: darken($white-color, 4%) !important;
  padding: 0 5px 0 5px;
}
.text-white {
  color: $white-color !important;
}
.card-actions {
  text-align: right;
}
.explore-btn {
  left: 5px;
  margin-top: 10px;
}
/deep/ .explore-btn .md-ripple {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
</style>
