// schools, teachers and classes settings

const RegionSchools = {
  schools: [
    "A B Daley Community School, Nanton, (The Livingstone Range School Division)",
    "A. Blair McPherson School, Edmonton, (The Edmonton School Division)",
    "A. E. Bowers Elementary School, Airdrie, (The Rocky View School Division)",
    "A. E. Cross School, Calgary, (The Calgary School Division)",
    "A. L. Horton Elementary School, Vegreville, (The Elk Island School Division)",
    "AADAC Youth Services, Calgary, (The Calgary School Division)",
    "Aahsaopi Elementary School, Glenwood, (Kainai Board of Education)",
    "Abbeydale School, Calgary, (The Calgary School Division)",
    "Abbott School, Edmonton, (The Edmonton School Division)",
    "ABC Head Start Society  - Millwoods, Edmonton, (ABC Head Start Society)",
    "Aboriginal Head Start - Abbott Site, Edmonton, (Amiskwaciy Cultural Society)",
    "Aboriginal Head Start - Belmead Site, Edmonton, (Amiskwaciy Cultural Society)",
    "Aboriginal Head Start - St. Francis Site, Edmonton, (Amiskwaciy Cultural Society)",
    "Academie Saint-Andre Academy, Beaumont, (The St. Thomas Aquinas Roman Catholic Separate School Division )",
    "Academy of Learning, Edmonton, (Other Schools)",
    "Acadia Colony School, Acadia Valley, (The Prairie Rose School Division)",
    "Acadia School, Calgary, (The Calgary School Division)",
    "Access Academy, Calgary, (Access International College (Calgary) Incorporated)",
    "ACE Place Learning Centre, Taber, (The Horizon School Division)",
    "Acme School, Acme, (The Golden Hills School Division)",
    "Across the Waters Outreach, Whitecourt, (The Living Waters Catholic Separate School Division)",
    "Adolescent Day Treatment Program, Calgary, (The Calgary School Division)",
    "Afton School, Edmonton, (The Edmonton School Division)",
    "Agape Early Childhood Services, Lethbridge, (Victory Christian Fellowship of Lethbridge (1983) Inc.)",
    "Airdrie Christian Academy, Airdrie, (Airdrie Christian Academy Society)",
    "Airdrie Foundation Four Early Childhood Intervention, Airdrie, (Airdrie Foundation Four Early Childhood Intervention)",
    "Airdrie Francophone School, Airdrie, (The Southern Francophone Education Region)",
    "Airdrie Learning Connection, Airdrie, (The Rocky View School Division)",
    "Airdrie Petits Amis Support Services, Airdrie, (Airdrie Petits Amis Support Services)",
    "Akiva Academy, Calgary, (Congregation House of Jacob-Mikveh Israel)",
    "Al Mustafa North Location, Edmonton, (Al Mustafa Academy & Humanitarian Society)",
    "Al-Mustafa Academy, Edmonton, (Al Mustafa Academy & Humanitarian Society)",
    "AlBaqir Academy, Edmonton, (AlBaqir Academy Association)",
    "Albert Lacombe Catholic Elementary School, St. Albert, (The Greater St. Albert Roman Catholic Separate School Division)",
    "Alberta Ballet School, Calgary, (The Alberta Ballet Company)",
    "Alberta Chung Wah School, Calgary, (Alberta Chung Wah School Society)",
    "Alberta Distance Learning Centre, Barrhead, (The Pembina Hills School Division)",
    "Alberta Education, Edmonton, (Alberta Education)",
    "Alberta Health Services Intensive Day Treatment Youth Program, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Alberta Health Services Youth Residential Treatment Centre, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Alberta High School of Fine Arts, Okotoks, (The Foothills School Division)",
    "Alberta Learning - Self Registrations, Edmonton, (Other Schools)",
    "Alberta School for the Deaf, Edmonton, (The Edmonton School Division)",
    "Albion Ridge Colony School, Picture Butte, (The Palliser School Division)",
    "Alcoma School, Rainier, (The Grasslands School Division)",
    "Alder Flats Elementary School, Alder Flats, (The Wetaskiwin School Division)",
    "Aldergrove School, Edmonton, (The Edmonton School Division)",
    "Aleda Patterson School, , (The Edmonton School Division)",
    "Alex Ferguson School, Calgary, (The Calgary School Division)",
    "Alex Janvier School, , (The Edmonton School Division)",
    "Alex Munro School, Calgary, (The Calgary School Division)",
    "Alexander Forbes School, Grande Prairie, (The Grande Prairie School Division)",
    "Alexandra Middle School, Medicine Hat, (The Medicine Hat School Division)",
    "Alexis Elementary Junior Senior High School, Glenevis, (Alexis Band)",
    "Alix (Mirror and Alix Central), Alix, (The Wolf Creek School Division)",
    "Alix Colony School, Alix, (The Wolf Creek School Division)",
    "All Boys Alternative Program, Calgary, (The Calgary School Division)",
    "All Saints School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Allan Johnstone School, Hardisty, (The Battle River School Division )",
    "Allen Gray Child Development Centre, Edmonton, (The Gray House Guild)",
    "Allenby Colony, Lethbridge, (The Palliser School Division)",
    "Allendale School, Edmonton, (The Edmonton School Division)",
    "Almadina Language Charter Academy, Mountain View Campus, Calgary, (Almadina School Society)",
    "Almadina Language Charter Academy, Ogden Campus, Calgary, (Almadina School Society)",
    "Alpenglow Community School, Canmore, (The Canadian Rockies School Division)",
    "Alpine Children's Centre, Calgary, (Montessori Education Preschool & ECS Institute of Alberta)",
    "Altadore School, Calgary, (The Calgary School Division)",
    "Altario School, Altario, (The Prairie Land School Division)",
    "Altario School ECS, Altario, (Kirriemuir Altario Compeer School Project Society)",
    "Alternative Education, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Alternative High School, Calgary, (The Calgary School Division)",
    "Amisk Community School, Lac La Biche, (Beaver Lake Education Authority)",
    "Amisk School, Amisk, (The Buffalo Trail School Division)",
    "Amiskwaciy Academy, Edmonton, (The Edmonton School Division)",
    "Anchors Outreach School, Strathmore, (The Golden Hills School Division)",
    "Andrew School, Andrew, (The Elk Island School Division)",
    "Andrew Sibbald School, Calgary, (The Calgary School Division)",
    "Anne Fitzgerald, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Annie Foote School, Calgary, (The Calgary School Division)",
    "Annie Gale School, Calgary, (The Calgary School Division)",
    "Annie L Gaetz School, Red Deer, (The Red Deer School Division )",
    "Annunciation, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Anzac School, Anzac, (The Northland School Division)",
    "Apostles of Jesus, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Apple Tree Preschool, Calgary, (Trail Kids Ltd.)",
    "Arbour Lake Middle School, Calgary, (The Calgary School Division)",
    "Archbishop Jordan Catholic High School, Sherwood Park, (The Elk Island Catholic Separate School Division)",
    "Archbishop Joseph MacNeil, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Archbishop MacDonald, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Archbishop O'Leary, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Arden T. Litt Centre for Learning, Grassy Lake, (The Horizon School Division)",
    "Ardmore School, Ardmore, (The Northern Lights School Division)",
    "Ardrossan Junior Senior High School, Ardrossan, (The Elk Island School Division)",
    "Argyll Home School Centre, Edmonton, (The Edmonton School Division)",
    "Armada Colony School, Taber, (The Horizon School Division)",
    "Arrowwood Colony School, Lethbridge, (The Palliser School Division)",
    "Arrowwood Community School, Arrowwood, (The Palliser School Division)",
    "Arrowwood School, Arrowwood, (Arrowwood and District E.C.S. Society)",
    "Art Smith Aviation Academy, Cold Lake, (The Northern Lights School Division)",
    "Asasa Academy, Calgary, (Asasa Learning)",
    "Ascension of Our Lord, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Ashmont Elementary Community School, Ashmont, (The St. Paul School Division)",
    "Ashmont Secondary School, Ashmont, (The St. Paul School Division)",
    "Aspen Grove School, Grande Prairie, (The Grande Prairie School Division)",
    "Aspen Heights Elementary School, Red Deer, (The Red Deer School Division )",
    "Aspen Hill Montessori School, Calgary, (Aspen Hill Montessori Casa)",
    "Aspen Program, Edmonton, (The Edmonton School Division)",
    "Aspire Special Needs Resource Centre, Red Deer, (Aspire Special Needs Resource Centre)",
    "Assumption Junior Senior High School, Cold Lake, (The Lakeland Roman Catholic Separate School Division)",
    "Assumption Roman Catholic School, Oyen, (The Christ the Redeemer Catholic Separate School Division)",
    "Athabasca Colony School, Athabasca, (The Aspen View School Division)",
    "Athabasca Delta Community School, Fort Chipewyan, (The Northland School Division)",
    "Athabasca University Learning Centre, Edmonton, (Other Schools)",
    "Athlone School, Edmonton, (The Edmonton School Division)",
    "Atlas Learning Academy Ltd., Airdrie, (Atlas Learning Academy Ltd.)",
    "Auburn Bay School, Calgary, (The Calgary School Division)",
    "August Departmental Exam, Edmonton, (Other Schools)",
    "Aurora Elementary School, Drayton Valley, (The Wild Rose School Division )",
    "Aurora Learning Calgary, Calgary, (Aurora Learning Foundation (Calgary))",
    "Aurora Learning Foundation, Sherwood Park, (Aurora Learning Foundation)",
    "Aurora Middle School, Lac La Biche, (The Northern Lights School Division)",
    "Aurora School, Edmonton, (Aurora School Ltd.)",
    "Aurora School, Edmonton, (Aurora School Ltd.)",
    "Austin O'Brien, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Autumn Leaf Colony School, Wainwright, (The Buffalo Trail School Division)",
    "Avalon Junior School, Edmonton, (The Edmonton School Division)",
    "Avondale School, Grande Prairie, (The Grande Prairie School Division)",
    "Avonmore School, Edmonton, (The Edmonton School Division)",
    "Baker Colony School, Foremost, (The Prairie Rose School Division)",
    "Balmoral School, Calgary, (The Calgary School Division)",
    "Balwin School, Edmonton, (The Edmonton School Division)",
    "Banbury Crossroads School, Calgary, (Banbury Crossroads School)",
    "Banded Peak School, Bragg Creek, (The Rocky View School Division)",
    "Banff Community High School, Banff, (The Canadian Rockies School Division)",
    "Banff Elementary School, Banff, (The Canadian Rockies School Division)",
    "Banff Trail School, Calgary, (The Calgary School Division)",
    "Bannerman School, Edmonton, (The Edmonton School Division)",
    "Banting and Best School, Calgary, (The Calgary School Division)",
    "Barnwell School, Barnwell, (The Horizon School Division)",
    "Barons School, Barons, (The Palliser School Division)",
    "Barr Colony, Lloydminster, (Lloydminster Public School Division)",
    "Barrhead Composite High School, Barrhead, (The Pembina Hills School Division)",
    "Barrhead Elementary School, Barrhead, (The Pembina Hills School Division)",
    "Barrhead Outreach, Barrhead, (The Pembina Hills School Division)",
    "Bashaw School, Bashaw, (The Battle River School Division )",
    "Bassano School, Bassano, (The Grasslands School Division)",
    "Battalion Park School, Calgary, (The Calgary School Division)",
    "Battle River Home Schools, Camrose, (The Battle River School Division )",
    "Battle River Online, Camrose, (The Battle River School Division )",
    "Battle River Summer School, Camrose, (The Battle River School Division )",
    "Baturyn School, Edmonton, (The Edmonton School Division)",
    "Bawlf School, Bawlf, (The Battle River School Division )",
    "Bay Tree Colony School, Bay Tree, (The Peace Wapiti School Division)",
    "Beacon Heights School, Edmonton, (The Edmonton School Division)",
    "Beacon Hill Elementary School, Sylvan Lake, (The Chinook's Edge School Division)",
    "Beacon Hill School, Fort McMurray, (The Fort McMurray School Division)",
    "Bear Canyon Hutterite Colony, Peace River, (The Peace River School Division)",
    "Bearspaw Christian School & College, Calgary, (Bearspaw Christian School Society)",
    "Bearspaw Christian Virtual School, , (Bearspaw Christian School Society)",
    "Bearspaw School, Calgary, (The Rocky View School Division)",
    "Beaverlodge Elementary School, Beaverlodge, (The Peace Wapiti School Division)",
    "Beaverlodge Regional High School, Beaverlodge, (The Peace Wapiti School Division)",
    "Beddington Heights School, Calgary, (The Calgary School Division)",
    "Behaviour Therapy and Learning Centre Ltd., Calgary, (Behaviour Therapy and Learning Centre Ltd.)",
    "Beiseker Colony School, Beiseker, (The Rocky View School Division)",
    "Beiseker Community School, Beiseker, (The Rocky View School Division)",
    "Belfast School, Calgary, (The Calgary School Division)",
    "Belgravia School, Edmonton, (The Edmonton School Division)",
    "Bellerose Composite High School, St. Albert, (The St. Albert School Division)",
    "Bellerose Continuing Education, St. Albert, (The St. Albert School Division)",
    "Belmead School, Edmonton, (The Edmonton School Division)",
    "Belmont School, Edmonton, (The Edmonton School Division)",
    "Belvedere Parkway School, Calgary, (The Calgary School Division)",
    "Belvedere School, Edmonton, (The Edmonton School Division)",
    "Ben Calf Robe - St. Clare, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Bentley Colony School, Lacombe County, (The Wolf Creek School Division)",
    "Bentley School, Bentley, (The Wolf Creek School Division)",
    "Berry Creek Colony School, Hanna, (The Prairie Land School Division)",
    "Berry Creek Community School, Cessford, (The Prairie Land School Division)",
    "Bert Church High School, Airdrie, (The Rocky View School Division)",
    "Bertha Kennedy Catholic Community School, St. Albert, (The Greater St. Albert Roman Catholic Separate School Division)",
    "Bessie Nichols School, Edmonton, (The Edmonton School Division)",
    "Bev Facey Community High School, Sherwood Park, (The Elk Island School Division)",
    "Beyond Walls Outreach School, Medicine Hat, (The Prairie Rose School Division)",
    "Bezanson School, Bezanson, (The Peace Wapiti School Division)",
    "Big Bend Colony School, Cardston, (The Westwind School Division)",
    "Big Plans for Little Kids, Calgary, (Big Plans for Little Kids Ltd.)",
    "Big Rock School, Okotoks, (The Foothills School Division)",
    "Big Valley School, Big Valley, (The Clearview School Division)",
    "Bigstone Community School, Wabasca, (Bigstone Education Authority Society)",
    "Bill Woodward School, Anzac, (The Northland School Division)",
    "Birch Hills Hutterite School, Wanham, (The Peace Wapiti School Division)",
    "Birch Meadows Colony School, Eaglesham, (The Peace Wapiti School Division)",
    "Bishop Carroll High School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Bishop David Motiuk School, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Bishop Grandin High School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Bishop Greschuk, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Bishop Kidd School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Bishop Lloyd, Lloydminster, (Lloydminster Public School Division)",
    "Bishop McNally High School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Bishop O'Byrne High School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Bishop Pinkham School, Calgary, (The Calgary School Division)",
    "Bishop Routhier School, High Prairie, (The Northland School Division)",
    "Bishop Savaryn, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Bisset School, Edmonton, (The Edmonton School Division)",
    "Black Gold Home-Based School, Beaumont, (The Black Gold School Division )",
    "Black Gold Outreach - Beaumont, Beaumont, (The Black Gold School Division )",
    "Black Gold Outreach School, Leduc, (The Black Gold School Division )",
    "Blackie School, Blackie, (The Foothills School Division)",
    "Blessed Marie-Rose, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Blessed Sacrament Outreach School, Wainwright, (The East Central Alberta Catholic Separate School Division)",
    "Blessed Sacrament School, Wainwright, (The East Central Alberta Catholic Separate School Division)",
    "Blood Tribe Youth Ranch Alternate High School, Stand Off, (Kainai Board of Education)",
    "Blue Hills Community School, Buffalo Head Prairie, (The Fort Vermilion School Division)",
    "Blue Ridge Colony School, Cardston, (The Westwind School Division)",
    "Blue Sky Colony School, Hanna, (The Prairie Land School Division)",
    "Blueberry School, Stony Plain, (The Parkland School Division)",
    "Bluegrass Colony School, Taber, (The Horizon School Division)",
    "Bluffton School, Bluffton, (The Wolf Creek School Division)",
    "Blumenort Mennonite School, La Crete, (Wilson Prairie Mennonite School Society)",
    "Bob Edwards School, Calgary, (The Calgary School Division)",
    "Bon Accord Community School, Bon Accord, (The Sturgeon School Division )",
    "Bonanza School, Bonanza, (The Peace Wapiti School Division)",
    "Bonaventure Gate Writing Centre, Edmonton, (Other Schools)",
    "Bonavista Children's Centre, Calgary, (Montessori Education Preschool & ECS Institute of Alberta)",
    "Bonnyville Centralized High School, Bonnyville, (The Northern Lights School Division)",
    "Bonnyville Outreach, Bonnyville, (The Northern Lights School Division)",
    "Botha School, Botha, (The Clearview School Division)",
    "Bow City Colony School, Brooks, (The Grasslands School Division)",
    "Bow Island Elementary School, Bow Island, (The Prairie Rose School Division)",
    "Bow Valley College - Airdrie Campus, Calgary, (Bow Valley College)",
    "Bow Valley College - Correctional Centre, Calgary, (Bow Valley College)",
    "Bow Valley College - High School Extension, Calgary, (Bow Valley College)",
    "Bow Valley College - Main Campus, Calgary, (Bow Valley College)",
    "Bow Valley College (Outreach), Calgary, (Bow Valley College)",
    "Bow Valley High School, Cochrane, (The Rocky View School Division)",
    "Bowcroft School, Calgary, (The Calgary School Division)",
    "Bowden (Grandview) School, Bowden, (The Chinook's Edge School Division)",
    "Bowness High School, Calgary, (The Calgary School Division)",
    "Boyle School, Boyle, (The Aspen View School Division)",
    "Boyle Street Education Centre, Edmonton, (Boyle Street Education Centre)",
    "Braemar School, Edmonton, (The Edmonton School Division)",
    "Braeside School, Calgary, (The Calgary School Division)",
    "Bragg Creek Community Kindergarten, Bragg Creek, (Bragg Creek Education Services Association)",
    "Brander Gardens School, Edmonton, (The Edmonton School Division)",
    "Brant Christian School, Brant, (The Palliser School Division)",
    "Brant Colony School, Lethbridge, (The Palliser School Division)",
    "Branton School, Calgary, (The Calgary School Division)",
    "Breaking Point Colony School, Manning, (The Peace River School Division)",
    "Brentwood School, Sherwood Park, (The Elk Island School Division)",
    "Brentwood School, Calgary, (The Calgary School Division)",
    "Breton Elementary School, Breton, (The Wild Rose School Division )",
    "Breton High School, Breton, (The Wild Rose School Division )",
    "Briar Hill School, Calgary, (The Calgary School Division)",
    "Bridge Network, Grande Prairie, (The Grande Prairie School Division)",
    "Bridlewood School, Calgary, (The Calgary School Division)",
    "Bright Bank Academy, Stony Plain, (The Parkland School Division)",
    "Brighter Futures , , (Edson Friendship Centre)",
    "Brightview School, Edmonton, (The Edmonton School Division)",
    "Britannia Children's Center, Calgary, (Montessori Education Preschool & ECS Institute of Alberta)",
    "Britannia School, Edmonton, (The Edmonton School Division)",
    "Britestone Hutterite Colony, Strathmore, (The Golden Hills School Division)",
    "Brooks Composite High School, Brooks, (The Grasslands School Division)",
    "Brooks Early Learning Academy, Brooks, (Brooks Community Enrichment Foundation)",
    "Brooks Junior High School, Brooks, (The Grasslands School Division)",
    "Brookside School, Edmonton, (The Edmonton School Division)",
    "Brookwood School, Spruce Grove, (The Parkland School Division)",
    "Brownfield Community School, Brownfield, (The Clearview School Division)",
    "Bruderheim Community School, Bruderheim, (The Elk Island School Division)",
    "Buchanan School, Calgary, (The Calgary School Division)",
    "Buck Mountain Central School, Buck Lake, (The Wetaskiwin School Division)",
    "Buck Mountain Outreach Program, Buck Lake, (The Wetaskiwin School Division)",
    "Buffalo Creek Learning Center, Innisfail, (The Government of Canada as represented by Correctional Service Canada)",
    "Buffalo Head Prairie School, Buffalo Head Prairie, (The Fort Vermilion School Division)",
    "Buffalo Rubbing Stone School, Calgary, (The Calgary School Division)",
    "Burdett School, Burdett, (The Prairie Rose School Division)",
    "Burman University, Lacombe, (Burman University GED Writing Centre)",
    "Busby School, Busby, (The Pembina Hills School Division)",
    "Byemoor Colony School, Byemoor, (The Clearview School Division)",
    "Byemoor School, Byemoor, (The Clearview School Division)",
    "C Ian McLaren School, Black Diamond, (The Foothills School Division)",
    "C J Schurter Elementary School, Slave Lake, (The High Prairie School Division)",
    "C W Sears Elementary School, Tofield, (The Battle River School Division )",
    "C. P. Blakely Elementary School, Sylvan Lake, (The Chinook's Edge School Division)",
    "C.W. Perry School, Airdrie, (The Rocky View School Division)",
    "Cadotte Lake School, Cadotte Lake, (Kee Tas Kee Now Tribal Council Education Authority)",
    "Caernarvon School, Edmonton, (The Edmonton School Division)",
    "CAFA Playschool Family and Early Development Program, Edmonton, (Canadian Arab Friendship Association of Edmonton)",
    "Calder School, Edmonton, (The Edmonton School Division)",
    "Caledonia Park School, Leduc, (The Black Gold School Division )",
    "Calgary Academy, Calgary, (Calgary Academy Society)",
    "Calgary Academy Collegiate, Calgary, (Calgary Academy Society)",
    "Calgary Arts Academy, Calgary, (Calgary Arts Academy Society)",
    "Calgary Changemaker School , , (Calgary Changemakers in Education Society)",
    "Calgary Chinese Alliance School, Calgary, (Calgary Chinese Alliance School Society)",
    "Calgary Chinese Private School, Calgary, (The Calgary Chinese Public School Society)",
    "Calgary Christian High School, Calgary, (The Palliser School Division)",
    "Calgary Christian School, Calgary, (The Palliser School Division)",
    "Calgary Early Learning Centre, Calgary, (Calgary Early Learning Centre Association)",
    "Calgary French & International School, Calgary, (Calgary French & International School Society)",
    "Calgary German Language School Society, Calgary, (Calgary German Language School Society)",
    "Calgary Girls Charter School, Calgary, (Calgary Girls' School Society)",
    "Calgary Home Education, Calgary, (The Calgary School Division)",
    "Calgary International Academy, , (Calgary International Academy Ltd)",
    "Calgary International Academy ECS, , (Calgary International Academy Ltd)",
    "Calgary Islamic School, Akram Jomaa Campus, Calgary, (The Palliser School Division)",
    "Calgary Islamic School, Omar Bin Al-Khattab Campus, Calgary, (The Palliser School Division)",
    "Calgary Jewish Academy, Calgary, (The Calgary Jewish Academy)",
    "Calgary Quest School, Calgary, (Calgary Quest Children's Society)",
    "Calgary Waldorf School, Calgary, (Calgary Waldorf School Society)",
    "Calgary Writing Centre (Argyll Home School), Calgary, (The Edmonton School Division)",
    "Calling Lake School, Calling Lake, (The Northland School Division)",
    "Callingwood Elementary School, Edmonton, (The Edmonton School Division)",
    "Calmar Elementary School, Calmar, (The Black Gold School Division )",
    "Calmar Secondary School, Calmar, (The Black Gold School Division )",
    "Calvin Christian School, Coalhurst, (Calvin Christian School Society of the Netherlands Reformed Congregations)",
    "Cambrian Heights School, Calgary, (The Calgary School Division)",
    "Cameron Crossing School, Okotoks, (The Foothills School Division)",
    "Cameron Farms School, Taber, (The Horizon School Division)",
    "Camilla School, Sturgeon County, (The Sturgeon School Division )",
    "Camrose Colony School, Camrose, (The Battle River School Division )",
    "Camrose Composite High School, Camrose, (The Battle River School Division )",
    "Camrose Outreach School, Camrose, (The Battle River School Division )",
    "Canadian Collegiate Institute, Edmonton, (Canadian Collegiate Institute)",
    "Canadian Montessori School, Calgary, (The Canadian Montessori School Ltd.)",
    "Canadian Montessori School North, Calgary, (The Canadian Montessori School Ltd.)",
    "Canadian Montessori School West, Calgary, (The Canadian Montessori School Ltd.)",
    "Canadian Rockies Education Outreach, Canmore, (The Canadian Rockies School Division)",
    "Canmore Collegiate, Canmore, (The Canadian Rockies School Division)",
    "Canyon Elementary School, Pincher Creek, (The Livingstone Range School Division)",
    "Canyon Meadows School, Calgary, (The Calgary School Division)",
    "Capitol Hill School, Calgary, (The Calgary School Division)",
    "Cappy Smart School, Calgary, (The Calgary School Division)",
    "Captain John Palliser School, Calgary, (The Calgary School Division)",
    "Captain Nichola Goddard School, Calgary, (The Calgary School Division)",
    "Carbon School, Carbon, (The Golden Hills School Division)",
    "Cardinal Collins High School Academic Centre, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Cardinal Leger, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Cardston Elementary School, Cardston, (The Westwind School Division)",
    "Cardston High School, Cardston, (The Westwind School Division)",
    "Cardston Junior High School, Cardston, (The Westwind School Division)",
    "CARE (Centre for Adolescent Responsibility Education) Outreach, Lethbridge, (The Holy Spirit Roman Catholic Separate School Division)",
    "Carmangay Outreach, Carmangay, (The Palliser School Division)",
    "Caroline School, Caroline, (The Wild Rose School Division )",
    "Carousel Children's Centre, Calgary, (Montessori Education Preschool & ECS Institute of Alberta)",
    "Carseland School, Carseland, (The Golden Hills School Division)",
    "Carstairs Elementary School, Carstairs, (The Chinook's Edge School Division)",
    "CASA Preschool Day Program, Edmonton, (CASA Child, Adolescent and Family Mental Health)",
    "Caslan School, Caslan, (The Northern Lights School Division)",
    "Castle School, Fort Saskatchewan, (The Elk Island School Division)",
    "Castor Outreach School, Castor, (The Clearview School Division)",
    "Catherine Nichols Gunn School, Calgary, (The Calgary School Division)",
    "Catholic Central High School, Lethbridge, (The Holy Spirit Roman Catholic Separate School Division)",
    "Cause & Effect Early Intervention Services, Calgary, (Cause and Effect Foundation)",
    "Cayley Colony School, Cayley, (The Foothills School Division)",
    "Cayley School, Cayley, (The Foothills School Division)",
    "CBe - Learn, Calgary, (The Calgary School Division)",
    "Cecil Swanson School, Calgary, (The Calgary School Division)",
    "Cedarbrae School, Calgary, (The Calgary School Division)",
    "Centennial High School, Calgary, (The Calgary School Division)",
    "Centennial School, Wetaskiwin, (The Wetaskiwin School Division)",
    "Centennial School, Edmonton, (The Edmonton School Division)",
    "Central Alberta Christian High School, Lacombe, (Central Alberta Christian High School Society)",
    "Central High School, Medicine Hat, (The Medicine Hat School Division)",
    "Central High School Sedgewick, Sedgewick, (The Battle River School Division )",
    "Central Memorial High School, Calgary, (The Calgary School Division)",
    "Central Middle School, Red Deer, (The Red Deer School Division )",
    "Centre for Academic and Personal Excellence, Medicine Hat, (CAPE - Centre for Academic and Personal Excellence Institute)",
    "Centre for Alternative and Virtual Education, Athabasca, (The Aspen View School Division)",
    "Centre francophone d'education a distance, Edmonton, (The East Central Francophone Education Region)",
    "Centre High, Edmonton, (The Edmonton School Division)",
    "Chamberlain School, Grassy Lake, (The Horizon School Division)",
    "Champion School, Champion, (The Palliser School Division)",
    "Champion School ECS, Champion, (Champion Kindergarten Association)",
    "Chaparral School, Calgary, (The Calgary School Division)",
    "Charles Spencer High School, Grande Prairie, (The Grande Prairie School Division)",
    "Charlie Killam School, Camrose, (The Battle River School Division )",
    "Cherry Coulee Christian Academy, Bow Island, (40-Mile Christian Education Society)",
    "Chester Ronning School, Camrose, (The Battle River School Division )",
    "Chestermere High School, Calgary, (The Rocky View School Division)",
    "Chestermere Lake Middle School, Chestermere, (The Rocky View School Division)",
    "Chestermere Learning Centre, Calgary, (Marlborough Day Nursery ECS Limited)",
    "Chestermere Learning Connection, Chestermere, (The Rocky View School Division)",
    "Chief Jacob Bearspaw School, Eden Valley, (Stoney Tribal Administration)",
    "Chief Justice Milvain School, Calgary, (The Calgary School Division)",
    "Chief Napeweaw Comprehensive School, Frog Lake, (Frog Lake Band)",
    "Chief Old Sun Elementary School, Siksika, (Siksika Board of Education)",
    "Chief Tallcree School North, Fort Vermilion, (Tallcree Band)",
    "Chief Tallcree School South, Fort Vermilion, (Tallcree Band)",
    "Chiila School, Tsuu T'ina, (Tsuut'ina Nation Education Department)",
    "Children of St. Martha School, Lethbridge, (The Holy Spirit Roman Catholic Separate School Division)",
    "Children's Autism Services of Edmonton, Edmonton, (Children's Autism Services of Edmonton)",
    "Children's Commons Ecological Society Nature Kindergarten, Calgary, (Children's Commons Ecological Society)",
    "Children's House Day Care, Lethbridge, (The Children's House Child Care Society)",
    "Children's Village School, Calgary, (The Calgary School Division)",
    "Chin Lakes Colony School, Lethbridge, (The Palliser School Division)",
    "Chiniki Community College, Morley, (Chiniki Community College)",
    "Chinook High School, Lethbridge, (The Lethbridge School Division)",
    "Chinook Learning Services, Calgary, (The Calgary School Division)",
    "Chinook Park School, Calgary, (The Calgary School Division)",
    "Chinook Winds Adventist Academy, Calgary, (Alberta Conference of 7th Day Adventist Church)",
    "Chinook's Edge Summer School, Innisfail, (The Chinook's Edge School Division)",
    "Chipewyan Lake School, Wabasca, (The Northland School Division)",
    "Chipewyan Prairie Dene High School, Chard, (Chipewyan Prairie First Nation)",
    "Chris Akkerman School, Calgary, (The Calgary School Division)",
    "Christ The King Academy, Brooks, (The Christ the Redeemer Catholic Separate School Division)",
    "Christ the King Catholic School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Christ the King School, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Christ the King School, Leduc, (The St. Thomas Aquinas Roman Catholic Separate School Division )",
    "Christ-King Catholic School, Stettler, (The East Central Alberta Catholic Separate School Division)",
    "Christina Gordon Public School, Fort McMurray, (The Fort McMurray School Division)",
    "Christine Meikle School, Calgary, (The Calgary School Division)",
    "Citadel Park School, Calgary, (The Calgary School Division)",
    "Clairmont Community School, Clairmont, (The Peace Wapiti School Division)",
    "Clandonald Public School ECS, Clandonald, (Clandonald Early Childhood Services Association)",
    "Clandonald School, Clandonald, (The Buffalo Trail School Division)",
    "Clara Tyner School, Edmonton, (The Edmonton School Division)",
    "Clarence Jaycox School, Red Earth Creek, (Kee Tas Kee Now Tribal Council Education Authority)",
    "Clarence Sansom School, Calgary, (The Calgary School Division)",
    "Clareview Head Start, Edmonton, (Edmonton City Centre Church Corporation)",
    "Clear Lake Colony School, Fort Macleod, (The Livingstone Range School Division)",
    "Clear Vista School, Wetaskiwin, (The Wetaskiwin School Division)",
    "Clear Water Academy, Calgary, (Clear Water Academy Foundation)",
    "Cleardale Colony School, Cleardale, (The Peace River School Division)",
    "Cleardale Mennonite School, Cleardale, (Cleardale Mennonite School Society)",
    "Clearview Colony School, Bassano, (The Grasslands School Division)",
    "Clive School, Clive, (The Wolf Creek School Division)",
    "Clover Bar Junior High School, Sherwood Park, (The Elk Island School Division)",
    "Cloverleaf Colony, Delia, (The Prairie Land School Division)",
    "CLS James Fowler Writing Centre, Calgary, (The Calgary School Division)",
    "CLS Lord Beaverbrook Writing Centre, Calgary, (The Calgary School Division)",
    "CLS Lord Shaughnessy Writing Centre, Calgary, (The Calgary School Division)",
    "Coalbanks Elementary School, Lethbridge, (The Lethbridge School Division)",
    "Coaldale Christian School, Coaldale, (Coaldale Canadian Reformed School Society)",
    "Coalhurst Elementary School, Coalhurst, (The Palliser School Division)",
    "Coalhurst High School, Coalhurst, (The Palliser School Division)",
    "Cochrane Christian Academy, Cochrane, (The Rocky View School Division)",
    "Cochrane High School, Cochrane, (The Rocky View School Division)",
    "Cochrane Learning Connection, Cochrane, (The Rocky View School Division)",
    "Cochrane Valley Montessori School, Cochrane, (1620215 Alberta Ltd.)",
    "Cochrane Valley Montessori School, Cochrane, (1620215 Alberta Ltd.)",
    "Codesa Hutterite Colony School, Eaglesham, (The Peace Wapiti School Division)",
    "Cold Lake Elementary School, Cold Lake, (The Northern Lights School Division)",
    "Cold Lake High School, Cold Lake, (The Northern Lights School Division)",
    "Cold Lake Middle School, Cold Lake, (The Northern Lights School Division)",
    "Cold Lake Outreach, Cold Lake, (The Northern Lights School Division)",
    "College Heights Christian School, Lacombe, (Alberta Conference of 7th Day Adventist Church)",
    "College Park School, Lloydminster, (Lloydminster Public School Division)",
    "Collingwood School, Calgary, (The Calgary School Division)",
    "Colonel Irvine School, Calgary, (The Calgary School Division)",
    "Colonel J. Fred Scott School, Calgary, (The Calgary School Division)",
    "Colonel Macleod School, Calgary, (The Calgary School Division)",
    "Colonel Sanders School, Calgary, (The Calgary School Division)",
    "Colonel Walker School, Calgary, (The Calgary School Division)",
    "Columbia College, Calgary, (Columbia College Corp.)",
    "Columbus Academy, Edmonton, (Unlimited Potential Community Services Society)",
    "Commonwealth International Academy, Edmonton, (Golden Pinnacle Academy LTD)",
    "Community Association for Lasting Success, Vegreville, (The Community Association for Lasting Success)",
    "Community Early Learning Program, Medicine Hat, (The Medicine Hat School Division)",
    "Community Preschool Education, Edmonton, (Community Options-A Society for Children and Families)",
    "Condor Elementary School, Condor, (The Wild Rose School Division )",
    "Conklin Community School, Conklin, (The Northland School Division)",
    "Connaught School, Medicine Hat, (The Medicine Hat School Division)",
    "Connaught School, Calgary, (The Calgary School Division)",
    "Connect Charter School, Calgary, (Connect Charter School Society)",
    "Connect Society's Early Childhood Services, Edmonton, (Connect Society-Deafness, Education, Advocacy & Family Services)",
    "Connect Society's Early Childhood Services - Calgary, Calgary, (Connect Society-Deafness, Education, Advocacy & Family Services)",
    "Connections for Learning, Stony Plain, (The Parkland School Division)",
    "Consort School, Consort, (The Prairie Land School Division)",
    "Constable Daniel Woodall School, Edmonton, (The Edmonton School Division)",
    "Coopers Crossing School, Airdrie, (The Rocky View School Division)",
    "Copperfield Farms Colony School, Taber, (The Horizon School Division)",
    "Copperfield School, Calgary, (The Calgary School Division)",
    "Copperhaven School, Spruce Grove, (The Parkland School Division)",
    "Coralwood Adventist Academy, Edmonton, (Alberta Conference of 7th Day Adventist Church)",
    "Corbett Hall Early Education Program, Edmonton, (Department of Communication Sciences and Disorders)",
    "Cornerstone Christian Academy, Kingman, (The Cornerstone Christian Academy of Camrose)",
    "Coronation Outreach School, Coronation, (The Clearview School Division)",
    "Coronation School, Coronation, (The Clearview School Division)",
    "Coronation School, Edmonton, (The Edmonton School Division)",
    "Corpus Christi School, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Corpus Christi School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Country Hills School, Strathmore, (The Golden Hills School Division)",
    "Countryside Christian School, Edberg, (Countryside Christian School)",
    "County Central High School, Vulcan, (The Palliser School Division)",
    "Covenant Canadian Reformed School, County of Barrhead, (Canadian Reformed School Society of Neerlandia)",
    "Covenant Christian School, Leduc, (The Black Gold School Division )",
    "Coventry Hills School, Calgary, (The Calgary School Division)",
    "Craigmyle Colony School, Craigmyle, (The Prairie Land School Division)",
    "Cranston School, Calgary, (The Calgary School Division)",
    "Crawford Plains School, Edmonton, (The Edmonton School Division)",
    "Crawling Valley School, Strathmore, (The Golden Hills School Division)",
    "Creekside Campus ECS, Calgary, (Marlborough Day Nursery ECS Limited)",
    "Creighton Colony School, Mannville, (The Buffalo Trail School Division)",
    "Cremona School, Cremona, (The Chinook's Edge School Division)",
    "Crescent Heights High School, Medicine Hat, (The Medicine Hat School Division)",
    "Crescent Heights High School, Calgary, (The Calgary School Division)",
    "Crescent Valley School, Hinton, (The Grande Yellowhead School Division)",
    "Crestomere School, Lacombe, (The Wolf Creek School Division)",
    "Crestwood School, Medicine Hat, (The Medicine Hat School Division)",
    "Crestwood School, Edmonton, (The Edmonton School Division)",
    "Crooked Creek Colony School, Crooked Creek, (The Peace Wapiti School Division)",
    "Crossfield Elementary School, Crossfield, (The Rocky View School Division)",
    "Crossing Park School, Calgary, (The Calgary School Division)",
    "Crossroads Outreach School, Caslan, (The Northern Lights School Division)",
    "Crowfoot School, Siksika, (Siksika Board of Education)",
    "Crowsnest Consolidated High School, Coleman, (The Livingstone Range School Division)",
    "Crowther Memorial Junior High School, Strathmore, (The Golden Hills School Division)",
    "Crystal Park School, Grande Prairie, (The Grande Prairie School Division)",
    "Crystal Spring Colony School, Cardston, (The Westwind School Division)",
    "CUPS One World Child Development Centre, Calgary, (Calgary Urban Project Society)",
    "D S MacKenzie School, Edmonton, (The Edmonton School Division)",
    "D. A. Ferguson Middle School, Taber, (The Horizon School Division)",
    "Dalhousie Community Kindergarten, Calgary, (Dalhousie Community Kindergarten Society)",
    "Dalhousie School, Calgary, (The Calgary School Division)",
    "Daly Creek Colony School, Fort Macleod, (The Livingstone Range School Division)",
    "Daly Grove School, Edmonton, (The Edmonton School Division)",
    "Dan Knott School, Edmonton, (The Edmonton School Division)",
    "Dante Alighieri Italian School, Edmonton, (The Dante Alighieri Society)",
    "Darwell School, Darwell, (The Northern Gateway School Division)",
    "Dave McNeilly Public School, Fort McMurray, (The Fort McMurray School Division)",
    "David Thomas King School, Edmonton, (The Edmonton School Division)",
    "David Thompson School, Condor, (The Wild Rose School Division )",
    "David Thompson School, Calgary, (The Calgary School Division)",
    "Davidson Creek Elementary School, Sherwood Park, (The Elk Island School Division)",
    "Daysland School, Daysland, (The Battle River School Division )",
    "Deep Creek Colony School, Athabasca, (The Aspen View School Division)",
    "Deer Run School, Calgary, (The Calgary School Division)",
    "Deerfield Colony School, Cardston, (The Westwind School Division)",
    "Delburne Centralized School, Delburne, (The Chinook's Edge School Division)",
    "Delco Colony School, Taber, (The Horizon School Division)",
    "Delia School, Delia, (The Prairie Land School Division)",
    "Delnorte School, Innisfree, (The Buffalo Trail School Division)",
    "Delta West Academy, Calgary, (Delta West Academy Society)",
    "Delton School, Edmonton, (The Edmonton School Division)",
    "Delwood School, Edmonton, (The Edmonton School Division)",
    "Dene Tha' Community School, Chateh, (Dene Tha' Band)",
    "Departmental Center, Edmonton, (Other Schools)",
    "Departmental Evaluation, Edmonton, (Other Schools)",
    "Derek Taylor School, Grande Prairie, (The Grande Prairie School Division)",
    "Destiny Christian School Society, Red Deer County, (Destiny Christian School Society)",
    "Devon Christian School, Devon, (Devon Christian School Society)",
    "Dewberry School, Dewberry, (The Buffalo Trail School Division)",
    "Dewberry School ECS, Dewberry, (Dewberry Early Childhood Parents Society)",
    "Dickinsfield School, Edmonton, (The Edmonton School Division)",
    "Didsbury Career High School, Didsbury, (The Chinook's Edge School Division)",
    "Didsbury High School, Didsbury, (The Chinook's Edge School Division)",
    "Direwood Treatment Centre, Red Deer, (The Red Deer School Division )",
    "Discovering Choices, Calgary, (The Calgary School Division)",
    "Discovering Choices 2, Calgary, (The Calgary School Division)",
    "Distance Learning High School, Slave Lake, (Northern Lakes College)",
    "Divine Mercy Catholic Elementary School, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Divine Mercy School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Dixonville School, Dixonville, (The Peace River School Division)",
    "Doe River Colony School, Bonanza, (The Peace Wapiti School Division)",
    "Don Bosco School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Don Campbell Elementary School, Red Deer, (The Red Deer School Division )",
    "Donald R. Getty School, Edmonton, (The Edmonton School Division)",
    "Donalda Colony School, Donalda, (The Clearview School Division)",
    "Donalda School, Donalda, (The Clearview School Division)",
    "Donnan School, Edmonton, (The Edmonton School Division)",
    "Dorothy Dalgliesh School, Picture Butte, (The Palliser School Division)",
    "Douglas Harkness School, Calgary, (The Calgary School Division)",
    "Douglasdale School, Calgary, (The Calgary School Division)",
    "Dovercourt School, Edmonton, (The Edmonton School Division)",
    "Dr Elliott Community School, Linden, (The Golden Hills School Division)",
    "Dr Karl A Clark Elementary, Fort McMurray, (The Fort McMurray School Division)",
    "Dr Mary Jackson School, Keg River, (The Northland School Division)",
    "Dr Morris Gibson School, Okotoks, (The Foothills School Division)",
    "Dr. Anne Anderson School, , (The Edmonton School Division)",
    "Dr. Donald Massey School, Edmonton, (The Edmonton School Division)",
    "Dr. E. P. Scarlett High School, Calgary, (The Calgary School Division)",
    "Dr. E. W. Coffin School, Calgary, (The Calgary School Division)",
    "Dr. Folkins Community School, Chauvin, (The Buffalo Trail School Division)",
    "Dr. Freda Miller School, , (The Calgary School Division)",
    "Dr. George Stanley School, Calgary, (The Calgary School Division)",
    "Dr. Gerald B. Probe Elementary School, Lethbridge, (The Lethbridge School Division)",
    "Dr. Gladys McKelvie Egbert, Calgary, (The Calgary School Division)",
    "Dr. Gordon Higgins School, Calgary, (The Calgary School Division)",
    "Dr. Gordon Townsend School, Calgary, (The Calgary School Division)",
    "Dr. Hamman School, Taber, (The Horizon School Division)",
    "Dr. J. K. Mulloy School, Calgary, (The Calgary School Division)",
    "Dr. Ken Sauer School, Medicine Hat, (The Medicine Hat School Division)",
    "Dr. Lila Fahlman School, Edmonton, (The Edmonton School Division)",
    "Dr. Margaret-Ann Armour School, Edmonton, (The Edmonton School Division)",
    "Dr. Martha Cohen School, Calgary, (The Calgary School Division)",
    "Dr. Oakley School, Calgary, (The Calgary School Division)",
    "Dr. Roberta Bondar School, Calgary, (The Calgary School Division)",
    "Dr. Roy Wilson Learning Centre, Medicine Hat, (The Medicine Hat School Division)",
    "Drayton Christian School, Drayton Valley, (The Wild Rose School Division )",
    "Drayton Valley Community Outreach School, Drayton Valley, (The Wild Rose School Division )",
    "Drumheller Outreach, Drumheller, (The Golden Hills School Division)",
    "Drumheller Valley Secondary School, Drumheller, (The Golden Hills School Division)",
    "Duchess Bethel Mennonite School, Duchess, (Duchess Bethel Mennonite School)",
    "Duchess School, Duchess, (The Grasslands School Division)",
    "Duclos School, Bonnyville, (The Northern Lights School Division)",
    "Duffield School, Duffield, (The Parkland School Division)",
    "Duggan School, Edmonton, (The Edmonton School Division)",
    "Dunluce School, Edmonton, (The Edmonton School Division)",
    "Dunstable School, Busby, (The Pembina Hills School Division)",
    "E E Oliver Elementary School, Fairview, (The Peace River School Division)",
    "E G Wahlstrom School, Slave Lake, (The High Prairie School Division)",
    "E W Pratt High School, High Prairie, (The High Prairie School Division)",
    "E. H. Walter School, Paradise Valley, (The Buffalo Trail School Division)",
    "E2 Academy, Edmonton, (E2 Society for Twice-Exceptional Learners)",
    "Eagle Butte High School, Dunmore, (The Prairie Rose School Division)",
    "Eaglesham School, Eaglesham, (The Peace Wapiti School Division)",
    "Earl Buxton School, Edmonton, (The Edmonton School Division)",
    "Earl Grey School, Calgary, (The Calgary School Division)",
    "Early Discoveries Childhood Services, Calgary, (Early Discoveries Childhood Services Ltd.)",
    "Early Education & Family Wellness Centre, Wetaskiwin, (The Wetaskiwin School Division)",
    "East Cardston Colony School, Cardston, (The Westwind School Division)",
    "East Elementary School, Leduc, (The Black Gold School Division )",
    "East Lake School, Chestermere, (The Rocky View School Division)",
    "East Raymond School, Cardston, (The Westwind School Division)",
    "Eastbrook Elementary School, Brooks, (The Grasslands School Division)",
    "Eastglen School, Edmonton, (The Edmonton School Division)",
    "Eastside Christian Academy, Calgary, (Eastside City Church and Missionary Society)",
    "Eastview Middle School, Red Deer, (The Red Deer School Division )",
    "Echo Valley Christian School, Bluffton, (Echo Valley Church of God in Christ Mennonite)",
    "ECI Learning Academy, Chestermere, (Kin-Dir Education Foundation)",
    "Eckville Elementary School, Eckville, (The Wolf Creek School Division)",
    "Eckville Junior Senior High School, Eckville, (The Wolf Creek School Division)",
    "Ecole A la D‚couverte, Edmonton, (The Greater North Central Francophone Education Region)",
    "Ecole Agnes Davidson School, Lethbridge, (The Lethbridge School Division)",
    "Ecole Airdrie Middle School, Airdrie, (The Rocky View School Division)",
    "Ecole Alexandre-Tach‚, St. Albert, (The Greater North Central Francophone Education Region)",
    "Ecole Ardrossan Elementary School, Ardrossan, (The Elk Island School Division)",
    "Ecole Barrie Wilson Elementary School, Red Deer, (The Red Deer School Division )",
    "Ecole Beau Meadow School, Beaumont, (The Black Gold School Division )",
    "Ecole Beausejour, Plamondon, (The East Central Francophone Education Region)",
    "Ecole Beausoleil, Okotoks, (The Southern Francophone Education Region)",
    "Ecole Bellevue School, Beaumont, (The Black Gold School Division )",
    "Ecole Boreal, Fort McMurray, (The Greater North Central Francophone Education Region)",
    "Ecole Brentwood Elementary School, Strathmore, (The Golden Hills School Division)",
    "Ecole Broxton Park School, Spruce Grove, (The Parkland School Division)",
    "Ecole Camille J Lerouge School, Red Deer, (The Red Deer Catholic Separate School Division)",
    "Ecole Campbelltown School, Sherwood Park, (The Elk Island School Division)",
    "Ecole Champs Vall‚e School, Beaumont, (The Black Gold School Division )",
    "Ecole Citadelle, Legal, (The Greater North Central Francophone Education Region)",
    "Ecole Claudette-et-Denis-Tardif, Sherwood Park, (The Greater North Central Francophone Education Region)",
    "Ecole Coloniale Estates School, Beaumont, (The Black Gold School Division )",
    "Ecole Corinthia Park School, Leduc, (The Black Gold School Division )",
    "Ecole Dansereau Meadows School, Beaumont, (The Black Gold School Division )",
    "Ecole de la Rose Sauvage, Calgary, (The Southern Francophone Education Region)",
    "Ecole de La Source, Calgary, (The Southern Francophone Education Region)",
    "Ecole Deer Meadow School, Olds, (The Chinook's Edge School Division)",
    "Ecole des Beaux-Lacs, Bonnyville, (The East Central Francophone Education Region)",
    "Ecole des Fondateurs, Camrose, (The Greater North Central Francophone Education Region)",
    "Ecole des Quatre-Vents, Peace River, (The Northwest Francophone Education Region)",
    "Ecole Desrochers, Jasper, (The Greater North Central Francophone Education Region)",
    "Ecole Dickinsfield School, Fort McMurray, (The Fort McMurray School Division)",
    "Ecole Dr Bernard Brosseau School, Bonnyville, (The Lakeland Roman Catholic Separate School Division)",
    "Ecole du Nouveau-Monde, Calgary, (The Southern Francophone Education Region)",
    "Ecole du Sommet, St. Paul, (The East Central Francophone Education Region)",
    "Ecole Edwards Elementary School, Airdrie, (The Rocky View School Division)",
    "Ecole Elementaire St. Paul Elementary School, St. Paul, (The St. Paul School Division)",
    "Ecole Father Jan Community School, St. Albert, (The Greater St. Albert Roman Catholic Separate School Division)",
    "Ecole Fox Run School, Sylvan Lake, (The Chinook's Edge School Division)",
    "Ecole H.J. Cody High School, Sylvan Lake, (The Chinook's Edge School Division)",
    "Ecole H‚ritage, Falher, (The Northwest Francophone Education Region)",
    "Ecole Innisfail Middle School, Innisfail, (The Chinook's Edge School Division)",
    "Ecole J E Lapointe School, Beaumont, (The Black Gold School Division )",
    "Ecole James S. McCormick School, Lacombe, (The Wolf Creek School Division)",
    "Ecole John Wilson Elementary School, Innisfail, (The Chinook's Edge School Division)",
    "Ecole Joseph-Moreau, Edmonton, (The Greater North Central Francophone Education Region)",
    "Ecole La Mission, St. Albert, (The Greater North Central Francophone Education Region)",
    "Ecole La Mosa‹que, Calgary, (The Southern Francophone Education Region)",
    "Ecole La Prairie, Red Deer, (The Greater North Central Francophone Education Region)",
    "Ecole La Verendrye, Lethbridge, (The Southern Francophone Education Region)",
    "Ecole Lacombe Junior High School, Lacombe, (The Wolf Creek School Division)",
    "Ecole Lacombe Upper Elementary School, Lacombe, (The Wolf Creek School Division)",
    "Ecole Le Ruisseau, Brooks, (The Southern Francophone Education Region)",
    "Ecole Leduc Estates School, Leduc, (The Black Gold School Division )",
    "Ecole Leduc Junior High School, Leduc, (The Black Gold School Division )",
    "Ecole Les Cypres, Medicine Hat, (The Southern Francophone Education Region)",
    "Ecole Mallaig Community School, Mallaig, (The St. Paul School Division)",
    "Ecole Marie Poburan, St. Albert, (The Greater St. Albert Roman Catholic Separate School Division)",
    "Ecole Maurice-Lavallee, Edmonton, (The Greater North Central Francophone Education Region)",
    "Ecole McTavish Public High School, Fort McMurray, (The Fort McMurray School Division)",
    "Ecole Meridian Heights School, Stony Plain, (The Parkland School Division)",
    "Ecole Micha‰lle-Jean, Edmonton, (The Greater North Central Francophone Education Region)",
    "Ecole Montrose School, Grande Prairie, (The Grande Prairie School Division)",
    "Ecole Mother d'Youville School, Beaumont, (The St. Thomas Aquinas Roman Catholic Separate School Division )",
    "Ecole Mountain View School, Hinton, (The Grande Yellowhead School Division)",
    "Ecole Nicholas Sheran School, Lethbridge, (The Lethbridge School Division)",
    "Ecole Notre Dame High, Bonnyville, (The Lakeland Roman Catholic Separate School Division)",
    "Ecole Notre Dame School, Leduc, (The St. Thomas Aquinas Roman Catholic Separate School Division )",
    "Ecole Notre-Dame, Edmonton, (The Greater North Central Francophone Education Region)",
    "Ecole Notre-Dame des Monts, Canmore, (The Southern Francophone Education Region)",
    "Ecole Notre-Dame des Vallees, Cochrane, (The Southern Francophone Education Region)",
    "Ecole Nouvelle Frontiere, Grande Prairie, (The Northwest Francophone Education Region)",
    "Ecole Olds Elementary School, Olds, (The Chinook's Edge School Division)",
    "Ecole Olds High School, Olds, (The Chinook's Edge School Division)",
    "Ecole Parc Elementaire, Fort Saskatchewan, (The Elk Island School Division)",
    "Ecole Pere Kenneth Kearns Catholic School, Sherwood Park, (The Elk Island Catholic Separate School Division)",
    "Ecole Pere-Lacombe, Edmonton, (The Greater North Central Francophone Education Region)",
    "Ecole Pine Grove School, Edson, (The Grande Yellowhead School Division)",
    "Ecole Plamondon School, Plamondon, (The Northern Lights School Division)",
    "Ecole Providence School, McLennan, (The Holy Family Catholic Separate School Division)",
    "Ecole Publique Gabrielle-Roy, Edmonton, (The Greater North Central Francophone Education Region)",
    "Ecole Queen Elizabeth School, Wetaskiwin, (The Wetaskiwin School Division)",
    "Ecole Regionale St. Paul Regional High School, St. Paul, (The St. Paul School Division)",
    "Ecole Rocky Elementary School, Rocky Mountain House, (The Wild Rose School Division )",
    "Ecole Saint-Christophe, Wainwright, (The Greater North Central Francophone Education Region)",
    "Ecole Saint-Vital, Beaumont, (The Greater North Central Francophone Education Region)",
    "Ecole Sainte-Catherine, Lac La Biche, (The East Central Francophone Education Region)",
    "Ecole Sans-FrontiŠres, Lloydminster, (The Greater North Central Francophone Education Region)",
    "Ecole Secondaire Beaumont Composite High School, Beaumont, (The Black Gold School Division )",
    "Ecole Secondaire Highwood High School, High River, (The Foothills School Division)",
    "Ecole Secondaire Lacombe Composite High School, Lacombe, (The Wolf Creek School Division)",
    "Ecole Secondaire Notre Dame High School, Red Deer, (The Red Deer Catholic Separate School Division)",
    "Ecole Secondaire Notre Dame Summer School, Red Deer, (The Red Deer Catholic Separate School Division)",
    "Ecole Secondaire Sainte Marguerite d'Youville, St. Albert, (The Greater St. Albert Roman Catholic Separate School Division)",
    "Ecole St. Gerard Catholic School, Grande Prairie, (The Grande Prairie Roman Catholic Separate School Division)",
    "Ecole St. John Paul II School, Medicine Hat, (The Medicine Hat Roman Catholic Separate School Division)",
    "Ecole St. Joseph School, Whitecourt, (The Living Waters Catholic Separate School Division)",
    "Ecole St. Mary School, Lethbridge, (The Holy Spirit Roman Catholic Separate School Division)",
    "Ecole St. Mary School, Whitecourt, (The Living Waters Catholic Separate School Division)",
    "Ecole St. Pius X School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Ecole Ste-Jeanne-d'Arc, Edmonton, (The Greater North Central Francophone Education Region)",
    "Ecole Steffie Woima Elementary School, Sylvan Lake, (The Chinook's Edge School Division)",
    "Ecole Terre des Jeunes, Calgary, (The Southern Francophone Education Region)",
    "Ecole Voyageur, Cold Lake, (The East Central Francophone Education Region)",
    "Edge School, Calgary, (The Edge School For Athletes Society)",
    "Edgemont School, Calgary, (The Calgary School Division)",
    "Edgerton Public School, Edgerton, (The Buffalo Trail School Division)",
    "Edison School, Okotoks, (Edison School Society)",
    "Edith Rogers School, Edmonton, (The Edmonton School Division)",
    "Edmonton Academy, Edmonton, (Edmonton Academy Society for Learning Disabled)",
    "Edmonton Bible Heritage Christian School, Edmonton, (Edmonton Bible Heritage Christian School & College Society)",
    "Edmonton Christian High School, Edmonton, (The Edmonton School Division)",
    "Edmonton Christian Northeast School, Edmonton, (The Edmonton School Division)",
    "Edmonton Christian West School, Edmonton, (The Edmonton School Division)",
    "Edmonton Islamic Academy, Edmonton, (Edmonton Islamic School Society)",
    "Edmonton Khalsa School, Edmonton, (Edmonton Khalsa School Educational Association)",
    "Edmonton Menorah Academy, Edmonton, (Universal Educational Institute of Canada)",
    "Edwin Parr Composite Community School, Athabasca, (The Aspen View School Division)",
    "Ehpewapahk Alternate School, Maskwacis, (Maskwacis Education Schools Commission)",
    "EIPS Centre for Educational Alternatives, Sherwood Park, (The Elk Island School Division)",
    "EIPS Home Education, Sherwood Park, (The Elk Island School Division)",
    "Ekota School, Edmonton, (The Edmonton School Division)",
    "Elbow Park School, Calgary, (The Calgary School Division)",
    "Elbow Valley Elementary School, Calgary, (The Rocky View School Division)",
    "Elboya School, Calgary, (The Calgary School Division)",
    "Eldorado Elementary School, Drayton Valley, (The Wild Rose School Division )",
    "Eleanor Hall School, Clyde, (The Pembina Hills School Division)",
    "Elizabeth Barrett Elementary School, Cochrane, (The Rocky View School Division)",
    "Elizabeth Community School, Cold Lake, (The Northland School Division)",
    "Elizabeth Finch School, Edmonton, (The Edmonton School Division)",
    "Elizabeth Rummel School, Canmore, (The Canadian Rockies School Division)",
    "Elk Island Youth Ranch Learning Centre, Sherwood Park, (The Elk Island School Division)",
    "Elk Point Elementary School, Elk Point, (The St. Paul School Division)",
    "Elk Point Outreach, Elk Point, (The St. Paul School Division)",
    "Ellerslie Campus, Edmonton, (The Edmonton School Division)",
    "Elm Street School, Medicine Hat, (The Medicine Hat School Division)",
    "Elmer Elson Elementary School, Mayerthorpe, (The Northern Gateway School Division)",
    "Elmer S Gish School, St. Albert, (The St. Albert School Division)",
    "Elmspring Colony School, Taber, (The Horizon School Division)",
    "Elmwood School, Edmonton, (The Edmonton School Division)",
    "Elmworth School, Elmworth, (The Peace Wapiti School Division)",
    "Elnora School, Elnora, (The Chinook's Edge School Division)",
    "Elsie Yanik Catholic School, Fort McMurray, (The Fort McMurray Roman Catholic Separate School Division)",
    "Elves Adult & Youth Centre, Edmonton, (Elves Special Needs Society)",
    "Elves Child Development Centre, Edmonton, (Elves Special Needs Society)",
    "Elves Scouts Canada Campus, Edmonton, (Elves Special Needs Society)",
    "Emily Follensbee School, Calgary, (The Calgary School Division)",
    "Enchant Colony School, Taber, (The Horizon School Division)",
    "Enchant School, Enchant, (The Horizon School Division)",
    "Engage Teach Achieve Society, , (Engage Teach Achieve Society)",
    "Entwistle School, Entwistle, (The Parkland School Division)",
    "Equinox Group Home, Sylvan Lake, (The Chinook's Edge School Division)",
    "Eric Harvie School, Calgary, (The Calgary School Division)",
    "Erin Woods School, Calgary, (The Calgary School Division)",
    "Erle Rivers High School, Milk River, (The Horizon School Division)",
    "Ermineskin Elementary School, Maskwacis, (Maskwacis Education Schools Commission)",
    "Ermineskin Junior Senior High School, Maskwacis, (Maskwacis Education Schools Commission)",
    "Ermineskin Kindergarten, Maskwacis, (Maskwacis Education Schools Commission)",
    "Ermineskin Primary School, Maskwacis, (Maskwacis Education Schools Commission)",
    "Ernest Manning High School, Calgary, (The Calgary School Division)",
    "Ernest Morrow School, Calgary, (The Calgary School Division)",
    "Erskine Colony School, Erskine, (The Clearview School Division)",
    "Erskine School, Erskine, (The Clearview School Division)",
    "Escuela Vista Grande, Red Deer, (The Red Deer School Division )",
    "Esther Starkman School, Edmonton, (The Edmonton School Division)",
    "Ethel M. Johnson School, Calgary, (The Calgary School Division)",
    "Eugene Coste School, Calgary, (The Calgary School Division)",
    "Evansdale School, Edmonton, (The Edmonton School Division)",
    "Evansview School, Evansburg, (The Grande Yellowhead School Division)",
    "Evergreen Catholic Junior High Outreach, Spruce Grove, (The Evergreen Catholic Separate School Division)",
    "Evergreen Colony, Taber, (The Horizon School Division)",
    "Evergreen School, Calgary, (The Calgary School Division)",
    "Evergreen School, Edson, (The Grande Yellowhead School Division)",
    "Ewelme Colony School, Fort Macleod, (The Livingstone Range School Division)",
    "Exshaw School, Exshaw, (The Canadian Rockies School Division)",
    "F. E. Osborne School, Calgary, (The Calgary School Division)",
    "F. P. Walshe School, Fort Macleod, (The Livingstone Range School Division)",
    "F. R. Haythorne School, Sherwood Park, (The Elk Island School Division)",
    "F.G. Miller Junior Senior High School, Elk Point, (The St. Paul School Division)",
    "Fairlane Colony School, Taber, (The Horizon School Division)",
    "Fairview and Area Learning Store, Fairview, (The Peace River School Division)",
    "Fairview College - La Crete, La Crete, (The Northern Alberta Institute of Technology)",
    "Fairview College - Northern Region, High Level, (The Northern Alberta Institute of Technology)",
    "Fairview College - Northern Region (GED), High Level, (The Northern Alberta Institute of Technology)",
    "Fairview College - Peace River, Peace River, (The Northern Alberta Institute of Technology)",
    "Fairview Colony School, Irricana, (The Rocky View School Division)",
    "Fairview High School, Fairview, (The Peace River School Division)",
    "Fairview School, Red Deer, (The Red Deer School Division )",
    "Fairview School, Calgary, (The Calgary School Division)",
    "Fairville Colony School, Bassano, (The Grasslands School Division)",
    "Falconridge School, Calgary, (The Calgary School Division)",
    "Falun School, Falun, (The Wetaskiwin School Division)",
    "Father Doucet School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Father Gerard Redmond Community Catholic School, Hinton, (The Evergreen Catholic Separate School Division)",
    "Father Henri Voisin, Red Deer, (The Red Deer Catholic Separate School Division)",
    "Father J A Turcotte OMI School, Fort McMurray, (The Fort McMurray Roman Catholic Separate School Division)",
    "Father James Whelihan, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Father Lacombe Catholic School, Lacombe, (The St. Thomas Aquinas Roman Catholic Separate School Division )",
    "Father Lacombe School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Father Leduc Catholic School, Leduc, (The St. Thomas Aquinas Roman Catholic Separate School Division )",
    "Father Leo Green, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Father Leonard Van Tighem School, Lethbridge, (The Holy Spirit Roman Catholic Separate School Division)",
    "Father Michael Mireau School, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Father Michael Troy Junior High, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Father Patrick Mercredi Community School, Fort McMurray, (The Fort McMurray Roman Catholic Separate School Division)",
    "Father R Perin School, Chard, (The Northland School Division)",
    "Father Scollen School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Ferrybank Colony School, Ponoka, (The Wolf Creek School Division)",
    "FFCA High School Campus, Calgary, (Foundations for the Future Charter Academy Charter School Society)",
    "Filipino Language & Cultural School, Edmonton, (The Filipino Canadian Saranay Association of Alberta)",
    "Filipino Language and Cultural School of Calgary, Calgary, (Philippine Cultural Center Foundation (Calgary))",
    "Fireside School, Cochrane, (The Rocky View School Division)",
    "Fireweed Education Centre, Edmonton, (The Government of Canada as represented by Correctional Service Canada)",
    "First Steps & Beyond Inc., Red Deer, (First Steps & Beyond Inc.)",
    "Fish Creek School, Calgary, (The Calgary School Division)",
    "Fishing Lake Outreach Program, Fishing Lake, (The Northern Lights School Division)",
    "Fleetwood Bawden School, Lethbridge, (The Lethbridge School Division)",
    "Florence Hallock School, Edmonton, (The Edmonton School Division)",
    "Florence MacDougall Community School, High Level, (The Fort Vermilion School Division)",
    "Foothills Academy, Calgary, (Foothills Academy Society)",
    "Foothills Alliance ECS, Calgary, (Foothills Alliance Kindergarten Society)",
    "Foothills Composite High School, Okotoks, (The Foothills School Division)",
    "Foothills Creative Beginnings, Cochrane, (Foothills Creative Beginnings Preschool and E.C.S. Association)",
    "Footprints for Learning Society, Airdrie, (Footprints for Learning Society)",
    "Foremost School, Foremost, (The Prairie Rose School Division)",
    "Forest Green School, Stony Plain, (The Parkland School Division)",
    "Forest Heights School, Edmonton, (The Edmonton School Division)",
    "Forest Lawn High School, Calgary, (The Calgary School Division)",
    "Forestburg School, Forestburg, (The Battle River School Division )",
    "Fort Assiniboine School, Ft. Assiniboine, (The Pembina Hills School Division)",
    "Fort McKay First Nation E-Learning School, Fort McMurray , (Fort McKay First Nation)",
    "Fort McKay School, Fort MacKay, (The Northland School Division)",
    "Fort McMurray Christian School, Fort McMurray, (The Fort McMurray School Division)",
    "Fort McMurray Composite High School, Fort McMurray, (The Fort McMurray School Division)",
    "Fort McMurray Islamic School, Fort McMurray, (The Fort McMurray School Division)",
    "Fort Saskatchewan Christian School, Fort Saskatchewan, (The Elk Island School Division)",
    "Fort Saskatchewan Elementary School, Fort Saskatchewan, (The Elk Island School Division)",
    "Fort Saskatchewan High School, Fort Saskatchewan, (The Elk Island School Division)",
    "Fort Saskatchewan Next Step II Outreach School, Fort Saskatchewan, (The Elk Island School Division)",
    "Fort Vermilion Outreach Program, Fort Vermilion, (The Fort Vermilion School Division)",
    "Fort Vermilion Public School, Fort Vermilion, (The Fort Vermilion School Division)",
    "Four Winds Public School, Morinville, (The Sturgeon School Division )",
    "Fox Creek School, Fox Creek, (The Northern Gateway School Division)",
    "Fox Lake Elementary School, Fox Lake, (Little Red River Board of Education)",
    "Fr M Beauregard Education Community Centre, Fort McMurray, (The Fort McMurray Roman Catholic Separate School Division)",
    "Frank Maddock High School, Drayton Valley, (The Wild Rose School Division )",
    "Frank Spragins High School, Fort McMurray, (The Fort McMurray School Division)",
    "Fraser School, Edmonton, (The Edmonton School Division)",
    "Frere Antoine, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Fresh Start Cardinal Collins, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Fresh Start Millwoods, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Fresh Start Outreach Program, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Fresh Start Westmount Academic Centre, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Frog Lake Outreach School, Frog Lake, (Frog Lake Band)",
    "Fulham School, Yellowhead County, (The Grande Yellowhead School Division)",
    "Fultonvale Elementary Junior High School, Sherwood Park, (The Elk Island School Division)",
    "G H Dawe Community School, Red Deer, (The Red Deer School Division )",
    "G. S. Lakie Middle School, Lethbridge, (The Lethbridge School Division)",
    "G.W. Skene School, Calgary, (The Calgary School Division)",
    "Gadsby Colony School, Botha, (The Clearview School Division)",
    "Galbraith School, Lethbridge, (The Lethbridge School Division)",
    "Garden River School, John D'or Prairie, (Kayas Cultural College)",
    "Garneau School, Edmonton, (The Edmonton School Division)",
    "Garth Worthington School, , (The Edmonton School Division)",
    "Gasoline Alley Career High School, Red Deer County, (The Chinook's Edge School Division)",
    "Gateway Academy Fox Creek, Fox Creek, (The Northern Gateway School Division)",
    "Gateway Academy Onoway, Onoway, (The Northern Gateway School Division)",
    "Gateway Academy Valleyview, Valleyview, (The Northern Gateway School Division)",
    "Gateway Academy Whitecourt, Whitecourt, (The Northern Gateway School Division)",
    "Gateway Christian School, Red Deer, (The Red Deer School Division )",
    "Gem School, Gem, (The Grasslands School Division)",
    "General Stewart School, Lethbridge, (The Lethbridge School Division)",
    "Genesis Centre, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Genesis Protestant Reformed School, Lacombe, (Genesis Protestant Reformed School Society of Lacombe)",
    "George Davison Elementary School, Medicine Hat, (The Medicine Hat School Division)",
    "George Freeman School, Strathmore, (The Golden Hills School Division)",
    "George H Luck School, Edmonton, (The Edmonton School Division)",
    "George McDougall High School, Airdrie, (The Rocky View School Division)",
    "George P. Nicholson School, Edmonton, (The Edmonton School Division)",
    "George Wilbert Smith School, Red Deer, (The Red Deer School Division )",
    "Georges H Primeau School, Morinville, (The Greater St. Albert Roman Catholic Separate School Division)",
    "Georges P Vanier School, Donnelly, (The High Prairie School Division)",
    "Georges P. Vanier School, Calgary, (The Calgary School Division)",
    "German Language School Society of Edmonton, Edmonton, (German Language School Society of Edmonton)",
    "Getting Ready For Inclusion To, Calgary, (G.R.I.T. (Getting Ready for Inclusion Today) Calgary Society)",
    "Gibbons School, Gibbons, (The Sturgeon School Division )",
    "Gift Lake School, Gift Lake, (The Northland School Division)",
    "Gil Vicente School, Edmonton, (Gil Vicente Edmonton Portuguese School Society)",
    "Gilbert Paterson Middle School, Lethbridge, (The Lethbridge School Division)",
    "Glamorgan Community Kindergarten Society, Calgary, (Glamorgan Community Kindergarten Society)",
    "Glamorgan School, Calgary, (The Calgary School Division)",
    "Glen Allan Elementary School, Sherwood Park, (The Elk Island School Division)",
    "Glen Avon Protestant School, St. Paul, (The St. Paul School Division)",
    "Glenbow Elementary School, Cochrane, (The Rocky View School Division)",
    "Glenbrook School, Calgary, (The Calgary School Division)",
    "Glendale School, Edmonton, (The Edmonton School Division)",
    "Glendale School, Calgary, (The Calgary School Division)",
    "Glendale Sciences and Technology School, Red Deer, (The Red Deer School Division )",
    "Glendon School, Glendon, (The Northern Lights School Division)",
    "Glengarry School, Edmonton, (The Edmonton School Division)",
    "Glenmary School, Peace River, (The Holy Family Catholic Separate School Division)",
    "Glenmeadows School, Calgary, (The Calgary School Division)",
    "Glenmore Christian Academy Elementary, Calgary, (GCA Educational Society)",
    "Glenmore Christian Academy Junior High, Calgary, (GCA Educational Society)",
    "Glenora School, Edmonton, (The Edmonton School Division)",
    "Glenrose Colony School, Strathmore, (The Golden Hills School Division)",
    "Gobind Sarvar School, Calgary, (Gobind Marg Charitable Trust Foundation)",
    "Gold Bar School, Edmonton, (The Edmonton School Division)",
    "Gold Ridge Colony School, Lethbridge, (The Palliser School Division)",
    "Golden Hills Learning Academy, Strathmore, (The Golden Hills School Division)",
    "Goldspring Colony School, Warner, (The Horizon School Division)",
    "Good Shepherd, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Good Shepherd Community School, Fort McMurray, (The Fort McMurray Roman Catholic Separate School Division)",
    "Good Shepherd School, Airdrie, (The Calgary Roman Catholic Separate School Division)",
    "Good Shepherd School, Peace River, (The Holy Family Catholic Separate School Division)",
    "Good Shepherd School, Okotoks, (The Christ the Redeemer Catholic Separate School Division)",
    "Grace Marie Swampy Memorial Primary School, Maskwacis, (Maskwacis Education Schools Commission)",
    "Grace Martin School, Edmonton, (The Edmonton School Division)",
    "Graminia School, Spruce Grove, (The Parkland School Division)",
    "Grand Trunk High School, Evansburg, (The Grande Yellowhead School Division)",
    "Grande Cache Community High School, Grande Cache, (The Grande Yellowhead School Division)",
    "Grande Prairie Christian School, Grande Prairie, (The Grande Prairie School Division)",
    "Grande Prairie Composite High School, Grande Prairie, (The Grande Prairie School Division)",
    "Grande Prairie Public School District #2357 Home Schooling, Grande Prairie, (The Grande Prairie School Division)",
    "Grande Prairie Regional College, Grande Prairie, (Grande Prairie Regional College)",
    "Grande Yellowhead Regional Division Home Schooling, Edson, (The Grande Yellowhead School Division)",
    "Grandin School, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Grandview Colony School, County of Grande Prairie, (The Peace Wapiti School Division)",
    "Grandview Heights School, Edmonton, (The Edmonton School Division)",
    "Grant MacEwan School, Calgary, (The Calgary School Division)",
    "Granum Schools, Granum, (The Livingstone Range School Division)",
    "Grasmere School, Alberta Beach, (The Northern Gateway School Division)",
    "Grassland Community School, Grassland, (The Aspen View School Division)",
    "Greek Community School, Calgary, (Hellenic Society of Calgary and District)",
    "Greely Road School, Fort McMurray, (The Fort McMurray School Division)",
    "Green Acres School, Strathmore, (The Golden Hills School Division)",
    "Greenfield School, Edmonton, (The Edmonton School Division)",
    "Greentree School, Drumheller, (The Golden Hills School Division)",
    "Greenview School, Edmonton, (The Edmonton School Division)",
    "Greenwood Colony School, Fort Macleod, (The Livingstone Range School Division)",
    "Greystone Centennial Middle School, Spruce Grove, (The Parkland School Division)",
    "Grierson Education Centre, Edmonton, (The Government of Canada as represented by Correctional Service Canada)",
    "Griffin Park School, Brooks, (The Grasslands School Division)",
    "Griffith Woods School, Calgary, (The Calgary School Division)",
    "Griffiths-Scott School, Millet, (The Wetaskiwin School Division)",
    "Grimmon House, Innisfail, (The Chinook's Edge School Division)",
    "Grimshaw Public School, Grimshaw, (The Peace River School Division)",
    "Grit Program, Edmonton, (Getting Ready for Inclusion Today (The GRIT Program) Society of Edmonton)",
    "Grouard Northland School, Grouard, (The Northland School Division)",
    "Grovenor School, Edmonton, (The Edmonton School Division)",
    "Guardian Angel School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Gus Wetter School, Castor, (The Clearview School Division)",
    "Guthrie School, Lancaster Park, (The Sturgeon School Division )",
    "Guy Weadick School, Calgary, (The Calgary School Division)",
    "Gwynne School, Gwynne, (The Wetaskiwin School Division)",
    "H E Bourgoin Middle School, Bonnyville, (The Northern Lights School Division)",
    "H W Pickup Junior High School, Drayton Valley, (The Wild Rose School Division )",
    "H. A. Kostash School, Smoky Lake, (The Aspen View School Division)",
    "H. D. Cartwright School, Calgary, (The Calgary School Division)",
    "H. E. Beriault, Edmonton, (The Edmonton Catholic Separate School Division)",
    "H. Lorimer School, Airdrie, (The Rocky View School Division)",
    "Hairy Hill Colony School, Two Hills, (The St. Paul School Division)",
    "Handhills Colony School, Hanna, (The Prairie Land School Division)",
    "Hardisty School, Edmonton, (The Edmonton School Division)",
    "Harold Panabaker School, Calgary, (The Calgary School Division)",
    "Harry Ainlay School, Edmonton, (The Edmonton School Division)",
    "Harry Balfour School, Grande Prairie, (The Peace Wapiti School Division)",
    "Harry Collinge High School, Hinton, (The Grande Yellowhead School Division)",
    "Harry Gray Elementary School, Valleyview, (The Northern Gateway School Division)",
    "Hartland Colony School, Camrose, (The Battle River School Division )",
    "Harvest Baptist Academy, Spruce Grove, (Independent Baptist Christian Education Society)",
    "Haultain Memorial School, Calgary, (The Calgary School Division)",
    "Hawkwood School, Calgary, (The Calgary School Division)",
    "Hay Lakes School, Hay Lakes, (The Battle River School Division )",
    "Hay Lakes School ECS, Hay Lakes, (Hay Lakes ECS)",
    "Hays School, Hays, (The Horizon School Division)",
    "Haysboro School, Calgary, (The Calgary School Division)",
    "Hazeldean School, Edmonton, (The Edmonton School Division)",
    "Headway School Society of Alberta, Edmonton, (Headway School Society of Alberta)",
    "Heart Lake Kohls School, Lac La Biche, (Heart Lake Band #469)",
    "Heart Valley Christian School, Wanham, (The Heart Valley Church of God in Christ Mennonite)",
    "Heinsburg Community School, Heinsburg, (The St. Paul School Division)",
    "Helen E. Taylor School, Wembley, (The Peace Wapiti School Division)",
    "Hellenic-Canadian Community of Edmonton and Region, Edmonton, (Hellenic-Canadian Community of Edmonton and Region)",
    "Henry Wise Wood High School, Calgary, (The Calgary School Division)",
    "Herald School, Medicine Hat, (The Medicine Hat School Division)",
    "Heritage Christian Academy, Calgary, (The Palliser School Division)",
    "Heritage Heights School, De Winton, (The Foothills School Division)",
    "Heritage Hills Elementary School, , (The Elk Island School Division)",
    "Herons Crossing School, Airdrie, (The Rocky View School Division)",
    "Hidden Valley School, Calgary, (The Calgary School Division)",
    "High Level Christian Academy, High Level, (High Level Christian Education Society)",
    "High Level Learning Store, High Level, (The Fort Vermilion School Division)",
    "High Level Public School, High Level, (The Fort Vermilion School Division)",
    "High Park School, Stony Plain, (The Parkland School Division)",
    "High Prairie Elementary School, High Prairie, (The High Prairie School Division)",
    "High River Colony School, High River, (The Foothills School Division)",
    "Higher Ground Christian School, Medicine Hat, (Alberta Conference of 7th Day Adventist Church)",
    "Highland View Colony School, Hughenden, (The Buffalo Trail School Division)",
    "Highlands School, Edmonton, (The Edmonton School Division)",
    "Highwood School, Calgary, (The Calgary School Division)",
    "Hill Crest Community School, La Crete, (The Fort Vermilion School Division)",
    "Hillcrest Christian School, Grande Prairie, (Hillcrest Christian School Society)",
    "Hillcrest Montessori Academy, Fort McMurray, (Fort McMurray Montessori Foundation)",
    "Hillcrest School, Edmonton, (The Edmonton School Division)",
    "Hillhurst School, Calgary, (The Calgary School Division)",
    "Hillridge Colony School, Taber, (The Horizon School Division)",
    "Hillside Community School, Grande Prairie, (The Grande Prairie School Division)",
    "Hillside Junior Senior High School, Valleyview, (The Northern Gateway School Division)",
    "Hilltop Christian School, Fort Vermilion, (Church of God in Christ Mennonite)",
    "Hilltop Education Centre, Drumheller, (The Government of Canada as represented by Correctional Service Canada)",
    "Hilltop High School, Whitecourt, (The Northern Gateway School Division)",
    "Hillview School, High Prairie, (The Northland School Division)",
    "Hillview School, Edmonton, (The Edmonton School Division)",
    "Hilwie Hamdon School, Edmonton, (The Edmonton School Division)",
    "Hines Creek Composite School, Hines Creek, (The Peace River School Division)",
    "Hines School, Strathmore, (The Golden Hills School Division)",
    "History Re-Registration, Edmonton, (Other Schools)",
    "Hofmann Colony School, Lethbridge, (The Palliser School Division)",
    "Holden Colony School, Camrose, (The Battle River School Division )",
    "Holden School, Holden, (The Battle River School Division )",
    "Holt Colony School, Irma, (The Buffalo Trail School Division)",
    "Holy Angels School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Holy Child School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Holy Cross, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Holy Cross Catholic School, Grande Prairie, (The Grande Prairie Roman Catholic Separate School Division)",
    "Holy Cross Collegiate, Strathmore, (The Christ the Redeemer Catholic Separate School Division)",
    "Holy Cross Elementary Junior High School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Holy Cross Elementary School, Cold Lake, (The Lakeland Roman Catholic Separate School Division)",
    "Holy Family, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Holy Family Academy, Brooks, (The Christ the Redeemer Catholic Separate School Division)",
    "Holy Family Catholic School, Waskatenau, (The Lakeland Roman Catholic Separate School Division)",
    "Holy Family Cyberhigh Virtual School, Peace River, (The Holy Family Catholic Separate School Division)",
    "Holy Family School, Grimshaw, (The Holy Family Catholic Separate School Division)",
    "Holy Family School, Red Deer, (The Red Deer Catholic Separate School Division)",
    "Holy Family School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Holy Name (Bilingual) School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Holy Redeemer Catholic Junior/Senior High School, Edson, (The Living Waters Catholic Separate School Division)",
    "Holy Redeemer Catholic School, Ardrossan, (The Elk Island Catholic Separate School Division)",
    "Holy Rosary High School, Lloydminster, (Lloydminster Roman Catholic Separate School Division)",
    "Holy Spirit Academy, High River, (The Christ the Redeemer Catholic Separate School Division)",
    "Holy Spirit Catholic School, Sherwood Park, (The Elk Island Catholic Separate School Division)",
    "Holy Spirit Catholic School, Devon, (The Evergreen Catholic Separate School Division)",
    "Holy Spirit Catholic School, Cochrane, (The Calgary Roman Catholic Separate School Division)",
    "Holy Trinity, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Holy Trinity Academy, Drayton Valley, (The St. Thomas Aquinas Roman Catholic Separate School Division )",
    "Holy Trinity Academy, Okotoks, (The Christ the Redeemer Catholic Separate School Division)",
    "Holy Trinity High School, Fort McMurray, (The Fort McMurray Roman Catholic Separate School Division)",
    "Holy Trinity School, Olds, (The Red Deer Catholic Separate School Division)",
    "Holy Trinity School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Holyrood School, Edmonton, (The Edmonton School Division)",
    "HOME Base, Olds, (The Chinook's Edge School Division)",
    "Home Education, Okotoks, (The Foothills School Division)",
    "Home Schooling, Ponoka, (The Wolf Creek School Division)",
    "Home Schooling Program, Vermilion, (The East Central Alberta Catholic Separate School Division)",
    "Homeland Colony School, Valleyview, (The Northern Gateway School Division)",
    "Homesteader School, Edmonton, (The Edmonton School Division)",
    "Hope Christian School, Champion, (Evangelical Free Church of Champion Alberta)",
    "Horace Allen School, Coleman, (The Livingstone Range School Division)",
    "Horizon Alternate Program, Olds, (The Chinook's Edge School Division)",
    "Horizon Group Care, Sexsmith, (The Peace Wapiti School Division)",
    "Horizon Heritage Arabic School, Calgary, (Horizon Academy Institute)",
    "Horizon Leadership Academy, Calgary, (The Palliser School Division)",
    "Horizon MAP School, Vauxhall, (The Horizon School Division)",
    "Horse Hill School, Edmonton, (The Edmonton School Division)",
    "Horse Lake School, Hythe, (Horse Lake First Nation)",
    "Hospital School Campuses, Edmonton, (The Edmonton School Division)",
    "HUB Okotoks, Okotoks, (The Foothills School Division)",
    "Hugh A. Bennett School, Calgary, (The Calgary School Division)",
    "Hugh Sutherland School, Carstairs, (The Chinook's Edge School Division)",
    "Hughenden Public School, Hughenden, (The Buffalo Trail School Division)",
    "Hunting Hills High School, Red Deer, (The Red Deer School Division )",
    "Huntington Hills School, Calgary, (The Calgary School Division)",
    "Huntsville School, Iron Springs, (The Palliser School Division)",
    "Hutterian Brethren Church School of Elkwater, Irvine, (The Prairie Rose School Division)",
    "Hutterian Brethren Church School of Murray Lake, Dunmore, (The Prairie Rose School Division)",
    "Hutterite Colony School, Smoky Lake, (The Aspen View School Division)",
    "Hutterville Colony School, Cardston, (The Westwind School Division)",
    "Huxley Colony School, Strathmore, (The Golden Hills School Division)",
    "Hythe Regional School, Hythe, (The Peace Wapiti School Division)",
    "I.V. Macklin Public School, Grande Prairie, (The Grande Prairie School Division)",
    "I'm For Kids Team, Calgary, (I'm For Kids Team Ltd.)",
    "Ian Bazalgette School, Calgary, (The Calgary School Division)",
    "Immanuel Christian Elementary School, Lethbridge, (The Lethbridge School Division)",
    "Immanuel Christian Secondary School, Lethbridge, (The Lethbridge School Division)",
    "Indus School, Indus, (The Rocky View School Division)",
    "Inglewood School, Edmonton, (The Edmonton School Division)",
    "Inner City High School, Edmonton, (Inner City Youth Development Association)",
    "Innisfail Career High School, Innisfail, (The Chinook's Edge School Division)",
    "Innisfail High School, Innisfail, (The Chinook's Edge School Division)",
    "Innisfree School, Innisfree, (Innisfree and District Early Childhood Services Association)",
    "Institutional Services Schools, Edmonton, (The Edmonton School Division)",
    "Irma School, Irma, (The Buffalo Trail School Division)",
    "Iron Creek Colony School, Camrose, (The Battle River School Division )",
    "Iron Ridge Elementary Campus, Blackfalds, (The Wolf Creek School Division)",
    "Iron Ridge Intermediate Campus, Blackfalds, (The Wolf Creek School Division)",
    "Iron Ridge Junior Campus, Blackfalds, (The Wolf Creek School Division)",
    "Iron River School, Iron River, (The Northern Lights School Division)",
    "Irricana Community Hall ECS, Irricana, (Irricana E.C.S. Society)",
    "Irvine School, Dunmore, (The Prairie Rose School Division)",
    "Isabel Campbell Public School, Grande Prairie, (The Grande Prairie School Division)",
    "Isabel F Cox School, Redcliff, (The Prairie Rose School Division)",
    "Isabelle Sellon School, Blairmore, (The Livingstone Range School Division)",
    "It Takes a Village Kindergarten, Cochrane, (It Takes A Village Educational Society)",
    "Italian School of Calgary, Calgary, (Centro Linguistico E Culturale Italiano Calgary)",
    "Ivan Franko Ukrainian School, Edmonton, (The Society of Ukrainian Studies of Alberta)",
    "Ivor Dent School, Edmonton, (The Edmonton School Division)",
    "J A Fife School, Edmonton, (The Edmonton School Division)",
    "J A Williams High School, Lac La Biche, (The Northern Lights School Division)",
    "J F Dion School, Fishing Lake, (The Northland School Division)",
    "J H Picard, Edmonton, (The Edmonton Catholic Separate School Division)",
    "J Percy Page School, Edmonton, (The Edmonton School Division)",
    "J. C. Charyk Hanna School, Hanna, (The Prairie Land School Division)",
    "J. J. Bowlen, Edmonton, (The Edmonton Catholic Separate School Division)",
    "J. J. Nearing Catholic Elementary School, St. Albert, (The Greater St. Albert Roman Catholic Separate School Division)",
    "J. R. Robson School, Vermilion, (The Buffalo Trail School Division)",
    "J. T. Foster School, Nanton, (The Livingstone Range School Division)",
    "Jack James High School, Calgary, (The Calgary School Division)",
    "Jack Stuart School, Camrose, (The Battle River School Division )",
    "Jackson Heights Elementary, Edmonton, (The Edmonton School Division)",
    "James Fowler High School, Calgary, (The Calgary School Division)",
    "James Gibbons School, Edmonton, (The Edmonton School Division)",
    "James Mowat School, Fort Saskatchewan, (The Elk Island School Division)",
    "James Short Memorial School, Calgary, (The Calgary School Division)",
    "Jan Reimer School, Edmonton, (The Edmonton School Division)",
    "Janet Johnstone School, Calgary, (The Calgary School Division)",
    "Janus Academy, Calgary, (Janus Academy Society)",
    "Janus Academy (Holy Cross Site), Calgary, (Janus Academy Society)",
    "Jasper Elementary School, Jasper, (The Grande Yellowhead School Division)",
    "Jasper Junior Senior High School, Jasper, (The Grande Yellowhead School Division)",
    "Jasper Place Child Care/ECS Program, Edmonton, (Jasper Place Child and Family Resource Society)",
    "Jasper Place School, Edmonton, (The Edmonton School Division)",
    "Jean Baptiste Sewepagaham School, Fox Lake, (Little Red River Board of Education)",
    "Jean Vanier Catholic School, Sherwood Park, (The Elk Island Catholic Separate School Division)",
    "Jenner Colony School, Jenner, (The Prairie Rose School Division)",
    "Jenner School, Jenner, (Cactus Country E.C.S. Association)",
    "Jenner School, Jenner, (The Prairie Rose School Division)",
    "Jennie Elliott School, Calgary, (The Calgary School Division)",
    "Jennie Emery Elementary School, Coaldale, (The Palliser School Division)",
    "Jessie Duncan Elementary School, Penhold, (The Chinook's Edge School Division)",
    "Joane Cardinal-Schubert High School, Calgary, (The Calgary School Division)",
    "John A. McDougall School, Edmonton, (The Edmonton School Division)",
    "John Barnett School, Edmonton, (The Edmonton School Division)",
    "John Costello Catholic School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "John D Bracco School, Edmonton, (The Edmonton School Division)",
    "John D'or Prairie School, John D'or Prairie, (Little Red River Board of Education)",
    "John D'Or School, John D'or Prairie, (Kayas Cultural College)",
    "John Davidson School Program, Coaldale, (The Palliser School Division)",
    "John G Diefenbaker High School, Calgary, (The Calgary School Division)",
    "John Maland High School, Devon, (The Black Gold School Division )",
    "John Paul I, Edmonton, (The Edmonton Catholic Separate School Division)",
    "John Ware School, Calgary, (The Calgary School Division)",
    "Johnny Bright School, Edmonton, (The Edmonton School Division)",
    "Joseph M. Demko School, St. Albert, (The St. Albert School Division)",
    "Joseph Welsh School, Red Deer, (The Red Deer School Division )",
    "Journeys Learning Academy, Kikino, (The Northern Lights School Division)",
    "Joussard School, Joussard, (The High Prairie School Division)",
    "Julia Kiniski School, Edmonton, (The Edmonton School Division)",
    "Jumbo Valley Hutterite Colony School, Fort Macleod, (The Livingstone Range School Division)",
    "Junior High Fresh Start Outreach Program, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Juno Beach Academy of Canadian Studies, Calgary, (The Calgary School Division)",
    "K-C Community Services, Sylvan Lake, (kcs Association for Early Learning, Special Needs, and Family Supports)",
    "Kainai Adolescent Treatment Centre, Stand Off, (Kainai Board of Education)",
    "Kainai Alternate Academy, Stand Off, (Kainai Board of Education)",
    "Kainai High School, Cardston, (Kainai Board of Education)",
    "Kameyosek School, Edmonton, (The Edmonton School Division)",
    "Kate Andrews High School, Coaldale, (The Palliser School Division)",
    "Kate Chegwin School, Edmonton, (The Edmonton School Division)",
    "Kateri School, Trout Lake, (Kee Tas Kee Now Tribal Council Education Authority)",
    "Katherine Therrien, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Kathyrn School, Kathyrn, (The Rocky View School Division)",
    "Kayas Cultural College, John D'or Prairie, (Kayas Cultural College)",
    "Keeler School, Calgary, (The Calgary School Division)",
    "Keenooshayo Elementary School, St. Albert, (The St. Albert School Division)",
    "Keheewin School, Edmonton, (The Edmonton School Division)",
    "Kehewin Community Education Centre, Kehewin, (Kehewin Band)",
    "Kehewin Community Education Centre Outreach, Kehewin, (Kehewin Band)",
    "Keho Lake Colony School, Lethbridge, (The Palliser School Division)",
    "Kenilworth School, Edmonton, (The Edmonton School Division)",
    "Kenneth D. Taylor School, Calgary, (The Calgary School Division)",
    "Kensington School, Edmonton, (The Edmonton School Division)",
    "Keyano College, Fort McMurray, (Keyano College)",
    "Khalsa School Calgary Educational Foundation, Calgary, (Khalsa School Calgary Educational Foundation)",
    "Kids at Play Preschool, Calgary, (Trail Kids Ltd.)",
    "Kids Developmental Services Ltd., Calgary, (Kids Developmental Services Ltd.)",
    "Kihew Asiniy Education Centre, Saddle Lake, (Saddle Lake Education Authority)",
    "Kikino Elementary School, Kikino, (The Northern Lights School Division)",
    "Kildare School, Edmonton, (The Edmonton School Division)",
    "Killam Public School, Killam, (The Battle River School Division )",
    "Killarney School, Edmonton, (The Edmonton School Division)",
    "Killarney School, Calgary, (The Calgary School Division)",
    "Killarney School Program, Edmonton, (ABC Head Start Society)",
    "Kim Hung School, Edmonton, (The Edmonton School Division)",
    "King Edward School, Edmonton, (The Edmonton School Division)",
    "King George School, Calgary, (The Calgary School Division)",
    "Kingslake Colony School, Foremost, (The Prairie Rose School Division)",
    "Kingsland Colony School, Taber, (The Horizon School Division)",
    "Kinuso Outreach Program, Kinuso, (The High Prairie School Division)",
    "Kinuso School, Kinuso, (The High Prairie School Division)",
    "Kipohtakaw Education Centre, Morinville, (Alexander First Nation Education Authority)",
    "Kirkness School, Edmonton, (The Edmonton School Division)",
    "Kisiko Awasis Kiskinahamawin, Robb, (The Edmonton Catholic Separate School Division)",
    "Kisipatnahk School, Maskwacis, (Maskwacis Education Schools Commission)",
    "Kitaskinaw School, Enoch, (Kitaskinaw Education Authority)",
    "Kitscoty & District Early Childhood Education Kindergarten, Kitscoty, (Kitscoty and District Early Childhood Education Kindergarten)",
    "Kitscoty Elementary School, Kitscoty, (The Buffalo Trail School Division)",
    "Kitscoty Junior Senior High School, Kitscoty, (The Buffalo Trail School Division)",
    "Kneehill Christian School, Linden, (Kneehill Christian School)",
    "Koinonia Christian School (Red Deer), Red Deer, (Koinonia Christian School - Red Deer Society)",
    "Kootenay School, Rocky Mountain House, (The Wild Rose School Division )",
    "L Y Cairns School, Edmonton, (The Edmonton School Division)",
    "L.T. Westlake School, Taber, (The Horizon School Division)",
    "La Crete Outreach Program, La Crete, (The Fort Vermilion School Division)",
    "La Crete Public School, La Crete, (The Fort Vermilion School Division)",
    "La Glace School, La Glace, (The Peace Wapiti School Division)",
    "Lac La Biche Outreach, Lac La Biche, (The Northern Lights School Division)",
    "Lacombe Christian School, Lacombe, (The Lacombe Christian School Society)",
    "Lacombe Outreach School, Lacombe, (The Wolf Creek School Division)",
    "Lago Lindo School, Edmonton, (The Edmonton School Division)",
    "Lake Bonavista School, Calgary, (The Calgary School Division)",
    "Lakedell School, Westerose, (The Wetaskiwin School Division)",
    "Lakeland Christian Academy, Cold Lake, (Lakeland Christian School Society)",
    "Lakeland College (Lloydminster), Vermilion, (Lakeland College)",
    "Lakeland College (Vermillion), Vermilion, (Lakeland College)",
    "Lakeland Country School, Dewberry, (Lakeland Church of God in Christ (Mennonite) of Dewberry)",
    "Lakeland Ridge School, Sherwood Park, (The Elk Island School Division)",
    "Lakeside Outreach School, Slave Lake, (The High Prairie School Division)",
    "Lakeview Christian School, Stettler, (Lakeview Christian School)",
    "Lakeview Elementary School, Lethbridge, (The Lethbridge School Division)",
    "Lamont Elementary School, Lamont, (The Elk Island School Division)",
    "Lamont High School, Lamont, (The Elk Island School Division)",
    "Landing Trail Intermediate School, Athabasca, (The Aspen View School Division)",
    "Landing Trail School, Gibbons, (The Sturgeon School Division )",
    "Lanes Lake Colony School, Castor, (The Clearview School Division)",
    "Langdon School, Langdon, (The Rocky View School Division)",
    "Langevin School, Calgary, (The Calgary School Division)",
    "Lansdowne School, Edmonton, (The Edmonton School Division)",
    "LaPerle School, Edmonton, (The Edmonton School Division)",
    "Lathom Colony School, Bassano, (The Grasslands School Division)",
    "Lauderdale School, Edmonton, (The Edmonton School Division)",
    "Laurier Heights School, Edmonton, (The Edmonton School Division)",
    "Lawrence Grassi Middle School, Canmore, (The Canadian Rockies School Division)",
    "Le Goff School, Cold Lake, (Federally Administered Schools)",
    "Le Roi Daniels School, Calgary, (The Calgary School Division)",
    "Lead Foundation, Calgary, (Lead Foundation)",
    "Learning From Play, Spruce Grove, (Learning From Play Educational Foundation)",
    "Learning Store at Blue Quill, Edmonton, (The Edmonton School Division)",
    "Learning Store at Circle Square, Edmonton, (The Edmonton School Division)",
    "Learning Store at Londonderry, Edmonton, (The Edmonton School Division)",
    "Learning Store on Whyte, Edmonton, (The Edmonton School Division)",
    "Learning Store West Edmonton, Edmonton, (The Edmonton School Division)",
    "Leduc Composite High School, Leduc, (The Black Gold School Division )",
    "Lee Ridge School, Edmonton, (The Edmonton School Division)",
    "Leedale Hutterite Colony, Rimbey, (The Wolf Creek School Division)",
    "Legal School, Legal, (The Greater St. Albert Roman Catholic Separate School Division)",
    "Lendrum School, Edmonton, (The Edmonton School Division)",
    "Leo Nickerson Elementary, St. Albert, (The St. Albert School Division)",
    "Leslieville Elementary School, Leslieville, (The Wild Rose School Division )",
    "Lester B. Pearson High School, Calgary, (The Calgary School Division)",
    "Lethbridge Christian School, Lethbridge, (The Lethbridge School Division)",
    "Lethbridge Collegiate Institute, Lethbridge, (The Lethbridge School Division)",
    "Lethbridge Community College, Lethbridge, (Lethbridge Community College)",
    "Lethbridge Community College (Lethbridge Correctional Centre), Lethbridge, (Lethbridge Community College)",
    "Lethbridge Montessori Kindergarten, Lethbridge, (Lethbridge Montessori Society)",
    "Lethbridge Montessori Kindergarten-Forestry Avenue Campus, Lethbridge, (Lethbridge Montessori Society)",
    "Lethbridge Regional Hospital School, Lethbridge, (The Lethbridge School Division)",
    "Lethbridge Writing Centre, Lethbridge, (The East Central Alberta Catholic Separate School Division)",
    "Light of Christ Catholic School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Light of Christ Catholic School, Lac La Biche, (The Lakeland Roman Catholic Separate School Division)",
    "Lighthouse Christian School, Sylvan Lake, (Lighthouse Christian School Society)",
    "Lightning Ridge Education Centre, Grande Cache, (The Government of Canada as represented by Correctional Service Canada)",
    "Lilian Schick School, Bon Accord, (The Sturgeon School Division )",
    "Lillian Osborne High School, Edmonton, (The Edmonton School Division)",
    "Lindsay Thurber Comprehensive High School, Red Deer, (The Red Deer School Division )",
    "Linsford Park School, Leduc, (The Black Gold School Division )",
    "Little Angels, Calgary, (Asasa Learning)",
    "Little Bow Colony School, Fort Macleod., (The Livingstone Range School Division)",
    "Little Buffalo School, Cadotte Lake, (Kee Tas Kee Now Tribal Council Education Authority)",
    "Living Springs Christian School, Hythe, (Living Springs Mennonite Church of Hythe)",
    "Living Truth Christian School, Mirror, (Living Truth Christian School Society)",
    "Living Waters Christian Academy, Spruce Grove, (Living Waters Christian Academy)",
    "Livingstone Colony School, Fort Macleod, (The Livingstone Range School Division)",
    "Livingstone Range Outreach - North, Claresholm, (The Livingstone Range School Division)",
    "Livingstone Range School Division Virtual School, Fort Macleod, (The Livingstone Range School Division)",
    "Livingstone Range Summer School, Fort Macleod, (The Livingstone Range School Division)",
    "Livingstone School, Lundbreck, (The Livingstone Range School Division)",
    "Lloyd Garrison School, Berwyn, (The Peace River School Division)",
    "Lloydminster Comprehensive High School, Lloydminster, (Lloydminster Public School Division)",
    "Lochearn Elementary School, Rocky Mountain House, (The Wild Rose School Division )",
    "Lois E. Hole Elementary School, St. Albert, (The St. Albert School Division)",
    "Lomond Colony School, Taber, (The Horizon School Division)",
    "Lomond Community School, Lomond, (The Horizon School Division)",
    "Lomond School ECS, Lomond, (Lomond Little Learners Society)",
    "Londonderry Child Development Society, Edmonton, (Londonderry Child Development Society)",
    "Londonderry School, Edmonton, (The Edmonton School Division)",
    "Lone Pine Colony School, Botha, (The Clearview School Division)",
    "Longview School, Longview, (The Foothills School Division)",
    "Lord Beaverbrook High School, Calgary, (The Calgary School Division)",
    "Lorelei School, Edmonton, (The Edmonton School Division)",
    "Lorne Akins Junior High School, St. Albert, (The St. Albert School Division)",
    "Lotsa Tots Day Care ECS, Red Deer, (Lotsa Tots Early Childhood Society)",
    "Lotsa Tots West Kindergarten, Red Deer, (Lotsa Tots Early Childhood Society)",
    "Lougheed Colony School, Camrose, (The Battle River School Division )",
    "Louis Riel School, Calgary, (The Calgary School Division)",
    "Louis St. Laurent, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Louise Dean School, Calgary, (The Calgary School Division)",
    "Lycee Louis Pasteur, Calgary, (Lycee Louis Pasteur Society)",
    "Lymburn School, Edmonton, (The Edmonton School Division)",
    "Lynnwood School, Edmonton, (The Edmonton School Division)",
    "M. E. LaZerte School, Edmonton, (The Edmonton School Division)",
    "MAC Islamic School, Edmonton, (Muslim Association of Canada)",
    "MAC Islamic School - Calgary Chapter, Calgary, (Muslim Association of Canada)",
    "MAC Islamic School Southside Campus, , (Muslim Association of Canada)",
    "MacMillan Colony School, Cayley, (The Foothills School Division)",
    "Madeleine D' Houet Bilingual School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Madonna Catholic School, Sherwood Park, (The Elk Island Catholic Separate School Division)",
    "Magrath Elementary School, Magrath, (The Westwind School Division)",
    "Magrath Junior Senior High School, Magrath, (The Westwind School Division)",
    "Major General Griesbach School, Edmonton, (The Edmonton School Division)",
    "Malcolm Tweddle School, Edmonton, (The Edmonton School Division)",
    "Malmo School, Edmonton, (The Edmonton School Division)",
    "Mamawi Atosketan Native School, Ponoka, (Alberta Conference of 7th Day Adventist Church)",
    "Manachaban Middle School, Cochrane, (The Rocky View School Division)",
    "Manmeet Singh Bhullar School, Calgary, (The Calgary School Division)",
    "Manning Adult Learning Centre, Edmonton, (The Government of Canada as represented by Correctional Service Canada)",
    "Manning Community Adult Learning Centre, Manning, (Manning Community Adult Learning Centre)",
    "Manning Elementary School, Manning, (The Peace River School Division)",
    "Mannville School, Mannville, (The Buffalo Trail School Division)",
    "Maple Ridge School, Calgary, (The Calgary School Division)",
    "Margaret Wooding School, Redcliff, (The Prairie Rose School Division)",
    "Maria Montessori School, Calgary, (Maria Montessori Education Centre of Calgary, Ltd.)",
    "Maria Montessori School - cSPACE King Edward Location, Calgary, (Maria Montessori Education Centre of Calgary, Ltd.)",
    "Marion Carson School, Calgary, (The Calgary School Division)",
    "Marlborough Day Nursery ECS Ltd. West Henday Campus, Edmonton, (Marlborough Day Nursery ECS Limited)",
    "Marlborough Day Nursery Ltd., Calgary, (Marlborough Day Nursery ECS Limited)",
    "Marlborough School, Calgary, (The Calgary School Division)",
    "Marshall Springs School, Calgary, (The Calgary School Division)",
    "Marwayne Jubilee School, Marwayne, (The Buffalo Trail School Division)",
    "Mary Bergeron Elementary School, Edson, (The Grande Yellowhead School Division)",
    "Mary Butterworth School, Edmonton, (The Edmonton School Division)",
    "Mary Hanley, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Maryview School, Red Deer, (The Red Deer Catholic Separate School Division)",
    "Maskwachees Cultural School, Maskwacis, (Maskwachees Cultural College Foundation)",
    "Maskwacis Outreach School, Maskwacis, (Maskwacis Education Schools Commission)",
    "Master's Academy, Calgary, (The Palliser School Division)",
    "Master's College, Calgary, (The Palliser School Division)",
    "Matthew Halton High School, Pincher Creek, (The Livingstone Range School Division)",
    "Mattie McCullough Elementary School, Red Deer, (The Red Deer School Division )",
    "Maude Clifford Public School, Grande Prairie, (The Grande Prairie School Division)",
    "Maximize Early Childhood Services, , (Maximize Services for Special Needs Society of Alberta)",
    "Mayerthorpe Junior Senior High School, Mayerthorpe, (The Northern Gateway School Division)",
    "Mayfield Hutterite Colony School, Dunmore, (The Prairie Rose School Division)",
    "Mayfield School, Edmonton, (The Edmonton School Division)",
    "Mayland Heights School, Calgary, (The Calgary School Division)",
    "McArthur School, Edmonton, (The Edmonton School Division)",
    "McKee School, Edmonton, (The Edmonton School Division)",
    "McKenzie Highlands School, Calgary, (The Calgary School Division)",
    "McKenzie Lake School, Calgary, (The Calgary School Division)",
    "McKenzie Learning Centre, Calgary, (Marlborough Day Nursery ECS Limited)",
    "McKenzie Towne School, Calgary, (The Calgary School Division)",
    "McKernan School, Edmonton, (The Edmonton School Division)",
    "McLeod School, Edmonton, (The Edmonton School Division)",
    "McNally School, Edmonton, (The Edmonton School Division)",
    "Meadow Ridge School, Okotoks, (The Foothills School Division)",
    "Meadowbrook School, Airdrie, (The Rocky View School Division)",
    "Meadowlark Christian School, Edmonton, (The Edmonton School Division)",
    "Meadowlark School, Edmonton, (The Edmonton School Division)",
    "Meadows Baptist Academy, Edmonton, (Independent Baptist Christian Education Society)",
    "Meander River Community School, Meander River, (Dene Tha' Band)",
    "Mecca Glen School, Ponoka, (The Wolf Creek School Division)",
    "Medicine Hat Christian School, Medicine Hat, (The Medicine Hat School Division)",
    "Medicine Hat College, Medicine Hat, (Medicine Hat College)",
    "Medicine Hat College (Brooks), Brooks, (Medicine Hat College)",
    "Medicine Hat High School, Medicine Hat, (The Medicine Hat School Division)",
    "Medicine Hat School District No.76 Summer School, Medicine Hat, (The Medicine Hat School Division)",
    "Mee-Yah-Noh School, Edmonton, (The Edmonton School Division)",
    "Memorial Composite High School, Stony Plain, (The Parkland School Division)",
    "Memorial Composite High School Outreach, Stony Plain, (The Parkland School Division)",
    "Menisa School, Edmonton, (The Edmonton School Division)",
    "Menno Simons Christian School, Calgary, (The Palliser School Division)",
    "Menno-Simons Community School, Cleardale, (The Peace River School Division)",
    "Meridian Colony School, Oyen, (The Prairie Rose School Division)",
    "Meskanahk Ka-Nipa-Wit School, Maskwacis, (Maskwacis Education Schools Commission)",
    "Metro Continuing Education, Edmonton, (The Edmonton School Division)",
    "Meyokumin School, Edmonton, (The Edmonton School Division)",
    "Meyonohk School, Edmonton, (The Edmonton School Division)",
    "MHHS Gateway School, Pincher Creek, (The Livingstone Range School Division)",
    "Mialta Colony School, Lethbridge, (The Palliser School Division)",
    "Miami Colony School, Cardston, (The Westwind School Division)",
    "Michael A Kostek Elementary School, Edmonton, (The Edmonton School Division)",
    "Michael Phair School, Edmonton, (The Edmonton School Division)",
    "Michael Strembitsky School, Edmonton, (The Edmonton School Division)",
    "Middle Years Outreach School, Grande Prairie, (The Grande Prairie School Division)",
    "Midland Colony School, Taber, (The Horizon School Division)",
    "Midnapore School, Calgary, (The Calgary School Division)",
    "Midpark Writing Centre, Calgary, (The East Central Alberta Catholic Separate School Division)",
    "Midsun School, Calgary, (The Calgary School Division)",
    "Mihtatakaw Sipiy School, Driftpile, (Driftpile Band)",
    "Mike Mountain Horse School, Lethbridge, (The Lethbridge School Division)",
    "Mikisew Cree First Nation Alternative Education Program, Fort Chipewyan, (Mikisew Cree First Nation)",
    "Milford Colony School, Cardston, (The Westwind School Division)",
    "Milk River Elementary School, Milk River, (The Horizon School Division)",
    "Mill Creek School, Edmonton, (The Edmonton School Division)",
    "Millarville Community School, Millarville, (The Foothills School Division)",
    "Millgrove School, Spruce Grove, (The Parkland School Division)",
    "Mills Haven Elementary School, Sherwood Park, (The Elk Island School Division)",
    "Millwoods Christian School, Edmonton, (The Edmonton School Division)",
    "Milo Community School, Milo, (The Palliser School Division)",
    "Milo ECS School, Milo, (Milo Alphabets Kindergarten Society)",
    "Miltow Colony School, Taber, (The Horizon School Division)",
    "Mimiw Sakahikan School, Ma-Me-O Beach, (Maskwacis Education Schools Commission)",
    "Minchau School, Edmonton, (The Edmonton School Division)",
    "Mistassiniy Outreach Program, Wabasca, (The Northland School Division)",
    "Mistassiniy School, Wabasca, (The Northland School Division)",
    "Mitford School, Cochrane, (The Rocky View School Division)",
    "Monsignor A. J. Hetherington Elementary School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Monsignor E. L. Doyle Elementary School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Monsignor Fee Otterson Catholic Elementary/Junior High School, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Monsignor J. J. O'Brien, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Monsignor J. S. Smith School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Monsignor McCoy High School, Medicine Hat, (The Medicine Hat Roman Catholic Separate School Division)",
    "Monsignor Neville Anderson School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Monsignor William Irwin Catholic Elementary School, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Monterey Park School, Calgary, (The Calgary School Division)",
    "Montessori Alberta, Calgary, (2128607 Alberta Ltd.)",
    "Montessori Children's House Academy Ltd #1, Calgary, (Montessori Children's House Charity Foundation of Calgary)",
    "Montessori Children's House Academy Ltd #2, Calgary, (Montessori Children's House Charity Foundation of Calgary)",
    "Montessori Children's House Academy Ltd #3, Calgary, (Montessori Children's House Charity Foundation of Calgary)",
    "Montessori School of Calgary, Calgary, (Montessori School of Calgary)",
    "Montrose School, Edmonton, (The Edmonton School Division)",
    "Morinville Christian School, Morinville, (The Father's House Christian Fellowship-Sturgeon County)",
    "Morinville Colony School, Sturgeon County, (The Sturgeon School Division )",
    "Morinville Community High School, Morinville, (The Greater St. Albert Roman Catholic Separate School Division)",
    "Morinville Learning Centre, Morinville, (The Sturgeon School Division )",
    "Morinville Public School, Morinville, (The Sturgeon School Division )",
    "Morley Community School, Morley, (Stoney Tribal Administration)",
    "Morrin School, Morrin, (The Prairie Land School Division)",
    "Mother Earth's Children's Charter School, Warburg, (Mother Earth's Children's Charter School Society)",
    "Mother Margaret Mary Catholic High School, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Mother Mary Greene School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Mother Teresa, Sylvan Lake, (The Red Deer Catholic Separate School Division)",
    "Mother Teresa Early Childhood Education Centre, Lloydminster, (Lloydminster Roman Catholic Separate School Division)",
    "Mother Teresa School, Grande Prairie, (The Grande Prairie Roman Catholic Separate School Division)",
    "Mother Teresa School, Medicine Hat, (The Medicine Hat Roman Catholic Separate School Division)",
    "Mount Pleasant School, Edmonton, (The Edmonton School Division)",
    "Mount Royal School, Edmonton, (The Edmonton School Division)",
    "Mount Royal School, Calgary, (The Calgary School Division)",
    "Mount View School, Calgary, (The Calgary School Division)",
    "Mountain Park School, Calgary, (The Calgary School Division)",
    "Mountain View Academy, Calgary, (Mountain View Academy Society)",
    "Mountain View School, Mountain View, (The Westwind School Division)",
    "Mountainview School, Strathmore, (The Golden Hills School Division)",
    "Mountview School, Red Deer, (The Red Deer School Division )",
    "Muir Lake School, Stony Plain, (The Parkland School Division)",
    "Mundare School, Mundare, (The Elk Island School Division)",
    "Muriel Clayton Middle School, Airdrie, (The Rocky View School Division)",
    "Muriel Martin Elementary School, St. Albert, (The St. Albert School Division)",
    "Myrnam Outreach Program, Myrnam, (The St. Paul School Division)",
    "Nakoda Elementary School, Morley, (Stoney Tribal Administration)",
    "Namao School, Sturgeon County, (The Sturgeon School Division )",
    "Nampa Public School, Nampa, (The Peace River School Division)",
    "Napi's Playground Elementary School, Brocket, (Peigan Board of Education Society)",
    "National Sport School, Calgary, (The Calgary School Division)",
    "Nebula Academy, Edmonton, (Nebula Foundation)",
    "Neerlandia Public Christian School, Neerlandia, (The Pembina Hills School Division)",
    "Neil M Ross Catholic School, St. Albert, (The Greater St. Albert Roman Catholic Separate School Division)",
    "Nellie Carlson School, Edmonton, (The Edmonton School Division)",
    "Nellie McClung School, Calgary, (The Calgary School Division)",
    "Nelson Heights Middle School, Cold Lake, (The Northern Lights School Division)",
    "Nelson Mandela High School, Calgary, (The Calgary School Division)",
    "Neu Muehl Colony School, Drumheller, (The Prairie Land School Division)",
    "Neuanlage School, Buffalo Head Prairie, (Buffalo Head Mennonite School Society)",
    "Neudorf Colony School, Crossfield, (The Chinook's Edge School Division)",
    "New Brigden School, New Brigden, (The Prairie Rose School Division)",
    "New Brigden School Portable ECS, Esther, (New Brigden Early Childhood Services Association)",
    "New Brighton School, Calgary, (The Calgary School Division)",
    "New Dale Colony School, Lethbridge, (The Palliser School Division)",
    "New Elm Colony School, Cardston, (The Westwind School Division)",
    "New Heights School and Learning Services, Calgary, (New Heights School and Learning Services Society)",
    "New Horizons School, Sherwood Park, (New Horizons Charter School Society)",
    "New Humble Centre School, Calmar, (The Black Gold School Division )",
    "New Myrnam School, Myrnam, (The St. Paul School Division)",
    "New Norway School, New Norway, (The Battle River School Division )",
    "New Pine Creek Colony, Boyle, (The Aspen View School Division)",
    "New Rockport Colony School, Cardston, (The Westwind School Division)",
    "New Sarepta Community High School, New Sarepta, (The Black Gold School Division )",
    "New Sarepta Elementary School, New Sarepta, (The Black Gold School Division )",
    "Newell Christian School, Brooks, (Newell Christian School Society)",
    "Newell Colony School, Bassano, (The Grasslands School Division)",
    "Newman Theological College, Edmonton, (The East Central Alberta Catholic Separate School Division)",
    "Nickle School, Calgary, (The Calgary School Division)",
    "Night Wind, Kihew House School, Rochester, (The Aspen View School Division)",
    "Night Wind, Maskwa House School, Rochester, (The Aspen View School Division)",
    "Niitsitapi Learning Centre, Calgary, (The Calgary School Division)",
    "Nikoodi Upgrading School, Glenevis, (Alexis Band)",
    "Nipisihkopahk Elementary School, Maskwacis, (Nipisihkopahk Education Authority)",
    "Nipisihkopahk Primary School, Maskwacis, (Nipisihkopahk Education Authority)",
    "Nipisihkopahk Middle School, Maskwacis, (Nipisihkopahk Education Authority)",
    "Nipisihkopahk Secondary School, Maskwacis, (Nipisihkopahk Education Authority)",
    "Niton Central School, Niton Junction, (The Grande Yellowhead School Division)",
    "Noble Central School, Nobleford, (The Palliser School Division)",
    "Normandeau School, Red Deer, (The Red Deer School Division )",
    "NorQuest College (Wetaskiwin), Wetaskiwin, (NorQuest College)",
    "NorQuest College Edmonton, Edmonton, (NorQuest College)",
    "Norquest College Tricentre, Fort Saskatchewan, (NorQuest College)",
    "North Campus ABC Program, Edmonton, (ABC Head Start Society)",
    "North Cottage High School, Red Deer, (The Red Deer School Division )",
    "North Haven School, Calgary, (The Calgary School Division)",
    "North Middle School Campus, Calgary, (Foundations for the Future Charter Academy Charter School Society)",
    "North Point School for Boys, Calgary, (North Point School for Boys)",
    "North Point School for Boys, , (North Point School for Boys)",
    "North Star Elementary School, Cold Lake, (The Northern Lights School Division)",
    "Northcott Prairie School, Airdrie, (The Rocky View School Division)",
    "Northeast Elementary Campus, Calgary, (Foundations for the Future Charter Academy Charter School Society)",
    "Northern Alberta Institute of Technology, Edmonton, (Other Schools)",
    "Northern Lights School, Spirit River, (Northern Lights School Education Foundation)",
    "Northern Lights School, , (The Calgary School Division)",
    "Northern Outreach Center, La Crete, (The Fort Vermilion School Division)",
    "Northmount School, Edmonton, (The Edmonton School Division)",
    "NorthStar Academy, Didsbury, (The Golden Hills School Division)",
    "Northstar Outreach, Tofield, (The Battle River School Division )",
    "Northwest Elementary Campus, Calgary, (Foundations for the Future Charter Academy Charter School Society)",
    "Norwood Child & Family Resource Centre, Edmonton, (Norwood Child & Family Resource Centre)",
    "Norwood Child and Family Services, Edmonton, (Norwood Child & Family Resource Centre)",
    "Norwood School, Wetaskiwin, (The Wetaskiwin School Division)",
    "Norwood School, Edmonton, (The Edmonton School Division)",
    "Nose Creek Elementary School, Airdrie, (The Rocky View School Division)",
    "Nose Creek School, Calgary, (The Calgary School Division)",
    "Notre Dame Academy, Medicine Hat, (The Medicine Hat Roman Catholic Separate School Division)",
    "Notre Dame Collegiate, High River, (The Christ the Redeemer Catholic Separate School Division)",
    "Notre Dame Elementary School, Morinville, (The Greater St. Albert Roman Catholic Separate School Division)",
    "Notre Dame Elementary School, Bonnyville, (The Lakeland Roman Catholic Separate School Division)",
    "Notre Dame High School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Notre-Dame de la Paix, Calgary, (The Southern Francophone Education Region)",
    "NSonline, Sherwood Park, (The Elk Island School Division)",
    "O K Colony School, Cardston, (The Westwind School Division)",
    "O.S. Geiger School, Calgary, (The Calgary School Division)",
    "O'Chiese School, Rocky Mountain House, (O'Chiese Education Authority)",
    "Oak Hill School, Bon Accord, (The Sturgeon School Division )",
    "Oaklane Colony School, Taber, (The Horizon School Division)",
    "Oaks and Acorns Montessori at Auburn Bay, Calgary, (Montessori Projects of Alberta Ltd.)",
    "Oaks and Acorns Montessori Kindergarten, Calgary, (Montessori Projects of Alberta Ltd.)",
    "Ochre Park School, Redwater, (The Sturgeon School Division )",
    "Off Campus Learning, Olds, (The Chinook's Edge School Division)",
    "Oilfields High School, Black Diamond, (The Foothills School Division)",
    "Okotoks Children's Montessori Academy, Okotoks, (Okotoks Children's Montessori Academy)",
    "Okotoks Home Schooling, Okotoks, (The Christ the Redeemer Catholic Separate School Division)",
    "Okotoks Junior High School, Okotoks, (The Foothills School Division)",
    "Okotoks Montessori Preschool, Okotoks, (Okotoks Montessori Preschool Society)",
    "Okotoks Montessori School, Okotoks, (Okotoks Montessori Preschool Society)",
    "Old Elm Colony School, Cardston, (The Westwind School Division)",
    "Old Scona School, Edmonton, (The Edmonton School Division)",
    "Old Sun Community College, Siksika, (The Old Sun Society)",
    "Olds Career High School, Olds, (The Chinook's Edge School Division)",
    "Olds Koinonia Christian School, Olds, (The Chinook's Edge School Division)",
    "Olds Mountain View Christian School, Olds, (Olds Mountain View Christian School Association)",
    "Oliver Centre, Edmonton, (Oliver Centre Early Learning Programs for Children & Families Society)",
    "Oliver School, Edmonton, (The Edmonton School Division)",
    "Olympic Heights School, Calgary, (The Calgary School Division)",
    "Onchaminahos School, Saddle Lake, (Saddle Lake Education Authority)",
    "One World Child Development Centre, Calgary, (Calgary Urban Project Society)",
    "Onoway Elementary School, Onoway, (The Northern Gateway School Division)",
    "Onoway Junior Senior High School, Onoway, (The Northern Gateway School Division)",
    "Opokaa'sin Early Intervention Society, Lethbridge, (Opokaa'sin Early Intervention Society)",
    "Oriole Park School, Red Deer, (The Red Deer School Division )",
    "Ormsby School, Edmonton, (The Edmonton School Division)",
    "Oscar Adolphson Primary School, Valleyview, (The Northern Gateway School Division)",
    "Oskayak Treatment Centre, Red Deer, (The Red Deer School Division )",
    "Ostland School, Buffalo Head Prairie, (Buffalo Head Mennonite School Society)",
    "Ottewell School, Edmonton, (The Edmonton School Division)",
    "Our Lady of Fatima, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Our Lady of Grace School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Our Lady of Lourdes, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Our Lady of Mount Carmel, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Our Lady of Mount Pleasant Catholic School, Camrose, (The Elk Island Catholic Separate School Division)",
    "Our Lady of Peace, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Our Lady of Peace Elementary Junior High School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Our Lady of Perpetual Help Catholic School, Sherwood Park, (The Elk Island Catholic Separate School Division)",
    "Our Lady of The Angels Catholic School, Fort Saskatchewan, (The Elk Island Catholic Separate School Division)",
    "Our Lady of The Assumption School, Lethbridge, (The Holy Spirit Roman Catholic Separate School Division)",
    "Our Lady of the Assumption School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Our Lady of the Evergreens Catholic School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Our Lady of The Prairies, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Our Lady of the Rosary School, Sylvan Lake, (The Red Deer Catholic Separate School Division)",
    "Our Lady of the Rosary School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Our Lady of the Snows Catholic Academy, Canmore, (The Christ the Redeemer Catholic Separate School Division)",
    "Our Lady of Victories, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Our Lady of Wisdom, Chestermere, (The Calgary Roman Catholic Separate School Division)",
    "Our Lady Queen of Peace Elementary School, Airdrie, (The Calgary Roman Catholic Separate School Division)",
    "Out-of-District Program, Edmonton, (The Edmonton School Division)",
    "Outreach School Centre, Red Deer, (The Red Deer School Division )",
    "Outreach West, Coleman, (The Livingstone Range School Division)",
    "Over the Rainbow Academy, Edmonton, (Over the Rainbow Academy)",
    "Overlanders School, Edmonton, (The Edmonton School Division)",
    "Oyen Public School, Oyen, (The Prairie Rose School Division)",
    "Pacekids Programs North, Calgary, (Pacekids Society for Kids with Special Needs)",
    "Pacekids Programs South, Calgary, (Pacekids Society for Kids with Special Needs)",
    "Paddle Prairie School, Paddle Prairie, (The Northland School Division)",
    "Pakan Elementary and Junior High School, Goodfish Lake, (Whitefish Lake Education Authority)",
    "Palliser Beyond Borders, Vulcan, (The Palliser School Division)",
    "Palliser Beyond Borders at Calgary, Calgary, (The Palliser School Division)",
    "Palliser Beyond Borders at Coaldale, Coaldale, (The Palliser School Division)",
    "Palliser Beyond Borders at Picture Butte, Picture Butte, (The Palliser School Division)",
    "Palliser Beyond Borders at Vulcan, Vulcan, (The Palliser School Division)",
    "Palliser Home Learning School, Lethbridge, (The Palliser School Division)",
    "Panorama Hills School, Calgary, (The Calgary School Division)",
    "Park Meadows School, Lethbridge, (The Lethbridge School Division)",
    "Parkallen School, Edmonton, (The Edmonton School Division)",
    "Parkdale School, Wetaskiwin, (The Wetaskiwin School Division)",
    "Parkland Colony School, Fort Macleod, (The Livingstone Range School Division)",
    "Parkland Composite High School, Edson, (The Grande Yellowhead School Division)",
    "Parkland Immanuel Christian School, Edmonton, (Canadian Reformed School Society of Edmonton)",
    "Parkland School Special Education, Red Deer, (Parkland Community Living and Supports Society)",
    "Parkland Village School, Spruce Grove, (The Parkland School Division)",
    "Parkland Youth Homes, Red Deer, (The Red Deer School Division )",
    "Parkside Montessori, Grande Prairie, (The Grande Prairie School Division)",
    "Parkside School, Redcliff, (The Prairie Rose School Division)",
    "Parkview Adventist Academy, Lacombe, (Burman University)",
    "Parkview School, Edmonton, (The Edmonton School Division)",
    "Partners for Youth, Edmonton, (The Edmonton Catholic Separate School Division)",
    "PAS, Medicine Hat, (The Medicine Hat School Division)",
    "Pat Hardy Primary School, Whitecourt, (The Northern Gateway School Division)",
    "Pathway Montessori Inc, Calgary, (Woodpark Montessori Society)",
    "Patricia Heights School, Edmonton, (The Edmonton School Division)",
    "Patrick Airlie School, Calgary, (The Calgary School Division)",
    "Paul Band First Nation School, Duffield, (Paul Band Education Authority)",
    "Paul Kane Continuing Education, St. Albert, (The St. Albert School Division)",
    "Paul Kane High School, St. Albert, (The St. Albert School Division)",
    "Paul Rowe Junior Senior High School, Manning, (The Peace River School Division)",
    "Peace Academy of Virtual Education, Spirit River, (The Peace Wapiti School Division)",
    "Peace Hills Adventist School, Wetaskiwin, (Alberta Conference of 7th Day Adventist Church)",
    "Peace Mennonite School, La Crete, (Peace Mennonite School)",
    "Peace Regional Outreach Campus, Peace River, (The Peace River School Division)",
    "Peace River Correctional Centre, Peace River, (Northern Lakes College)",
    "Peace River High School, Peace River, (The Peace River School Division)",
    "Peace Wapiti Academy, Grande Prairie, (The Peace Wapiti School Division)",
    "Peace Wapiti Enterprise Centre, Grande Prairie, (The Peace Wapiti School Division)",
    "Peace Wapiti Outreach Program, Spirit River, (The Peace Wapiti School Division)",
    "Peerless Lake School, Peerless Lake, (Kee Tas Kee Now Tribal Council Education Authority)",
    "Pelican Mountain School, Wabasca, (The Northland School Division)",
    "Pembina Ed Cons - Rocky Mtn, Rocky Mountain House, (Other Schools)",
    "Pembina Ed Cons - Whitecourt, Whitecourt, (Other Schools)",
    "Pembina Ed Cons-Drayton Valley, Drayton Valley, (NorQuest College)",
    "Pembina North Community School, Dapp, (The Pembina Hills School Division)",
    "Pembina Valley Christian School, Westlock, (Church of God in Christ Mennonite Pembina Valley Congregation)",
    "Penbrooke Meadows School, Calgary, (The Calgary School Division)",
    "Penhold Crossing Secondary School, Penhold, (The Chinook's Edge School Division)",
    "Penhold Elementary School, Penhold, (The Chinook's Edge School Division)",
    "Penson School, Grovedale, (The Peace Wapiti School Division)",
    "Percy Baxter Middle School, Whitecourt, (The Northern Gateway School Division)",
    "Percy Lake Colony School, Minburn, (The Buffalo Trail School Division)",
    "Percy Pegler Elementary School, Okotoks, (The Foothills School Division)",
    "Personalized Learning Activities and Community Education, Edmonton, (Centre for Autism Services Alberta)",
    "Peter Lougheed School, Calgary, (The Calgary School Division)",
    "Phoenix Academy, Edmonton, (Unlimited Potential Community Services Society)",
    "Phoenix Education Foundation, Calgary, (Phoenix Education Foundation)",
    "Pibroch Colony School, Dapp, (The Pembina Hills School Division)",
    "Picture Butte High School, Picture Butte, (The Palliser School Division)",
    "Pigeon Lake Outreach, Ma-Me-O Beach, (The Wetaskiwin School Division)",
    "Pigeon Lake Regional, Falun, (The Wetaskiwin School Division)",
    "Piikani Nation Secondary School, Brocket, (Peigan Board of Education Society)",
    "Piitoayis Family School, Calgary, (The Calgary School Division)",
    "Pincher Creek Sunset Colony, Fort Macleod, (The Livingstone Range School Division)",
    "Pine Haven School, Wetaskiwin, (The Wetaskiwin School Division)",
    "Pine Hill Colony School, Red Deer, (The Chinook's Edge School Division)",
    "Pine Meadow Hutterite School, Glendon, (The Northern Lights School Division)",
    "Pine Street School, Sherwood Park, (The Elk Island School Division)",
    "Pineridge School, Calgary, (The Calgary School Division)",
    "Pioneer School, Rocky Mountain House, (The Wild Rose School Division )",
    "Pipestone School, Millet, (The Wetaskiwin School Division)",
    "Pitawani School, Lethbridge, (The Lethbridge School Division)",
    "Plain Lake Colony School, Two Hills, (The St. Paul School Division)",
    "Plainview Colony School, Foremost, (The Prairie Rose School Division)",
    "Playcare ECS, Calgary, (Playcare ECS Ltd.)",
    "Pleasant Ridge Colony School, Vegreville, (The Elk Island School Division)",
    "Pleasant Valley Colony School, Clive, (The Wolf Creek School Division)",
    "Polish Sienkiewicz School, Edmonton, (H. Sienkiewicz Polish School Parent Association)",
    "Pollard Meadows School, Edmonton, (The Edmonton School Division)",
    "Ponderosa Colony School, Bow Island, (The Prairie Rose School Division)",
    "Ponoka Christian School, Ponoka, (Ponoka Christian School Society)",
    "Ponoka Elementary School, Ponoka, (The Wolf Creek School Division)",
    "Ponoka Secondary Campus, Ponoka, (The Wolf Creek School Division)",
    "Poplar Ridge School, Red Deer County, (The Chinook's Edge School Division)",
    "Poplar Row School, Strathmore, (The Golden Hills School Division)",
    "Portage College - St. Paul Campus, St. Paul, (Portage College)",
    "Portage College Lac La Biche, Lac La Biche, (Portage College)",
    "Portage College-Cold Lake Campus, Cold Lake, (Portage College)",
    "Prairie Adventist Christian eSchool, Lacombe, (Alberta Conference of 7th Day Adventist Church)",
    "Prairie Christian Academy, Three Hills, (The Golden Hills School Division)",
    "Prairie Land Outreach School, Hanna, (The Prairie Land School Division)",
    "Prairie Mennonite Alternative School, Medicine Hat, (The Prairie Rose School Division)",
    "Prairie River Junior High School, High Prairie, (The High Prairie School Division)",
    "Prairie Rose Regional Division Home Education, Medicine Hat, (The Prairie Rose School Division)",
    "Prairie View Colony School, Oyen, (The Prairie Rose School Division)",
    "Prairie View Outreach School, High Prairie, (The High Prairie School Division)",
    "Prairie Waters Elementary School, Chestermere, (The Rocky View School Division)",
    "Prairiehome Colony School, Taber, (The Horizon School Division)",
    "Prescott Learning Centre, Spruce Grove, (The Parkland School Division)",
    "Prince Charles School, Edmonton, (The Edmonton School Division)",
    "Prince of Peace Lutheran School, Calgary, (The Rocky View School Division)",
    "Prince of Peace School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Prince of Wales School, Calgary, (The Calgary School Division)",
    "Princeton School, Edmonton, (The Edmonton School Division)",
    "Private Study, Edmonton, (Other Schools)",
    "Progressive Academy, Edmonton, (Progressive Academy Education Society)",
    "Project Trust, Calgary, (The Calgary School Division)",
    "Providence Children's Centre, Calgary, (Providence Child Development Society)",
    "Providence Children's Centre - Beddington, Calgary, (Providence Child Development Society)",
    "Providence Children's Centre - Falconridge, Calgary, (Providence Child Development Society)",
    "Providence Children's Centre - Hawkwood, Calgary, (Providence Child Development Society)",
    "Providence Children's Centre - McKenzie Towne, Calgary, (Providence Child Development Society)",
    "Providence Children's Centre - Midnapore, Calgary, (Providence Child Development Society)",
    "Providence Christian School, Monarch, (Providence Christian School Society)",
    "Provost Public School, Provost, (The Buffalo Trail School Division)",
    "Queen Alexandra School, Edmonton, (The Edmonton School Division)",
    "Queen Elizabeth, Lloydminster, (Lloydminster Public School Division)",
    "Queen Elizabeth High School, Calgary, (The Calgary School Division)",
    "Queen Elizabeth School, Edmonton, (The Edmonton School Division)",
    "Queen Elizabeth School, Calgary, (The Calgary School Division)",
    "Quest High School, Red Deer, (The Red Deer School Division )",
    "R. I. Baker Middle School, Coaldale, (The Palliser School Division)",
    "R. J. Hawkey Elementary School, Airdrie, (The Rocky View School Division)",
    "R. T. Alderman School, Calgary, (The Calgary School Division)",
    "Racette Junior High School, St. Paul, (The St. Paul School Division)",
    "Radisson Park School, Calgary, (The Calgary School Division)",
    "Rainbow Colony School, Red Deer County, (The Chinook's Edge School Division)",
    "Rainbow Creek Elementary School, Chestermere, (The Rocky View School Division)",
    "Rainbow Lake Learning Store, Rainbow Lake, (The Fort Vermilion School Division)",
    "Rainbow Lake School, Rainbow Lake, (The Fort Vermilion School Division)",
    "Ralph McCall School, Airdrie, (The Rocky View School Division)",
    "Ralston School, Ralston, (The Prairie Rose School Division)",
    "Ramsay School, Calgary, (The Calgary School Division)",
    "RancheView School, Cochrane, (The Rocky View School Division)",
    "Ranchlands Montessori Children's Centre, Calgary, (Montessori Education Preschool & ECS Institute of Alberta)",
    "Ranchlands School, Calgary, (The Calgary School Division)",
    "Raymond Alternate School, Raymond, (The Westwind School Division)",
    "Raymond Elementary School, Raymond, (The Westwind School Division)",
    "Raymond High School, Raymond, (The Westwind School Division)",
    "Raymond Junior High School, Raymond, (The Westwind School Division)",
    "REAL McMan, Medicine Hat, (The Medicine Hat School Division)",
    "Red Crow Community College, Cardston, (Red Crow Community College)",
    "Red Deer College, Red Deer, (Red Deer College GED Writing Centre)",
    "Red Deer College Children Care Center, Red Deer, (Red Deer College)",
    "Red Deer Lake School, Calgary, (The Foothills School Division)",
    "Red Deer Regional Hospital Education Program, Red Deer, (The Red Deer School Division )",
    "Red Deer School District Home Education, Red Deer, (The Red Deer School Division )",
    "Red Earth Creek School, Red Earth Creek, (The Peace River School Division)",
    "Redcliff Mennonite Alternative Program, Redcliff, (The Prairie Rose School Division)",
    "Redwater School, Redwater, (The Sturgeon School Division )",
    "Reed Ranch School, Olds, (The Chinook's Edge School Division)",
    "Rendell Park, Lloydminster, (Lloydminster Public School Division)",
    "Renert School, Calgary, (The Renert School Foundation)",
    "Renfrew Educational Services - Child Development Centre, Calgary, (Renfrew Educational Services Society)",
    "Renfrew Educational Services - Park Place Centre, Calgary, (Renfrew Educational Services Society)",
    "Renfrew Educational Services-Bowness Centre, Calgary, (Renfrew Educational Services Society)",
    "Renfrew Educational Services-Janice McTighe Centre, Calgary, (Renfrew Educational Services Society)",
    "Renfrew Thomas W. Buchanan Centre, Calgary, (Renfrew Educational Services Society)",
    "Revelation On-Line, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Ribstone Colony School, Edgerton, (The Buffalo Trail School Division)",
    "Rich Valley School, Gunn, (The Northern Gateway School Division)",
    "Richard F Staples Secondary School, Westlock, (The Pembina Hills School Division)",
    "Richard S Fowler Catholic Junior High School, St. Albert, (The Greater St. Albert Roman Catholic Separate School Division)",
    "Richard Secord School, Edmonton, (The Edmonton School Division)",
    "Richmond School, Calgary, (The Calgary School Division)",
    "Rideau Park School, Edmonton, (The Edmonton School Division)",
    "Rideau Park School, Calgary, (The Calgary School Division)",
    "Ridgevalley School, Crooked Creek, (The Peace Wapiti School Division)",
    "Ridgeview Central School, La Crete, (The Fort Vermilion School Division)",
    "Right Honorable Joe Clark School, High River, (The Foothills School Division)",
    "Rimbey Christian School, Rimbey, (The Rimbey Christian School Society)",
    "Rimbey Elementary School, Rimbey, (The Wolf Creek School Division)",
    "Rimbey Junior Senior High School, Rimbey, (The Wolf Creek School Division)",
    "Rio Terrace Elementary School, Edmonton, (The Edmonton School Division)",
    "Rising Sun School, Strathmore, (The Golden Hills School Division)",
    "River Heights Elementary, Medicine Hat, (The Medicine Hat School Division)",
    "River Road Colony School, Taber, (The Horizon School Division)",
    "River Valley School, Sundre, (The Chinook's Edge School Division)",
    "River Valley School, Calgary, (River Valley School Society)",
    "River Valley School ECS, Calgary, (River Valley School Society)",
    "Riverbend Colony School, Lethbridge, (The Palliser School Division)",
    "Riverbend School, Edmonton, (The Edmonton School Division)",
    "Riverbend School, Calgary, (The Calgary School Division)",
    "Riverdale School, Edmonton, (The Edmonton School Division)",
    "Riverside Colony School, Cardston, (The Westwind School Division)",
    "Riverstone Public School, Grande Prairie, (The Grande Prairie School Division)",
    "Riverview Middle School, Devon, (The Black Gold School Division )",
    "Robert Rundle Elementary, St. Albert, (The St. Albert School Division)",
    "Robert Thirsk School, Calgary, (The Calgary School Division)",
    "Robert W. Zahara Public School, Sexsmith, (The Peace Wapiti School Division)",
    "Robert Warren School, Calgary, (The Calgary School Division)",
    "Roberta MacAdams School, , (The Edmonton School Division)",
    "Robina Baker Elementary School, Devon, (The Black Gold School Division )",
    "Rochester School, Rochester, (The Aspen View School Division)",
    "Rochfort Bridge Colony School, Mayerthorpe, (The Northern Gateway School Division)",
    "Rock Lake Colony School, Lethbridge, (The Palliser School Division)",
    "Rockport Colony School, Cardston, (The Westwind School Division)",
    "Rocky Christian School, Rocky Mountain House, (The Wild Rose School Division )",
    "Rocky Community Learning Centre, Rocky Mountain House, (Rocky Community Learning Centre)",
    "Rocky Lane School, Fort Vermilion, (The Fort Vermilion School Division)",
    "Rocky View Schools Online, Chestermere, (The Rocky View School Division)",
    "Rockyview Christian School, Pincher Creek, (Rocky View Mennonite Church)",
    "Roland Little Poplar Learning Center, Maskwacis, (The Government of Canada as represented by Correctional Service Canada)",
    "Roland Michener School, Calgary, (The Calgary School Division)",
    "Roland Michener Secondary School, Slave Lake, (The High Prairie School Division)",
    "Rolling Hills School, Rolling Hills, (The Grasslands School Division)",
    "Ron Southern School, Calgary, (The Calgary School Division)",
    "Ronald Harvey Elementary, St. Albert, (The St. Albert School Division)",
    "Rosalind Colony School, Camrose, (The Battle River School Division )",
    "Rosary Roman Catholic Separate School, Manning, (The Holy Family Catholic Separate School Division)",
    "Rosebud Creek School, Strathmore, (The Golden Hills School Division)",
    "Rosebud River School, Strathmore, (The Golden Hills School Division)",
    "Rosecrest School, Edmonton, (The Edmonton School Division)",
    "Rosedale Christian School, Crooked Creek, (Church of God in Christ Mennonite Rosedale Congregation)",
    "Rosedale Colony School, Dunmore, (The Prairie Rose School Division)",
    "Rosedale School, Calgary, (The Calgary School Division)",
    "Roseglen Hutterian Church School, Hilda, (The Prairie Rose School Division)",
    "Rosemary School, Rosemary, (The Grasslands School Division)",
    "Rosemont School, Calgary, (The Calgary School Division)",
    "Rosenfeld School, Buffalo Head Prairie, (Buffalo Head Mennonite School Society)",
    "Rosewood Colony School, Provost, (The Buffalo Trail School Division)",
    "Ross Ford Elementary School, Didsbury, (The Chinook's Edge School Division)",
    "Ross Glen School, Medicine Hat, (The Medicine Hat School Division)",
    "Ross Sheppard School, Edmonton, (The Edmonton School Division)",
    "Rosscarrock School, Calgary, (The Calgary School Division)",
    "Rosslyn School, Edmonton, (The Edmonton School Division)",
    "Round Hill School, Round Hill, (The Battle River School Division )",
    "Routhier School, Falher, (The High Prairie School Division)",
    "Roy Bickell Public School, Grande Prairie, (The Grande Prairie School Division)",
    "Royal Oak School, Calgary, (The Calgary School Division)",
    "Rudolph Hennig Junior High School, Fort Saskatchewan, (The Elk Island School Division)",
    "Rundle College Academy, Calgary, (Rundle College Society)",
    "Rundle College Elementary School, Calgary, (Rundle College Society)",
    "Rundle College Junior High School, Calgary, (Rundle College Society)",
    "Rundle College Senior High School, Calgary, (Rundle College Society)",
    "Rundle School, Calgary, (The Calgary School Division)",
    "Rural Futures, Medcine Hat, (The Prairie Rose School Division)",
    "Russian Education Centre, Edmonton, (Alberta Interscience Association)",
    "Rutherford School, Edmonton, (The Edmonton School Division)",
    "RVS Online Grades 1-8, Virtual, (The Rocky View School Division)",
    "RVS Online Grades 9-12, Virtual, (The Rocky View School Division)",
    "Rycroft School, Rycroft, (The Peace Wapiti School Division)",
    "Ryley School, Ryley, (The Battle River School Division )",
    "S Bruce Smith School, Edmonton, (The Edmonton School Division)",
    "Saamis REAL Outreach, Medicine Hat, (The Medicine Hat School Division)",
    "Sacred Heart Academy, Strathmore, (The Christ the Redeemer Catholic Separate School Division)",
    "Sacred Heart Elementary School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Sacred Heart School, Wetaskiwin, (The St. Thomas Aquinas Roman Catholic Separate School Division )",
    "Saddle Ridge School, Calgary, (The Calgary School Division)",
    "Sainte Marguerite Bourgeoys, Calgary, (The Southern Francophone Education Region)",
    "Saipoyi Community School, Stand Off, (Kainai Board of Education)",
    "Sakaw School, Edmonton, (The Edmonton School Division)",
    "Salisbury Composite (Evening), Sherwood Park, (The Elk Island School Division)",
    "Salisbury Composite High School, Sherwood Park, (The Elk Island School Division)",
    "Sam Livingston School, Calgary, (The Calgary School Division)",
    "Samson Cree Nation Primary School, Maskwacis, (Nipisihkopahk Education Authority)",
    "Samuel W. Shaw School, Calgary, (The Calgary School Division)",
    "Sanctuary Outreach, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Sand Hills Elementary School, La Crete, (The Fort Vermilion School Division)",
    "Sandhills School, Strathmore, (The Golden Hills School Division)",
    "Sangudo Community School, Sangudo, (The Northern Gateway School Division)",
    "Sarah Thompson School, Langdon, (The Rocky View School Division)",
    "Satoo School, Edmonton, (The Edmonton School Division)",
    "Savanna School, Silver Valley, (The Peace Wapiti School Division)",
    "Sawenegezhik Academy, Saddle Lake, (Saddle Lake Indian Full Gospel School Mission)",
    "Sayre School, Strathmore, (The Golden Hills School Division)",
    "Scenic Acres School, Calgary, (The Calgary School Division)",
    "School of East Indian Languages and Performing Arts, Calgary, (School of East Indian Languages and Performing Arts of Calgary)",
    "School of Hope, Vermilion, (The East Central Alberta Catholic Separate School Division)",
    "School Within a College, Red Deer, (The Red Deer School Division )",
    "Schuler School, Schuler, (The Prairie Rose School Division)",
    "Schuler School Early Childhood Services, Schuler, (The Schuler Early Childhood Service)",
    "Scott Robertson School, Edmonton, (The Edmonton School Division)",
    "Seba Beach School, Seba Beach, (The Parkland School Division)",
    "Seden Centre for Child Development and Wellness, Sherwood Park, (Seden Centre for Child Development and Wellness)",
    "Sedgewick ECS Portable, Sedgewick, (Sedgewick Early Childhood Centre)",
    "Senator Buchanan Elementary School, Lethbridge, (The Lethbridge School Division)",
    "Senator Gershaw School, Bow Island, (The Prairie Rose School Division)",
    "Senator Joyce Fairbairn Middle School, Lethbridge, (The Lethbridge School Division)",
    "Senator Patrick Burns School, Calgary, (The Calgary School Division)",
    "Senator Riley School, High River, (The Foothills School Division)",
    "Seven Persons School, Seven Persons, (The Prairie Rose School Division)",
    "Sexsmith Secondary School, Sexsmith, (The Peace Wapiti School Division)",
    "Shadow Ranch Colony School, Lethbridge, (The Palliser School Division)",
    "Shady Lane Colony School, Wanham, (The Peace Wapiti School Division)",
    "Shamrock Colony School, Bow Island, (The Prairie Rose School Division)",
    "Shauna May Seneca School, Edmonton, (The Edmonton School Division)",
    "Sheldon Coates Elementary School, Grande Cache, (The Grande Yellowhead School Division)",
    "Sherwood Heights Junior High School, Sherwood Park, (The Elk Island School Division)",
    "Sherwood Park Kindergarten, Sherwood Park, (Sherwood Park Kindergarten)",
    "Sherwood Park Next Step I Outreach School, Sherwood Park, (The Elk Island School Division)",
    "Sherwood School, Edmonton, (The Edmonton School Division)",
    "Sherwood School, Calgary, (The Calgary School Division)",
    "Sibylla Kiddle School, , (The Calgary School Division)",
    "Sifton School, Camrose, (The Battle River School Division )",
    "Sifton School, Edmonton, (The Edmonton School Division)",
    "Siksika Nation High School, Siksika, (Siksika Board of Education)",
    "Siksika Storefront School, Siksika, (Siksika Board of Education)",
    "Silver Creek School, Ferintosh, (The Wetaskiwin School Division)",
    "Silver Sage Colony School, Dunmore, (The Prairie Rose School Division)",
    "Silver Spring Colony School, Stettler, (The Clearview School Division)",
    "Silver Springs School, Calgary, (The Calgary School Division)",
    "Silver Valley Colony School, Bonanza, (The Peace Wapiti School Division)",
    "Simon Fraser School, Calgary, (The Calgary School Division)",
    "Simons Valley School, Calgary, (The Calgary School Division)",
    "Sir Alexander Mackenzie School, St. Albert, (The St. Albert School Division)",
    "Sir George Simpson Junior High, St. Albert, (The St. Albert School Division)",
    "Sir John A Macdonald School, Calgary, (The Calgary School Division)",
    "Sir John Franklin School, Calgary, (The Calgary School Division)",
    "Sir John Thompson, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Sir Wilfrid Laurier School, Calgary, (The Calgary School Division)",
    "Sir Winston Churchill High School, Calgary, (The Calgary School Division)",
    "Sister Alphonse Academy, St. Albert, (The Greater St. Albert Roman Catholic Separate School Division)",
    "Sister Annata Brockman Catholic Elementary/Junior High School, Edmonton, (The Edmonton Catholic Separate School Division)",
    "Sister Gloria School, Garden River, (Little Red River Board of Education)",
    "Sister Mary Phillps Elementary School, Fort McMurray, (The Fort McMurray Roman Catholic Separate School Division)",
    "Skylight Colony School, Lethbridge, (The Palliser School Division)",
    "Slave Lake Koinonia Christian, Slave Lake, (Slave Lake Koinonia Christian School Society)",
    "Smith School, Smith, (The Aspen View School Division)",
    "Society for Treatment of Autism, Calgary, (Society For Treatment of Autism (Calgary Region))",
    "Solomon College, Edmonton, (Solomon College)",
    "Somerset School, Calgary, (The Calgary School Division)",
    "Soraya Hafez School, Edmonton, (The Edmonton School Division)",
    "South Central High School, Oyen, (The Prairie Rose School Division)",
    "South Ferriby Colony School, Marwayne, (The Buffalo Trail School Division)",
    "South Middle School Campus, Calgary, (Foundations for the Future Charter Academy Charter School Society)",
    "South Side Christian School, Red Deer, (Alberta Conference of 7th Day Adventist Church)",
    "Southbend Colony School, Camrose, (The Battle River School Division )",
    "Southeast Elementary Campus, Calgary, (Foundations for the Future Charter Academy Charter School Society)",
    "Southern Alberta Institute of Technology, Calgary, (Other Schools)",
    "SouthPointe School, Fort Saskatchewan, (The Elk Island School Division)",
    "Southview Child Care, Edmonton, (The First Mennonite Church)",
    "Southview Community School, Medicine Hat, (The Medicine Hat School Division)",
    "Southwest Elementary Campus, Calgary, (Foundations for the Future Charter Academy Charter School Society)",
    "Sparling School, Camrose, (The Battle River School Division )",
    "Special Centre, Edmonton, (Other Schools)",
    "Spirit of the North Community School, High Level, (The Fort Vermilion School Division)",
    "Spirit River Regional Academy, Spirit River, (The Peace Wapiti School Division)",
    "Spitzee Elementary School, High River, (The Foothills School Division)",
    "Spring Glen Elementary School, Glenwood, (The Westwind School Division)",
    "Spring Glen Junior High School, Hill Spring, (The Westwind School Division)",
    "Spring Point Colony School, Fort Macleod, (The Livingstone Range School Division)",
    "Spring Valley Colony School, Cardston, (The Westwind School Division)",
    "Springbank Community High School, Calgary, (The Rocky View School Division)",
    "Springbank Middle School, Calgary, (The Rocky View School Division)",
    "Springfield Elementary School, Peace River, (The Peace River School Division)",
    "Springridge Colony School, Lethbridge, (The Palliser School Division)",
    "Springside Colony School, Rosemary, (The Grasslands School Division)",
    "Springview Colony School, Gem, (The Grasslands School Division)",
    "Spruce Avenue School, Edmonton, (The Edmonton School Division)",
    "Spruce Grove Composite High School, Spruce Grove, (The Parkland School Division)",
    "Spruce Grove Composite High School Outreach, Spruce Grove, (The Parkland School Division)",
    "Spruce View School, Spruce View, (The Chinook's Edge School Division)",
    "St. Albert Catholic High School, St. Albert, (The Greater St. Albert Roman Catholic Separate School Division)",
    "St. Albert Public Home Education, St. Albert, (The St. Albert School Division)",
    "St. Albert Public Outreach, St. Albert, (The St. Albert School Division)",
    "St. Albert Public Schools Preschool, St. Albert, (The St. Albert School Division)",
    "St. Albert the Great, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Alphonsus, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Alphonsus Elementary Junior High School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Ambrose School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Andr‚ Bessette Catholic High School, Fort Saskatchewan, (The Elk Island Catholic Separate School Division)",
    "St. Andrew School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Andrew's School, High Prairie, (The Holy Family Catholic Separate School Division)",
    "St. Angela, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Angela Elementary, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Anne, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Anne Academic Centre, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Anne School, Fort McMurray, (The Fort McMurray Roman Catholic Separate School Division)",
    "St. Anthony, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Anthony School, Drayton Valley, (The St. Thomas Aquinas Roman Catholic Separate School Division )",
    "St. Anthony's School, Drumheller, (The Christ the Redeemer Catholic Separate School Division)",
    "St. Augustine, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Augustine Elementary Junior High School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Augustine School, Ponoka, (The St. Thomas Aquinas Roman Catholic Separate School Division )",
    "St. Basil Elementary/Junior High School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Basil School, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Bede Elementary School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Benedict, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Benedict School, Leduc, (The St. Thomas Aquinas Roman Catholic Separate School Division )",
    "St. Benedict School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Bernadette, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Bernadette School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Bonaventure, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Bonaventure School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Boniface, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Boniface Elementary School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Brendan School, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Brigid, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Catherine, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Catherine Catholic School, Grande Prairie, (The Grande Prairie Roman Catholic Separate School Division)",
    "St. Catherine Elementary School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Catherine's School, Picture Butte, (The Holy Spirit Roman Catholic Separate School Division)",
    "St. Cecilia, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Cecilia (Bilingual) Elementary School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Charles, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Clare, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Clement, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Clement Catholic School, Grande Prairie, (The Grande Prairie Roman Catholic Separate School Division)",
    "St. Cyril School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Damien School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. David's United Church, Calgary, (The East Central Alberta Catholic Separate School Division)",
    "St. Dominic, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Dominic High School, Rocky Mountain House, (The Red Deer Catholic Separate School Division)",
    "St. Dominic School, Cold Lake, (The Lakeland Roman Catholic Separate School Division)",
    "St. Dominic School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Edmund, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Elizabeth, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Elizabeth Seton, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Elizabeth Seton, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Elizabeth Seton School, Red Deer, (The Red Deer Catholic Separate School Division)",
    "St. Francis High School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Francis Holistic Learning Centre, High Prairie, (The Holy Family Catholic Separate School Division)",
    "St. Francis Junior High School, Lethbridge, (The Holy Spirit Roman Catholic Separate School Division)",
    "St. Francis of Assisi, Red Deer, (The Red Deer Catholic Separate School Division)",
    "St. Francis of Assisi, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Francis of Assisi Academy, De Winton, (The Christ the Redeemer Catholic Separate School Division)",
    "St. Francis of Assisi Catholic Academy, Slave Lake, (The Living Waters Catholic Separate School Division)",
    "St. Francis Xavier, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Francis Xavier School, Medicine Hat, (The Medicine Hat Roman Catholic Separate School Division)",
    "St. Gabriel, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Gabriel Cyber School, St. Albert, (The Greater St. Albert Roman Catholic Separate School Division)",
    "St. Gabriel High School, St. Albert, (The Greater St. Albert Roman Catholic Separate School Division)",
    "St. Gabriel Online School, Red Deer, (The Red Deer Catholic Separate School Division)",
    "St. Gabriel Online Summer School, Red Deer, (The Red Deer Catholic Separate School Division)",
    "St. Gabriel School, Fort McMurray, (The Fort McMurray Roman Catholic Separate School Division)",
    "St. Gabriel the Archangel, Chestermere, (The Calgary Roman Catholic Separate School Division)",
    "St. George's Hellenic Language School, Edmonton, (Greek Orthodox Community of Edmonton and District Society)",
    "St. Gerard, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Gerard Bilingual School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Gregory Catholic School, Hinton, (The Evergreen Catholic Separate School Division)",
    "St. Gregory School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Gregory The Great Catholic School, Blackfalds, (The Red Deer Catholic Separate School Division)",
    "St. Helena School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Henry Elementary School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Hilda, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Hubert Elementary School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Isabella School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Isidore Learning Centre, Sherwood Park, (The Elk Island Catholic Separate School Division)",
    "St. James Elementary Junior High School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Jean Brebeuf School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Jerome, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Jerome Elementary School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Jerome's School, Vermilion, (The East Central Alberta Catholic Separate School Division)",
    "St. Joan of Arc Elementary/Junior High School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. John Bosco, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. John Bosco Catholic School, Grande Prairie, (The Grande Prairie Roman Catholic Separate School Division)",
    "St. John Henry Newman School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. John Paul II Catholic Outreach School, Red Deer, (The Red Deer Catholic Separate School Division)",
    "St. John Paul II Catholic School, Fort Saskatchewan, (The Elk Island Catholic Separate School Division)",
    "St. John Paul II Catholic School, Stony Plain, (The Evergreen Catholic Separate School Division)",
    "St. John Paul II Catholic School / Facult‚ St. Jean-Paul II, Grande Prairie, (The Grande Prairie Roman Catholic Separate School Division)",
    "St. John Paul II Collegiate, Okotoks, (The Christ the Redeemer Catholic Separate School Division)",
    "St. John Paul II School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. John XXIII Catholic School, Fort Saskatchewan, (The Elk Island Catholic Separate School Division)",
    "St. John XXIII School, , (The Edmonton Catholic Separate School Division)",
    "St. John XXIII School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Joseph, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Joseph Catholic High School, Grande Prairie, (The Grande Prairie Roman Catholic Separate School Division)",
    "St. Joseph Catholic School, Spruce Grove, (The Evergreen Catholic Separate School Division)",
    "St. Joseph Elementary Junior High School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Joseph High School, Red Deer, (The Red Deer Catholic Separate School Division)",
    "St. Joseph's Collegiate, Brooks, (The Christ the Redeemer Catholic Separate School Division)",
    "St. Joseph's Elementary School, Lloydminster, (Lloydminster Roman Catholic Separate School Division)",
    "St. Joseph's School, Coaldale, (The Holy Spirit Roman Catholic Separate School Division)",
    "St. Jude School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Justin, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Kateri Catholic School, Grande Prairie, (The Grande Prairie Roman Catholic Separate School Division)",
    "St. Kateri Catholic School, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Kateri School, Fort McMurray, (The Fort McMurray Roman Catholic Separate School Division)",
    "St. Kateri Tekakwitha School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Leo, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Louis School, Medicine Hat, (The Medicine Hat Roman Catholic Separate School Division)",
    "St. Lucy, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Luke Bilingual Elementary School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Luke Catholic School, Sherwood Park, (The Elk Island Catholic Separate School Division)",
    "St. Luke's Outreach Centre Brooks, Brooks, (The Christ the Redeemer Catholic Separate School Division)",
    "St. Luke's Outreach Centre Drumheller, Drumheller, (The Christ the Redeemer Catholic Separate School Division)",
    "St. Luke's Outreach Centre High River, High River, (The Christ the Redeemer Catholic Separate School Division)",
    "St. Luke's Outreach Centre Okotoks, Okotoks, (The Christ the Redeemer Catholic Separate School Division)",
    "St. Margaret School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Marguerite Bourgeoys, Innisfail, (The Red Deer Catholic Separate School Division)",
    "St. Marguerite Catholic School, Spruce Grove, (The Evergreen Catholic Separate School Division)",
    "St. Marguerite School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Maria Goretti, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Maria Goretti Elementary School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Mark, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Mark Elementary School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Martha, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Martha Catholic School, Fort McMurray, (The Fort McMurray Roman Catholic Separate School Division)",
    "St. Martha School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Martin, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Martin De Porres High School, Airdrie, (The Calgary Roman Catholic Separate School Division)",
    "St. Martin De Porres School, Red Deer, (The Red Deer Catholic Separate School Division)",
    "St. Martin's Catholic School, Vegreville, (The Elk Island Catholic Separate School Division)",
    "St. Mary, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Mary Catholic School, Beaverlodge, (The Grande Prairie Roman Catholic Separate School Division)",
    "St. Mary Catholic School, Westlock, (The Evergreen Catholic Separate School Division)",
    "St. Mary of the Lake, Slave Lake, (The Living Waters Catholic Separate School Division)",
    "St. Mary's Catholic High School, Vegreville, (The Elk Island Catholic Separate School Division)",
    "St. Mary's Catholic School, Sexsmith, (The Grande Prairie Roman Catholic Separate School Division)",
    "St. Mary's College, Vermilion, (Other Schools)",
    "St. Mary's Elementary School, Lloydminster, (Lloydminster Roman Catholic Separate School Division)",
    "St. Mary's Elementary School, Fort Vermilion, (The Fort Vermilion School Division)",
    "St. Mary's High School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Mary's Roman Catholic Separate School, Taber, (The Holy Spirit Roman Catholic Separate School Division)",
    "St. Mary's School, Okotoks, (The Christ the Redeemer Catholic Separate School Division)",
    "St. Mary's School, Medicine Hat, (The Medicine Hat Roman Catholic Separate School Division)",
    "St. Matthew, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Matthew Lutheran Christian Academy, Stony Plain, (St. Matthew Evangelical Lutheran Church of Stony Plain, Alberta)",
    "St. Matthew School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Matthews Catholic School, Rocky Mountain House, (The Red Deer Catholic Separate School Division)",
    "St. Michael School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Michael's School, Pincher Creek, (The Holy Spirit Roman Catholic Separate School Division)",
    "St. Michael's School, Medicine Hat, (The Medicine Hat Roman Catholic Separate School Division)",
    "St. Michaels School, Bow Island, (The Holy Spirit Roman Catholic Separate School Division)",
    "St. Monica School, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Monica School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Nicholas, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Oscar Romero Catholic High School, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Patrick Catholic School, Camrose, (The Elk Island Catholic Separate School Division)",
    "St. Patrick Fine Arts Elementary School, Lethbridge, (The Holy Spirit Roman Catholic Separate School Division)",
    "St. Patrick School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Patrick's Roman Catholic Separate School, Taber, (The Holy Spirit Roman Catholic Separate School Division)",
    "St. Patrick's School, Medicine Hat, (The Medicine Hat Roman Catholic Separate School Division)",
    "St. Patricks Community School, Red Deer, (The Red Deer Catholic Separate School Division)",
    "St. Paul, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Paul Alternate Education Centre, St. Paul, (The St. Paul School Division)",
    "St. Paul Elementary School, Lethbridge, (The Holy Spirit Roman Catholic Separate School Division)",
    "St. Paul Store Front Campus, St. Paul, (The St. Paul School Division)",
    "St. Paul's Academy, Okotoks, (The Christ the Redeemer Catholic Separate School Division)",
    "St. Paul's Elementary School, Fort McMurray, (The Fort McMurray Roman Catholic Separate School Division)",
    "St. Peter Elementary School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Peter the Apostle Catholic High School, Spruce Grove, (The Evergreen Catholic Separate School Division)",
    "St. Philip, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Philip School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Pius X, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Richard, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Rita School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Rose, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Rose of Lima Junior High School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Rupert School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Sebastian Elementary School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Stanislaus, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Stephen School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Stephens Catholic School, Valleyview, (The Holy Family Catholic Separate School Division)",
    "St. Sylvester School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Teresa, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Teresa Catholic Outreach School, Spruce Grove, (The Evergreen Catholic Separate School Division)",
    "St. Teresa of Avila School, Red Deer, (The Red Deer Catholic Separate School Division)",
    "St. Teresa of Calcutta Elementary School, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Teresa of Calcutta School, Lethbridge, (The Holy Spirit Roman Catholic Separate School Division)",
    "St. Teresa of Calcutta School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Theresa Catholic School, Sherwood Park, (The Elk Island Catholic Separate School Division)",
    "St. Theresa School, Wabasca, (The Northland School Division)",
    "St. Thomas Aquinas Catholic School, Spruce Grove, (The Evergreen Catholic Separate School Division)",
    "St. Thomas Aquinas School, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Thomas Aquinas School, Red Deer, (The Red Deer Catholic Separate School Division)",
    "St. Thomas Aquinas School, Provost, (The East Central Alberta Catholic Separate School Division)",
    "St. Thomas Aquinas School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Thomas Elementary School, Lloydminster, (Lloydminster Roman Catholic Separate School Division)",
    "St. Thomas More Catholic School, Fairview, (The Grande Prairie Roman Catholic Separate School Division)",
    "St. Thomas More Junior High School, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Thomas More School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Timothy, Cochrane, (The Calgary Roman Catholic Separate School Division)",
    "St. Timothy, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Veronica School, Airdrie, (The Calgary Roman Catholic Separate School Division)",
    "St. Vincent, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Vincent De Paul Elementary Junior High School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. Vladimir Elementary School, Edmonton, (The Edmonton Catholic Separate School Division)",
    "St. Wilfrid Elementary School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "St. William School, Calgary, (The Calgary Roman Catholic Separate School Division)",
    "Stafford Ridge School, Lethbridge, (The Lethbridge School Division)",
    "Standoff Colony School, Cardston, (The Westwind School Division)",
    "Stanley Jones School, Calgary, (The Calgary School Division)",
    "STAR Catholic Outreach School, Leduc, (The St. Thomas Aquinas Roman Catholic Separate School Division )",
    "Star Ridge Colony School, Botha, (The Clearview School Division)",
    "Starbrite Hutterite Colony School, Dunmore, (The Prairie Rose School Division)",
    "Starland Colony School, Hanna, (The Prairie Land School Division)",
    "Start Outreach, Calgary, (The Calgary School Division)",
    "Stavely Elementary School, Stavely, (The Livingstone Range School Division)",
    "Ste. Marie Catholic School, Spirit River, (The Grande Prairie Roman Catholic Separate School Division)",
    "Steele Heights School, Edmonton, (The Edmonton School Division)",
    "Steinhauer School, Edmonton, (The Edmonton School Division)",
    "Step By Step Early Intervention Program, Calgary, (Step By Step Early Intervention Society)",
    "Stettler Adult Learning Centre, Stettler, (Stettler Adult Learning Centre)",
    "Stettler Elementary School, Stettler, (The Clearview School Division)",
    "Stettler Outreach School, Stettler, (The Clearview School Division)",
    "Stirling Mennonite Day School, Raymond, (Stirling Mennonite Church Day School)",
    "Stirling School, Stirling, (The Westwind School Division)",
    "Stony Plain Central School, Stony Plain, (The Parkland School Division)",
    "Stratford Elementary/Junior High School, Edmonton, (The Edmonton School Division)",
    "Strathcona Christian Academy, Sherwood Park, (The Elk Island School Division)",
    "Strathcona Christian Academy Elementary School, Sherwood Park, (The Elk Island School Division)",
    "Strathcona School, Edmonton, (The Edmonton School Division)",
    "Strathcona-Tweedsmuir School, Okotoks, (Strathcona-Tweedsmuir School)",
    "Strathmore High School, Strathmore, (The Golden Hills School Division)",
    "Strathmore Store Front School, Strathmore, (The Golden Hills School Division)",
    "Student's Union & Community Day Care Centre, Edmonton, (Students' Union of the University of Alberta and Community Day Care Centre Society)",
    "Students On Line, Dewberry, (The Buffalo Trail School Division)",
    "Sturgeon Composite High School, Sturgeon County, (The Sturgeon School Division )",
    "Sturgeon Heights School, St. Albert, (The Sturgeon School Division )",
    "Sturgeon Lake School, Valleyview, (Sturgeon Lake First Nation, Band #154)",
    "Sturgeon Learning Centre, Gibbons, (The Sturgeon School Division )",
    "Sucker Creek K4-K5 School, Enilda, (Sucker Creek First Nation)",
    "Summit West Independent School, Foothills, (Independent School Authority Ltd.)",
    "Summitview School, Grande Cache, (The Grande Yellowhead School Division)",
    "Sun Country Christian School, Bow Island, (Bow Island Congregation, Church of God in Christ, Mennonite)",
    "Sunalta School, Calgary, (The Calgary School Division)",
    "Sunchild E-Learning Community, Calgary, (ScCyber E-Learning Community)",
    "Sunchild First Nation School, Rocky Mountain House, (Sunchild First Nation Band)",
    "Suncrest Colony School, Castor, (The Clearview School Division)",
    "Sundance School, Calgary, (The Calgary School Division)",
    "Sundre High School, Sundre, (The Chinook's Edge School Division)",
    "Sundre Learning Centre, Sundre, (The Chinook's Edge School Division)",
    "Sunny Bend Colony School, Westlock, (The Pembina Hills School Division)",
    "Sunnyside School, Lethbridge, (The Palliser School Division)",
    "Sunnyside School, Calgary, (The Calgary School Division)",
    "Sunnysite Colony School, Taber, (The Horizon School Division)",
    "Sunrise Colony School, Etzikom, (The Prairie Rose School Division)",
    "Sunrise School, Brooks, (The Grasslands School Division)",
    "Susa Creek School, Grande Cache, (The Northland School Division)",
    "Suzuki Charter School, Edmonton, (Suzuki Charter School Society)",
    "Svend Hansen School, Edmonton, (The Edmonton School Division)",
    "Swan Hills School, Swan Hills, (The Pembina Hills School Division)",
    "Swan River School, Kinuso, (Swan River First Nation)",
    "Swanavon School, Grande Prairie, (The Grande Prairie School Division)",
    "Swedish Language School, Calgary, (Swedish Society of Calgary)",
    "Sweet Grass School, Edmonton, (The Edmonton School Division)",
    "Sylvan Lake Career High School, Sylvan Lake, (The Chinook's Edge School Division)",
    "Sylvan Meadow Adventist School, Sylvan Lake, (Alberta Conference of 7th Day Adventist Church)",
    "T A Norris Middle School, Peace River, (The Peace River School Division)",
    "T D Baker School, Edmonton, (The Edmonton School Division)",
    "Taber and District Community Adult Learning Association, Taber, (Taber and District Community Adult Learning Association)",
    "Taber Central School, Taber, (The Horizon School Division)",
    "Taber Christian School Alternative Program, Taber, (The Horizon School Division)",
    "Taber Mennonite School, Taber, (The Horizon School Division)",
    "Talmud Torah School, Edmonton, (The Edmonton School Division)",
    "Tanbridge Academy, Calgary, (Tanbridge Academy)",
    "Taotha School, Nordegg, (Stoney Tribal Administration)",
    "Taradale School, Calgary, (The Calgary School Division)",
    "Tatsikiisaapo'p Middle School, Cardston, (Kainai Board of Education)",
    "Ted Harrison School, Calgary, (The Calgary School Division)",
    "Teepee Creek School, Sexsmith, (The Peace Wapiti School Division)",
    "Tempo School, Edmonton, (Tempo School)",
    "Terrace Ridge School, Lacombe, (The Wolf Creek School Division)",
    "Terrace Road School, Calgary, (The Calgary School Division)",
    "Terry Fox School, Calgary, (The Calgary School Division)",
    "Tevie Miller Heritage School Program, Edmonton, (The Edmonton School Division)",
    "The Academy at King Edward, Edmonton, (The Edmonton School Division)",
    "The Brick Learning Centre, Ponoka, (The Wolf Creek School Division)",
    "The Centre for Learning@HOME, Okotoks, (The Christ the Redeemer Catholic Separate School Division)",
    "The Centre for Learning@Home-Edmonton, Edmonton, (The Christ the Redeemer Catholic Separate School Division)",
    "The Chinese Academy, Calgary, (The Chinese Academy Foundation)",
    "The Chinese Cultural Promotion Society, Edmonton, (Chinese Cultural Promotion Society (CCPS))",
    "The First Step Adult Education Center, Lacombe, (The First Step Adult Education Center)",
    "The Hamptons School, Calgary, (The Calgary School Division)",
    "The Heartland Agency, Calgary, (The Heartland Agency)",
    "The Learning Connection - Edson, Edson, (The Grande Yellowhead School Division)",
    "The Learning Connection - Evansburg, Evansburg, (The Grande Yellowhead School Division)",
    "The Learning Connection - Grande Cache, Grande Cache, (The Grande Yellowhead School Division)",
    "The Learning Connection - Hinton, Hinton, (The Grande Yellowhead School Division)",
    "The Learning Connection - Jasper, Jasper, (The Grande Yellowhead School Division)",
    "The PREP Program, Calgary, (The PREP Program - A Calgary Society to Promote Education for Individuals with Down Syndrome)",
    "The Third Academy, Calgary, (Third Academy International Ltd.)",
    "Thelma Chalifoux School, , (The Edmonton School Division)",
    "Theresetta Roman Catholic Separate School, Castor, (The East Central Alberta Catholic Separate School Division)",
    "Thickwood Heights School, Fort McMurray, (The Fort McMurray School Division)",
    "Third Academy Calgary South, Calgary, (Third Academy International Ltd.)",
    "Thomas B Riley School, Calgary, (The Calgary School Division)",
    "Thomas More Academy, Edmonton, (Unlimited Potential Community Services Society)",
    "Thompson Colony School, Fort Macleod, (The Livingstone Range School Division)",
    "Thorhild Central School, Thorhild, (The Aspen View School Division)",
    "Thorncliffe School, Edmonton, (The Edmonton School Division)",
    "Thorncliffe School, Calgary, (The Calgary School Division)",
    "Thornhill Community Building Early Childhood Society, Calgary, (Thornhill Child Care Society)",
    "Thorsby Elementary School, Thorsby, (The Black Gold School Division )",
    "Thorsby Junior Senior High School, Thorsby, (The Black Gold School Division )",
    "Three Hills Colony School, Strathmore, (The Golden Hills School Division)",
    "Three Hills School, Three Hills, (The Golden Hills School Division)",
    "Tilley Mennonite School, Tilley, (Duchess Bethel Mennonite School)",
    "Tilley School, Tilley, (The Grasslands School Division)",
    "Timberlea Public School, Fort McMurray, (The Fort McMurray School Division)",
    "Tiny Treasures Ellenwood, Calgary, (Marlborough Day Nursery ECS Limited)",
    "Tipaskan School, Edmonton, (The Edmonton School Division)",
    "Tofield Colony School, Camrose, (The Battle River School Division )",
    "Tofield School, Tofield, (The Battle River School Division )",
    "Tom Baines School, Calgary, (The Calgary School Division)",
    "Tomahawk School, Tomahawk, (The Parkland School Division)",
    "Tompkins Mennonite School, La Crete, (Wilson Prairie Mennonite School Society)",
    "Towers School, Strathmore, (The Golden Hills School Division)",
    "Transitions at the Y, Edmonton, (The Edmonton School Division)",
    "Trinity Christian Academy, Strathmore, (The Golden Hills School Division)",
    "Trinity Christian School, Calgary, (The Palliser School Division)",
    "Trinity Christian School-Grand Centre, Cold Lake, (Trinity Christian School Association)",
    "Trinity Learning Centre, Lethbridge, (The Holy Spirit Roman Catholic Separate School Division)",
    "Trochu Valley School, Trochu, (The Golden Hills School Division)",
    "Tschetter Colony School, Irricana, (The Rocky View School Division)",
    "Tsuu T'ina Bullhead Adult Education Centre, Calgary, (Tsuut'ina Nation Education Department)",
    "Tsuut'ina High School, Tsuu T'ina Sarcee, (Tsuut'ina Nation Education Department)",
    "Tsuut'ina Nation Middle School, Tsuu T'ina, (Tsuut'ina Nation Education Department)",
    "Turner Valley Elementary Junior High School, Turner Valley, (The Foothills School Division)",
    "Tuscany School, Calgary, (The Calgary School Division)",
    "Tween Valley Christian School, Taber, (Tween Valley Fellowship/Tween Valley Christian School)",
    "Twelve Mile Coulee School, Calgary, (The Calgary School Division)",
    "Twilight Colony School, Valleyview, (The Northern Gateway School Division)",
    "Twin Creeks School, Strathmore, (The Golden Hills School Division)",
    "Two Hills Mennonite School, Two Hills, (The St. Paul School Division)",
    "Two Hills School, Two Hills, (The St. Paul School Division)",
    "Tyndale Christian School, Calgary, (The Canadian Reformed School Society of Calgary)",
    "Uncas Elementary School, Ardrossan, (The Elk Island School Division)",
    "University Day Care Centre, Calgary, (University Child Care Centre Society)",
    "University School, Calgary, (The Calgary School Division)",
    "Uplands Elementary School, Brooks, (The Grasslands School Division)",
    "Upper Hay River School, High Level, (The Fort Vermilion School Division)",
    "URSA, , (Third Academy International Ltd.)",
    "V.I.K. Academy Midnapore, Calgary, (V.I.K. Society of Alberta)",
    "Valhalla Community School, Valhalla Centre, (Valhalla School Foundation)",
    "Valley Creek School, Calgary, (The Calgary School Division)",
    "Valley View School, Calgary, (The Calgary School Division)",
    "Valleyview Colony School, Strathmore, (The Golden Hills School Division)",
    "Valleyview Ranches Colony School, Valleyview, (The Northern Gateway School Division)",
    "Vanier Community Catholic School, Edson, (The Living Waters Catholic Separate School Division)",
    "Varsity Acres School, Calgary, (The Calgary School Division)",
    "Vastland School, Buffalo Head Prairie, (Buffalo Head Mennonite School Society)",
    "Vauxhall Elementary School, Vauxhall, (The Horizon School Division)",
    "Vauxhall Junior Senior High School, Vauxhall, (The Horizon School Division)",
    "Vegreville Composite High School, Vegreville, (The Elk Island School Division)",
    "Vegreville Next Step III Outreach School, Vegreville, (The Elk Island School Division)",
    "Velma E. Baker School, Edmonton, (The Edmonton School Division)",
    "Vera M. Welsh Elementary School, Lac La Biche, (The Northern Lights School Division)",
    "Verdant Valley Colony, Drumheller, (The Prairie Land School Division)",
    "Vermilion and Area Brighter Beginnings, Vermilion, (Vermilion and Area Brighter Beginnings Society)",
    "Vermilion Elementary School, Vermilion, (The Buffalo Trail School Division)",
    "Vermilion Outreach School, Vermilion, (The Buffalo Trail School Division)",
    "Vernon Barford School, Edmonton, (The Edmonton School Division)",
    "Veteran Colony School, Veteran, (The Prairie Land School Division)",
    "Veteran School, Veteran, (The Prairie Land School Division)",
    "Victoria Park High School, Lethbridge, (The Lethbridge School Division)",
    "Victoria School, Edmonton, (The Edmonton School Division)",
    "Viking Colony School, Camrose, (The Battle River School Division )",
    "Viking School, Viking, (The Battle River School Division )",
    "Vilna Off-Campus Achievement Academy, Vilna, (The Aspen View School Division)",
    "Vilna School, Vilna, (The Aspen View School Division)",
    "Vimy Ridge, Edmonton, (The Edmonton School Division)",
    "Vincent J Maloney Catholic School, St. Albert, (The Greater St. Albert Roman Catholic Separate School Division)",
    "Vincent Massey School, Medicine Hat, (The Medicine Hat School Division)",
    "Vincent Massey School, Calgary, (The Calgary School Division)",
    "Virginia Park School, Edmonton, (The Edmonton School Division)",
    "Visions West School, Rocky Mountain House, (The Wild Rose School Division )",
    "Vista Heights School, Calgary, (The Calgary School Division)",
    "Vista Virtual School, Edmonton, (The Pembina Hills School Division)",
    "Vista Virtual School - Calgary Writing Centre, Calgary, (The Pembina Hills School Division)",
    "Vista Virtual School - Edmonton Writing Centre, Edmonton, (The Pembina Hills School Division)",
    "Vital Grandin Catholic School, St. Albert, (The Greater St. Albert Roman Catholic Separate School Division)",
    "Vulcan Prairieview Elementary School, Vulcan, (The Palliser School Division)",
    "W P Wagner School, Edmonton, (The Edmonton School Division)",
    "W. A. Day Elementary School, Fort Macleod, (The Livingstone Range School Division)",
    "W. G. Murdoch School, Crossfield, (The Rocky View School Division)",
    "W. R. Myers High School, Taber, (The Horizon School Division)",
    "W.H. Croxford High School, Airdrie, (The Rocky View School Division)",
    "W.H.Cushing Workplace School, Calgary, (The Calgary School Division)",
    "W.O. Mitchell School, Calgary, (The Calgary School Division)",
    "Wabamun School, Wabamun, (The Parkland School Division)",
    "Wainwright Elementary School, Wainwright, (The Buffalo Trail School Division)",
    "Wainwright High School, Wainwright, (The Buffalo Trail School Division)",
    "Waldorf Independent School of Edmonton, Edmonton, (Waldorf Education Society of Edmonton)",
    "Walshe Crossroads Campus, Fort Macleod, (The Livingstone Range School Division)",
    "Walter & Gladys Hill Public School, Fort McMurray, (The Fort McMurray School Division)",
    "Wandering River School, Wandering River, (The Northern Lights School Division)",
    "Warburg Colony School, Warburg, (The Black Gold School Division )",
    "Warburg School, Warburg, (The Black Gold School Division )",
    "Warner Hockey School, Warner, (The Horizon School Division)",
    "Warner School, Warner, (The Horizon School Division)",
    "Warren Peer School ECS, Acadia Valley, (Acadia Valley Early Childhood Services)",
    "Warren Peers School, Acadia Valley, (The Prairie Rose School Division)",
    "Waterton New Yarrow Colony School, Fort Macleod, (The Livingstone Range School Division)",
    "Waverley School, Edmonton, (The Edmonton School Division)",
    "Wavy Lake Colony School, Camrose, (The Battle River School Division )",
    "Webber Academy, Calgary, (Webber Academy Foundation)",
    "Webster Niblock School, Medicine Hat, (The Medicine Hat School Division)",
    "Weinlos School, Edmonton, (The Edmonton School Division)",
    "Wembley Elementary School, Wembley, (The Peace Wapiti School Division)",
    "Wes Hosford School, Sherwood Park, (The Elk Island School Division)",
    "West Campus ABC Program, Edmonton, (ABC Head Start Society)",
    "West Central High School, Rocky Mountain House, (The Wild Rose School Division )",
    "West Country Outreach School, Rimbey, (The Wolf Creek School Division)",
    "West Dalhousie School, Calgary, (The Calgary School Division)",
    "West Dover School, Calgary, (The Calgary School Division)",
    "West Haven Public School, Leduc, (The Black Gold School Division )",
    "West Island College, Calgary, (West Island College Society of Alberta)",
    "West Island College-Class Afloat, Calgary, (Other Schools)",
    "West Meadow Elementary School, Claresholm, (The Livingstone Range School Division)",
    "West Park Elementary School, Red Deer, (The Red Deer School Division )",
    "West Raley Colony School, Cardston, (The Westwind School Division)",
    "West Ridge School, Calgary, (The Calgary School Division)",
    "West Springs School, Calgary, (The Calgary School Division)",
    "West View School, Calgary, (The Calgary School Division)",
    "Westboro Elementary School, Sherwood Park, (The Elk Island School Division)",
    "Westbrook Outreach, Calgary, (The Calgary School Division)",
    "Westbrook School, Cochrane, (The Rocky View School Division)",
    "Westbrook School, Edmonton, (The Edmonton School Division)",
    "Western Canada High School, Calgary, (The Calgary School Division)",
    "Westgate School, Calgary, (The Calgary School Division)",
    "Westglen School, Didsbury, (The Chinook's Edge School Division)",
    "Westglen School, Edmonton, (The Edmonton School Division)",
    "Westhaven Elementary School, Edson, (The Grande Yellowhead School Division)",
    "Westlawn School, Edmonton, (The Edmonton School Division)",
    "Westlock Elementary School, Westlock, (The Pembina Hills School Division)",
    "Westlock Outreach, Westlock, (The Pembina Hills School Division)",
    "Westminster School, Lethbridge, (The Lethbridge School Division)",
    "Westminster School, Edmonton, (The Edmonton School Division)",
    "Westmount Elementary School, Calgary, (Westmount Charter School Society)",
    "Westmount Mid/High School, Calgary, (Westmount Charter School Society)",
    "Westmount School, Okotoks, (The Foothills School Division)",
    "Westmount School, Strathmore, (The Golden Hills School Division)",
    "Westmount School, Edmonton, (The Edmonton School Division)",
    "Westpark Middle School, Red Deer, (The Red Deer School Division )",
    "Westview School, Fort McMurray, (The Fort McMurray School Division)",
    "Westwind Alternate School, Cardston, (The Westwind School Division)",
    "Westwood Community High School, Fort McMurray, (The Fort McMurray School Division)",
    "Wetaskiwin Composite High School, Wetaskiwin, (The Wetaskiwin School Division)",
    "Wetaskiwin Home Education School, Wetaskiwin, (The Wetaskiwin School Division)",
    "Wetaskiwin Outreach, Wetaskiwin, (The Wetaskiwin School Division)",
    "Wheatland Crossing School, Standard, (The Golden Hills School Division)",
    "Wheatland Elementary School, Strathmore, (The Golden Hills School Division)",
    "Whispering Hills Primary School, Athabasca, (The Aspen View School Division)",
    "Whispering Ridge Community School, Grande Prairie, (The Peace Wapiti School Division)",
    "White Cloud Head Start, Edmonton, (Bent Arrow Traditional Healing Society)",
    "White Lake Colony School, Lethbridge, (The Palliser School Division)",
    "Whitecourt Central Elementary School, Whitecourt, (The Northern Gateway School Division)",
    "Whitefish Lake First Nation School, Atikameg, (Kee Tas Kee Now Tribal Council Education Authority)",
    "Whitesand Colony School, , (The Clearview School Division)",
    "Wild Rose Colony School, Lethbridge, (The Palliser School Division)",
    "Wild Rose Elementary School, St. Albert, (The St. Albert School Division)",
    "Wildflowers Childcare, Jasper, (Municipality of Jasper)",
    "Wildwood School, Wildwood, (The Grande Yellowhead School Division)",
    "Wildwood School, Calgary, (The Calgary School Division)",
    "William Aberhart High School, Calgary, (The Calgary School Division)",
    "William D Cuts School, St. Albert, (The St. Albert School Division)",
    "William D. Pratt School, Calgary, (The Calgary School Division)",
    "William E Hay Composite High School, Stettler, (The Clearview School Division)",
    "William Reid School, Calgary, (The Calgary School Division)",
    "William Roper Hull School, Calgary, (The Calgary School Division)",
    "Willow Creek Colony School, Fort Macleod, (The Livingstone Range School Division)",
    "Willow Creek Composite High School, Claresholm, (The Livingstone Range School Division)",
    "Willow Park School, Leduc, (The Black Gold School Division )",
    "Willow Park School, Calgary, (The Calgary School Division)",
    "Wilma Hansen School, Calgary, (The Calgary School Division)",
    "Wilson Middle School, Lethbridge, (The Lethbridge School Division)",
    "Wilson Prairie Mennonite School, La Crete, (Wilson Prairie Mennonite School Society)",
    "Win Ferguson Community School, Fort Saskatchewan, (The Elk Island School Division)",
    "Windsong Heights School, Airdrie, (The Rocky View School Division)",
    "Windsor Park School, Edmonton, (The Edmonton School Division)",
    "Winfield Elementary School, Winfield, (The Wetaskiwin School Division)",
    "Winnifred Hutterite Colony, Dunmore, (The Prairie Rose School Division)",
    "Winston Churchill High School, Lethbridge, (The Lethbridge School Division)",
    "Winterburn School, Edmonton, (The Edmonton School Division)",
    "Wintering Hills, Strathmore, (The Golden Hills School Division)",
    "Woking School, Woking, (The Peace Wapiti School Division)",
    "Wolf Creek Academy, Lacombe, (The Wolf Creek School Division)",
    "Wolf Creek Colony School, Cardston, (The Westwind School Division)",
    "Wolf Creek Education Centre, Ponoka, (The Wolf Creek School Division)",
    "Wolf Creek Summer School, Lacombe, (The Wolf Creek School Division)",
    "Wood's Homes School, Calgary, (The Calgary School Division)",
    "Woodbine School, Calgary, (The Calgary School Division)",
    "Woodbridge Farms School, Sherwood Park, (The Elk Island School Division)",
    "Woodhaven Middle School, Spruce Grove, (The Parkland School Division)",
    "Woodlands Adventist School, Ponoka, (Alberta Conference of 7th Day Adventist Church)",
    "Woodlands School, Calgary, (The Calgary School Division)",
    "Woodman School, Calgary, (The Calgary School Division)",
    "Worsely Mennonite School, Cleardale, (Cleardale Mennonite School Society)",
    "Worsley Central School, Worsley, (The Peace River School Division)",
    "Wye School, Sherwood Park, (The Elk Island School Division)",
    "Yellowhead Koinonia Christian School, Edson, (Yellowhead Koinonia Christian School Society)",
    "Yellowhead R.E.C. (Gr Cache), Grande Cache, (Other Schools)",
    "Yellowhead Tribal Education Centre, Edmonton, (Yellowhead Tribal Council)",
    "YMCA Stay in School Career High School, Medicine Hat, (The Medicine Hat School Division)",
    "York School, Edmonton, (The Edmonton School Division)",
    "Young Adult Program, Calgary, (The Calgary School Division)",
    "Young Moms' School, Medicine Hat, (The Medicine Hat School Division)",
    "Youngstown School, Youngstown, (The Prairie Land School Division)",
    "Youngstown School, Edmonton, (The Edmonton School Division)",
    "Youngstown School Early Childhood Services, Youngstown, (Youngstown Kindergarten Assoc.)",
    "Youth Assessment Centre, Lac La Biche, (The Northern Lights School Division)",
    "Youth Assessment Centre, Red Deer, (The Red Deer School Division )",
    "Youth Community Support Program, Edmonton, (The Edmonton School Division)",
    "Yufeng Chinese School, Calgary, (Yufeng Chinese School Centre)",
    "OTHER - School not listed",
  ],

  curriculumOptions: [
    {
      id: 0,
      name: "Science",
    },
    {
      id: 1,
      name: "Biology",
    },
    {
      id: 2,
      name: "Chemistry",
    },
    {
      id: 3,
      name: "Physics",
    },
    {
      id: 4,
      name: "Social Studies",
    },
    {
      id: 5,
      name: "Outdoor and Environmental Education",
    },
    {
      id: 6,
      name: "CTS",
    },
    {
      id: 7,
      name: "English/Language Arts",
    },
    {
      id: 8,
      name: "Math",
    },
    {
      id: 9,
      name: "Physical Education",
    },
    {
      id: 10,
      name: "Health",
    },
    {
      id: 11,
      name: "Fine Arts",
    },
    {
      id: 12,
      name: "Special Projects",
    },
    {
      id: 13,
      name: "Locally Developed Course",
    },
    {
      id: 14,
      name: "Other",
    },
  ],

  gradeOptions: [
    {
      id: 0,
      name: "K",
    },
    {
      id: 1,
      name: "1",
    },
    {
      id: 2,
      name: "2",
    },
    {
      id: 3,
      name: "3",
    },
    {
      id: 4,
      name: "4",
    },
    {
      id: 5,
      name: "5",
    },
    {
      id: 6,
      name: "6",
    },
    {
      id: 7,
      name: "7",
    },
    {
      id: 8,
      name: "8",
    },
    {
      id: 9,
      name: "9",
    },
    {
      id: 10,
      name: "10",
    },
    {
      id: 11,
      name: "11",
    },
    {
      id: 12,
      name: "12",
    },
    {
      id: 13,
      name: "Post-Secondary",
    },
  ],

  restrictTeachersToDomains: false,

  acceptedTeacherDomains: [
    "bluegeo.ca",
    "alces.ca",
    "albertatomorrow.ca",
    "ursudio.com",
  ],
};

export default RegionSchools;
