<template>
  <md-card>
    <md-card-header class="md-card-header-text md-card-header-blue">
      <div class="card-text">
        <h4 class="title">
          My Scenarios
        </h4>
        <p class="category">
          Simulate changes on the landscape and observe their effects
        </p>
      </div>
    </md-card-header>
    <md-card-content v-if="scenarios.length === 0">
      <div class="button-container">
        <md-button
          class="md-info md-lg"
          @click="newScenario()"
        >
          Create a new Scenario
        </md-button>
      </div>
    </md-card-content>
    <md-card-content v-else>
      <div class="md-layout">
        <div
          v-for="item in scenarios"
          :key="item.id"
          class="md-layout-item md-size-100"
        >
          <md-card
            md-with-hover
            class="md-card-scenario md-card-no-elevation"
            @click.native="explore(item)"
          >
            <div class="md-layout">
              <div class="md-layout-item md-size-10">
                <md-icon class="md-card-header-blue">
                  map
                </md-icon>
              </div>
              <div class="md-layout-item md-size-30 md-small-size-100">
                <b>{{ item.name }}</b>
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                {{ item.description }}
              </div>
              <div
                v-if="!item.complete"
                class="md-layout-item md-size-10 md-small-size-100"
              >
                <md-progress-spinner
                  :md-diameter="30"
                  :md-stroke="3"
                  md-mode="indeterminate"
                />
              </div>
              <div
                v-else
                class="md-layout-item md-size-10"
                @click="explore(item)"
              >
                <md-button
                  v-if="item.incompleteRun"
                  class="md-round md-just-icon md-danger"
                >
                  <i class="fas fa-pen" />
                </md-button>
                <md-button
                  v-else
                  class="md-round md-just-icon md-info"
                  @mouseenter="doNotView = !doNotView"
                  @mouseleave="doNotView = !doNotView"
                  @click="explore(item)"
                >
                  <md-icon>explore</md-icon>
                </md-button>
              </div>
            </div>
          </md-card>
          <md-card-actions md-alignment="space-between" />
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import { mapActions } from "vuex";
import { types } from "@/store/types";
export default {
  name: "DashboardScenarios",
  props: {
    scenarios: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      doNotView: false
    }
  },
  methods: {
    ...mapActions([
      types.actions.SET_ACTIVE_SCENARIO,
      types.actions.TOGGLE_EDIT_SCENARIO_DIALOG
    ]),
    explore(scenario) {
      if (!this.doNotView) {
        this.SET_ACTIVE_SCENARIO({vm: this, scenario: scenario});
        this.$emit('explore-scenario')
      }
    },
    newScenario() {
      this.TOGGLE_EDIT_SCENARIO_DIALOG();
    }
  }
}
</script>

<style scoped>
.md-card-no-elevation {
  background: transparent;
  box-shadow: none;
}
</style>
