// challenges setup
import RegionConditions from "./region-conditions";

/*
Challenges can be defined in one of 2 different types: 'quiz' or 'quest'
sample quiz challenge:
  {
    code: "code", // unique code for user's history tracking
    title: "title",
    description: "description",
    comment: "", // admin comments for reference
    type: "quiz",
    question: "ask a question?",
    answers: ["answer1", "answer2", "answer3", "answer4"], // multiple choice answers to offer user
    correctIndex: 0, // the index of the correct answer above
    passMsg: "correct",
    failMsg: "incorrect",
    learnMsg: "some msg",
    event: "scenario-view", // event that can trigger this challenge possible types: ['obs-create', 'obs-view', 'video-view', 'scenario-view', 'quest-complete', 'report-create', 'mission-complete']
    conditions: [ // conditions must be in place for this challenge to be launched
      RegionConditions.someCondition,
      RegionConditions.someOtherCondition
    ],
    points: 100,
    media: {
      type: "none",
      status: ""
    }
  }

sample quest challenge:
  {
    code: "code", // unique code for user's history tracking
    title: "title",
    description: "description",
    prompt: "prompt", // subtitle to prompt the user to take the quest
    comment: "", // admin comments for reference
    activities: [ // list of activities user must complete for the quest
      "Some activity user should do",
      "Another activity they should do"
    ],
    type: "quest",
    icon: "fa-something", // font-awesome icon to symbolize this quest
    setupConditions: null, // conditions function that will run to setup the UI to help the user get started (example: make sure observations layer is turned on)
    passConditions: [ // conditions must be passed in order to pass the quest.  Conditions don't need to be passed sequentially and can be passed as long as the quest is active
      {
        activity: 0, // index of the activity this is related to
        event: "obs-view", // event that can trigger this condition to be tested possible types: ['obs-create', 'obs-view', 'video-view', 'scenario-view', 'quest-complete', 'report-create', 'mission-complete', 'map-move']
        condition: RegionConditions.someCondition
      },
      {
        activity: 1,
        event: "video-view",
        condition: [RegionConditions.someCondition, RegionConditions.anotherCondition] // conditions can also be an array of conditions
      }
    ],
    passMsg: "correct", // message displayed when quest is completed
    event: "scenario-view", // event that can trigger this challenge
    conditions: [], // conditions must be in place for this challenge to be launched
    points: 100,
    media: {
      type: "none",
      status: ""
    }
  }
*/

const RegionChallenges = {
  QuestsGeneralInfo: {
    title: "Quests",
    description:
      "Take on these quests to boost your learning and earn bonus points!",
  },
  Challenges: [
    // ----------------- QUIZ CHALLENGES -------------------------
    {
      code: "forestCooperation",
      title: "Forestry Cooperation",
      description:
        "A forestry company and oil and gas company are cooperating in building a road to get to the same area. In the industry this is considered a best practice.",
      comment: "Timber production rose more than 30%",
      type: "quiz",
      question: "Which of the following is also a best practice?",
      answers: [
        "Cultivating land adjacent to wetland areas",
        "Reducing the width of seismic lines",
        "Increasing the size of well pads",
      ],
      correctIndex: 1,
      passMsg:
        "You are correct! By reducing the width of seismic lines, less natural landscape is disturbed. Watch the best practices video to learn about more best practices. Research other best practices in Forestry, Oil and Gas Production or Agriculture.",
      failMsg:
        "Oops! That's not quite right. Increasing the size of well pad sites, or cultivating land adjacent to wetland or riparian areas disturbed more natural landscape and wildlife habitat. By reducing the width of seismic lines, less natural landscape is disturbed. Watch the best practices video to learn about more best practices. Research other best practices in Forestry, Oil and Gas Production or Agriculture.",
      learnMsg:
        "By reducing the width of seismic lines, less natural landscape is disturbed. Watch the best practices video to learn about more best practices. Research other best practices in Forestry, Oil and Gas Production or Agriculture.",
      event: "scenario-view",
      conditions: [RegionConditions.indsTimberIncrease30Percent],
      points: 100,
      media: {
        type: "none",
        status: "",
      },
    },
    {
      code: "bikeLanes",
      title: "Bike Lanes",
      description: "Cities and towns in your area have instituted bike lanes.",
      comment: "if GHG goes up by more than 30%",
      type: "quiz",
      question: "How might this affect the Greenhouse Gas emissions indicator?",
      answers: [
        "Greenhouse gas emissions will go down",
        "Greenhouse gas emissions will go up",
      ],
      correctIndex: 0,
      passMsg:
        "You are correct! Bike lanes encourage citizens to bike more and drive less. Does your city or town have bike lanes? Research cities and towns that do have bike lanes.",
      failMsg:
        "Oops! That's not quite right! Bike lanes encourage citizens to bike more and drive less. Does your city or town have bike lanes? Research cities and town that do have bike lanes.",
      learnMsg:
        "Bike lanes encourage citizens to bike more and drive less. Does your city or town have bike lanes? Research cities and town that do have bike lanes.",
      event: "scenario-view",
      conditions: [RegionConditions.indsGHGIncrease30Percent],
      points: 100,
      media: {
        type: "none",
        status: "",
      },
    },
    {
      code: "carbonTax",
      title: "Carbon Tax",
      description:
        "The government has instituted a carbon tax. That means you will pay more to drive and heat your homes.",
      comment: "if GHG goes up by more than 30%",
      type: "quiz",
      question: "How might this affect consumer behaviour?",
      answers: [
        "Consumers will drive less and conserve electricity",
        "Consumers will drive more and use more electricity",
      ],
      correctIndex: 0,
      passMsg:
        "You are correct! A Carbon Tax makes driving our cars and electricity more expensive. This incentivizes consumers to drive less and conserve electricity. Research other areas around the world that have a Carbon Tax.",
      failMsg:
        "Oops! That's not quite right. A Carbon Tax makes driving our cars and electricity more expensive. This incentivizes consumers to drive less and conserve electricity. Research other areas around the world that have a Carbon Tax.",
      learnMsg:
        "A Carbon Tax makes driving our cars and electricity more expensive. This incentivizes consumers to drive less and conserve electricity. Research other areas around the world that have a Carbon Tax.",
      event: "scenario-view",
      conditions: [RegionConditions.indsGHGIncrease30Percent],
      points: 100,
      media: {
        type: "none",
        status: "",
      },
    },
    {
      code: "decreasedSeismicWidth",
      title: "Decreased Seismic Line Width",
      description:
        "Seismic companies have decreased the width of seismic lines from 5 meters to 1 meter.",
      comment: "oil and gas rise by more than 30%",
      type: "quiz",
      question: "How might this help some of your environmental indicators?",
      answers: [
        "Natural Landscapes will decrease in your study area",
        "Greenhouse gases will increase",
        "Natural Landscapes will increase and Suitable wildlife habitat will increase.",
      ],
      correctIndex: 2,
      passMsg:
        "You are correct! By decreasing the width of seismic lines, less natural landscapes are disturbed. Try turning on best practices to see how your environmental indicators will be affected. ",
      failMsg:
        "Oops! That's not quite right. By decreasing the width of seismic lines, less natural landscapes are disturbed. Try turning on best practices to see how your environmental indicators will be affected.",
      learnMsg:
        "By decreasing the width of seismic lines, less natural landscapes are disturbed. Try turning on best practices to see how your environmental indicators will be affected. ",
      event: "scenario-view",
      conditions: [RegionConditions.indsEnergyIncrease30Percent],
      points: 100,
      media: {
        type: "none",
        status: "",
      },
    },
    {
      code: "oilPriceFallGDP",
      title: "Oil and Gas Prices Fall",
      description: "Oil and Gas prices have fallen.",
      comment: "hydrocarbon production goes down",
      type: "quiz",
      question:
        "How might you change your plan so you still reach your GDP goal?",
      answers: [
        "Lower the Industrial Activity level",
        "Decrease the area devoted to Agriculture",
        "Increase Industrial Activity which would increase Forestry profit to help reach the GDP goal",
      ],
      correctIndex: 2,
      passMsg:
        "You are correct! You can increase industrial activity which will increase Forestry activity. This might make up for the loss dues to low oil prices. Research how much of Alberta's GDP typically comes from Oil and Gas and how much comes from the Forestry industry. Be careful though; increasing industrial activity could have impacts on your environmental indicators!",
      failMsg:
        "Oops! That's not quite right. You can increase industrial activity which will increase Forestry activity. This might make up for the loss dues to low oil prices, but it might also adversely affect environmental indicators. Research how much of Alberta's GDP typically comes from Oil and Gas and how much comes from the Forestry industry.",
      learnMsg:
        "You can increase industrial activity which will increase Forestry activity. This might make up for the loss dues to low oil prices, but it might also adversely affect environmental indicators so be careful! Research how much of Alberta's GDP typically comes from Oil and Gas and how much comes from the Forestry industry. ",
      event: "scenario-view",
      conditions: [
        RegionConditions.scenarioIsLandUse,
        RegionConditions.indsEnergyDecrease,
      ],
      points: 100,
      media: {
        type: "none",
        status: "",
      },
    },
    {
      code: "oilPriceFallAffectsPlan",
      title: "Oil and Gas Prices Fall",
      description: "Oil and Gas prices have fallen.",
      comment: "hydrocarbon down more than 30% AND user fails their GDP goal",
      type: "quiz",
      question: "How might this affect the outcome of your landuse plan?",
      answers: [
        "It will not affect my land-use plan",
        "I may not be able to reach my GDP goal",
        "I will need to lower industrial activity",
      ],
      correctIndex: 1,
      passMsg:
        "You are correct! You may not be able to reach your GDP goal since oil prices are low. This may affect the amount of money the government gets from Oil and Gas Royalties. Research how the government uses Royalties.",
      failMsg:
        "Oops. That's not quite right. For a refresher, check out the GDP video. You may not be able to reach your GDP goal since oil prices are low. This may affect the amount of money the government gets from Oil and Gas Royalties. Research how the government uses Royalties.",
      learnMsg:
        "You may not be able to reach your GDP goal since oil prices are low. This may affect the amount of money the government gets from Oil and Gas Royalties. Research how the government uses Royalties. For a refresher, check out the GDP video.",
      event: "scenario-view",
      conditions: [
        RegionConditions.scenarioIsLandUse,
        RegionConditions.indsEnergyDecrease30Percent,
        RegionConditions.goalsFailGDP,
      ],
      points: 100,
      media: {
        type: "none",
        status: "",
      },
    },
    {
      code: "waterShortages",
      title: "Water Shortages",
      description:
        "Drought and water shortages mean we must decrease water consumption for the future.",
      comment: "",
      type: "quiz",
      question: "What best practices would decrease water consumption?",
      answers: [
        "Municipal water conservation",
        "Agricultural water conservation",
        "Energy sector water conservation",
        "All of the above",
      ], // multiple choice answers to offer user
      correctIndex: 3,
      passMsg:
        "Correct!  By turning on water conservation practices for municipalities, industry and agriculture, water consumption will go down.",
      failMsg:
        "Oops, that's not quite right. Watch the water consumption video to learn more about water conservation.",
      learnMsg:
        "By turning on water conservation practices for municipalities, industry and agriculture, water consumption will go down. Watch the water consumption video to learn more about water conservation.",
      event: "scenario-view",
      conditions: [RegionConditions.indsWaterConsumptionIncrease],
      points: 100,
      media: {
        type: "none",
        status: "",
      },
    },
    // {
    //   code: "oilPriceFallGDP2",
    //   title: "Oil and Gas Prices Fall",
    //   description: "Oil and Gas prices have fallen.",
    //   comment: "",
    //   type: "quiz",
    //   question: "How might this affect the GDP indicator?",
    //   answers: ["GDP will fall", "GDP will increase"], // multiple choice answers to offer user
    //   correctIndex: 0,
    //   passMsg:
    //     "You are correct.  GDP in Alberta is largely due to the oil and gas industry and will therefore affect GDP.",
    //   failMsg:
    //     "Oops, that's not quite right.  GDP will go down if oil and gas prices fall. Watch the GDP video for more information.",
    //   learnMsg:
    //     "GDP in Alberta is largely due to the oil and gas industry and will therefore affect GDP. GDP will go down if oil and gas prices fall. Watch the GDP video for more information.",
    //   event: "scenario-view",
    //   conditions: [
    //     RegionConditions.scenarioIsLandUse,
    //     RegionConditions.indsEnergyDecrease,
    //   ],
    //   points: 100,
    //   media: {
    //     type: "none",
    //     status: "",
    //   },
    // },
    {
      code: "GHGRise",
      title: "Greenhouse Gas Emissions",
      description: "Greenhouse gas emissions are rising.",
      comment: "",
      type: "quiz",
      question: "What two factors will cause carbon dioxide levels to go up?",
      answers: [
        "Oil and Gas Production and planting more trees",
        "Oil and Gas Production and more population growth",
        "Decrease in Agriculture and more population growth",
        "Decrease in agriculture and planting more trees",
      ], // multiple choice answers to offer user
      correctIndex: 1,
      passMsg:
        "You are correct. Both oil and gas production and population growth will lead to an increase in carbon dioxide levels.",
      failMsg:
        "Oops, not exactly. Oil and Gas Production and an increase in population growth will cause carbon dioxide to go up.  For more information watch the greenhouse gases video.",
      learnMsg:
        "Oil and Gas Production and an increase in population growth will cause carbon dioxide to go up.  For more information watch the greenhouse gases video.",
      event: "scenario-view",
      conditions: [
        RegionConditions.scenarioIsBAUOrLandUse,
        RegionConditions.indsGHGIncrease,
      ],
      points: 100,
      media: {
        type: "none",
        status: "",
      },
    },
    {
      code: "carbonAndNatLands",
      title: "Biotic Carbon Storage and Natural Landscapes",
      description:
        "Your biotic carbon storage fell, meaning there is less carbon being stored in the grasses, trees and soil.",
      comment: "",
      type: "quiz",
      question: " How could you increase biotic carbon storage?",
      answers: [
        "Decrease natural landscapes",
        "Increase agricultural production",
        "Increase natural landscapes",
        "Decrease agricultural production",
      ], // multiple choice answers to offer user
      correctIndex: 3,
      passMsg:
        "You are correct.  Natural landscapes, especially forests, absorb more carbon than other landscape types.  Try adding more natural landscapes to your map and see how it changes biotic carbon storage.",
      failMsg:
        "Not quite.  We want to increase carbon absorption by having more vegetation and natural areas! Try adding more natural landscapes to your map and see how it changes biotic carbon storage.",
      learnMsg:
        "We can increase carbon absorption by having more vegetation and natural areas! Try adding more natural landscapes to your map and see how it changes biotic carbon storage.",
      event: "scenario-view",
      conditions: [
        RegionConditions.scenarioIsBAUOrLandUse,
        RegionConditions.indsBioticCarbonDecrease,
      ],
      points: 100,
      media: {
        type: "none",
        status: "",
      },
    },
    {
      code: "waterQualityDown",
      title: "Water Quality",
      description: "Water quality has fallen since 1910. ",
      comment: "",
      type: "quiz",
      question: "The two factors that affect water quality the most are:",
      answers: [
        "Human Population and Forestry",
        "Human Population and Agriculture",
        "Forestry and Agriculture",
        "Oil and Gas Production and Agriculture",
      ], // multiple choice answers to offer user
      correctIndex: 1,
      passMsg:
        "You are correct!  Human Population and agriculture have the biggest impact on water quality. In Alberta Tomorrow we measure water quality by looking at levels of phosphorus runoff. For more information, watch the water quality video.",
      failMsg:
        "Oops, not quite.  Human Population and Agriculture have the most impact on water quality. For more information, watch the water quality video.",
      learnMsg:
        "Human Population and Agriculture have the most impact on water quality. For more information, watch the water quality video.",
      event: "scenario-view",
      conditions: [RegionConditions.indsWaterQualityDecrease],
      points: 100,
      media: {
        type: "none",
        status: "",
      },
    },
    {
      code: "bioticCarbon",
      title: "Biotic Carbon Storage",
      description:
        "Biotic carbon refers to the amount of carbon stored in vegetation and soil.",
      comment: "",
      type: "quiz",
      question: "Do we want biotic carbon storage to go up or down?",
      answers: ["Up", "Down"],
      correctIndex: 0,
      passMsg:
        "Correct! You want biotic carbon storage to go up. Then there will be more carbon stored in the trees, grasses and soil and less in the atmosphere.  High carbon dioxide in the atmosphere contributes to climate change. How can you increase carbon storage on the landscape?",
      failMsg:
        "Not really. You want biotic carbon storage to go up. Then there will be more carbon stored in the trees, grasses and soil and less in the atmosphere. High carbon dioxide in the atmosphere contributes to climate change. How can you increase carbon storage on the landscape?",
      learnMsg:
        "Biotic carbon storage going up means there will be more carbon stored in the trees, grasses and soil and less in the atmosphere. High carbon dioxide in the atmosphere contributes to climate change. How can you increase carbon storage on the landscape?",
      event: "scenario-view",
      conditions: [], // any scenario should be ok for this challenge
      points: 100,
      media: {
        type: "none",
        status: "",
      },
    },
    {
      code: "popGrowth",
      title: "Population Growth",
      description: "Alberta's population has grown exponentially since 1910.",
      comment: "",
      type: "quiz",
      question:
        "What beneficial management practice would allow our population to grow, but not take away area of other landscape types?",
      answers: [
        "Increase the density of cities and towns",
        "Decrease the density of cities and towns",
      ],
      correctIndex: 0,
      passMsg: `Fantastic.  If we increase the density of developments, we can have more people living in an area, without expanding our cities and town into urban sprawl. For example, building "up" instead of "out". In your landuse plan, you can do this by turning on the beneficial management practice of "walkable cities/urban densification".`,
      failMsg: `Sorry, that's not quite right.  If we increase density of development we will be able to have our population grow in numbers without taking up more space.  In your landuse plan, you can do this by turning on the beneficial management practice of "walkable cities/urban densification".`,
      learnMsg: "some msg",
      event: "scenario-view",
      conditions: [
        RegionConditions.scenarioIsBAUOrLandUse,
        RegionConditions.indsPopulationIncrease,
      ],
      points: 100,
      media: {
        type: "none",
        status: "",
      },
    },
    {
      code: "forestry",
      title: "Forestry",
      description:
        "The forestry industry in Alberta contributes to Alberta's GDP.",
      comment: "",
      type: "quiz",
      question: "When the price of lumber falls, what happens to GDP?",
      answers: [
        "GDP related to forestry falls",
        "GDP related to forestry goes up",
      ], // multiple choice answers to offer user
      correctIndex: 0,
      passMsg:
        "You are correct. GDP related to forestry will fall because forestry companies will be making less money. Watch the GDP video to find out what other industries contribute to GDP.",
      failMsg:
        "Oops. Actually, GDP related to forestry will fall. Watch the GDP video to find out what other industries contribute to GDP.",
      learnMsg:
        "GDP related to forestry will fall. Watch the GDP video to find out what other industries contribute to GDP.",
      event: "scenario-view",
      conditions: [RegionConditions.scenarioIsBAUOrLandUse],
      points: 100,
      media: {
        type: "none",
        status: "",
      },
    },
    {
      code: "agProduction",
      title: "Agricultural Production",
      description:
        "Agricultural production increased rapidly after the year 1910, but is now falling in the more urbanized areas of Alberta.",
      comment: "",
      type: "quiz",
      question:
        "Why is the area of agricultural land falling now when it increase rapidly from the year 1910?",
      answers: [
        "Farmers are not farming all of their land.",
        "Cities and towns are now expanding into farmland",
      ], // multiple choice answers to offer user
      correctIndex: 1,
      passMsg:
        "Correct.  For most of the 1900s natural landscapes were being converted into agricultural land.  Now agricultural land is being overtaken by urban development.  To see this happening, create a plan for the study area that contains Calgary or Edmonton.",
      failMsg:
        "Oops.  Not quite.  The area of agricultural land is actually decreasing because cities and towns are expanding into agricultural land.  To see this happening, create a plan for the study area that contains Calgary or Edmonton.",
      learnMsg:
        "The area of agricultural land in Alberta is decreasing because cities and towns are expanding into agricultural land.  To see this happening, look at a historic scenario and then create a plan for the study area that contains Calgary or Edmonton.",
      event: "scenario-view",
      conditions: [RegionConditions.indsAgDecrease],
      points: 100,
      media: {
        type: "none",
        status: "",
      },
    },
    {
      code: "waterConsumption",
      title: "Water Consumption",
      description:
        "Albertans use a lot of water.  Unfortunately, although 80% of Alberta's population is in the southern half of the province, 80% of the water is in the northern half of the province.  This creates a problem of too little water where there is the most population.",
      comment: "",
      type: "quiz",
      question:
        "Which beneficial management practice would decrease water consumption.",
      answers: [
        "Taking 20 minute showers rather than 5 minute showers.",
        "Using water conservation techniques in homes, schools, businesses and industry.",
        "Watering lawns in the middle of the day rather than evenings.",
        "Using surface water to water crops.",
      ], // multiple choice answers to offer user
      correctIndex: 1,
      passMsg:
        "Correct!  You can conserve water in your home, school, business or industry. For more information, watch the water consumption video.",
      failMsg:
        "Oops.  Not so fast.  That will actually increase water consumption.  To learn more about water consumption, watch the water consumption video.",
      learnMsg:
        "We can conserve water in our homes, schools, businesses and industry. For more information, watch the water consumption video.",
      event: "scenario-view",
      conditions: [RegionConditions.scenarioIsBAUOrLandUse],
      points: 100,
      media: {
        type: "none",
        status: "",
      },
    },
    // ----------------- QUESTS -------------------------
    {
      code: "glaciersQuest", // unique code for user's history tracking
      title: "Glaciers & Freshwater",
      description:
        "Most of the major rivers in Western Canada originate from glaciers and high elevation snowpacks in the Rocky Mountains. In Alberta, there are several major rivers that start from glaciers in Banff and Jasper National Parks. Snow melt is the largest contributor to the annual flows in these rivers, followed by rainfall.",
      prompt:
        "Learn more about glaciers and how they affect water in Alberta to complete this quest.",
      comment: "", // admin comments for reference
      activities: [
        // list of activities user must complete for the quest
        // "Find the Athabasca Glacier on the map and view the info about it.",
        "Find the Athabasca Glacier on the map and view the panorama video (check out the other glacier videos too while you are exploring!)",
        "Find the University of Alberta Ice Core Lab and watch the video about the importance of glaciers with Dr. Ali Criscitiello.",
        "Create a landuse scenario and increase your water quality.",
        `Find your MLA's office on the map, then create a landuse observation with "MLA" in the title to geotag your MLA’s office`,
      ],
      type: "quest",
      icon: "fa-snowflake",
      setupConditions: [
        RegionConditions.obsLayerOn,
        RegionConditions.satelliteLayerOn,
        RegionConditions.launchExplore,
      ], // conditions function that will run to setup the UI to help the user get started (example: make sure observations layer is turned on)
      passConditions: [
        // conditions must be passed in order to pass the quest.  There must be at least 1 condition.  Conditions don't need to be passed sequentially and can be passed as long as the quest is active
        {
          activity: 0,
          event: "obs-view", // event that can trigger this condition to be tested
          condition: RegionConditions.obsViewAthabascaGlacier,
        },
        {
          activity: 1,
          event: "obs-view", // event that can trigger this condition to be tested
          condition: RegionConditions.obsViewIceCoreLab,
        },
        {
          activity: 1,
          event: "video-view",
          condition: RegionConditions.videoViewIceCoreLab,
        },
        {
          activity: 2,
          event: "scenario-view",
          condition: [
            RegionConditions.scenarioIsLandUse,
            RegionConditions.indsWaterQualityIncrease,
          ],
        },
        {
          activity: 3,
          event: "obs-create",
          condition: RegionConditions.userHasMLAObservation,
        },
      ],
      passMsg:
        "Congratulations! You completed the Glaciers & Freshwater Quest! Did you learn something new about glaciers and how they affect all Albertans?  Think about how climate change might affect our drinking water supply.  For next steps,  write a letter to your MLA explaining the importance of protecting the sources of our drinking water.  Keep up the good work!",
      event: "scenario-view", // event that can trigger this challenge
      conditions: [], // conditions must be in place for this challenge to be launched
      points: 200,
      media: {
        type: "none",
        status: "",
      },
    },
    {
      code: "waterAndFishQuest", // unique code for user's history tracking
      title: "Water Quality and Fish Habitat",
      description:
        "Fish health and water quality are closely related.  It can be said that a healthy fish population indicates that the water quality is quite good.  Vice versa, if fish are not healthy, water quality is likely poor and water quality is poor because of the land uses that are taking place around that water.",
      prompt: "Can you explain why fish kills are happening in our lakes?", // subtitle to prompt the user to take the quest
      comment: "", // admin comments for reference
      activities: [
        // list of activities user must complete for the quest
        `Watch the video on Water Quality`,
        `Watch the Fish Habitat Video`,
        `On the satellite imagery, find the video west of Edmonton where Mike Sullivan talks about Fish kills in Alberta Lakes`,
        `Make a “Wanted Alive” poster for a Native Fish in ALberta. Include it’s description, habitat, status and threats.  Using the satellite imagery, create an observation of an area where you can find that native species and upload a picture of your “Wanted Alive” Poster.  Put "Wanted Alive" in the observation title.`,
      ],
      type: "quest",
      icon: "fa-fish", // font-awesome icon to symbolize this quest
      setupConditions: [
        RegionConditions.satelliteLayerOn,
        RegionConditions.obsLayerOn,
        RegionConditions.launchWaterQualityVideo,
      ], // conditions function that will run to setup the UI to help the user get started (example: make sure observations layer is turned on)
      passConditions: [
        // conditions must be passed in order to pass the quest.  Conditions don't need to be passed sequentially and can be passed as long as the quest is active
        {
          activity: 0,
          event: "video-view",
          condition: RegionConditions.videoViewWaterQuality,
        },
        {
          activity: 1,
          event: "video-view",
          condition: RegionConditions.videoViewFishHabitat,
        },
        {
          activity: 2,
          event: "obs-view",
          condition: RegionConditions.obsViewFishKill,
        },
        {
          activity: 3,
          event: "obs-create",
          condition: RegionConditions.userHasFishWantedObservation,
        },
      ],
      passMsg:
        "Congratulations!  You now know why central Alberta has experienced massive fish kills in the summer!  Can you explain the process of Eutrophication to someone else?", // message displayed when quest is completed
      event: "scenario-view", // event that can trigger this challenge
      conditions: [], // conditions must be in place for this challenge to be launched
      points: 400,
      media: {
        type: "none",
        status: "",
      },
    },
    {
      code: "elbowWatershedQuest", // unique code for user's history tracking
      title: "Elbow River Watershed Quest",
      description:
        "Did you know that approximately 40% of Calgary’s water supply comes from the Elbow River? ",
      prompt: "Let's explore the Elbow River Watershed", // subtitle to prompt the user to take the quest
      comment: "", // admin comments for reference
      activities: [
        // list of activities user must complete for the quest
        `Find the picture of the Rae Glacier, the headwaters of the Elbow River`,
        `Complete a future Business As Usual Simulation for the Elbow River Watershed.`,
        `Click on an observation showing water quality measurements from that river.`,
        `Enter your own observation from a water body near your school or home`,
      ],
      type: "quest",
      icon: "fa-water", // font-awesome icon to symbolize this quest
      setupConditions: [
        RegionConditions.obsLayerOn,
        RegionConditions.satelliteLayerOn,
        RegionConditions.launchExplore,
      ], // conditions function that will run to setup the UI to help the user get started (example: make sure observations layer is turned on)
      passConditions: [
        // conditions must be passed in order to pass the quest.  Conditions don't need to be passed sequentially and can be passed as long as the quest is active
        {
          activity: 0,
          event: "obs-view", // event that can trigger this condition to be tested possible types: ['obs-create', 'obs-view', 'video-view', 'scenario-view', 'quest-complete', 'report-create', 'mission-complete']
          condition: RegionConditions.obsViewRaeGlacier,
        },
        {
          activity: 1,
          event: "scenario-view",
          condition: RegionConditions.userHasElbowRiverBAUScenario,
        },
        {
          activity: 2,
          event: "obs-view",
          condition: RegionConditions.obsViewElbowWaterObs,
        },
        {
          activity: 3,
          event: "obs-create",
          condition: RegionConditions.userHasWaterObservations,
        },
      ],
      passMsg:
        "Congratulations!  You now know the source and extent of the Elbow River Watershed, the source of drinking water for 40% of Calgarians!  Is this the source of your own drinking water?", // message displayed when quest is completed
      event: "scenario-view", // event that can trigger this challenge
      conditions: [], // conditions must be in place for this challenge to be launched
      points: 200,
      media: {
        type: "none",
        status: "",
      },
    },
    {
      code: "waterUseQuest", // unique code for user's history tracking
      title: "Water Use in Alberta",
      description:
        "When you look at satellite imagery of Alberta, particularly in southern Alberta, do you notice the perfect round green circles on the land? Do you know what causes them?",
      prompt:
        "Even though Alberta has freshwater available to use, the distribution of this water is not where the demand is.  Complete this quest to find out more about water consumption in Alberta.", // subtitle to prompt the user to take the quest
      comment: "", // admin comments for reference
      activities: [
        // list of activities user must complete for the quest
        "Find the town of Vauxhall northwest of Lethbridge.  Do you see the perfect circles?  Those are from irrigation systems.  Zoom out…. Does it appear to you that we use a lot of irrigation in Southern Alberta?",
        "Watch the water consumption video",
        "Develop a future landuse simulation and set your goal for water consumption at ½ of what it currently is. Develop a plan to meet that goal.  What must you use?",
      ],
      type: "quest",
      icon: "fa-tint", // font-awesome icon to symbolize this quest
      setupConditions: [
        RegionConditions.satelliteLayerOn,
        RegionConditions.launchExplore,
      ], // conditions function that will run to setup the UI to help the user get started (example: make sure observations layer is turned on)
      passConditions: [
        // conditions must be passed in order to pass the quest.  Conditions don't need to be passed sequentially and can be passed as long as the quest is active
        {
          activity: 0,
          event: "map-move", // event that can trigger this condition to be tested possible types: ['obs-create', 'obs-view', 'video-view', 'scenario-view', 'quest-complete', 'report-create', 'mission-complete']
          condition: RegionConditions.mapViewIrrigation,
        },
        {
          activity: 1,
          event: "video-view",
          condition: RegionConditions.videoViewWaterConsumption,
        },
        {
          activity: 2,
          event: "scenario-view",
          condition: [
            RegionConditions.scenarioIsLandUse,
            RegionConditions.indsWaterConsumptionDecrease50Percent,
          ],
        },
      ],
      passMsg:
        "Congratulations! You have completed the Water Use quest. Irrigation and Agriculture account for over 50% of surface water use in Alberta.  Municipal use still accounts for 11%.  Organize an awareness campaign in your school or community that makes people aware of beneficial management practices that can be used to reduce municipal water consumption.", // message displayed when quest is completed
      event: "scenario-view", // event that can trigger this challenge
      conditions: [], // conditions must be in place for this challenge to be launched
      points: 200,
      media: {
        type: "none",
        status: "",
      },
    },
    {
      code: "forestryQuest", // unique code for user's history tracking
      title: "Forestry",
      description:
        "It’s hard to grasp how much forest is harvested in Alberta until you start zooming in on the satellite imagery.  Look in the boreal forest as well as the foothills and rocky mountain areas and you will begin to see areas that have been harvested for lumber and/or pulp and paper",
      prompt:
        "Have you noticed a bunch of irregularly shaped clearings in forested areas of Alberta when looking at satellite imagery?", // subtitle to prompt the user to take the quest
      comment: "", // admin comments for reference
      activities: [
        // list of activities user must complete for the quest
        `Search North West of Calgary for the Ghost Public Land Use Zone and look for irregularly shaped clearings on the satellite imagery`,
        `Watch the Forestry video to find out what causes these clearnings`,
        `Create a future land use plan for a forested area that shows forestry footprint.  Adjust the industrial activity slider and see what happens to the amount of forestry in the future.  Record what happens to the other indicator dials when you increase industrial activity.`,
      ],
      type: "quest",
      icon: "fa-tree", // font-awesome icon to symbolize this quest
      setupConditions: [
        RegionConditions.satelliteLayerOn,
        RegionConditions.launchExplore,
      ], // conditions function that will run to setup the UI to help the user get started (example: make sure observations layer is turned on)
      passConditions: [
        // conditions must be passed in order to pass the quest.  Conditions don't need to be passed sequentially and can be passed as long as the quest is active
        {
          activity: 0,
          event: "map-move",
          condition: RegionConditions.mapViewCutblocks,
        },
        {
          activity: 1,
          event: "video-view",
          condition: RegionConditions.videoViewForestry,
        },
        {
          activity: 2,
          event: "scenario-view",
          condition: [
            RegionConditions.scenarioIsLandUse,
            RegionConditions.scenarioDevRateChanged,
            RegionConditions.scenarioHasForestry,
          ],
        },
      ],
      passMsg:
        "Congratulations!  You have completed the Forestry Quest!  Now research some beneficial management practices used in the forestry industry to lessen the impact of forestry impact on the environment and speed up reforestation.", // message displayed when quest is completed
      event: "scenario-view", // event that can trigger this challenge
      conditions: [], // conditions must be in place for this challenge to be launched
      points: 200,
      media: {
        type: "none",
        status: "",
      },
    },
    {
      code: "oilSandsQuest", // unique code for user's history tracking
      title: "Oil Sands",
      description:
        "The Alberta economy has long depended on the oil and gas industry for much of its GDP.  The oil sands in northern Alberta provide jobs to many Albertans. Other than employees or those who live near the oil sands, most Albertans have never seen the area.",
      prompt:
        "What are the beige colored clearings north of Fort McMurray in northern Alberta? (Hint: this area contributes significantly to Alberta’s GDP).", // subtitle to prompt the user to take the quest
      comment: "", // admin comments for reference
      activities: [
        // list of activities user must complete for the quest
        `Go to northern Alberta and find the City of Fort McMurray`,
        `Continue exploring further north of Fort McMurray.`,
        `Watch the Oil and Gas Production video.`,
        `Complete a business as usual scenario for the Lower Athabasca Watershed.  The grey areas you see north of the city are the oil sands.  What is happening to this area in the next 30 years?  What happens to the oil and gas indicator dial?`,
      ],
      type: "quest",
      icon: "fa-burn", // font-awesome icon to symbolize this quest
      setupConditions: [
        RegionConditions.satelliteLayerOn,
        RegionConditions.launchExplore,
      ], // conditions function that will run to setup the UI to help the user get started (example: make sure observations layer is turned on)
      passConditions: [
        // conditions must be passed in order to pass the quest.  Conditions don't need to be passed sequentially and can be passed as long as the quest is active
        {
          activity: 0,
          event: "map-move", // event that can trigger this condition to be tested possible types: ['obs-create', 'obs-view', 'video-view', 'scenario-view', 'quest-complete', 'report-create', 'mission-complete']
          condition: RegionConditions.mapViewFtMac,
        },
        {
          activity: 1,
          event: "map-move", // event that can trigger this condition to be tested possible types: ['obs-create', 'obs-view', 'video-view', 'scenario-view', 'quest-complete', 'report-create', 'mission-complete']
          condition: RegionConditions.mapViewOilSands,
        },
        {
          activity: 2,
          event: "video-view",
          condition: RegionConditions.videoViewOil,
        },
        {
          activity: 3,
          event: "scenario-view",
          condition: RegionConditions.userHasLowerAthabascaBAUScenario,
        },
      ],
      passMsg:
        "Congratulations!  You now have knowledge of how much our economy relies on oil and gas production and where the Oil Sands are!  Research what companies operate in the oil sands and what Beneficial Management Practices they use to help lessen the environmental impact of the development.", // message displayed when quest is completed
      event: "scenario-view", // event that can trigger this challenge
      conditions: [], // conditions must be in place for this challenge to be launched
      points: 200,
      media: {
        type: "none",
        status: "",
      },
    },
    {
      code: "climateChangeQuest", // unique code for user's history tracking
      title: "Climate Change",
      description:
        "Here in Alberta, we have already been experiencing the effects of climate change such as extreme weather, flooding, increased forest fire occurrence, and melting glaciers.",
      prompt:
        "How will the study area where you live change in 30 years depending on which climate change scenario turns out to be the correct one? You will investigate 3 different climate change scenarios, Mild (RCP 2.6) Medium (RCP 4.5) and Hot (RCP 8.5)", // subtitle to prompt the user to take the quest
      comment: "", // admin comments for reference
      activities: [
        // list of activities user must complete for the quest
        `Watch the Climate Change Video`,
        `Create and run three Business as Usual scenarios for the same watershed: one for each climate change model (mild, medium and hot)`,
        `Click on the climate change information button to understand the difference between the 3 models and the assumptions made for each.`,
        `Generate a report comparing the three different climate scenarios you created.`,
        `Share your report using the hashtag #albertaclimatechange`,
      ],
      type: "quest",
      icon: "fa-cloud-sun-rain", // font-awesome icon to symbolize this quest
      setupConditions: RegionConditions.launchClimateVideo, // conditions function that will run to setup the UI to help the user get started (example: make sure observations layer is turned on)
      passConditions: [
        // conditions must be passed in order to pass the quest.  Conditions don't need to be passed sequentially and can be passed as long as the quest is active
        {
          activity: 0,
          event: "video-view", // event that can trigger this condition to be tested possible types: ['obs-create', 'obs-view', 'video-view', 'scenario-view', 'quest-complete', 'report-create', 'mission-complete']
          condition: RegionConditions.videoViewClimateChange,
        },
        {
          activity: 1,
          event: "scenario-view",
          condition: RegionConditions.userHas3BauClimateScenarios,
        },
        {
          activity: 2,
          event: "panel-view",
          condition: RegionConditions.userHasViewedClimatePanel,
        },
        {
          activity: 3,
          event: "report-create",
          condition: RegionConditions.userHasComparisonReportClimateQuest,
        },
        {
          activity: 4,
          event: "report-share",
          condition: RegionConditions.trueConditionTest, // must be true for this event to be fired
        },
      ],
      passMsg:
        "Congratulations!  You now understand how climate change will continue to affect Alberta in the future.  The extent of climate change impacts will depend on our ability to change our behaviours to decrease the levels of greenhouse gases we emit into the atmosphere.   Complete a carbon footprint calculator and see what your family’s individual footprint is. What actions are you going to take personally to decrease your own carbon footprint?", // message displayed when quest is completed
      event: "scenario-view", // event that can trigger this challenge
      conditions: [], // conditions must be in place for this challenge to be launched
      points: 300,
      media: {
        type: "none",
        status: "",
      },
    },
  ],
};

export default RegionChallenges;
