<template>
  <md-card class="side-panel-card layout-padding-none">
    <md-card-header class="layout-gray-dark layout-padding-small">
      <div class="md-title">
        <md-icon>place</md-icon>
        Observation Details
        <md-button
          class="md-icon-button md-mini"
          @click="closeObservation"
        >
          <md-icon>close</md-icon>
        </md-button>
      </div>
    </md-card-header>
    <md-card-content>
      <!-- new observation -->
      <div v-if="isNewObservation">
        <observation-new />
      </div>
      <!-- existing observation -->
      <div v-else-if="!obsDataLoaded">
        <md-progress-spinner
          :md-diameter="140"
          :md-stroke="8"
          md-mode="indeterminate"
          class="loading-spinner"
        />
      </div>
      <div
        v-else-if="SELECTED_OBSERVATION"
        class="md-layout text-white"
      >
        <!-- media area -->
        <div
          v-if="hasImg && !isFlagged"
          class="md-layout-item md-size-100"
        >
          <img
            v-if="imgURL"
            :src="imgURL"
          >
        </div>
        <div
          v-else-if="hasVideo && !isFlagged"
          class="md-layout-item md-size-100"
        >
          <div
            v-if="SELECTED_OBSERVATION.properties.media.status === 'Complete'"
          >
            <video-player
              v-if="videoReady"
              :options="getVideoOptions(SELECTED_OBSERVATION.properties)"
              :title="getVideoTitle()"
              :is360="is360Video"
              :locations="'locations' in SELECTED_OBSERVATION.properties.media ? SELECTED_OBSERVATION.properties.media.locations : []"
              class="md-layout-item md-size-100"
              @on-location-update="locationUpdate"
            />
          </div>
          <div
            v-else-if="
              SELECTED_OBSERVATION.properties.media.status === 'Ingest'
            "
          >
            <p>Video currently in processing queue</p>
          </div>
        </div>
        <!-- title and description -->
        <div class="md-layout-item md-size-60">
          <h4
            v-if="!isFlagged"
            class="obs-title"
          >
            {{ SELECTED_OBSERVATION.properties.title }}
          </h4>
          <h4 v-else>
            This observation has been flagged as inappropriate.
          </h4>
          <p
            v-if="'UserName' in SELECTED_OBSERVATION.properties"
            class="submitted-by"
          >
            Submitted by {{ SELECTED_OBSERVATION.properties.UserName }}
          </p>
        </div>
        <div class="md-layout-item md-size-40 action-btns">
          <!-- 360 video -->
          <md-chip
            v-if="is360Video"
            class="threesixty-chip"
          >
            360°
            <md-tooltip md-direction="top">
              Drag video to explore!
            </md-tooltip>
          </md-chip>
          <!-- SHARE BUTTON -->
          <share-dialog
            v-if="$Region.socialSharingOn"
            ref="shareDialog"
            obj-type="obs"
            :obj="SELECTED_OBSERVATION"
            gtag-action="share_observation_close"
            gtag-category="observations"
          >
            <md-button class="md-simple md-just-icon share-btn">
              <span @click="toggleShareDialog">
                <md-icon>share</md-icon>
                <md-tooltip md-direction="bottom">Share</md-tooltip>
              </span>
            </md-button>
          </share-dialog>
          <!-- FLY TO BUTTON -->
          <md-button
            class="md-icon-button md-dense center-vertical md-info"
            @click="flyToObservation"
          >
            <i class="fas fa-search-location" />
            <md-tooltip md-direction="bottom">
              Fly To Observation
            </md-tooltip>
          </md-button>
          <md-button
            v-if="isTeacherLinkedObs"
            class="md-icon-button md-dense center-vertical"
            :class="[{ 'md-danger': isFlagged }]"
            @click="setObsStatus(isFlagged)"
          >
            <i class="fas fa-exclamation-circle" />
            <md-tooltip md-direction="bottom">
              {{ getFlaggedTooltip(isFlagged) }}
            </md-tooltip>
          </md-button>
        </div>
        <div class="md-layout-item md-size-100">
          <p
            v-if="observationDate !== 'Invalid Date'"
            class="text-white"
          >
            {{ observationDate }}
          </p>
        </div>
        <!-- residential school details -->
        <div
          v-if="isResSchool"
          class="md-layout-item md-size-100"
        >
          <p class="md-title">
            Residential School
          </p>
          <p>{{ SELECTED_OBSERVATION.properties.LocEarlyYR }} - {{ SELECTED_OBSERVATION.properties.LocLastYR }}</p>
        </div>
        <div v-if="!isFlagged">
          <div class="md-layout-item md-size-100">
            <p>{{ SELECTED_OBSERVATION.properties.description }}</p>
          </div>

          <!-- Show related BRBC video in prev / next cycle -->
          <div
            v-if="isBRBC"
            class="md-layout-item md-size-100"
          >
            <p class="md-title">
              Bow River Watershed 360 Video Series
            </p>
            <md-button
              class="md-dense md-info prev-next-btn"
              @click="prevBRBC()"
            >
              Previous
            </md-button>
            <md-button
              class="md-dense md-info"
              @click="nextBRBC()"
            >
              Next
            </md-button>
            <!--
              Return all video links here and connect prev and next and create links
            -->
          </div>

          <!-- residential school link -->
          <div
            v-if="isResSchool"
            class="md-layout-item md-size-100"
          >
            <p class="text-white">
              Reference:
              <a
                :href="SELECTED_OBSERVATION.properties.relatedUrls[1]"
                :title="SELECTED_OBSERVATION.properties.relatedUrls[0]"
                target="_blank"
                class="reference-link"
              >
                {{ SELECTED_OBSERVATION.properties.relatedUrls[0] }}
              </a>
            </p>
          </div>

          <!-- general observation details -->
          <div v-if="SELECTED_OBSERVATION.properties.type === 'general'">
            <div
              v-for="(item, key) in generalFields"
              :key="key"
              class="md-layout-item md-size-100"
            >
              <h5>{{ item.label }}</h5>
              <p>{{ getDetails(item.field) }}</p>
            </div>
          </div>
          <!-- water observation details -->
          <div v-else-if="SELECTED_OBSERVATION.properties.type === 'water'">
            <!-- water numeric fields -->
            <div
              v-for="(item, key) in includedWaterFields"
              :key="key"
              class="md-layout-item md-size-100"
            >
              <!-- weather fields -->
              <h5
                v-if="
                  waterFieldsWeather.includes(item.field) &&
                    SELECTED_OBSERVATION.properties.details[item.field].length > 0
                "
              >
                {{ item.label }}:
                <span
                  v-for="_item in SELECTED_OBSERVATION.properties.details[item.field]"
                  :key="`${item.field}_${_item}`"
                >
                  <i
                    class="weather-icon"
                    :class="weatherConditionsIcons[_item]"
                  />
                  <md-tooltip md-direction="top">
                    {{ _item }}
                  </md-tooltip>
                </span>
              </h5>
              <h5
                v-else-if="
                  !waterFieldsWeather.includes(item.field) &&
                    getDetails(item.field) !== ''
                "
              >
                {{ item.label }}: {{ getDetails(item.field) }}
                <span v-if="!waterFieldsNonNumeric.includes(item.field)">
                  <!-- only show units on numeric fields -->
                  {{ item.helperText }}
                </span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { types } from "@/store/types";
import VideoPlayer from "@/components/VideoPlayer";
import ShareDialog from "@/pages/Dashboard/Components/ShareDialog";
import { Observation } from "./mixins/Observation";
import { VideoOptions } from "@/pages/Dashboard/Components/mixins/VideoOptions";
import { Gamification } from "@/pages/Dashboard/Components/mixins/Gamification";
import { ObservationSummary } from "@/pages/Dashboard/Components/mixins/ObservationSummary";
import ObservationNew from "./ObservationNew";
export default {
  name: "ObservationDetails",

  components: {
    VideoPlayer,
    ShareDialog,
    ObservationNew,
  },
  mixins: [Observation, VideoOptions, Gamification, ObservationSummary],

  props: {},

  data: function () {
    return {
      obsDataLoaded: false,
      observationDate: null,
      videoReady: false, // to make sure the video component is loading the video correctly, especially when selected observation changes while this component is mounted
      imgURL: null,
      weatherConditionsIcons: {
        Sunny: "fas fa-sun",
        Cloudy: "fas fa-cloud",
        Rainy: "fas fa-cloud-rain",
        Windy: "fas fa-wind",
      }
    };
  },

  computed: {
    ...mapGetters([types.getters.STUDENTS]),

    isNewObservation() {
      // determine if this is an existing observation or a new observation being created
      let isNew = false;
      if (
        this.SELECTED_OBSERVATION !== null &&
        "status" in this.SELECTED_OBSERVATION &&
        this.SELECTED_OBSERVATION.status === "new"
      ) {
        isNew = true;
      }
      return isNew;
    },
    isFlagged() {
      // is this observation is flagged as inappropriate?
      return (
        "properties" in this.SELECTED_OBSERVATION &&
        "status" in this.SELECTED_OBSERVATION.properties &&
        this.SELECTED_OBSERVATION.properties.status === "flagged"
      );
    },
    selectedObsId() {
      const systemTypes = Object.keys(this.$Region.observation_types).filter(_key => !['water', 'general'].includes(_key));
      if (
        this.SELECTED_OBSERVATION !== null &&
        "id" in this.SELECTED_OBSERVATION
      ) {
        return this.SELECTED_OBSERVATION.id;
      } else if (
        "properties" in this.SELECTED_OBSERVATION &&
        systemTypes.includes(this.SELECTED_OBSERVATION.properties.type)
      ) {
        return this.SELECTED_OBSERVATION.properties.id;
      }
      return -1;
    },
    hasImg() {
      if (
        "properties" in this.SELECTED_OBSERVATION &&
        "media" in this.SELECTED_OBSERVATION.properties &&
        this.SELECTED_OBSERVATION.properties.media.type === "image"
      ) {
        return true;
      }
      return false;
    },
    hasVideo() {
      if (
        this.SELECTED_OBSERVATION !== null &&
        "properties" in this.SELECTED_OBSERVATION &&
        "media" in this.SELECTED_OBSERVATION.properties &&
        this.SELECTED_OBSERVATION.properties.media.type === "video"
      ) {
        return true;
      }
      return false;
    },
    is360Video() {
      return ('media' in this.SELECTED_OBSERVATION.properties && 'is360' in this.SELECTED_OBSERVATION.properties.media && this.SELECTED_OBSERVATION.properties.media.is360 == true);
    },
    isTeacherLinkedObs() {
      // if admin buttons should be displayed - teachers only
      if (this.USER_DATA.usertype === "teacher") {
        // check if user is a student that belongs to the teacher
        const studentsList = this.STUDENTS.map((student) => student.UserName);
        if (
          "UserName" in this.SELECTED_OBSERVATION.properties &&
          studentsList.includes(this.SELECTED_OBSERVATION.properties.UserName)
        ) {
          // observation user is a student of the teacher
          return true;
        }
      }
      return false;
    },
    includedWaterFields() {
      // list of water fields that are actually present on the selected obs
      return Object.fromEntries(Object.entries(this.waterFields).filter(([key]) => Object.keys(this.SELECTED_OBSERVATION.properties.details).includes(key)));
    },
    isResSchool() {
      return "subtype" in this.SELECTED_OBSERVATION.properties && this.SELECTED_OBSERVATION.properties.subtype === "resschool";
    },
    isBRBC() {
      return "subtype" in this.SELECTED_OBSERVATION.properties && this.SELECTED_OBSERVATION.properties.subtype === "brbc";
    },
    prevBtnState() {
      const currentIndex = this.$Region.system_observations.filter((obs)=>'subtype' in obs && obs.subtype == 'brbc' && 'media' in obs && obs.media.type === 'video').findIndex((item) => item.id == this.SELECTED_OBSERVATION.properties.id);
      if (currentIndex !== -1 && currentIndex !== 0) {
        return false
      }
      return true
    },
    nextBtnState() {
      const currentIndex = this.$Region.system_observations.filter((obs)=>'subtype' in obs && obs.subtype == 'brbc' && 'media' in obs && obs.media.type === 'video').findIndex((item) => item.id == this.SELECTED_OBSERVATION.properties.id);
      if (currentIndex !== -1 && currentIndex < this.$Region.system_observations.filter((obs)=>'subtype' in obs && obs.subtype == 'brbc' && 'media' in obs && obs.media.type === 'video').length - 1) {
        return false
      }
      return true
    },
    videoObservations() {
      // get list of all video observations for subtype brbc
      return this.$Region.system_observations.filter((obs)=>'subtype' in obs && obs.subtype == 'brbc' && 'media' in obs && obs.media.type === 'video');
    }
  },

  watch: {
    selectedObsId(newVal) {
      if (newVal !== -1) {
        this.loadObservationDetails();
      }
    },
    hasImg(newVal) {
      if (newVal === true) {
        this.loadImg();
      }
    },
    hasVideo(newVal) {
      if (newVal === true) {
        this.loadVideo();
      } else {
        this.videoReady = false;
      }
    },
  },

  beforeMount() {
    if (!this.isNewObservation) {
      this.loadObservationDetails();
    } else {
      this.obsDataLoaded = true;
    }
  },

  mounted() {
    // GAMIFICATION EVENT: obs-view
    this.getGameActivity("obs-view");
  },

  methods: {
    ...mapActions([
      types.actions.OBSERVATIONS_LOAD_DETAILS,
      types.actions.SET_MAP_FLYTO,
      types.actions.UPDATE_SELECTED_OBSERVATION,
    ]),

    loadObservationDetails() {
      // load the observation details via api call
      this.obsDataLoaded = false;
      this.videoReady = false;
      this.OBSERVATIONS_LOAD_DETAILS({
        vm: this,
        UserName: this.SELECTED_OBSERVATION.properties.UserName,
        UserTransaction: this.SELECTED_OBSERVATION.properties.UserTransaction,
        type: this.SELECTED_OBSERVATION.properties.type,
        id: this.SELECTED_OBSERVATION.properties.id,
      })
        .then(() => {
          this.observationDate = new Date(
            parseInt(this.SELECTED_OBSERVATION.properties.observedTime)
          ).toDateString();

          if (this.hasImg) {
            // if an image is attached to the observation get the image download URL
            this.loadImg();
          }
          if (this.hasVideo) {
            this.loadVideo();
          }

          // analytics tracking
          const label =
            "UserTransaction" in this.SELECTED_OBSERVATION.properties
              ? this.SELECTED_OBSERVATION.properties.UserTransaction
              : `system_obs_${this.SELECTED_OBSERVATION.properties.id}`;
          this.$Helpers.gtagEvent(
            this,
            `view_observation`,
            `observations`,
            label
          );

          // mark as viewed for teachers
          this.updateViewedStatus();

          // ready to view
          this.obsDataLoaded = true;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error.response);
        });
    },
    async loadImg() {
      // get the tempLink URL for the image
      this.imgURL = null;
      if (this.SELECTED_OBSERVATION.properties.media.url.startsWith("http")) {
        this.imgURL = this.SELECTED_OBSERVATION.properties.media.url;
      } else {
        this.imgURL = await this.$Helpers.getImageURL(
          this.$ImageBucket,
          this.$Auth,
          this.$API,
          this.SELECTED_OBSERVATION.properties.media.url
        );
      }
    },
    loadVideo() {
      // make video player ready
      this.videoReady = true;
    },
    getDetails(key) {
      // get the data for a given observation properties details key
      if (
        "details" in this.SELECTED_OBSERVATION.properties &&
        key in this.SELECTED_OBSERVATION.properties.details
      ) {
        return this.SELECTED_OBSERVATION.properties.details[key];
      }
      return "";
    },
    getVideoTitle() {
      // return a title for the video for history tracking purposes
      if ("UserTransaction" in this.SELECTED_OBSERVATION.properties) {
        // use usertransaction for user observations to ensure they are unique
        return this.SELECTED_OBSERVATION.properties.UserTransaction;
      } else if ("title" in this.SELECTED_OBSERVATION.properties) {
        // use title for system observations so titles align with videos played from the videos page
        return this.SELECTED_OBSERVATION.properties.title;
      }
      return "untitled";
    },
    flyToObservation() {
      // zoom map to the observation point
      this.SET_EXPLORE_MOBILE_FS_CONTENT("map");
      this.SET_MAP_FLYTO(
        this.SELECTED_OBSERVATION.geometry.coordinates.toString()
      );
    },
    locationUpdate(coords) {
      // trigger a map flyto to the new coords and tell the map to move the marker as well
      this.SET_MAP_FLYTO(`${coords},1`); // add extra param to coords so MAP_FLYTO recognizes the marker needs to move
    },
    async setObsStatus(status) {
      // set the status of an observation
      // status === false => set to 'flagged'
      // status === true => set to 'viewed'
      const properties = JSON.parse(
        JSON.stringify(this.SELECTED_OBSERVATION.properties)
      );
      properties.status = !status ? "flagged" : "viewed"; // assuming teacher unflagged it...
      // todo handle cases where admin usertype unflags an obs
      await this.UPDATE_SELECTED_OBSERVATION({ properties: properties });
      this.saveObservationUpdatesToDB(["status"]);
    },
    updateViewedStatus() {
      // mark as viewed for teachers when viweing it first time
      if (
        this.isTeacherLinkedObs &&
        !("status" in this.SELECTED_OBSERVATION.properties)
      ) {
        this.setObsStatus(true);
      }
    },
    getFlaggedTooltip(flaggedStatus) {
      // tooltip to display on flag button
      return !flaggedStatus ? `Flag as Inappropriate` : `Click to Un-flag`;
    },
    toggleShareDialog() {
      this.$refs.shareDialog.toggleShowDialog();
    },
    prevBRBC() {
      // launch the previous BRBC video
      const currentIndex = this.$Region.system_observations.filter((obs)=>'subtype' in obs && obs.subtype == 'brbc' && 'media' in obs && obs.media.type === 'video').findIndex((item) => item.id == this.SELECTED_OBSERVATION.properties.id);
      if (currentIndex !== -1 && currentIndex !== 0) {
        const videoId = this.$Region.system_observations.filter((obs)=>'subtype' in obs && obs.subtype == 'brbc' && 'media' in obs && obs.media.type === 'video')[currentIndex - 1].id;
        this.explore(videoId, 'brbc', true, false, false, true);
      }
      return false
    },
    nextBRBC() {
      // launch the next BRBC video
      const currentIndex = this.$Region.system_observations.filter((obs)=>'subtype' in obs && obs.subtype == 'brbc' && 'media' in obs && obs.media.type === 'video').findIndex((item) => item.id == this.SELECTED_OBSERVATION.properties.id);
      if (currentIndex !== -1 && currentIndex < this.$Region.system_observations.filter((obs)=>'subtype' in obs && obs.subtype == 'brbc' && 'media' in obs && obs.media.type === 'video').length - 1) {
        const videoId = this.$Region.system_observations.filter((obs)=>'subtype' in obs && obs.subtype == 'brbc' && 'media' in obs && obs.media.type === 'video')[currentIndex + 1].id;
        this.explore(videoId, 'brbc', true, false, false, true);
      }
      return false
    },
  },
};
</script>
<style lang="scss" scoped>
.obs-title {
  margin-bottom: 0;
}
.text-white {
  color: $white-color !important;
}
.reference-link {
  color: $white-color !important;
  text-decoration: underline !important;
}
.loading-spinner {
  left: calc(50% - 70px);
  margin-top: 100px;
}
.submitted-by {
  color: $white-color;
  font-size: 12px;
  padding: 0;
  margin: 4px 0 0 0;
}
.side-panel-card {
  height: 100%;
}
.action-btns {
  padding-top: 12px;
}
/deep/ .action-btns button {
  margin-left: 10px;
}
/deep/ .md-title i {
  color: $white-color !important;
}
.share-btn {
  transform: translate(0, -5px);
}
.share-btn i {
  color: $gray-lighter !important;
}
.weather-icon {
  padding-left: 10px;
}
.threesixty-chip {
  background-color: $brand-info !important;
}
/deep/ .prev-next-btn {
  margin-right: 10px;
}
</style>