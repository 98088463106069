<template>
  <md-card class="md-card-profile">
    <div class="md-card-avatar">
      <img
        class="img"
        :src="AVATAR_TEMPLINK"
      >
    </div>

    <md-card-content>
      <h6 class="category text-gray">
        {{ userData.usertype }}
      </h6>
      <h4 class="card-title">
        {{ userData.firstname + ' ' + userData.lastname }}
      </h4>
      <p
        v-if="userData.usertype !== 'general public'"
        class="card-description"
      >
        {{ userData.school }}
      </p>
      <md-button
        class="md-round md-success"
        @click="surpriseMe"
      >
        Surprise Me
      </md-button>
    </md-card-content>
  </md-card>
</template>
<script>
import { User } from "@/pages/Dashboard/Components/mixins/User";
import swal from 'sweetalert2'

export default {
  name: 'UserCard',
  mixins: [User], // avatar stuff
  props: {
    userData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
  },

  beforeMount() {
    // load the profile picture
    this.debounceGetAvatarUrl();
  },

  methods: {
    surpriseMe() {
      swal.fire({
        title: `<p style="color:red;">ERROR</p>`,
        text: "Just kidding...you're doing great!",
        timer: 2000,
        showConfirmButton: false
      })
    }
  }
}

</script>
<style>
</style>
