<template>
  <div class="carousel-cell">
    <div v-if="'url' in sponsor && sponsor.url !== ''">
      <!-- link provided -->
      <a
        :href="sponsor.url"
        target="_blank"
        :title="sponsor.name"
      >
        <!-- logo provided -->
        <img
          v-if="'logo' in sponsor && sponsor.logo !== ''"
          :alt="sponsor.name"
          :src="sponsor.logo"
          crossorigin="anonymous"
          height="120"
          class="sponsor-logo"
        >
        <!-- no logo provided -->
        <span
          v-else
          class="sponsor-name"
        >
          {{ sponsor.name }}
        </span>
      </a>
    </div>
    <div v-else>
      <!-- no link provided -->
      <img
        v-if="'logo' in sponsor && sponsor.log !== ''"
        :alt="sponsor.name"
        :src="sponsor.logo"
        crossorigin="anonymous"
        height="120"
        class="sponsor-logo"
      >
      <span
        v-else
        class="sponsor-name"
      >
        {{ sponsor.name }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SponsorLogo",

  props: {
    sponsor: {
      type: Object,
      required: true
    }
  },

  data() {
    return {}
  },
};
</script>

<style lang="scss" scoped>
</style>
