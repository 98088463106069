/* eslint-disable */
// Generated by CoffeeScript 1.8.0
(function() {
  let Reader, typecode;

  typecode = {
    string: 0,
    object: 1,
    list: 2,
    int8: 3,
    int8array: 4,
    int16: 5,
    int16array: 6,
    int32: 7,
    int32array: 8,
    int64: 9,
    int64array: 10,
    float16: 11,
    float16array: 12,
    float32: 13,
    float32array: 14,
    float64: 15,
    float64array: 16,
    uint8: 17,
    uint8array: 18,
    uint16: 19,
    uint16array: 20,
    uint32: 21,
    uint32array: 22,
    uint64: 23,
    uint64array: 24,
    "null": 25
  };

  Reader = (function() {
    function Reader(buffer) {
      this.buffer = buffer;
      this.view = new DataView(buffer);
      this.offset = 0;
    }

    Reader.prototype.string = function() {
      let i, length, result, _i;
      length = this.uint32();
      result = '';
      for (i = _i = 0; 0 <= length ? _i < length : _i > length; i = 0 <= length ? ++_i : --_i) {
        result += String.fromCharCode(this.uint8());
      }
      return decodeURIComponent(escape(result));
    };

    Reader.prototype.object = function() {
      let count, key, result, value, _, _i;
      count = this.uint32();
      result = {};
      for (_ = _i = 0; 0 <= count ? _i < count : _i > count; _ = 0 <= count ? ++_i : --_i) {
        key = this.string();
        value = this.decode();
        result[key] = value;
      }
      return result;
    };

    Reader.prototype.list = function() {
      let count, i, _i, _results;
      count = this.uint32();
      _results = [];
      for (i = _i = 0; 0 <= count ? _i < count : _i > count; i = 0 <= count ? ++_i : --_i) {
        _results.push(this.decode());
      }
      return _results;
    };

    Reader.prototype.uint8 = function() {
      let value;
      value = this.view.getUint8(this.offset, true);
      this.offset += 1;
      return value;
    };

    Reader.prototype.uint8array = function() {
      let length, value;
      length = this.uint32();
      value = new Uint8Array(this.buffer, this.offset, length);
      this.offset += length;
      return value;
    };

    Reader.prototype.int8 = function() {
      let value;
      value = this.view.getInt8(this.offset, true);
      this.offset += 1;
      return value;
    };

    Reader.prototype.int8array = function() {
      let length, value;
      length = this.uint32();
      value = new Int8Array(this.buffer, this.offset, length);
      this.offset += length;
      return value;
    };

    Reader.prototype.uint16 = function() {
      let value;
      value = this.view.getUint16(this.offset, true);
      this.offset += 2;
      return value;
    };

    Reader.prototype.uint16array = function() {
      let length, padding, value;
      length = this.uint32();
      padding = this.offset % 2;
      this.offset += padding;
      value = new Uint16Array(this.buffer, this.offset, length);
      this.offset += length * 2;
      return value;
    };

    Reader.prototype.int16 = function() {
      let value;
      value = this.view.getInt16(this.offset, true);
      this.offset += 2;
      return value;
    };

    Reader.prototype.int16array = function() {
      let length, padding, value;
      length = this.uint32();
      padding = this.offset % 2;
      this.offset += padding;
      value = new Int16Array(this.buffer, this.offset, length);
      this.offset += length * 2;
      return value;
    };

    Reader.prototype.uint32 = function() {
      let value;
      value = this.view.getUint32(this.offset, true);
      this.offset += 4;
      return value;
    };

    Reader.prototype.uint32array = function() {
      let length, padding, value;
      length = this.uint32();
      padding = (4 - (this.offset % 4)) % 4;
      this.offset += padding;
      value = new Uint32Array(this.buffer, this.offset, length);
      this.offset += length * 4;
      return value;
    };

    Reader.prototype.int32 = function() {
      let value;
      value = this.view.getInt32(this.offset, true);
      this.offset += 4;
      return value;
    };

    Reader.prototype.int32array = function() {
      let length, padding, value;
      length = this.uint32();
      padding = (4 - (this.offset % 4)) % 4;
      this.offset += padding;
      value = new Int32Array(this.buffer, this.offset, length);
      this.offset += length * 4;
      return value;
    };

    Reader.prototype.uint64 = function() {
      let value;
      value = this.view.getUint64(this.offset, true);
      this.offset += 8;
      return value;
    };

    Reader.prototype.uint64array = function() {
      let length, padding, value;
      length = this.uint32();
      padding = (8 - (this.offset % 8)) % 8;
      this.offset += padding;
      value = new Uint64Array(this.buffer, this.offset, length);
      this.offset += length * 8;
      return value;
    };

    Reader.prototype.int64 = function() {
      let value;
      value = this.view.getInt64(this.offset, true);
      this.offset += 8;
      return value;
    };

    Reader.prototype.int64array = function() {
      let length, padding, value;
      length = this.uint32();
      padding = (8 - (this.offset % 8)) % 8;
      this.offset += padding;
      value = new Int64Array(this.buffer, this.offset, length);
      this.offset += length * 8;
      return value;
    };

    Reader.prototype.float32 = function() {
      let value;
      value = this.view.getFloat32(this.offset, true);
      this.offset += 4;
      return value;
    };

    Reader.prototype.float32array = function() {
      let length, padding, value;
      length = this.uint32();
      padding = (4 - (this.offset % 4)) % 4;
      this.offset += padding;
      value = new Float32Array(this.buffer, this.offset, length);
      this.offset += length * 4;
      return value;
    };

    Reader.prototype.float64 = function() {
      let value;
      value = this.view.getFloat64(this.offset, true);
      this.offset += 8;
      return value;
    };

    Reader.prototype.float64array = function() {
      let length, padding, value;
      length = this.uint32();
      padding = (8 - (this.offset % 8)) % 8;
      this.offset += padding;
      value = new Float64Array(this.buffer, this.offset, length);
      this.offset += length * 8;
      return value;
    };

    Reader.prototype.decode = function() {
      let type;
      type = this.uint8();
      switch (type) {
        case typecode.string:
          return this.string();
        case typecode.object:
          return this.object();
        case typecode.list:
          return this.list();
        case typecode.int8:
          return this.int8();
        case typecode.int8array:
          return this.int8array();
        case typecode.uint8:
          return this.uint8();
        case typecode.uint8array:
          return this.uint8array();
        case typecode.int16:
          return this.int16();
        case typecode.int16array:
          return this.int16array();
        case typecode.uint16:
          return this.int16();
        case typecode.uint16array:
          return this.int16array();
        case typecode.int32:
          return this.int32();
        case typecode.int32array:
          return this.int32array();
        case typecode.uint32:
          return this.int32();
        case typecode.uint32array:
          return this.int32array();
        case typecode.int64:
          return this.int64();
        case typecode.int64array:
          return this.int64array();
        case typecode.uint64:
          return this.int64();
        case typecode.uint64array:
          return this.int64array();
        case typecode.float32:
          return this.float32();
        case typecode.float32array:
          return this.float32array();
        case typecode.float64:
          return this.float64();
        case typecode.float64array:
          return this.float64array();
        case typecode["null"]:
          return null;
        default:
          throw 'unknown type: ' + type;
      }
    };

    return Reader;

  })();

  window.JICKLE = {
    parse: function(buffer) {
      let reader;
      reader = new Reader(buffer);
      return reader.decode();
    }
  };

  /*
  xhr = new XMLHttpRequest()
  xhr.open 'GET', 'data.bin', true
  xhr.responseType = 'arraybuffer'
  xhr.onload = ->
      console.log decode(xhr.response)
  xhr.send()
   */

}).call(this);
