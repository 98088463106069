// virtual tours available to users

/*
geojson repr of virtual tours
*/
const RegionVirTours = [
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [-110.7421875, 59.5899751],
    },
    properties: {
      code: "vt_ab_canadian_shield",
      title: "Canadian Shield Tour",
      description: "",
      thumbnail: "",
      kuula_id: "7Pz0G",
      image: "https://files.kuula.io/5f6c-ea04-74c4-b546/01-cover.jpg"
    }
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [-110.8740234, 50.4295179],
    },
    properties: {
      code: "vt_ab_grassland",
      title: "Grassland Tour",
      description: "",
      thumbnail: "",
      kuula_id: "7Pz00",
      image: "https://files.kuula.io/5f6c-e40d-6b0f-4859/01-cover.jpg"
    }
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [-116.9055176, 52.4693968],
    },
    properties: {
      code: "vt_ab_rocky_mountain",
      title: "Rocky Mountain Tour",
      description: "",
      thumbnail: "",
      kuula_id: "7Pz0p",
      image: "https://files.kuula.io/5f6c-e36a-ee4b-7151/01-cover.jpg"
    }
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [-111.9726562, 52.7163309],
    },
    properties: {
      code: "vt_ab_aspen_parkland",
      title: "Aspen Parkland Tour",
      description: "",
      thumbnail: "",
      kuula_id: "7Pz0m",
      image: "https://files.kuula.io/5f6c-e2dd-1e4b-9591/01-cover.jpg"
    }
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [-116.9824219, 53.8849163],
    },
    properties: {
      code: "vt_ab_foothills",
      title: "Foothills Tour",
      description: "",
      thumbnail: "",
      kuula_id: "7Pz0L",
      image: "https://files.kuula.io/5f6c-e234-ca79-3536/01-cover.jpg",
    }
  },
  {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [-116.4990234, 57.9265173],
    },
    properties: {
      code: "vt_ab_boreal_forest",
      title: "Boreal Forest Tour",
      description: "",
      thumbnail: "",
      kuula_id: "7Pz0h",
      image: "https://files.kuula.io/5f6c-e1e4-a750-1124/01-cover.jpg"
    }
  },
];

export default RegionVirTours;
