<template>
  <time-line
    v-if="showMissionItems"
    plain
    type="simple"
  >
    <time-line-item
      inverted
      icon-only
      badge-type="info"
      badge-icon="work"
      :btn-action="launchMission"
    />
    <!--REGISTER - always a success-->
    <time-line-item
      inverted
      badge-type="success"
      badge-icon="fingerprint"
      :btn-action="launchUserProfile"
    >
      <h3 slot="header">
        Profile
      </h3>
      <p slot="content">
        Nice! You have created a profile in the {{ $Region.app_name }} Simulator. Now
        work towards other achievements.
      </p>
      <span
        v-if="usertype==='student' && !teacher"
        slot="footer"
        class="badge badge-warning"
      >Who is your teacher? Add them in your profile</span>
    </time-line-item>
    <!--STUDENTS - ADD TEACHER-->
    <time-line-item
      v-if="usertype==='student' && !teacher"
      inverted
      badge-type="timeline-todo"
      badge-icon="question_answer"
      :btn-action="launchUserProfile"
    >
      <h3 slot="header">
        Teacher
      </h3>
      <p slot="content">
        Who is your teacher? Connect to them by editing your profile.
      </p>
    </time-line-item>
    <time-line-item
      v-else-if="usertype==='student' && teacher"
      inverted
      badge-type="success"
      badge-icon="question_answer"
      :btn-action="launchUserProfile"
    >
      <h3 slot="header">
        Teacher
      </h3>
      <p slot="content">
        You are connected with your teacher, the great {{ teacher }}.
      </p>
      <span
        slot="footer"
        class="badge badge-warning"
      >Don't be afraid to ask for help</span>
    </time-line-item>
    <!-- REGION-SPECIFIC TIMELINE ITEMS -->
    <time-line-item
      v-for="(item, index) in missionItems"
      :key="index"
      inverted
      :badge-type="item.badgeType"
      :badge-icon="item.icon"
      :badge-icon-class="item.iconClass"
      :btn-action="item.helpAction"
    >
      <h3 slot="header">
        {{ item.title }}
      </h3>
      <p slot="content">
        {{ item.statusDescription }}
      </p>
      <span
        v-if="item.showPrompt"
        slot="footer"
        class="badge badge-warning"
      >{{ item.successPrompt }}</span>
    </time-line-item>
  </time-line>
</template>

<script>
import { TimeLine, TimeLineItem } from '@/components'
import { Gamification } from "@/pages/Dashboard/Components/mixins/Gamification";

export default {
  name: "DashboardAchievements",
  components: {
    TimeLine,
    TimeLineItem
  },
  mixins: [Gamification], // for evaluating mission item success conditions
  props: {
    teacher: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true
    },
    usertype: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      missionItems: [...this.$Region.mission.missionItems],
      showMissionItems: false
    }
  },

  watch: {
    MISSION_UPDATE_TRIGGER() {
      this.hideMissionItems();
      this.loadMissionItems();
    }
  },

  beforeMount() {
    this.loadMissionItems();
  },

  methods: {
    hideMissionItems() {
      // to hide the timeline
      // necessary to completely remove & reload the timeline component
      // when mission item statuses are potentially changing in order to trigger the status of each TimeLineItem to update
      this.showMissionItems = false;
    },
    loadMissionItems() {
      // load/parse the mission items, eval the conditions
      // compute the status of each missionItem
      let completedCount = 0;
      this.missionItems.forEach(item => {
        const status = this.evalConditions(item.successConditions);
        if (status) {
          completedCount += 1;
        }
        item.badgeType = status ? 'success' : 'timeline-todo';
        item.statusDescription = status ? item.successMsg : item.description;
        item.showPrompt = status;
      });
      this.showMissionItems = true;
      if (completedCount === this.missionItems.length) {
        // all tasks passed
        this.getGameActivity('mission-complete');
      }
    },
    launchMission() {
      if (this.$router.currentRoute.name !== 'Your Mission') {
        this.$router.push({
          name: "Your Mission"
        });
      }
    },
    launchUserProfile() {
      this.$router.push({
        name: "User Profile"
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.badge-warning {
  display: inline-block;
  white-space: normal;
  font-size: 11px;
}
</style>
