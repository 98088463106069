<template>
  <!--Loading bar (symbolic)-->
  <div class="md-layout">
    <div
      v-if="collectingMapData"
      class="md-layout-item md-size-100"
    >
      <h4 class="card-title">
        Collecting map data...
      </h4>
      <md-progress-bar
        class="md-primary"
        md-mode="buffer"
        :md-value="progressAmount"
        :md-buffer="progressAmount"
      />
    </div>
    <!--Error collecting data-->
    <div
      v-else-if="dataError"
      class="md-layout"
    >
      <div class="md-layout-item md-size-100">
        <h4
          class="card-title"
          style="{color: red}"
        >
          Unable to collect map data, please try again later
        </h4>
      </div>
    </div>
    <div
      :id="mapContainerID"
      class="md-layout-item md-size-100 mapCard"
    />
  </div>
</template>

<script>
import * as mapboxgl from "mapbox-gl";
import { MapLayers } from "./mixins/MapLayers";

export default {
  name: "UserProfileMap",

  components: {
  },
  mixins: [MapLayers],

  props: {
    lat: {
      type: Number,
      required: true
    },
    lng: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      dataError: false, // TODO this isn't being used, but maybe it should be? Would need to implement an error check if region data isn't loading
    };
  },

  computed: {
    mapContainerID() {
      return `mapCard${this._uid}`
    },
    mapOptions() {
      return {
        container: this.mapContainerID,
        style: this.mapboxStyles.streets, // from MapLayers mixin
        center: [this.lng, this.lat],
        zoom: 5,
        maxZoom: 22,
        antialias: true
      }
    }
  },

  beforeMount() {
    this.incrementBar();
  },

  mounted() {
    this.initMap();
    // add marker at user's location
    new mapboxgl.Marker({
      draggable: false
    })
      .setLngLat([this.lng, this.lat])
      .addTo(this.map);
  },
  methods: {
    initMap() {
      // Initiate the map instance
      mapboxgl.accessToken = this.$Region.mapbox_token;
      this.map = new mapboxgl.Map(this.mapOptions);

      // Initiate layers on load
      const _map = this.map;
      this.map.on("style.load", () => {
        const waiting = () => {
          if (!_map.isStyleLoaded()) {
            setTimeout(waiting, 200);
          } else {
            // add some layers
            this.addHillshadeLayer();
            // this.loadScenarioStudyAreaJson(true);
          }
        };
        waiting();
        this.closeProgressBar();
      });
    }
  }
};
</script>

<style scoped>
.map-small {
  min-height: 150px;
  width: 100%;
}
.map-medium {
  min-height: 300px;
  width: 100%;
}
.map-large {
  min-height: 450px;
  width: 100%;
}
</style>
