/* jshint esversion: 6 */
// getter, mutation and action type constants for vuex store

export const types = {
  getters: {
    // gamification
    CHALLENGE_COUNTDOWN: "CHALLENGE_COUNTDOWN",
    HINT_COUNTDOWN: "HINT_COUNTDOWN",
    ACTIVE_HINT: "ACTIVE_HINT",
    PREVIOUS_HINT_INDEX: "PREVIOUS_HINT_INDEX",
    ACTIVE_HINT_ALLOCATION: "ACTIVE_HINT_ALLOCATION",
    ACTIVE_HINT_TRANSITION: "ACTIVE_HINT_TRANSITION",
    SHOW_HINT_DIALOG: "SHOW_HINT_DIALOG",
    HINT_BUTTON_ANIMATION: "HINT_BUTTON_ANIMATION",
    ACTIVE_CHALLENGE: "ACTIVE_CHALLENGE",
    SHOW_CHALLENGE_DIALOG: "SHOW_CHALLENGE_DIALOG",
    ACTIVE_QUEST: "ACTIVE_QUEST",
    QUEST_BUTTON_ANIMATION: "QUEST_BUTTON_ANIMATION",
    CHALLENGE_SUCCESS_ANIMATION: "CHALLENGE_SUCCESS_ANIMATION",
    ACTIVE_BADGE: "ACTIVE_BADGE",
    PENDING_BADGES: "PENDING_BADGES",
    SHOW_BADGE_DIALOG: "SHOW_BADGE_DIALOG",
    MISSION_UPDATE_TRIGGER: "MISSION_UPDATE_TRIGGER",
    // observation
    OBSERVATIONS_LAST_LOADED: "OBSERVATIONS_LAST_LOADED",
    OBSERVATIONS_ALL_JSON: "OBSERVATIONS_ALL_JSON",
    OBSERVATIONS_ALL_USER: "OBSERVATIONS_ALL_USER",
    HIDDEN_OBSERVATION: "HIDDEN_OBSERVATION",
    OBSERVATION_FEATURE_TO_LOAD: "OBSERVATION_FEATURE_TO_LOAD",
    SELECTED_OBSERVATION: "SELECTED_OBSERVATION",
    SELECTED_OBSERVATION_TYPE: "SELECTED_OBSERVATION_TYPE",
    SHOW_OBSERVATIONS: "SHOW_OBSERVATIONS",
    PREV_SHOW_OBSERVATIONS: "PREV_SHOW_OBSERVATIONS",
    SHOW_VIRTOURS: "SHOW_VIRTOURS",
    REFRESH_OBSERVATION_DATA: "REFRESH_OBSERVATION_DATA",
    // report
    REPORT_MODE: "REPORT_MODE",
    NEW_REPORT_MSG: "NEW_REPORT_MSG",
    USER_REPORTS: "USER_REPORTS",
    ACTIVE_REPORT: "ACTIVE_REPORT",
    ACTIVE_REPORT_SHARED: "ACTIVE_REPORT_SHARED",
    ACTIVE_REPORT_SCENARIO_COUNT: "ACTIVE_REPORT_SCENARIO_COUNT",
    // scenario
    USER_SCENARIOS: "USER_SCENARIOS",
    ACTIVE_SCENARIO: "ACTIVE_SCENARIO",
    ACTIVE_SCENARIO_SHARED: "ACTIVE_SCENARIO_SHARED",
    ACTIVE_RUNID: "ACTIVE_RUNID",
    ACTIVE_RUNID_NEW_SCENARIO_ID: "ACTIVE_RUNID_NEW_SCENARIO_ID",
    SCENARIO_CONNECTION_ACTIVE: "SCENARIO_CONNECTION_ACTIVE",
    REFRESH_SCENARIO: "REFRESH_SCENARIO",
    SCENARIO_UNDOS: "SCENARIO_UNDOS",
    SELECTED_REGION_TYPE: "SELECTED_REGION_TYPE",
    SELECTED_REGIONS: "SELECTED_REGIONS",
    SELECTED_REGION_NAME: "SELECTED_REGION_NAME",
    REMOVE_ALLOCATION_ID: "REMOVE_ALLOCATION_ID",
    ALLOCATION_AREA_MAX: "ALLOCATION_AREA_MAX",
    ALLOCATIONS_UPDATED: "ALLOCATIONS_UPDATED",
    MGMT_PRACTICE_UPDATED: "MGMT_PRACTICE_UPDATED",
    PLANNING_MAKERS_DRAGGABLE: "PLANNING_MAKERS_DRAGGABLE",
    SHOW_PLANNING_LAYER: "SHOW_PLANNING_LAYER",
    REFRESH_LANDUSE_LAYER: "REFRESH_LANDUSE_LAYER",
    REFRESH_PLANNING_MARKERS: "REFRESH_PLANNING_MARKERS",
    REFRESH_REGIONS_LAYER: "REFRESH_REGIONS_LAYER",
    REMOVE_REGIONS_LAYER: "REMOVE_REGIONS_LAYER",
    REMOVE_PLANNING_MARKERS: "REMOVE_PLANNING_MARKERS",
    LAST_SCENARIO_PATCH: "LAST_SCENARIO_PATCH",
    INDICATOR_VALS: "INDICATOR_VALS",
    INDICATOR_VALS_READY: "INDICATOR_VALS_READY",
    // teacher
    STUDENTS: "STUDENTS",
    STUDENTS_DATA_LOADED: "STUDENTS_DATA_LOADED",
    STUDENTS_LAST_LOADED: "STUDENTS_LAST_LOADED",
    // tours
    TOUR_ACTIVE: "TOUR_ACTIVE",
    HELP_BTN_TOUR_COMPLETE: "HELP_BTN_TOUR_COMPLETE",
    AVAILABLE_TOURS: "AVAILABLE_TOURS",
    PRIORITY_AVAILABLE_TOURS: "PRIORITY_AVAILABLE_TOURS",
    AVAILABLE_TOURS_TRIGGER: "AVAILABLE_TOURS_TRIGGER",
    // ui
    EXPLORE_MODE: "EXPLORE_MODE",
    EXPLORE_MOBILE_FS_CONTENT: "EXPLORE_MOBILE_FS_CONTENT",
    SIDEBAR_OPEN: "SIDEBAR_OPEN",
    SHOW_SIDEBAR_CONTAINER: "SHOW_SIDEBAR_CONTAINER",
    SIDEBAR_CONTENT_OPEN: "SIDEBAR_CONTENT_OPEN",
    SIDEBAR_CONTENT_TYPE: "SIDEBAR_CONTENT_TYPE",
    ACTIVE_INDICATOR: "ACTIVE_INDICATOR",
    ACTIVE_LT: "ACTIVE_LT",
    SCENARIO_DIALOG_OPEN: "SCENARIO_DIALOG_OPEN",
    EDIT_SCENARIO_DIALOG_OPEN: "EDIT_SCENARIO_DIALOG_OPEN",
    SCENARIO_EDIT_MODE: "SCENARIO_EDIT_MODE",
    SCENARIO_COMPUTING_DIALOG_OPEN: "SCENARIO_COMPUTING_DIALOG_OPEN",
    SCENARIO_COMPUTING_RUNTIME: "SCENARIO_COMPUTING_RUNTIME",
    BASEMAP_STYLE: "BASEMAP_STYLE",
    MAP_DATALAYER: "MAP_DATALAYER",
    MAP_DATALAYER_RELOAD: "MAP_DATALAYER_RELOAD",
    MAP_FLYTO: "MAP_FLYTO",
    MAP_ACTION_TRIGGER: "MAP_ACTION_TRIGGER",
    SHOW_LANDUSE_OVERLAY: "SHOW_LANDUSE_OVERLAY",
    SHOW_PLANNING_MARKERS: "SHOW_PLANNING_MARKERS",
    SHOW_PLANNING_MARKERS_LOCKED: "SHOW_PLANNING_MARKERS_LOCKED",
    ACTIVE_LAND_SUMMARY_TAB: "ACTIVE_LAND_SUMMARY_TAB",
    ACTIVE_CLIMATE_TAB: "ACTIVE_CLIMATE_TAB",
    ACTIVE_SCENARIO_WIZARD_TAB: "ACTIVE_SCENARIO_WIZARD_TAB",
    ACTIVE_SCENARIO_WIZARD_NEXT: "ACTIVE_SCENARIO_WIZARD_NEXT",
    ACTIVE_SCENARIO_WIZARD_PREV: "ACTIVE_SCENARIO_WIZARD_PREV",
    SELECTED_VIRTUAL_TOUR: "SELECTED_VIRTUAL_TOUR",
    SHOW_IND_LAYERS: "SHOW_IND_LAYERS",
    SHOW_IND_FEATURES: "SHOW_IND_FEATURES",
    // user
    USER_DATA: "USER_DATA",
    AVATAR_TEMPLINK: "AVATAR_TEMPLINK",
    AVATAR_TEMPLINK_LAST_LOADED: "AVATAR_TEMPLINK_LAST_LOADED",
  },
  mutations: {
    // gamification
    SET_CHALLENGE_COUNTDOWN: "SET_CHALLENGE_COUNTDOWN",
    DECREMENT_CHALLENGE_COUNTDOWN: "DECREMENT_CHALLENGE_COUNTDOWN",
    SET_HINT_COUNTDOWN: "SET_HINT_COUNTDOWN",
    DECREMENT_HINT_COUNTDOWN: "DECREMENT_HINT_COUNTDOWN",
    SET_ACTIVE_HINT: "SET_ACTIVE_HINT",
    SET_PREVIOUS_HINT_INDEX: "SET_PREVIOUS_HINT_INDEX",
    SET_ACTIVE_HINT_ALLOCATION: "SET_ACTIVE_HINT_ALLOCATION",
    SET_ACTIVE_HINT_TRANSITION: "SET_ACTIVE_HINT_TRANSITION",
    TOGGLE_HINT_BUTTON_ANIMATION: "TOGGLE_HINT_BUTTON_ANIMATION",
    SET_ACTIVE_CHALLENGE: "SET_ACTIVE_CHALLENGE",
    SET_SHOW_HINT_CHALLENGE_DIALOG: "SET_SHOW_HINT_CHALLENGE_DIALOG",
    SET_ACTIVE_QUEST: "SET_ACTIVE_QUEST",
    TOGGLE_QUEST_BUTTON_ANIMATION: "TOGGLE_QUEST_BUTTON_ANIMATION",
    TOGGLE_CHALLENGE_SUCCESS_ANIMATION: "TOGGLE_CHALLENGE_SUCCESS_ANIMATION",
    SET_ACTIVE_BADGE: "SET_ACTIVE_BADGE",
    UPDATE_PENDING_BADGES: "UPDATE_PENDING_BADGES",
    SET_SHOW_BADGE_DIALOG: "SET_SHOW_BADGE_DIALOG",
    INCREMENT_MISSION_UPDATE_TRIGGER: "INCREMENT_MISSION_UPDATE_TRIGGER",
    // observation
    SET_OBSERVATIONS_LAST_LOADED: "SET_OBSERVATIONS_LAST_LOADED",
    OBSERVATIONS_ALL_FEATURES_SPLICE: "OBSERVATIONS_ALL_FEATURES_SPLICE",
    OBSERVATIONS_PUSH: "OBSERVATIONS_PUSH",
    USER_OBSERVATIONS_REMOVE: "USER_OBSERVATIONS_REMOVE",
    OBSERVATIONS_CLEAR: "OBSERVATIONS_CLEAR",
    OBSERVATIONS_USER_PUSH: "OBSERVATIONS_USER_PUSH",
    OBSERVATIONS_USER_CLEAR: "OBSERVATIONS_USER_CLEAR",
    SET_HIDDEN_OBSERVATION: "SET_HIDDEN_OBSERVATION",
    SET_OBSERVATION_FEATURE_TO_LOAD: "SET_OBSERVATION_FEATURE_TO_LOAD",
    SET_SELECTED_OBSERVATION: "SET_SELECTED_OBSERVATION",
    UPDATE_SELECTED_OBSERVATION: "UPDATE_SELECTED_OBSERVATION",
    SET_SELECTED_OBSERVATION_TYPE: "SET_SELECTED_OBSERVATION_TYPE",
    SHOW_OBSERVATIONS_ON: "SHOW_OBSERVATIONS_ON",
    SHOW_OBSERVATIONS_OFF: "SHOW_OBSERVATIONS_OFF",
    SET_PREV_SHOW_OBSERVATIONS: "SET_PREV_SHOW_OBSERVATIONS",
    TOGGLE_SHOW_VIRTOURS: "TOGGLE_SHOW_VIRTOURS",
    TOGGLE_REFRESH_OBSERVATION_DATA: "TOGGLE_REFRESH_OBSERVATION_DATA",
    // report
    SET_REPORT_MODE: "SET_REPORT_MODE",
    SET_NEW_REPORT_MSG: "SET_NEW_REPORT_MSG",
    LOAD_USER_REPORTS: "LOAD_USER_REPORTS",
    USER_REPORTS_PUSH: "USER_REPORTS_PUSH",
    USER_REPORTS_UPDATE: "USER_REPORTS_UPDATE",
    USER_REPORTS_REMOVE: "USER_REPORTS_REMOVE",
    SET_ACTIVE_REPORT: "SET_ACTIVE_REPORT",
    UPDATE_ACTIVE_REPORT: "UPDATE_ACTIVE_REPORT",
    SET_ACTIVE_REPORT_SHARED: "SET_ACTIVE_REPORT_SHARED",
    ACTIVE_REPORT_ADD_SCENARIO: "ACTIVE_REPORT_ADD_SCENARIO",
    ACTIVE_REPORT_REMOVE_SCENARIO: "ACTIVE_REPORT_REMOVE_SCENARIO",
    ACTIVE_REPORT_UPDATE: "ACTIVE_REPORT_UPDATE",
    // scenario
    LOAD_USER_SCENARIOS: "LOAD_USER_SCENARIOS",
    USER_SCENARIOS_PUSH: "USER_SCENARIOS_PUSH",
    USER_SCENARIOS_UPDATE: "USER_SCENARIOS_UPDATE",
    USER_SCENARIOS_REMOVE: "USER_SCENARIOS_REMOVE",
    SET_ACTIVE_SCENARIO: "SET_ACTIVE_SCENARIO",
    UPDATE_ACTIVE_SCENARIO: "UPDATE_ACTIVE_SCENARIO",
    SET_ACTIVE_SCENARIO_SHARED: "SET_ACTIVE_SCENARIO_SHARED",
    TOGGLE_REFRESH_SCENARIO: "TOGGLE_REFRESH_SCENARIO",
    SET_ACTIVE_RUNID: "SET_ACTIVE_RUNID",
    SET_ACTIVE_RUNID_NEW_SCENARIO_ID: "SET_ACTIVE_RUNID_NEW_SCENARIO_ID",
    SET_SCENARIO_CONNECTION_ACTIVE: "SET_SCENARIO_CONNECTION_ACTIVE",
    SCENARIO_UNDOS_PUSH: "SCENARIO_UNDOS_PUSH",
    SCENARIO_UNDOS_REMOVE: "SCENARIO_UNDOS_REMOVE",
    SET_SELECTED_REGION_TYPE: "SET_SELECTED_REGION_TYPE",
    SELECTED_REGIONS_PUSH: "SELECTED_REGIONS_PUSH",
    SELECTED_REGIONS_CLEAR: "SELECTED_REGIONS_CLEAR",
    SET_SELECTED_REGION_NAME: "SET_SELECTED_REGION_NAME",
    SET_REMOVE_ALLOCATION_ID: "SET_REMOVE_ALLOCATION_ID",
    SET_ALLOCATION_AREA_MAX: "SET_ALLOCATION_AREA_MAX",
    ALLOCATIONS_UPDATED_PUSH: "ALLOCATIONS_UPDATED_PUSH",
    ALLOCATIONS_UPDATED_REMOVE: "ALLOCATIONS_UPDATED_REMOVE",
    SET_MGMT_PRACTICE_UPDATED: "SET_MGMT_PRACTICE_UPDATED",
    PLANNING_MARKERS_DRAGGABLE_ON: "PLANNING_MARKERS_DRAGGABLE_ON",
    PLANNING_MARKERS_DRAGGABLE_OFF: "PLANNING_MARKERS_DRAGGABLE_OFF",
    SET_SHOW_PLANNING_LAYER: "SET_SHOW_PLANNING_LAYER",
    TOGGLE_REFRESH_REGIONS_LAYER: "TOGGLE_REFRESH_REGIONS_LAYER",
    TOGGLE_REFRESH_LANDUSE_LAYER: "TOGGLE_REFRESH_LANDUSE_LAYER",
    TOGGLE_REFRESH_PLANNING_MARKERS: "TOGGLE_REFRESH_PLANNING_MARKERS",
    TOGGLE_REMOVE_REGIONS_LAYER: "TOGGLE_REMOVE_REGIONS_LAYER",
    TOGGLE_CLEAR_PLANNING_MARKERS: "TOGGLE_CLEAR_PLANNING_MARKERS",
    SET_LAST_SCENARIO_PATCH: "SET_LAST_SCENARIO_PATCH",
    SET_INDICATOR_VAL: "SET_INDICATOR_VAL",
    CLEAR_INDICATOR_VALS: "CLEAR_INDICATOR_VALS",
    SET_INDICATOR_VALS_READY: "SET_INDICATOR_VALS_READY",
    // teacher
    STUDENTS_LOAD: "STUDENTS_LOAD",
    SET_STUDENTS_DATA_LOADED: "SET_STUDENTS_DATA_LOADED",
    SET_STUDENTS_LAST_LOADED: "SET_STUDENTS_LAST_LOADED",
    // tours
    SET_TOUR_ACTIVE: "SET_TOUR_ACTIVE",
    SET_HELP_BTN_TOUR_COMPLETE: "SET_HELP_BTN_TOUR_COMPLETE",
    AVAILABLE_TOURS_PUSH: "AVAILABLE_TOURS_PUSH",
    AVAILABLE_TOURS_REMOVE: "AVAILABLE_TOURS_REMOVE",
    SET_PRIORITY_AVAILABLE_TOURS: "SET_PRIORITY_AVAILABLE_TOURS",
    TOGGLE_AVAILABLE_TOURS_TRIGGER: "TOGGLE_AVAILABLE_TOURS_TRIGGER",
    // ui
    SET_EXPLORE_MODE: "SET_EXPLORE_MODE",
    SET_EXPLORE_MOBILE_FS_CONTENT: "SET_EXPLORE_MOBILE_FS_CONTENT",
    OPEN_SIDEBAR: "OPEN_SIDEBAR",
    TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
    CLOSE_SIDEBAR: "CLOSE_SIDEBAR",
    TOGGLE_SIDEBAR_CONTENT: "TOGGLE_SIDEBAR_CONTENT",
    OPEN_SIDEBAR_CONTENT: "OPEN_SIDEBAR_CONTENT",
    CLOSE_SIDEBAR_CONTENT: "CLOSE_SIDEBAR_CONTENT",
    SET_SIDEBAR_CONTENT_TYPE: "SET_SIDEBAR_CONTENT_TYPE",
    SET_INDICATOR: "SET_INDICATOR",
    SET_ACTIVE_LT: "SET_ACTIVE_LT",
    TOGGLE_SCENARIO_DIALOG: "TOGGLE_SCENARIO_DIALOG",
    CLOSE_SCENARIO_DIALOG: "CLOSE_SCENARIO_DIALOG",
    TOGGLE_EDIT_SCENARIO_DIALOG: "TOGGLE_EDIT_SCENARIO_DIALOG",
    CLOSE_EDIT_SCENARIO_DIALOG: "CLOSE_EDIT_SCENARIO_DIALOG",
    SET_SCENARIO_EDIT_MODE: "SET_SCENARIO_EDIT_MODE",
    OPEN_SCENARIO_COMPUTING_DIALOG: "OPEN_SCENARIO_COMPUTING_DIALOG",
    CLOSE_SCENARIO_COMPUTING_DIALOG: "CLOSE_SCENARIO_COMPUTING_DIALOG",
    SET_SCENARIO_COMPUTING_RUNTIME: "SET_SCENARIO_COMPUTING_RUNTIME",
    SET_BASEMAP_STYLE: "SET_BASEMAP_STYLE",
    SET_MAP_DATALAYER: "SET_MAP_DATALAYER",
    TRIGGER_MAP_DATALAYER_RELOAD: "TRIGGER_MAP_DATALAYER_RELOAD",
    SET_MAP_FLYTO: "SET_MAP_FLYTO",
    SET_MAP_ACTION_TRIGGER: "SET_MAP_ACTION_TRIGGER",
    SHOW_LANDUSE_OVERLAY_ON: "SHOW_LANDUSE_OVERLAY_ON",
    TOGGLE_LANDUSE_OVERLAY: "TOGGLE_LANDUSE_OVERLAY",
    SHOW_PLANNING_MARKERS_ON: "SHOW_PLANNING_MARKERS_ON",
    SHOW_PLANNING_MARKERS_OFF: "SHOW_PLANNING_MARKERS_OFF",
    TOGGLE_PLANNING_MARKERS: "TOGGLE_PLANNING_MARKERS",
    SHOW_PLANNING_MARKERS_LOCK_ON: "SHOW_PLANNING_MARKERS_LOCK_ON",
    SHOW_PLANNING_MARKERS_LOCK_OFF: "SHOW_PLANNING_MARKERS_LOCK_OFF",
    SET_LAND_SUMMARY_TAB: "SET_LAND_SUMMARY_TAB",
    SET_CLIMATE_TAB: "SET_CLIMATE_TAB",
    SET_SCENARIO_WIZARD_TAB: "SET_SCENARIO_WIZARD_TAB",
    TOGGLE_ACTIVE_SCENARIO_WIZARD_BTN: "TOGGLE_ACTIVE_SCENARIO_WIZARD_BTN",
    SET_SELECTED_VIRTUAL_TOUR: "SET_SELECTED_VIRTUAL_TOUR",
    IND_LAYERS_INIT: "IND_LAYERS_INIT",
    TOGGLE_IND_LAYER: "TOGGLE_IND_LAYER",
    IND_FEATURES_INIT: "IND_FEATURES_INIT",
    TOGGLE_IND_FEATURE: "TOGGLE_IND_FEATURE",
    // user
    USER_LOAD: "USER_LOAD",
    USER_DATA_UPDATE: "USER_DATA_UPDATE",
    SET_AVATAR_TEMPLINK: "SET_AVATAR_TEMPLINK",
    SET_AVATAR_TEMPLINK_LAST_LOADED: "SET_AVATAR_TEMPLINK_LAST_LOADED",
  },
  actions: {
    // gamification
    SET_CHALLENGE_COUNTDOWN: "SET_CHALLENGE_COUNTDOWN",
    DECREMENT_CHALLENGE_COUNTDOWN: "DECREMENT_CHALLENGE_COUNTDOWN",
    SET_HINT_COUNTDOWN: "SET_HINT_COUNTDOWN",
    DECREMENT_HINT_COUNTDOWN: "DECREMENT_HINT_COUNTDOWN",
    SET_ACTIVE_HINT: "SET_ACTIVE_HINT",
    CLEAR_ACTIVE_HINT: "CLEAR_ACTIVE_HINT",
    SET_PREVIOUS_HINT_INDEX: "SET_PREVIOUS_HINT_INDEX",
    SET_ACTIVE_HINT_ALLOCATION: "SET_ACTIVE_HINT_ALLOCATION",
    SET_ACTIVE_HINT_TRANSITION: "SET_ACTIVE_HINT_TRANSITION",
    SET_ACTIVE_CHALLENGE: "SET_ACTIVE_CHALLENGE",
    CLEAR_ACTIVE_CHALLENGE: "CLEAR_ACTIVE_CHALLENGE",
    SET_SHOW_HINT_CHALLENGE_DIALOG: "SET_SHOW_HINT_CHALLENGE_DIALOG",
    SET_ACTIVE_QUEST: "SET_ACTIVE_QUEST",
    CLEAR_ACTIVE_QUEST: "CLEAR_ACTIVE_QUEST",
    TOGGLE_QUEST_BUTTON_ANIMATION: "TOGGLE_QUEST_BUTTON_ANIMATION",
    TOGGLE_CHALLENGE_SUCCESS_ANIMATION: "TOGGLE_CHALLENGE_SUCCESS_ANIMATION",
    SET_ACTIVE_BADGE: "SET_ACTIVE_BADGE",
    CLEAR_ACTIVE_BADGE: "CLEAR_ACTIVE_BADGE",
    UPDATE_PENDING_BADGES: "UPDATE_PENDING_BADGES",
    SET_SHOW_BADGE_DIALOG: "SET_SHOW_BADGE_DIALOG",
    INCREMENT_MISSION_UPDATE_TRIGGER: "INCREMENT_MISSION_UPDATE_TRIGGER",
    // observation
    SET_OBSERVATIONS_LAST_LOADED: "SET_OBSERVATIONS_LAST_LOADED",
    OBSERVATIONS_LOAD_ALL: "OBSERVATIONS_LOAD_ALL",
    OBSERVATIONS_LOAD_ALL_USER: "OBSERVATIONS_LOAD_ALL_USER",
    OBSERVATIONS_LOAD_ALL_SYSTEM: "OBSERVATIONS_LOAD_ALL_SYSTEM",
    OBSERVATIONS_LOAD_DETAILS: "OBSERVATIONS_LOAD_DETAILS",
    OBSERVATIONS_ALL_FEATURES_SPLICE: "OBSERVATIONS_ALL_FEATURES_SPLICE",
    OBSERVATIONS_PUSH: "OBSERVATIONS_PUSH",
    USER_OBSERVATIONS_REMOVE: "USER_OBSERVATIONS_REMOVE",
    SET_HIDDEN_OBSERVATION: "SET_HIDDEN_OBSERVATION",
    CLEAR_HIDDEN_OBSERVATION: "CLEAR_HIDDEN_OBSERVATION",
    RESTORE_HIDDEN_OBSERVATION: "RESTORE_HIDDEN_OBSERVATION",
    SET_OBSERVATION_FEATURE_TO_LOAD: "SET_OBSERVATION_FEATURE_TO_LOAD",
    CLEAR_OBSERVATION_FEATURE_TO_LOAD: "CLEAR_OBSERVATION_FEATURE_TO_LOAD",
    SET_SELECTED_OBSERVATION: "SET_SELECTED_OBSERVATION",
    UPDATE_SELECTED_OBSERVATION: "UPDATE_SELECTED_OBSERVATION",
    CLEAR_SELECTED_OBSERVATION: "CLEAR_SELECTED_OBSERVATION",
    SET_SELECTED_OBSERVATION_TYPE: "SET_SELECTED_OBSERVATION_TYPE",
    SHOW_OBSERVATIONS_ON: "SHOW_OBSERVATIONS_ON",
    SHOW_OBSERVATIONS_OFF: "SHOW_OBSERVATIONS_OFF",
    SET_PREV_SHOW_OBSERVATIONS: "SET_PREV_SHOW_OBSERVATIONS",
    TOGGLE_SHOW_VIRTOURS: "TOGGLE_SHOW_VIRTOURS",
    TOGGLE_REFRESH_OBSERVATION_DATA: "TOGGLE_REFRESH_OBSERVATION_DATA",
    // report
    SET_REPORT_MODE: "SET_REPORT_MODE",
    SET_NEW_REPORT_MSG: "SET_NEW_REPORT_MSG",
    LOAD_USER_REPORTS: "LOAD_USER_REPORTS",
    CLEAR_USER_REPORTS: "CLEAR_USER_REPORTS",
    USER_REPORTS_PUSH: "USER_REPORTS_PUSH",
    USER_REPORTS_UPDATE: "USER_REPORTS_UPDATE",
    USER_REPORTS_REMOVE: "USER_REPORTS_REMOVE",
    SET_ACTIVE_REPORT: "SET_ACTIVE_REPORT",
    UPDATE_ACTIVE_REPORT: "UPDATE_ACTIVE_REPORT",
    ACTIVE_REPORT_ADD_SCENARIO: "ACTIVE_REPORT_ADD_SCENARIO",
    ACTIVE_REPORT_REMOVE_SCENARIO: "ACTIVE_REPORT_REMOVE_SCENARIO",
    ACTIVE_REPORT_UPDATE: "ACTIVE_REPORT_UPDATE",
    ACTIVE_REPORT_CLEAR: "ACTIVE_REPORT_CLEAR",
    // scenario
    LOAD_USER_SCENARIOS: "LOAD_USER_SCENARIOS",
    CLEAR_USER_SCENARIOS: "CLEAR_USER_SCENARIOS",
    USER_SCENARIOS_PUSH: "USER_SCENARIOS_PUSH",
    USER_SCENARIOS_UPDATE: "USER_SCENARIOS_UPDATE",
    USER_SCENARIOS_REMOVE: "USER_SCENARIOS_REMOVE",
    SET_ACTIVE_SCENARIO: "SET_ACTIVE_SCENARIO",
    UPDATE_ACTIVE_SCENARIO: "UPDATE_ACTIVE_SCENARIO",
    CLOSE_SCENARIO: "CLOSE_SCENARIO",
    CLEAR_SCENARIO_AND_REGIONS: "CLEAR_SCENARIO_AND_REGIONS",
    INIT_NEW_SCENARIO: "INIT_NEW_SCENARIO",
    TOGGLE_REFRESH_SCENARIO: "TOGGLE_REFRESH_SCENARIO",
    NEW_ACTIVE_RUNID: "NEW_ACTIVE_RUNID",
    CLEAR_ACTIVE_RUNID: "CLEAR_ACTIVE_RUNID",
    SET_ACTIVE_RUNID_NEW_SCENARIO_ID: "SET_ACTIVE_RUNID_NEW_SCENARIO_ID",
    SET_SCENARIO_CONNECTION_ACTIVE: "SET_SCENARIO_CONNECTION_ACTIVE",
    SCENARIO_UNDOS_PUSH: "SCENARIO_UNDOS_PUSH",
    SCENARIO_UNDOS_REMOVE: "SCENARIO_UNDOS_REMOVE",
    SCENARIO_UNDOS_GET: "SCENARIO_UNDOS_GET",
    SET_SELECTED_REGION_TYPE: "SET_SELECTED_REGION_TYPE",
    SELECTED_REGIONS_PUSH: "SELECTED_REGIONS_PUSH",
    SELECTED_REGIONS_CLEAR: "SELECTED_REGIONS_CLEAR",
    SET_SELECTED_REGION_NAME: "SET_SELECTED_REGION_NAME",
    SET_REMOVE_ALLOCATION_ID: "SET_REMOVE_ALLOCATION_ID",
    SET_ALLOCATION_AREA_MAX: "SET_ALLOCATION_AREA_MAX",
    ALLOCATIONS_PUSH: "ALLOCATIONS_PUSH",
    ALLOCATIONS_UPDATED_PUSH: "ALLOCATIONS_UPDATED_PUSH",
    ALLOCATIONS_UPDATED_REMOVE: "ALLOCATIONS_UPDATED_REMOVE",
    ALLOCATIONS_CLEAR: "ALLOCATIONS_CLEAR",
    SET_MGMT_PRACTICE_UPDATED: "SET_MGMT_PRACTICE_UPDATED",
    GOALS_CLEAR: "GOALS_CLEAR",
    SET_SHOW_PLANNING_LAYER: "SET_SHOW_PLANNING_LAYER",
    PLANNING_MARKERS_DRAGGABLE_ON: "PLANNING_MARKERS_DRAGGABLE_ON",
    PLANNING_MARKERS_DRAGGABLE_OFF: "PLANNING_MARKERS_DRAGGABLE_OFF",
    TOGGLE_REFRESH_REGIONS_LAYER: "TOGGLE_REFRESH_REGIONS_LAYER",
    TOGGLE_REFRESH_LANDUSE_LAYER: "TOGGLE_REFRESH_LANDUSE_LAYER",
    TOGGLE_REMOVE_REGIONS_LAYER: "TOGGLE_REMOVE_REGIONS_LAYER",
    TOGGLE_REFRESH_PLANNING_MARKERS: "TOGGLE_REFRESH_PLANNING_MARKERS",
    TOGGLE_CLEAR_PLANNING_MARKERS: "TOGGLE_CLEAR_PLANNING_MARKERS",
    SET_LAST_SCENARIO_PATCH: "SET_LAST_SCENARIO_PATCH",
    SET_INDICATOR_VAL: "SET_INDICATOR_VAL",
    CLEAR_INDICATOR_VALS: "CLEAR_INDICATOR_VALS",
    SET_INDICATOR_VALS_READY: "SET_INDICATOR_VALS_READY",
    // teacher
    STUDENTS_LOAD: "STUDENTS_LOAD",
    // tours
    SET_TOUR_ACTIVE: "SET_TOUR_ACTIVE",
    SET_HELP_BTN_TOUR_COMPLETE: "SET_HELP_BTN_TOUR_COMPLETE",
    AVAILABLE_TOURS_PUSH: "AVAILABLE_TOURS_PUSH",
    AVAILABLE_TOURS_REMOVE: "AVAILABLE_TOURS_REMOVE",
    SET_PRIORITY_AVAILABLE_TOURS: "SET_PRIORITY_AVAILABLE_TOURS",
    // ui
    SET_EXPLORE_MODE: "SET_EXPLORE_MODE",
    SET_EXPLORE_MOBILE_FS_CONTENT: "SET_EXPLORE_MOBILE_FS_CONTENT",
    OPEN_SIDEBAR: "OPEN_SIDEBAR",
    TOGGLE_SIDEBAR: "TOGGLE_SIDEBAR",
    CLOSE_SIDEBAR: "CLOSE_SIDEBAR",
    TOGGLE_SIDEBAR_CONTENT: "TOGGLE_SIDEBAR_CONTENT",
    OPEN_SIDEBAR_CONTENT: "OPEN_SIDEBAR_CONTENT",
    CLOSE_SIDEBAR_CONTENT: "CLOSE_SIDEBAR_CONTENT",
    SET_SIDEBAR_CONTENT_TYPE: "SET_SIDEBAR_CONTENT_TYPE",
    SET_INDICATOR: "SET_INDICATOR",
    SET_ACTIVE_LT: "SET_ACTIVE_LT",
    TOGGLE_SCENARIO_DIALOG: "TOGGLE_SCENARIO_DIALOG",
    CLOSE_SCENARIO_DIALOG: "CLOSE_SCENARIO_DIALOG",
    TOGGLE_EDIT_SCENARIO_DIALOG: "TOGGLE_EDIT_SCENARIO_DIALOG",
    CLOSE_EDIT_SCENARIO_DIALOG: "CLOSE_EDIT_SCENARIO_DIALOG",
    SET_SCENARIO_EDIT_MODE: "SET_SCENARIO_EDIT_MODE",
    OPEN_SCENARIO_COMPUTING_DIALOG: "OPEN_SCENARIO_COMPUTING_DIALOG",
    CLOSE_SCENARIO_COMPUTING_DIALOG: "CLOSE_SCENARIO_COMPUTING_DIALOG",
    SET_BASEMAP_STYLE: "SET_BASEMAP_STYLE",
    SET_MAP_DATALAYER: "SET_MAP_DATALAYER",
    TRIGGER_MAP_DATALAYER_RELOAD: "TRIGGER_MAP_DATALAYER_RELOAD",
    SET_MAP_FLYTO: "SET_MAP_FLYTO",
    SET_MAP_ACTION_TRIGGER: "SET_MAP_ACTION_TRIGGER",
    SHOW_LANDUSE_OVERLAY_ON: "SHOW_LANDUSE_OVERLAY_ON",
    TOGGLE_LANDUSE_OVERLAY: "TOGGLE_LANDUSE_OVERLAY",
    SHOW_PLANNING_MARKERS_ON: "SHOW_PLANNING_MARKERS_ON",
    SHOW_PLANNING_MARKERS_OFF: "SHOW_PLANNING_MARKERS_OFF",
    TOGGLE_PLANNING_MARKERS: "TOGGLE_PLANNING_MARKERS",
    SHOW_PLANNING_MARKERS_LOCK_ON: "SHOW_PLANNING_MARKERS_LOCK_ON",
    SHOW_PLANNING_MARKERS_LOCK_OFF: "SHOW_PLANNING_MARKERS_LOCK_OFF",
    SET_LAND_SUMMARY_TAB: "SET_LAND_SUMMARY_TAB",
    SET_CLIMATE_TAB: "SET_CLIMATE_TAB",
    SET_SCENARIO_WIZARD_TAB: "SET_SCENARIO_WIZARD_TAB",
    TOGGLE_ACTIVE_SCENARIO_WIZARD_BTN: "TOGGLE_ACTIVE_SCENARIO_WIZARD_BTN",
    SET_SELECTED_VIRTUAL_TOUR: "SET_SELECTED_VIRTUAL_TOUR",
    IND_LAYERS_INIT: "IND_LAYERS_INIT",
    TOGGLE_IND_LAYER: "TOGGLE_IND_LAYER",
    IND_FEATURES_INIT: "IND_FEATURES_INIT",
    TOGGLE_IND_FEATURE: "TOGGLE_IND_FEATURE",
    // user
    USER_LOAD: "USER_LOAD",
    USER_DATA_UPDATE: "USER_DATA_UPDATE",
    SET_AVATAR_TEMPLINK: "SET_AVATAR_TEMPLINK",
    SET_AVATAR_TEMPLINK_LAST_LOADED: "SET_AVATAR_TEMPLINK_LAST_LOADED",
  },
};
